import { denormalize } from 'normalizr'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import {
  audioBookSchema,
  audioBooksSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import { AUDIOBOOK_CHANGED } from 'client/bookmate/reducers/audiobook-reducer'
import {
  IMPRESSION_ADDED,
  IMPRESSION_REMOVED,
} from 'client/bookmate/reducers/impressions-reducer'

export function getAudioBooksByIds(state, ids) {
  return denormalize(ids, audioBooksSchema, state.entities)
}

export function getAudioBookById(state, id) {
  return denormalize(id, audioBookSchema, state.entities)
}

function updateListenersCount(oldListenersCount, reason) {
  return (
    oldListenersCount +
    {
      added: 1,
      updated: 0,
      removed: -1,
    }[reason]
  )
}

const initialState = {}

export default function audiobooks(state = initialState, action) {
  if (action.entities && action.entities.audiobooks) {
    return merge({}, state, action.entities.audiobooks)
  }

  switch (action.type) {
    case AUDIOBOOK_CHANGED: {
      const { audiobook: changedAudiobook, reason } = action
      const oldAudiobookData = state[changedAudiobook.uuid]

      if (!oldAudiobookData) return state

      return {
        ...state,
        [changedAudiobook.uuid]: {
          ...oldAudiobookData,
          ...changedAudiobook,
          listeners_count: updateListenersCount(
            oldAudiobookData.listeners_count,
            reason,
          ),
        },
      }
    }

    case IMPRESSION_ADDED: {
      if (action.resourceType === 'audiobook') {
        const currentAudiobook = state[action.resourceUuid]

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentAudiobook,
            impression_uuid: action.impression.uuid,
            impressions_count: currentAudiobook.impressions_count + 1,
          },
        }
      } else {
        return state
      }
    }

    case IMPRESSION_REMOVED: {
      if (action.resourceType === 'audiobook') {
        let currentAudiobook = state[action.resourceUuid]
        currentAudiobook = omit(currentAudiobook, 'impression_uuid')

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentAudiobook,
            impressions_count: currentAudiobook.impressions_count - 1,
          },
        }
      } else {
        return state
      }
    }

    default:
      return state
  }
}

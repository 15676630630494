import { CALL_API } from 'shared/middlewares/api-middleware'

const TEMPLATE_LOAD = 'TEMPLATE_LOAD'
const TEMPLATE_LOAD_SUCCESS = 'TEMPLATE_LOAD_SUCCESS'
const TEMPLATE_LOAD_ERROR = 'TEMPLATE_LOAD_ERROR'

import { Dispatch, GetState } from 'shared/types/redux'

type LoadTemplatePayload = {
  templateName: string
  data: {
    [key: string]: unknown
  }
}

type TemplateLoadAction = {
  type: typeof TEMPLATE_LOAD
}

type TemplateLoadSuccessAction = {
  type: typeof TEMPLATE_LOAD_SUCCESS
  templateName: string
  template: { html: string }
}

type TemplateLoadErrorAction = {
  type: typeof TEMPLATE_LOAD_ERROR
  error: string
}

type Action =
  | TemplateLoadAction
  | TemplateLoadSuccessAction
  | TemplateLoadErrorAction

export type State = {
  loaded: boolean
  loading: boolean
  error: string | null | undefined
  [key: string]: { html: string }
}

export function loadTemplate({ templateName, data = {} }: LoadTemplatePayload) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      app: { pointName },
      currentUser,
    } = getState()

    await dispatch({
      [CALL_API]: {
        endpoint: `/templates/${pointName}/${templateName}`,
        options: {
          data: {
            data: JSON.stringify(data),
            locale: currentUser.data.locale,
          },
        },
        modifyResponse: response => ({ template: response, templateName }),
        types: [TEMPLATE_LOAD, TEMPLATE_LOAD_SUCCESS, TEMPLATE_LOAD_ERROR],
      },
    })
  }
}

const initialState = {
  loaded: false,
  loading: false,
  error: null,
}

export default function templates(state: State = initialState, action: Action) {
  switch (action.type) {
    case TEMPLATE_LOAD:
      return {
        ...state,
        loading: true,
      }

    case TEMPLATE_LOAD_SUCCESS:
      return {
        ...state,
        [action.templateName]: action.template,
        loading: false,
        loaded: true,
        error: null,
      }

    case TEMPLATE_LOAD_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

import { normalize } from 'normalizr'

import { CALL_API, CALL_API_ERROR } from 'shared/middlewares/api-middleware'

import { shelfSchema } from 'client/bookmate/reducers/schemas/schemas'
import {
  SHELF_CHANGED,
  SHELF_ADDED,
} from 'client/bookmate/reducers/shelves-reducer'
import {
  analyticsEvent,
  SHELF_CREATED,
} from 'client/shared/reducers/analytics-reducer'

const SHELF_FORM_LOADING = 'SHELF_FORM_LOADING'
const SHELF_FORM_LOADED = 'SHELF_FORM_LOADED'

const SHELF_FORM_CHANGE_ANNOTATION = 'SHELF_FORM_CHANGE_ANNOTATION'
const SHELF_FORM_CHANGE_TITLE = 'SHELF_FORM_CHANGE_TITLE'
const SHELF_FORM_CHANGE_COVER = 'SHELF_FORM_CHANGE_COVER'
const SHELF_FORM_TOGGLE_STATE = 'SHELF_FORM_TOGGLE_STATE'

const initialState = {
  uuid: '',
  annotation: '',
  title: '',
  cover: '',
  state: 'published',
  loading: false,
  submitLocked: true,
}

export default function shelfForm(state = initialState, action) {
  switch (action.type) {
    case SHELF_FORM_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SHELF_FORM_LOADED:
      return {
        ...state,
        ...action.shelf,
        loading: false,
      }
    case CALL_API_ERROR:
      return {
        ...state,
        loading: false,
      }
    case SHELF_FORM_CHANGE_ANNOTATION:
      return {
        ...state,
        annotation: action.annotation,
      }
    case SHELF_FORM_CHANGE_TITLE:
      return {
        ...state,
        title: action.title,
        submitLocked: !action.title.trim(),
      }
    case SHELF_FORM_CHANGE_COVER:
      return {
        ...state,
        cover: action.cover,
      }
    case SHELF_FORM_TOGGLE_STATE:
      return {
        ...state,
        state: action.state,
      }
    case SHELF_CHANGED:
    case SHELF_ADDED:
      return initialState
    default:
      return state
  }
}

export function changeAnnotation(annotation) {
  return { type: SHELF_FORM_CHANGE_ANNOTATION, annotation }
}

export function changeTitle(title) {
  return { type: SHELF_FORM_CHANGE_TITLE, title }
}

export function changeCover(cover) {
  return { type: SHELF_FORM_CHANGE_COVER, cover }
}

export function editShelf(uuid, data, cb) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}`,
      options: {
        method: 'put',
        isMultipart: true,
        body: data,
      },
      normalize: response => {
        const { entities } = normalize(response.bookshelf, shelfSchema)

        return {
          shelf: response.bookshelf,
          entities,
        }
      },
      onSuccess: (dispatch, getState, res) => cb(res.shelf),
      types: [SHELF_FORM_LOADING, SHELF_CHANGED],
    },
  }
}

export function createShelf(data, cb) {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/bookshelves',
      options: {
        method: 'post',
        isMultipart: true,
        body: data,
      },
      normalize: response => {
        const { entities } = normalize(response.bookshelf, shelfSchema)

        return {
          shelf: response.bookshelf,
          entities,
        }
      },
      onSuccess: (dispatch, getState, response) => {
        dispatch(
          analyticsEvent(SHELF_CREATED, { shelf_id: response.shelf.uuid }),
        )
        cb(response.shelf)
      },
      types: [SHELF_FORM_LOADING, SHELF_ADDED],
    },
  }
}

export function loadShelf(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}`,
      normalize: response => {
        const { entities } = normalize(response.bookshelf, shelfSchema)

        return {
          shelf: response.bookshelf,
          entities,
        }
      },
      types: [SHELF_FORM_LOADING, SHELF_FORM_LOADED],
    },
  }
}

export function toggleState(state) {
  return { type: SHELF_FORM_TOGGLE_STATE, state }
}

import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import SVGInline from 'react-svg-inline'
import compose from 'lodash/fp/compose'

import AppStoreIcon from 'client/shared/icons/app-store.svg'
import GooglePlayIcon from 'client/shared/icons/google-play.svg'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import Linka from 'client/shared/blocks/linka'
import './store-button.styl'
import urlHelper from 'shared/tools/url-helper'
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from 'client/shared/helpers/deeplink-helper'

type Props = {
  platform: 'ios' | 'android'
  size: 'big' | 'small'
  inverted: boolean
  path?: string
  campaign?: string
  stage?: string
  width?: string
  theme?: string
} & withI18nProps &
  DecoratorProps

type StoreInfo = {
  icon: string
  url: string
  text: string
}

class StoreButton extends Component<Props> {
  static defaultProps = {
    size: 'big',
    inverted: false,
  }

  apps = {
    ios: {
      icon: AppStoreIcon,
      url: APP_STORE_LINK,
      text: 'buttons.AppStore',
    },
    android: {
      icon: GooglePlayIcon,
      url: GOOGLE_PLAY_LINK,
      text: 'buttons.GooglePlay',
    },
  }

  getPlatformInfo = (): StoreInfo => {
    const { platform } = this.props

    return this.apps[platform]
  }

  render() {
    const { i18n, size, inverted, width, theme, path } = this.props

    const colorMod = theme || (inverted ? 'dark' : 'light')
    const componentClass = `store-button store-button_${size} store-button_${colorMod}`
    const widthStyle = width ? { width } : {}

    return (
      <Linka
        path={path || this.getPlatformInfo().url || urlHelper.getTheApp()}
        className={componentClass}
        target="_blank"
        style={widthStyle}
      >
        <SVGInline
          svg={this.getPlatformInfo().icon}
          className="store-button__icon"
        />
        <div className="store-button__text">
          <span
            dangerouslySetInnerHTML={{
              __html: i18n._(this.getPlatformInfo().text),
            }}
          />
        </div>
      </Linka>
    )
  }
}

const wrappers = compose(uiBox, withI18n({ update: true }))

export default wrappers(StoreButton)

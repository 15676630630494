export function doesUrlEndInTitle(title, urlFragment) {
  // this is the pattern we used to transform title before adding it in the end of the url
  const transformedTitle = title.replace(/[&/\\ #,+()$~%.'":*?<>{}–«»]/g, '_')
  return transformedTitle === decodeURIComponent(urlFragment)
}

export function isResourcePage(pathname: string): boolean {
  return (
    pathname.startsWith('/books/') ||
    pathname.startsWith('/authors/') ||
    pathname.startsWith('/serials/') ||
    pathname.startsWith('/series/') ||
    pathname.startsWith('/audiobooks/') ||
    pathname.startsWith('/comicbooks/') ||
    pathname.startsWith('/bookshelves/')
  )
}

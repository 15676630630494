import { socialClientConfig } from '../config'
import { makeMobileUrl, addState } from './urls'
import { getAbsentProviderMsg } from './errors'

const desktopAuth = ({ width, height, path }, isConnecting) => {
  const top = screen.height / 2 - height / 2
  const left = screen.width / 2 - width / 2

  window.open(
    isConnecting ? addState(path, { isConnecting, fromDesktop: true }) : path,
    '',
    `width=${width},height=${height},top=${top},left=${left}`,
  )
}

const mobileAuth = (name, state: { backUrl?: string } = {}) => {
  if (!state.backUrl) state.backUrl = window.location.href
  window.location.assign(makeMobileUrl(name, state))
}

const mobileRegex = /ip(hone|ad|od)|android|windows phone|blackberry/i

export const useSocialProvider = (name, state) => {
  const providerParams = socialClientConfig[name]

  if (!providerParams) {
    throw new Error(getAbsentProviderMsg(name, Object.keys(socialClientConfig)))
  }

  if (mobileRegex.test(window.navigator.userAgent)) {
    mobileAuth(name, state)
    return
  }

  desktopAuth(providerParams, state?.isConnecting)
}

import cookie from 'react-cookie'

import env from 'env'

/*
 * This is intented to be used in React components for server-side redirect
 * Errors thrown by this function during server-side rendering will be handled
 */
export function redirectTo(url, status = 302, rememberCurrentUrl = false) {
  // eslint-disable-next-line no-throw-literal
  throw {
    response: {
      status,
      location: url,
      rememberCurrentUrl,
    },
  }
}

export function serverRedirectTo(...args) {
  if (env.isServer()) {
    redirectTo(...args)
  }
}

/**
 * Check if the user has been redirected to the current page
 * (by looking up the `redirectedFrom` ky in the cookies)
 */
export function getPreviousUrl(domain: string): string {
  const previousUrl = cookie.load('redirectedFrom')

  if (previousUrl) {
    cookie.remove('redirectedFrom')
    cookie.remove('redirectedFrom', { domain })
  }

  return previousUrl
}

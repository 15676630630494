import React, { Component } from 'react'

import './placeholder-background.styl'

type Props = {
  placeholder: string
  children?: any
}

class PlaceholderBackground extends Component<Props> {
  render() {
    const { placeholder, children } = this.props

    const image = placeholder ? `data:image/png;base64,${placeholder}` : ''
    const background = image
      ? `url("${image}") no-repeat center / cover`
      : '#ece6d6'
    const imageBackgroundStyles = { background }

    return (
      <div className="placeholder-background" style={imageBackgroundStyles}>
        {children}
      </div>
    )
  }
}

export default PlaceholderBackground

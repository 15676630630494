import { normalize } from 'normalizr'

import config from 'config'
import { cacheAction } from 'shared/middlewares/cache-middleware'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { showcaseSectionsSchema } from 'client/bookmate/reducers/schemas/showcase-schemas'
import { addParamsToPath } from 'shared/tools/url-helper'

import {
  ContentType,
  Navigation,
  ShowcaseHierarchy,
} from 'client/shared/types/showcase'
import { GenericDispatchedEvent } from 'shared/types/redux'

const LOAD_SHOWCASE_ROOT_NAVIGATION = 'LOAD_SHOWCASE_ROOT_NAVIGATION'
const LOAD_SHOWCASE_ROOT_NAVIGATION_ERROR =
  'LOAD_SHOWCASE_ROOT_NAVIGATION_ERROR'
const LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS =
  'LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS'

const SET_SHOWCASE_PARAMS = 'SET_SHOWCASE_PARAMS'

const LOAD_SHOWCASE_NAVIGATION = 'LOAD_SHOWCASE_NAVIGATION'
const LOAD_SHOWCASE_NAVIGATION_ERROR = 'LOAD_SHOWCASE_NAVIGATION_ERROR'
const LOAD_SHOWCASE_NAVIGATION_SUCCESS = 'LOAD_SHOWCASE_NAVIGATION_SUCCESS'

const LOAD_SHOWCASE_SECTIONS = 'LOAD_SHOWCASE_SECTIONS'
const LOAD_SHOWCASE_SECTIONS_ERROR = 'LOAD_SHOWCASE_SECTIONS_ERROR'
const LOAD_SHOWCASE_SECTIONS_SUCCESS = 'LOAD_SHOWCASE_SECTIONS_SUCCESS'
const SHOWCASE_BANNER_CLOSE = 'SHOWCASE_BANNER_CLOSE'
const SHOWCASE_BANNER_CLOSE_SUCCESS = 'SHOWCASE_BANNER_CLOSE_SUCCESS'

const CLEAN_SHOWCASE = 'CLEAN_SHOWCASE'

export const DEFAULT_FROM_VALUE = 1
export const DEFAULT_LIMIT_VALUE = 10

export type State = {
  rootNavigation: {
    language: string
    items: Navigation[]
    loading: boolean
  }
  content: {
    [key: string]: {
      language: string
      contentType: ContentType
      uuid?: string
      slug: string
      background?: {
        large: string
      }
      title?: string
      hasTopics?: boolean
      hierarchy?: ShowcaseHierarchy[]
      sections: {
        items: string[]
        loading: boolean
      }
      navigation: {
        items: Navigation[]
        loading: boolean
      }
      loadSectionsFrom?: number
      limit?: number
    }
  }
}

type LoadRootShowcaseNavigationAction = {
  type: typeof LOAD_SHOWCASE_ROOT_NAVIGATION
}

type LoadRootShowcaseNavigationSuccessAction = {
  type: typeof LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS
  language: string
  navigation: {
    positions: Navigation[]
  }
}

type LoadRootShowcaseNavigationErrorAction = {
  type: typeof LOAD_SHOWCASE_ROOT_NAVIGATION_ERROR
}

type SetShowcaseAttributesAction = {
  type: typeof SET_SHOWCASE_PARAMS
  currentSlug: string
  slug: string
  language: string
  contentType: ContentType
}

type LoadShowcaseNavigationAction = {
  type: typeof LOAD_SHOWCASE_NAVIGATION
  currentSlug: string
}

type LoadShowcaseNavigationSuccessAction = {
  type: typeof LOAD_SHOWCASE_NAVIGATION_SUCCESS
  navigation: {
    positions: Navigation[]
  }
  currentSlug: string
}

type LoadShowcaseNavigationErrorAction = {
  type: typeof LOAD_SHOWCASE_NAVIGATION_ERROR
  currentSlug: string
}

type LoadShowcaseSectionsAction = {
  type: typeof LOAD_SHOWCASE_SECTIONS
  currentSlug: string
}

type LoadShowcaseSectionsSuccessAction = {
  type: typeof LOAD_SHOWCASE_SECTIONS_SUCCESS
  result: string[]
  background: {
    large: string
  }
  title: string
  has_topics?: boolean
  uuid: string
  hierarchy?: ShowcaseHierarchy[]
  currentSlug: string
  from: number
  limit: number
  next_section_id?: number
}

type LoadShowcaseSectionsErrorAction = {
  type: typeof LOAD_SHOWCASE_SECTIONS_ERROR
  currentSlug: string
}

type CleanShowcase = {
  type: typeof CLEAN_SHOWCASE
}

type Action =
  | LoadRootShowcaseNavigationAction
  | LoadRootShowcaseNavigationSuccessAction
  | LoadRootShowcaseNavigationErrorAction
  | SetShowcaseAttributesAction
  | LoadShowcaseNavigationAction
  | LoadShowcaseNavigationSuccessAction
  | LoadShowcaseNavigationErrorAction
  | LoadShowcaseSectionsAction
  | LoadShowcaseSectionsSuccessAction
  | LoadShowcaseSectionsErrorAction
  | CleanShowcase

export function closeBanner(tag: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/banners/${tag}`,
      options: {
        method: 'delete',
      },
      types: [SHOWCASE_BANNER_CLOSE, SHOWCASE_BANNER_CLOSE_SUCCESS],
    },
  }
}

export const loadRootShowcaseNavigation = cacheAction(
  function (lang: string | null | undefined) {
    const language = lang === 'rus' ? 'ru' : lang || config.fallbackLocale

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/showcases/${language}/root_navigation`,
        modifyResponse: response => ({
          ...response,
          language,
        }),
        types: [
          LOAD_SHOWCASE_ROOT_NAVIGATION,
          LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS,
          LOAD_SHOWCASE_ROOT_NAVIGATION_ERROR,
        ],
      },
    }
  },
  LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS,
  {},
)

type ShowcaseAttributes = {
  slug: string
  language: string
  contentType: ContentType
}

export function setShowcaseAttributes(
  currentSlug: string,
  { slug, language, contentType }: ShowcaseAttributes,
): ShowcaseAttributes & { type: string; currentSlug: string } {
  return {
    type: SET_SHOWCASE_PARAMS,
    slug,
    language,
    contentType,
    currentSlug,
  }
}

export const loadShowcaseNavigation = cacheAction(
  function ({ currentSlug }: { currentSlug: string }) {
    return {
      [CALL_API]: {
        endpoint: state => {
          const currentShowcase = state.showcase.content[currentSlug]

          if (!currentShowcase) return ''

          const { language, slug, contentType } = currentShowcase

          return `/p/api/v5/showcases/${language}/${slug}/${contentType}/navigation`
        },
        types: [
          LOAD_SHOWCASE_NAVIGATION,
          LOAD_SHOWCASE_NAVIGATION_SUCCESS,
          LOAD_SHOWCASE_NAVIGATION_ERROR,
        ],
      },
      currentSlug,
    }
  },
  LOAD_SHOWCASE_NAVIGATION_SUCCESS,
  {},
)

export const loadShowcaseSections = cacheAction(
  function ({
    currentSlug = '',
    withHierarchy = false,
    data = {
      from: DEFAULT_FROM_VALUE,
      limit: DEFAULT_LIMIT_VALUE,
      'components[]': '',
    },
  } = {}) {
    return {
      [CALL_API]: {
        endpoint: state => {
          const showcaseContent = state.showcase.content[currentSlug]

          const { language, slug, contentType } = showcaseContent

          if (!language || !slug || !contentType) return ''

          let params = data
          if (withHierarchy) {
            params = {
              ...params,
              'components[]': 'hierarchy',
            }
          }

          const endpoint = addParamsToPath(
            `/p/api/v5/showcases/${language}/${slug}/${contentType}/content`,
            params,
          )

          return endpoint
        },
        normalize: ({
          meta: { next_section_id, has_topics },
          showcase: { sections, title, uuid, background, components },
        }) => {
          const { result, entities } = normalize(
            sections,
            showcaseSectionsSchema,
          )

          return {
            ...data,
            next_section_id,
            has_topics,
            title,
            uuid,
            background,
            hierarchy:
              withHierarchy && components ? components.hierarchy : null,
            result,
            entities,
          }
        },
        types: [
          LOAD_SHOWCASE_SECTIONS,
          LOAD_SHOWCASE_SECTIONS_SUCCESS,
          LOAD_SHOWCASE_SECTIONS_ERROR,
        ],
      },
      currentSlug,
    }
  },
  LOAD_SHOWCASE_SECTIONS_SUCCESS,
  {},
)

export const loadKidsSections = cacheAction(
  function ({
    currentSlug = '',
    language = 'sr',
    contentType = 'mixed',
    withHierarchy = false,
    data = {
      from: DEFAULT_FROM_VALUE,
      limit: DEFAULT_LIMIT_VALUE,
      'components[]': '',
    },
  } = {}) {
    return {
      [CALL_API]: {
        endpoint: () => {
          let params = data
          if (withHierarchy) {
            params = {
              ...params,
              'components[]': 'hierarchy',
            }
          }

          const endpoint = addParamsToPath(
            `/p/api/v5/showcases/${language}/${currentSlug}/${contentType}/content`,
            params,
          )

          return endpoint
        },
        normalize: ({
          meta: { next_section_id, has_topics },
          showcase: { sections, title, uuid, background, components },
        }) => {
          const { result, entities } = normalize(
            sections,
            showcaseSectionsSchema,
          )

          return {
            ...data,
            next_section_id,
            has_topics,
            title,
            uuid,
            background,
            hierarchy:
              withHierarchy && components ? components.hierarchy : null,
            result,
            entities,
          }
        },
        types: [
          LOAD_SHOWCASE_SECTIONS,
          LOAD_SHOWCASE_SECTIONS_SUCCESS,
          LOAD_SHOWCASE_SECTIONS_ERROR,
        ],
      },
      currentSlug,
    }
  },
  LOAD_SHOWCASE_SECTIONS_SUCCESS,
  {},
)

export const cleanShowcase = (): GenericDispatchedEvent => {
  return {
    type: CLEAN_SHOWCASE,
  }
}

const initialState: State = {
  rootNavigation: {
    language: config.fallbackLocale,
    items: [],
    loading: false,
  },
  content: {},
}

export default function showcase(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case CLEAN_SHOWCASE:
      return initialState

    case LOAD_SHOWCASE_ROOT_NAVIGATION:
      return {
        ...state,
        rootNavigation: {
          ...state.rootNavigation,
          loading: true,
        },
      }

    case LOAD_SHOWCASE_ROOT_NAVIGATION_SUCCESS:
      return {
        ...state,
        rootNavigation: {
          language: action.language,
          items: action.navigation.positions,
          loading: false,
        },
      }

    case SET_SHOWCASE_PARAMS: {
      const currentShowcase = state.content[action.currentSlug]

      if (currentShowcase && currentShowcase.language === action.language) {
        return state
      }

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            language: action.language,
            contentType: action.contentType,
            slug: action.slug,
            limit: DEFAULT_LIMIT_VALUE,
            loadSectionsFrom: DEFAULT_FROM_VALUE,
            sections: {
              items: [],
              loading: false,
            },
            navigation: {
              items: [],
              loading: false,
            },
          },
        },
      }
    }

    case LOAD_SHOWCASE_NAVIGATION: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            navigation: {
              ...currentShowcase?.navigation,
              loading: true,
            },
          },
        },
      }
    }

    case LOAD_SHOWCASE_NAVIGATION_SUCCESS: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            navigation: {
              items: action.navigation?.positions,
              loading: false,
            },
          },
        },
      }
    }

    case LOAD_SHOWCASE_NAVIGATION_ERROR: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            navigation: {
              items: [],
              loading: false,
            },
          },
        },
      }
    }

    case LOAD_SHOWCASE_SECTIONS: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            sections: {
              ...currentShowcase?.sections,
              loading: true,
            },
          },
        },
      }
    }

    case LOAD_SHOWCASE_SECTIONS_SUCCESS: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            background: action.background,
            title: action.title,
            hasTopics: action.has_topics,
            uuid: action.uuid,
            sections: {
              items:
                action.from > 1
                  ? [...currentShowcase?.sections.items, ...action.result]
                  : action.result,
              loading: false,
            },
            hierarchy: action.hierarchy,
            loadSectionsFrom: action.next_section_id,
            limit: action.limit,
          },
        },
      }
    }

    case LOAD_SHOWCASE_SECTIONS_ERROR: {
      const currentShowcase = state.content[action.currentSlug]

      return {
        ...state,
        content: {
          ...state.content,
          [action.currentSlug]: {
            ...currentShowcase,
            sections: {
              items: [],
              loading: false,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

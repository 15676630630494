import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'

import { addBMSuffixWhen } from 'client/shared/helpers/seo-helpers'

type Props = {
  disableSuffix: boolean
  children: string
}

class HeadTitle extends Component<Props> {
  static defaultProps = {
    disableSuffix: false,
  }

  render() {
    const { children, disableSuffix } = this.props

    const title = addBMSuffixWhen(!disableSuffix, children)

    return <Helmet title={title} />
  }
}

export default HeadTitle

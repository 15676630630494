/**
 * Check whether the passed userAgent string shows evidence of request being sent
 * by a known search engine bot
 */
export function isKnownBotUa(ua: string): boolean {
  return [isGoogleBotUA, isYandexBotUA, isBingBotUA].some(check => check(ua))
}

export function isBotThatExecutesJS(ua: string): boolean {
  return isGoogleBotUA(ua)
}

export function isGoogleBotUA(ua: string): boolean {
  const googleBotRegex = /googlebot/i
  return googleBotRegex.test(ua)
}

export function isYandexBotUA(ua: string): boolean {
  const yandexBotRegex = /yandex.+\/bots/
  return yandexBotRegex.test(ua)
}

export function isBingBotUA(ua: string): boolean {
  const bingBotRegexes = [/bingbot/i, /msnbot/i]
  return bingBotRegexes.some(regex => regex.test(ua))
}

export default class MemoryCache {
  limit: number
  _cache: Record<string, any>
  size: number
  head: any
  tail: any | null

  constructor(limit = 1024) {
    this.limit = limit
    this._cache = new Map()
    this.size = 0
    this.head = null
    this.tail = null
  }

  set(key, value) {
    const item: any = { key, value }
    this._cache.set(key, item)

    if (this.tail) {
      this.tail.next = item
      item.prev = this.tail
    } else {
      this.head = item
    }

    this.tail = item
    if (this.size++ === this.limit) this.purge()
  }

  get(key) {
    const item = this._cache.get(key)
    const { value } = item

    if (item === this.tail) return value
    if (item.next) {
      if (item === this.head) this.head = item.next
      item.next.prev = item.prev
    }
    if (item.prev) item.prev.next = item.next

    item.next = null
    item.prev = this.tail

    if (this.tail) this.tail.next = item
    this.tail = item

    return value
  }

  has(key) {
    return this._cache.has(key)
  }

  purge() {
    const item = this.head

    if (this.head.next) {
      this.head = this.head.next
      this.head.prev = null
    }

    this._cache.delete(item.key)
    item.prev = null
    item.next = null
    this.size--
  }

  purgeKey(key) {
    let item = this._cache.get(key)
    if (!item) return

    if (item === this.head) this.head = item.next
    if (item.next) item.next.prev = item.prev
    if (item.prev) item.prev.next = item.next

    this._cache.delete(key)
    item = null
    this.size--
  }
}

import React, { Component } from 'react'
import './code-subscription-layout.styl'
import 'client/bookmate/blocks/subscription-form/subscription-form.styl'
import findIndex from 'lodash/findIndex'
import { Heading } from 'client/shared/blocks/heading'
import Spacer from 'client/shared/blocks/spacer'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import SubscriptionStep, {
  SubscriptionStepInner,
} from 'client/bookmate/blocks/subscription-step'
import StripeCreditCardBox from 'client/shared/boxes/stripe-credit-card-box'
import {
  getStripe,
  getStripeInstance,
  loadStripe,
} from 'client/shared/helpers/stripe-helper'
import { Dispatch } from 'shared/types/redux'
import { State as CodeState } from 'client/bookmate/reducers/code-reducer'
import { Locale } from 'client/shared/types/current-user'
import { ARRIVA, MA60 } from 'client/bookmate/boxes/code-subscription-box'
import {
  PaymentIntent,
  createPromoPaymentIntent,
} from 'client/shared/reducers/stripe-reducer'
import env from 'env'
import { doesCountryAllowApplePay } from 'client/shared/helpers/apple-pay-helper'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import {
  StripePaymentRequest,
  PaymentSystem as PaymentSystemType,
} from 'client/shared/types/subscription'
import { ApplePayOptions } from '../subscription-form/subscription-form'
import { constants } from 'client/bookmate/constants/constants'
import { RadioTogglerOption } from 'client/shared/blocks/toggler/radio-toggler-option'
import { Toggler } from 'client/shared/blocks/toggler/toggler'
import { PaymentSystem } from 'client/shared/blocks/payment-system/payment-system'
import { LinkPayButton } from 'client/shared/blocks/link-pay/link-pay-button'

type Props = {
  loading: boolean
  isDesktop: boolean
  isMobile?: boolean
  code: CodeState
  country: string
  paymentIntent: PaymentIntent
  callbackError:
    | (string | null | undefined)
    | ({ message: string } | null | undefined)
  language: Locale
  locale: Locale
  dispatch: Dispatch
}

type State = {
  applePayAllowed: ApplePayOptions
  onLoadApplePayButton: () => void | null
  onUnmountApplePay: () => void | null
  selectedPaymentSystem: PaymentSystem
}

export default class SubscriptionLayout extends Component<Props, State> {
  state = {
    applePayAllowed: null,
    onLoadApplePayButton: (): null => null,
    onUnmountApplePay: (): null => null,
    selectedPaymentSystem: ('cards' as unknown) as PaymentSystem,
  }

  componentDidMount(): void {
    loadStripe(this.props.dispatch, () => {
      this.isApplePayAvailable()
      setTimeout(() => {
        this.createPaymentIntent()
      }, 2000)
    })
  }

  isApplePayAllowed = (): boolean =>
    doesCountryAllowApplePay(this.props.country)

  async createPaymentIntent(): Promise<void> {
    if (env.isServer() || this.props.paymentIntent?.complete) return
    const { code, dispatch } = this.props
    const stripe = getStripe()

    if (!stripe) {
      await loadStripe(this.props.dispatch)
    }

    dispatch(
      createPromoPaymentIntent({
        promoUuid: code.codeValue,
      }),
    )
  }

  async isApplePayAvailable(): Promise<void> {
    const {
      currency,
      id,
      price,
      payment_scheme,
    } = this.props.code.credit_card_prompt.plan
    const setUpIntent = payment_scheme === 'recurrent_after_free_period'

    if (!this.isApplePayAllowed() || setUpIntent) return

    const covertedCurrency = currency.toLowerCase()
    const covertedCountry = this.props.country.toUpperCase()

    const stripe = getStripe()

    const paymentRequest: StripePaymentRequest = stripe.paymentRequest({
      country: covertedCountry,
      currency: covertedCurrency,
      total: {
        label: id,
        amount: price,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    if (!paymentRequest) return

    const stripeInstance = getStripeInstance()

    if (!stripeInstance) return
    const elements = stripe.elements()
    const prButton = elements.create('paymentRequestButton', {
      paymentRequest,
    })
    ;(async () => {
      const result: ApplePayOptions = await paymentRequest.canMakePayment()

      const onLoadApplePayButton = () => {
        if (result) {
          prButton.mount('#payment-request-button')
        } else {
          const paymentRequestButton = document.getElementById(
            'payment-request-button',
          )
          if (!paymentRequestButton) return null
        }
      }

      const unmountApplePayButton = (): void => {
        const paymentRequestButton = document.getElementById(
          'payment-request-button',
        )
        if (!paymentRequestButton) return

        paymentRequestButton.style.display = 'none'
        prButton.unmount('#payment-request-button')
      }

      this.setState({
        applePayAllowed: result,
        onLoadApplePayButton,
        onUnmountApplePay: unmountApplePayButton,
      })
    })()

    paymentRequest.on('paymentmethod', async ev => {
      const _paymentIntent = this.props.paymentIntent
      const { dispatch } = this.props
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe.confirmCardPayment(
        _paymentIntent.key,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      )
      if (confirmError) {
        dispatch(showAlert('error', { message: confirmError }))
        ev.complete('fail')
      } else {
        ev.complete('success')
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(
            this.props.paymentIntent.key,
          )
          if (error) dispatch(showAlert('error', { message: error }))
          else window.location.replace(_paymentIntent.returnUrl as string)
        } else {
          window.location.replace(_paymentIntent.returnUrl as string)
        }
      }
    })
  }

  render(): JSX.Element {
    return (
      <div className="code-subscription-layout">
        <LayoutColumn autoWidth centered size={8}>
          <Spacer size={32} />
          {this.renderCreditCardForm()}
        </LayoutColumn>
        <Spacer size="bottom-page" />
      </div>
    )
  }

  getHeading({ campaign }: { campaign: string }): JSX.Element {
    const {
      code: {
        credit_card_prompt: { title },
      },
      isMobile,
    } = this.props

    switch (campaign) {
      case ARRIVA:
        return (
          <>
            {!isMobile && (
              <>
                <img
                  className="bookmate-arriva-logo"
                  src="https://a.bmstatic.com/iu/63/136/logo line-89a83f8150825b12bf49bf871474b8f3.svg"
                  alt="logo bookmate arriva"
                />
                <Spacer size={64} />
              </>
            )}
            <Heading kind="graphik" rank={1} centered>
              Gratis abonnement i&nbsp;90&nbsp;DAGE
            </Heading>
            <Spacer size={32} />
            <p className="bookmate-offer-desc">
              Har du prøvet at have ubegrænset adgang til tusindvis af e- og
              lydbøger altid. Hele tiden? At have et kæmpe bibliotek med i
              lommen uanset, hvor du er? I dette eksklusive samarbejde tilbyder
              Bookmate og Arriva+ muligheden for netop det gratis
              i&nbsp;3&nbsp;måneder.
            </p>
          </>
        )
      case MA60:
        return (
          <>
            <Spacer size={64} />
            <Heading kind="graphik" rank={1} centered>
              Gratis abonnement i&nbsp;60&nbsp;DAGE
            </Heading>
            <Spacer size={32} />
            <p className="bookmate-offer-desc">
              Har du prøvet at have ubegrænset adgang til tusindvis af e-og
              lydbøger altid. Hele tiden? At have et kæmpe bibliotek med i
              lommen uanset, hvor du er? I dette eksklusive samarbejde tilbyder
              Bookmate og Magistrenes A-kasse muligheden for helt gratis at
              prøve det i 2 måneder. Det er da ret magisk.
            </p>
            <Spacer size={64} />
            <Heading kind="graphik" rank={2} centered>
              Og det er ikke det hele!
            </Heading>
            <Spacer size={32} />
            <p className="bookmate-offer-desc">
              Når den udvidede gratis prøveperiode er ovre, tilbyder MA dig 10%
              rabat hos Bookmate. Så hvis du fortsætter dit abonnement efter de
              60 dage, har MA sikret dig 10% rabat – resten af livet!
            </p>
            <p className="offer-caption">
              Bemærk:Tilbuddet gælder kun nye brugere, der ikke i forvejen
              abonnerer på Bookmate. Efter 60 dage vil abonnementet automatisk
              blive fornyet. Du kan annullere den automatiske fornyelse til
              enhver tid – under eller efter gratisperioden. Efter de 60 dage er
              rabatten på 10% tilgængelig ved køb af et månedsabonnement – så
              længe du beholder abonnementet på samme profil uden at opsige det.
            </p>
          </>
        )
      default:
        return (
          <Heading kind="graphik" rank={2} centered>
            {title}
          </Heading>
        )
    }
  }

  shouldRenderPaymentOptions = (): boolean =>
    Boolean(this.state.applePayAllowed)

  renderCreditCardForm(): JSX.Element {
    const {
      code: {
        codeValue,
        credit_card_prompt: { advantages },
      },
      isMobile,
    } = this.props
    const isArrivaCampaign = codeValue.includes(ARRIVA)
    const isMA60Campaign = codeValue.includes(MA60)
    const currentCampaign = isArrivaCampaign
      ? ARRIVA
      : isMA60Campaign
      ? MA60
      : 'none'

    const sortedSystems = ['cards' as PaymentSystemType]
    const applePayAllowed = this.state.applePayAllowed as ApplePayOptions
    if (applePayAllowed?.applePay) {
      sortedSystems.push((constants.APPLE_PAY as unknown) as PaymentSystemType)
    } else if (applePayAllowed?.googlePay) {
      sortedSystems.push((constants.GOOGLE_PAY as unknown) as PaymentSystemType)
    } else if (applePayAllowed?.link) {
      sortedSystems.push((constants.LINK as unknown) as PaymentSystemType)
    }

    const paymentOptions = sortedSystems.map(paymentSystem => {
      return {
        element: (
          <div className="subscription-form__button-wrapper">
            <PaymentSystem system={paymentSystem} />
          </div>
        ),
        value: paymentSystem,
      }
    })

    const activeOptionIndex = findIndex(
      sortedSystems,
      (system: PaymentSystem) => system === this.state.selectedPaymentSystem,
    )

    return (
      <div className="code-subscription-layout__form">
        <LayoutColumn
          centered
          size={isArrivaCampaign || isMA60Campaign ? 8 : 6}
        >
          <Spacer size={32} />
          {this.getHeading({ campaign: currentCampaign })}
        </LayoutColumn>
        <Spacer size={32} />
        <SubscriptionStep
          withCounter={false}
          header={'subscription.payment_card'}
          description={advantages}
        >
          <div className="subscription-form__radio-wrapper payment-options">
            {this.shouldRenderPaymentOptions() && (
              <Toggler
                name="payment-systems"
                component={RadioTogglerOption}
                values={paymentOptions}
                checkedIndex={activeOptionIndex}
                onChange={system =>
                  this.setState({ selectedPaymentSystem: system })
                }
                hideChecker
              />
            )}
          </div>
          {this.getSelectedPaymentButton()}
        </SubscriptionStep>
        {isArrivaCampaign && isMobile && (
          <>
            <Spacer size={32} />
            <img
              className="decor-train-2_mobile"
              src="https://a.bmstatic.com/iu/0/90/mobile train-4b2e33ae565e7e3c474aad1e4e87a94f.webp"
              alt="bookmate boger"
            />
          </>
        )}
        <Spacer size="bottom-page" />
      </div>
    )
  }
  getSelectedPaymentButton(): JSX.Element | null {
    const {
      loading,
      callbackError,
      code: {
        credit_card_prompt: { button_label },
      },
    } = this.props

    switch ((this.state.selectedPaymentSystem as unknown) as string) {
      case constants.CARDS:
        return (
          <SubscriptionStepInner>
            <StripeCreditCardBox
              buttonChild={button_label}
              disabled={false}
              awaitingApiResponse={loading}
              callbackError={callbackError}
            />
          </SubscriptionStepInner>
        )
      case constants.APPLE_PAY:
      case constants.GOOGLE_PAY:
      case constants.LINK:
        return (
          <SubscriptionStepInner>
            <LinkPayButton
              onUnmountApplePay={this.state.onUnmountApplePay}
              onLoadApplePayButton={this.state.onLoadApplePayButton}
            />
          </SubscriptionStepInner>
        )
      default:
        return null
    }
  }
}

import { DefaultRootState } from 'react-redux'

import find from 'lodash/find'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import property from 'lodash/property'
import reduce from 'lodash/reduce'

import { State } from 'shared/types/redux'
import {
  AccessLevels,
  CurrentUserSubscriptions,
  FamilyInvite,
  Product,
  ProductPlan,
  RecurrentSubscription,
} from 'client/shared/types/subscription'
import { UpgradeCostsPayload } from 'client/bookmate/reducers/subscription-reducer'
import { FamilyMember } from '../../shared/types/subscription'

export const areAnySubscriptionDataLoading = (
  state: State | DefaultRootState,
) => {
  return reduce(
    state.subscription.loadingStates,
    (result, value) => {
      return result ? result : value === 'loading'
    },
    false,
  )
}

export const areUserSubscriptionsLoading = (
  state: State | DefaultRootState,
): boolean => state.subscription.loadingStates.userSubscriptions === 'loading'

export const areUpgradeCostsLoading = (
  state: State | DefaultRootState,
): boolean => state.subscription.loadingStates.upgradeCosts === 'loading'

export const areProductsLoaded = (state: State | DefaultRootState): boolean => {
  return state.subscription.loadingStates.products === 'success'
}

export const getAllProducts = (state: State | DefaultRootState): Product[] => {
  return state.subscription.products || []
}

export const haveUserAccessLevelsLoaded = (
  state: State | DefaultRootState,
): boolean => {
  return state.subscription.loadingStates.userAccessLevels === 'success'
}

export const haveUserSubscriptionsLoaded = (
  state: State | DefaultRootState,
): boolean => {
  return state.subscription.loadingStates.userSubscriptions === 'success'
}

export const getUserAccessLevels = (
  state: State | DefaultRootState,
): AccessLevels => {
  return state.subscription.userAccessLevels
}

export const getUserSubscriptions = (
  state: State | DefaultRootState,
): CurrentUserSubscriptions => state.subscription.userSubscriptions

export const getActiveSubscription = (
  state: State | DefaultRootState,
): RecurrentSubscription =>
  state.subscription.userSubscriptions.recurrents.find(
    (subscription: RecurrentSubscription) => subscription.autorenew_enabled,
  )

export const getRecurrentSubscription = (
  state: State | DefaultRootState,
  subscriptionId: string,
): RecurrentSubscription | undefined => {
  const { recurrents } = getUserSubscriptions(state)
  return find(recurrents, subscription => subscription.uuid === subscriptionId)
}

export const getFamilySubscriptionMembers = (
  state: State | DefaultRootState,
): FamilyMember[] => state.subscription.familySubscription.members
export const getFamilySubscriptionInvites = (
  state: State | DefaultRootState,
): FamilyInvite[] => state.subscription.familySubscription.invites
export const getFamilySubscriptionError = (
  state: State | DefaultRootState,
): string | null | undefined => state.subscription.familySubscription.error

export const getFamilySubscriptionInvitation = (
  state: State | DefaultRootState,
): FamilyInvite | null => state.subscription.familySubscription.invitation

const receivedProducts = (state: State | DefaultRootState) => {
  return state.subscription.products !== null
}

export const getProductPlans = (
  state: State | DefaultRootState,
  productId: string,
): ProductPlan[] => {
  const products = getAllProducts(state)
  const product = find(products, _product => _product.id === productId)
  return product ? product.button.plans : []
}

export const findProductPlanById = (
  state: State | DefaultRootState,
  planId: string,
): ProductPlan | null | undefined => {
  // search products and featuredProduct for a plan by plan id
  const products = getAllProducts(state)
  const featuredProduct = getFeaturedProduct(state)

  return compact([...products, featuredProduct]).reduce((result, product) => {
    const plan = find(product.button.plans, ({ id }) => id === planId)
    return plan ? plan : result
  }, null)
}

export const getUserSubscriptionTypes = (state: State | DefaultRootState) => {
  const { userAccessLevels } = state.subscription
  return userAccessLevels.expiration_dates.map(property('level'))
}

export const getProduct = (
  state: State | DefaultRootState | DefaultRootState,
  productId: string,
): Product | undefined => {
  const products = getAllProducts(state)
  return find(products, product => product.id === productId)
}

export const isProductUnavailable = (
  state: State | DefaultRootState | DefaultRootState,
  productId: string,
): boolean => {
  return receivedProducts(state) && isEmpty(getProduct(state, productId))
}

export const hasFeaturedProductLoaded = (
  state: State | DefaultRootState,
): boolean => {
  return state.subscription.loadingStates.featuredProduct === 'success'
}

export const getFeaturedProduct = (
  state: State | DefaultRootState,
): Product => {
  return state.subscription.featuredProduct
}

export const isChangeCardRequestInProgress = (
  state: State | DefaultRootState,
): boolean => {
  return state.subscription.loadingStates.changeCard === 'loading'
}

export const getPayloadForUpgradeCosts = (
  state: State | DefaultRootState,
  productId: string,
): UpgradeCostsPayload | null | undefined => {
  const product = getProduct(state, productId)
  if (!product) return null
  const { upgradeable_from } = product
  if (!upgradeable_from) return null

  const planIds: string[] = getProductPlans(state, productId).map(
    ({ id }) => id,
  )

  return {
    recurrentUuid: upgradeable_from, // id of the recurrent subscription from which the user is upgrading
    planIds,
    productId,
  }
}

export const getUpgradeOptions = (state: State | DefaultRootState): Product =>
  state.subscription.upgradeSubscription.upgradeOptions

export const getNonExpiredAccessLevels = (state: State | DefaultRootState) => {
  const accessLevels = getUserAccessLevels(state)

  return accessLevels.expiration_dates
    .filter(({ expires_at }) => expires_at * 1000 > Date.now())
    .map(property('level'))
}

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { showAlert } from 'client/shared/reducers/alert-reducer'

import {
  analyticsEvent,
  REPORT_ERROR,
} from 'client/shared/reducers/analytics-reducer'
import { GenericDispatchedEvent } from 'shared/types/redux'

const PROBLEM_FORM_LOADING = 'PROBLEM_FORM_LOADING'
const PROBLEM_FORM_REPORTED = 'PROBLEM_FORM_REPORTED'
const PROBLEM_FORM_TYPES_LOADED = 'PROBLEM_FORM_TYPES_LOADED'
const PROBLEM_FORM_CHANGE_DATA = 'PROBLEM_FORM_CHANGE_DATA'
const PROBLEM_FORM_CLEAR_DATA = 'PROBLEM_FORM_CLEAR_DATA'

export function loadProblemTypes(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/a/4/cp/types',
      modifyResponse: response => ({ types: response }),
      types: [PROBLEM_FORM_LOADING, PROBLEM_FORM_TYPES_LOADED],
    },
  }
}

export function changeFormData(
  data: Record<string, any>,
): GenericDispatchedEvent & { data: Record<string, any> } {
  return {
    type: PROBLEM_FORM_CHANGE_DATA,
    data,
  }
}

export function clearFormData(): GenericDispatchedEvent {
  return { type: PROBLEM_FORM_CLEAR_DATA }
}

export function report(data: Record<string, any>): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/a/4/cp',
      options: {
        method: 'post',
        data,
      },
      onSuccess: dispatch => {
        dispatch(showAlert('feedback', { message: 'alerts.feedback' }))
        dispatch(
          analyticsEvent(REPORT_ERROR, {
            object_type: data.content_problem.resource_type,
            object_id: data.content_problem.resource_uuid,
          }),
        )
      },
      onError: dispatch => {
        dispatch(clearFormData())
      },
      types: [PROBLEM_FORM_LOADING, PROBLEM_FORM_REPORTED],
    },
  }
}
export type ProblemFormState = {
  types: { [key: string]: string }
  loading: boolean
  selectedType: string
  email: string
  comment: string
}

const initialState = {
  types: {},
  loading: false,
  selectedType: '',
  email: '',
  comment: '',
}

export default function problemForm(state = initialState, action) {
  switch (action.type) {
    case PROBLEM_FORM_TYPES_LOADED:
      return {
        ...state,
        types: action.types,
        loading: false,
      }

    case PROBLEM_FORM_LOADING:
      return {
        ...state,
        loading: true,
      }

    case PROBLEM_FORM_CLEAR_DATA:
    case PROBLEM_FORM_REPORTED:
      return initialState

    case PROBLEM_FORM_CHANGE_DATA:
      return {
        ...state,
        ...action.data,
      }

    default:
      return state
  }
}

import { schema, denormalize } from 'normalizr'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import noop from 'lodash/noop'
import { createSelector } from '@reduxjs/toolkit'

import {
  bookSchema,
  userSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import {
  analyticsEvent,
  LIKE_BUTTON_PRESSED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { throttledLikeHelper } from 'shared/tools/api-helpers'
import { checkEntities } from 'client/bookmate/helpers/entities-helper'

export const quoteSchema = new schema.Entity(
  'quotes',
  {
    book: bookSchema,
    creator: userSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy(quote) {
      return {
        ...quote,
        resourceType: 'quote',
      }
    },
  },
)

const QUOTE_LOADING = 'QUOTE_LOADING'
const QUOTE_LOADED = 'QUOTE_LOADED'
const QUOTE_LIKE = 'QUOTE_LIKE'
export const QUOTE_LIKED = 'QUOTE_LIKED'
const QUOTE_REMOVE = 'QUOTE_REMOVE'
export const QUOTE_REMOVED = 'QUOTE_REMOVED'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
  LikeToggleProps,
} from 'client/bookmate/reducers/comments-reducer'

const entitiesSelector = state => state.entities
const idSelector = (_state, id) => id

export function getQuotesByIds(state, ids) {
  return ids.map(id => getQuoteById(state, id))
}

export const getQuoteById = createSelector(
  idSelector,
  entitiesSelector,
  (id, entities) => denormalize(id, quoteSchema, entities),
)

function getGroupedQuote(state, { id, count }) {
  return {
    quote: getQuoteById(state, id),
    count,
  }
}

export function getGroupedQuotes(state, groupedQuotesData) {
  return groupedQuotesData.map(item => getGroupedQuote(state, item))
}

export function load(quoteId, params = {}) {
  return async (dispatch, getState) => {
    const quote = getQuoteById(getState(), quoteId)
    const { ignoreError = false } = params

    if (quote) return

    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/quotes/${quoteId}`,
        schema: quoteSchema,
        responseKey: 'quote',
        types: [QUOTE_LOADING, QUOTE_LOADED],
        ignoreError,
      },
    })
  }
}

export function remove(uuid, bookUuid, cb = noop) {
  return {
    [CALL_API]: {
      endpoint: `/p/a/4/m/${uuid}`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      onSuccess: dispatch => {
        cb() // will redirect to the book page if needed
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'quote',
            object_id: uuid,
          }),
        )
      },
      types: [QUOTE_REMOVE, QUOTE_REMOVED],
    },
    uuid,
    bookUuid,
  }
}

function toggleLike({ uuid, liked }: LikeToggleProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/quotes/${uuid}/likers`,
      options: {
        method: liked ? 'delete' : 'post',
        dontParse: true,
      },
      onSuccess: dispatch => {
        dispatch(
          analyticsEvent(LIKE_BUTTON_PRESSED, {
            object_type: 'quote',
            object_id: uuid,
          }),
        )
      },
      types: [QUOTE_LIKE, QUOTE_LIKED],
    },
    uuid,
    liked,
  }
}

export const throttledToggleLike = throttledLikeHelper(toggleLike, QUOTE_LIKED)

const initialState = {}

export default function quotes(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'quotes')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }

  switch (action.type) {
    case QUOTE_REMOVED:
      return omit(state, action.uuid)

    case QUOTE_LIKED: {
      const quote = state[action.uuid]
      if (typeof action.likes_count !== 'number') return state

      return {
        ...state,
        [action.uuid]: {
          ...quote,
          likes_count: action.liked
            ? action.likes_count - 1
            : action.likes_count + 1,
          liked: !action.liked,
        },
      }
    }

    case COMMENT_ADDED:
    case COMMENT_REMOVED: {
      const quote = state[action.resourceUuid]

      if (!quote) return state

      return {
        ...state,
        [action.resourceUuid]: {
          ...quote,
          comments_count: Math.max(
            quote.comments_count + (action.type === COMMENT_ADDED ? 1 : -1),
            0,
          ),
        },
      }
    }

    default:
      return state
  }
}

import React, { Component } from 'react'

import SVGInline from 'react-svg-inline'

import './back-button.styl'

import CloseIcon from 'client/shared/icons/close.svg'

type Props = {
  kind?: 'default' | 'white' | 'black' | 'without-avatar'
  clickHandler: () => void
  children: React.ReactNode
}

class BackButton extends Component<Props> {
  render(): JSX.Element {
    const { kind, children, clickHandler } = this.props
    const kindMod = kind ? `back-button_${kind}` : ''

    return (
      <div className={`back-button ${kindMod}`}>
        {children}
        <SVGInline
          svg={CloseIcon}
          className="back-button__button"
          onClick={clickHandler}
        />
      </div>
    )
  }
}

export default BackButton

import { schema } from 'normalizr'
import merge from 'lodash/merge'

import { quoteSchema } from 'client/bookmate/reducers/quotes-reducer'
import {
  userSchema,
  bookSchema,
  shelfSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import { impressionSchema } from 'client/bookmate/reducers/impressions-reducer'
import { postSchema } from 'client/bookmate/reducers/posts-reducer'

export const START_READING = 'startReading'
export const FINISH_READING = 'finishedReading'
export const BOOK = 'book'
export const SERIES = 'series'
export const EXTERNAL_LINK = 'external_link'
const BOOKS = 'books'
export const USER = 'user'
const USERS = 'users'
export const QUOTE = 'quote'
const QUOTES = 'quotes'
export const IMPRESSION = 'impression'
const IMPRESSIONS = 'impressions'
export const SHELF = 'shelf'
const SHELVES = 'shelves'
export const POST = 'post'
const POSTS = 'posts'
export const LIKE = 'like'

const feedEntrySchema = new schema.Entity(
  'feedEntries',
  {},
  {
    idAttribute: entry => entryIdGenerator(entry),
  },
)

const resourcesSchema = new schema.Array(
  {
    [BOOKS]: bookSchema,
    [QUOTES]: quoteSchema,
    [IMPRESSIONS]: impressionSchema,
    [SHELVES]: shelfSchema,
    [POSTS]: postSchema,
  },
  (input, parent) => pluralizeResourceName(parent.resourceType),
)

const activistsSchema = new schema.Array(
  {
    [SHELVES]: shelfSchema,
    [USERS]: userSchema,
  },
  input => pluralizeResourceName(input.activistType),
)

feedEntrySchema.define({
  resources: resourcesSchema,
  activists: activistsSchema,
})

export const feedEntriesSchema = new schema.Array(feedEntrySchema)

function entryIdGenerator(entry) {
  return `${entry.type}-${entry.resources[0].uuid}`
}

function pluralizeResourceName(name) {
  if (name === SHELF) {
    return SHELVES
  } else {
    return `${name}s`
  }
}

const initialState = {}

export default function feedEntries(state = initialState, action) {
  if (action.entities) {
    return merge({}, state, action.entities.feedEntries)
  }
  switch (action.type) {
    default:
      return state
  }
}

const FAKE_PAGE_SHOW = 'FAKE_PAGE_SHOW'
const FAKE_PAGE_HIDE = 'FAKE_PAGE_HIDE'

type ShowAction = {
  type: 'FAKE_PAGE_SHOW'
  page: string
  params?: { theme: string }
}

type HideAction = {
  type: 'FAKE_PAGE_HIDE'
}

type Action = ShowAction | HideAction

type State = {
  hidden: boolean
  page: string | null | undefined
}

export function show(page: string, params?: { theme?: string; type?: string }) {
  return {
    type: FAKE_PAGE_SHOW,
    page,
    params,
  }
}

export function hide() {
  return {
    type: FAKE_PAGE_HIDE,
  }
}

const initialState: State = {
  hidden: true,
  page: null,
}

export default function fakePage(state: State = initialState, action: Action) {
  switch (action.type) {
    case FAKE_PAGE_SHOW:
      return {
        hidden: false,
        page: action.page,
        params: action.params,
      }

    case FAKE_PAGE_HIDE:
      return initialState

    default:
      return state
  }
}

import get from 'lodash/get'

import { isPasswordValid } from 'client/bookmate/helpers/user-helpers'

import { State } from 'shared/types/redux'

function areSignupConditionsMet(state: State) {
  const {
    auth: {
      preSignupFields: { acceptTermsOfService, isOfLegalAge },
    },
  } = state
  return acceptTermsOfService && isOfLegalAge
}

// whether to show the "I agree to receive emails from Bookmate" checkbox
export function shouldShowPreSignupEmailsField(state: State) {
  // user is signing up via email
  return (
    get(state, 'auth.currentStep') === 'email' || // user is signing up via social network; their email was picked up by our api
    get(state, 'auth.socialAuth.email_provided') || // we got user's email from the social network and will send it to the api
    Boolean(get(state, 'auth.socialAuth.rawOauthResponse.email'))
  )
}

export function shouldShowPreSignupFields(state: State) {
  return !state.auth.userIsRegistered && state.auth.flow === 'signup'
}

export function shouldAllowAuth(state: State) {
  const { auth: authState } = state
  const blockedBySignupConditions =
    authState.flow === 'signup' && !areSignupConditionsMet(state)
  const blockedByPassword =
    authState.currentStep === 'email' && !isPasswordValid(authState.password)

  const blockedBySMSCode =
    authState.currentStep === 'codeSent' && !authState.smsCode.length

  return !blockedBySignupConditions && !blockedByPassword && !blockedBySMSCode
}

export function isUserRegistered(state: State) {
  return state.auth.userRegistrationChecked && state.auth.userIsRegistered
}

export function isUserRegistrationChecked(state: State) {
  return state.auth.userRegistrationChecked
}

import React, { Component } from 'react'
import { push } from 'react-router-redux'
import SVGInline from 'react-svg-inline'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'
import cn from 'classnames'

import './header.styl'

import deviceHelper from 'shared/tools/device-helper'
import urlFor from 'shared/tools/url-helper'
import HeaderAvatarBox from 'client/shared/boxes/header-avatar-box'

import Linka from 'client/shared/blocks/linka'
import Logo, { LogoKind } from 'client/shared/blocks/logo'
import LanguagesButtonBox from 'client/shared/boxes/languages-button-box'
import LoginButton from 'client/shared/blocks/login-button'
import SubscriptionButton from 'client/shared/blocks/subscription-button'
import Spacer from 'client/shared/blocks/spacer'

import GiftIcon from 'client/shared/icons/gift.svg'
import BookIcon from 'client/shared/icons/book.svg'
import SearchIcon from 'client/shared/icons/search.svg'
import AccountIcon from 'client/shared/icons/account.svg'
import { show as showPopup } from 'client/shared/reducers/popup-reducer'

import { Location, Route, Router } from 'client/shared/types/react-router'
import { CurrentUserState } from 'client/shared/types/current-user'
import { withRouter } from 'react-router'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { HeaderNavigation } from './header-nav'
import { HeaderNavigationMobile } from './header-nav-mobile'
import { Navigation } from 'client/shared/types/showcase'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'
import { isResourcePage } from 'client/bookmate/helpers/url-check-helpers'
import { connect } from 'react-redux'
import {
  getAllProducts,
  getFamilySubscriptionMembers,
} from 'client/bookmate/selectors/subscription-selectors'
import { FamilyMember } from '../../types/subscription'
import isEmpty from 'lodash/isEmpty'
import { BookmateRunIcon } from './bookmate-run-icon'
import { isSalinas } from 'client/shared/reducers/salinas-reducer'
import {
  HeaderType,
  SimpleHeader,
} from 'client/bookmate/blocks/salinas/salinas-header'

type Props = {
  dispatch: Dispatch
  app: AppState
  route?: Route
  logoKind?: LogoKind
  router?: Router
  user: CurrentUserState
  hasSubscription?: boolean
  onNotificationsHide: () => void
  giftsShown?: boolean
  location: Location
  showcaseType: Navigation[]
  hideSubscriptionBoxAt?: string[]
  size?: string
  fixed?: boolean
  custom?: 'family'
  familyMembers: FamilyMember[]
} & withI18nProps

type State = {
  isScrollActive: boolean
}

class Header extends Component<Props, State> {
  static defaultProps = {
    giftsShown: false,
  }

  state = { isScrollActive: false }

  pushToSearchAll = (query: string) => {
    this.props.dispatch(
      push(urlFor.searchAll(query, this.props.router?.location?.query)),
    )
  }

  handleSearchFocus = () => {
    this.props.dispatch(push(urlFor.search(this.props.router?.location?.query)))
  }

  handleScroll = (): void => {
    this.setState({ isScrollActive: window.pageYOffset > 0 })
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll)
  }

  isSalinasUser = (): boolean =>
    isSalinas(this.props.user.data.company_subscription || '')

  render() {
    const {
      size,
      hasSubscription,
      fixed,
      custom,
      router: {
        location: { pathname, query },
      },
      hideSubscriptionBoxAt,
      logoKind,
    } = this.props

    const isMobileSize = deviceHelper.isMobileSize({ size })

    const isOnResourcePage = isResourcePage(pathname)

    const hideOnScroll = this.state.isScrollActive && isOnResourcePage

    const hideMobileSubscriptionBanner =
      hideSubscriptionBoxAt?.some(path => pathname.includes(path)) ||
      hideOnScroll ||
      this.isSalinasUser()

    const shouldShowExtraSpacer =
      isOnResourcePage && !hasSubscription && isMobileSize

    return (
      <>
        <div
          className={cn('header', {
            header_narrow: hasSubscription || hideMobileSubscriptionBanner,
            header_fixed: fixed,
          })}
        >
          {this.isSalinasUser() ? (
            <SimpleHeader type={'white' as HeaderType} query={query} />
          ) : (
            <div className="header__controls header__controls_left">
              <Logo href={`${urlFor.root(query)}`} kind={custom || logoKind} />
            </div>
          )}
          {!isMobileSize && this.renderNav()}
          {isMobileSize
            ? this.renderMobileRightControls()
            : this.renderDesktopRightControls()}
        </div>
        {shouldShowExtraSpacer && <Spacer size={8} />}
      </>
    )
  }

  renderNav() {
    const {
      user: { auth, data: userData },
      route,
      giftsShown,
      showcaseType,
      size,
      location,
      custom,
    } = this.props

    const shouldShowGifts = giftsShown && !this.isSalinasUser()
    const navCustomMode = custom ? `header__nav_custom-${custom}` : ''

    return (
      <div className={`header__nav ${navCustomMode}`}>
        <div className="header__nav-col">
          <HeaderNavigation
            kind={custom}
            {...{
              query: this.props.router?.location?.query,
              location,
              auth,
              userData,
              route,
              giftsShown: shouldShowGifts,
              showcaseType,
              isTabletSize:
                deviceHelper.isTabletSize({ size: size as string }) ||
                deviceHelper.isDesktopSize({ size: size as string }),
            }}
          />
        </div>
      </div>
    )
  }

  renderMobileRightControls() {
    const {
      dispatch,
      user: { data: userData, completelyLoaded, auth },
      hasSubscription,
      router,
    } = this.props

    return !auth ? (
      <div className="header__controls header__controls_right">
        <BookmateRunIcon />
        {!hasSubscription && !this.isSalinasUser() && completelyLoaded && (
          <SubscriptionButton context="header" kind="small" />
        )}
        <span
          className="header__nav-link"
          onClick={() => dispatch(showPopup())}
        >
          <SVGInline
            className="header__icon header__nav-link__login"
            svg={AccountIcon}
          />
        </span>
        <Linka
          className="header__nav-link"
          activeClassName="header__nav-link_active"
          path={urlFor.search(router?.location?.query)}
        >
          <SVGInline className="header__icon" svg={SearchIcon} />
        </Linka>
        <HeaderNavigationMobile dispatch={dispatch} />
      </div>
    ) : (
      <div className="header__controls header__controls_right">
        <div className="header__icons">
          <BookmateRunIcon />
          <Linka
            className="header__nav-link"
            activeClassName="header__nav-link_active"
            path={urlFor.userBooksAll(userData.login, router?.location?.query)}
          >
            <SVGInline className="header__icon" svg={BookIcon} />
          </Linka>
          <Linka
            className="header__nav-link"
            activeClassName="header__nav-link_active"
            path={urlFor.search(router?.location?.query)}
          >
            <SVGInline className="header__icon" svg={SearchIcon} />
          </Linka>
        </div>
        <div className="header__nav-link">
          <HeaderAvatarBox />
        </div>
        <HeaderNavigationMobile dispatch={dispatch} />
      </div>
    )
  }

  renderDesktopRightControls() {
    const {
      user: { auth, completelyLoaded },
      hasSubscription,
      custom,
      familyMembers,
      router,
    } = this.props
    const hasFamilySubscription = !isEmpty(familyMembers)

    return (
      <div className="header__controls header__controls_right">
        {custom === 'family' && (
          <Linka
            className="header__nav-link"
            activeClassName="header__nav-link_active"
            path={urlFor.gifts(router?.location?.query)}
          >
            <SVGInline className="header__icon" svg={GiftIcon} />
          </Linka>
        )}
        <BookmateRunIcon
          size={!hasSubscription && completelyLoaded ? 33 : 40}
        />
        {!hasFamilySubscription && custom === 'family' && completelyLoaded && (
          <div className="header__nav-link header__nav-link_right">
            <SubscriptionButton context="header" kind="small" custom={custom} />
          </div>
        )}
        {!hasSubscription &&
          !router.location.pathname.includes('code') &&
          custom !== 'family' &&
          completelyLoaded &&
          !this.isSalinasUser() && (
            <div className="header__nav-link header__nav-link_right margined">
              <SubscriptionButton
                context="header"
                kind="small"
                custom={custom}
              />
            </div>
          )}
        <div className="header__nav-link header__nav-link_right">
          {!auth && <LoginButton />}
          {auth && (
            <div className="header__avatar">
              <HeaderAvatarBox />
            </div>
          )}
        </div>
        {
          <div className="header__nav-link header__nav-link_right">
            <LanguagesButtonBox />
          </div>
        }
      </div>
    )
  }
}

const connectWrapper = connect((state: ConnectedState) => {
  return {
    familyMembers: getFamilySubscriptionMembers(state),
    products: getAllProducts(state),
  }
})

const wrappers = compose(connectWrapper, withI18n({ update: true }), withRouter)

export default wrappers(Header)

import pick from 'lodash/fp/pick'
import compact from 'lodash/compact'
import sortBy from 'lodash/fp/sortBy'
import last from 'lodash/fp/last'
import get from 'lodash/fp/get'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'
import isUndefined from 'lodash/isUndefined'

import { CALL_API } from 'shared/middlewares/api-middleware'
import { redirectTo } from 'client/shared/helpers/redirect-helpers'
import urlHelper from 'shared/tools/url-helper'
import { pluralizeObjectTypeName } from 'client/bookmate/helpers/showcase-helpers'

import { AudiobookProps } from 'client/shared/types/audiobook'
import { BookProps } from 'client/shared/types/book'
import { ComicbookProps } from 'client/shared/types/comicbook'

const TOPIC_INFO_LOAD = 'TOPIC_INFO_LOAD'
const TOPIC_INFO_LOAD_SUCCESS = 'TOPIC_INFO_LOAD_SUCCESS'
const TOPIC_LIST_LOAD = 'TOPIC_LIST_LOAD'
const TOPIC_LIST_LOAD_SUCCESS = 'TOPIC_LIST_LOAD_SUCCESS'
const TOPIC_UPDATE_PAGE = 'TOPIC_UPDATE_PAGE'

export type State = {
  resources: {
    books: BookProps[]
    audiobooks: AudiobookProps[]
    comicbooks: ComicbookProps[]
  }
  resourcesInfo: {
    books: number
    audiobooks: number
    comicbooks: number
  }
  pages: {
    books: number
    audiobooks: number
    comicbooks: number
  }
  pagesAmount: {
    books: null | number
    audiobooks: null | number
    comicbooks: null | number
  }
  uuid: string
  contentType?: string
  rewriteUrl?: string
}

const getInfo = pick([
  'hierarchy',
  'is_subtopic',
  'title',
  'subtopics',
  'slug',
  'language',
  'uuid',
])

const getTheMostCommonResourceType = compose(
  get(['content_type']),
  last,
  sortBy('count'),
)

const normalizeAuthors = map(({ authors_objects, authors, ...rest }) => ({
  ...rest,
  authors: isUndefined(authors_objects) ? authors : authors_objects,
}))

const modifyData = _topic => ({
  ...getInfo(_topic),
  resourcesInfo: _topic.resources.reduce(
    (result, _resource) => ({
      ...result,
      [pluralizeObjectTypeName(_resource.content_type)]: _resource.count,
    }),
    {},
  ),
  resources: _topic.resources.reduce(
    (result, item) => ({
      ...result,
      [pluralizeObjectTypeName(item.content_type)]: normalizeAuthors(
        item.resources,
      ),
    }),
    {},
  ),
})

export function loadTopicInfoFromQuery(query) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/catalog/compatibility/?topic_title=${query}`,
      modifyResponse: ({ topic: topicData }) => {
        const resource = getTheMostCommonResourceType(topicData.resources)
        const urls = urlHelper.topicHierarchy({
          contentType: resource,
          ...topicData.hierarchy.reduce(
            (result, data) => ({
              ...result,
              [`${data.is_subtopic ? 'sub' : ''}topic`]: get(['slug'], data),
            }),
            {},
          ),
        })

        return {
          data: {
            ...modifyData(topicData),
            contentType: resource,
            rewriteUrl: topicData.is_subtopic ? urls.subtopic : urls.topic,
          },
        }
      },
      onSuccess: (dispatch, _getState, { data }) => {
        redirectTo(`/${data.contentType}s${data.rewriteUrl.slice(10)}`, 301)
      },
      types: [TOPIC_INFO_LOAD, TOPIC_INFO_LOAD_SUCCESS],
    },
  }
}

export function loadTopicInfo({
  topic: _topic,
  subtopic,
  resource,
  page,
  per_page = 20,
}) {
  let data = {
    page,
    per_page,
  }

  if (resource !== 'mixed' && !Boolean(subtopic)) {
    data = {
      ...data,
      resources_type: pluralizeObjectTypeName(resource),
    }
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/catalog/${compact([_topic, subtopic]).join('/')}`,
      options: {
        data,
      },
      modifyResponse: ({ topic: topicData }) => ({
        data: {
          page,
          per_page,
          contentType: resource,
          ...modifyData(topicData),
        },
      }),
      types: [TOPIC_INFO_LOAD, TOPIC_INFO_LOAD_SUCCESS],
    },
  }
}

export function loadTopicResources({
  topic: _topic,
  subtopic,
  resource,
  page,
  per_page = 20,
  pagination,
  order_direction = 'desc',
  order_by = 'popularity',
}) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/catalog/${compact([_topic, subtopic]).join(
        '/',
      )}/${pluralizeObjectTypeName(resource)}`,
      options: {
        data: {
          page,
          per_page,
          order_by,
          order_direction,
        },
      },
      modifyResponse: response => ({
        data: {
          resources: normalizeAuthors(
            response[pluralizeObjectTypeName(resource)],
          ),
          contentType: resource,
          pagination,
          per_page,
          page,
        },
      }),
      types: [TOPIC_LIST_LOAD, TOPIC_LIST_LOAD_SUCCESS],
    },
  }
}

export function topicUpdatePage(page, contentType) {
  return {
    type: TOPIC_UPDATE_PAGE,
    data: {
      page,
      contentType,
    },
  }
}

const initialState = {
  resources: {
    books: [],
    audiobooks: [],
    comicbooks: [],
  },
  resourcesInfo: {
    books: 0,
    audiobooks: 0,
    comicbooks: 0,
  },
  pages: {
    books: 1,
    audiobooks: 1,
    comicbooks: 1,
  },
  pagesAmount: {
    books: null,
    audiobooks: null,
    comicbooks: null,
  },
}

export default function topic(state = initialState, action = {}) {
  switch (action.type) {
    case TOPIC_INFO_LOAD:
      return initialState

    case TOPIC_INFO_LOAD_SUCCESS: {
      const { contentType, resourcesInfo, page, per_page } = action.data
      const isMixed = contentType === 'mixed'

      const pagesAmount = isMixed
        ? state.pagesAmount
        : {
            ...state.pagesAmount,
            [pluralizeObjectTypeName(contentType)]: Math.min(
              Math.ceil(
                resourcesInfo[pluralizeObjectTypeName(contentType)] / per_page,
              ),
              500,
            ),
          }
      const updatedPages = isMixed
        ? state.pages
        : {
            ...state.pages,
            [pluralizeObjectTypeName(contentType)]: Number(page),
          }

      return {
        ...state,
        ...getInfo(action.data),
        ...(contentType ? { contentType } : {}),
        resourcesInfo,
        pagesAmount,
        resources: {
          ...initialState.resources,
          ...action.data.resources,
        },
        pages: updatedPages,
      }
    }
    case TOPIC_LIST_LOAD_SUCCESS: {
      const { page, pagination, contentType } = action.data
      const { pagesAmount } = state

      return {
        ...state,
        resources: {
          ...state.resources,
          [pluralizeObjectTypeName(contentType)]: pagination
            ? action.data.resources
            : [
                ...state.resources[pluralizeObjectTypeName(contentType)],
                ...action.data.resources,
              ],
        },
        ...(isNaN(pagesAmount[pluralizeObjectTypeName(contentType)])
          ? {}
          : {
              pages: {
                ...state.pages,
                [pluralizeObjectTypeName(contentType)]: Number(page),
              },
            }),
      }
    }
    case TOPIC_UPDATE_PAGE: {
      return {
        ...state,
        pages: {
          ...state.pages,
          [pluralizeObjectTypeName(action.data.contentType)]: action.data.page,
        },
      }
    }
    default:
      return state
  }
}

import { CALL_API } from 'shared/middlewares/api-middleware'

const SITEMAP_PAGE_LOAD = 'SITEMAP_PAGE_LOAD'
const SITEMAP_PAGE_LOAD_SUCCESS = 'SITEMAP_PAGE_LOAD_SUCCESS'

type Item = {
  title: string
  uuid: string
}

type Meta = {
  total_pages: number
}

export type State = {
  items: Item[]
  meta: Meta
  loading: boolean
}

type LoadPageAction = {
  type: typeof SITEMAP_PAGE_LOAD
  items: Item[]
  meta: Meta
}

type Action = LoadPageAction

export function loadPage(resourceType: string, page = 1, pp? = 48) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/sitemaps/${resourceType}`,
      options: {
        data: {
          per_page: pp,
          page,
        },
      },
      types: [SITEMAP_PAGE_LOAD, SITEMAP_PAGE_LOAD_SUCCESS],
    },
  }
}

const initialState = {
  items: [],
  meta: {
    total_pages: 0,
  },
  loading: true,
}

export default function sitemap(state: State = initialState, action: Action) {
  switch (action.type) {
    case SITEMAP_PAGE_LOAD:
      return {
        ...state,
        loading: true,
      }

    case SITEMAP_PAGE_LOAD_SUCCESS:
      return {
        ...state,
        items: action.items,
        meta: action.meta,
        loading: false,
      }

    default:
      return state
  }
}

import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import deviceHelper from 'shared/tools/device-helper'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { ArrowButton } from 'client/shared/blocks/arrow-button'
import HorizontalScroller from 'client/shared/blocks/horizontal-scroller'
import ViewPager from 'client/shared/boxes/view-pager-box'
import { Frame, Track, View } from 'client/shared/blocks/pager'

import './slider.styl'

type HeightProps = {
  big: number
  small: number
}

type Props = {
  infinite: boolean
  components: Array<Node>
  height?: HeightProps
} & DecoratorProps

type State = {
  current: number
}

class Slider extends Component<Props, State> {
  static defaultProps = {
    infinite: true,
  }

  state = {
    // eslint-disable-next-line react/no-unused-state
    current: 0,
  }

  slider = null

  showPrev = () => {
    if (this.slider) this.slider.prev()
  }

  showNext = () => {
    if (this.slider) this.slider.next()
  }

  goTo = index => {
    if (this.slider) this.slider.scrollTo(index)
  }

  updateActiveIndex = indices => {
    const newCurrentIndex = indices[0]
    // eslint-disable-next-line react/no-unused-state
    this.setState({ current: newCurrentIndex })
  }

  getHeight() {
    const { app, height } = this.props

    const desktopStyle = isEmpty(height) ? {} : { height: `${height.big}px` }
    const mobileStyle = isEmpty(height) ? {} : { height: `${height.small}px` }

    return deviceHelper.isMobileSize(app) ? mobileStyle : desktopStyle
  }

  render() {
    const { app } = this.props

    return (
      <div className="slider" style={this.getHeight()}>
        {deviceHelper.isMobileSize(app) || deviceHelper.isMobileOS(app)
          ? this.renderMobileBlock()
          : this.renderDesktopBlock()}
      </div>
    )
  }

  renderDesktopBlock() {
    return (
      <div className="slider__wrapper" style={this.getHeight()}>
        <div className="slider__btn slider__btn_prev">
          <ArrowButton kind="prev" type="big" onClick={this.showPrev} />
        </div>
        {this.renderDesktopSlider()}
        <div className="slider__btn slider__btn_next">
          <ArrowButton kind="next" type="big" onClick={this.showNext} />
        </div>
      </div>
    )
  }

  renderMobileBlock() {
    const { components } = this.props

    return (
      <div className="slider__mobile-wrapper">
        <HorizontalScroller>{components}</HorizontalScroller>
      </div>
    )
  }

  renderDesktopSlider() {
    const { components, infinite } = this.props

    return (
      <ViewPager>
        <Frame className="slider__frame">
          <Track
            ref={component => (this.slider = component)}
            viewsToShow={1}
            infinite={infinite}
            className="slider__track"
          >
            {components.map((component, index) => (
              <View key={index}>{component}</View>
            ))}
          </Track>
        </Frame>
      </ViewPager>
    )
  }
}

export default uiBox(Slider)

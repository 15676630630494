import { normalize, denormalize } from 'normalizr'
import { CALL_API } from 'shared/middlewares/api-middleware'
import { cacheAction } from 'shared/middlewares/cache-middleware'

import {
  postsSchema,
  preparePostsFromAPIResponse,
  POST_ADDED,
  POST_REMOVED,
  POST_LIKED,
  POST_CHANGED,
} from 'client/bookmate/reducers/posts-reducer'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
} from 'client/bookmate/reducers/comments-reducer'

const POSTS_LOAD = 'POSTS_LOAD'
const POSTS_LOAD_SUCCESS = 'POSTS_LOAD_SUCCESS'

export const loadPosts = cacheAction(
  function (uuid) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/bookshelves/${uuid}/posts`,
        options: {
          data: {
            page: 1,
            per_page: 80,
          },
        },
        normalize: response => {
          const { result, entities } = normalize(
            preparePostsFromAPIResponse(response.posts),
            postsSchema,
          )

          return {
            shelf: {
              uuid,
              posts: result,
            },
            entities,
          }
        },
        types: [POSTS_LOAD, POSTS_LOAD_SUCCESS],
      },
    }
  },
  POSTS_LOAD_SUCCESS,
  [
    POST_LIKED,
    POST_ADDED,
    POST_CHANGED,
    POST_REMOVED,
    COMMENT_ADDED,
    COMMENT_REMOVED,
  ],
)

export function getShelfPosts(state, shelfId) {
  const shelf = state.specials.shelves[shelfId]

  return shelf ? denormalize(shelf.posts, postsSchema, state.entities) : []
}

const initialState = {
  loading: false,
  error: null,
  shelves: {},
}

export default function specials(state = initialState, action) {
  switch (action.type) {
    case POSTS_LOAD_SUCCESS:
      return {
        ...state,
        shelves: {
          ...state.shelves,
          [action.shelf.uuid]: action.shelf,
        },
      }

    default:
      return state
  }
}

import React, { Component, createElement } from 'react'
import pickBy from 'lodash/pickBy'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

import { ViewPagerContext } from 'client/bookmate/helpers/view-pager-helper'

import Pager from './pager'

function pickComparableProps(props) {
  return pickBy(props, (value, key) => !['children', 'pager'].includes(key))
}

type Props = {
  tag: string | null | undefined
  pager: Pager
}

class View extends Component<Props> {
  static defaultProps = {
    tag: 'div',
  }

  viewAdded = false
  viewInstance = null
  styles = {}

  shouldComponentUpdate(nextProps) {
    nextProps = pickComparableProps(nextProps)
    const currentProps = pickComparableProps(this.props)
    return (
      !isEqual(nextProps, currentProps) ||
      !isEqual(this.getStyles(), this.styles)
    )
  }

  componentDidMount() {
    this.viewInstance = this.props.pager.addView(this.element)
    this.viewAdded = true
    this.setStyles()
    this.forceUpdate()
  }

  componentDidUpdate() {
    this.setStyles()
  }

  componentWillUnmount() {
    this.props.pager.removeView(this.viewInstance)
  }

  setStyles() {
    this.styles = this.getStyles()
  }

  getStyles() {
    return this.viewInstance ? this.viewInstance.getStyles() : {}
  }

  render() {
    const { tag, ...restProps } = this.props
    const style = {
      ...restProps.style,
      ...this.getStyles(),
    }

    // hide view visually until it has been added to the pager
    // this should help avoid FOUC
    if (!this.viewAdded) {
      style.visibility = 'hidden'
      style.pointerEvents = 'none'
    }

    return createElement(tag, {
      ...omit(restProps, 'pager'),
      style,
      ref: element => (this.element = element),
    })
  }
}

export default props => (
  <ViewPagerContext.Consumer>
    {({ pager }) => <View {...props} pager={pager} />}
  </ViewPagerContext.Consumer>
)

import React, { ComponentType } from 'react'

function RefsForwarder(Component: ComponentType<any>) {
  return React.forwardRef((props, ref) => {
    const refProps = {}
    if (typeof ref === 'function') {
      refProps.forwardedRef = ref
    }
    return <Component {...props} {...refProps} />
  })
}

export default RefsForwarder

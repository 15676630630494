import React from 'react'

export const BookmateRunIcon: React.FC<{ size?: number }> = ({ size = 33 }) => (
  <a
    href="/bookmate-run"
    target="_blank"
    className={`header-icon_bookmate-run_${size}`}
  >
    <img
      src="https://a.bmstatic.com/iu/10/4/e866e57d-fb9c-4e1f-8572-0e4f778ffb28-332134da75af9d3b6af54407515f7282.png"
      alt="bookmate game"
    />
  </a>
)

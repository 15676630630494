import config from 'config'
import dedent from 'dedent'
import { CookieConsent } from 'shared/tools/cookie-helper'

export function getTikTokAnalytics(): string {
  return `!function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
    ttq.load('CH4OT9JC77U2I5R8IT10');
    ttq.page();
  }(window, document, 'ttq');`
}

export function getTagManagerScript(): string {
  return dedent`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${config.googleTagManagerId}');
  `
}

export function customizeTagManagerScript(
  softPolicy: boolean,
  consentObj: CookieConsent,
): string {
  let marketingConsent = 'granted'
  let performanceConsent = 'granted'
  if (!softPolicy) {
    marketingConsent = consentObj
      ? consentObj.cookieMarketing
        ? 'granted'
        : 'denied'
      : 'denied'
    performanceConsent = consentObj
      ? consentObj.cookiePerformance
        ? 'granted'
        : 'denied'
      : 'denied'
  }

  return dedent`
    window.dataLayer = window.dataLayer || [];
    function gtag() {dataLayer.push(arguments)};
    gtag('consent', 'default', {'ad_storage': '${marketingConsent}','analytics_storage': '${performanceConsent}', wait_for_update: 5000});
  `
}

export function pushCookieConsentUpdateEvent(): void {
  window.dataLayer.push({ event: 'Consent Update' })
}

export function invokeTagManagerConsentUpdateScript(
  marketing = false,
  performance = false,
): void {
  if (isGtagInitialized()) {
    const marketingConsent = marketing ? 'granted' : 'denied'
    const performanceConsent = performance ? 'granted' : 'denied'
    const updatedConsent = {
      ad_storage: marketingConsent,
      analytics_storage: performanceConsent,
    }

    window.gtag('consent', 'update', updatedConsent)
  }
}

function isGtagInitialized(): boolean {
  return Boolean(window.gtag)
}

export function appendFacebookPixel(): void {
  const script = document.createElement('script')

  script.async = true
  script.type = 'text/javascript'
  script.text = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod? \
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n; \
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0; \
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, \
    document,'script','//connect.facebook.net/en_US/fbevents.js'); \
    fbq('init', '${config.facebookPixelId}'); \
    fbq('track', 'PageView');`

  document.body.appendChild(script)
}

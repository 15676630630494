import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import CloseIcon from 'client/shared/icons/close.svg'
import SuccessIcon from 'client/shared/icons/checkmark-circle.svg'
import ErrorIcon from 'client/shared/icons/alert-error.svg'
import FeedbackIcon from 'client/shared/icons/alert-info.svg'
import SVGInline from 'react-svg-inline'

import { AlertKind, hideAlert } from 'client/shared/reducers/alert-reducer'

import './alert.styl'
import { Dispatch } from 'shared/types/redux'

type Props = {
  alert: Record<string, any>
  isMobile: boolean
  dispatch: Dispatch
  html?: string
}

export class Alert extends Component<Props> {
  getMessage(message: string): string {
    switch (message) {
      case 'invalid CSRF token':
        return 'alerts.invalid_session'
      default:
        return message
    }
  }

  getIconKey(kind: AlertKind): string {
    switch (kind) {
      case 'success':
        return SuccessIcon
      case 'error':
        return ErrorIcon
      case 'feedback':
      default:
        return FeedbackIcon
    }
  }

  render(): JSX.Element {
    const { kind, message, values, components, html } = this.props.alert
    const { isMobile, dispatch } = this.props

    const iconKey: string = this.getIconKey(kind)

    return (
      <div className={`alert alert_${kind}`}>
        <div className="alert__theme" />
        <div className="alert__message">
          {!isMobile && (
            <SVGInline className="alert__theme-icon" svg={iconKey} />
          )}
          {html ? (
            <span
              className="alert__message-text"
              dangerouslySetInnerHTML={{ __html: html }}
            ></span>
          ) : (
            <span className="alert__message-text">
              <Trans
                id={this.getMessage(message)}
                values={values}
                components={components}
              />
            </span>
          )}
        </div>
        <span
          className="alert__close-icon"
          onClick={() => dispatch(hideAlert())}
        >
          <SVGInline svg={CloseIcon} />
        </span>
      </div>
    )
  }
}

import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import './checkbox.styl'

import CheckmarkIcon from 'client/shared/icons/mark.svg'

type Props = {
  isDisabled?: boolean
} & React.HTMLProps<HTMLInputElement>

export default class Checkbox extends Component<Props> {
  render(): JSX.Element {
    return (
      <div className="checkbox">
        <input
          type="checkbox"
          name={this.props.name}
          id={this.props.id}
          checked={this.props.checked}
          value={this.props.value || this.props.checked}
          onChange={this.props.onChange}
          disabled={this.props.isDisabled}
          {...this.props}
        />
        <label htmlFor={this.props.id}>
          <span className="checkbox__box">
            <span className="checkbox__checkmark">
              <SVGInline svg={CheckmarkIcon} />
            </span>
          </span>
          <span className="checkbox__label">{this.props.label}</span>
        </label>
      </div>
    )
  }
}

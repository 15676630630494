import { schema } from 'normalizr'
import merge from 'lodash/merge'

export const libraryBannerSchema = new schema.Entity(
  'banners',
  {},
  { idAttribute: 'tag' },
)

const initialState = {}

export default function banners(state = initialState, action) {
  if (action.entities && action.entities.banners) {
    return merge({}, state, action.entities.banners)
  }

  switch (action.type) {
    default:
      return state
  }
}

import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './side-menu.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'

type Props = {
  locale: string
  query?: QueryParams
}
export class SideMenu extends Component<Props> {
  render(): JSX.Element {
    const { locale, query } = this.props

    return (
      <div className="side-menu">
        <ul className="side-menu__links">
          <li className="side-menu__item">
            <Linka
              path={urlFor.about(query)}
              className="side-menu__link"
              activeClassName="side-menu__link_active"
              onlyActiveOnIndex
            >
              <Trans id="side_menu.about" />
            </Linka>
          </li>
          <li className="side-menu__item">
            <Linka
              path={urlFor.press(query)}
              className="side-menu__link"
              activeClassName="side-menu__link_active"
            >
              <Trans id="side_menu.press" />
            </Linka>
          </li>
          <li className="side-menu__item">
            <Linka
              path={urlFor.jobs(query)}
              className="side-menu__link"
              activeClassName="side-menu__link_active"
            >
              <Trans id="side_menu.jobs" />
            </Linka>
          </li>
          <li className="side-menu__item">
            <Linka
              path={urlFor.legal(undefined, query)}
              className="side-menu__link"
              activeClassName="side-menu__link_active"
            >
              <Trans id="side_menu.legal" />
            </Linka>
          </li>
        </ul>
      </div>
    )
  }
}

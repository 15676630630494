import { ReactNode } from 'react'
import pickBy from 'lodash/pickBy'

// import FacebookIcon from 'client/shared/icons/facebook2.svg' hide
import TwitterIcon from 'client/shared/icons/twitter2.svg'
import GoogleIcon from 'client/shared/icons/google.svg'
import YettelIcon from 'client/bookmate/blocks/subscription-logo/assets/yettel.svg'
import AppleIcon from 'client/shared/icons/apple.svg'

export type Provider = {
  name: // | 'facebook' hide
  'twitter' | 'google' | 'telenor_bulgaria' | 'apple' | 'mtn_ghana' | 'yettel'
  title?: string
  icon: ReactNode
  isMore?: boolean
  disabled?: boolean
}

export type ProvidersConditions = {
  [name in
    | 'telenor_bulgaria'
    // | 'facebook' hide
    | 'twitter'
    | 'mtn_ghana'
    | 'yettel']?: boolean
}

const additionalProviders: Provider[] = [
  { name: 'telenor_bulgaria', title: 'Yettel', icon: YettelIcon },
  { name: 'mtn_ghana', title: 'Log in with MTN', icon: '' },
  { name: 'yettel', title: 'Log in with Yettel', icon: '' },
]

export const providers = (conditions?: ProvidersConditions): Provider[] => {
  const providersWithMetConditions = Object.keys(pickBy(conditions, Boolean))
  const restProviders = additionalProviders.filter(provider =>
    providersWithMetConditions.includes(provider.name),
  )

  return [
    ...restProviders,
    /* hide facebook
    {
      name: 'facebook',
      title: 'Facebook',
      icon: FacebookIcon,
      disabled: !conditions?.facebook,
    },*/
    { name: 'google', title: 'Google', icon: GoogleIcon },
    {
      name: 'twitter',
      title: 'Twitter',
      icon: TwitterIcon,
      isMore: true,
      disabled: !conditions?.twitter,
    },
    { name: 'apple', title: 'Apple', icon: AppleIcon, isMore: true },
  ]
}

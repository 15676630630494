import React, { Component, forwardRef, RefObject } from 'react'

import './horizontal-scroller.styl'

type Props = {
  innerRef?: RefObject<HTMLDivElement>
  isReducedSpacing?: boolean
  className?: string
  asList?: boolean
  isSeries?: boolean
}
class HorizontalScroller extends Component<Props> {
  render(): JSX.Element {
    return (
      <ul
        className={`horizontal-scroller ${
          this.props.className ? this.props.className : ''
        }`}
        ref={this.props.innerRef}
      >
        {this.props.asList
          ? this.props.children
          : React.Children.map(this.props.children, child => (
              <li
                className={`horizontal-scroller_item ${
                  this.props.isReducedSpacing ? 'reduced-margins' : ''
                }${this.props.isSeries ? ' series' : ''}`}
              >
                {child}
              </li>
            ))}
      </ul>
    )
  }
}

export default forwardRef((props, ref) => (
  <HorizontalScroller innerRef={ref} {...props} />
))

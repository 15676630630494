import env from 'env'

import { redirectTo } from 'client/shared/helpers/redirect-helpers'

import { EXTERNAL_REDIRECT } from 'client/shared/reducers/app-reducer'

export default function redirectMiddleware() {
  let alreadyRedirected = false

  return next => action => {
    if (action.type !== EXTERNAL_REDIRECT || alreadyRedirected) {
      return next(action)
    } else {
      const { location, status, replace } = action

      if (!location)
        throw new Error('External redirect location should be defined')

      if (env.isServer()) {
        redirectTo(location, status)
      } else {
        window.location[replace ? 'replace' : 'assign'](location)
      }
      alreadyRedirected = true
    }
  }
}

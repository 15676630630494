import React from 'react'
import { Helmet } from 'react-helmet-async'

import env from 'env'
import config from 'config'
import { CookieConsent } from '../../../../shared/tools/cookie-helper'
// import { isLatAmCountry } from 'shared/tools/country-helpers'
// import { getTikTokAnalytics } from 'client/shared/helpers/vendor-trackers-helper'

type UrlType = {
  protocol: string
  host: string
  pathname: string
  query: Record<string, string>
  locale?: string
}

type HeadProps = {
  title: string
  url: UrlType
  lang: string
  gtag: () => string
  locale: string
  alternateLocale: string
  gtagHelper: (a: boolean, b: CookieConsent) => string
  cookiePolicy: boolean
  cookieConsent: CookieConsent
  browser?: string
  country: string
}

type HeadState = {
  htmlClass: string
}
class Head extends React.Component<HeadProps, HeadState> {
  state = {
    htmlClass: 'no-js',
  }

  componentDidMount(): void {
    const flexClass =
      window.CSS && CSS.supports('display', 'flex') ? '' : 'no-flex-wrap'

    this.setState({ htmlClass: `${flexClass}` })
  }

  render(): JSX.Element {
    const {
      title,
      url,
      lang,
      gtag,
      locale,
      alternateLocale,
      gtagHelper,
      cookiePolicy,
      cookieConsent,
      browser,
    } = this.props

    const { htmlClass } = this.state

    // Helmet typings do not include charset and http-equiv in .d.ts
    const metaHelmetUntypedProps = [
      { charset: 'utf-8' },
      { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
    ]

    if (browser === 'IE')
      metaHelmetUntypedProps.push({ 'http-equiv': 'cleartype', content: 'on' })

    const isJobsPage = url.pathname.includes('jobs')
    const appLinksMeta = isJobsPage
      ? []
      : [
          { property: 'fb:app_id', content: '124791507543584' },
          { property: 'al:ios:app_name', content: 'Bookmate' },
          { property: 'al:ios:app_store_id', content: '386177450' },
          { property: 'al:android:app_name', content: 'Bookmate' },
          { property: 'al:android:package', content: 'com.bookmate' },
        ]

    return (
      <Helmet
        htmlAttributes={{ lang, class: htmlClass }}
        title={title}
        meta={[
          ...metaHelmetUntypedProps,
          ...appLinksMeta,
          {
            name: 'google-site-verification',
            content: 'oBqTwVeuzOLy2mFJ8fP6GkyzEODF-xUrC3X6Gdz823s',
          },
          { name: 'yandex-verification', content: 'c58d5367f8bae66e' },
          {
            name: 'viewport',
            content:
              'width=device-width, height=device-height, initial-scale=1.0, maximum-scale=3.0, viewport-fit=cover',
          },
          { name: 'HandheldFriendly', content: 'True' },
          {
            name: 'msapplication-config',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            content: `${ASSETS_PATH}favicons/browserconfig.xml`,
          },
          { name: 'theme-color', content: '#ffffff' },
          { name: 'keywords', content: 'Bookmate' },
          { property: 'twitter:site', content: '@bookmate_ru' },
          {
            property: 'twitter:image',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            content: `${ASSETS_PATH}sharing/main.png?v4`,
          },
          { property: 'twitter:app:name:iphone', content: 'Bookmate' },
          { property: 'twitter:app:id:iphone', content: '386177450' },
          { property: 'twitter:app:name:googleplay', content: 'Bookmate' },
          { property: 'twitter:app:id:googleplay', content: 'com.bookmate' },
          { property: 'og:site_name', content: 'Bookmate' },
          { property: 'og:url', content: generateUrl(url) },
          {
            property: 'og:image',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            content: `${ASSETS_PATH}sharing/main.png?v4`,
          },
          { property: 'og:locale', content: locale },
          { property: 'og:locale:alternate', content: alternateLocale },
          {
            name: 'ahrefs-site-verification',
            content:
              'a2f8cb50a16d3eadea976e3827748aaff260e3e4b2f5e1dc46a72a24fcb4ea24',
          },
        ]}
        link={[
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { rel: 'manifest', href: `${ASSETS_PATH}favicons/manifest.json` },
          {
            rel: 'mask-icon',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href: `${ASSETS_PATH}favicons/safari-pinned-tab.svg`,
            color: '#9D7760',
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          { rel: 'shortcut icon', href: `${ASSETS_PATH}favicons/favicon.ico` },
          ...this.generateFavicons(
            ['16x16', '32x32', '96x96'],
            [
              '57x57',
              '60x60',
              '72x72',
              '76x76',
              '114x114',
              '120x120',
              '144x144',
              '152x152',
              '180x180',
            ],
          ),
          { rel: 'canonical', href: generateUrl(url) },
          ...(env.isProduction() && url.pathname === '/'
            ? getAlternateLinks(url)
            : []),
        ]}
      >
        {!env.getParamAsBoolean('DISABLE_ANALYTICS') && (
          <script async>{gtagHelper(cookiePolicy, cookieConsent)}</script>
        )}
        {!env.getParamAsBoolean('DISABLE_ANALYTICS') && (
          <script async>{gtag()}</script>
        )}
        {/* {!env.getParamAsBoolean('DISABLE_ANALYTICS') &&
          isLatAmCountry(country) && (
            <script async>{getTikTokAnalytics()}</script>
        )} */}
      </Helmet>
    )
  }

  generateFavicons(
    iconSizes: string[],
    appleIconSizes: string[],
  ): Record<string, any>[] {
    const links: Record<string, any>[] = [
      {
        rel: 'apple-touch-icon',
        href: require(`client/shared/assets/favicons/apple-touch-icon.png`),
      },
    ]

    appleIconSizes.forEach(size => {
      links.push({
        rel: 'apple-touch-icon',
        href: require(`client/shared/assets/favicons/apple-touch-icon-${size}.png`),
        sizes: size,
      })
    })

    iconSizes.forEach(size => {
      links.push({
        rel: 'icon',
        href: require(`client/shared/assets/favicons/favicon-${size}.png`),
        sizes: size,
        type: 'image/png',
      })
    })

    return links
  }
}

export default Head

function generateUrl({ protocol, host, pathname, query, locale = '' }) {
  if (locale) {
    // generate url for another language
    const hostSegments = host?.split('.')

    let subdomain = ''
    subdomain = locale === 'en' ? '' : `${locale}.`

    host = config.locales.includes(hostSegments[0])
      ? `${subdomain}${hostSegments.slice(1).join('.')}` // remove locale from host name and add alternative locale
      : `${subdomain}${host}` // just add alternative locale to host name
  }

  query = query.lang ? `?lang=${query.lang}` : ''
  if (host.includes('ru')) host = host.replace('ru', 'rus')
  return `${protocol}://${host}${pathname}${query}`
}

function getBaseHost({ host }) {
  const hostSegments = host?.split('.')
  return config.locales.includes(hostSegments[0])
    ? `${hostSegments.slice(1).join('.')}` // remove locale from host name
    : host // just use host name
}

function getAlternateLinks({ protocol, host }) {
  host = getBaseHost({ host })

  const base = [
    {
      rel: 'alternate',
      hreflang: config.fallbackLocale,
      href: `${protocol}://${host}`,
    },
    { rel: 'alternate', hreflang: 'x-default', href: `${protocol}://${host}` },
  ]

  const mappedLocales = config.locales.filter(_locale => _locale !== 'ru')
  let other = mappedLocales
    .filter(locale => locale !== config.fallbackLocale)
    .map(locale => ({
      rel: 'alternate',
      hreflang: locale,
      href: `${protocol}://${locale}.${host}`,
    }))
  other = [
    ...other,
    {
      rel: 'alternate',
      hreflang: 'ru',
      href: `${protocol}://rus.${host}`,
    },
    {
      rel: 'alternate',
      hreflang: 'en-gh',
      href: `${protocol}://gh.${host}`,
    },
  ]
  return base.concat(other)
}

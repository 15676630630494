export function filterLanguages({ all, available }) {
  if (!available) return []

  if (available.all) {
    return all
  } else if (Array.isArray(available)) {
    return all.filter(lang => available.includes(lang.code))
  } else {
    return []
  }
}

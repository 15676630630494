import React, { Component, ComponentClass } from 'react'
import SVGInline from 'react-svg-inline'
import { Trans, withI18n, withI18nProps } from '@lingui/react'

import urlFor from 'shared/tools/url-helper'
import {
  isFacebookAvailable,
  isMTNAvailable,
  isTelenorAvailable,
  isTwitterAvailable,
  isYettelAvailable,
} from 'client/shared/helpers/socials-helpers'

import { withRouter } from 'react-router'

import LoginFormBox from 'client/shared/boxes/login-form-box'
import SignupCheckboxesBox from 'client/shared/boxes/signup-checkboxes-box'

import Input from 'client/shared/blocks/input'
import Button from 'client/shared/blocks/button'
import Spacer from 'client/shared/blocks/spacer'
import Linka from 'client/shared/blocks/linka'
import AuthProviders from 'client/shared/blocks/auth-providers'
import SocialUserinfoPlate from 'client/shared/blocks/social-userinfo-plate'
import JoinOrLoginText from 'client/shared/blocks/join-or-login-text'

import BackIcon from 'client/shared/icons/back.svg'
import SuccessMarkIcon from 'client/shared/icons/success-mark.svg'

import { AuthProps } from 'client/shared/decorators/with-auth'
import {
  AuthStep,
  markYettel,
  markYettelEnd,
} from 'client/shared/reducers/auth-reducer'
import { CurrentUserData } from 'client/shared/types/current-user'
import { Recaptcha } from 'client/shared/blocks/recaptcha'
import { Timer } from '../timer/timer'
import './auth.styl'
import { RecaptchaNotice } from '../recaptcha/recaptcha-notice'
import { Location } from 'client/shared/types/react-router'
import { ARRIVA } from 'client/bookmate/boxes/code-subscription-box'
import PhoneInput from '../phone-input/phone-input'
import {
  markAuth,
  markEndAuth,
  MNTShortSignInThunk,
  MTNState,
  setMNTPhoneNumberAC,
} from 'client/bookmate/reducers/mtn-reducer'

import cookie from 'react-cookie'
import { Loader } from '../loader/loader'
import compose from 'lodash/fp/compose'
import { loginMode } from '../../../../shared/constants/login.constants'

const LINK_CLASS = 'auth__link'

type SpecialParams = {
  giftCode: string | null | undefined
}

// type typeHeaders = Headers | string[][] | Record<string, string> | undefined

type Props = {
  changeStep: (step: AuthStep, sendAnalytics?: boolean) => void
  currentStep: AuthStep
  initialStep: AuthStep
  data: CurrentUserData
  specialParams?: SpecialParams
  location: Location
  timerRunning?: boolean
  stopTimer?: () => void
  resendCode: () => void
  giftCode?: string
  mtn: MTNState
  yettel?: boolean
  params: Record<string, string>
} & AuthProps &
  withI18nProps

class Auth extends Component<Props> {
  static defaultProps = {
    initialStep: 'social',
  }

  componentDidMount(): void {
    const { location } = this.props

    if (typeof window !== 'undefined') {
      const mtnNumber = cookie.load('mtn_header_phone')

      if (mtnNumber) {
        this.props.dispatch(setMNTPhoneNumberAC(mtnNumber))
      }
    }

    const isMondia = this.props.params.loginmode === loginMode.mondiaPay
    if (isMondia) {
      this.props.changeStep('email', true)
      return
    }

    const isMtnLogin =
      location?.pathname?.includes('mtn') && this.props.mtn?.authInit
    if (!isMtnLogin) return
    this.props.changeStep('phone', true)
  }

  render(): JSX.Element {
    const { initialStep, currentStep } = this.props
    const step = currentStep || initialStep
    return (
      <>
        <Recaptcha />
        <div className="auth">
          {this[`${step}Step`] && this[`${step}Step`]()}
        </div>
        {this.props.mtn?.loading && (
          <div className="overlay">
            <Loader />
          </div>
        )}
      </>
    )
  }

  async MTNAction() {
    this.props.dispatch(markAuth())
    if (this.props.mtn.headerPhone) {
      this.props.dispatch(MNTShortSignInThunk(this.props.mtn.headerPhone))
    } else {
      this.props.changeStep('phone', true)
    }
  }

  yettelAction() {
    this.props.dispatch(markYettel())
    this.props.changeStep('phone', true)
  }
  socialStep(): JSX.Element {
    const {
      changeStep,
      onSocialButtonClick,
      specialParams,
      data,
      giftCode,
    } = this.props

    const isArriva = giftCode?.includes(ARRIVA)

    return (
      <div key="social" className="auth__step">
        <h3 className="auth__header">
          <Trans id="auth.join_or_login" />
        </h3>
        <AuthProviders
          onButtonClick={onSocialButtonClick}
          specialParams={specialParams}
          mtnAction={() => this.MTNAction()}
          yettelAction={() => this.yettelAction()}
          providersConditions={{
            mtn_ghana: isMTNAvailable(data.country),
            telenor_bulgaria: isTelenorAvailable(data.country),
            facebook: isArriva ? false : isFacebookAvailable(data.country),
            twitter: isTwitterAvailable(data.country),
            yettel: isYettelAvailable(data.country),
          }}
        />
        <p className="auth__subaction">
          <span
            className={LINK_CLASS}
            onClick={() => changeStep('email', true)}
          >
            <Trans id="auth.email_placeholder" />
          </span>{' '}
        </p>
      </div>
    )
  }

  socialAuthenticatedStep(): JSX.Element {
    const { changeStep, auth, shouldAllowAuth, loading, error } = this.props
    // this step is active only during signup; so always show signup checkboxes,
    const toEmail = (
      <span className="auth__error-link" onClick={() => changeStep('email')} />
    )
    const errorElement = error && <Trans id={error} components={[toEmail]} />

    return (
      <div key="social-authenticated" className="auth__step">
        <h3 className="auth__header">
          <Trans id="auth.join" />
        </h3>
        <SVGInline
          svg={BackIcon}
          className="auth__back"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => changeStep('social')}
        />
        <SocialUserinfoPlate {...auth.socialAuth} />
        <Spacer />
        <SignupCheckboxesBox />
        <Spacer size={24} />
        <Button kind="primary" disabled={!shouldAllowAuth} loading={loading}>
          <Trans id="buttons.join" />
        </Button>
        {error && this.showError(errorElement)}
      </div>
    )
  }

  emailStep(): JSX.Element {
    const {
      changeStep,
      isUserRegistrationChecked,
      isUserRegistered,
    } = this.props

    const isMondia = this.props.params.loginmode === loginMode.mondiaPay

    return (
      <div key="email" className="auth__step">
        <h3 className="auth__header">
          <JoinOrLoginText
            isChecked={isUserRegistrationChecked}
            isRegistered={isUserRegistered}
            role="header"
          />
        </h3>

        {!isMondia && (
          <SVGInline
            svg={BackIcon}
            className="auth__back"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => changeStep('social')}
          />
        )}
        <LoginFormBox marker={isMondia ? 'mondia' : undefined} />
        <RecaptchaNotice />
      </div>
    )
  }

  resetStep(): JSX.Element {
    const { changeStep, lockSubmit, loading, error, i18n } = this.props

    return (
      <div key="reset" className="auth__step">
        <h3 className="auth__header">
          <Trans id="auth.reset_password" />
        </h3>
        <SVGInline
          svg={BackIcon}
          className="auth__back"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => changeStep('email')}
        />
        <p className="auth__instruction">
          <Trans id="auth.reset_password_instruction" />
        </p>
        <Input
          type="text"
          name="email"
          error={Boolean(error)}
          placeholder={i18n.t`auth.email_placeholder`}
          autoFocus
        />
        {this.showError(error)}
        <Button type="submit" disabled={lockSubmit} loading={loading}>
          <Trans id="auth.reset_password_button" />
        </Button>
        <p className="auth__subaction">
          <Linka className={LINK_CLASS} path={urlFor.support()}>
            <Trans id="auth.need_help" />
          </Linka>
        </p>
      </div>
    )
  }

  phoneStep(): JSX.Element {
    const {
      changeStep,
      lockSubmit,
      error,
      loading,
      mtn,
      currentUser,
      dispatch,
      yettel,
    } = this.props
    let authHeader = <Trans id="auth.join_or_login" />
    if (mtn?.authInit && mtn?.registered)
      authHeader = <span>Log in with MTN</span>
    else if (mtn?.authInit && !mtn?.registered)
      authHeader = <span>Sign in with MTN</span>

    return (
      <div key="phone" className="auth__step">
        <h3 className="auth__header">{authHeader}</h3>
        <SVGInline
          svg={BackIcon}
          className="auth__back"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => {
            if (currentUser.data.country === 'gh') dispatch(markEndAuth())
            if (yettel) dispatch(markYettelEnd())
            changeStep('social')
          }}
        />
        <PhoneInput
          country={mtn?.authInit ? 'gh' : 'rs'}
          name="phone"
          phoneNumber={`+${mtn?.phone}`}
          codeName="country-code"
          placeholder="000 00 00"
          error={error}
          autoFocus
          readOnly
        />
        {this.showError(error)}
        <Button
          type="submit"
          disabled={lockSubmit}
          loading={loading}
          className={mtn?.authInit ? 'ghana_button' : ''}
        >
          <Trans id="auth.send_code" />
        </Button>
        <Spacer />
        <p className="auth__subaction">
          <span
            className={LINK_CLASS}
            onClick={() => changeStep('email', true)}
          >
            <Trans id="auth.have_password" />
          </span>
          <RecaptchaNotice />
        </p>
      </div>
    )
  }

  codeSentStep(): JSX.Element {
    const {
      changeStep,
      isUserRegistrationChecked,
      isUserRegistered,
      shouldShowPreSignupFields,
      shouldAllowAuth,
      loading,
      error,
      phone,
      resendCode,
      timerRunning,
      stopTimer,
      mtn,
    } = this.props

    return (
      <div key="codeSent" className="auth__step">
        <h3 className="auth__header">
          <JoinOrLoginText
            isChecked={isUserRegistrationChecked}
            isRegistered={mtn?.registered || isUserRegistered}
            role="header"
          />
        </h3>
        <SVGInline
          svg={BackIcon}
          className="auth__back"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onClick={() => changeStep('phone')}
        />
        <Input
          type="text"
          inputMode="numeric"
          kind="code"
          name="sms-code"
          error={Boolean(error)}
          max={4}
          autoFocus
          placeholder="0000"
        />
        {this.showError(error)}
        {shouldShowPreSignupFields && <SignupCheckboxesBox />}
        <Button
          type="submit"
          disabled={!shouldAllowAuth}
          loading={loading}
          className="ghana_button"
        >
          <JoinOrLoginText
            isChecked={isUserRegistrationChecked}
            isRegistered={mtn?.registered || isUserRegistered}
            role="button"
          />
        </Button>
        <p className="auth__subaction">
          <Spacer />
          <p className="auth__instruction">
            <Trans
              id="auth.code_sent_insturction"
              values={{ phone: `${phone}` }}
            />
          </p>
          {timerRunning ? (
            <>
              <Trans id="profile.can_request_again_in" />{' '}
              <Timer minutes={2} onEnd={stopTimer} />
            </>
          ) : (
            <span className={LINK_CLASS} onClick={resendCode}>
              <Trans id="auth.send_code_again" />
            </span>
          )}
        </p>
      </div>
    )
  }

  linkSentStep(): JSX.Element {
    return (
      <div key="linkSent" className="auth__step">
        <h3 className="auth__header">
          <Trans id="auth.link_sent" />
        </h3>
        <p className="auth__instruction">
          <Trans id="auth.link_sent_instruction" />
        </p>
        <SVGInline svg={SuccessMarkIcon} className="auth__success-mark" />
      </div>
    )
  }

  showError(error?: string | null | JSX.Element): JSX.Element | null {
    if (error) {
      return <p className="auth__error">{error}</p>
    } else {
      return null
    }
  }
}

const wrappers = compose(withI18n({ update: true }), withRouter)

export default wrappers(Auth as ComponentClass<Props, any>)

import omit from 'lodash/omit'

import env from './env'
import styles from './client/shared/styles/vars.json'

const isProductionHost = env.isProduction()

const supportedLocales = [
  'da', // Danish
  'de', // German
  'en', // English
  'es', // Spanish
  'id', // Indonesian
  'nl', // Dutch
  'pt', // Portuguese
  'ru', // Russian
  'sr', // Serbian
  'sv', // Swedish
  'tr', // Turkish
  'uk', // Ukrainian
  'bg', // Bulgarian
  'hu', // Hungary
  'hr', // Croatia
]

const developmentConfig = {
  socialAuthPath: '/social_auth/',
  vanguardApiPath: '/vanguard-api/',

  locales: supportedLocales,
  fallbackLocale: 'en',

  googleTagManagerId: env.getParam('GOOGLE_TAG_MANAGER_ID'),

  facebookPixelId: env.getParam('FACEBOOK_PIXEL_ID'),

  stripeKey: env.getParam('STRIPE_PUBLIC_KEY'),

  AWSIdentityPoolId: env.getParam('AWS_IDENTITY_POOL_ID'),

  AMAappId: env.getParam('AMA_APP_ID'),

  AMAappTitle: env.getParam('AMA_APP_TITLE'),

  AppStoreID: env.getParam('APP_STORE_ID'),

  recaptchaV3SiteKey: env.getParam('RECAPTCHA_V3_SITE_KEY'),

  styles: {
    ...styles,
    bp: omit(styles.bp, 'oneColumn'),
  },
}

const productionConfig = {
  ...developmentConfig,

  recaptchaV3SiteKey: isProductionHost
    ? env.getParam('RECAPTCHA_V3_SITE_KEY')
    : developmentConfig.recaptchaV3SiteKey,

  googleTagManagerId: isProductionHost
    ? env.getParam('GOOGLE_TAG_MANAGER_ID')
    : developmentConfig.googleTagManagerId,

  AMAappId: isProductionHost
    ? env.getParam('AMA_APP_ID')
    : developmentConfig.AMAappId,

  stripeKey: isProductionHost
    ? env.getParam('STRIPE_PUBLIC_KEY')
    : developmentConfig.stripeKey,
}

let currentConfig = developmentConfig

// TODO: rewrite this
if (env.isTest()) {
  currentConfig = developmentConfig
} else if (env.isClient()) {
  currentConfig = window.CLIENT_PARAMS.config
} else if (!env.isDevelopment()) {
  currentConfig = productionConfig
}

export default currentConfig

import config from 'config'

export function isValidLocale(locale: string | null | undefined): boolean {
  if (!locale) return false

  return Boolean(
    config.locales.includes(locale) && locale !== config.fallbackLocale,
  )
}

export function getValidLocale(locale: string | null | undefined): string {
  if (!locale) return config.fallbackLocale

  return isValidLocale(locale) ? locale : config.fallbackLocale
}

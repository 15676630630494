import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'

import { unfollow as unfollowUser } from 'client/bookmate/reducers/users-reducer'
import { CurrentUserState } from 'client/shared/types/current-user'

import DeleteIcon from 'client/shared/icons/delete.svg'
import {
  ContextMenuButton,
  MenuKind,
} from 'client/shared/blocks/context-menu-button'
import { Dispatch } from 'shared/types/redux'

type Props = {
  user: CurrentUserState
  dispatch: Dispatch
  kind: MenuKind
  onStateChange: () => void
} & withI18nProps

class _UserContextMenuBox extends Component<Props> {
  getMenuData() {
    const { i18n } = this.props
    const unfollowUserMenuItem = {
      title: i18n.t`shelf.unfollow`,
      action: this.onUserUnfollowClick,
      icon: DeleteIcon,
    }
    const items = [unfollowUserMenuItem]

    return { items }
  }

  onUserUnfollowClick = (): void => {
    const { dispatch, user, onStateChange } = this.props

    dispatch(unfollowUser(user))
    if (onStateChange) {
      onStateChange()
    }
  }

  render(): JSX.Element {
    const { kind } = this.props

    return <ContextMenuButton menuData={this.getMenuData()} kind={kind} />
  }
}

const wrappers = compose(connect(), withI18n({ update: true }))

export const UserContextMenuBox = wrappers(_UserContextMenuBox)

import { $Diff } from 'utility-types'

import React, { Component, ComponentType, ElementConfig } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import deviceHelper from 'shared/tools/device-helper'

import { State, Dispatch } from 'shared/types/redux'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { Locale } from 'client/shared/types/current-user'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { DeviceHelperWithApp } from 'shared/tools/device-helper'

const osSelector = (state: State) => state.app.os
const sizeSelector = (state: State) => state.app.size

const getHelpers = createSelector(osSelector, sizeSelector, (os, size) =>
  deviceHelper.withApp({ os, size }),
)

type DecoratorPropsFromState = {
  dispatch: Dispatch
  app: AppState
  auth: boolean
  country: string
  username: string
  locale: Locale
  library_lang: string
  team: boolean
  subscriptionState: SubscriptionState
}

export type DecoratorProps = DecoratorPropsFromState & DeviceHelperWithApp

export default function uiBox<Com extends ComponentType<any>>(
  DecoratedComponent: Com,
): ComponentType<$Diff<ElementConfig<Com>, DecoratorProps>> {
  class UiBoxDecorator extends Component<ElementConfig<Com>> {
    render() {
      return <DecoratedComponent {...this.props} />
    }
  }

  return connect((state: State) => ({
    app: state.app,
    auth: state.currentUser.auth,
    country: state.currentUser.data.country,
    username: state.currentUser.data.login,
    locale: state.currentUser.data.locale,
    library_lang: state.currentUser.data.library_lang,
    team: state.currentUser.team,
    subscriptionState: state.subscription,
    ...getHelpers(state),
  }))(UiBoxDecorator)
}

import { v1 as uuidv1 } from 'uuid'
import mapValues from 'lodash/mapValues'

type SocialProvider = {
  authUrl: string
  accessUrl?: string
  key?: string
  secret?: string
  scope?: string | string[]
  redirectProviderName?: string
  customParams?: {
    [key: string]: unknown
  }
  windowParams?: {
    width: number
    height: number
  }
  [key: string]: unknown
}

const providers: Record<string, SocialProvider> = {
  facebook: {
    authUrl: 'https://www.facebook.com/dialog/oauth',
    accessUrl: 'https://graph.facebook.com/oauth/access_token',
    key: process.env.FACEBOOK_KEY,
    secret: process.env.FACEBOOK_SECRET,
    scope: ['user_friends', 'email'],
    customParams: {
      display: 'popup',
    },
    windowParams: {
      width: 600,
      height: 260,
    },
  },
  twitter: {
    requestUrl: 'https://api.twitter.com/oauth/request_token',
    authUrl: 'https://api.twitter.com/oauth/authenticate',
    accessUrl: 'https://api.twitter.com/oauth/access_token',
    key: process.env.TWITTER_KEY,
    secret: process.env.TWITTER_SECRET,
    flow: 'oauth1',
    windowParams: {
      width: 760,
      height: 380,
    },
  },
  google: {
    authUrl: 'https://accounts.google.com/o/oauth2/auth',
    accessUrl: 'https://accounts.google.com/o/oauth2/token',
    key: process.env.GOOGLE_KEY,
    secret: process.env.GOOGLE_SECRET,
    scope: ['profile', 'email'],
    scopeDelimiter: scope => scope.join(' '),
    windowParams: {
      width: 445,
      height: 615,
    },
  },
  telenor_bulgaria: {
    authUrl: 'https://id.yettel.bg/oauth/authorize',
    key: process.env.TELENOR_KEY,
    scope: ['openid'],
    scopeDelimiter: scope => scope,
    redirectProviderName: 'telenor',
    customParams: {
      ui_locales: 'bg',
      code_challenge: uuidv1,
    },
    passThruParams: ['code_challenge'],
    windowParams: {
      width: 521,
      height: 717,
    },
  },
  apple: {
    authUrl: 'https://appleid.apple.com/auth/authorize',
    key: process.env.APPLE_IDENTIFIER,
    customParams: {
      response_mode: 'form_post',
      team_id: process.env.APPLE_TEAM_ID,
      key_id: process.env.APPLE_KEY_ID,
    },
    scopeDelimiter: scope => scope.join(' '),
    scope: ['name', 'email'],
    windowParams: {
      width: 521,
      height: 717,
    },
  },
}

export const socialClientConfig = {
  ...mapValues(providers, (data, key) => ({
    path: `/connect/${key}`,
    ...data.windowParams,
  })),
}

export const getSocialServerConfig = (
  origin: string,
): { origin: string; providers: Record<string, SocialProvider> } => ({
  origin,
  providers,
})

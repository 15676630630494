import { $Diff } from 'utility-types'

import React, { Component, ComponentType, ElementConfig } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import nprogress from 'nprogress'
import omit from 'lodash/omit'
import compose from 'lodash/fp/compose'

import env from 'env'

import { Store } from 'redux'
import { Location, Params, Router } from 'client/shared/types/react-router'
import {
  Action,
  State,
  Dispatch,
  State as ConnectedState,
} from 'shared/types/redux'
import { userBooksProps } from 'client/bookmate/boxes/user-books-box'

type UpdateParamFunction = (
  arg0: userBooksProps,
  props: userBooksProps,
) => boolean

type Options = {
  userListener?: boolean
  updateParams?: string | string[] | UpdateParamFunction
  name?: string
}

type PrepareFunctionParameters = {
  store: Store<State, Action, Dispatch>
  params: Params
  location: Location
  options?: PrepareFunctionOptions
  router: Router
}
type PrepareFunctionOptions = { force?: boolean }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PrepareFunctionType = (arg0: PrepareFunctionParameters) => Promise<any>

type PrepareProps = {
  isFirstRender: boolean
  socialProvider: string
  userLogin: string
  params: {
    [key: string]: string
  }
  location: Location
  router: Router
}

export default function prepareComponent(
  prepareFunction: PrepareFunctionType,
  options: Options = {},
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function prepareDecorator<Com extends ComponentType<any>>(
    DecoratedComponent: Com,
  ): ComponentType<$Diff<ElementConfig<Com>, PrepareProps>> {
    type Props = $Diff<ElementConfig<Com>, PrepareProps>

    class PrepareComponentDecorator extends Component<Props> {
      static prepareComponent = prepareFunction
      static name = options.name || 'component'
      static contextTypes = { store: {} }

      componentDidMount() {
        // call prepare function only on the client side,
        // and only when the component has not been server-rendered
        // to avoid unnecessary requests for data
        if (!this.props.isFirstRender || env.isDevelopment()) {
          this.prepare()
        }
      }

      componentDidUpdate(prevProps: Props) {
        if (this.observedParamsUpdated(prevProps)) {
          this.prepare()
        }
      }

      observedParamsUpdated(prevProps: Props) {
        const { updateParams: _updateParams, userListener } = options
        let updateParams = _updateParams

        if (!updateParams && !userListener) {
          return false
        }

        if (this.hasUserChanged(prevProps)) {
          return true
        }

        // updateParams option, if present, can be a string, an array of strings.
        // or a function with a signature (prevParams, newParams) => Boolean

        // reduce cases when updateParams can be either a string or an array of strings
        // to one case (array of strings)
        if (typeof updateParams === 'string') {
          updateParams = [updateParams]
        }

        if (Array.isArray(updateParams)) {
          return updateParams.some(
            param => prevProps.params[param] !== this.props.params[param],
          )
        } else if (typeof updateParams === 'function') {
          return updateParams(prevProps, this.props)
        }
      }

      hasUserChanged(prevProps) {
        const {
          userLogin: oldUserLogin,
          socialProvider: oldSocialProvider,
        } = prevProps
        const { userLogin, socialProvider } = this.props

        if (socialProvider) {
          return socialProvider !== oldSocialProvider
        } else {
          return userLogin !== oldUserLogin
        }
      }

      // eslint-disable-next-line no-shadow
      async prepare(options: PrepareFunctionOptions = {}) {
        // TODO: with the current implementation of react-redux, we are dependent
        // on the store being passed using the old context api. Update this when
        // they release a new version of react-redux that takes advantage of
        // the new context API introduced in react 16.3
        const {
          context: { store },
          props: { params, location, router },
        } = this

        if (nprogress.status > 0) {
          nprogress.inc()
        } else {
          nprogress.start()
        }

        // TODO: now that we are passing React Router to prepare function,
        // we do not need params and location objects
        await prepareFunction({ store, params, location, options, router })

        nprogress.done()
      }

      render() {
        const propsToOmit = ['isFirstRender', 'socialProvider', 'userLogin']
        return <DecoratedComponent {...omit(this.props, propsToOmit)} />
      }
    }

    return compose(
      connect((state: ConnectedState) => ({
        isFirstRender: state.app.firstRender,
        socialProvider: state.currentUser.socialProvider,
        userLogin: state.currentUser.data.login,
        query: state.app.storedQuery,
      })),
      withRouter,
    )(PrepareComponentDecorator)
  }
}

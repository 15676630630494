import React, { useState, useEffect } from 'react'

type Props = {
  minutes?: number
  seconds?: number
  onEnd?: () => void
  className?: string
}

const getAllTimeInSeconds = (minutes: number): number => minutes * 60
const getMinutesFromSeconds = (seconds: number): number =>
  Math.floor(seconds / 60)

export const Timer = ({
  minutes: _minutes = 0,
  seconds: _seconds = 0,
  onEnd,
  className,
}: Props): JSX.Element | null => {
  const countdownInSeconds = _minutes
    ? getAllTimeInSeconds(_minutes) + _seconds
    : _seconds

  const [seconds, setSeconds] = useState(countdownInSeconds)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setSeconds(0)
    }
  }, [seconds])

  const minutesLeft = getMinutesFromSeconds(seconds)

  let secondsLeft: string

  if (seconds < 10) {
    secondsLeft = `0${seconds}`
  } else if (seconds === 120 || seconds === 60) {
    secondsLeft = '00'
  } else if (seconds > 60 && seconds < 70) {
    secondsLeft = `0${seconds % 60}`
  } else {
    secondsLeft = `${seconds % 60}`
  }

  if (onEnd && seconds === 0) onEnd()

  return (
    <span className={className}>
      {minutesLeft}:{secondsLeft}
    </span>
  )
}

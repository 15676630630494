const SUPPORTED_MIME_TYPES = [
  'text/xml',
  'application/epub+zip',
  'application/zip',
]
const SUPPORTED_FORMATS = ['fb2', 'epub', 'zip']
const SUPPORTED_FORMATS_RX = new RegExp(
  `\\.(?:${SUPPORTED_FORMATS.join('|')})$`,
  'i',
)
export const VISIBLE_SUPPORTED_FORMATS = SUPPORTED_FORMATS.filter(
  format => format !== 'zip',
)

export const MAX_FILES_AT_ONCE = 5
const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100mb

import { VALIDATE_URL_REGEX } from 'client/shared/helpers/text-helpers'

export default {
  validateFile(file, validFiles, inTeam) {
    let error = null
    // number of files per upload is limited for non-admin users
    if (!inTeam && validFiles.length + 1 > MAX_FILES_AT_ONCE) {
      error = 'upload.error_invalid_quantity'
    }
    if (file.size > MAX_FILE_SIZE) {
      error = 'upload.error_invalid_size'
    }
    if (
      !SUPPORTED_MIME_TYPES.includes(file.type) &&
      !SUPPORTED_FORMATS_RX.test(file.name)
    ) {
      error = 'upload.error_invalid_format'
    }
    return [error, file]
  },

  supportedFormatsAsString() {
    return SUPPORTED_FORMATS.map(format => `.${format}`).join(',')
  },

  validateUrl(url) {
    return VALIDATE_URL_REGEX.test(url)
  },
}

import React, { Component } from 'react'
import MetaTitle from 'client/shared/blocks/meta-title/meta-title'
import Meta from 'client/shared/blocks/meta/meta'
import { SimpleHeader } from '../blocks/salinas/salinas-header'
import { Heading } from 'client/shared/blocks/heading/heading'
import Spacer from 'client/shared/blocks/spacer/spacer'
import Button from 'client/shared/blocks/button/button'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { EmployeeData, signUp } from 'client/shared/reducers/salinas-reducer'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'
import { Event } from 'client/shared/types/event'
import omit from 'lodash/omit'
import ShareFB from 'client/bookmate/blocks/salinas/share-fb.png'
import ShareTW from 'client/bookmate/blocks/salinas/share-tw.png'
import '../blocks/salinas/salinas.styl'
import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import Checkbox from 'client/shared/blocks/checkbox/checkbox'
import urlHelper from 'shared/tools/url-helper'

export const SALINAS_HEADING = '¡Bienvenido a tu Biblioteca Socio!'
export const SALINAS_DESCRIPTION =
  'Hemos renovado nuestra librería para ofrecerte un catálogo ilimitado en 19 idiomas, recomendaciones personalizadas y las mejores editoriales latinoamericanas. Con esta nueva plataforma en colaboración con Bookmate, ¡vas a querer leer más!'

export const SALINAS_FIELDS_BASE = [
  {
    name: 'email',
    label: 'Correo electrónico',
    placeholder: 'name@MAIL.com',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Contraseña',
    placeholder: 'contraseña',
  },
]

const SALINAS_FIELDS = [
  {
    name: 'employee_id',
    label: 'Número de empleado',
    placeholder: '1234 1234 1234',
  },
  {
    name: 'date_of_birth',
    type: 'date',
    label: 'Fecha de nacimiento',
    placeholder: 'YYYY-MM-DD',
  },
  ...SALINAS_FIELDS_BASE,
]

type State = {
  submitDisabled: boolean
  acceptTermsOfService: boolean
} & EmployeeData

type Props = {
  loading: boolean
} & DecoratorProps

class SalinasSignUp extends Component<Props, State> {
  state = {
    date_of_birth: '',
    employee_id: '',
    email: '',
    password: '',
    submitDisabled: true,
    acceptTermsOfService: false,
  }

  componentDidUpdate(): void {
    if (this.state.submitDisabled && this.areAllFilledOut()) {
      this.setState({ submitDisabled: false })
    } else if (!this.state.submitDisabled && !this.areAllFilledOut()) {
      this.setState({ submitDisabled: true })
    }
  }

  areAllFilledOut(): boolean {
    const {
      date_of_birth,
      password,
      email,
      employee_id,
      acceptTermsOfService,
    } = this.state
    return Boolean(
      date_of_birth && password && email && employee_id && acceptTermsOfService,
    )
  }

  handleForm(
    event: Event,
    dispatch: Dispatch,
    data: Record<string, string | boolean>,
  ): void {
    event.preventDefault()

    dispatch(
      signUp(
        omit(data, 'submitDisabled', 'acceptTermsOfService') as EmployeeData,
      ),
    )
  }

  onChange(fieldName: string, event: Event): void {
    const newValue = event?.target?.value

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setState({ [fieldName]: newValue })
  }

  render(): JSX.IntrinsicAttributes {
    const { dispatch, isTabletSize, isDesktopSize } = this.props

    return (
      <>
        <MetaTitle
          disableSuffix
          title="Biblioteca socio x Bookmate - Grupo Salinas"
        />
        <Meta content={ShareTW} property="twitter:image" />
        <Meta content={ShareFB} property="og:image" />
        <SimpleHeader />
        <div className="salinas-body">
          <div className="spaceholder" />
          <div className="salinas-column">
            <img
              className="logos_intro"
              alt="Bookmate x salinas logo"
              src="https://a.bmstatic.com/iu/25/142/Group 1152-622001d48b4bdd870e2daad6f48fb4c1.png"
              aria-label="Bookmate x salinas logo"
            />
            <Spacer />
            <Heading>{SALINAS_HEADING}</Heading>
            <Spacer size={24} />
            <p className="salinas-description">{SALINAS_DESCRIPTION}</p>
            <Spacer size={40} />
            <form
              autoComplete="off"
              onSubmit={(event: Event) =>
                this.handleForm(event, dispatch, this.state)
              }
              className="salinas-register_form"
            >
              {SALINAS_FIELDS.map(field => (
                <label className="salinas-register_field" key={field.name}>
                  <span className="register-hint">{field.label}</span>
                  <Spacer size={8} />
                  <input
                    maxlength={40}
                    autoComplete="off"
                    placeholder={field.placeholder}
                    type={field.type || 'text'}
                    className="register-input"
                    name={field.name}
                    value={this.state[field.name]}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(event: Event) =>
                      this.onChange(field.name, event)
                    }
                  />
                  {this.state[field.name] ? (
                    <span className="valid">✓</span>
                  ) : (
                    <span className="invalid">✖</span>
                  )}
                </label>
              ))}
              <Checkbox
                checked={this.state.acceptTermsOfService}
                id="signup-checkboxes-legal"
                label={
                  <>
                    Acepto los{' '}
                    <a href={urlHelper.legal('#terms_of_use')} target="_blank">
                      Términos del servicio
                    </a>
                  </>
                }
                onChange={() =>
                  this.setState({
                    acceptTermsOfService: !this.state.acceptTermsOfService,
                  })
                }
              />
              <Spacer />
              <Button
                loading={this.props.loading}
                disabled={this.state.submitDisabled}
              >
                Registrarse
              </Button>
            </form>
            {isTabletSize() ||
              (isDesktopSize() && <div className="spaceholder" />)}
            <Spacer size={100} />
            <img
              className="logos_footer"
              alt="Bookmate x salinas logo"
              src="https://a.bmstatic.com/iu/124/228/Group 1144-612b1f5c12f22b9627427c4cc4f37637.png"
              aria-label="Bookmate x salinas logo"
            />
            <div className="spaceholder" />
          </div>
        </div>
      </>
    )
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  loading: state.salinas.loading,
}))

const wrappers = compose(uiBox, connectWrapper)

export default wrappers(SalinasSignUp)

import React, { PureComponent } from 'react'
import SVGInline from 'react-svg-inline'
import cn from 'classnames'
import noop from 'lodash/noop'

import { filterLanguages } from 'client/bookmate/helpers/languages-helper'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'

import { Dropdown } from 'client/shared/blocks/dropdown'

import { CurrentUserState } from 'client/shared/types/current-user'
import { AccessLevels } from 'client/shared/types/subscription'
import { State as LanguagesState } from 'client/shared/reducers/languages-reducer'

import OkIcon from 'client/shared/icons/ok.svg'

import './languages-button.styl'

type Props = {
  currentUser: CurrentUserState
  userAccessLevels: AccessLevels
  languages: LanguagesState
  onUserLanguageChange: (lang: string) => void
}

type State = {
  dropdownShown: boolean
}

const DROPDOWN_TOP_OFFSET = 40
const DROPDOWN_RIGHT_OFFSET = 205

class LanguagesButton extends PureComponent<Props, State> {
  state = {
    dropdownShown: false,
  }

  showDropdown = (target): void => {
    if (
      typeof target.dataset?.testId === 'string' &&
      target.dataset?.testId.includes('language')
    )
      return

    this.setState({
      dropdownShown: true,
    })
  }

  hideDropdown = (): void => {
    this.setState({
      dropdownShown: false,
    })
  }

  onUserLanguageChange = (language: string): void => {
    this.props.onUserLanguageChange(language)
    this.hideDropdown()
  }

  render(): JSX.Element {
    const { currentUser } = this.props
    let libraryLanguage = currentUser.data.library_lang
    if (libraryLanguage === 'rus') libraryLanguage = 'ru'

    const userLanguages = this.getUserLanguages()

    return (
      <div
        className="languages-button"
        onMouseEnter={this.showDropdown}
        onClick={event => this.showDropdown(event.target)}
        onMouseLeave={this.hideDropdown}
      >
        <div>{libraryLanguage && <span>{libraryLanguage}</span>}</div>
        {(userLanguages.length && (
          <Dropdown
            position="top-right-offset"
            offsetTop={DROPDOWN_TOP_OFFSET}
            offsetRight={DROPDOWN_RIGHT_OFFSET}
            hidden={!this.state.dropdownShown}
          >
            <div className="languages">
              {this.renderLanguagesList({
                list: userLanguages,
                active: libraryLanguage,
              })}
            </div>
          </Dropdown>
        )) ||
          ''}
      </div>
    )
  }

  renderLanguagesList({
    list,
    active,
  }: {
    list: any
    active: string
  }): JSX.Element {
    return (
      <div className="languages__list">
        {list.map(lang => (
          <div
            className={cn('languages__item', {
              languages__item_active: lang.code === active,
            })}
            onClick={
              lang.code === active
                ? noop
                : this.onUserLanguageChange.bind(null, lang.code)
            }
            key={lang.code}
          >
            <span>{lang.name}</span>
            {lang.code === active && (
              <SVGInline svg={OkIcon} className="languages__item-icon" />
            )}
          </div>
        ))}
      </div>
    )
  }

  getUserLanguages(): string[] {
    const {
      userAccessLevels,
      languages: { allUserLanguages, availableUserLanguages },
    } = this.props

    const hasYouseeSubscription = subscriptionHelper.hasActiveYouseeSubscription(
      userAccessLevels,
    )

    return filterLanguages({
      all: allUserLanguages,
      available: hasYouseeSubscription ? ['da'] : availableUserLanguages,
    })
  }
}

export default LanguagesButton

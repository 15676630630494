import React, { Component } from 'react'

import deviceHelper from 'shared/tools/device-helper'
import animateScrollTo from 'animated-scroll-to'
import { Book } from 'client/shared/blocks/book'
import AudioBook from 'client/bookmate/blocks/audiobook'
import { Comicbook } from 'client/shared/blocks/comicbook'
import Series from 'client/shared/blocks/series'
import Shelf from 'client/bookmate/blocks/shelf'
import List, { ListType } from 'client/bookmate/blocks/list'
import { LibraryPosts } from 'client/bookmate/blocks/library-posts'
import { closeBanner } from 'client/bookmate/reducers/showcase-reducer'
import LibraryBanner from 'client/bookmate/blocks/library-banner'
import Spacer from 'client/shared/blocks/spacer'

import {
  TYPE_BOOK,
  TYPE_AUDIOBOOK,
  TYPE_COMICBOOK,
  TYPE_SERIES,
  TYPE_BOOKSHELF,
  TYPE_POST,
  TYPE_BANNER,
} from 'client/shared/types/showcase'

import {
  ShowcaseSection,
  SectionWithBooks,
  SectionWithAudiobooks,
  SectionWithComicbooks,
  SectionWithSeries,
  SectionWithShelves,
  SectionWithPost,
  SectionWithBanner,
} from 'client/shared/types/showcase'

import { State as AppState } from 'client/shared/reducers/app-reducer'
import { Dispatch } from 'shared/types/redux'
import { BookProps } from 'client/shared/types/book'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { ShelfProps } from 'client/shared/types/shelf'
import { SeriesProps } from 'client/shared/types/series'

import {
  AUDIOBOOK,
  BOOK,
  COMICBOOK,
  SERIES,
} from '../search-best-match/search-best-match'
import { sendOnClickAnalytics } from 'shared/tools/analytics-helper'
import { SHELF } from 'client/bookmate/reducers/feed-entries-reducer'

const MAX_SHELVES_IN_ROW = 6

type Props = {
  app: AppState
  dispatch: Dispatch
  sections: ShowcaseSection[]
  language: string
  slug: string
  areLanguagesMatched: boolean
  getShowcasePath: (section: ShowcaseSection) => string
  userId: number
  sectionSlug?: string
  isKids?: boolean
}

const SCROLL_SPEED = 420

class ShowcaseSections extends Component<Props> {
  componentDidMount(): void {
    let { sectionSlug = '' } = this.props
    sectionSlug = `@${sectionSlug}`
    const matchingParagraphNode = document.getElementById(sectionSlug as string)

    if (matchingParagraphNode) {
      const bodyTop =
        (document.body && document.body.getBoundingClientRect().top) || 0
      const nodeTop = matchingParagraphNode.getBoundingClientRect().top
      animateScrollTo(nodeTop - bodyTop, { speed: SCROLL_SPEED })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.sections.length === this.props.sections.length) return
    let { sectionSlug = '' } = this.props
    sectionSlug = `@${sectionSlug}`
    const matchingParagraphNode = document.getElementById(sectionSlug as string)

    if (matchingParagraphNode) {
      const bodyTop =
        (document.body && document.body.getBoundingClientRect().top) || 0
      const nodeTop = matchingParagraphNode.getBoundingClientRect().top
      animateScrollTo(nodeTop - bodyTop, { speed: SCROLL_SPEED })
    }
  }

  isLargeScreen(): boolean {
    const { app } = this.props

    return deviceHelper.isTabletSize(app) || deviceHelper.isDesktopAllSizes(app)
  }

  closeBanner = (tag: string): void => {
    this.props.dispatch(closeBanner(tag))
  }

  prepareAllLink(section: ShowcaseSection): string | undefined {
    const { areLanguagesMatched, getShowcasePath } = this.props

    if (!areLanguagesMatched) return

    return getShowcasePath(section)
  }

  noNeedRenderResource = (index: number, max: number): boolean =>
    this.isLargeScreen() && index >= max

  render(): (boolean | JSX.Element | JSX.Element[] | null)[] {
    const { sections } = this.props

    return sections.map(this.renderSection)
  }

  renderSection = (
    section: ShowcaseSection,
    key: number,
  ): JSX.Element | boolean | JSX.Element[] | null => {
    switch (section.objects_type) {
      case TYPE_POST:
        return this.renderPost(section as SectionWithPost, `post-${key}`)

      case TYPE_BANNER:
        return this.renderBanners(section, `banner-${key}`)

      case TYPE_BOOK:
        return this.renderBooks(section as SectionWithBooks, `book-${key}`)

      case TYPE_AUDIOBOOK:
        return this.renderAudiobooks(
          section as SectionWithAudiobooks,
          `audiobook-${key}`,
        )

      case TYPE_COMICBOOK:
        return this.renderComicbooks(
          section as SectionWithComicbooks,
          `comicbook-${key}`,
        )

      case TYPE_SERIES:
        return this.renderSeriesList(
          section as SectionWithSeries,
          `series-${key}`,
        )

      case TYPE_BOOKSHELF:
        return this.renderShelves(section as SectionWithShelves, `shelf-${key}`)

      default:
        return null
    }
  }

  renderPost(section: SectionWithPost, key: string): JSX.Element | boolean {
    return (
      section.objects.length > 0 && (
        <div id={`@s-${section.slug}`} key={key}>
          <LibraryPosts
            posts={section.objects}
            query={this.props.app.storedQuery}
          />
          <Spacer size={56} />
        </div>
      )
    )
  }

  renderBanners(section: SectionWithBanner, key: string): JSX.Element[] {
    const { objects } = section

    return objects.map((banner, index) => {
      return (
        <div id={`@s-${section.slug}`} key={`${key}-${index}`}>
          <LibraryBanner banner={banner} onClose={this.closeBanner} />
          <Spacer size={48} />
        </div>
      )
    })
  }

  renderBooks(section: SectionWithBooks, key: string): JSX.Element {
    const { title, objects } = section
    const { app, dispatch } = this.props

    return (
      <div id={`@s-${section.slug}`} key={key}>
        <List
          isOuterList
          headerRank={2}
          isReducedSpacing
          type={deviceHelper.isDesktopAllSizes(app) ? 'walker' : 'slider'}
          localizedHeader
          header={title}
          looksLike={deviceHelper.isMobileSize(app) ? 2 : 1}
          allLink={this.prepareAllLink(section)}
          showAllButton
          dispatch={dispatch}
        >
          {objects.map((book, index) => {
            return this.renderBook(book, index)
          })}
        </List>
        <Spacer size={48} />
      </div>
    )
  }

  renderShelves(section: SectionWithShelves, key: string): JSX.Element {
    const { title, objects } = section
    const { app, dispatch, isKids } = this.props

    let listType =
      deviceHelper.isMobileSize(app) || deviceHelper.isTabletSize(app)
        ? 'slider'
        : 'default'

    if (objects.length === 1) {
      listType = 'slider'
    }

    return (
      <div id={`@s-${section.slug}`} key={key}>
        <List
          isReducedSpacing
          isOuterList={listType === 'slider'}
          type={listType as ListType}
          localizedHeader
          header={title}
          headerRank={2}
          looksLike={deviceHelper.isMobileSize(app) ? 2 : 1}
          allLink={isKids ? '' : this.prepareAllLink(section)}
          dispatch={dispatch}
        >
          {objects
            .map((shelf, index) => {
              return this.renderShelf(shelf, index)
            })
            .filter(item => item !== null)}
        </List>
        <Spacer size={48} />
      </div>
    )
  }

  renderAudiobooks(section: SectionWithAudiobooks, key: string): JSX.Element {
    const { title, objects } = section
    const { app, dispatch } = this.props

    return (
      <div id={`@s-${section.slug}`} key={key}>
        <List
          type={deviceHelper.isDesktopAllSizes(app) ? 'walker' : 'slider'}
          localizedHeader
          isOuterList
          isReducedSpacing
          header={title}
          headerRank={2}
          looksLike={deviceHelper.isMobileSize(app) ? 2 : 1}
          allLink={this.prepareAllLink(section)}
          coverHeight="176"
          showAllButton
          dispatch={dispatch}
        >
          {objects.map((audiobook, index) => {
            return this.renderAudiobook(audiobook, index)
          })}
        </List>
        <Spacer size={48} />
      </div>
    )
  }

  renderComicbooks(section: SectionWithComicbooks, key: string): JSX.Element {
    const { title, objects } = section
    const { app, dispatch } = this.props

    return (
      <div id={`@s-${section.slug}`} key={key}>
        <List
          type={deviceHelper.isDesktopAllSizes(app) ? 'walker' : 'slider'}
          localizedHeader
          isReducedSpacing
          isOuterList
          header={title}
          headerRank={2}
          looksLike={deviceHelper.isMobileSize(app) ? 2 : 1}
          allLink={this.prepareAllLink(section)}
          showAllButton
          dispatch={dispatch}
        >
          {objects.map((comicbook, index) => {
            return this.renderComicbook(comicbook, index)
          })}
        </List>
        <Spacer size={48} />
      </div>
    )
  }

  renderSeriesList(section: SectionWithSeries, key: string): JSX.Element {
    const { title, objects } = section
    const { app, dispatch } = this.props

    return (
      <div id={`@s-${section.slug}`} key={key}>
        <List
          type="slider"
          localizedHeader
          isReducedSpacing
          isOuterList
          header={title}
          headerRank={2}
          looksLike={deviceHelper.isMobileSize(app) ? 2 : 1}
          allLink={this.prepareAllLink(section)}
          dispatch={dispatch}
          isSeries
        >
          {objects
            .slice(0, 11)
            .map((series, index) => this.renderSeries(series, index))}
        </List>
        <Spacer size={48} />
      </div>
    )
  }

  renderBook(book: BookProps, index: number): JSX.Element {
    const { dispatch, userId } = this.props

    return (
      <Book
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: BOOK,
            itemUuid: book.uuid,
            listType: 'section',
            screenName: `showcase_${BOOK}s`,
            position: index + 1,
            userId,
          })
        }
        key={book.uuid || index}
        showPlus={false}
        kind="grid"
        book={book}
        coverSize={this.isLargeScreen() ? 176 : 120}
        isInSlider
      />
    )
  }

  renderAudiobook(audiobook: AudiobookProps, index: number): JSX.Element {
    const { dispatch, userId } = this.props
    return (
      <AudioBook
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: AUDIOBOOK,
            listType: 'section',
            itemUuid: audiobook.uuid,
            screenName: `showcase_${AUDIOBOOK}s`,
            position: index + 1,
            userId,
          })
        }
        key={audiobook.uuid || index}
        kind="grid"
        audiobook={audiobook}
        coverSize={this.isLargeScreen() ? 176 : 120}
      />
    )
  }

  renderComicbook(comicbook: ComicbookProps, index: number): JSX.Element {
    const { dispatch, userId } = this.props
    return (
      <Comicbook
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            listType: 'section',
            resourceType: COMICBOOK,
            itemUuid: comicbook.uuid,
            screenName: `showcase_${COMICBOOK}s`,
            position: index + 1,
            userId,
          })
        }
        key={comicbook.uuid || index}
        kind="grid"
        comicbook={comicbook}
        coverSize={this.isLargeScreen() ? 176 : 120}
        isInSlider
      />
    )
  }

  renderShelf(shelf: ShelfProps, index: number): JSX.Element | null {
    if (this.noNeedRenderResource(index, MAX_SHELVES_IN_ROW)) {
      return null
    }
    const { dispatch, userId, app } = this.props

    return (
      <Shelf
        as={
          deviceHelper.isMobileSize(app) || deviceHelper.isTabletSize(app)
            ? 'div'
            : 'li'
        }
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            listType: 'section',
            resourceType: SHELF,
            itemUuid: shelf.uuid,
            screenName: `showcase_${BOOK}s`,
            position: index + 1,
            userId,
          })
        }
        key={shelf.uuid || index}
        kind="grid"
        shelf={shelf}
        query={app.storedQuery}
      />
    )
  }

  renderSeries(series: SeriesProps, index: number): JSX.Element | null {
    const { dispatch, userId } = this.props
    return (
      <Series
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            listType: 'section',
            resourceType: SERIES,
            itemUuid: series.uuid,
            screenName: `showcase_${BOOK}s`,
            position: index + 1,
            userId,
          })
        }
        key={series.uuid || index}
        series={series}
        kind="grid"
      />
    )
  }
}

export default ShowcaseSections

import { CALL_API, ApiAction } from 'shared/middlewares/api-middleware'

const NOTIFICATIONS_LOAD = 'NOTIFICATIONS_LOAD'
const NOTIFICATIONS_LOAD_SUCCESS = 'NOTIFICATIONS_LOAD_SUCCESS'
const NOTIFICATIONS_MARK = 'NOTIFICATIONS_MARK'
export const NOTIFICATIONS_MARK_AS_READ = 'NOTIFICATIONS_MARK_AS_READ'

import { Notification as NotificationProps } from 'client/shared/types/notification'

export type NotificationsState = {
  data: NotificationProps[]
  page: number | null | undefined
}

type NotificationsLoadSuccessAction = {
  type: 'NOTIFICATIONS_LOAD_SUCCESS'
  data: NotificationProps[]
  append: boolean
  nextPage: number
}

type NotificationsMarkAsReadAction = {
  type: 'NOTIFICATIONS_MARK_AS_READ'
}

type Action = NotificationsLoadSuccessAction | NotificationsMarkAsReadAction

type NotificationsLoadPayload = {
  p?: number | null | undefined
  pp?: number
  append?: boolean
  markAsRead?: boolean
}

type NotificationsRequestData = {
  p: number
  pp: number
  serializer: 'flexible'
  mark_as_read?: boolean
}

const initialState = {
  data: [],
  page: null,
}

function prepareNotificationsData(currentUserNotifications) {
  return currentUserNotifications.map(notification => {
    return { ...notification, activist: notification.activist.user }
  })
}

export default function notifications(
  state: NotificationsState = initialState,
  action: Action,
) {
  switch (action.type) {
    case NOTIFICATIONS_LOAD_SUCCESS:
      return {
        ...state,
        data: action.append ? [...state.data, ...action.data] : action.data,
        page: action.nextPage,
      }

    default:
      return state
  }
}

export function markAsRead(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/notifications/status',
      options: {
        method: 'put',
        data: { notifications: { status: true } },
      },
      types: [NOTIFICATIONS_MARK, NOTIFICATIONS_MARK_AS_READ],
    },
  }
}

export function load(params: NotificationsLoadPayload = {}): ApiAction {
  const { p, pp, append = false } = params

  const data: NotificationsRequestData = {
    p: p || 1,
    pp: pp || 5,
    serializer: 'flexible',
  }

  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/notifications',
      options: {
        data,
      },
      modifyResponse: response => ({
        data: prepareNotificationsData(response.notifications),
        append,
        nextPage: response.notifications.length ? p + 1 : null,
      }),
      types: [NOTIFICATIONS_LOAD, NOTIFICATIONS_LOAD_SUCCESS],
    },
  }
}

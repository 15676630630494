import React, { Component } from 'react'
import classNames from 'classnames'

import SVGInline from 'react-svg-inline'
import XMarkIcon from 'client/shared/icons/x-mark.svg'

import './input.styl'

type Props = {
  className?: string
  kind?: string
  error?: boolean
  icon?: string
  type?: 'text' | 'email' | 'password' | 'file'
  inputMode?: 'numeric'
  name?: string
  value?: string
  placeholder?: string
  id?: string
  autoComplete?: 'off'
  disabled?: boolean
  autoFocus?: boolean
  onChange?: (arg?: any) => void
  max?: number
  onClear?: () => void
  onBlur?: (arg0: Event) => void
}

export default class Input extends Component<Props> {
  input: HTMLInputElement | null | undefined

  render(): JSX.Element {
    const {
      kind,
      error,
      icon,
      className: classNameFromProps,
      max,
      onClear,
      ...otherProps
    } = this.props
    const className = classNames(
      'input',
      kind && `input_${kind}`,
      {
        input_icon: icon,
        input_error: error,
      },
      classNameFromProps,
    )
    const style = {}
    if (icon) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style.backgroundImage = `url(${icon})`
    }

    return (
      <div className={onClear ? 'input__wrapper' : ''}>
        <input
          className={className}
          autoComplete="on"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          ref={element => (this.input = element)}
          style={style}
          maxLength={max}
          {...otherProps}
        />
        {onClear && (
          <span className="input_clear" onClick={onClear}>
            <SVGInline svg={XMarkIcon} />
          </span>
        )}
      </div>
    )
  }

  getElement(): HTMLInputElement | null | undefined {
    return this.input
  }

  getValue(): string {
    if (!(this.input instanceof HTMLInputElement)) return ''

    return this.input.value
  }

  setValue(newVal: string): string | undefined {
    if (!(this.input instanceof HTMLInputElement)) return

    this.input.value = newVal
  }

  focus(): void {
    if (!(this.input instanceof HTMLInputElement)) return

    this.input.focus()
  }

  blur(): void {
    if (!(this.input instanceof HTMLInputElement)) return

    this.input.blur()
  }
}

import React, { Component, createRef, RefObject } from 'react'
import { Trans } from '@lingui/react'

import HorizontalScroller from 'client/shared/blocks/horizontal-scroller'
import { ArrowButton } from 'client/shared/blocks/arrow-button'

import './walker.styl'

type Props = {
  showAllLink?: string | null | undefined
  coverHeight?: string
  shouldShowLink?: boolean
  isReducedSpacing?: boolean
}

type State = {
  scrollStep: number
  scrollMaxLength: number
  isForwardControlVisible: boolean
  isBackControlVisible: boolean
  controlsVisible: boolean
}

export default class Walker extends Component<Props, State> {
  scrollerRef = createRef() as RefObject<HTMLElement>

  static defaultProps = {
    coverHeight: '247',
    shouldShowLink: false,
  }

  state = {
    scrollStep: 0,
    scrollMaxLength: 0,
    isForwardControlVisible: true,
    isBackControlVisible: false,
    controlsVisible: false,
  }

  componentDidMount = (): void => {
    this.setState({
      scrollStep: this.scrollerRef?.current?.offsetWidth || 0,
      scrollMaxLength:
        (this.scrollerRef?.current?.scrollWidth || 0) -
        (this.scrollerRef?.current?.offsetWidth || 0),
    })
  }

  scrollForward = (): void => {
    const { scrollStep } = this.state
    this.scrollerRef?.current?.scrollBy({
      left: scrollStep,
      behavior: 'smooth',
    })
  }

  scrollBack = (): void => {
    const { scrollStep } = this.state
    this.scrollerRef?.current?.scrollBy({
      left: -scrollStep,
      behavior: 'smooth',
    })
  }

  onScroll = (): void => {
    const { scrollMaxLength } = this.state

    this.setState({
      isForwardControlVisible:
        this.scrollerRef?.current?.scrollLeft !== scrollMaxLength,
      isBackControlVisible: this.scrollerRef?.current?.scrollLeft !== 0,
    })
  }

  showControls = (): void => {
    this.setState({
      controlsVisible: true,
    })
  }

  hideControls = (): void => {
    this.setState({
      controlsVisible: false,
    })
  }

  render(): JSX.Element {
    const {
      controlsVisible,
      isBackControlVisible,
      isForwardControlVisible,
    } = this.state
    const {
      showAllLink,
      coverHeight,
      shouldShowLink,
      isReducedSpacing,
    } = this.props

    const height = `walker_${coverHeight}`

    return (
      <div
        className="walker"
        onScrollCapture={this.onScroll}
        onMouseEnter={this.showControls}
        onMouseLeave={this.hideControls}
      >
        <div
          className={`walker__btn walker__btn_prev ${height}`}
          onClick={this.scrollBack}
        >
          <div className="walker__decoration walker__decoration_prev">
            {controlsVisible && isBackControlVisible && (
              <ArrowButton kind="prev" appearance="transparent" />
            )}
          </div>
        </div>
        <HorizontalScroller
          isReducedSpacing={isReducedSpacing}
          ref={this.scrollerRef}
        >
          {this.props.children}
          {shouldShowLink && showAllLink && (
            <div className="walker__link">
              <div className={`walker__link-container ${height}`}>
                <a href={showAllLink || undefined} className="walker__link-btn">
                  <ArrowButton
                    kind="prev"
                    appearance="round"
                    type={['medium']}
                  />
                  <p className="walker__link-text">
                    <Trans id="topic.show_all" />
                  </p>
                </a>
              </div>
            </div>
          )}
        </HorizontalScroller>
        <div
          className={`walker__btn walker__btn_next ${height}`}
          onClick={this.scrollForward}
        >
          <div className="walker__decoration walker__decoration_next">
            {controlsVisible && isForwardControlVisible && (
              <ArrowButton kind="next" appearance="transparent" />
            )}
          </div>
        </div>
      </div>
    )
  }
}

import { CALL_API } from 'shared/middlewares/api-middleware'

const ALL_USER_LANGUAGES = 'ALL_USER_LANGUAGES'
const ALL_USER_LANGUAGES_SUCCESS = 'ALL_USER_LANGUAGES_SUCCESS'
const ALL_LIBRARY_LANGUAGES_SUCCESS = 'ALL_LIBRARY_LANGUAGES_SUCCESS'
const AVAILABLE_USER_LANGUAGES = 'AVAILABLE_USER_LANGUAGES'

const CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD =
  'CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD'
const CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_SUCCESS =
  'CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_SUCCESS'
const CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_ERROR =
  'CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_ERROR'

import { Dispatch, GetState } from 'shared/types/redux'

type GetUserLanguageTranslations = {
  type:
    | typeof CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD
    | typeof CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_SUCCESS
    | typeof CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_ERROR
  locale?: string
  translations?: { [key: string]: string }
  error?: Error
}

type SetAvailableUserLanguagesAction = {
  type: typeof AVAILABLE_USER_LANGUAGES
  data: string[] | { all: boolean }
}

type SetAvailableLibraryLanguagesAction = {
  type: typeof AVAILABLE_USER_LANGUAGES
  data: string[] | { all: boolean }
}

type AllUserLanguagesSuccessAction = {
  type: typeof ALL_USER_LANGUAGES_SUCCESS
  data: Language[]
}

type AllLibraryLanguagesSuccessAction = {
  type: typeof ALL_LIBRARY_LANGUAGES_SUCCESS
  data: Language[]
}

type Action =
  | GetUserLanguageTranslations
  | SetAvailableUserLanguagesAction
  | SetAvailableLibraryLanguagesAction
  | AllUserLanguagesSuccessAction
  | AllLibraryLanguagesSuccessAction

type Language = {
  name: string
  code: string
}

export type Translations =
  | {
      [key: string]:
        | {
            [key: string]: string
          }
        | null
        | undefined
    }
  | {
      [key: string]:
        | {
            messages: {
              [key: string]: string | Function
            }
          }
        | null
        | undefined
    }

export type State = {
  translations: Translations
  allUserLanguages: Language[]
  allLibraryLanguages: Language[]
  availableUserLanguages: string[] | { all: boolean }
  availableLibraryLanguages: string[] | { all: boolean }
  loaded: boolean
  loading: boolean
  error: string | null | undefined
}

export function setAllUserLanguages() {
  return {
    [CALL_API]: {
      endpoint: `/p/a/4/locales`,
      modifyResponse: response => ({ data: response }),
      types: [ALL_USER_LANGUAGES, ALL_USER_LANGUAGES_SUCCESS],
    },
  }
}

export function setAvailableUserLanguages(
  userLanguages: string[] | { all: true },
) {
  return {
    type: AVAILABLE_USER_LANGUAGES,
    data: userLanguages,
  }
}

export function getUserLanguageTranslations(locale: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState()
    const {
      app: { pointName },
      currentUser,
    } = state

    if (state.languages.translations[locale]) {
      return
    }

    await dispatch({
      [CALL_API]: {
        endpoint: `/locales/${pointName}/${locale}/${currentUser.data.country}`,
        modifyResponse: response => {
          let localesData = response.data
          if (typeof localesData === 'string') {
            // eslint-disable-next-line no-new-func
            localesData = new Function(`return ${localesData}`)()
          }
          return { translations: localesData, locale }
        },
        types: [
          CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD,
          CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_SUCCESS,
          CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_ERROR,
        ],
      },
    })
  }
}

export const initialState = {
  translations: {},
  allUserLanguages: [],
  allLibraryLanguages: [],
  availableUserLanguages: { all: true },
  availableLibraryLanguages: { all: true },
  loaded: false,
  loading: false,
  error: null,
}

export default function languages(state: State = initialState, action: Action) {
  switch (action.type) {
    case ALL_USER_LANGUAGES_SUCCESS:
      return {
        ...state,
        allUserLanguages: action.data,
      }

    case ALL_LIBRARY_LANGUAGES_SUCCESS:
      return {
        ...state,
        allLibraryLanguages: action.data,
      }

    case AVAILABLE_USER_LANGUAGES:
      return {
        ...state,
        availableUserLanguages: action.data,
      }

    case CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD:
      return {
        ...state,
        loading: true,
      }

    case CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_SUCCESS:
      return {
        ...state,
        translations: {
          ...state.translations,
          [action.locale]: action.translations,
        },
        loaded: true,
        loading: false,
        error: null,
      }

    case CURRENT_USER_LANGUAGE_TRANSLATIONS_LOAD_ERROR:
      return {
        ...state,
        loaded: false,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

import React, { Component } from 'react'
import ViewPager from 'client/shared/boxes/view-pager-box'
import { Frame, Track, View } from 'client/shared/blocks/pager'

import './carousel.styl'

import { Pager } from 'client/shared/blocks/slider-controls'

type Props = {
  viewsToShow?: number
  infinite?: boolean
  contain?: boolean
  children: JSX.Element[]
  className?: string
}

export default class Carousel extends Component<Props> {
  slider
  static defaultProps = {
    viewsToShow: 1,
    infinite: true,
    contain: false,
  }

  state = {
    currentSlide: 0,
  }

  goToSlide = (index?: number): void => this.slider.scrollTo(index)

  updateCurrentSlide = (indices: number[]): void =>
    this.setState({ currentSlide: indices[0] })

  render(): JSX.Element {
    return (
      <div className={this.props.className}>
        {this.renderCarousel()}
        {this.renderPager()}
      </div>
    )
  }

  renderCarousel(): JSX.Element {
    const { viewsToShow, infinite, contain } = this.props

    return (
      <ViewPager>
        <Frame className="carousel__frame">
          <Track
            ref={component => (this.slider = component)}
            viewsToShow={viewsToShow}
            infinite={infinite}
            onViewChange={this.updateCurrentSlide}
            contain={contain}
          >
            {this.props.children?.map((child, index) => (
              <View key={index}>{child}</View>
            ))}
          </Track>
        </Frame>
      </ViewPager>
    )
  }

  renderPager(): JSX.Element {
    return (
      <div className="carousel__pager">
        <Pager
          numberOfSlides={this.props.children?.length}
          activeSlide={this.state.currentSlide}
          handleClick={this.goToSlide}
        />
      </div>
    )
  }
}

import { configureStore } from '@reduxjs/toolkit'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import { Middleware } from 'redux'

import env from 'env'
import fetchWrapper from 'shared/tools/fetch-wrapper'
import cacheMiddleware from 'shared/middlewares/cache-middleware'
import apiMiddleware from 'shared/middlewares/api-middleware'
import clientAnalyticsMiddleware, {
  serverAnalyticsMiddleware,
} from 'shared/middlewares/analytics-middleware'
import redirectMiddleware from 'shared/middlewares/redirect-middleware'
import authCallbackMiddleware from 'shared/middlewares/auth-callback-middleware'

const configureClient = (middlewares, reducers) => {
  middlewares.unshift(cacheMiddleware())

  const store = configureStore({
    reducer: reducers,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        immutableCheck: { ignoredPaths: ['upload.files'] },
        serializableCheck: false,
      }).prepend(middlewares),
    devTools: !env.isProduction(),
    preloadedState: window.CLIENT_PARAMS.initialState,
  })

  return store
}

export default function storeCreator(reducers) {
  const analyticsMiddleware: Middleware[] = []

  if (!env.getParamAsBoolean('DISABLE_ANALYTICS')) {
    analyticsMiddleware.push(
      env.isClient() ? clientAnalyticsMiddleware() : serverAnalyticsMiddleware,
    )
  }

  const middlewares = [
    apiMiddleware(fetchWrapper),
    routerMiddleware(browserHistory),
    ...analyticsMiddleware,
    authCallbackMiddleware,
    redirectMiddleware,
  ]

  if (env.isClient() && window.CLIENT_PARAMS.initialState) {
    return configureClient(middlewares, reducers)
  }

  return ({ initialState }) =>
    configureStore({
      reducer: reducers,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          immutableCheck: { ignoredPaths: ['upload.files'] },
          serializableCheck: false,
        }).prepend(middlewares),
      preloadedState: initialState,
    })
}

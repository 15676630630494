import React, { Component, Children, createElement } from 'react'
import { findDOMNode } from 'react-dom'
// for .cjs extension see https://bit.ly/2DVJnTW
import { Spring, SpringConfig } from 'react-spring/renderprops.cjs'
import noop from 'lodash/noop'

import Pager from './pager'

import {
  ViewPagerContext,
  getIndex,
} from 'client/bookmate/helpers/view-pager-helper'

type Props = {
  tag: any
  currentView: any
  viewsToShow: number | string
  viewsToMove: number
  align: number
  contain: boolean
  axis: string
  animations: any[]
  infinite: boolean
  instant: boolean
  swipe: boolean | string
  swipeThreshold: number
  flickTimeout: number
  springConfig: SpringConfig
  onSwipeStart: (e: React.SyntheticEvent) => void
  onSwipeMove: (e: React.SyntheticEvent) => void
  onSwipeEnd: () => void
  onScroll: (arg0: number, arg1: number) => void
  onViewChange: (arg0: number[]) => void
  onRest: () => void
  pager: Pager
}

const isNotEqual = (current, next) =>
  current.viewsToShow !== next.viewsToShow ||
  current.viewsToMove !== next.viewsToMove ||
  current.align !== next.align ||
  current.axis !== next.axis ||
  current.animations !== next.animations ||
  current.infinite !== next.infinite ||
  current.swipe !== next.swipe ||
  current.swipeThreshold !== next.swipeThreshold ||
  current.flickTimeout !== next.flickTimeout

// Track scroller is an intermediate component that allows us to provide
// React Spring with a value to onScroll and lets any user of onScroll use setState
class TrackScroller extends Component<Props> {
  state = {
    // eslint-disable-next-line react/no-unused-state
    x: 0,
    // eslint-disable-next-line react/no-unused-state
    y: 0,
  }

  componentDidUpdate(prevProps) {
    const { pager, trackPosition } = this.props

    if (trackPosition !== prevProps.trackPosition) {
      requestAnimationFrame(() =>
        this.props.onScroll(
          (trackPosition / pager.getTrackSize(false)) * -1,
          trackPosition,
        ),
      )
    }
  }

  render() {
    const { tag, trackPosition, pager, children, ...restProps } = this.props

    let style = {
      ...restProps.style,
    }

    if (pager.track) {
      style = {
        ...style,
        ...pager.track.getStyles(trackPosition),
      }
    }

    // update view styles with current position tween
    // this method can get called hundreds of times, let's make sure to optimize as much as we can
    // NOTE: this method needs to be called before the views are rendered, so that they always
    // get the updated styles (otherwise the last update may be missed, which will be visible
    // as a flicker in an infinite scroller)
    pager.setViewStyles(trackPosition)

    return createElement(
      tag,
      {
        children, // we don't need to pass all props, its produce warnings
        style,
      },
      this.renderViews(),
    )
  }

  renderViews() {
    // it's a bit crazy, but without the Children.map (that does nothing!) infinite carousel will
    // contain empty slides
    return Children.map(this.props.children, child => React.cloneElement(child))
  }
}

class Track extends Component<Props> {
  static defaultProps = {
    tag: 'div',
    currentView: 0,
    viewsToShow: 1,
    viewsToMove: 1,
    align: 0,
    contain: false,
    axis: 'x',
    infinite: false,
    instant: false,
    swipe: true,
    swipeThreshold: 0.5,
    flickTimeout: 300,
    onSwipeStart: noop,
    onSwipeMove: noop,
    onSwipeEnd: noop,
    onScroll: noop,
    onViewChange: noop,
    onRest: noop,
  }

  state = {
    instant: false,
  }

  currentTween = 0
  hydrate = false

  constructor(props) {
    super(props)
    this.props.pager.setOptions(this.props)
  }

  componentDidMount() {
    const { pager } = this.props
    // add track to pager
    pager.addTrack(findDOMNode(this))

    // set initial view index and listen for any incoming view index changes
    this.scrollTo(getIndex(this.props.currentView, this.props.children))

    // set values instantly on respective events
    pager.on('hydrated', () => this.setValueInstantly(true, true))
    pager.on('swipeMove', () => this.setValueInstantly(true))
    pager.on('swipeEnd', () => this.setValueInstantly(false))

    // prop callbacks
    pager.on('swipeStart', this.props.onSwipeStart)
    pager.on('swipeMove', this.props.onSwipeMove)
    pager.on('swipeEnd', this.props.onSwipeEnd)
    pager.on('viewChange', this.props.onViewChange)
  }

  componentDidUpdate(prevProps) {
    const { currentView, instant, pager, children } = this.props

    // update instant state from props
    if (instant !== prevProps.instant) {
      this.setValueInstantly(instant)
    }

    // update state with new index if necessary
    if (currentView !== prevProps.currentView) {
      this.scrollTo(getIndex(currentView, children))
    }

    // update any options that have changed
    if (isNotEqual(this.props, prevProps)) {
      pager.setOptions(this.props)
      pager.hydrate()
    }
  }

  prev() {
    this.props.pager.prev()
  }

  next() {
    this.props.pager.next()
  }

  scrollTo(index) {
    this.props.pager.setCurrentView({ index })
  }

  setValueInstantly(instant, reset) {
    this.setState({ instant }, () => {
      if (reset) {
        this.setState({ instant: false })
      }
    })
  }

  getTrackStyle() {
    return { trackPosition: this.props.pager.trackPosition }
  }

  handleOnRest = () => {
    if (this.props.infinite && !this.state.instant) {
      // reset back to a normal index
      this.props.pager.resetViewIndex()

      // set instant flag so we can prime track for next move
      this.setValueInstantly(true, true)
    }

    this.props.onRest()
  }

  render() {
    return (
      <Spring
        to={this.getTrackStyle()}
        onRest={this.handleOnRest}
        immediate={this.state.instant}
      >
        {({ trackPosition }) =>
          createElement(TrackScroller, {
            ...this.props,
            trackPosition,
          })
        }
      </Spring>
    )
  }
}

type TrackProps = {
  ref: ((instance: TrackWithContext | null) => void) & Track
  viewsToShow?: number
  infinite?: boolean
  onViewChange: (indices: number[]) => void
  contain?: boolean
}

export default class TrackWithContext extends Component<TrackProps> {
  prev() {
    this.track.prev()
  }

  next() {
    this.track.next()
  }

  scrollTo(index) {
    this.track.scrollTo(index)
  }

  render() {
    return (
      <ViewPagerContext.Consumer>
        {({ pager }) => (
          <Track
            {...this.props}
            pager={pager}
            ref={element => (this.track = element)}
          />
        )}
      </ViewPagerContext.Consumer>
    )
  }
}

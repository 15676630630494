import isEmpty from 'lodash/isEmpty'
import { BookProps as Book } from 'client/shared/types/book'
import { SerialProps as Serial } from 'client/shared/types/serial'
import { LibraryCardProps as LibraryCard } from 'client/shared/types/library-card'

export default {
  canBeRead(book: Book | Serial) {
    return book.can_be_read
  },

  inWishlist(book: Book | Serial) {
    return book.in_wishlist
  },

  isAdded(book: Book | Serial) {
    return (book && book.in_library) || (book && book.lc)
  },

  isPrivate(libraryCard: LibraryCard) {
    return !isEmpty(libraryCard) && !libraryCard.public
  },

  isFinished(libraryCard: LibraryCard) {
    return !isEmpty(libraryCard) && libraryCard.state === 'finished'
  },

  inProgress(libraryCard: LibraryCard) {
    return (
      !isEmpty(libraryCard) &&
      libraryCard.progress !== undefined &&
      libraryCard.state !== 'finished'
    )
  },

  getProgressInPercent(libraryCard: LibraryCard) {
    if (!isEmpty(libraryCard)) {
      // book progress (as percentage of total book length) is stored in the `progress` field
      return libraryCard.progress
    }
  },
}

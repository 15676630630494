import 'client/shared/styles/fonts.styl'
import 'client/shared/styles/global.styl'
import './app-wrapper.styl'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { replace, push } from 'react-router-redux'
import URL from 'url-parse'
import qs from 'query-string'
import omit from 'lodash/omit'
import get from 'lodash/get'

import config from 'config'
import env from 'env'
import * as languagesActions from 'client/shared/reducers/languages-reducer'
import * as userActions from 'client/shared/reducers/current-user-reducer'
import * as authActions from 'client/shared/reducers/auth-reducer'
import {
  resetDisplaySize,
  markFirstRender,
  clearTokens,
  storeToken,
  storeQuery,
} from 'client/shared/reducers/app-reducer'
import { flushEvents } from 'client/shared/reducers/analytics-reducer'
import {
  hide as hidePopup,
  PopupState,
} from 'client/shared/reducers/popup-reducer'
import { hide as closeFakePage } from 'client/shared/reducers/fake-page-reducer'
import { acceptSocialAuthData } from 'client/shared/reducers/auth-reducer'
import {
  loadUserAccessLevels,
  loadProducts,
  loadFeaturedProduct,
  loadFamilyMembers,
  loadFamilyInvites,
} from 'client/bookmate/reducers/subscription-reducer'
import { loadGiftsPlans } from 'client/bookmate/reducers/gifts-reducer'

import { localeToRegion } from 'client/shared/helpers/locales-helpers'
import { getFingerprint } from 'client/shared/helpers/fingerprint-helper'
import { setFingerprint } from 'client/shared/reducers/app-reducer'
import {
  getTagManagerScript,
  customizeTagManagerScript,
} from 'client/shared/helpers/vendor-trackers-helper'
import urlFor from 'shared/tools/url-helper'
import cookieHelper from 'shared/tools/cookie-helper'

import Popup from 'client/shared/blocks/popup'
import Head from 'client/shared/blocks/head'
import { CookiesMessage } from 'client/shared/blocks/cookies-message'
import AuthBox from 'client/shared/boxes/auth-box'
import { Alert } from 'client/shared/blocks/alert'
import FakePage, {
  Props as FakePageState,
} from 'client/shared/blocks/fake-page'
import { Loader } from 'client/shared/blocks/loader'
import Spacer from 'client/shared/blocks/spacer'

import smoothscroll from 'smoothscroll-polyfill'

import { State as AppState } from 'client/shared/reducers/app-reducer'
import { Dispatch, State } from 'shared/types/redux'
import { Location } from './types/react-router'
import { AlertState } from './reducers/alert-reducer'
import { loadFeatureFlags } from './reducers/app-reducer'
import deviceHelper from 'shared/tools/device-helper'

const ALLOWED_ORIGIN_RX = /^https?:\/\/(\w+\.)*bookmate\./

export async function prepare({ store, location }) {
  const { dispatch } = store

  await dispatch(userActions.loadContext())
  dispatch(storeQuery(location?.query))

  const {
    currentUser,
    app: { signInToken, accessToken },
  } = store.getState()

  if (location.query.special === 'emails_ru' && signInToken)
    dispatch(storeToken(signInToken || accessToken))

  if (!currentUser.auth && (signInToken || accessToken)) {
    await dispatch(authActions.markSignInWithTokenStart())
  } else if (currentUser.auth && (signInToken || accessToken)) {
    await dispatch(userActions.logout(true))
    currentUser.auth = false
    await dispatch(authActions.markSignInWithTokenStart())
  }

  const actions = [
    dispatch(languagesActions.setAvailableUserLanguages(config.locales)),
    dispatch(
      languagesActions.getUserLanguageTranslations(currentUser.data.locale),
    ),
    dispatch(
      languagesActions.getUserLanguageTranslations(config.fallbackLocale),
    ),
    dispatch(loadFeaturedProduct()),
    dispatch(loadFeatureFlags()),
  ]

  if (currentUser.auth) {
    actions.push(dispatch(userActions.loadInfo()))
    actions.push(dispatch(loadUserAccessLevels()))
    actions.push(dispatch(loadFamilyMembers()))
    actions.push(dispatch(loadFamilyInvites()))
    actions.push(dispatch(userActions.loadPrivacy()))
    actions.push(dispatch(userActions.checkIfUnreadNotifications()))
  }

  await Promise.all(actions)
  dispatch(userActions.flagUserDataAsCompletelyLoaded())
}

type Props = {
  app: AppState
  auth: boolean
  locale: string
  libraryLanguage: string
  country: string
  dispatch: Dispatch
  location?: Location
  fakePage: FakePageState
  alert: AlertState
  signInWithToken: { loading: boolean }
  popup: PopupState
  analytics: { events: Record<string, unknown>[] }
  mtn?: boolean
  yettel?: boolean
}

const connectWrapper = connect((state: State) => ({
  app: state.app,
  auth: state.currentUser.auth,
  currentUser: state.currentUser,
  signInWithToken: state.auth?.signInWithToken,
  locale: state.currentUser.data.locale,
  libraryLanguage: state.currentUser.data.library_lang,
  country: state.currentUser.data.country,
  analytics: state.analytics,
  alert: state.alert,
  popup: state.popup,
  fakePage: state.fakePage,
  mtn: Boolean(state.mtn?.authInit),
  yettel: state.auth?.yettel?.authInit,
}))

class AppWrapper extends Component<Props> {
  changeAppDeviceTimeout = null

  constructor(props) {
    super(props)

    if (typeof window === 'undefined') return

    const payloadStr = get(props, 'location.query.socialAuthPayload', '')

    try {
      const payload = JSON.parse(payloadStr)

      if (payload.provider && payload.providerTokenData) {
        this.handleMessage({ data: payload }, true)
      }
    } catch (err) {
      // ignore
    }

    this.cleanQueryParams()
  }

  async componentDidMount() {
    const { dispatch, auth, app, location, country } = this.props
    const { signInToken, accessToken } = app
    let fingerprint = app.fingerprint

    const actions = [dispatch(loadProducts()), dispatch(loadGiftsPlans())]

    Promise.all(actions)

    if (env.isClient()) {
      smoothscroll.polyfill()
    }

    this.markFirstRender()
    this.addMediaQueryListeners()
    this.addMessageListeners()
    this.flushAnalyticsEvents()

    // If the user logs in via socials, they already have their fingerprint. No need to generate one.
    // If we do generate a fingerprint, then reassign the corresponsing variable.
    if (!fingerprint) {
      await getFingerprint(dispatch)
      fingerprint = this.props.app.fingerprint
    }

    if (auth && fingerprint && (signInToken || accessToken)) {
      await dispatch(userActions.logout(true))
      await dispatch(authActions.signInWithToken(signInToken, accessToken))
    } else if (!auth && fingerprint && (signInToken || accessToken))
      await dispatch(authActions.signInWithToken(signInToken, accessToken))

    if (
      cookieHelper.isSoftCookiePolicy(location?.pathname as string, country)
    ) {
      if (!cookieHelper.isFullConsent()) {
        cookieHelper.enableAllCategories({ force: true, country })
      }
    } else {
      if (cookieHelper.isStashedConsent()) {
        cookieHelper.applyConsentStash(country)
      }
      if (!cookieHelper.isConsentSet() || !cookieHelper.isConsentGiven()) {
        cookieHelper.setBasicConsent({ country })
      }
    }
  }

  componentDidUpdate({ location: prevLocation, auth: prevAuth }) {
    const {
      location: curLocation,
      fakePage,
      auth,
      country,
      dispatch,
      app: { signInToken, accessToken, url },
    } = this.props

    if (prevLocation.pathname !== curLocation.pathname) {
      const pathname = curLocation.pathname
      if (cookieHelper.isSoftCookiePolicy(pathname, country)) {
        if (!cookieHelper.isFullConsent()) {
          cookieHelper.enableAllCategories({ country })
        }
      } else {
        if (cookieHelper.isStashedConsent()) {
          cookieHelper.applyConsentStash(country)
        }
        if (!cookieHelper.isConsentSet() || !cookieHelper.isConsentGiven()) {
          cookieHelper.setBasicConsent({ mode: 'update', country })
        }
      }
    }

    if (auth && (signInToken || accessToken)) dispatch(clearTokens(url))

    if (prevAuth !== auth) {
      dispatch(loadProducts())
      dispatch(loadGiftsPlans())
    }

    if (prevLocation.pathname !== curLocation.pathname && !fakePage.hidden) {
      this.closeFakePage()
    }
  }

  componentWillUnmount() {
    this.removeMediaQueryListeners()
    this.removeMessageListeners()
  }

  cleanQueryParams() {
    if (env.isServer()) return

    const { dispatch } = this.props
    const currentUrl = new URL(window.location.href)
    const cleanedQuery = qs.stringify(
      omit(qs.parse((currentUrl.query as unknown) as string), [
        'socialAuthPayload',
      ]),
    )

    currentUrl.set('query', cleanedQuery)
    dispatch(
      replace(`${currentUrl.pathname}?${qs.stringify(currentUrl.query)}`),
    )
  }

  markFirstRender() {
    const { dispatch } = this.props

    dispatch(markFirstRender())
  }

  flushAnalyticsEvents() {
    const { dispatch, analytics } = this.props
    const { events } = analytics

    if (events && events.length) {
      events.forEach(dispatch)
    }
    dispatch(flushEvents())
  }

  handleMessage = (
    { origin, data }: { data: any; origin?: string },
    fromSelf: boolean,
  ) => {
    const { dispatch } = this.props

    if (data.goto) {
      dispatch(push(data.goto))
    }

    if (!fromSelf && !ALLOWED_ORIGIN_RX.test(origin as string)) return

    if (data?.mobileState?.fingerprint)
      dispatch(setFingerprint(data.mobileState.fingerprint))

    if (data?.provider && data?.providerTokenData) {
      dispatch(acceptSocialAuthData(data))
    }
  }

  addMediaQueryListeners() {
    this.handleMediaQuery()

    window.addEventListener('resize', this.handleMediaQuery)
  }

  removeMediaQueryListeners() {
    clearTimeout(this.changeAppDeviceTimeout)

    window.removeEventListener('resize', this.handleMediaQuery)
  }

  handleMediaQuery = () => {
    const { app, dispatch } = this.props

    clearTimeout(this.changeAppDeviceTimeout)

    this.changeAppDeviceTimeout = setTimeout(() => {
      Object.keys(config.styles.bp).forEach(deviceName => {
        const mediaQueryDescription = config.styles.bp[deviceName]
        const mediaMatches = window.matchMedia(mediaQueryDescription).matches

        if (mediaMatches && app.size !== deviceName) {
          dispatch(resetDisplaySize(deviceName))
        }
      })
    }, 15)
  }

  addMessageListeners() {
    window.addEventListener('message', this.handleMessage, false)
  }

  removeMessageListeners() {
    window.removeEventListener('message', this.handleMessage, false)
  }

  hidePopup = () => {
    this.props.dispatch(hidePopup())
  }

  closeFakePage = () => {
    const { dispatch } = this.props

    dispatch(closeFakePage())
  }

  renderHead() {
    const {
      app: { protocol, host, browser },
      location: { pathname, query },
      locale = '',
      country,
    } = this.props

    const cookiePolicy = cookieHelper.isSoftCookiePolicy(pathname, country)
    const cookieConsent = cookieHelper.getCurrentCookieConsent()

    return (
      <Head
        title="Bookmate"
        url={{ protocol, host, pathname, query }}
        lang={locale}
        locale={localeToRegion(locale)}
        alternateLocale={localeToRegion(locale === 'en' ? 'ru' : 'en')}
        gtagHelper={customizeTagManagerScript}
        gtag={getTagManagerScript}
        cookiePolicy={cookiePolicy}
        cookieConsent={cookieConsent}
        browser={browser}
        country={country}
      />
    )
  }

  getPopup(
    element: JSX.Element,
    options?: Record<string, unknown>,
  ): JSX.Element {
    return (
      <Popup
        onClose={this.hidePopup}
        dispatch={this.props.dispatch}
        mtn={Boolean(this.props.mtn)}
        yettel={this.props.yettel}
        {...options}
      >
        {element}
      </Popup>
    )
  }

  renderAlert() {
    const { alert, app, dispatch } = this.props
    const isMobile = deviceHelper.isMobileSize(app)

    return <Alert alert={alert} isMobile={isMobile} dispatch={dispatch} />
  }

  renderFakePage() {
    const { fakePage } = this.props
    if (!fakePage.onClose) {
      fakePage.onClose = this.closeFakePage
    }

    return <FakePage {...fakePage} />
  }

  render() {
    const {
      app,
      signInWithToken,
      children,
      alert,
      popup,
      locale,
      country,
      fakePage,
      location,
    } = this.props

    if (signInWithToken?.loading)
      return (
        <>
          <Spacer size={40} />
          <Loader color="gray" kind="big" centered />
        </>
      )

    return (
      <div
        className={`locale-${locale} country-${country}${
          location?.pathname === urlFor.bookmateRun()
            ? ' bookmate-run-game'
            : ''
        }`}
      >
        {!(location?.search && /(\?|&)xba=(a|i)/.test(location.search)) && (
          <CookiesMessage
            app={app}
            country={country}
            disabledAt={[urlFor.goodNews()]}
          />
        )}

        {this.renderHead()}
        {!alert.hidden && this.renderAlert()}
        {!popup.authHidden && this.getPopup(<AuthBox />)}
        {!fakePage.hidden && this.renderFakePage()}
        {children}
      </div>
    )
  }
}

export default connectWrapper(AppWrapper)

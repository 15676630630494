import React, { Component } from 'react'

import './spacer.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

type NumericSize =
  | 4
  | 8
  | 16
  | 20
  | 24
  | 32
  | 40
  | 48
  | 56
  | 64
  | 80
  | 100
  | 120
  | 140
  | 180

type DimensionSize = {
  desktopLarge: NumericSize
  desktopMedium: NumericSize
  desktop: NumericSize
  tablet: NumericSize
  mobile: NumericSize
}

type NamedSize =
  | 'top-page'
  | 'top-page-compact'
  | 'bottom-page'
  | 'after-heading'

type Size = NumericSize | DimensionSize | NamedSize

type Props = {
  size: Size
} & DecoratorProps

class Spacer extends Component<Props> {
  static defaultProps = {
    size: 16,
  }

  render() {
    const { app, size: _size } = this.props
    let size = _size

    if (typeof size === 'string') {
      switch (size) {
        case 'top-page':
          size = {
            desktopLarge: 48,
            desktopMedium: 48,
            desktop: 48,
            tablet: 32,
            mobile: 32,
          }
          break
        case 'top-page-compact':
          size = {
            desktopLarge: 48,
            desktopMedium: 48,
            desktop: 32,
            tablet: 32,
            mobile: 16,
          }
          break
        case 'after-heading':
          size = {
            desktopLarge: 40,
            desktopMedium: 40,
            desktop: 40,
            tablet: 32,
            mobile: 16,
          }
          break
        case 'bottom-page':
          size = {
            desktopLarge: 64,
            desktopMedium: 64,
            desktop: 64,
            tablet: 64,
            mobile: 64,
          }
          break
        default:
          size = 16
      }
    }

    const sizeKind = typeof size === 'object' ? size[app.size] : size

    return <div className={`spacer spacer_${sizeKind}`}></div>
  }
}

export default uiBox(Spacer)

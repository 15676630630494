import { $Diff } from 'utility-types'

import React, { Component, ComponentType, ElementConfig } from 'react'
import { connect } from 'react-redux'

import { areAnySubscriptionDataLoading } from 'client/bookmate/selectors/subscription-selectors'

import { CurrentUserState } from 'client/shared/types/current-user'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { State } from 'shared/types/redux'

export type MarketingProps = {
  app: AppState
  subscription: SubscriptionState
  currentUser: CurrentUserState
  areAnySubscriptionDataLoading: boolean
}

export default function withMarketing<
  InProps extends MarketingProps,
  Com extends ComponentType<InProps>,
  OutProps extends $Diff<ElementConfig<Com>, MarketingProps>
>(DecoratedComponent: Com): ComponentType<OutProps> {
  class WithMarketingDecorator extends Component<ElementConfig<Com>> {
    areUserDataLoading() {
      const {
        currentUser,
        areAnySubscriptionDataLoading: _areAnySubscriptionDataLoading,
      } = this.props
      return !currentUser.completelyLoaded || _areAnySubscriptionDataLoading
    }

    render() {
      return <DecoratedComponent {...this.props} />
    }
  }

  return connect((state: State) => ({
    app: state.app,
    subscription: state.subscription,
    currentUser: state.currentUser,
    areAnySubscriptionDataLoading: areAnySubscriptionDataLoading(state),
  }))(WithMarketingDecorator)
}

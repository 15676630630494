import { schema, normalize } from 'normalizr'

import { cacheAction } from 'shared/middlewares/cache-middleware'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  createLibraryCard,
  updateLibraryCard,
} from 'client/bookmate/reducers/library-cards-reducer'

import {
  quoteSchema,
  QUOTE_LIKED,
  QUOTE_REMOVED,
} from 'client/bookmate/reducers/quotes-reducer'

import {
  impressionsSchema,
  addIdToImpressions,
  prepareImpressionsFromAPIResponse,
  IMPRESSION_ADDED,
  IMPRESSION_REMOVED,
  IMPRESSION_CHANGED,
  IMPRESSION_LIKED,
  IMPRESSION_CURRENT_USER_LOADED,
  IMPRESSION_CURRENT_USER_CLEAN,
} from 'client/bookmate/reducers/impressions-reducer'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
} from 'client/bookmate/reducers/comments-reducer'

import {
  shelfSchema,
  bookSchema,
  booksSchema,
  usersSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import { normalizeSeriesParts } from 'client/bookmate/helpers/series-helpers'

import { audioBooksSchema } from 'client/bookmate/reducers/schemas/schemas'
import { constants } from 'client/bookmate/constants'

import { loadBookAction } from './loadBookAction'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import { BookProps } from 'client/shared/types/book'
import { LibraryCardProps } from 'client/shared/types/library-card'
import { isEmpty } from 'lodash/fp'

const BOOK_LOAD = constants.BOOK_LOAD
const BOOK_LOAD_SUCCESS = constants.BOOK_LOAD_SUCCESS
const BOOK_LOAD_ERROR = constants.BOOK_LOAD_ERROR
export const BOOK_CHANGED = constants.BOOK_CHANGED

const BOOK_READERS_LOAD = constants.BOOK_READERS_LOAD
const BOOK_READERS_LOAD_SUCCESS = constants.BOOK_READERS_LOAD_SUCCESS

const BOOK_SHELVES_LOAD = constants.BOOK_SHELVES_LOAD
const BOOK_SHELVES_LOAD_SUCCESS = constants.BOOK_SHELVES_LOAD_SUCCESS

const BOOK_RELATED_LOAD = constants.BOOK_RELATED_LOAD
const BOOK_RELATED_LOAD_SUCCESS = constants.BOOK_RELATED_LOAD_SUCCESS

const BOOK_QUOTES_LOAD = constants.BOOK_QUOTES_LOAD
const BOOK_QUOTES_LOAD_SUCCESS = constants.BOOK_QUOTES_LOAD_SUCCESS

const BOOK_IMPRESSIONS_LOAD = constants.BOOK_IMPRESSIONS_LOAD
const BOOK_IMPRESSIONS_LOAD_SUCCESS = constants.BOOK_IMPRESSIONS_LOAD_SUCCESS

const BOOK_PUBLISHER_RESOURCES_LOAD = 'BOOK_PUBLISHER_RESOURCES_LOAD'
const BOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS =
  'BOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS'

const BOOK_REMOVED_FROM_WISHLIST = constants.BOOK_REMOVED_FROM_WISHLIST
const BOOK_REMOVED_FROM_WISHLIST_SUCCESS =
  constants.BOOK_REMOVED_FROM_WISHLIST_SUCCESS

const BOOK_ADD_TO_WISHLIST = constants.BOOK_ADD_TO_WISHLIST
const BOOK_ADD_TO_WISHLIST_SUCCESS = constants.BOOK_ADD_TO_WISHLIST_SUCCESS

const BOOK_OTHER_VERSIONS_LOAD = constants.BOOK_OTHER_VERSIONS_LOAD
const BOOK_OTHER_VERSIONS_LOAD_SUCCESS =
  constants.BOOK_OTHER_VERSIONS_LOAD_SUCCESS

const BOOK_EMOTION_RATING_LOAD = constants.BOOK_EMOTION_RATING_LOAD
const BOOK_EMOTION_RATING_LOAD_SUCCESS =
  constants.BOOK_EMOTION_RATING_LOAD_SUCCESS

const BOOK_SERIES_EPISODES_LOAD = constants.BOOK_SERIES_EPISODES_LOAD
const BOOK_SERIES_EPISODES_LOAD_SUCCESS =
  constants.BOOK_SERIES_EPISODES_LOAD_SUCCESS

export const load = loadBookAction

export const loadQuotes = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    const data = {
      page: p,
      per_page: pp,
      top: true,
    }

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/quotes`,
        options: { data },
        schema: new schema.Array(quoteSchema),
        responseKey: 'quotes',
        modifyResponse: response => ({
          updateField: index ? 'quotesIndex' : 'quotes',
          index,
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [BOOK_QUOTES_LOAD, BOOK_QUOTES_LOAD_SUCCESS],
      },
    }
  },
  BOOK_QUOTES_LOAD_SUCCESS,
  [QUOTE_LIKED, QUOTE_REMOVED, COMMENT_ADDED, COMMENT_REMOVED],
)

export function loadEmotionRating(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/emotion_ratings`,
      types: [BOOK_EMOTION_RATING_LOAD, BOOK_EMOTION_RATING_LOAD_SUCCESS],
    },
  }
}

export function loadPublisherResources(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/api/v5/books/${uuid}/publisher_resources`,
      types: [
        BOOK_PUBLISHER_RESOURCES_LOAD,
        BOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS,
      ],
      ignoreError: true,
    },
  }
}

export const loadImpressions = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/impressions`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionsFromAPIResponse(response.impressions),
            impressionsSchema,
          )

          return {
            result,
            entities: {
              ...entities,
              impressions: addIdToImpressions(
                entities.impressions,
                uuid,
                'book',
              ),
            },
            updateField: index ? 'impressionsIndex' : 'impressions',
            index,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [BOOK_IMPRESSIONS_LOAD, BOOK_IMPRESSIONS_LOAD_SUCCESS],
      },
    }
  },
  BOOK_IMPRESSIONS_LOAD_SUCCESS,
  [
    IMPRESSION_ADDED,
    IMPRESSION_CHANGED,
    IMPRESSION_REMOVED,
    IMPRESSION_LIKED,
    COMMENT_ADDED,
    COMMENT_REMOVED,
  ],
)

export const loadReaders = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, p = 1, pp = 15, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/readers`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [BOOK_READERS_LOAD, BOOK_READERS_LOAD_SUCCESS],
      },
    }
  },
  BOOK_READERS_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

// eslint-disable-next-line func-names
export const loadShelves = cacheAction(
  function ({ uuid, p = 1, pp = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/bookshelves`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: new schema.Array(shelfSchema),
        responseKey: 'bookshelves',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [BOOK_SHELVES_LOAD, BOOK_SHELVES_LOAD_SUCCESS],
      },
    }
  },
  BOOK_SHELVES_LOAD_SUCCESS,
  null,
)

export const loadRelated = cacheAction(
  // eslint-disable-next-line func-names
  function ({
    uuid,
    p = 1,
    pp = 20,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/related`,
        options: {
          data: {
            page: p,
            per_page: pp,
            order_by,
            order_direction,
          },
        },
        schema: booksSchema,
        responseKey: 'books',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [BOOK_RELATED_LOAD, BOOK_RELATED_LOAD_SUCCESS],
      },
    }
  },
  BOOK_RELATED_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

export const loadOtherVersions = cacheAction(
  // eslint-disable-next-line func-names
  function (uuid, type) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/variants/${type}`,
        schema: type === 'book' ? booksSchema : audioBooksSchema,
        responseKey: `${type}s`,
        modifyResponse: response => ({
          ...response,
          resourceType: type,
        }),
        types: [BOOK_OTHER_VERSIONS_LOAD, BOOK_OTHER_VERSIONS_LOAD_SUCCESS],
      },
    }
  },
  BOOK_OTHER_VERSIONS_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

export const loadOtherEpisodes = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, seriesUuid, p = 1, pp = 20, append = false }) {
    const data = {
      page: p,
      per_page: pp,
    }

    if (seriesUuid) {
      data.series_uuid = seriesUuid
    }

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/more_in_series`,
        options: { data },
        normalize: ({ parts }) => {
          const { result, entities } = normalizeSeriesParts(parts)

          return {
            result,
            entities,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [BOOK_SERIES_EPISODES_LOAD, BOOK_SERIES_EPISODES_LOAD_SUCCESS],
      },
    }
  },
  BOOK_SERIES_EPISODES_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

export function readInPrivate(
  _book: BookProps,
  libraryCardUuid: LibraryCardProps,
): ApiAction {
  const libraryCardState = {
    uuid: libraryCardUuid ? libraryCardUuid : _book.library_card_uuid,
    public: false,
  }

  if (_book.in_library) {
    return updateLibraryCard(libraryCardState)
  } else {
    return createLibraryCard(libraryCardState, _book)
  }
}

export function markAsRead(
  _book: BookProps,
  libraryCardUuid: LibraryCardProps,
): ApiAction {
  const libraryCardState = {
    uuid: libraryCardUuid ? libraryCardUuid : _book.library_card_uuid,
    state: 'finished',
  }

  if (_book.in_library) {
    return updateLibraryCard(libraryCardState)
  } else {
    return createLibraryCard(libraryCardState, _book)
  }
}

export function addToWishlist(_book: BookProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/wishlisted_books`,
      options: {
        method: 'post' as FetchMethod,
        data: {
          uuid: _book.uuid,
        },
      },
      schema: bookSchema,
      responseKey: 'wishlisted_book',
      types: [BOOK_ADD_TO_WISHLIST, BOOK_ADD_TO_WISHLIST_SUCCESS],
    },
  }
}

export function removeFromWishlist(_book: BookProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/wishlisted_books/${_book.uuid}`,
      options: {
        method: 'delete' as FetchMethod,
        dontParse: true,
      },
      modifyResponse: () => ({
        uuid: _book.uuid,
      }),
      types: [BOOK_REMOVED_FROM_WISHLIST, BOOK_REMOVED_FROM_WISHLIST_SUCCESS],
    },
  }
}

const initialState = {
  loading: true,
  uuid: '',
  in_wishlist: undefined,
  is_uploaded: false,
  library_card_uuid: '',
  impressionsIndex: [],
  impressions: [],
  quotesIndex: [],
  quotes: [],
  shelves: [],
  related: [],
  otherEpisodes: [],
  otherVersions: {},
  readers: [],
  pages: {
    impressions: 1,
    quotes: 1,
    related: 1,
    readers: 1,
    shelves: 1,
    otherEpisodes: 1,
  },
  publisherResources: [],
  emotionRating: [],
  currentUserImpressionUuid: null,
}

export default function book(state = initialState, action = {}) {
  switch (action.type) {
    case BOOK_LOAD:
      return initialState

    case BOOK_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        library_card_uuid: action.library_card_uuid,
        uuid: action.result,
        error: null,
      }

    case BOOK_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        uuid: '',
        error: action.error,
      }

    case BOOK_READERS_LOAD_SUCCESS:
      return {
        ...state,
        readers: action.append
          ? [...state.readers, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          readers: action.nextPage,
        },
      }

    case BOOK_SHELVES_LOAD_SUCCESS:
      return {
        ...state,
        shelves: action.append
          ? [...state.shelves, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          shelves: action.nextPage,
        },
      }

    case BOOK_RELATED_LOAD_SUCCESS:
      return {
        ...state,
        related: action.append
          ? [...state.related, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          related: action.nextPage,
        },
      }

    case BOOK_OTHER_VERSIONS_LOAD_SUCCESS: {
      const resourceType = `${action.resourceType}s`
      const resources = action.entities[resourceType]
      if (isEmpty(resources)) return state
      return {
        ...state,
        otherVersions: {
          ...state.otherVersions,
          [resourceType]: Object.values(resources),
        },
      }
    }

    case BOOK_SERIES_EPISODES_LOAD_SUCCESS: {
      return {
        ...state,
        otherEpisodes: action.append
          ? [...state.otherEpisodes, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          otherEpisodes: action.nextPage,
        },
      }
    }

    case BOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS:
      return {
        ...state,
        publisherResources: action.books,
      }

    case BOOK_IMPRESSIONS_LOAD_SUCCESS:
    case BOOK_QUOTES_LOAD_SUCCESS: {
      const { updateField, result, index, append } = action

      return {
        ...state,
        [updateField]:
          index || !append ? result : [...state[updateField], ...result],
        pages: {
          ...state.pages,
          [updateField]: action.nextPage,
        },
      }
    }

    case QUOTE_REMOVED:
      return {
        ...state,
        quotesIndex: state.quotesIndex.filter(uuid => uuid !== action.uuid),
        quotes: state.quotes.filter(uuid => uuid !== action.uuid),
      }

    case IMPRESSION_REMOVED:
      if (action.resourceType === 'book') {
        return {
          ...state,
          impressionsIndex: state.impressionsIndex.filter(
            uuid => uuid !== action.uuid,
          ),
          impressions: state.impressions.filter(uuid => uuid !== action.uuid),
        }
      } else {
        return state
      }

    case BOOK_CHANGED:
      if (action.book.uuid !== state.uuid) {
        return state
      }

      return {
        ...state,
        library_card_uuid: action.book.library_card_uuid,
      }

    case BOOK_EMOTION_RATING_LOAD_SUCCESS:
      return {
        ...state,
        emotionRating: action.emotion_ratings,
      }
    case IMPRESSION_CURRENT_USER_LOADED: {
      const { resourceUuid, resourceType, uuid } = action.data

      if (resourceType === 'book' && resourceUuid === state.uuid) {
        return {
          ...state,
          currentUserImpressionUuid: uuid,
        }
      } else {
        return state
      }
    }

    case IMPRESSION_CURRENT_USER_CLEAN:
      return {
        ...state,
        currentUserImpressionUuid: null,
      }

    default:
      return state
  }
}

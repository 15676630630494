import { CALL_API, UNKNOWN_ERROR_ID } from 'shared/middlewares/api-middleware'
import { push } from 'react-router-redux'

import urlFor from 'shared/tools/url-helper'

import {
  loadUserAccessLevels,
  loadUserSubscriptions,
} from 'client/bookmate/reducers/subscription-reducer'
import {
  analyticsEvent,
  SUBSCRIPTION_FAILED,
  SUBSCRIPTION_INITIATED,
  SUBSCRIPTION_SUCCESSFUL,
} from 'client/shared/reducers/analytics-reducer'
import { Dispatch, GetState, ThunkAction } from 'shared/types/redux'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import { DK_SHELF_UUID, DK_SUMMER_SHELF_UUID } from '../boxes/follow-button-box'
import { getPromocodeFromUrl } from '../../../shared/tools/utilities/get-promocode-from-url'

// PROMO CODES
// eslint-disable-next-line no-shadow
export enum promoCodeList {
  dk_burns = 'BRUUNSFRIENDS2024',
}
// SHELVES
export const promoCodeShelves: Record<promoCodeList, string> = {
  [promoCodeList.dk_burns]: 'Gdbdkpzm',
}

const CODE_SET = 'CODE_SET'
const CODE_CHECKED = 'CODE_CHECKED'
const CODE_CHECKING = 'CODE_CHECKING'
const CODE_ERROR = 'CODE_CHECKING'

type CodeSetAction = {
  type: 'CODE_SET'
  data: {
    codeValue: string
    autocomplete: boolean
    error?: string
  }
}

type CodeCheckingAction = {
  type: 'CODE_CHECKING'
}

type CodeCheckedAction = {
  type: 'CODE_CHECKED'
  data: {
    status: string
    credit_card_prompt: {
      title: string
      payment_conditions: string
      button_label: string
    }
  }
}

type Action =
  | CodeSetAction
  | CodeCheckingAction
  | CodeCheckedAction
  | (ThunkAction & { type: string; data: Record<string, unknown> })

export type State = {
  codeValue: string
  autocomplete: boolean
  credit_card_prompt: {
    title: string
    payment_conditions: string
    button_label: string
    advantages: string[]
    plan: {
      currency: string
      id: string
      payment_scheme:
        | 'recurrent_after_free_period'
        | 'recurrent_after_paid_period'
      price: number
    }
  }
  status: string
  loading: boolean
  error: string | null | undefined
  redirectUrl?: string | null | undefined
}

export function setCode({
  codeValue,
  redirectUrl,
  autocomplete = false,
  error,
}: {
  codeValue: string
  redirectUrl?: string | null | undefined
  autocomplete?: boolean
  error?: string
}): CodeSetAction {
  if (Array.isArray(codeValue)) codeValue = codeValue[0]
  const redirectUrlWrapper = redirectUrl ? { redirectUrl } : {}
  return {
    type: CODE_SET,
    data: {
      codeValue,
      autocomplete,
      error,
      ...redirectUrlWrapper,
    },
  }
}

export function checkCode({
  codeValue,
  redirect_to = '',
  query = '',
}: {
  codeValue: string
  redirect_to?: string
  query?: string
}): ThunkAction {
  return async dispatch => {
    dispatch(
      analyticsEvent(SUBSCRIPTION_INITIATED, {
        sub_type: 'code',
        is_trial: false,
      }),
    )

    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/profile/promocodes`,
        options: {
          method: 'post' as FetchMethod,
          data: {
            code: codeValue,
          },
        },
        normalize: (res: { status: string; credit_card_prompt: string }) => {
          const { status, credit_card_prompt } = res

          if (status !== 'activated') {
            return {
              data: { status, credit_card_prompt },
            }
          } else {
            return {
              type: CODE_CHECKED,
              data: {
                status,
              },
            }
          }
        },
        onSuccess: (
          _dispatch: Dispatch,
          getState: GetState,
          res: { data: { status: string } },
        ) => {
          _dispatch(
            analyticsEvent(SUBSCRIPTION_SUCCESSFUL, {
              payment_method: 'code',
              is_trial: false,
            }),
          )
          _dispatch(loadUserSubscriptions())
          _dispatch(loadUserAccessLevels())

          let newQuery = query

          if (codeValue in promoCodeShelves) {
            if (query === '') newQuery = '?'
            if (newQuery !== '?') {
              newQuery += '&'
            }
            newQuery += `promocode=${encodeURIComponent(codeValue)}`
          }

          _dispatch(
            redirectToCorrectPage({
              action: res.data.status,
              redirect: redirect_to,
              query: newQuery,
            }),
          )
        },
        onError: (
          _dispatch: Dispatch,
          getState: GetState,
          error: { error: { message: string } } | string,
        ) => {
          let errorMessage = ''
          if (typeof error === 'string') {
            errorMessage = error
          } else if (error && typeof error === 'object') {
            errorMessage = error.error?.message
          } else {
            errorMessage = UNKNOWN_ERROR_ID
          }

          const analyticsPayload: {
            payment_method: string
            is_trial: boolean
            reason: string
          } = {
            payment_method: 'code',
            is_trial: false,
            reason: errorMessage,
          }

          _dispatch({
            type: CODE_CHECKED,
            data: {
              error: errorMessage,
            },
          })
          _dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))
          _dispatch(
            redirectToCorrectPage({ action: 'error', redirect: redirect_to }),
          )
        },
        types: [CODE_CHECKING, CODE_CHECKED, CODE_ERROR],
      },
    })
  }
}

export function redirectToCorrectPage({
  action,
  redirect = '',
  query = '',
}: {
  action: string
  redirect?: string
  query?: string
}): ThunkAction {
  return async dispatch => {
    if (action === 'share_with_friend' && query.includes(DK_SHELF_UUID)) {
      return push(urlFor.shelf(DK_SHELF_UUID))
    } else if (
      action === 'share_with_friend' &&
      query.includes(DK_SUMMER_SHELF_UUID)
    ) {
      return push(urlFor.shelf(DK_SUMMER_SHELF_UUID))
    }

    if (action === 'activated') {
      const promocode = decodeURIComponent(getPromocodeFromUrl(query) ?? '')
      if (promocode in promoCodeShelves)
        return push(urlFor.shelf(promoCodeShelves[promocode]))
    }

    switch (action) {
      case 'requires_credit_card':
        return dispatch(
          push(urlFor.codePage(`${redirect}subscription${query}`)),
        )
      case 'share_with_friend':
        return dispatch(push(urlFor.codePage(`${redirect}share`)))
      case 'activated':
        return dispatch(push(urlFor.codePage(`${redirect}success${query}`)))
      case '3d_secure_flow_initiated':
        return dispatch(push(redirect))
      case 'error':
        return (
          redirect === 'gorkypark/' &&
          dispatch(push(urlFor.codePage(`${redirect}error`)))
        )
    }
  }
}

const initialState = {
  codeValue: '',
  status: '',
  autocomplete: false,
  loading: false,
  error: null,
  credit_card_prompt: {},
}

export default function code(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case CODE_SET:
      return {
        ...state,
        ...action.data,
      }

    case CODE_CHECKED:
      return <State>{
        ...state,
        loading: false,
        ...action.data,
      }

    case CODE_CHECKING:
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

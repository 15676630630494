import env from 'env'

import { setCurrentLocationHref } from 'client/shared/reducers/app-reducer'

const SCREEN_SHOWN = 'Screen Shown'
export const ERROR_SHOWN = 'Error Shown'
export const RECOMMENDATIONS_SHOWN = 'Recommendations Shown'

const ANALYTICS_DEFER_EVENT = 'ANALYTICS_DEFER_EVENT'
const ANALYTICS_FLUSH_EVENTS = 'ANALYTICS_FLUSH_EVENTS'

const ANALYTICS_EVENT = 'ANALYTICS_EVENT'
export const SHARE_BUTTON_PRESSED = 'Share Button Pressed'
export const LIKE_BUTTON_PRESSED = 'Like Button Pressed'
export const BOOK_ADDED = 'Book Added'
export const AUDIOBOOK_ADDED = 'Audiobook Added'
export const COMICBOOK_ADDED = 'Comic Added'
export const FRIEND_FOLLOW = 'User Follow'
export const SHELF_FOLLOW_ANALYTICS = 'Shelf Follow'

export const SUBSCRIPTION_INITIATED = 'Subscription Initiated'
export const SUBSCRIPTION_SUCCESSFUL = 'Subscription Successful'
export const SUBSCRIPTION_FAILED = 'Subscription Failed'

export const PAYMENT_METHOD_SELECTED = 'Payment Method Selected'
export const DURATION_SELECTED = 'Duration Selected'
export const RECURRENT_SUBSCRIPTION_STOPPED = 'Recurrent Subscription Stopped'
export const PLEDGE_MADE = 'Pledge Made'
export const GIFT_PURCHASED = 'Gift Purchased'

export const SEARCH_RESULTS_LOADED = 'Search Results Loaded'

export const UPLOAD_BUTTON_PRESSED = 'Upload Book Button Pressed'

export const IMPRESSION_CREATED = 'Impression Created'

export const AUTHENTICATION_POPUP_SHOWN = 'Authentication Popup Shown'
export const AUTHENTICATION_INITIATED = 'Authentication Initiated'
export const AUTHENTICATION_SUCCESSFUL = 'Authentication Successful'
export const AUTHENTICATION_FAILED = 'Authentication Failed'
export const REGISTRATION_CONFIRM = 'Registration Confirm'
export const REGISTRATION_SUCCESSFUL = 'Registration Successful'
export const RESET_PASSWORD_REQUESTED = 'Reset Password Requested'
export const VERIFICATION_CODE_REQUESTED = 'Verification Code Requested'

export const FIND_FRIEND_INITIATED = 'Friend Finder Initiated'
export const MAILING_SUBSCRIPTIONS_CHANGED = 'Mailing Subscriptions Changed'
export const LOGGED_OUT = 'Logged Out'
export const ACCOUNT_DELETED = 'Account Deleted'

export const SHELF_CREATED = 'Shelf Created'
export const SHELF_POST_CREATED = 'Shelf Post Created'

export const OBJECT_REMOVED = 'Object Removed'
export const REPORT_ERROR = 'Report Error'

export const TOP_ADVERT_SIGN_UP = 'TopAdvert SignUp'

export const CONTENT_READ = 'content_read'
export const CONTENT_ADD = 'content_add'
export const CONTENT_VIEWED = 'content_view'
export const CONTENT_LIST_CLICK = 'content_list_click'

const initialState = {
  events: [],
}

export type AnalyticsData = {
  event_type: string
  event_datetime: string
  user_id: number
  screen: string
  list_uuid?: string
  list_type?: string
  list_position?: number | string
  content_type: string
  content_uuid: string
  content_position: string | number
  screen_uuid?: string
}

export const AnalyticsDataInitialState: AnalyticsData = {
  event_type: '',
  event_datetime: '',
  user_id: 0,
  screen: '',
  list_uuid: '',
  list_type: '',
  list_position: 0,
  content_type: '',
  content_uuid: '',
  content_position: '',
}

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case ANALYTICS_DEFER_EVENT:
      return {
        events: [...state.events, action],
      }

    case ANALYTICS_FLUSH_EVENTS:
      return {
        events: [],
      }

    default:
      return state
  }
}

export function screenShown() {
  return dispatch => {
    dispatch({
      type: ANALYTICS_EVENT,
      meta: {
        analytics: {
          type: SCREEN_SHOWN,
          payload: {},
        },
      },
    })

    if (env.isClient()) {
      dispatch(setCurrentLocationHref(window.location.href))
    }
  }
}

export function analyticsEvent(
  eventName = 'UNDEFINED_EVENT',
  payloadData = {},
) {
  return {
    type: ANALYTICS_EVENT,
    meta: {
      analytics: {
        type: eventName,
        payload: payloadData,
      },
    },
  }
}

export function deferEvent(type, payload) {
  return {
    type: ANALYTICS_DEFER_EVENT,
    meta: {
      analytics: {
        type,
        payload,
      },
    },
  }
}

export function flushEvents() {
  return {
    type: ANALYTICS_FLUSH_EVENTS,
  }
}

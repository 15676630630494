import { $Diff } from 'utility-types'

import React, { Component, ComponentType, ElementConfig } from 'react'
import { connect } from 'react-redux'
import camelCase from 'lodash/camelCase'
import { withRouter } from 'react-router'
import {
  isUserRegistered,
  isUserRegistrationChecked,
  shouldAllowAuth,
  shouldShowPreSignupFields,
} from 'client/shared/selectors/auth-selectors'

import {
  analyticsEvent,
  AUTHENTICATION_POPUP_SHOWN,
} from 'client/shared/reducers/analytics-reducer'
import * as authActions from 'client/shared/reducers/auth-reducer'
import {
  authAdditionFlow,
  AuthStep,
  State as AuthState,
} from 'client/shared/reducers/auth-reducer'

import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from 'client/shared/types/current-user'

import { AUTOFILL_CHECK_TIMEOUT } from 'client/shared/constants/time-constants'
import type { Exact } from 'types/local/exact-check'
import { Recaptcha } from '../blocks/recaptcha/recaptcha'
import { handleRecaptсhaV3 } from '../helpers/recaptcha-helpers'
import { loginMode } from '../../../shared/constants/login.constants'

export type AuthProps = Exact<DecoratorProps> & {
  withAuth: true
  lockSubmit: boolean
  loading: boolean
  error: string | null | undefined
  phone: string
  onSocialButtonClick: (
    arg0: string,
  ) => {
    [key: string]: any
  }
}

type DecoratorProps = {
  auth: AuthState
  currentUser: CurrentUserState
  shouldShowPreSignupFields: boolean
  shouldAllowAuth: boolean
  isUserRegistrationChecked: boolean
  isUserRegistered: boolean
  dispatch: Dispatch
}

export default function withAuth(options: { initialStep: AuthStep }) {
  return function decorator<Com extends ComponentType<any>>(
    DecoratedComponent: Com,
  ): ComponentType<$Diff<ElementConfig<Com>, AuthProps>> {
    class WithAuthDecorator extends Component<
      $Diff<ElementConfig<Com>, DecoratorProps>
    > {
      form: HTMLFormElement | null | undefined = null

      componentDidMount() {
        const { dispatch } = this.props
        dispatch(analyticsEvent(AUTHENTICATION_POPUP_SHOWN))
        dispatch(authActions.changeStep(options.initialStep))
        this.detectAutofill()
      }

      componentWillUnmount() {
        this.props.dispatch(authActions.changeStep(options.initialStep))
      }

      shouldUseRecaptcha() {
        const {
          auth: { currentStep },
        } = this.props

        return ['phone', 'email'].includes(currentStep)
      }

      submitForm = (token?: string) => {
        this.synchronizeWithRedux()

        let authAdditionFlowObj: authAdditionFlow | undefined

        // MondiaPay sign up
        if (
          this.props.params?.loginmode === loginMode.mondiaPay &&
          'authcode' in this.props.params
        ) {
          authAdditionFlowObj = { mondiaPay: this.props.params.authcode }
        }

        this.props.dispatch(
          authActions.handleAuthenticationStep(
            this.shouldUseRecaptcha(),
            token,
            authAdditionFlowObj,
          ),
        )
      }

      handleSubmit = (event: Event) => {
        event.preventDefault()
        if (this.shouldUseRecaptcha()) {
          const {
            auth: { flow },
          } = this.props

          const action = `${flow === 'signup' ? 'sign_up' : 'sign_in'}/password`

          handleRecaptсhaV3(action, token => {
            this.submitForm(token)
          })
        } else {
          this.submitForm()
        }
      }

      handleChange = () => {
        const { dispatch } = this.props
        if (!this.form) return

        this.synchronizeWithRedux()
        if (this.validate(this.form)) {
          dispatch(authActions.unlockSubmit())
        } else {
          dispatch(authActions.lockSubmit())
        }
      }

      synchronizeWithRedux() {
        if (!this.form) return

        const { dispatch } = this.props

        const formValues = [...this.form.elements].reduce((result, element) => {
          let name = element.getAttribute('name')
          name = name && camelCase(name) // to avoid hyphens or underscores in object key names
          if (name && element instanceof HTMLInputElement) {
            return { ...result, [name]: element.value.trim() }
          } else {
            return result
          }
        }, {})

        dispatch(authActions.syncAuthValues(formValues))
      }

      validate(form) {
        if (!(form && form.elements)) return false

        return [...form.elements]
          .filter((elem): boolean => elem.nodeName === 'INPUT')
          .every((elem: HTMLInputElement) => {
            try {
              return (
                (elem.type === 'password' && elem.value.length > 3) ||
                (elem.type !== 'password' && elem.value.length > 0) ||
                elem.matches(':-webkit-autofill')
              ) // Chrome-autofilled field
            } catch (e) {
              return false
            }
          })
      }

      handleSocialButtonClick = provider => {
        this.props.dispatch(authActions.initSocialAuth(provider))
      }

      shouldLockSubmit() {
        const {
          auth: {
            ui: { currentFormLockSubmit },
          },
        } = this.props

        return currentFormLockSubmit
      }

      detectAutofill() {
        setTimeout(() => {
          this.handleChange()
        }, AUTOFILL_CHECK_TIMEOUT)
      }

      render() {
        const {
          auth: { loading, error, phone },
        } = this.props

        return (
          <form
            className="auth-form"
            ref={element => (this.form = element)}
            onSubmit={this.handleSubmit}
            onChange={this.handleChange}
          >
            {this.shouldUseRecaptcha() && <Recaptcha />}
            <DecoratedComponent
              {...this.props}
              withAuth
              phone={phone}
              lockSubmit={this.shouldLockSubmit()}
              loading={loading}
              error={error}
              onSocialButtonClick={this.handleSocialButtonClick}
            />
          </form>
        )
      }
    }

    return withRouter(
      connect((state: State) => {
        return {
          auth: state.auth,
          currentUser: state.currentUser,
          shouldShowPreSignupFields: shouldShowPreSignupFields(state),
          shouldAllowAuth: shouldAllowAuth(state),
          isUserRegistrationChecked: isUserRegistrationChecked(state),
          isUserRegistered: isUserRegistered(state),
          locale: state.currentUser.data.locale,
        }
      })(WithAuthDecorator),
    )
  }
}

import URL from 'url-parse'
import queryString from 'query-string'

import env from 'env'

function getServerUrlInfo(app) {
  if (app.url) {
    const parsedUrl = new URL(app.url)

    return {
      pathname: parsedUrl.pathname,
      query: parsedUrl.search,
    }
  }

  return {
    pathname: '',
    query: '',
  }
}

export default {
  getPathname(state) {
    const { app } = state
    let pathname

    if (env.isServer()) {
      pathname = getServerUrlInfo(app).pathname
    } else {
      pathname = state.routing.locationBeforeTransitions.pathname
    }

    return pathname
  },

  hasQueryParams(state, params) {
    const { app } = state
    let query

    if (env.isServer()) {
      query = getServerUrlInfo(app).query
    } else {
      query = state.routing.locationBeforeTransitions.search
    }

    const parsedQuery = queryString.parse(query)

    return Object.keys(params).every(key => params[key] === parsedQuery[key])
  },
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import Spacer from 'client/shared/blocks/spacer'

import { CurrentUserState } from 'client/shared/types/current-user'

import { SubscriptionDescriptionAdvantage } from './subscription-description-advantage'
import { StyledBadge } from 'client/shared/blocks/badge'

import './subscription-step.styl'
import { State } from 'shared/types/redux'

type Props = {
  header?: string
  title?: React.ReactNode
  number?: number
  total?: number
  withCounter: boolean
  disabled: boolean
  description?: React.ReactNode
  children: React.ReactNode
  currentUser: CurrentUserState
  badge?: { text: string; color: string }
  isDarkTheme?: boolean
}

export const SubscriptionStepInner: React.FC = props => {
  return <div className="subscription-step-inner">{props.children}</div>
}

class SubscriptionStep extends Component<Props> {
  static defaultProps = {
    disabled: false,
    withCounter: true,
  }

  render(): JSX.Element {
    const {
      withCounter,
      number,
      total,
      header,
      title,
      children,
      description,
      disabled,
      badge,
      isDarkTheme,
    } = this.props

    const blockClassName = cn('subscription-step', {
      'subscription-step_disabled': disabled,
      'subscription-step_mode-dark': isDarkTheme,
      'subscription-step_mode-light': !isDarkTheme,
    })

    return (
      <div className={blockClassName}>
        {disabled && <div className="subscription-step__screen" />}
        {badge && <StyledBadge color={badge.color}>{badge.text}</StyledBadge>}
        {description && Array.isArray(description) && description.length && (
          <>
            <ul className="subscription-description-advantages">
              {Array.isArray(description) &&
                description.map((advantage: string) => (
                  <SubscriptionDescriptionAdvantage advantage={advantage} />
                ))}
            </ul>
            <Spacer size={24} />
          </>
        )}
        {withCounter && number && total && (
          <div className="subscription-step__counter">
            <Trans id="subscription.step_counter" values={{ number, total }} />
            <Spacer size={20} />
          </div>
        )}
        {header && (
          <h3 className="subscription-step__header">
            <Trans id={header} />
          </h3>
        )}
        {title && <h3 className="subscription-step__header">{title}</h3>}
        {children}
      </div>
    )
  }
}

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  app: state.app,
}))

const wrappers = compose(connectWrapper)

export default wrappers(SubscriptionStep)

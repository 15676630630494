import omit from 'lodash/omit'

import { decodeEntities } from 'client/shared/helpers/text-helpers'

export default (html, options = { allowedTags: [] }) => {
  if (IS_CLIENT) {
    return decodeEntities(
      require('dompurify').sanitize(html, {
        ALLOWED_TAGS: options.allowedTags,
        ...omit(options, 'allowedTags'),
      }),
    )
  } else {
    return require('sanitize-html')(html, options)
  }
}

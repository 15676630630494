import React from 'react'
import SVGInline from 'react-svg-inline'

type Props = {
  paymentSystem: string
}

function getCardLogoImg(paymentSystem) {
  paymentSystem = paymentSystem.toLowerCase()
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`client/shared/icons/credit-card-${paymentSystem}.svg`)
      .default
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    return require(`client/shared/icons/credit-card-default.svg`).default
  }
}

export default function CardLogo(props: Props): JSX.Element {
  const { paymentSystem } = props

  return (
    <SVGInline
      key="card-logo"
      svg={getCardLogoImg(paymentSystem)}
      className="stripe-credit-card__payment-logo"
    />
  )
}

import React, { Component } from 'react'
import StoreButtonBox from 'client/shared/boxes/store-button-box'
import './footer-store-buttons.styl'

type Props = {
  kind: 'inline' | 'block'
  campaign?: string
  stage?: string
  size?: 'big' | 'small'
  theme?: string
  order: ('ios' | 'android')[]
  inverted: boolean
  path?: string
}

class FooterStoreButtons extends Component<Props> {
  static defaultProps = {
    size: 'small',
    order: ['ios', 'android'],
    inverted: false,
  }

  render() {
    const {
      kind,
      campaign,
      stage,
      size,
      theme,
      order,
      inverted,
      path,
    } = this.props

    return (
      <div className={`footer__storebuttons footer__storebuttons_${kind}`}>
        {order.map(platform => (
          <div key={platform} className="footer__storebutton">
            <StoreButtonBox
              platform={platform}
              size={size}
              campaign={campaign}
              stage={stage}
              theme={theme}
              inverted={inverted}
              width="100%"
              path={path}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default FooterStoreButtons

import dedent from 'dedent'
import { URL } from 'url'
import qs from 'query-string'

export const addDataToUrl = (rawUrl, data) => {
  const url = new URL(rawUrl)

  Object.keys(data).forEach(key =>
    url.searchParams.append(key, JSON.stringify(data[key])),
  )

  return url.toString()
}

export const makeRedirectUrl = (prefix, providerName, url) =>
  url || `${prefix}/connect/${providerName}/callback`

export const makeCallbackUrl = (prefix, providerName, url) =>
  url || `${prefix}${providerName}/send`

export const addState = (url, state, key = 'mobileState') => dedent`
  ${url}
  ${url.includes('?') ? '&' : '?'}
  ${qs.stringify({ [key]: JSON.stringify(state) })}
`

export const makeMobileUrl = (name, state) =>
  addState(`/connect/${name}`, state)

import { schema, normalize, denormalize } from 'normalizr'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import find from 'lodash/find'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import { createRedirectForResource } from 'shared/tools/url-helper'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'

import {
  analyticsEvent,
  LIKE_BUTTON_PRESSED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'
import { BOOK_CHANGED } from 'client/bookmate/reducers/book-reducer'
import {
  COMICBOOK_CARD_ADDED,
  COMICBOOK_CARD_REMOVED,
} from 'client/bookmate/reducers/comicbooks-reducer'

import {
  userSchema,
  bookSchema,
  audioBookSchema,
  comicbookSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import { quoteSchema } from 'client/bookmate/reducers/quotes-reducer'
import { impressionSchema } from 'client/bookmate/reducers/impressions-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'

import { throttledLikeHelper } from 'shared/tools/api-helpers'

const POST_LOADING = 'POST_LOADING'
const POST_LOADED = 'POST_LOADED'
const POST_LIKE = 'POST_LIKE'
export const POST_LIKED = 'POST_LIKED'
export const POST_ADDED = 'POST_ADDED'
export const POST_CHANGED = 'POST_CHANGED'
const POST_REMOVE = 'POST_REMOVE'
export const POST_REMOVED = 'POST_REMOVED'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
  LikeToggleProps,
} from 'client/bookmate/reducers/comments-reducer'

const postResourceSchema = new schema.Union(
  {
    book: bookSchema,
    comicbook: comicbookSchema,
    quote: quoteSchema,
    impression: impressionSchema,
    audiobook: audioBookSchema,
  },
  (input, parent) => {
    return parent.resourceType
  },
)

export const postSchema = new schema.Entity(
  'posts',
  {
    creator: userSchema,
    resource: postResourceSchema,
  },
  { idAttribute: 'uuid' },
)

export const postsSchema = new schema.Array(postSchema)

const getPostRedirectUrl = createRedirectForResource('post')

export function getPost(postId, entities) {
  return denormalize(postId, postSchema, entities)
}

export function getPosts(postIds, entities) {
  return denormalize(postIds, postsSchema, entities)
}

// each post returned from our API has the `response_type` field,
// which for quotes says `marker`; since `marker` is not in our domain language;
// we modify each post by adding the `responseType` field
export function preparePostFromAPIResponse(post) {
  if (['impression', 'quote', 'marker'].includes(post.resource_type)) {
    post.resource.embeddedResourceType = post.resource.resource_type
  }

  return {
    ...post,
    resourceType:
      post.resource_type === 'marker' ? 'quote' : post.resource_type,
  }
}

// applies preparePostFromAPIResponse to each post in an array returned from the API
export function preparePostsFromAPIResponse(_posts) {
  return _posts.map(post => preparePostFromAPIResponse(post))
}

function findPostByResourceId(resourceId, resourceType, state) {
  return find(
    state,
    post =>
      post.resourceType === resourceType && post.resource.id === resourceId,
  )
}

export function singularizePostResourceType(resourceTypePlural) {
  return resourceTypePlural.substring(0, resourceTypePlural.length - 1)
}

function toggleLike({ uuid, liked }: LikeToggleProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/posts/${uuid}/likers`,
      options: {
        method: liked ? 'delete' : 'post',
        dontParse: true,
      },
      onSuccess: dispatch => {
        dispatch(
          analyticsEvent(LIKE_BUTTON_PRESSED, {
            object_type: 'shelf_post',
            object_id: uuid,
          }),
        )
      },
      types: [POST_LIKE, POST_LIKED],
    },
    uuid,
    liked,
  }
}

export const throttledToggleLike = throttledLikeHelper(toggleLike, POST_LIKED)

export function loadPost(shelfUuid, uuid, params = {}) {
  const { ignoreError = false } = params

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${shelfUuid}/posts/${uuid}`,
      normalize: (response, { getState }) => {
        const {
          post: { uuid: correctPostId },
        } = response

        if (uuid !== correctPostId) {
          const currentPath = getState().app.url
          serverRedirectTo(getPostRedirectUrl(correctPostId, currentPath), 301)
        }

        const post = preparePostFromAPIResponse(response.post)
        const { result, entities } = normalize(post, postSchema)

        return {
          post,
          result,
          entities,
        }
      },
      types: [POST_LOADING, POST_LOADED],
      ignoreError,
    },
  }
}

export function remove(shelfUuid, uuid) {
  return async (dispatch, getState) => {
    const state = getState()

    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/bookshelves/${shelfUuid}/posts/${uuid}`,
        options: {
          method: 'delete',
          dontParse: true,
        },
        modifyResponse: () => {
          const {
            resource: { resourceType },
          } = getPost(uuid, state.entities) // get resource type of the removed post

          return {
            uuid,
            shelfUuid,
            resourceType,
          }
        },
        onSuccess: _dispatch => {
          const { resource } = getPost(uuid, state.entities)
          const { resourceType } = resource
          const messageId = `alerts.post_${resourceType}_deleted`
          _dispatch(
            showAlert('success', {
              message: messageId,
              values: getValuesForRemovalMessage(resource),
            }),
          )
          _dispatch(
            analyticsEvent(OBJECT_REMOVED, {
              object_type: 'shelf_post',
              object_id: uuid,
            }),
          )
        },
        types: [POST_REMOVE, POST_REMOVED],
      },
    })
  }
}

function getValuesForRemovalMessage(resource) {
  if (['book', 'audiobook', 'comicbook'].includes(resource.resourceType)) {
    return { title: resource.title }
  } else if (resource.resourceType === 'quote') {
    return { title: resource.book.title }
  } else {
    return {}
  }
}

const initialState = {}

export default function posts(state = initialState, action) {
  if (action.entities && action.entities.posts) {
    return merge({}, state, action.entities.posts)
  }

  switch (action.type) {
    case POST_ADDED:
      return {
        ...state,
        [action.post.uuid]: action.post,
      }

    case POST_CHANGED: {
      const changedPost = state[action.post.uuid]

      return {
        ...state,
        [action.post.uuid]: {
          ...changedPost,
          ...action.post,
        },
      }
    }

    case POST_REMOVED:
      return omit(state, action.uuid)

    case POST_LIKED: {
      const post = state[action.uuid]

      if (action.liked !== post.liked) {
        return state
      }

      return {
        ...state,
        [action.uuid]: {
          ...post,
          likes: action.liked ? post.likes - 1 : post.likes + 1,
          liked: !action.liked,
        },
      }
    }

    case BOOK_CHANGED: {
      if (!['added', 'removed'].includes(action.reason)) return state
      const postWithChangedBook = findPostByResourceId(
        action.book.uuid,
        'book',
        state,
      )
      if (!postWithChangedBook) return state

      return {
        ...state,
        [postWithChangedBook.uuid]: {
          ...postWithChangedBook,
          readers_count:
            action.reason === 'added'
              ? postWithChangedBook.readers_count + 1
              : postWithChangedBook.readers_count - 1,
        },
      }
    }

    case COMICBOOK_CARD_ADDED:
    case COMICBOOK_CARD_REMOVED: {
      const postWithChangedComicbook = findPostByResourceId(
        action.comicbook.uuid,
        'comicbook',
        state,
      )
      if (!postWithChangedComicbook) return state

      return {
        ...state,
        [postWithChangedComicbook.uuid]: {
          ...postWithChangedComicbook,
          readers_count:
            action.type === COMICBOOK_CARD_ADDED
              ? postWithChangedComicbook.readers_count + 1
              : postWithChangedComicbook.readers_count - 1,
        },
      }
    }

    case COMMENT_ADDED:
    case COMMENT_REMOVED: {
      const post = state[action.resourceUuid]

      if (!post) return state

      return {
        ...state,
        [action.resourceUuid]: {
          ...post,
          comments_count: Math.max(
            post.comments_count + (action.type === COMMENT_ADDED ? 1 : -1),
            0,
          ),
        },
      }
    }

    default:
      return state
  }
}

import { normalize } from 'normalizr'
import { cacheAction } from 'shared/middlewares/cache-middleware'
import { createRedirectForResource } from 'shared/tools/url-helper'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import {
  audioBookSchema,
  audioBooksSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import {
  booksSchema,
  usersSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import {
  impressionsSchema,
  addIdToImpressions,
  prepareImpressionsFromAPIResponse,
  IMPRESSION_REMOVED,
  IMPRESSION_CURRENT_USER_LOADED,
  IMPRESSION_CURRENT_USER_CLEAN,
} from 'client/bookmate/reducers/impressions-reducer'
import { normalizeSeriesParts } from 'client/bookmate/helpers/series-helpers'
import { isEmpty } from 'lodash'
import { EmotionRatingProps } from 'client/shared/types/emotion'
import { AudiobookProps } from 'client/shared/types/audiobook'

const AUDIOBOOK_LOAD = 'AUDIOBOOK_LOAD'
const AUDIOBOOK_LOAD_SUCCESS = 'AUDIOBOOK_LOAD_SUCCESS'
const AUDIOBOOK_LOAD_ERROR = 'AUDIOBOOK_LOAD_ERROR'
export const AUDIOBOOK_CHANGED = 'AUDIOBOOK_CHANGED'

const AUDIOBOOK_LISTENERS_LOAD = 'AUDIOBOOK_LISTENERS_LOAD'
const AUDIOBOOK_LISTENERS_LOAD_SUCCESS = 'AUDIOBOOK_LISTENERS_LOAD_SUCCESS'

const AUDIOBOOK_RELATED_LOAD = 'AUDIOBOOK_RELATED_LOAD'
const AUDIOBOOK_RELATED_LOAD_SUCCESS = 'AUDIOBOOK_RELATED_LOAD_SUCCESS'

const AUDIOBOOK_IMPRESSIONS_LOAD = 'AUDIOBOOK_IMPRESSIONS_LOAD'
const AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS = 'AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS'

const AUDIOBOOK_OTHER_VERSIONS_LOAD = 'AUDIOBOOK_OTHER_VERSIONS_LOAD'
const AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS =
  'AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS'

const AUDIOBOOK_EMOTION_RATING_LOAD = 'AUDIOBOOK_EMOTION_RATING_LOAD'
const AUDIOBOOK_EMOTION_RATING_LOAD_SUCCESS =
  'AUDIOBOOK_EMOTION_RATING_LOAD_SUCCESS'

const AUDIOBOOK_PUBLISHER_RESOURCES_LOAD = 'AUDIOBOOK_PUBLISHER_RESOURCES_LOAD'
const AUDIOBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS =
  'AUDIOBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS'

const AUDIOBOOK_SERIES_EPISODES_LOAD = 'AUDIOBOOK_SERIES_EPISODES_LOAD'
const AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS =
  'AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS'

const getAudiobookRedirectUrl = createRedirectForResource('audiobook')

export function load(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/audiobooks/${uuid}`,
      normalize: ({ audiobook: _audiobook }, { getState }) => {
        const correctAudiobookId = _audiobook.uuid
        const currentPath = getState().app.url

        if (uuid !== correctAudiobookId) {
          serverRedirectTo(
            getAudiobookRedirectUrl(correctAudiobookId, currentPath),
            301,
          )
        } else {
          const { result, entities } = normalize(_audiobook, audioBookSchema)

          return {
            result,
            entities,
          }
        }
      },
      types: [AUDIOBOOK_LOAD, AUDIOBOOK_LOAD_SUCCESS, AUDIOBOOK_LOAD_ERROR],
    },
  }
}

export const loadListeners = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, p = 1, pp = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/audiobooks/${uuid}/listeners`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [AUDIOBOOK_LISTENERS_LOAD, AUDIOBOOK_LISTENERS_LOAD_SUCCESS],
      },
    }
  },
  AUDIOBOOK_LISTENERS_LOAD_SUCCESS,
  [AUDIOBOOK_CHANGED],
)

export const loadRelated = cacheAction(
  // eslint-disable-next-line func-names
  function ({
    uuid,
    p = 1,
    pp = 20,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/audiobooks/${uuid}/related`,
        options: {
          data: {
            page: p,
            per_page: pp,
            order_direction,
            order_by,
          },
        },
        schema: audioBooksSchema,
        responseKey: 'audiobooks',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [AUDIOBOOK_RELATED_LOAD, AUDIOBOOK_RELATED_LOAD_SUCCESS],
      },
    }
  },
  AUDIOBOOK_RELATED_LOAD_SUCCESS,
  [AUDIOBOOK_CHANGED],
)

export const loadOtherVersions = cacheAction(
  // eslint-disable-next-line func-names
  function (uuid, type) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/audiobooks/${uuid}/variants/${type}`,
        schema: type === 'audiobook' ? audioBooksSchema : booksSchema,
        responseKey: `${type}s`,
        modifyResponse: response => ({
          ...response,
          resourceType: type,
        }),
        types: [
          AUDIOBOOK_OTHER_VERSIONS_LOAD,
          AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS,
        ],
      },
    }
  },
  AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS,
  [AUDIOBOOK_CHANGED],
)

// eslint-disable-next-line func-names
export const loadImpressions = cacheAction(
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/audiobooks/${uuid}/impressions`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionsFromAPIResponse(response.impressions),
            impressionsSchema,
          )

          return {
            result,
            entities: {
              ...entities,
              impressions: addIdToImpressions(
                entities.impressions,
                uuid,
                'audiobook',
              ),
            },
            updateField: index ? 'impressionsIndex' : 'impressions',
            index,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [AUDIOBOOK_IMPRESSIONS_LOAD, AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS],
      },
    }
  },
  AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS,
  null,
)

export function loadEmotionRating(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/audiobooks/${uuid}/emotion_ratings`,
      types: [
        AUDIOBOOK_EMOTION_RATING_LOAD,
        AUDIOBOOK_EMOTION_RATING_LOAD_SUCCESS,
      ],
    },
  }
}

export function loadPublisherResources(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/api/v5/audiobooks/${uuid}/publisher_resources`,
      types: [
        AUDIOBOOK_PUBLISHER_RESOURCES_LOAD,
        AUDIOBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS,
      ],
      ignoreError: true,
    },
  }
}

export const loadOtherEpisodes = cacheAction(
  // eslint-disable-next-line func-names
  function ({ uuid, seriesUuid, p = 1, pp = 20, append = false }) {
    const data: { page: number; per_page: number; series_uuid?: string } = {
      page: p,
      per_page: pp,
    }

    if (seriesUuid) {
      data.series_uuid = seriesUuid
    }

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/audiobooks/${uuid}/more_in_series`,
        options: { data },
        normalize: ({ parts }) => {
          const { result, entities } = normalizeSeriesParts(parts)

          return {
            result,
            entities,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [
          AUDIOBOOK_SERIES_EPISODES_LOAD,
          AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS,
        ],
      },
    }
  },
  AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS,
  [AUDIOBOOK_CHANGED],
)

const initialState = {
  loading: true,
  uuid: '',
  listeners: [],
  related: [],
  otherVersions: {},
  impressions: [],
  impressionsIndex: [],
  otherEpisodes: [],
  pages: {
    listeners: 1,
    related: 1,
    impressions: 1,
    impressionsIndex: 1,
    otherEpisodes: 1,
  },
  publisherResources: [],
  emotionRating: [],
  currentUserImpressionUuid: null,
}

type AudiobookLoadAction = {
  type: typeof AUDIOBOOK_LOAD
}

type AudiobookLoadSuccessAction = {
  type: typeof AUDIOBOOK_LOAD_SUCCESS
  result: string
}

type AudiobookLoadErrorAction = {
  type: typeof AUDIOBOOK_LOAD_ERROR
  error: string
}

type AudiobookImpressionsLoadSuccessAction = {
  type: typeof AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS
  updateField: string
  result: string[]
  index: boolean
  append: boolean
  nextPage: number
}

type AudiobookSeriesLoadSuccessAction = {
  type: typeof AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS
  append: boolean
  nextPage: number
  result: string[]
}

type AudiobookImpressionRemovedAction = {
  type: typeof IMPRESSION_REMOVED
  resourceType: string
  uuid: string
}

type AudiobookListenersLoadSuccessAction = {
  type: typeof AUDIOBOOK_LISTENERS_LOAD_SUCCESS
  append: boolean
  result: string[]
  nextPage: number
}

type AudiobookRelatedLoadSuccessAction = {
  type: typeof AUDIOBOOK_RELATED_LOAD_SUCCESS
  result: string[]
  append: boolean
  nextPage: number
}

type AudiobookOtherVersionsLoadSuccessAction = {
  type: typeof AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS
  resourceType: string
  result: string[]
  entities: Record<string, any>
}

type AudiobookEmotionRatingLoadSuccessAction = {
  type: typeof AUDIOBOOK_EMOTION_RATING_LOAD_SUCCESS
  emotion_ratings: EmotionRatingProps[]
}

type AudiobookResourcesLoadSuccessAction = {
  type: typeof AUDIOBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS
  audiobooks: AudiobookProps[]
}

type ImpressionUserLoadedAction = {
  type: typeof IMPRESSION_CURRENT_USER_LOADED
  data: { resourceUuid: string; resourceType: string; uuid: string }
}

type ImpressionUserCleanAction = {
  type: typeof IMPRESSION_CURRENT_USER_CLEAN
}

type Action =
  | AudiobookLoadAction
  | AudiobookLoadSuccessAction
  | AudiobookLoadErrorAction
  | AudiobookImpressionsLoadSuccessAction
  | AudiobookSeriesLoadSuccessAction
  | AudiobookImpressionRemovedAction
  | AudiobookListenersLoadSuccessAction
  | AudiobookRelatedLoadSuccessAction
  | AudiobookOtherVersionsLoadSuccessAction
  | AudiobookEmotionRatingLoadSuccessAction
  | AudiobookResourcesLoadSuccessAction
  | ImpressionUserLoadedAction
  | ImpressionUserCleanAction

export default function audiobook(state = initialState, action: Action) {
  switch (action.type) {
    case AUDIOBOOK_LOAD:
      return initialState

    case AUDIOBOOK_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uuid: action.result,
        error: null,
      }

    case AUDIOBOOK_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        uuid: '',
        error: action.error,
      }

    case AUDIOBOOK_IMPRESSIONS_LOAD_SUCCESS: {
      const { updateField, result, index, append } = action

      return {
        ...state,
        [updateField]:
          index || !append ? result : [...state[updateField], ...result],
        pages: {
          ...state.pages,
          [updateField]: action.nextPage,
        },
      }
    }

    case AUDIOBOOK_SERIES_EPISODES_LOAD_SUCCESS: {
      return {
        ...state,
        otherEpisodes: action.append
          ? [...state.otherEpisodes, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          otherEpisodes: action.nextPage,
        },
      }
    }

    case IMPRESSION_REMOVED:
      if (action.resourceType === 'audiobook') {
        return {
          ...state,
          impressionsIndex: state.impressionsIndex.filter(
            uuid => uuid !== action.uuid,
          ),
          impressions: state.impressions.filter(uuid => uuid !== action.uuid),
        }
      } else {
        return state
      }

    case AUDIOBOOK_LISTENERS_LOAD_SUCCESS:
      return {
        ...state,
        listeners: action.append
          ? [...state.listeners, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          listeners: action.nextPage,
        },
      }

    case AUDIOBOOK_RELATED_LOAD_SUCCESS:
      return {
        ...state,
        related: action.append
          ? [...state.related, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          related: action.nextPage,
        },
      }

    case AUDIOBOOK_OTHER_VERSIONS_LOAD_SUCCESS: {
      const resourceType = `${action.resourceType}s`
      const resources = action.entities[resourceType]
      if (isEmpty(resources)) return state
      return {
        ...state,
        otherVersions: {
          ...state.otherVersions,
          [resourceType]: Object.values(resources),
        },
      }
    }

    case AUDIOBOOK_EMOTION_RATING_LOAD_SUCCESS:
      return {
        ...state,
        emotionRating: action.emotion_ratings,
      }

    case AUDIOBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS:
      return {
        ...state,
        publisherResources: action.audiobooks,
      }

    case IMPRESSION_CURRENT_USER_LOADED: {
      const { resourceUuid, resourceType, uuid } = action.data

      if (resourceType === 'audiobook' && resourceUuid === state.uuid) {
        return {
          ...state,
          currentUserImpressionUuid: uuid,
        }
      } else {
        return state
      }
    }

    case IMPRESSION_CURRENT_USER_CLEAN:
      return {
        ...state,
        currentUserImpressionUuid: null,
      }

    default:
      return state
  }
}

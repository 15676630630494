import React, { Component, createElement } from 'react'

import { ViewPagerContext } from 'client/bookmate/helpers/view-pager-helper'
import { Pager } from 'client/shared/blocks/pager'

type Props = {
  tag: string
}

class ViewPager extends Component<Props> {
  static defaultProps = {
    tag: 'div',
  }

  constructor(props) {
    super(props)

    this.pager = new Pager()
    this._isMounted = false
    const forceUpdate = () => this._isMounted && this.forceUpdate()

    // re-render the whole tree to update components on certain events
    this.pager.on('viewChange', forceUpdate)
    this.pager.on('hydrated', forceUpdate)
  }

  rerenderTree() {
    this.forceUpdate()
  }

  componentDidMount() {
    // run a hydration on the next animation frame to obtain proper targets and positioning
    this._isMounted = true
    requestAnimationFrame(() => {
      this.pager.hydrate()
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    this.pager.off()
  }

  render() {
    return (
      <ViewPagerContext.Provider value={{ pager: this.pager }}>
        {this.renderViewPager()}
      </ViewPagerContext.Provider>
    )
  }

  renderViewPager() {
    const { tag, ...restProps } = this.props
    return createElement(tag, restProps)
  }
}

export { ViewPagerContext }
export default ViewPager

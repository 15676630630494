import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import './comicbook.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { Size } from 'client/shared/types/cover'
import urlFor, { QueryParams } from 'shared/tools/url-helper'

import PlusBox from 'client/shared/boxes/plus-box'

import { BookCover } from 'client/shared/blocks/book-cover'
import BadgeList from 'client/shared/blocks/badge-list'
import MetaCounters from 'client/bookmate/blocks/meta-counters'
import TextTruncate from 'client/shared/blocks/text-truncate'
import { AuthorsList } from 'client/shared/blocks/authors-list'
import BookStatus from 'client/shared/blocks/book-status'
import Linka from 'client/shared/blocks/linka'

import { ComicbookProps } from 'client/shared/types/comicbook'
import { State } from 'shared/types/redux'
import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import { COMICBOOK } from 'client/bookmate/blocks/search-best-match/search-best-match'

const HEIGHTS = {
  '56': 78,
  '88': 124,
  '120': 176,
  '176': 247,
  '208': 290,
}

type Props = {
  comicbook: ComicbookProps
  kind: 'grid' | 'list'
  coverSize?: Size
  titleLines: number
  authorsLines: number
  hoverable: boolean
  bookshelfId?: string
  finished?: boolean
  progress?: number
  inPrivate?: boolean
  showPlus: boolean
  description: boolean
  shouldShowCover: boolean
  shouldShowMetaCounters: boolean
  shouldShowBadges: boolean
  seriesPosition?: string
  seriesUuid?: string
  isInSlider?: boolean
  id?: string
  onClick?: () => void
  query?: QueryParams
} & DecoratorProps

class _Comicbook extends Component<Props> {
  static defaultProps = {
    kind: 'list',
    hoverable: false,
    showPlus: true,
    description: true,
    titleLines: 2,
    authorsLines: 1,
    shouldShowCover: true,
    shouldShowMetaCounters: true,
    shouldShowBadges: true,
  }

  getCoverSize() {
    const { kind, coverSize } = this.props

    if (!coverSize) {
      return ['grid'].includes(kind) ? 120 : 88
    }

    return coverSize
  }

  getCoverSizeKind() {
    const { app } = this.props

    const coverSize = this.getCoverSize()

    return typeof coverSize === 'object' ? coverSize[app.size] : coverSize
  }

  getPath() {
    const {
      comicbook: { uuid },
      seriesUuid,
      query,
    } = this.props

    return urlFor.resource(uuid, COMICBOOK, {
      ...query,
      from_series: seriesUuid as string,
    })
  }

  render() {
    const { comicbook } = this.props

    if (isEmpty(comicbook)) return null

    const {
      kind,
      hoverable,
      description,
      shouldShowCover,
      id,
      onClick,
    } = this.props

    const hoverableMod = hoverable ? 'comicbook_hoverable' : ''

    switch (kind) {
      case 'list':
        return (
          <li
            onClick={onClick}
            id={id}
            className={`comicbook comicbook_${kind} ${hoverableMod} comicbook_${this.getCoverSizeKind()}`}
          >
            {shouldShowCover && this.renderCover()}
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </li>
        )
      case 'grid':
        return (
          <div
            onClick={onClick}
            id={id}
            className={`comicbook comicbook_${kind} ${hoverableMod} comicbook_${this.getCoverSizeKind()}`}
          >
            <div className="comicbook__reading-status">
              {this.renderBookStatus()}
            </div>
            {shouldShowCover && this.renderCover()}
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </div>
        )
      default:
        return (
          <div
            id={id}
            className={`comicbook comicbook_${kind} ${hoverableMod} comicbook_${this.getCoverSizeKind()}`}
          >
            {['grid'].includes(kind) && (
              <div className="comicbook__reading-status">
                {this.renderBookStatus()}
              </div>
            )}
            {shouldShowCover && this.renderCover()}
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </div>
        )
    }
  }

  shouldShowPlus() {
    const { kind, comicbook, showPlus } = this.props

    return showPlus && ['list'].includes(kind) && comicbook.can_be_read
  }

  renderPlus() {
    const { bookshelfId } = this.props
    let { comicbook } = this.props

    if (bookshelfId) {
      // comicbook is on a bookshelf;
      // we will send bookshelf id to the api when user adds the comicbook from a shelf
      // to their library; this is needed to correctly display counters
      comicbook = { ...comicbook, bookshelfId }
    }

    return <PlusBox comicbook={comicbook} stateless />
  }

  renderDescription() {
    const {
      comicbook: {
        cover,
        language,
        title,
        authors,
        labels,
        readers_count,
        bookshelves_count,
        impressions_count,
      },
      kind,
      titleLines,
      shouldShowMetaCounters,
      shouldShowBadges,
      isInSlider,
      coverSize,
    } = this.props

    const attributes = {}
    if (isInSlider) {
      const coverHeight = HEIGHTS[coverSize]
      if (cover.ratio) {
        attributes.width = `${coverHeight * cover.ratio}px`
      }
    }

    const counters = [
      { kind: 'users', count: readers_count },
      { kind: 'impressions', count: impressions_count },
      { kind: 'shelves', count: bookshelves_count },
    ].filter(({ count }) => count > 0)

    return (
      <div className="comicbook__description" style={{ ...attributes }}>
        <AuthorsList
          authors={authors}
          className="comicbook__authors"
          kind="compact"
        />
        <Linka title={title} className="comicbook__title" path={this.getPath()}>
          <TextTruncate lines={titleLines} text={title} textType="title" />
        </Linka>
        {['list'].includes(kind) && (
          <div className="comicbook__reading-status">
            {this.renderBookStatus()}
          </div>
        )}
        {['list'].includes(kind) &&
          shouldShowMetaCounters &&
          Boolean(counters.length) && <MetaCounters counters={counters} />}
        {['list'].includes(kind) && shouldShowBadges && labels && (
          <BadgeList labels={labels} language={language} />
        )}
      </div>
    )
  }

  renderCover() {
    const {
      comicbook,
      comicbook: { title },
      description,
      seriesPosition,
      isInSlider,
    } = this.props

    const bookCover = (
      <BookCover
        book={comicbook}
        size={this.getCoverSize()}
        isInSlider={isInSlider}
      />
    )

    return (
      <div className="comicbook__cover">
        {seriesPosition && (
          <span className="comicbook__position">{seriesPosition}</span>
        )}
        {description ? (
          /* simply render book cover; link to the book will be in the book description */
          bookCover
        ) : (
          /* wrap book cover in the link to the book (or to the reader) */
          <Linka title={title} path={this.getPath()}>
            {bookCover}
          </Linka>
        )}
      </div>
    )
  }

  renderBookStatus() {
    const { finished, progress, inPrivate } = this.props

    return (
      <BookStatus
        progress={progress}
        isFinished={finished}
        isPrivate={inPrivate}
      />
    )
  }
}

const connectWrapper = connect((state: State) => ({
  query: state.app.storedQuery,
}))

const wrappers = compose(connectWrapper, uiBox)

export const Comicbook = wrappers(_Comicbook)

import React, { Component } from 'react'

import './fallback-cover.styl'

import TextTruncate from 'client/shared/blocks/text-truncate'
import { authorsAsString } from 'client/bookmate/helpers/author/authorsAsString'

import { MinimalAuthorInfo } from 'client/shared/types/author'

type Props = {
  title: string
  authors: MinimalAuthorInfo[] | string // FIXME: remove string type when we move away from api4
  size: string | number
}

class FallbackCover extends Component<Props> {
  render() {
    const { title, authors: _authors, size } = this.props
    let authors = _authors

    if (Array.isArray(authors)) {
      authors = authorsAsString(authors)
    }

    return (
      <div className={`fallback-cover fallback-cover_${size}`}>
        <div className="fallback-cover-authors">
          <TextTruncate lines={3} text={authors} textType="authors" />
        </div>
        <div className="fallback-cover-separator">•</div>
        <div className="fallback-cover-title">
          <TextTruncate lines={4} text={title} textType="title" />
        </div>
      </div>
    )
  }
}

export default FallbackCover

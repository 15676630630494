import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import Transition from 'react-transition-group/Transition'

import { addParamsToPath } from 'shared/tools/url-helper'
import { Size } from 'client/shared/types/shelf'
import { Size as AvatarSize } from 'client/shared/types/avatar'

import './progressive-cover.styl'

interface Props {
  src?: string | null
  alt?: string
  placeholder?: string | null
  width: Size | '100%' | AvatarSize
  height: Size | AvatarSize
  setSizeBy?: SetSizeBy
}

export type SetSizeBy = 'width' | 'height' | null

interface State {
  loaded: boolean
}

interface Sources {
  src: string
  srcSet?: string
}

class ProgressiveCover extends Component<Props, State> {
  state = {
    loaded: false,
  }

  componentDidMount(): void {
    this.setState({ loaded: true })
  }

  getSourcesWithParams = (src: string, key: string, value: number): Sources => {
    const sizeX1 = addParamsToPath(src, { [key]: value })
    const sizeX2 = addParamsToPath(src, { [key]: value * 2 })

    return {
      src: sizeX2,
      srcSet: `${sizeX1} 1x, ${sizeX2} 2x`,
    }
  }

  getImageSources = (): Sources => {
    const { src, setSizeBy } = this.props

    if (!src) return { src: '' }

    let params: Sources = { src }
    const dimension = setSizeBy && this.props[setSizeBy]

    if (setSizeBy && typeof dimension === 'number') {
      params = this.getSourcesWithParams(src, setSizeBy, dimension)
    }

    return params
  }

  render(): JSX.Element {
    const { placeholder, alt, width, height } = this.props
    const { loaded } = this.state
    const { src, srcSet } = this.getImageSources()
    const coverStyles = {
      width: width as string,
      height: height as string,
      backgroundImage: placeholder
        ? `url(data:image/png;base64,${placeholder})`
        : undefined,
    }
    const getImageParams = stage => ({
      className: `progressive-image progressive-image_${stage}`,
      src,
      srcSet,
      alt,
      width: width as string,
      height: height as string,
    })

    return (
      <div className="progressive-cover" style={coverStyles}>
        <LazyLoad height={height as string}>
          <Transition key={src} in={loaded} timeout={200}>
            {stage => <img {...getImageParams(stage)} />}
          </Transition>
        </LazyLoad>
        <noscript>
          <img {...getImageParams('entered')} />
        </noscript>
      </div>
    )
  }
}

export default ProgressiveCover

import { normalize } from 'normalizr'
import merge from 'lodash/merge'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  feedEntriesSchema,
  START_READING,
  FINISH_READING,
  BOOK,
  QUOTE,
  IMPRESSION,
  SHELF,
  POST,
  USER,
  LIKE,
} from './feed-entries-reducer'
import { preparePostFromAPIResponse } from './posts-reducer'
import { AuthorProps } from 'client/shared/types/author'

const FRIENDS_FEED_LOADING = 'FRIENDS_FEED_LOADING'
const FRIENDS_FEED_LOAD_SUCCESS = 'FRIENDS_FEED_LOAD_SUCCESS'
const FRIENDS_FEED_LOAD_ERROR = 'FRIENDS_FEED_LOAD_ERROR'

const COMMUNITY_FEED_LOADING = 'COMMUNITY_FEED_LOADING'
const COMMUNITY_FEED_LOAD_SUCCESS = 'COMMUNITY_FEED_LOAD_SUCCESS'
const COMMUNITY_FEED_LOAD_ERROR = 'COMMUNITY_FEED_LOAD_ERROR'

const AUTHORS_FEED_LOADING = 'AUTHORS_FEED_LOADING'
const AUTHORS_FEED_LOAD_SUCCESS = 'AUTHORS_FEED_LOAD_SUCCESS'
const AUTHORS_FEED_LOAD_ERROR = 'AUTHORS_FEED_LOAD_ERROR'

const AUTHORS_FOLLOWING_LOADING = 'AUTHORS_FOLLOWING_LOADING'
const AUTHORS_FOLLOWING_SUCCESS = 'AUTHORS_FOLLOWING_LOAD_SUCCESS'
const AUTHORS_FOLLOWING_ERROR = 'AUTHORS_FOLLOWING_LOAD_ERROR'

const defaultLoadParameters = {
  page: 1,
  per_page: 20,
  append: false,
}

type FeedParams = {
  page?: number
  per_page?: number
  append?: boolean
  author?: string
}

export function loadFriendsFeed(params?: FeedParams): ApiAction {
  params = merge({}, defaultLoadParameters, params)
  const { page = 1, per_page = 20, append } = params

  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/feed',
      options: {
        data: {
          page,
          per_page,
        },
      },
      normalize: response => {
        const preparedResponse = prepareResponse(response.feed)
        const { result, entities } = normalize(
          preparedResponse,
          feedEntriesSchema,
        )

        return {
          result,
          entities,
          nextPage: result.length ? page + 1 : null,
          append,
        }
      },
      types: [
        FRIENDS_FEED_LOADING,
        FRIENDS_FEED_LOAD_SUCCESS,
        FRIENDS_FEED_LOAD_ERROR,
      ],
    },
  }
}

export function loadCommunityFeed(params: FeedParams): ApiAction {
  params = merge({}, defaultLoadParameters, params)
  const { page = 1, per_page = 20, append } = params

  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/superfeed',
      options: {
        data: {
          page,
          per_page,
        },
      },
      normalize: response => {
        const preparedResponse = prepareResponse(response.superfeed)
        const { result, entities } = normalize(
          preparedResponse,
          feedEntriesSchema,
        )

        return {
          result,
          entities,
          nextPage: result.length ? page + 1 : null,
          append,
        }
      },
      types: [
        COMMUNITY_FEED_LOADING,
        COMMUNITY_FEED_LOAD_SUCCESS,
        COMMUNITY_FEED_LOAD_ERROR,
      ],
    },
  }
}

export function loadFollowingAuthors(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/authors',
      types: [
        AUTHORS_FOLLOWING_LOADING,
        AUTHORS_FOLLOWING_SUCCESS,
        AUTHORS_FOLLOWING_ERROR,
      ],
    },
  }
}

export function loadAuthorsFeed(params: FeedParams): ApiAction {
  const { page = 1, per_page = 20, append = true, author } = params

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/authors/feed${
        author ? `?author=${author}` : ''
      }`,
      options: {
        data: {
          page,
          per_page,
        },
      },
      normalize: response => {
        return {
          entities: response.feed,
          nextPage: response.feed?.length ? page + 1 : null,
          append,
        }
      },
      types: [
        AUTHORS_FEED_LOADING,
        AUTHORS_FEED_LOAD_SUCCESS,
        AUTHORS_FEED_LOAD_ERROR,
      ],
    },
  }
}

/*
 * adjusts the api response in such a way that is easier to consume
 * Array[Object] -> Array[Object]
 */
function prepareResponse(response) {
  return response
    .map(entity => {
      const entryType = getEntryType(entity)
      const resourceType = getEntryResourceType(entity, entryType)
      return {
        ...entity,
        entryType,
        resourceType,
        resources: addResourceTypeToResources(entity.resources, resourceType),
        activists: addActivistType(entity.activists, getEntryType(entity)),
      }
    })
    .filter(
      entity =>
        entity.entryType && entity.resources.length && entity.activists.length,
    )
}

/*
 * reads fields of an entry from the array of feed elements returned from the api
 * and returns a custom label for this entry type
 * Object -> String
 */
function getEntryType(entity) {
  const { type, resources_type } = entity

  if (type === 'start_reading' && resources_type === 'book') {
    return START_READING
  } else if (type === 'document_read' && resources_type === 'book') {
    return FINISH_READING
  } else if (type === 'quote' && resources_type === 'quote') {
    return QUOTE
  } else if (type === 'impression' && resources_type === 'impression') {
    return IMPRESSION
  } else if (type === 'bookshelf' && resources_type === 'bookshelf') {
    return SHELF
  } else if (type === 'post' && resources_type === 'post') {
    return POST
  } else if (
    type === 'liker' &&
    ['quote', 'impression', 'post'].includes(resources_type)
  ) {
    return LIKE
  }
}

// add resourceType field to feedEntry (needed for applying proper schema for feed entry resources)
function getEntryResourceType(entry, entryType) {
  switch (entryType) {
    case START_READING:
    case FINISH_READING:
      return BOOK
    case QUOTE:
    case IMPRESSION:
    case SHELF:
    case POST:
      return entryType
    case LIKE:
      return entry.resources_type
  }
}

// add resourceType field to feedEntry resources if they happen to be posts
// (needed for applying proper schema for post resources)
function addResourceTypeToResources(resources, entryType) {
  return resources.map(resource => {
    if (entryType === POST || entryType === LIKE) {
      return preparePostFromAPIResponse(resource)
    } else {
      return resource
    }
  })
}

function addActivistType(activists, entryType) {
  let activistType
  switch (entryType) {
    case POST:
      activistType = SHELF
      break
    default:
      activistType = USER
      break
  }
  return activists.map(activist => ({ ...activist, activistType }))
}

const initialState = {
  friendsFeed: {
    page: 1,
    loading: false,
    entries: [],
  },
  communityFeed: {
    page: 1,
    loading: false,
    entries: [],
  },
  authorsFeed: {
    page: 1,
    loading: false,
    entries: [],
    following: [],
  },
}

export type Feed = {
  page: number
  loading: boolean
  entries: Record<string, any>[]
  following: AuthorProps[]
}

export default function feeds(state = initialState, action) {
  switch (action.type) {
    case AUTHORS_FOLLOWING_SUCCESS:
      return {
        ...state,
        authorsFeed: {
          ...state.authorsFeed,
          following: action.authors,
        },
      }

    case FRIENDS_FEED_LOADING:
      return {
        ...state,
        friendsFeed: {
          ...state.friendsFeed,
          loading: true,
        },
      }

    case COMMUNITY_FEED_LOADING:
      return {
        ...state,
        communityFeed: {
          ...state.communityFeed,
          loading: true,
        },
      }

    case FRIENDS_FEED_LOAD_SUCCESS:
      return {
        ...state,
        friendsFeed: {
          loading: false,
          page: action.nextPage,
          entries: action.append
            ? [...state.friendsFeed.entries, ...action.result]
            : action.result,
        },
      }

    case COMMUNITY_FEED_LOAD_SUCCESS:
      return {
        ...state,
        communityFeed: {
          loading: false,
          page: action.nextPage,
          entries: action.append
            ? [...state.communityFeed.entries, ...action.result]
            : action.result,
        },
      }

    case AUTHORS_FEED_LOADING:
      return {
        ...state,
        authorsFeed: {
          ...state.authorsFeed,
          loading: true,
        },
      }

    case AUTHORS_FEED_LOAD_SUCCESS:
      return {
        ...state,
        authorsFeed: {
          following: state.authorsFeed.following,
          loading: false,
          page: action.nextPage,
          entries: action.append
            ? [...state.authorsFeed.entries, ...action.entities]
            : action.entities,
        },
      }

    default:
      return state
  }
}

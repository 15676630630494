import urlParse from 'url-parse'
import analytics from 'redux-analytics'
import cookie from 'react-cookie'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import isUndefined from 'lodash/isUndefined'
import * as awsmaLite from 'aws-mobile-analytics-lite'
import { Dispatch, AnyAction } from 'redux'
import env from 'env'
import config from 'config'

import { getUserSubscriptionTypes } from 'client/bookmate/selectors/subscription-selectors'

import {
  deferEvent,
  SUBSCRIPTION_SUCCESSFUL,
} from 'client/shared/reducers/analytics-reducer'
import { getDomain } from 'shared/tools/url-helper'

const CLIENT_ID = 'aws-client-id'
const ONE_YEAR = 365 * 24 * 60 * 60

export function getClientId(): string {
  return cookie.load(CLIENT_ID)
}

function setClientId(cid) {
  const options = {
    maxAge: ONE_YEAR,
    domain: `.${getDomain(window.location.hostname)}`,
    path: '/',
  }

  return cookie.save(CLIENT_ID, cid, options)
}

function getAMAGlobalAttributes(state) {
  const {
    currentUser: {
      data: { id: user_id, country, library_lang, locale },
    },
    app: {
      ua: { browser, device },
      currentLocationHref,
      previousLocationHref,
      utmParams,
    },
  } = state

  const { screen, document, location } = window

  const locationHref = previousLocationHref || currentLocationHref
  const referrer = document.referrer || locationHref
  const referringDomain = urlParse(referrer).host

  return {
    user_id,
    subscription_country: country,
    library_lang,
    locale,
    browser: browser.name,
    browser_version: browser.major,
    device_type: device.type || 'desktop',
    screen_width: screen.width,
    screen_height: screen.height,
    referring_domain: referringDomain,
    referrer,
    current_url: location.href,
    subscription: getUserSubscriptionTypes(state),
    ...utmParams,
  }
}

function sendFacebookEvent(type, payload) {
  if (
    type === SUBSCRIPTION_SUCCESSFUL &&
    !env.isDevelopment() &&
    typeof window.fbq === 'function'
  ) {
    const { currency, price, payment_method, is_trial } = payload
    let { sub_type } = payload
    if (is_trial) {
      sub_type = `${sub_type}_trial`
    }
    if (payment_method === 'trial_3_months') {
      sub_type = `${sub_type}_3_months` // this will result in e.g. premium_trial_3_months
    }

    window.fbq('track', 'Purchase', {
      currency,
      value: price,
      content_type: sub_type,
    })
  }
}

function sendCustomGTMEvent(event, payload) {
  window.dataLayer.push({ event: `awsLite_${event}`, payload })
}

export default () => {
  const cid = getClientId()
  const options: {
    [key: string]: unknown
  } = {
    dependenciesHref: require('!!file-loader?esModule=false!aws-mobile-analytics-lite/dist/awsmalite-aws.min.js'),
    region: 'us-east-1',
    identityPoolId: config.AWSIdentityPoolId,
    appId: config.AMAappId,
    appTitle: config.AMAappTitle,
    autoSubmitEvents: true,
    clientOptions: {
      maxRetries: 3,
    },
  }

  if (cid) {
    options.clientId = cid
  }

  awsmaLite.initialize(options).then(manager => {
    if (isUndefined(cid)) {
      const { AWSMobileAnalyticsClientId } = manager.client.storage.cache
      setClientId(AWSMobileAnalyticsClientId)
    }
  })

  return analytics(({ type, payload }, state) => {
    const omittedProps = ['token']
    payload = omit(merge(getAMAGlobalAttributes(state), payload), omittedProps)
    awsmaLite.recordEvent(type, payload)
    sendFacebookEvent(type, payload)
    if (window.dataLayer) {
      sendCustomGTMEvent(type, payload)
    }
  })
}

export function serverAnalyticsMiddleware() {
  return (next: Dispatch) => (action: AnyAction) => {
    const returnValue = next(action)

    if (!action || !action.meta || !action.meta.analytics) {
      return returnValue
    }

    const { type, payload } = action.meta.analytics
    return next(deferEvent(type, payload))
  }
}

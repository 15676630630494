import React from 'react'
import { Dispatch } from 'shared/types/redux'
import { show } from 'client/shared/reducers/fake-page-reducer'
import SVGInline from 'react-svg-inline'
import MenuIcon from 'client/shared/icons/menu.svg'

export const HeaderNavigationMobile = ({
  dispatch,
}: {
  dispatch: Dispatch
}): JSX.Element => {
  return (
    <div className="header__nav-link" onClick={() => dispatch(show('nav'))}>
      <SVGInline className="header__icon header__icon_menu" svg={MenuIcon} />
    </div>
  )
}

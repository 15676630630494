import React, { Component } from 'react'

import './audiobook-cover.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { AudiobookCoverSize } from 'client/shared/types/cover'

import ProgressiveCover from 'client/shared/blocks/progressive-cover'
import FallbackCover from 'client/shared/blocks/fallback-cover'
import { authorsAsString } from 'client/bookmate/helpers/author/authorsAsString'

import SVGInline from 'react-svg-inline'
import PlayIcon from 'client/shared/icons/play.svg'
import { Trans } from '@lingui/react'
import cn from 'classnames'

type Props = {
  audiobook: AudiobookProps
  size: AudiobookCoverSize
  isLeadCover?: boolean
} & DecoratorProps

class AudioBookCover extends Component<Props> {
  static defaultProps = {
    size: 88,
  }

  getAlt(): string {
    const {
      audiobook: { title, authors },
    } = this.props

    return `${title}, ${authorsAsString(authors)}`
  }

  render() {
    const {
      app,
      size,
      audiobook,
      audiobook: { cover, can_be_listened },
      isLeadCover,
    } = this.props

    const sizeKind = typeof size === 'object' ? size[app.size] : size
    const isGiftsPage = app.currentLocationHref.includes('gifts')
    const showAsUnavailable =
      audiobook.hasOwnProperty('can_be_listened') &&
      !can_be_listened &&
      !isLeadCover &&
      !isGiftsPage

    return (
      <div
        className={cn(`audiobook-cover audiobook-cover_${sizeKind}`, {
          'audiobook-cover_unavailable': showAsUnavailable,
        })}
      >
        {showAsUnavailable && (
          <span className="audiobook__unavailable_wrapper">
            <span className="audiobook__unavailable_text">
              <Trans id="badge.private" />
            </span>
          </span>
        )}
        <SVGInline svg={PlayIcon} className="audiobook-cover__play-icon" />
        {cover && cover.large
          ? this.renderImg(sizeKind)
          : this.renderFallBack()}
      </div>
    )
  }

  renderImg(sizeKind) {
    const {
      audiobook: {
        cover: { large: largeCover, placeholder },
      },
    } = this.props

    return (
      <ProgressiveCover
        src={largeCover}
        placeholder={placeholder}
        width={sizeKind}
        height={sizeKind}
        alt={this.getAlt()}
      />
    )
  }

  renderFallBack() {
    const {
      app,
      size,
      audiobook: { title, authors },
    } = this.props

    return (
      <FallbackCover
        title={title}
        authors={authors}
        size={typeof size === 'object' ? size[app.size] : size}
      />
    )
  }
}

export default uiBox(AudioBookCover)

export const isTelenorAvailable = (country: string): boolean =>
  ['bg'].includes(country)

export const isShareAvailable = (country?: string): boolean => country !== 'ru'

export const isMTNAvailable = (country: string): boolean =>
  ['gh'].includes(country)

export const isYettelAvailable = (country: string): boolean =>
  ['rs'].includes(country)

export const isFacebookAvailable = (country?: string): boolean =>
  country !== 'ru'

export const isTwitterAvailable = (country?: string): boolean =>
  country !== 'ru'

export const isInstagramAvailable = (country?: string): boolean =>
  country !== 'ru'

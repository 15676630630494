import { normalize } from 'normalizr'
import merge from 'lodash/merge'

import fetchWrapper, { FetchMethod } from 'shared/tools/fetch-wrapper'
import urlFor from 'shared/tools/url-helper'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { CALL_API, ApiAction } from 'shared/middlewares/api-middleware'

import { authorSchema } from 'client/bookmate/reducers/authors-reducer'
import {
  booksSchema,
  audioBooksSchema,
  comicbooksSchema,
  seriesListSchema,
} from 'client/bookmate/reducers/schemas/schemas'

const AUTHOR_LOAD = 'AUTHOR_LOAD'
const AUTHOR_LOAD_SUCCESS = 'AUTHOR_LOAD_SUCCESS'
const AUTHOR_QUOTES_LOAD = 'AUTHOR_QUOTES_LOAD'
const AUTHOR_QUOTES_LOAD_SUCCESS = 'AUTHOR_QUOTES_LOAD_SUCCESS'
const AUTHOR_TOPICS_LOAD = 'AUTHOR_TOPICS_LOAD'
const AUTHOR_TOPICS_LOAD_SUCCESS = 'AUTHOR_TOPICS_LOAD_SUCCESS'
const AUTHOR_IMPRESSIONS_LOAD = 'AUTHOR_IMPRESSIONS_LOAD'
const AUTHOR_IMPRESSIONS_LOAD_SUCCESS = 'AUTHOR_IMPRESSIONS_LOAD_SUCCESS'
const AUTHOR_LANGUAGE_VARIANTS_LOAD = 'AUTHOR_LANGUAGE_VARIANTS_LOAD'
const AUTHOR_LANGUAGE_VARIANTS_LOAD_SUCCESS =
  'AUTHOR_LANGUAGE_VARIANTS_LOAD_SUCCESS'
const AUTHOR_GROUPED_WORKS_LOAD = 'AUTHOR_GROUPED_WORKS_LOAD'
export const AUTHOR_GROUPED_WORKS_LOAD_SUCCESS =
  'AUTHOR_GROUPED_WORKS_LOAD_SUCCESS'
const AUTHOR_WORKS_LOAD = 'AUTHOR_BOOKS_LOAD'
const AUTHOR_WORKS_LOAD_SUCCESS = 'AUTHOR_WORKS_LOAD_SUCCESS'
const AUTHOR_WORKS_LOAD_ERROR = 'AUTHOR_WORKS_LOAD_ERROR'
const AUTHOR_CLEAR = 'AUTHOR_CLEAR'
const AUTHOR_MEDIALINKS_LOAD = 'AUTHOR_MEDIALINKS_LOAD'
const AUTHOR_MEDIALINKS_LOAD_SUCCESS = 'AUTHOR_MEDIALINKS_LOAD_SUCCESS'
const AUTHOR_IMPRESSION_LIKED_SUCCESS = 'AUTHOR_IMPRESSION_LIKED_SUCCESS'
const AUTHOR_QUOTE_LIKED_SUCCESS = 'AUTHOR_QUOTE_LIKED_SUCCESS'

const AUTHOR_SUBSCRIBE_TO = 'AUTHOR_SUBSCRIBE_TO'
const AUTHOR_SUBSCRIBE_TO_SUCCESS = 'AUTHOR_SUBSCRIBE_TO_SUCCESS'
const AUTHOR_UNSUBSCRIBE_TO = 'AUTHOR_UNSUBSCRIBE_TO'
const AUTHOR_UNSUBSCRIBE_TO_SUCCESS = 'AUTHOR_UNSUBSCRIBE_TO_SUCCESS'

const NUMBER_OF_WORKS_IN_GROUP = 10

import {
  ThunkAction,
  GetState,
  Dispatch,
  GenericDispatchedEvent,
} from 'shared/types/redux'
import { QuoteProps } from 'client/shared/types/quote'
import { ResourceTopicProps } from 'client/shared/types/topic'
import { ImpressionProps } from 'client/shared/types/impression'
import { LikeToggleProps } from './comments-reducer'
import { OrderByOption, OrderDirectionOption } from '../blocks/sorting/sorting'

type AuthorLoadSuccessAction = {
  type: 'AUTHOR_LOAD_SUCCESS'
  result: string
  entities: {
    [key: string]: any
  }
}

type AuthorGroupedWorksLoadSuccessAction = {
  type: 'AUTHOR_GROUPED_WORKS_LOAD_SUCCESS'
  sectionsOrder: Section[]
  roles: {
    author?: WorkTypes
    translator?: WorkTypes
    narrator?: WorkTypes
    illustrator?: WorkTypes
    publisher?: WorkTypes
  }
}

type AuthorWorksLoadSuccess = {
  type: 'AUTHOR_WORKS_LOAD_SUCCESS'
  role: string
  worksType: string
  nextPage: number | null | undefined
  append: boolean
  result: string[]
}

type AuthorWorksLoadError = {
  type: 'AUTHOR_WORKS_LOAD_ERROR'
  error: string
}

type AuthorSubscribeToSuccess = {
  type: typeof AUTHOR_SUBSCRIBE_TO_SUCCESS
}

type AuthorUnsubcribeFromSuccess = {
  type: typeof AUTHOR_UNSUBSCRIBE_TO_SUCCESS
}

type AuthorTopicsLoadSuccess = {
  type: 'AUTHOR_TOPICS_LOAD_SUCCESS'
  topics: ResourceTopicProps[]
  meta: { total: number }
}

type AuthorLanguageVariantsLoadSuccess = {
  type: 'AUTHOR_LANGUAGE_VARIANTS_LOAD_SUCCESS'
  translations: AuthorLanguageVariant[]
}

type AuthorImpressionsLoadSuccess = {
  type: 'AUTHOR_IMPRESSIONS_LOAD_SUCCESS'
  impressions: ImpressionProps[]
}

type AuthorQuotesLoadSuccess = {
  type: 'AUTHOR_QUOTES_LOAD_SUCCESS'
  quotes: QuoteProps[]
  nextPage: number
}

type AuthorImpressionLikedSuccess = {
  type: 'AUTHOR_IMPRESSION_LIKED_SUCCESS'
  likes_count: number
  liked: boolean
  uuid: string
}

type AuthorQuoteLikedSuccess = {
  type: 'AUTHOR_QUOTE_LIKED_SUCCESS'
  likes_count: number
  liked: boolean
  uuid: string
}

type AuthorClearAction = {
  type: 'AUTHOR_CLEAR'
}

type AuthorMediaLinksLoadSuccess = {
  type: 'AUTHOR_MEDIALINKS_LOAD_SUCCESS'
  nextPage: number | null | undefined
  links: []
  append: boolean
}

type Action =
  | AuthorLoadSuccessAction
  | AuthorClearAction
  | AuthorGroupedWorksLoadSuccessAction
  | AuthorWorksLoadSuccess
  | AuthorWorksLoadError
  | AuthorMediaLinksLoadSuccess
  | AuthorLanguageVariantsLoadSuccess
  | AuthorQuotesLoadSuccess
  | AuthorTopicsLoadSuccess
  | AuthorImpressionsLoadSuccess
  | AuthorImpressionLikedSuccess
  | AuthorQuoteLikedSuccess
  | AuthorSubscribeToSuccess
  | AuthorUnsubcribeFromSuccess

type Section = {
  role: string // 'author' | 'translator' | 'narrator' | 'illustrator'
  worksType: string // 'books' | 'audiobooks' | 'comicbooks'
}

type WorksData = {
  ids: string[]
  total_count: number
  page: number
}

export type AuthorLanguageVariant = {
  name: string
  locale: string
  uuid: string
  works_count: number
}

type WorkTypes = {
  books?: WorksData
  audiobooks?: WorksData
  comicbooks?: WorksData
  series?: WorksData
}

export type State = {
  uuid: string
  external_links: {
    links: []
    page: number
  }
  quotes: {
    content: QuoteProps[]
    nextPage: number
  }
  topics: []
  topicsTotal: number
  impressions: {
    content: ImpressionProps[]
    nextPage: number
  }
  languageVariants: AuthorLanguageVariant[]
  sectionsOrder: Section[]
  roles: {
    author: WorkTypes
    translator: WorkTypes
    narrator: WorkTypes
    illustrator: WorkTypes
    publisher: WorkTypes
  }
}

type LoadWorksByRoleAndTypeParams = {
  authorId: string
  role: string
  worksType: string
  page?: number
  per_page?: number
  append?: boolean
  order_by?: OrderByOption
  order_direction?: OrderDirectionOption
}

const resourceTypeToSchema = {
  books: booksSchema,
  audiobooks: audioBooksSchema,
  comicbooks: comicbooksSchema,
  series: seriesListSchema,
}

const AVAILABLE_GROUPS = {
  books: ['author', 'translator', 'publisher'],
  audiobooks: ['author', 'translator', 'narrator', 'publisher'],
  comicbooks: ['author', 'translator', 'illustrator', 'publisher'],
  series: ['author', 'publisher'],
}

function getAvailableRoles(groups) {
  return groups.reduce((availableGroups, group) => {
    if (AVAILABLE_GROUPS[group.works_type].includes(group.role)) {
      availableGroups.push(group)
    }

    return availableGroups
  }, [])
}

export function load(authorId: string): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = `/p/api/v5/authors/${encodeURIComponent(authorId)}`

    dispatch({
      type: AUTHOR_CLEAR,
    })
    dispatch({
      type: AUTHOR_LOAD,
    })
    const state = getState()
    try {
      const response = await fetchWrapper.fetch(url, {
        state,
        method: 'get' as FetchMethod,
      })
      const { author: _author } = response
      const { result, entities } = normalize(_author, authorSchema)
      dispatch({
        type: AUTHOR_LOAD_SUCCESS,
        result,
        entities,
      })

      if (_author.external_links.length > 0) {
        dispatch({
          type: AUTHOR_MEDIALINKS_LOAD_SUCCESS,
          links: _author.external_links,
          nextPage: 2,
          append: false,
        })
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // most likely cause of error: the page has old url schema, where instead
        // of author's id we have author's name
        const { uuid: properAuthorId } = await dispatch(
          fetchAuthorIdByName(authorId),
        )
        serverRedirectTo(urlFor.author(properAuthorId, state.app.storedQuery), 301)
      } else {
        throw error
      }
    }
  }
}

export function loadAuthorGroupedWorks(authorId: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${encodeURIComponent(
        authorId,
      )}/grouped_works?per_page=${NUMBER_OF_WORKS_IN_GROUP}`,
      normalize: ({ grouped_works: groupedWorks }) => {
        const sectionsOrder = []
        const result = {}
        let allEntities = {}

        const transformedResponse = getAvailableRoles(groupedWorks).reduce(
          (accumulator, group) => {
            const {
              role,
              works,
              works_count: total_count,
              works_type: worksType,
            } = group
            sectionsOrder.push({ role, worksType })
            const { result: ids, entities } = normalize(
              works,
              resourceTypeToSchema[worksType],
            )
            allEntities = merge(allEntities, entities)
            const _result = {
              [role]: {
                [worksType]: {
                  ids,
                  total_count,
                },
              },
            }
            return merge(accumulator, _result)
          },
          {},
        )

        result.sectionsOrder = sectionsOrder
        result.roles = transformedResponse
        result.entities = allEntities
        return result
      },
      types: [AUTHOR_GROUPED_WORKS_LOAD, AUTHOR_GROUPED_WORKS_LOAD_SUCCESS],
    },
  }
}

export function loadWorksByRoleAndType(
  params: LoadWorksByRoleAndTypeParams,
): ApiAction {
  const {
    authorId,
    role,
    worksType,
    page = 1,
    per_page = 20,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  } = params

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${authorId}/${worksType}`,
      options: {
        data: {
          role,
          page,
          per_page,
          order_by,
          order_direction,
        },
      },
      normalize: response => {
        const schema = resourceTypeToSchema[worksType]
        const { result, entities } = normalize(response[worksType], schema)
        return {
          role,
          worksType,
          result,
          entities,
          append,
          nextPage: result.length ? page + 1 : null,
        }
      },
      types: [
        AUTHOR_WORKS_LOAD,
        AUTHOR_WORKS_LOAD_SUCCESS,
        AUTHOR_WORKS_LOAD_ERROR,
      ],
    },
  }
}

export function loadMoreWorksForSingleRoleAndWorksType(
  params: LoadWorksByRoleAndTypeParams,
) {
  return async (dispatch: Dispatch): Promise<ApiAction> => {
    return dispatch(loadWorksByRoleAndType(params))
  }
}

export function loadAuthorLanguageVariants(authorId: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${encodeURIComponent(
        authorId,
      )}/translations`,
      types: [
        AUTHOR_LANGUAGE_VARIANTS_LOAD,
        AUTHOR_LANGUAGE_VARIANTS_LOAD_SUCCESS,
      ],
    },
  }
}

export const IMPRESSIONS_LIMIT = 3
export const IMPRESSIONS_SAMPLE = 10

export const changeImpressionLikesCount = ({
  uuid,
  likes_count,
  liked,
}: LikeToggleProps): LikeToggleProps & GenericDispatchedEvent => ({
  type: 'AUTHOR_IMPRESSION_LIKED_SUCCESS',
  uuid,
  likes_count,
  liked,
})

export const changeQuoteLikesCount = ({
  uuid,
  likes_count,
  liked,
}: LikeToggleProps): LikeToggleProps & GenericDispatchedEvent => ({
  type: 'AUTHOR_QUOTE_LIKED_SUCCESS',
  uuid,
  likes_count,
  liked,
})

export function loadAuthorImpressions({
  authorId,
  per_page = IMPRESSIONS_SAMPLE,
  page = 1,
  append = false,
}: {
  authorId: string
  per_page?: number
  page?: number
  append?: boolean
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${encodeURIComponent(authorId)}/impressions`,
      options: {
        data: {
          per_page,
          page,
          append,
        },
      },
      types: [AUTHOR_IMPRESSIONS_LOAD, AUTHOR_IMPRESSIONS_LOAD_SUCCESS],
    },
  }
}

export const QUOTES_LIMIT = 3
export const QUOTES_SAMPLE = 10

export function loadAuthorQuotes({
  authorId,
  per_page = QUOTES_SAMPLE,
  page = 1,
  append = false,
}: {
  authorId: string
  per_page?: number
  page?: number
  append?: boolean
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${encodeURIComponent(authorId)}/quotes`,
      options: {
        data: {
          per_page,
          page,
          append,
        },
      },
      types: [AUTHOR_QUOTES_LOAD, AUTHOR_QUOTES_LOAD_SUCCESS],
    },
  }
}

export const TOPIC_LIMIT = 5

export function loadAuthorTopics(
  authorId: string,
  limit = TOPIC_LIMIT,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${encodeURIComponent(authorId)}/topics`,
      options: {
        data: {
          limit,
        },
      },
      types: [AUTHOR_TOPICS_LOAD, AUTHOR_TOPICS_LOAD_SUCCESS],
    },
  }
}

export function subscribeToAuthor(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${uuid}/followings`,
      options: {
        method: 'post' as FetchMethod,
      },
      types: [AUTHOR_SUBSCRIBE_TO, AUTHOR_SUBSCRIBE_TO_SUCCESS],
    },
  }
}

export function unsubscribeFromAuthor(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${uuid}/followings`,
      options: {
        method: 'delete' as FetchMethod,
      },
      types: [AUTHOR_UNSUBSCRIBE_TO, AUTHOR_UNSUBSCRIBE_TO_SUCCESS],
    },
  }
}

// temporary function to help us migrate from old urls (/author/:author_name)
// to new urls (/author/:author_id); is used to get author's uuid (required by api5)
// by author's name
export function fetchAuthorIdByName(authorName: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const url = `/p/a/4/authors`

    const result = await fetchWrapper.fetch(url, {
      method: 'get' as FetchMethod,
      state: getState(),
      data: {
        name: authorName,
      },
    })
    return result
  }
}

export function loadAuthorExternalLinks({
  authorId,
  page,
  per_page,
  append,
}: {
  authorId: string
  page: number
  per_page: number
  append: boolean
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/authors/${authorId}/external_links`,
      options: {
        data: {
          page,
          per_page,
        },
      },
      normalize: ({ external_links }) => {
        return {
          links: external_links,
          nextPage: external_links.length ? page + 1 : null,
          append,
        }
      },
      types: [AUTHOR_MEDIALINKS_LOAD, AUTHOR_MEDIALINKS_LOAD_SUCCESS],
    },
  }
}

const initialState = {
  uuid: '',
  languageVariants: [],
  sectionsOrder: [],
  topics: [],
  topicsTotal: 0,
  following: false,
  external_links: {
    links: [],
    page: 1,
  },
  impressions: {
    nextPage: 1,
    content: [],
  },
  quotes: {
    nextPage: 1,
    content: [],
  },
  roles: {
    author: {
      books: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      audiobooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      comicbooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      series: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
    },
    translator: {
      books: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      audiobooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      comicbooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
    },
    narrator: {
      audiobooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
    },
    illustrator: {
      comicbooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
    },
    publisher: {
      books: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      audiobooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      comicbooks: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
      series: {
        ids: [],
        total_count: 0,
        page: 1, // for paginated list
      },
    },
  },
}

export default function author(state = initialState, action: Action) {
  switch (action.type) {
    case AUTHOR_LOAD_SUCCESS:
      return {
        ...state,
        uuid: action.result,
      }

    case AUTHOR_GROUPED_WORKS_LOAD_SUCCESS: {
      return {
        ...state,
        sectionsOrder: action.sectionsOrder,
        roles: merge({}, state.roles, action.roles),
      }
    }

    case AUTHOR_WORKS_LOAD_SUCCESS: {
      const { role, worksType, result, append, nextPage } = action
      return {
        ...state,
        roles: {
          ...state.roles,
          [role]: {
            ...state.roles[role],
            [worksType]: {
              ...state.roles[role][worksType],
              ids: append
                ? [...state.roles[role][worksType].ids, ...result]
                : result,
              page: nextPage,
            },
          },
        },
      }
    }

    case AUTHOR_WORKS_LOAD_ERROR: {
      return {
        ...initialState,
        error: action.error,
      }
    }

    case AUTHOR_SUBSCRIBE_TO_SUCCESS: {
      return {
        ...state,
        following: true,
      }
    }

    case AUTHOR_UNSUBSCRIBE_TO_SUCCESS: {
      return {
        ...state,
        following: false,
      }
    }

    case AUTHOR_LANGUAGE_VARIANTS_LOAD_SUCCESS: {
      return {
        ...state,
        languageVariants: action.translations,
      }
    }

    case AUTHOR_IMPRESSIONS_LOAD_SUCCESS: {
      return {
        ...state,
        impressions: {
          content: [...state.impressions.content, ...action.impressions],
          nextPage: action.impressions?.length
            ? state.impressions.nextPage + 1
            : null,
        },
      }
    }

    case AUTHOR_QUOTES_LOAD_SUCCESS: {
      return {
        ...state,
        quotes: {
          content: [...state.quotes.content, ...action.quotes],
          nextPage: action.quotes?.length ? state.quotes.nextPage + 1 : null,
        },
      }
    }
    case AUTHOR_TOPICS_LOAD_SUCCESS: {
      return {
        ...state,
        topics: action.topics,
        topicsTotal: action.meta?.total || 0,
      }
    }

    case AUTHOR_MEDIALINKS_LOAD_SUCCESS: {
      return {
        ...state,
        external_links: {
          links: action.append
            ? [...state.external_links.links, ...action.links]
            : action.links,
          page: action.nextPage,
        },
      }
    }

    case AUTHOR_IMPRESSION_LIKED_SUCCESS:
      return {
        ...state,
        impressions: {
          ...state.impressions,
          content: [
            ...state.impressions.content.map((impression: ImpressionProps) =>
              impression.uuid === action.uuid
                ? {
                    ...impression,
                    likes_count: action.liked
                      ? action.likes_count - 1
                      : action.likes_count + 1,
                    liked: !action.liked,
                  }
                : impression,
            ),
          ],
        },
      }

    case AUTHOR_QUOTE_LIKED_SUCCESS:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          content: [
            ...state.quotes.content.map((quote: ImpressionProps) =>
              quote.uuid === action.uuid
                ? {
                    ...quote,
                    likes_count: action.liked
                      ? action.likes_count - 1
                      : action.likes_count + 1,
                    liked: !action.liked,
                  }
                : quote,
            ),
          ],
        },
      }

    case AUTHOR_CLEAR:
      return initialState

    default:
      return state
  }
}

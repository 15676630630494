import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { Size } from 'client/shared/types/cover'
import urlFor from 'shared/tools/url-helper'

import PlusBox from 'client/shared/boxes/plus-box'
import BookStatus from 'client/shared/blocks/book-status'
import {
  BookDescription,
  BookDescriptionProps,
} from './book-description/book-description'
import {
  BookCoverProps,
  BookCoverWrapper,
} from './book-cover/book-cover-wrapper'

import { BookSerialProps } from 'client/shared/types/resource'
import { LibraryCardProps } from 'client/shared/types/library-card'

import './book.styl'

type Props = {
  id?: string
  book: BookSerialProps
  kind: 'grid' | 'list'
  coverSize?: Size
  titleLines: number
  authorsLines: number
  hoverable: boolean
  showPlus: boolean
  bookshelfId?: string
  description: boolean
  progress?: number
  finished?: boolean
  inPrivate?: boolean
  library_lang?: string
  libraryCard?: LibraryCardProps
  shouldShowCover: boolean
  shouldShowMetaCounters: boolean
  shouldShowBadges: boolean
  seriesPosition?: string
  seriesUuid?: string
  isInSlider?: boolean
  serialInfo?: Record<string, string>
  onClick?: () => void
  isUserBooksPage?: boolean
} & DecoratorProps

class _Book extends Component<Props> {
  static defaultProps = {
    kind: 'list',
    hoverable: false,
    showPlus: true,
    description: true,
    titleLines: 2,
    authorsLines: 1,
    shouldShowCover: true,
    shouldShowMetaCounters: true,
    shouldShowBadges: true,
  }

  getCoverSize() {
    const { kind, coverSize } = this.props

    if (!coverSize) {
      return ['grid'].includes(kind) ? 120 : 88
    }

    return coverSize
  }

  getCoverSizeKind() {
    const { app } = this.props

    const coverSize = this.getCoverSize()

    return typeof coverSize === 'object' ? coverSize[app.size] : coverSize
  }

  getPath() {
    const {
      book: { uuid, book_uuid, resourceType, source_type },
      seriesUuid,
      app,
      serialInfo,
      isUserBooksPage,
    } = this.props

    const type = resourceType || (source_type === 'html' ? 'book' : 'serial')
    return isUserBooksPage
      ? urlFor.reader(
          book_uuid || uuid,
          { resourceType: type, seriesUuid },
          {
            ...app.storedQuery,
            ...serialInfo,
          },
        )
      : urlFor.resource(book_uuid || uuid, type, {
          ...app.storedQuery,
          ...serialInfo,
          from_series: seriesUuid as string,
        })
  }

  render(): JSX.Element | null {
    let { book } = this.props

    if (isEmpty(book)) return null

    const {
      kind,
      hoverable,
      description,
      shouldShowCover,
      finished,
      progress,
      inPrivate,
      libraryCard,
      bookshelfId,
      id,
      onClick,
    } = this.props

    if (bookshelfId) {
      book = { ...book, bookshelfId }
    }

    const hasEpisodePosition = Boolean(
      book.library_card && book.library_card.current_episode_position,
    )

    const hoverableMod = hoverable ? 'book_hoverable' : ''

    switch (kind) {
      case 'list':
        return (
          <li
            onClick={onClick}
            id={id}
            className={`book book_${kind} ${hoverableMod} book_${this.getCoverSizeKind()}`}
          >
            {shouldShowCover && (
              <BookCoverWrapper {...this.getBookCoverProps()} />
            )}
            {description && (
              <BookDescription {...this.getBookDescriptionProps()} />
            )}
            {this.shouldShowPlus() && (
              <PlusBox book={book} libraryCard={libraryCard} />
            )}
          </li>
        )
      case 'grid':
        return (
          <div
            id={id}
            onClick={onClick}
            className={`book book_${kind} ${hoverableMod} book_${this.getCoverSizeKind()}`}
          >
            <div className="book__reading-status">
              <BookStatus
                isFinished={finished}
                progress={progress}
                isPrivate={inPrivate}
                isSerial={book.source_type === 'serial'}
                episodePosition={
                  hasEpisodePosition
                    ? book.library_card.current_episode_position
                    : null
                }
              />
            </div>
            {shouldShowCover && (
              <BookCoverWrapper {...this.getBookCoverProps()} />
            )}
            {description && (
              <BookDescription {...this.getBookDescriptionProps()} />
            )}
            {this.shouldShowPlus() && (
              <PlusBox book={book} libraryCard={libraryCard} />
            )}
          </div>
        )
    }
  }

  getBookCoverProps(): BookCoverProps {
    const {
      book,
      kind,
      description,
      seriesPosition,
      coverSize,
      isInSlider,
    } = this.props

    return {
      book,
      bookPath: this.getPath(),
      description,
      seriesPosition,
      kind,
      coverSize,
      isInSlider,
    }
  }

  getBookDescriptionProps(): BookDescriptionProps {
    const {
      book,
      kind,
      titleLines,
      shouldShowMetaCounters,
      shouldShowBadges,
      finished,
      progress,
      inPrivate,
      isInSlider,
      coverSize,
    } = this.props

    const hasEpisodePosition = Boolean(
      book.library_card && book.library_card.current_episode_position,
    )

    return {
      kind,
      titleLines,
      shouldShowMetaCounters,
      shouldShowBadges,
      hasEpisodePosition,
      finished,
      progress,
      inPrivate,
      bookPath: this.getPath(),
      book,
      isInSlider,
      coverSize,
    }
  }

  shouldShowPlus() {
    const { kind, book, showPlus } = this.props

    return showPlus && ['list'].includes(kind) && book.can_be_read
  }
}

export const Book = uiBox(_Book)

import React, { Component } from 'react'

const TIMEOUT = 500

import './plus.styl'

type Props = {
  isAdded: boolean
  onAdd: () => Promise<void>
  contextMenu?: JSX.Element
  stateless?: boolean
}

export default class Plus extends Component<Props> {
  state = {
    isLoading: false,
    isLoadingBackwards: false,
  }

  componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isAdded && this.props.isAdded) {
      this.transitionToAdded()
    }

    if (prevProps.isAdded && !this.props.isAdded) {
      this.transitionToNotAdded()
    }
  }

  transitionToAdded(): void {
    const stateUpdater = () => ({ isLoading: true })
    const delayedStateUpdater = () =>
      setTimeout(() => {
        this.setState({ isLoading: false })
      }, TIMEOUT)
    this.setState(stateUpdater, delayedStateUpdater)
  }

  transitionToNotAdded(): void {
    const stateUpdater = () => ({ isLoadingBackwards: true })
    const delayedStateUpdater = () =>
      setTimeout(() => {
        this.setState({ isLoadingBackwards: false })
      }, TIMEOUT)
    this.setState(stateUpdater, delayedStateUpdater)
  }

  handleClick = async (): Promise<void> => {
    const { isAdded, onAdd } = this.props
    this.setState({ isLoading: true })

    if (!isAdded) {
      await onAdd()
    }

    this.setState({ isLoading: false })
  }

  render(): JSX.Element {
    const { isAdded, contextMenu } = this.props

    const { isLoading, isLoadingBackwards } = this.state

    const addedModifier = isAdded ? `plus_added` : `plus_not-added`
    const className = `plus ${isLoading ? 'plus_loading' : addedModifier} ${
      isLoadingBackwards ? 'plus_loading_backwrds' : ''
    }`

    return (
      <div key="plus" className={className} onClick={this.handleClick}>
        <div className="plus__bars">
          <div className="plus__bars_diagonal">
            <div className="plus__bar_diagonal plus__bar-1"></div>
            <div className="plus__bar_diagonal plus__bar-2"></div>
            <div className="plus__bar_diagonal plus__bar-3"></div>
            <div className="plus__bar_diagonal plus__bar-4"></div>
          </div>
          <div className="plus__bar plus__bar-1"></div>
          <div className="plus__bar plus__bar-2"></div>
          <div className="plus__bar plus__bar-3"></div>
          <div className="plus__bar plus__bar-4"></div>
        </div>
        <div className="plus__arrow-down">{contextMenu}</div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import merge from 'lodash/merge'
import noop from 'lodash/noop'

import './stripe-card-element.styl'

import { getStripeInstance } from 'client/shared/helpers/stripe-helper'
import {
  StripeElementDataObject,
  StripeElementError,
} from 'client/shared/types/stripe'
import { Locale } from 'client/shared/types/current-user'

export type StripeCardElementType = 'cardNumber' | 'cardCvc' | 'cardExpiry'

type Props = {
  type: 'cardNumber' | 'cardExpiry' | 'cardCvc'
  placeholder?: string
  error: StripeElementError | null | undefined
  onChange: (arg0: StripeElementDataObject) => void
  isDarkTheme?: boolean
  locale?: Locale
}

type State = {
  focused: boolean
}

export default class StripeElement extends Component<Props, State> {
  static defaultProps = {
    onChange: noop,
  }

  state = {
    focused: false,
  }

  input: HTMLDivElement | null | undefined

  inputRef = (
    el: HTMLDivElement | null | undefined,
  ): HTMLDivElement | null | undefined => (this.input = el)

  stripeElement:
    | {
        mount: (arg0: React.ReactNode) => void
        on: (arg0: string, arg1: (arg2: any) => void) => void
        destroy: () => void
      }
    | null
    | undefined = undefined

  componentDidMount(): void {
    this.createStripeElement()
    this.setupStripeEventListeners()
  }

  componentWillUnmount(): void {
    // eslint-disable-next-line no-unused-expressions
    this.stripeElement && this.stripeElement.destroy()
  }

  getElementStyles(): {
    style: { base: { [key: string]: string | { [key: string]: string } } }
  } {
    const { isDarkTheme } = this.props

    return {
      style: {
        base: {
          color: isDarkTheme ? '#d8d4d0' : '#302119',
          lineHeight: '40px',
          fontFamily: 'Helvetica, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: isDarkTheme ? 'rgba(255, 255, 255, 0.6)' : '#d8d4d0',
          },
        },
      },
    }
  }

  createStripeElement(): void {
    const { type, placeholder, locale } = this.props
    const options = {}
    merge(options, this.getElementStyles())

    if (placeholder) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options.placeholder = placeholder
    }

    const stripeInstance = getStripeInstance(locale)
    this.stripeElement = stripeInstance.create(type, options)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.stripeElement.mount(this.input)
  }

  setupStripeEventListeners(): void {
    const { stripeElement } = this
    if (stripeElement) {
      stripeElement.on('focus', this.handleFocus)
      stripeElement.on('change', elementData => {
        this.props.onChange(elementData)
      })
      stripeElement.on('blur', this.handleBlur)
    }
  }

  handleFocus = (): void => {
    this.setState({ focused: true })
  }

  handleBlur = (): void => {
    this.setState({ focused: false })
  }

  render(): JSX.Element {
    const baseClass = 'stripe-card-element'
    const focusModifier = this.state.focused ? `${baseClass}_focused` : ''
    const errorModifier = this.props.error ? `${baseClass}_error` : ''
    const className = `${baseClass} ${focusModifier} ${errorModifier}`

    return <div className={className} ref={this.inputRef} />
  }
}

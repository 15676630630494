import config from 'config'
import React from 'react'
import { Helmet } from 'react-helmet-async'

export const Recaptcha: React.FC = () => {
  return (
    <Helmet>
      <script
        src={`https://www.google.com/recaptcha/api.js?render=${config.recaptchaV3SiteKey}`}
        async
        defer
      ></script>
    </Helmet>
  )
}

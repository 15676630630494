import { ObservedItem } from 'client/bookmate/blocks/feed/feed'
import {
  AnalyticsData,
  CONTENT_LIST_CLICK,
  analyticsEvent,
} from 'client/shared/reducers/analytics-reducer'
import { ResourceName } from 'client/shared/types/resource'
import { Dispatch } from 'shared/types/redux'
import { getCurrentDateTime } from './date-helper'

export type ObservedAnalyticsState = {
  visible: ObservedItem[]
  seen: ObservedItem[]
}

export const sendOnClickAnalytics = ({
  resourceType,
  itemUuid,
  position,
  dispatch,
  userId,
  resourceUuid,
  screenName,
  listPosition,
  listUuid,
  listType,
}: {
  resourceType:
    | ResourceName
    | 'shelf'
    | 'post'
    | 'series'
    | 'external_link'
    | 'author'
    | 'search_topic'
  itemUuid: string
  position: number
  dispatch: Dispatch
  userId: number
  resourceUuid?: string
  screenName: string
  listPosition?: number
  listUuid?: string
  listType?: string
}): void => {
  if (!dispatch || !userId) return

  const data: AnalyticsData = {
    event_type: CONTENT_LIST_CLICK,
    event_datetime: getCurrentDateTime(),
    user_id: userId,
    screen: screenName,
    content_type: resourceType,
    content_uuid: itemUuid,
    content_position: position,
    ...(listPosition && { list_position: listPosition }),
    ...(listUuid && { list_uuid: listUuid }),
    ...(listType && { list_type: listType }),
    ...(resourceUuid && { screen_uuid: resourceUuid }),
  }

  dispatch(analyticsEvent(CONTENT_LIST_CLICK, data))
}

export const sendUnavailableItemEvent = (): void => {
  window.dataLayer.push({ event: 'unavailable_item' })
}

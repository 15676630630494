import React, { PureComponent } from 'react'
import { Trans } from '@lingui/react'
import SVGInline from 'react-svg-inline'
import qs from 'query-string'
import parse from 'url-parse'
import partition from 'lodash/partition'

import './auth-providers.styl'

import { useSocialProvider } from 'shared/tools/social-auth/utils/social-handler'
import { providers, Provider, ProvidersConditions } from './providers'

import Button from 'client/shared/blocks/button'
import uiBox from 'client/shared/decorators/ui-box'
import { State as AppState } from 'client/shared/reducers/app-reducer'
type SpecialParams = {
  giftCode: string | null | undefined
}

type Props = {
  kind: 'default' | 'links'
  onButtonClick: (title: string) => void
  specialParams?: SpecialParams
  providersConditions?: ProvidersConditions
  app?: AppState
  mtnAction?: () => void
  yettelAction?: () => void
}

type State = {
  restProvidersShown: boolean
}

class AuthProviders extends PureComponent<Props, State> {
  static defaultProps = {
    kind: 'default',
  }

  state = {
    restProvidersShown: false,
  }

  showAllProviders = () => {
    const { restProvidersShown } = this.state

    if (!restProvidersShown) this.setState({ restProvidersShown: true })
  }

  render() {
    const { kind, providersConditions } = this.props
    const { restProvidersShown } = this.state
    const kindMod = `auth-providers_${kind}`
    const allProviders = providers(providersConditions)
    const [restProviders, firstProviders] = partition(allProviders, {
      isMore: true,
    })
    const renderMethod = kind === 'links' ? this.renderLink : this.renderButton

    return (
      <div className={`auth-providers ${kindMod}`}>
        {kind === 'links' && this.renderTitle()}
        <div className="auth-providers__wrapper">
          {firstProviders.map(renderMethod)}
          {restProviders.length && restProvidersShown ? (
            restProviders.map(renderMethod)
          ) : (
            <Button
              className="auth-providers__button_more"
              onClick={this.showAllProviders}
              kind="inline"
            >
              <Trans id="auth.more" />
            </Button>
          )}
        </div>
      </div>
    )
  }

  renderButton = (provider: Provider, i: number) => {
    return provider.disabled ? null : (
      <div className="auth-providers__button" key={i}>
        <Button
          kind={provider.name}
          icon={provider.icon}
          onClick={this.authorize.bind(this, provider.name, provider)}
        >
          {provider.title || <Trans id={`auth.${provider.name}`} />}
        </Button>
      </div>
    )
  }

  renderLink = (provider: Provider, i: number) => {
    return provider.disabled ? null : (
      <span key={i}>
        <span
          className="auth-providers__link"
          onClick={this.authorize.bind(this, provider.name, provider)}
        >
          <SVGInline
            className={`auth-providers__icon auth-providers__icon-${provider.name}`}
            svg={provider.icon as string}
          />
        </span>
      </span>
    )
  }

  renderTitle = () => {
    return (
      <div className="auth-providers__title">
        <Trans id="auth.login_with" />
      </div>
    )
  }

  authorize(name: string, provider: Provider, evt: Event) {
    const params: { fingerprint?: string, backUrl?: string } = {}
    const {
      onButtonClick,
      specialParams,
      app,
      mtnAction,
      yettelAction,
    } = this.props

    if (name === 'mtn_ghana' && mtnAction) return mtnAction()
    else if (name === 'yettel' && yettelAction) return yettelAction()

    if (app && app.fingerprint) params.fingerprint = app.fingerprint

    evt.preventDefault()
    onButtonClick(provider?.title as string)

    if (specialParams && specialParams.giftCode) {
      const url = parse(window.location.href)
      const query = qs.parse(url.query)
      query.promo = specialParams.giftCode
      url.set('query', query)
      params.backUrl = url.toString()
    }

    useSocialProvider(name, params)
  }
}

export default uiBox(AuthProviders)

import React from 'react'
import Button from 'client/shared/blocks/button'
import Spacer from 'client/shared/blocks/spacer'
import LoginButton from 'client/shared/blocks/login-button'
import HeaderBox from 'client/shared/boxes/header-box'
import { Heading } from 'client/shared/blocks/heading/heading'
import Carousel from 'client/shared/blocks/carousel/carousel'

import './a1-layout.styl'

type Props = {
  auth: boolean
  handleForm: (e: React.SyntheticEvent<HTMLFormElement>) => void
  handleInput: (e: React.SyntheticEvent<HTMLInputElement>) => void
  isDesktop: boolean
  code: {
    codeValue: string
    autocomplete: boolean
    loading: boolean
    error: string | null | undefined
  }
  locale: string
}

const A1Caption = (): JSX.Element => (
  <p className="a1-caption">
    A1 je oduvijek ukorak s tobom u digitalnom svijetu, pa i kada je riječ o
    čitanju. Zato ti u suradnji s Bookmateom poklanjamo 30 dana potpuno
    besplatnog pristupa tisućama audio i e-knjiga — kako bismo nagradili tvoju
    vjernost. Sve što trebaš je mobitel!
  </p>
)

export class A1Layout extends React.Component<Props> {
  render(): JSX.Element {
    const { auth, code, handleForm, handleInput, isDesktop } = this.props
    const { codeValue, autocomplete, error, loading } = code

    const enabled = typeof codeValue === 'string' && codeValue?.trim()

    const SubmitForm = (
      <form onSubmit={handleForm}>
        <input
          className="a1-code-input"
          placeholder="Tvoj promotivni kód"
          type="text"
          value={codeValue}
          disabled={autocomplete}
          onChange={handleInput}
        />
        {error && (
          <>
            <Spacer />
            <div className="a1-code-input_error">{error}</div>
          </>
        )}
        <Spacer />
        {auth ? (
          <>
            <Button
              className="a1-action-button"
              type="inline"
              loading={loading}
              disabled={!enabled}
              aria-label="aktivirati ponudu"
            >
              Želim aktivirati ponudu
            </Button>
            <Spacer />
          </>
        ) : (
          <>
            <LoginButton
              type="button"
              message="Registriraj se ili se prijavi"
            />
            <p className="a1-subheader">
              Započni besplatnu pretplatu!
              <br />
              Registriraj se ili se prijavi
            </p>
          </>
        )}
      </form>
    )

    return (
      <div className="a1-layout">
        <HeaderBox />
        <div className="a1-lead-in">
          <div className="a1-column">
            <Heading kind="graphik" className="a1-heading">
              A1 i Bookmate poklanjaju vam 30 dana čitanja bez ograničenja
            </Heading>
            <Spacer size={32} />
            {isDesktop && (
              <>
                <A1Caption />
                <Spacer size={24} />
              </>
            )}
            {SubmitForm}
          </div>
        </div>
        {!isDesktop && (
          <>
            <A1Caption />
            <Spacer size={24} />
            <div className="a1-decor" />
          </>
        )}
        {isDesktop ? (
          <div className="a1-benefits">
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[0].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[0].description}</p>
              </div>
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[1].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[1].description}</p>
              </div>
              <div className="a1-benefit-cover" />
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[2].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[2].description}</p>
              </div>
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[3].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[3].description}</p>
              </div>
              <div className="a1-benefit-cover" />
            </div>
          </div>
        ) : (
          <Carousel infinite={false} className="a1-carousel" contain>
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[0].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[0].description}</p>
              </div>
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[1].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[1].description}</p>
              </div>
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[2].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[0].description}</p>
              </div>
            </div>
            <div className="a1-benefits-pair">
              <div className="a1-benefit-cover" />
              <div className="a1-benefit">
                <p className="a1-benefit__lead-in">{A1_BENEFITS[3].leadIn}</p>
                <p className="a1-benefit__desc">{A1_BENEFITS[0].description}</p>
              </div>
            </div>
          </Carousel>
        )}
        {isDesktop && (
          <div className="a1-footer">
            <Heading kind="kazimir" rank={2}>
              Najlakši način za uživanje u knjigama
            </Heading>
            <Spacer size={48} />
            {SubmitForm}
          </div>
        )}
      </div>
    )
  }
}

const A1_BENEFITS = [
  {
    leadIn: 'Nevjerojatan izbor',
    description:
      'Čeka te više od 2 000 e-knjiga i audio knjiga na hrvatskom jeziku, a ukupno više od 500 000 knjiga na 16 različitih jezika.',
  },
  {
    leadIn: 'Bogati sadržaj za svakog',
    description:
      'Bookmate nudi veliki izbor — od kriminalističkih romana, epskih djela, biografija i knjiga o samopomoći, originalnog dječjeg sadržaja, Bookmate Originalsa — jedinstvenog sadržaja koje autori pišu ekskluzivno za Bookmate, te sve do ljubavnih priča popularnih autora.',
  },
  {
    leadIn: 'Kod kuće i u pokretu — bez potrošnje interneta',
    description:
      'Audio i e-knjige se usklađuju između uređaja pa nastavljaš gdje se čitanje zaustavilo. Uz to, Bookmate možeš koristiti u online i offline načinu rada čime ne trošiš svoje megabajte.',
  },
  {
    leadIn: 'Želiš prijedloge?',
    description:
      'Svatko može napraviti tematsku policu za knjige (stručnjaci, prijatelji i ostali ljubitelji knjiga), a ti možeš pregledavati i pratiti bilo koju policu koja ti se sviđa.',
  },
]

import { isResourcePage } from 'client/bookmate/helpers/url-check-helpers'
import { BookmateRunIcon } from 'client/shared/blocks/header/bookmate-run-icon'
import { HeaderNavigationMobile } from 'client/shared/blocks/header/header-nav-mobile'
import Linka from 'client/shared/blocks/linka/linka'
import LoginButton from 'client/shared/blocks/login-button/login-button'
import Spacer from 'client/shared/blocks/spacer/spacer'
import { Sticker } from 'client/shared/blocks/sticker/sticker'
import SubscriptionButton from 'client/shared/blocks/subscription-button/subscription-button'
import HeaderAvatarBox from 'client/shared/boxes/header-avatar-box'
import uiBox from 'client/shared/decorators/ui-box'
import { DecoratorProps } from 'client/shared/decorators/ui-box'
import { CurrentUserState } from 'client/shared/types/current-user'
import React, { Component } from 'react'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { Route, withRouter } from 'react-router'
import deviceHelper from 'shared/tools/device-helper'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'
import SVGInline from 'react-svg-inline'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import BookIcon from 'client/shared/icons/book.svg'
import SearchIcon from 'client/shared/icons/search.svg'
import AccountIcon from 'client/shared/icons/account.svg'
import { show as showPopup } from 'client/shared/reducers/popup-reducer'
import { Location } from 'client/shared/types/react-router'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { Navigation } from 'client/shared/types/showcase'
import { compose } from 'lodash/fp'
import { isGiftsAvailable } from 'shared/tools/gifts-helper'
import { connect } from 'react-redux'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'
import Logo from 'client/shared/blocks/logo/logo'

import 'client/shared/blocks/header/header.styl'

type InnerProps = {
  dispatch: Dispatch
  app: AppState
  route?: Route
  router: { location: Location }
  currentUser: CurrentUserState
  hasSubscription?: boolean
  onNotificationsHide: () => void
  giftsShown?: boolean
  location: Location
  showcaseType: Navigation[]
  hideSubscriptionBoxAt?: string[]
  size?: string
}

type State = {
  isScrollActive: boolean
}

export type HeaderType = 'white' | 'black'

export const SimpleHeader = ({
  type = 'black',
  query,
}: {
  type?: HeaderType
  query?: QueryParams
}): JSX.Element => (
  <a href={urlFor.salinasShowcase(query)} className="controls_left">
    <div className={`salinas-header header-${type}`}>
      <img
        className="logos_header"
        alt="Bookmate x salinas logo"
        src={
          type === 'black'
            ? 'https://a.bmstatic.com/iu/139/31/logos-salinas-3b2f785346a9c4a5c2c836bb02198c39.png'
            : 'https://a.bmstatic.com/iu/195/223/download-e18b7557fe97b5b60633cd17991a4171.png'
        }
        aria-label="Bookmate x salinas logo"
      />
    </div>
  </a>
)

class SalinasHeaderInner extends Component<InnerProps, State> {
  render() {
    const { size, hasSubscription, location } = this.props

    const isMobileSize = deviceHelper.isMobileSize({ size })

    const isOnResourcePage = isResourcePage(location.pathname)

    const shouldShowExtraSpacer =
      isOnResourcePage && !hasSubscription && isMobileSize

    return (
      <>
        <div className="header-black salinas-header">
          {isMobileSize ? (
            <Logo kind="white" />
          ) : (
            <img
              className="logos_header"
              alt="Bookmate x salinas logo"
              src="https://a.bmstatic.com/iu/139/31/logos-salinas-3b2f785346a9c4a5c2c836bb02198c39.png"
              aria-label="Bookmate x salinas logo"
            />
          )}
          {!isMobileSize && this.renderNav()}
          {isMobileSize
            ? this.renderMobileRightControls()
            : this.renderDesktopRightControls()}
        </div>
        {shouldShowExtraSpacer && <Spacer size={8} />}
      </>
    )
  }

  renderNav() {
    return (
      <nav>
        <Linka path={urlFor.search()} className="header-navigation_item">
          Buscar
        </Linka>
        <Linka
          path={urlFor.salinasShowcase()}
          className="header-navigation_item"
        >
          Biblioteca
        </Linka>
        <Linka path={urlFor.bookshelves()} className="header-navigation_item">
          Estanterías
        </Linka>
      </nav>
    )
  }

  renderMobileRightControls() {
    const {
      dispatch,
      currentUser: { data: userData, completelyLoaded, auth },
      hasSubscription,
      router,
    } = this.props

    return !auth ? (
      <div className="header__controls header__controls_right">
        <BookmateRunIcon />
        {!hasSubscription && completelyLoaded && (
          <SubscriptionButton context="header" kind="small" />
        )}
        <span
          className="header__nav-link"
          onClick={() => dispatch(showPopup())}
        >
          <SVGInline
            className="header__icon header__nav-link__login"
            svg={AccountIcon}
          />
        </span>
        <Linka
          className="header__nav-link"
          activeClassName="header__nav-link_active"
          path={urlFor.search(router?.location?.query)}
        >
          <SVGInline className="header__icon" svg={SearchIcon} />
        </Linka>
        <HeaderNavigationMobile dispatch={dispatch} />
      </div>
    ) : (
      <div className="header__controls header__controls_right">
        <div className="header__icons">
          <BookmateRunIcon />
          <Linka
            className="header__nav-link"
            activeClassName="header__nav-link_active"
            path={urlFor.userBooksAll(userData.login, router?.location?.query)}
          >
            <SVGInline className="header__icon" svg={BookIcon} />
          </Linka>
          <Linka
            className="header__nav-link"
            activeClassName="header__nav-link_active"
            path={urlFor.search(router?.location?.query)}
          >
            <SVGInline className="header__icon" svg={SearchIcon} />
          </Linka>
        </div>
        <div className="header__nav-link">
          <HeaderAvatarBox />
        </div>
        <HeaderNavigationMobile dispatch={dispatch} />
      </div>
    )
  }

  renderDesktopRightControls() {
    const {
      currentUser: { auth, completelyLoaded },
      hasSubscription,
    } = this.props

    return (
      <div className="header__controls header__controls_right">
        <BookmateRunIcon
          size={!hasSubscription && completelyLoaded ? 33 : 40}
        />
        <div className="header__nav-link header__nav-link_right">
          {!auth && <LoginButton />}
          {auth && (
            <div className="header__avatar">
              <HeaderAvatarBox />
            </div>
          )}
        </div>
      </div>
    )
  }
}

type HeaderProps = {
  dispatch: Dispatch
  currentUser: CurrentUserState
  size: string
  subscription: SubscriptionState
  route?: Route
  giftsShown: boolean
  showcaseType: Navigation[]
  location: Location
  isPointerEventsBlocked?: boolean
} & DecoratorProps

class _SalinasHeader extends Component<HeaderProps> {
  markNotificationsAsRead = () => {
    const {
      currentUser: {
        data: { has_unread_notifications },
      },
    } = this.props

    if (has_unread_notifications) {
      this.props.dispatch(this.markNotificationsAsRead())
    }
  }

  render() {
    const {
      isMobileSize,
      dispatch,
      currentUser,
      size,
      route,
      giftsShown,
      subscription: { userAccessLevels },
      location,
    } = this.props
    const props = {
      dispatch,
      currentUser,
      size,
      hasSubscription: subscriptionHelper.hasActiveSubscriptions(
        userAccessLevels,
      ),
      onNotificationsHide: this.markNotificationsAsRead,
      route,
      giftsShown,
      location,
    }

    return isMobileSize() ? (
      <Sticker>
        <SalinasHeaderInner {...props} />
      </Sticker>
    ) : (
      <SalinasHeaderInner {...props} />
    )
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  size: state.app.size,
  isBookmateApp: state.app.isBookmateApp,
  currentUser: state.currentUser,
  subscription: state.subscription,
  giftsShown: state.gifts && isGiftsAvailable(state.gifts.availableKinds),
  showcaseType: state.showcase?.rootNavigation?.items,
}))

const wrapper = compose(uiBox, connectWrapper, withRouter)

export const SalinasHeader = wrapper(_SalinasHeader)

import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './more-button.styl'

import Button from 'client/shared/blocks/button'

type Props = {
  onClick?: () => void
  path?: string
  loading: boolean
  centered: boolean
}

type PropsWithLoading = {
  load: () => Promise<any>
  centered: boolean
}

type State = {
  loading: boolean
}

class MoreButton extends Component<Props> {
  static defaultProps = {
    loading: false,
    centered: false,
    children: <Trans id="buttons.show_more" />,
  }

  render(): JSX.Element {
    const { onClick, path, loading, children, centered } = this.props

    const className = centered
      ? 'more-button more-button_centered'
      : 'more-button'

    return (
      <div className={className}>
        <Button onClick={onClick} path={path} loading={loading} width="168px">
          {children}
        </Button>
      </div>
    )
  }
}

class MoreButtonWithLoading extends Component<PropsWithLoading, State> {
  static defaultProps = {
    centered: false,
    children: <Trans id="buttons.show_more" />,
  }

  state = {
    loading: false,
  }

  toggleLoader(loadingValue: boolean): void {
    this.setState({ loading: loadingValue })
  }

  onMoreButtonClick = (): void => {
    const { loading } = this.state
    const { load } = this.props

    if (loading) return

    this.toggleLoader(true)
    load()
      .then(() => {
        this.toggleLoader(false)
      })
      .catch(() => this.toggleLoader(false))
  }

  render(): JSX.Element {
    const { centered, children } = this.props

    return (
      <MoreButton
        onClick={this.onMoreButtonClick}
        loading={this.state.loading}
        centered={centered}
      >
        {children}
      </MoreButton>
    )
  }
}

export { MoreButtonWithLoading }

export default MoreButton

import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import './list-header.styl'

import Linka from 'client/shared/blocks/linka'
import { Heading } from 'client/shared/blocks/heading'
import { HeaderRank } from '../list/list'

type Props = {
  text: React.ReactNode | string
  path?: string | null | undefined
  looksLike: HeaderRank
  rank: HeaderRank
  count?: number
  shouldUseHistoryReplace: boolean
  interceptor: () => () => void | null | undefined
}

export default class ListHeader extends Component<Props> {
  static defaultProps = {
    rank: 1,
    shouldUseHistoryReplace: false,
  }

  render(): JSX.Element {
    const { path, rank, interceptor, looksLike } = this.props

    return (
      <div className="list-header">
        <Heading rank={rank} looksLike={looksLike}>
          {path && !interceptor ? this.renderAsLink() : this.renderAsText()}
        </Heading>
      </div>
    )
  }

  renderAsText(): JSX.Element {
    const { text, count, interceptor } = this.props

    return (
      <span
        className={cn(interceptor && 'list-header__pseudolink')}
        onClick={interceptor}
      >
        {text}
        {count && <span className="list-header__text-count">{count}</span>}
        {interceptor && (
          <span className="list-header__button">
            <Trans id="auth.all" />
          </span>
        )}
      </span>
    )
  }

  renderAsLink(): JSX.Element | null {
    const { text, path, count, shouldUseHistoryReplace } = this.props
    if (!path) return null

    return (
      <Linka
        path={path}
        className="list-header__link"
        replace={shouldUseHistoryReplace}
      >
        <span className="list-header__text">
          {text}
          {count && <span className="list-header__text-count">{count}</span>}
        </span>
        <span role="navigation" className="list-header__button">
          <Trans id="auth.all" />
        </span>
      </Linka>
    )
  }
}

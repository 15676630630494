import React, { Component } from 'react'
import Linka from 'client/shared/blocks/linka'
import { BookCover } from 'client/shared/blocks/book-cover'
import { Size } from 'client/shared/types/cover'
import { BookSerialProps } from 'client/shared/types/resource'
import { ComicbookProps } from 'client/shared/types/comicbook'

export type BookCoverProps = {
  book: BookSerialProps | ComicbookProps
  kind: 'grid' | 'list'
  coverSize?: Size
  description: boolean
  seriesPosition?: string
  bookPath?: string
  isInSlider?: boolean
}

export class BookCoverWrapper extends Component<BookCoverProps> {
  getCoverSize(): Size {
    const { kind, coverSize } = this.props

    if (!coverSize) {
      return ['grid'].includes(kind) ? 120 : 88
    }

    return coverSize
  }

  render(): JSX.Element {
    const {
      book,
      bookPath,
      description,
      seriesPosition,
      isInSlider,
    } = this.props
    const { title, library_card } = book

    const bookCover = (
      <BookCover
        book={book}
        size={this.getCoverSize()}
        isInSlider={isInSlider}
      />
    )
    const hasNewEpisode = library_card && library_card.has_new_episodes

    return (
      <div className="book__cover">
        {hasNewEpisode && <div className="book__new-episode-label" />}
        {seriesPosition && (
          <span className="book__position">{seriesPosition}</span>
        )}
        {description ? (
          bookCover
        ) : (
          <Linka title={title} path={bookPath}>
            {bookCover}
          </Linka>
        )}
      </div>
    )
  }
}

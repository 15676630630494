import React from 'react'
import Button from 'client/shared/blocks/button'
import Spacer from 'client/shared/blocks/spacer'
import LoginButton from 'client/shared/blocks/login-button'
import HeaderBox from 'client/shared/boxes/header-box'
import { Heading } from 'client/shared/blocks/heading/heading'
import Carousel from 'client/shared/blocks/carousel/carousel'
import './telcel-layout.styl'
import { THREE_MES } from 'client/bookmate/pages/telcel-page'

type Props = {
  auth: boolean
  handleForm: (e: React.SyntheticEvent<HTMLFormElement>) => void
  handleInput: (e: React.SyntheticEvent<HTMLInputElement>) => void
  isDesktop: boolean
  code: {
    codeValue: string
    autocomplete: boolean
    loading: boolean
    error: string | null | undefined
  }
  locale: string
  campaign?: string
}

const TEXTS = {
  heading: {
    threemes:
      'Bookmate es una app para leer y escuchar libros, audiolibros y cómics de manera ilimitada con una sola suscripción.',
    telcel: '',
  },
  head: {
    threemes: `Con Bookmate,<br />vas a querer leer más.<br /><span class="highlight-red">3 meses por 99 MXN</span>`,
    telcel: `Lee y escucha todos los libros que quieras durante <br /><span class="highlight-red">3 meses por 99 MXN</span>`,
  },
  caption: {
    threemes: '',
    telcel: '*Promoción válida hasta el 16 de septiembre 2023',
  },
}

export class TelcelLayout extends React.Component<Props> {
  render(): JSX.Element {
    const {
      auth,
      code,
      handleForm,
      handleInput,
      isDesktop,
      campaign = 'telcel',
    } = this.props
    const { codeValue, autocomplete, error, loading } = code
    const enabled = typeof codeValue === 'string' && codeValue?.trim()
    const isThreeMes = campaign === THREE_MES

    const SubmitForm = (
      <form onSubmit={handleForm}>
        {!isThreeMes && (
          <input
            className="telcel-code-input"
            placeholder="XXXXXXXX"
            type="text"
            value={codeValue}
            disabled={autocomplete}
            onChange={handleInput}
          />
        )}
        {error && (
          <>
            <Spacer />
            <div className="telcel-code-input_error">{error}</div>
          </>
        )}
        <Spacer />
        {auth ? (
          <>
            <Button
              className="telcel-action-button"
              type="inline"
              loading={loading}
              disabled={!enabled}
              aria-label="Activar la oferta"
            >
              Activar la oferta
            </Button>
            <Spacer />
          </>
        ) : (
          <LoginButton message="Activar la oferta" type="button" />
        )}
        <Spacer size={40} />
        <p className="telcel-subheader">{TEXTS.caption[campaign]}</p>
      </form>
    )

    return (
      <div className="telcel-layout">
        <HeaderBox />
        <div className="telcel-lead-in">
          <div className="telcel-column">
            <h1
              className="telcel-heading"
              dangerouslySetInnerHTML={{ __html: TEXTS.head[campaign] }}
            ></h1>
            <Spacer size={32} />
            <Heading
              looksLike={1}
              kind="graphik"
              rank={2}
              className="telcel-description"
            >
              {TEXTS.heading[campaign]}
            </Heading>
            <Spacer size={48} />
            {SubmitForm}
            {!isDesktop && (
              <>
                <Spacer size={32} />
                <div className="telcel-decor" />
              </>
            )}
          </div>
        </div>
        {isDesktop ? (
          <div className="telcel-benefits">
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[0].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[0].description}
                </p>
              </div>
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[1].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[1].description}
                </p>
              </div>
              <div className="telcel-benefit-cover" />
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[2].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[2].description}
                </p>
              </div>
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[3].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[3].description}
                </p>
              </div>
              <div className="telcel-benefit-cover" />
            </div>
          </div>
        ) : (
          <Carousel infinite={false} className="telcel-carousel" contain>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[0].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[0].description}
                </p>
              </div>
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[1].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[1].description}
                </p>
              </div>
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[2].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[2].description}
                </p>
              </div>
            </div>
            <div className="telcel-benefits-pair">
              <div className="telcel-benefit-cover" />
              <div className="telcel-benefit">
                <p className="telcel-benefit__lead-in">
                  {TELCEL_BENEFITS[3].leadIn}
                </p>
                <p className="telcel-benefit__desc">
                  {TELCEL_BENEFITS[3].description}
                </p>
              </div>
            </div>
          </Carousel>
        )}
        <div className="telcel-footer">
          <Heading kind="kazimir" rank={2}>
            ¡Regístrate y disfruta!
          </Heading>
          <Spacer size={48} />
          {SubmitForm}
        </div>
      </div>
    )
  }
}

const TELCEL_BENEFITS = [
  {
    leadIn: 'Explora un catalógo infinito',
    description:
      'Descubre nuestro inmenso catálogo con millones de libros, audiolibros y cómics; desde novelas clásicas hasta biografías, policiales y libros de autoayuda. ¡Y muchas opciones infantiles también!',
  },
  {
    leadIn: 'Lee donde quieras y cuando quieras',
    description:
      'Disfruta Bookmate en tu teléfono, tablet o computadora, incluso sin conexión a Internet. Puedes tener hasta tres dispositivos sincronizados.',
  },
  {
    leadIn: 'Personaliza tu lectura',
    description:
      'Cambia el color de tu pantalla, modifica el tamaño de la fuente, decide hacia dónde deslizar tu siguiente página, modifica la velocidad del reproductor de audio.',
  },
  {
    leadIn: 'Comparte tus gustos',
    description:
      'Comparte tus títulos favoritos y opiniones con una comunidad de 13 millones de lectores alrededor del mundo. Sigue a autores o líderes de opinión para descubrir lo que leen.',
  },
]

import React, { PureComponent } from 'react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { hexToRGB, stringifyRGB } from 'client/shared/helpers/color-helpers'

import Linka from 'client/shared/blocks/linka'

import './badge.styl'

type BadgeType =
  | 'premium'
  | 'default'
  | 'free'
  | 'private'
  | 'audio'
  | 'limited_audio'
  | 'user_upload'
  | 'litres'
  | 'language'
  | 'unavailable'

type BadgeKind = 'list'

type Props = {
  type: BadgeType
  kind?: BadgeKind
  children: React.ReactNode
  disabled: boolean
  query?: QueryParams
}

type StyledBadgeProps = {
  color: string // RGB in hex
  children: React.ReactNode
}

export default class Badge extends PureComponent<Props> {
  static defaultProps = {
    disabled: false,
  }

  render(): JSX.Element {
    const { type, kind, children, disabled, query } = this.props
    const kindMod = ['list'].includes(kind as string) ? `badge_${kind}` : ''
    const disabledMod = disabled ? 'badge_disabled' : ''
    let badge

    if (
      !disabled &&
      ['premium', 'default', 'audio', 'limited_audio'].includes(type)
    ) {
      badge = (
        <Linka
          path={urlFor.subscription('', query)}
          className={`badge badge_${type} ${kindMod}`}
        >
          {children}
        </Linka>
      )
    } else {
      badge = (
        <div className={`badge badge_${type} ${disabledMod}`}>{children}</div>
      )
    }
    return badge
  }
}

export class StyledBadge extends PureComponent<StyledBadgeProps> {
  getBackgroundColor(): string {
    const rgb = hexToRGB(this.props.color)
    if (!rgb) return ''

    return stringifyRGB({
      ...rgb,
      a: 0.15,
    })
  }

  getStyles(): { color: string; background?: string } {
    return {
      color: this.props.color,
      background: this.getBackgroundColor(),
    }
  }

  render(): JSX.Element {
    return (
      <div className="badge" style={this.getStyles()}>
        {this.props.children}
      </div>
    )
  }
}

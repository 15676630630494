import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import './logo.styl'

import Linka from 'client/shared/blocks/linka'

import LogoIcon from './logo.svg'
import WhiteLogoIcon from './logo_white.svg'
import BlackLogoIcon from './logo_black.svg'
import BlueLogoIcon from './logo_blue.svg'
import LogoBrown from '../../../shared/assets/landing/family-subscription/logo-brown.svg'
import LogoOrange from '../../../shared/assets/landing/family-subscription/logo-orange.svg'
import LogoGreen from '../../../shared/assets/landing/family-subscription/logo-green.svg'
import LogoGray from '../../../shared/assets/landing/family-subscription/logo-gray.svg'
import BrownishLogoIcon from './logo_brownish.svg'
import ShadowLogoIcon from './logo_shadow.svg'
import OrangeLogoIcon from './orange_logo.svg'

type Props = {
  kind: LogoKind
  href?: string
  size?: number
}

export type LogoKind =
  | 'default'
  | 'white'
  | 'black'
  | 'blue'
  | 'brownish'
  | 'shadow'
  | 'family'
  | 'orange'

class Logo extends Component<Props> {
  static defaultProps = {
    kind: 'default',
  }

  getIcon(): string {
    const { kind } = this.props

    switch (kind) {
      case 'white':
        return WhiteLogoIcon
      case 'black':
        return BlackLogoIcon
      case 'blue':
        return BlueLogoIcon
      case 'brownish':
        return BrownishLogoIcon
      case 'shadow':
        return ShadowLogoIcon
      case 'orange':
        return OrangeLogoIcon
      default:
        return LogoIcon
    }
  }

  renderFamilyLogos(): JSX.Element[] {
    const logos = [LogoBrown, LogoOrange, LogoGreen, LogoGray]
    return logos.map((item, index) => {
      return (
        <div key={index} className="logo__item">
          <SVGInline svg={item} />
        </div>
      )
    })
  }

  render(): JSX.Element {
    const { href, size, kind } = this.props
    const sizeMod = size ? `logo_size_${size}` : ''
    const kindMod = kind ? `logo_kind-${kind}` : ''

    return href ? (
      <Linka
        path={href}
        className={`logo ${sizeMod} ${kindMod}`}
        forceDefaultBehaviour
        aria-label="Bookmate logo"
      >
        {kind === 'family' ? (
          this.renderFamilyLogos()
        ) : (
          <SVGInline svg={this.getIcon()} />
        )}
      </Linka>
    ) : (
      <div className={`logo ${sizeMod}`}>
        {kind === 'family' ? (
          this.renderFamilyLogos()
        ) : (
          <SVGInline svg={this.getIcon()} />
        )}
      </div>
    )
  }
}

export default Logo

import merge from 'lodash/merge'
import noop from 'lodash/noop'

import { CALL_API } from 'shared/middlewares/api-middleware'

const SERIES_FOLLOW = `SERIES_FOLLOW`
const SERIES_FOLLOW_ERROR = `SERIES_FOLLOW_ERROR`
export const SERIES_FOLLOW_SUCCESS = `SERIES_FOLLOW_SUCCESS`

const SERIES_UNFOLLOW = `SERIES_UNFOLLOW`
const SERIES_UNFOLLOW_ERROR = `SERIES_UNFOLLOW_ERROR`
export const SERIES_UNFOLLOW_SUCCESS = `SERIES_UNFOLLOW_SUCCESS`

const SERIES_TURN_ON_NOTIFICATIONS = `SERIES_TURN_ON_NOTIFICATIONS`
const SERIES_TURN_ON_NOTIFICATIONS_ERROR = `SERIES_TURN_ON_NOTIFICATIONS_ERROR`
const SERIES_TURN_ON_NOTIFICATIONS_SUCCESS = `SERIES_TURN_ON_NOTIFICATIONS_SUCCESS`

const SERIES_TURN_OFF_NOTIFICATIONS = `SERIES_TURN_OFF_NOTIFICATIONS`
const SERIES_TURN_OFF_NOTIFICATIONS_ERROR = `SERIES_TURN_OFF_NOTIFICATIONS_ERROR`
const SERIES_TURN_OFF_NOTIFICATIONS_SUCCESS = `SERIES_TURN_OFF_NOTIFICATIONS_SUCCESS`

export function follow(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/followers`,
      options: {
        method: 'post',
      },
      modifyResponse: () => ({ uuid }),
      types: [SERIES_FOLLOW, SERIES_FOLLOW_SUCCESS, SERIES_FOLLOW_ERROR],
    },
  }
}

export function unfollow(uuid, onSuccess = noop) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/followers`,
      options: {
        method: 'delete',
      },
      modifyResponse: () => ({ uuid }),
      onSuccess,
      types: [SERIES_UNFOLLOW, SERIES_UNFOLLOW_SUCCESS, SERIES_UNFOLLOW_ERROR],
    },
  }
}

export function turnOnNotifications(uuid, onSuccess = noop) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/notification_options`,
      options: {
        method: 'post',
      },
      modifyResponse: () => ({ uuid }),
      onSuccess,
      types: [
        SERIES_TURN_ON_NOTIFICATIONS,
        SERIES_TURN_ON_NOTIFICATIONS_SUCCESS,
        SERIES_TURN_ON_NOTIFICATIONS_ERROR,
      ],
    },
  }
}

export function turnOffNotifications(uuid, onSuccess = noop) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/notification_options`,
      options: {
        method: 'delete',
      },
      modifyResponse: () => ({ uuid }),
      onSuccess,
      types: [
        SERIES_TURN_OFF_NOTIFICATIONS,
        SERIES_TURN_OFF_NOTIFICATIONS_SUCCESS,
        SERIES_TURN_OFF_NOTIFICATIONS_ERROR,
      ],
    },
  }
}

const initialState = {}

export default function seriesListReducer(state = initialState, action) {
  if (action.entities && action.entities.series) {
    return merge({}, state, action.entities.series)
  }

  switch (action.type) {
    case SERIES_FOLLOW_SUCCESS: {
      const followedSeries = state[action.uuid]

      if (!followedSeries) return state

      return {
        ...state,
        [followedSeries.uuid]: {
          ...followedSeries,
          following: true,
          followers_count: followedSeries.followers_count + 1,
          notifications_enabled: true,
        },
      }
    }

    case SERIES_UNFOLLOW_SUCCESS: {
      const followedSeries = state[action.uuid]

      if (!followedSeries) return state

      return {
        ...state,
        [followedSeries.uuid]: {
          ...followedSeries,
          following: false,
          followers_count: followedSeries.followers_count - 1,
          notifications_enabled: false,
        },
      }
    }

    case SERIES_TURN_ON_NOTIFICATIONS_SUCCESS: {
      const followedSeries = state[action.uuid]

      if (!followedSeries) return state

      return {
        ...state,
        [followedSeries.uuid]: {
          ...followedSeries,
          notifications_enabled: true,
        },
      }
    }

    case SERIES_TURN_OFF_NOTIFICATIONS_SUCCESS: {
      const followedSeries = state[action.uuid]

      if (!followedSeries) return state

      return {
        ...state,
        [followedSeries.uuid]: {
          ...followedSeries,
          notifications_enabled: false,
        },
      }
    }

    default:
      return state
  }
}

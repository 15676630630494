const alpha2CodesOfEUCountries = [
  'at',
  'be',
  'bg',
  'hr',
  'cy',
  'cz',
  'dk',
  'ee',
  'fl',
  'fr',
  'de',
  'gr',
  'hu',
  'ie',
  'it',
  'lv',
  'lt',
  'lu',
  'mt',
  'nl',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'es',
  'se',
  'gb',
]

const alpha2CodesOfLatAmCountries = [
  'br', // Brazil
  'mx', // Mexico
  'gt', // Guatemala
  'co', // Colombia
  'py', // Paraguay
  'hn', // Honduras
  'ni', // Nicaragua
  'sv', // El Salvador
  'bo', // Bolivia
  'pe', // Peru
  'ar', // Argentina
  've', // Venezuela
  'cl', // Chile
  'ec', // Ecuador
  'pa', // Panama
  'uy', // Uruguay
]

const alpha2CodesOfAdriaCountries = [
  'rs', // Serbia
  'hr', // Croatia
  'me', // Montenegro
  'bg', // Bulgaria
]

const alpha2CodesOfCisCountries = [
  'ru', // Russia
  'tm', // Turkmenistan
  'am', // Armenia
  'az', // Azerbaijan
  'by', // Belarus
  'kz', // Kazakhstan
  'kg', // Kyrgyzstan
  'md', // Moldova
  'tj', // Tajikistan
  'uz', // Uzbekistan
]

const alpha2CodesOfAfricaCountries = [
  'dz',
  'ao',
  'bj',
  'bw',
  'io',
  'bf',
  'bi',
  'cv',
  'cm',
  'cf',
  'td',
  'km',
  'cg',
  'cd',
  'ci',
  'dj',
  'eg',
  'gq',
  'er',
  'sz',
  'et',
  'tf',
  'ga',
  'gm',
  'gh',
  'gn',
  'gw',
  'ke',
  'ls',
  'lr',
  'ly',
  'mg',
  'mw',
  'ml',
  'mr',
  'mu',
  'yt',
  'ma',
  'mz',
  'na',
  'ne',
  'ng',
  're',
  'rw',
  'sh',
  'st',
  'sn',
  'sc',
  'sl',
  'so',
  'za',
  'ss',
  'sd',
  'tz',
  'tg',
  'tn',
  'ug',
  'eh',
  'zm',
  'zw',
]

export function isEUCountry(countryCode: string): boolean {
  return alpha2CodesOfEUCountries.includes(countryCode)
}

export function isLatAmCountry(countryCode: string): boolean {
  return alpha2CodesOfLatAmCountries.includes(countryCode)
}

export function isAdriaCountry(countryCode: string): boolean {
  return alpha2CodesOfAdriaCountries.includes(countryCode)
}

export function isCisCountry(countryCode: string): boolean {
  return alpha2CodesOfCisCountries.includes(countryCode)
}

export function isAfricaCountry(countryCode: string): boolean {
  return alpha2CodesOfAfricaCountries.includes(countryCode)
}

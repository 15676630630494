import { schema } from 'normalizr'

import {
  bookSchema,
  audioBookSchema,
  comicbookSchema,
  seriesSchema,
  shelfSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import { postSchema } from 'client/bookmate/reducers/posts-reducer'
import { libraryBannerSchema } from 'client/bookmate/reducers/library-banners-reducer'
import { pluralizeObjectTypeName } from 'client/bookmate/helpers/showcase-helpers'

import { ShowcaseSection } from 'client/shared/types/showcase'

export const showcaseSectionSchema = new schema.Entity(
  'showcaseSections',
  {},
  {
    idAttribute: (value: ShowcaseSection) =>
      `${value.slug}-${value.showcase_tag}`,
  },
)

const showcaseSectionObjectsSchema = new schema.Array(
  {
    books: bookSchema,
    audiobooks: audioBookSchema,
    comicbooks: comicbookSchema,
    shelves: shelfSchema,
    banners: libraryBannerSchema,
    posts: postSchema,
    series: seriesSchema,
  },
  (input, parent) => pluralizeObjectTypeName(parent.objects_type),
)

showcaseSectionSchema.define({
  objects: showcaseSectionObjectsSchema,
})

export const showcaseSectionsSchema = new schema.Array(showcaseSectionSchema)

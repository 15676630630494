import { CALL_API } from 'shared/middlewares/api-middleware'
import { normalize, denormalize, schema } from 'normalizr'
import merge from 'lodash/merge'
import mapValues from 'lodash/mapValues'

import { QUOTE_REMOVED } from 'client/bookmate/reducers/quotes-reducer'
import {
  prepareImpressionsFromAPIResponse,
  IMPRESSION_REMOVED,
} from 'client/bookmate/reducers/impressions-reducer'

import { constants } from 'client/bookmate/constants'
import { serialChanged } from 'client/bookmate/reducers/serial-reducer'

import {
  USER_FOLLOW,
  USER_UNFOLLOW,
} from 'client/bookmate/reducers/users-reducer'
import { impressionsSchema } from 'client/bookmate/reducers/impressions-reducer'
import { quoteSchema } from 'client/bookmate/reducers/quotes-reducer'

import { getUserById } from 'client/bookmate/reducers/users-reducer'

import {
  shelfSchema,
  userSchema,
  usersSchema,
  bookSchema,
  audioBookSchema,
  comicbookSchema,
  booksSchema,
  audioBooksSchema,
  comicbooksSchema,
  seriesListSchema,
} from 'client/bookmate/reducers/schemas/schemas'

/**
Our names for filters for books and audiobooks are different from what is used by the api.

Here's the map:

For books:

Us                Api           Meaning
'all'             <nothing>     Show all books
'recent'          'reading'     Show books the user is reading
'not_finished'    'later'       Show books saved for later
'finished'        'finished'    Show books the user has finished
'uploads'         'uploaded'    Show books the user has uploaded

For comicbooks:

Us                Api           Meaning
'all'             <nothing>     Show all comicbooks
'recent'          'reading'     Show comicbooks the user is reading
'not_finished'    'added'       Show comicbooks saved for later
'finished'        'finished'    Show comicbooks the user has finished

For audiobooks:

Us                Api           Meaning
'all'             <nothing>     Show all audiobooks
'recent'          'listening'   Show audiobooks the user is listening
'not_finished'    'added'       Show audiobooks saved for later
'finished'        'finished'    Show audiobooks the user has finished
**/
export const USER_BOOKS_ALL = 'all'
export const USER_BOOKS_RECENT = 'recent'
export const USER_BOOKS_FINISHED = 'finished'
export const USER_BOOKS_NOT_FINISHED = 'not_finished'
export const USER_BOOKS_UPLOADS = 'uploads'

// consts for keywords used by the api
export const USER_BOOKS_READING = 'reading'

const USER_LOAD = 'USER_LOAD'
const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS'

const USER_COUNTERS_LOAD = 'USER_COUNTERS_LOAD'
const USER_COUNTERS_LOAD_SUCCESS = 'USER_COUNTERS_LOAD_SUCCESS'

const USER_BOOKS_LOAD = 'USER_BOOKS_LOAD'
const USER_BOOKS_LOAD_SUCCESS = 'USER_BOOKS_LOAD_SUCCESS'

const USER_AUDIOBOOKS_LOAD = 'USER_AUDIOBOOKS_LOAD'
const USER_AUDIOBOOKS_LOAD_SUCCESS = 'USER_AUDIOBOOKS_LOAD_SUCCESS'

const USER_COMICBOOKS_LOAD = 'USER_COMICBOOKS_LOAD'
const USER_COMICBOOKS_LOAD_SUCCESS = 'USER_COMICBOOKS_LOAD_SUCCESS'

const USER_RECENT_CONTENTS_LOAD = 'USER_RECENT_CONTENTS_LOAD'
export const USER_RECENT_CONTENTS_LOAD_SUCCESS =
  'USER_RECENT_CONTENTS_LOAD_SUCCESS'

const USER_SHELVES_LOAD = 'USER_SHELVES_LOAD'
const USER_SHELVES_LOAD_SUCCESS = 'USER_SHELVES_LOAD_SUCCESS'

const USER_SHELVES_FOLLOWING_LOAD = 'USER_SHELVES_FOLLOWING_LOAD'
const USER_SHELVES_FOLLOWING_LOAD_SUCCESS =
  'USER_SHELVES_FOLLOWING_LOAD_SUCCESS'

const USER_QUOTES_LOAD = 'USER_QUOTES_LOAD'
const USER_QUOTES_LOAD_SUCCESS = 'USER_QUOTES_LOAD_SUCCESS'
export const USER_GROUPED_QUOTES_LOAD_SUCCESS =
  'USER_GROUPED_QUOTES_LOAD_SUCCESS'

const USER_IMPRESSIONS_LOAD = 'USER_IMPRESSIONS_LOAD'
const USER_IMPRESSIONS_LOAD_SUCCESS = 'USER_IMPRESSIONS_LOAD_SUCCESS'

const USER_FOLLOWERS_LOAD = 'USER_FOLLOWERS_LOAD'
const USER_FOLLOWERS_LOAD_SUCCESS = 'USER_FOLLOWERS_LOAD_SUCCESS'

const USER_FOLLOWING_LOAD = 'USER_FOLLOWING_LOAD'
const USER_FOLLOWING_LOAD_SUCCESS = 'USER_FOLLOWING_LOAD_SUCCESS'

const USER_SERIES_LOAD = 'USER_SERIES_LOAD'
const USER_SERIES_LOAD_SUCCESS = 'USER_SERIES_LOAD_SUCCESS'
const USER_SERIES_LOAD_ERROR = 'USER_SERIES_LOAD_ERROR'

const USER_IMPORT_REMOVED = 'USER_IMPORT_REMOVED'

const CHANGE_LAYOUT = 'CHANGE_LAYOUT'

const ITEM_TYPE_TO_SCHEMA = {
  book: bookSchema,
  audiobook: audioBookSchema,
  comicbook: comicbookSchema,
}

/**
 * Combine state of this reducer with the state of users reducer (for appropriate user)
 * to get an object with complete user information
 */
export function buildUser(state, id) {
  return merge({}, state.user, getUserById(state, id))
}

function isOwnProfile(state, username) {
  const {
    currentUser: {
      auth,
      data: { login },
    },
  } = state
  return auth && username === login
}

function getBaseUri(state, username) {
  return isOwnProfile(state, username)
    ? `/p/api/v5/profile`
    : `/p/api/v5/users/${username}`
}

export function getRecentItems(state, itemSignatures) {
  const { entities } = state

  return itemSignatures.map(({ type, id }) => {
    return {
      type,
      entity: denormalize(id, ITEM_TYPE_TO_SCHEMA[type], entities),
    }
  })
}

export function load(name) {
  return async (dispatch, getState) => {
    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/users/${name}`,
        normalize: res => {
          const { user: _user } = res
          const { result: login, entities } = normalize(_user, userSchema)

          return {
            entities,
            data: {
              login,
              myself: isOwnProfile(getState(), login),
            },
          }
        },
        types: [USER_LOAD, USER_LOAD_SUCCESS],
      },
    })
  }
}

export function loadCounters(username) {
  return async (dispatch, getState) => {
    await dispatch({
      [CALL_API]: {
        endpoint: `${getBaseUri(getState(), username)}/counters`,
        normalize: res => ({ data: res.counters }),
        types: [USER_COUNTERS_LOAD, USER_COUNTERS_LOAD_SUCCESS],
      },
    })
  }
}

function filterToApiFragmentMapper(contentType, filter) {
  const booksFilterMap = {
    recent: 'reading',
    finished: 'finished',
    not_finished: 'later',
    uploads: 'uploads',
  }
  const audiobooksFilterMap = {
    recent: 'listening',
    finished: 'finished',
    not_finished: 'added',
  }
  const comicbooksFilterMap = {
    recent: 'reading',
    finished: 'finished',
    not_finished: 'added',
  }

  if (contentType === 'books') {
    return booksFilterMap[filter]
  } else if (contentType === 'audiobooks') {
    return audiobooksFilterMap[filter]
  } else if (contentType === 'comicbooks') {
    return comicbooksFilterMap[filter]
  }
}

export function loadBooks(
  username: string,
  filter?: string,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async (dispatch, getState) => {
    let apiFragment = filter
      ? filterToApiFragmentMapper('books', filter)
      : undefined
    apiFragment = apiFragment ? `/${apiFragment}` : ''

    const uri = `${getBaseUri(getState(), username)}/books${apiFragment}`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: booksSchema,
        responseKey: 'books',
        modifyResponse: res => ({
          append,
          nextPage: res.result.length ? page + 1 : null,
          filter: filter || USER_BOOKS_ALL,
        }),
        types: [USER_BOOKS_LOAD, USER_BOOKS_LOAD_SUCCESS],
      },
    })
  }
}

export function loadAudioBooks(
  username,
  filter,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async (dispatch, getState) => {
    let apiFragment = filter
      ? filterToApiFragmentMapper('audiobooks', filter)
      : undefined
    apiFragment = apiFragment ? `/${apiFragment}` : ''

    const uri = `${getBaseUri(getState(), username)}/audiobooks${apiFragment}`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: audioBooksSchema,
        responseKey: 'audiobooks',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
          filter: filter || USER_BOOKS_ALL,
        }),
        types: [USER_AUDIOBOOKS_LOAD, USER_AUDIOBOOKS_LOAD_SUCCESS],
      },
    })
  }
}

export function loadComicbooks(
  username,
  filter,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async (dispatch, getState) => {
    let apiFragment = filter
      ? filterToApiFragmentMapper('comicbooks', filter)
      : undefined
    apiFragment = apiFragment ? `/${apiFragment}` : ''

    const uri = `${getBaseUri(getState(), username)}/comicbooks${apiFragment}`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: comicbooksSchema,
        responseKey: 'comicbooks',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
          filter: filter || USER_BOOKS_ALL,
        }),
        types: [USER_COMICBOOKS_LOAD, USER_COMICBOOKS_LOAD_SUCCESS],
      },
    })
  }
}

// load books, audiobooks and comicbooks recently accessed by user
export function loadRecent(username) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/users/${username}/recent_contents`,
      normalize: ({ recent_contents: recentContents }) => {
        const accumulator = { result: [], entities: {} }
        recentContents.reduce((acc, item) => {
          const _schema = ITEM_TYPE_TO_SCHEMA[item.type]
          const { result: id, entities } = normalize(item.entity, _schema)
          const itemInfo = { type: item.type, id }
          acc.result = [...acc.result, itemInfo]
          acc.entities = merge({}, acc.entities, entities)
          return acc
        }, accumulator)

        return {
          result: accumulator.result,
          entities: accumulator.entities,
        }
      },
      types: [USER_RECENT_CONTENTS_LOAD, USER_RECENT_CONTENTS_LOAD_SUCCESS],
    },
  }
}

export function loadShelves(username, page = 1, per_page = 20, append = false) {
  return async (dispatch, getState) => {
    const uri = `${getBaseUri(getState(), username)}/bookshelves`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: new schema.Array(shelfSchema),
        responseKey: 'bookshelves',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [USER_SHELVES_LOAD, USER_SHELVES_LOAD_SUCCESS],
      },
    })
  }
}

export function loadShelvesFollowing(
  username,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async (dispatch, getState) => {
    const uri = `${getBaseUri(getState(), username)}/bookshelves/following`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: new schema.Array(shelfSchema),
        responseKey: 'bookshelves',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [
          USER_SHELVES_FOLLOWING_LOAD,
          USER_SHELVES_FOLLOWING_LOAD_SUCCESS,
        ],
      },
    })
  }
}

export function loadQuotes(username, page = 1, per_page = 20, append = false) {
  return async (dispatch, getState) => {
    const uri = `${getBaseUri(getState(), username)}/quotes`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: new schema.Array(quoteSchema),
        responseKey: 'quotes',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [USER_QUOTES_LOAD, USER_QUOTES_LOAD_SUCCESS],
      },
    })
  }
}

export function loadGroupedQuotes(
  username,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async (dispatch, getState) => {
    const uri = `${getBaseUri(getState(), username)}/quotes/grouped`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        normalize: res => {
          const { result, entities } = res.quotes.reduce(
            (prevResult, item) => {
              const normalizedQuote = normalize(item.quote, quoteSchema)

              prevResult.result.push({
                count: item.all_quotes_count,
                id: normalizedQuote.result,
              })
              prevResult.entities = merge(
                {},
                prevResult.entities,
                normalizedQuote.entities,
              )

              return prevResult
            },
            { result: [], entities: {} },
          )

          return {
            result,
            entities,
            append,
            nextPage: result.length ? page + 1 : null,
          }
        },
        types: [USER_QUOTES_LOAD, USER_GROUPED_QUOTES_LOAD_SUCCESS],
      },
    })
  }
}

export function loadBookQuotes(
  username,
  bookUuid,
  page = 1,
  per_page = 20,
  append = false,
) {
  return async dispatch => {
    const uri = `/p/api/v5/books/${bookUuid}/quotes`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
            user_login: username,
          },
        },
        normalize: res => {
          if (res.quotes.length === 0 && page === 1) {
            // eslint-disable-next-line no-throw-literal
            throw { response: { status: 404 } }
          } else {
            const { result, entities } = normalize(
              res.quotes,
              new schema.Array(quoteSchema),
            )

            return {
              result,
              entities,
              append,
              nextPage: result.length ? page + 1 : null,
            }
          }
        },
        onError: (_dispatch, getState, err) => {
          // the error below will, with server-side rendering, make the app show the 404 error page
          // eslint-disable-next-line no-throw-literal
          throw { response: { status: err.code } }
        },
        types: [USER_QUOTES_LOAD, USER_QUOTES_LOAD_SUCCESS],
      },
    })
  }
}

export function loadImpressions({
  username,
  page = 1,
  per_page = 20,
  append = false,
}) {
  return async (dispatch, getState) => {
    const uri = `${getBaseUri(getState(), username)}/impressions`

    await dispatch({
      [CALL_API]: {
        endpoint: uri,
        options: {
          data: {
            page,
            per_page,
          },
        },
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionsFromAPIResponse(response.impressions),
            impressionsSchema,
          )

          return {
            result,
            entities,
            append,
            nextPage: result.length ? page + 1 : null,
          }
        },
        types: [USER_IMPRESSIONS_LOAD, USER_IMPRESSIONS_LOAD_SUCCESS],
      },
    })
  }
}

export function loadFollowings(
  username,
  page = 1,
  per_page = 20,
  append = false,
) {
  const uri = `/p/api/v5/users/${username}/followings`

  return {
    [CALL_API]: {
      endpoint: uri,
      options: {
        data: {
          page,
          per_page,
        },
      },
      schema: usersSchema,
      responseKey: 'users',
      modifyResponse: response => ({
        data: {
          ids: response.result,
          nextPage: response.result.length ? page + 1 : null,
          append,
        },
      }),
      types: [USER_FOLLOWING_LOAD, USER_FOLLOWING_LOAD_SUCCESS],
    },
  }
}

export function loadFollowers(
  username,
  page = 1,
  per_page = 20,
  append = false,
) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/users/${username}/followers`,
      options: {
        data: {
          page,
          per_page,
        },
      },
      schema: usersSchema,
      responseKey: 'users',
      modifyResponse: response => ({
        data: {
          ids: response.result,
          nextPage: response.result.length ? page + 1 : null,
          append,
        },
      }),
      types: [USER_FOLLOWERS_LOAD, USER_FOLLOWERS_LOAD_SUCCESS],
    },
  }
}

export function loadSeries(username, page = 1, per_page = 20, append = false) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/users/${username}/series/following`,
      options: {
        data: {
          page,
          per_page,
        },
      },
      schema: seriesListSchema,
      responseKey: 'series',
      modifyResponse: response => ({
        data: {
          ids: response.result,
          nextPage: response.result.length ? page + 1 : null,
          append,
        },
      }),
      types: [
        USER_SERIES_LOAD,
        USER_SERIES_LOAD_SUCCESS,
        USER_SERIES_LOAD_ERROR,
      ],
    },
  }
}

// after removal of an audio card (which occurs in audio-cards-reducer),
// or of a comicbook card (which occurs in comicbooks-reducer)
// delete the id of the associated entity from the user state
// (i.e. from user.audiobooks or user.comicbooks object)
function cleanRemovedEntityId(entityId, container) {
  return mapValues(container, section => section.filter(id => id !== entityId))
}

export function changeLayout(layout) {
  return {
    type: CHANGE_LAYOUT,
    layout,
  }
}

const initialState = {
  ui: {
    layout: 'grid',
    loaded: {},
  },
  login: '',
  myself: false,
  counters: {
    reading: 0,
    total: 0,
    finished: 0,
    read_later: 0,
    uploaded: 0,
    impressions: 0,
    quotes: 0,
    bookshelves: 0,
    following_bookshelves: 0,
  },
  followings_count: 0,
  followers_count: 0,
  recentItems: [],
  books: {
    all: [],
    recent: [],
    finished: [],
    not_finished: [],
    uploaded: [],
  },
  audiobooks: {
    all: [],
    recent: [],
    finished: [],
    not_finished: [],
  },
  comicbooks: {
    all: [],
    recent: [],
    finished: [],
    not_finished: [],
  },
  shelves: {
    created: [],
    following: [],
  },
  series: [],
  quotes: [],
  grouped_quotes: [],
  impressions: [],
  followingUsers: [],
  followersUsers: [],
  pages: {
    books: {
      all: 1,
      recent: 1,
      not_finished: 1,
      finished: 1,
      uploaded: 1,
    },
    audiobooks: {
      all: 1,
      recent: 1,
      not_finished: 1,
      finished: 1,
    },
    comicbooks: {
      all: 1,
      recent: 1,
      not_finished: 1,
      finished: 1,
    },
    shelves: {
      created: 1,
      following: 1,
    },
    series: 1,
    impressions: 1,
    quotes: 1,
    grouped_quotes: 1,
    followingUsers: 1,
    followersUsers: 1,
  },
}

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case USER_LOAD_SUCCESS:
      return {
        ...state,
        ...action.data,
        ui: {
          layout: 'grid',
          ...state.ui,
        },
      }

    case USER_COUNTERS_LOAD_SUCCESS:
      return {
        ...state,
        counters: action.data,
      }

    case USER_BOOKS_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loaded: {
            ...state.ui.loaded,
            books: {
              ...state.ui.loaded.books,
              [action.filter]: true,
            },
          },
        },
        books: {
          ...state.books,
          [action.filter]: action.append
            ? [...state.books[action.filter], ...action.result]
            : action.result,
        },
        pages: {
          ...state.pages,
          books: {
            [action.filter]: action.nextPage,
          },
        },
      }

    case USER_AUDIOBOOKS_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loaded: {
            ...state.ui.loaded,
            audiobooks: {
              ...state.ui.loaded.audiobooks,
              [action.filter]: true,
            },
          },
        },
        audiobooks: {
          ...state.audiobooks,
          [action.filter]: action.append
            ? [...state.audiobooks[action.filter], ...action.result]
            : action.result,
        },
        pages: {
          ...state.pages,
          audiobooks: {
            [action.filter]: action.nextPage,
          },
        },
      }

    case USER_COMICBOOKS_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loaded: {
            ...state.ui.loaded,
            comicbooks: {
              ...state.ui.loaded.comicbooks,
              [action.filter]: true,
            },
          },
        },
        comicbooks: {
          ...state.comicbooks,
          [action.filter]: action.append
            ? [...state.comicbooks[action.filter], ...action.result]
            : action.result,
        },
        pages: {
          ...state.pages,
          comicbooks: {
            [action.filter]: action.nextPage,
          },
        },
      }

    case USER_RECENT_CONTENTS_LOAD_SUCCESS:
      return {
        ...state,
        recentItems: action.result,
      }

    case USER_SHELVES_LOAD_SUCCESS:
      return {
        ...state,
        shelves: {
          ...state.shelves,
          created: action.append
            ? [...state.shelves.created, ...action.result]
            : action.result,
        },
        pages: {
          ...state.pages,
          shelves: {
            created: action.nextPage,
          },
        },
      }

    case USER_SHELVES_FOLLOWING_LOAD_SUCCESS:
      return {
        ...state,
        shelves: {
          ...state.shelves,
          following: action.append
            ? [...state.shelves.following, ...action.result]
            : action.result,
        },
        pages: {
          ...state.pages,
          shelves: {
            following: action.nextPage,
          },
        },
      }

    case USER_SERIES_LOAD_SUCCESS:
      return {
        ...state,
        series: action.append
          ? [...state.series, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          series: action.nextPage,
        },
      }

    case USER_QUOTES_LOAD_SUCCESS:
      return {
        ...state,
        quotes: action.append
          ? [...state.quotes, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          quotes: action.nextPage,
        },
      }

    case USER_GROUPED_QUOTES_LOAD_SUCCESS:
      return {
        ...state,
        grouped_quotes: action.append
          ? [...state.grouped_quotes, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          grouped_quotes: action.nextPage,
        },
      }

    case USER_IMPRESSIONS_LOAD_SUCCESS:
      return {
        ...state,
        impressions: action.append
          ? [...state.impressions, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          impressions: action.nextPage,
        },
      }

    case USER_FOLLOWING_LOAD_SUCCESS:
      return {
        ...state,
        followingUsers: action.data.append
          ? [...state.followingUsers, ...action.data.ids]
          : action.data.ids,
        pages: {
          ...state.pages,
          followingUsers: action.data.nextPage,
        },
      }

    case USER_FOLLOWERS_LOAD_SUCCESS:
      return {
        ...state,
        followersUsers: action.data.append
          ? [...state.followersUsers, ...action.data.ids]
          : action.data.ids,
        pages: {
          ...state.pages,
          followersUsers: action.data.nextPage,
        },
      }

    case USER_FOLLOW:
      if (state.myself) {
        return {
          ...state,
          followingUsers: [action.data.login, ...state.followingUsers],
        }
      } else {
        return state
      }

    case USER_UNFOLLOW:
      if (state.myself) {
        return {
          ...state,
          followingUsers: state.followingUsers.filter(
            username => username !== action.data.login,
          ),
        }
      } else {
        return state
      }

    case QUOTE_REMOVED: {
      const removedQuoteId = action.uuid

      return {
        ...state,
        counters: {
          ...state.counters,
          quotes: state.counters.quotes - 1,
        },
        quotes: state.quotes.filter(quoteId => quoteId !== removedQuoteId),
        grouped_quotes: state.grouped_quotes.filter(
          groupedQuote => groupedQuote.id !== removedQuoteId,
        ),
      }
    }

    case IMPRESSION_REMOVED: {
      const removedImpressionId = action.uuid

      return {
        ...state,
        counters: {
          ...state.counters,
          impressions: state.counters.impressions - 1,
        },
        impressions: state.impressions.filter(
          impressionId => impressionId !== removedImpressionId,
        ),
      }
    }

    case USER_IMPORT_REMOVED:
      return {
        ...state,
        counters: {
          ...state.counters,
          uploaded: state.counters.uploaded - 1,
        },
        imports: state.imports.filter(imp => imp.uuid !== action.uuid),
      }

    case constants.BOOK_CHANGED:
      if (action.reason === 'removed' && state.myself) {
        return {
          ...state,
          books: cleanRemovedEntityId(action.book.uuid, state.books),
        }
      }

      return state

    case [serialChanged]:
      if (action.reason === 'removed' && state.myself) {
        return {
          ...state,
          books: cleanRemovedEntityId(action.book.uuid, state.books),
        }
      }

      return state

    case constants.AUDIOBOOK_CHANGED:
      if (action.reason === 'removed' && state.myself) {
        return {
          ...state,
          audiobooks: cleanRemovedEntityId(
            action.audiobook.uuid,
            state.audiobooks,
          ),
        }
      } else {
        return state
      }

    case constants.COMICBOOK_CARD_REMOVED:
      if (state.myself) {
        return {
          ...state,
          comicbooks: cleanRemovedEntityId(
            action.comicbook.uuid,
            state.comicbooks,
          ),
        }
      } else {
        return state
      }

    case CHANGE_LAYOUT:
      return {
        ...state,
        ui: {
          ...state.ui,
          layout: action.layout,
        },
      }

    default:
      return state
  }
}

import { SALINAS } from 'client/bookmate/pages/salinas-page'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import { Navigation, ShowcaseSection } from '../types/showcase'
import { Dispatch } from 'shared/types/redux'
import { push } from 'react-router-redux'
import urlHelper from 'shared/tools/url-helper'
import {
  loadUserAccessLevels,
  loadUserSubscriptions,
} from 'client/bookmate/reducers/subscription-reducer'
import { showAlert } from './alert-reducer'
import {
  analyticsEvent,
  AUTHENTICATION_SUCCESSFUL,
  AUTHENTICATION_FAILED,
} from './analytics-reducer'
import { loadContext, loadInfo } from './current-user-reducer'
import { isEmpty } from 'lodash'

const SALINAS_SIGN_UP = 'SALINAS_SIGN_UP'
const SALINAS_SIGN_UP_SUCCESS = 'POPUSALINAS_SIGN_UP_SUCCESSP_SHOW'
const SALINAS_SIGN_UP_ERROR = 'SALINAS_SIGN_UP_ERROR'

const SALINAS_LOAD_SHOWCASE_CONTENT = 'SALINAS_LOAD_SHOWCASE_CONTENT'
const SALINAS_LOAD_SHOWCASE_CONTENT_SUCCESS =
  'SALINAS_LOAD_SHOWCASE_CONTENT_SUCCESS'
const SALINAS_LOAD_SHOWCASE_CONTENT_ERROR =
  'SALINAS_LOAD_SHOWCASE_CONTENT_ERROR'

const SALINAS_LOAD_SHOWCASE_NAVIGATION = 'SALINAS_LOAD_SHOWCASE_NAVIGATION'
const SALINAS_LOAD_SHOWCASE_NAVIGATION_SUCCESS =
  'SALINAS_LOAD_SHOWCASE_NAVIGATION_SUCCESS'
const SALINAS_LOAD_SHOWCASE_NAVIGATION_ERROR =
  'SALINAS_LOAD_SHOWCASE_NAVIGATION_ERROR'

const SALINAS_AUTH = 'SALINAS_AUTH'
const SALINAS_AUTH_ERROR = 'SALINAS_AUTH_ERROR'
const SALINAS_AUTH_SUCCESS = 'SALINAS_AUTH_SUCCESS'

export const isSalinas = (companySubscription: string): boolean =>
  companySubscription.includes('GrupoSalinas')

export type EmployeeData = {
  date_of_birth: string | null
  email: string
  employee_id: string
  password: string
}

type SalinasShowcaseNavigationSuccessAction = {
  type: typeof SALINAS_LOAD_SHOWCASE_NAVIGATION_SUCCESS
  navigation: {
    positions: Navigation[]
  }
}

type SalinasShowcaseContentSuccessAction = {
  type: typeof SALINAS_LOAD_SHOWCASE_CONTENT_SUCCESS
  showcase: {
    sections: ShowcaseSection[]
  }
}

type SalinasSignUpSuccess = {
  type: typeof SALINAS_SIGN_UP_SUCCESS
}

type SalinasAuth = {
  type: typeof SALINAS_AUTH
}

type SalinasAuthSuccess = {
  type: typeof SALINAS_AUTH_SUCCESS
}

type SalinasAuthError = {
  type: typeof SALINAS_AUTH_ERROR
}

type SalinasSignUpError = {
  type: typeof SALINAS_SIGN_UP_ERROR
}

type SalinasSignUp = {
  type: typeof SALINAS_SIGN_UP
}

type Action =
  | SalinasShowcaseContentSuccessAction
  | SalinasShowcaseNavigationSuccessAction
  | SalinasSignUpSuccess
  | SalinasSignUp
  | SalinasSignUpError
  | SalinasAuthError
  | SalinasAuthSuccess
  | SalinasAuth

export function loadContent(): ApiAction {
  const endpoint = `/p/api/v5/showcases/es/${SALINAS}/mixed/content?limit=100`

  return {
    [CALL_API]: {
      endpoint,
      types: [
        SALINAS_LOAD_SHOWCASE_CONTENT,
        SALINAS_LOAD_SHOWCASE_CONTENT_SUCCESS,
        SALINAS_LOAD_SHOWCASE_CONTENT_ERROR,
      ],
    },
  }
}

export function loadNavigation(): ApiAction {
  const endpoint = `/p/api/v5/showcases/es/${SALINAS}/mixed/navigation`

  return {
    [CALL_API]: {
      endpoint,
      types: [
        SALINAS_LOAD_SHOWCASE_NAVIGATION,
        SALINAS_LOAD_SHOWCASE_NAVIGATION_SUCCESS,
        SALINAS_LOAD_SHOWCASE_NAVIGATION_ERROR,
      ],
    },
  }
}

export function signUp({
  date_of_birth,
  email,
  employee_id,
  password,
}: EmployeeData): ApiAction {
  const endpoint = '/p/api/v5/sign_up/salinas'

  return {
    [CALL_API]: {
      endpoint,
      options: {
        method: 'post',
        data: {
          confirmations: { confirmation_terms_of_service: true },
          user: {
            date_of_birth: date_of_birth as string,
            email,
            employee_id,
            password,
          },
        },
      },
      types: [SALINAS_SIGN_UP, SALINAS_SIGN_UP_SUCCESS, SALINAS_SIGN_UP_ERROR],
      onSuccess: (_dispatch: Dispatch) => {
        _dispatch(loadUserSubscriptions())
        _dispatch(loadUserAccessLevels())
        _dispatch(push(urlHelper.salinasValidate('basic')))
      },
      onError: (_dispatch: Dispatch, _getState, response) => {
        if (isEmpty(response) || isEmpty(response?.error)) return
        const { type, message } = response?.error
        if (
          type === 'salinas.invalid_employee' ||
          type === 'email_already_taken'
        ) {
          _dispatch(
            showAlert('error', {
              message,
            }),
          )
        } else _dispatch(push(urlHelper.salinasValidate('error')))
      },
    },
  }
}

export function authenticate(
  payload: {
    'g-recaptcha-response': string
    user: { credential: string; password: string }
  },
  fingerprint: string,
) {
  return (dispatch: Dispatch): void => {
    const context = 'login'
    const source = 'email'

    dispatch({
      [CALL_API]: {
        endpoint: '/p/api/v5/sign_in/password',
        options: {
          method: 'post',
          data: payload,
          fingerprint,
        },
        onSuccess: _dispatch => {
          _dispatch(
            analyticsEvent(AUTHENTICATION_SUCCESSFUL, {
              source,
              context,
              custom: true,
            }),
          )
          _dispatch(loadContext())
          _dispatch(loadInfo())
        },
        onError: (_dispatch, _getState, response) => {
          const { error } = response
          const analyticsPayload: {
            [key: string]: string
          } = {
            source,
            context,
          }

          if (error && error.message) {
            analyticsPayload.reason = error.message
          }
          _dispatch(showAlert('error', { message: error?.message }))
          _dispatch(analyticsEvent(AUTHENTICATION_FAILED, analyticsPayload))
        },
        types: [SALINAS_AUTH, SALINAS_AUTH_SUCCESS, SALINAS_AUTH_ERROR],
      },
    })
  }
}

export type SalinasState = {
  sections: ShowcaseSection[]
  navigation: Navigation[]
  loading: boolean
}

const initialState = {
  sections: [],
  navigation: [],
  loading: false,
}

export default function salinasReducer(
  state: SalinasState = initialState,
  action: Action,
): SalinasState {
  switch (action.type) {
    case SALINAS_AUTH:
    case SALINAS_SIGN_UP:
      return {
        ...state,
        loading: true,
      }
    case SALINAS_SIGN_UP_SUCCESS:
    case SALINAS_SIGN_UP_ERROR:
    case SALINAS_AUTH_SUCCESS:
    case SALINAS_AUTH_ERROR:
      return {
        ...state,
        loading: false,
      }
    case SALINAS_LOAD_SHOWCASE_CONTENT_SUCCESS:
      return {
        ...state,
        sections: action.showcase.sections,
      }
    case SALINAS_LOAD_SHOWCASE_NAVIGATION_SUCCESS:
      return {
        ...state,
        navigation: action.navigation?.positions,
      }
    default:
      return state
  }
}

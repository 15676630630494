import React, { Component } from 'react'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { Dropdown } from 'client/shared/blocks/dropdown'

import './context-menu.styl'

export type MenuDataItem = {
  title: string
  action: () => void
  actionName?: string
  disabled?: boolean
  icon?: string
}

export type ContextMenuDataProps = {
  position?: string
  items: MenuDataItem[]
}

type Props = (ContextMenuDataProps & {
  children: React.ReactNode
}) &
  DecoratorProps

type State = {
  dropdownShown: boolean
}

class ContextMenu extends Component<Props, State> {
  state = {
    dropdownShown: false,
  }

  toggleDropdown = () => {
    const dropdownShown = !this.state.dropdownShown
    this.setState({ dropdownShown })
    if (dropdownShown && document.querySelector('.floating-action')) {
      document.querySelector('.floating-action').style.visibility = 'hidden'
    }
  }

  showDropdown = () => {
    this.setState({
      dropdownShown: true,
    })
  }

  hideDropdown = () => {
    this.setState({
      dropdownShown: false,
    })
  }

  render() {
    const { children, position, items, isMobileSize } = this.props
    const onMouseEnter = isMobileSize() ? null : this.showDropdown
    const onMouseLeave = isMobileSize() ? null : this.hideDropdown

    return (
      <div
        onMouseEnter={onMouseEnter}
        onClick={this.toggleDropdown}
        onMouseLeave={onMouseLeave}
        className="context-menu"
      >
        {children}
        <Dropdown
          position={position}
          items={items}
          onClose={this.hideDropdown}
          hidden={!this.state.dropdownShown}
        />
      </div>
    )
  }
}

export default uiBox(ContextMenu)

import React, { Component } from 'react'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { LayoutColumn } from 'client/shared/blocks/layout-column'
import Spacer from 'client/shared/blocks/spacer'

import FooterStoreButtons from 'client/shared/blocks/footer-store-buttons'
import FooterLinks from 'client/shared/blocks/footer-links'
import SocialLinks from 'client/shared/blocks/social-links'
import Copyright from 'client/shared/blocks/copyright'
import { SideMenu } from 'client/bookmate/blocks/side-menu'
import FooterNotice from 'client/bookmate/blocks/footer-notice'
import { withRouter } from 'react-router'
import { compose } from 'lodash/fp'
import cn from 'classnames'

type Props = {
  campaign?: string
  stage?: string
  withSocialLinks: boolean
  withCopyright: boolean
  withNotice: boolean
  withButtons: boolean
  aboutMenu: boolean
  noBottomSpace?: boolean
  libraryLink?: string
  router: { location: Location }
  extraStyleClass?: string
} & DecoratorProps

class Footer extends Component<Props> {
  static defaultProps = {
    withSocialLinks: true,
    withCopyright: true,
    withButtons: true,
    withNotice: false,
    aboutMenu: false,
  }

  render() {
    const {
      campaign,
      stage,
      locale,
      withSocialLinks,
      withCopyright,
      withButtons,
      withNotice,
      aboutMenu,
      noBottomSpace = false,
      app: { isBookmateApp, domain, storedQuery },
      country,
      libraryLink,
      router: { location },
      extraStyleClass,
    } = this.props

    if (isBookmateApp) return null

    return (
      <footer
        className={cn(
          { [extraStyleClass as string]: Boolean(extraStyleClass) },
          'footer',
        )}
      >
        <LayoutColumn>
          {!aboutMenu ? (
            <FooterLinks
              pathname={location.pathname}
              kind="inline"
              domain={domain}
              query={storedQuery}
              libraryLink={libraryLink}
            />
          ) : (
            <SideMenu locale={locale} query={storedQuery} />
          )}
          {withButtons && (
            <FooterStoreButtons
              kind="inline"
              campaign={campaign}
              stage={stage}
              path={libraryLink}
            />
          )}
          {withSocialLinks && <SocialLinks locale={locale} country={country} />}
          {withNotice && (
            <FooterNotice kind={locale === 'ru' ? 'seo' : 'legal'} />
          )}
          {withCopyright && <Copyright />}
          {!noBottomSpace && <Spacer size="bottom-page" />}
        </LayoutColumn>
      </footer>
    )
  }
}

const wrappers = compose(uiBox, withRouter)

export default wrappers(Footer)

import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { show as showPopup } from 'client/shared/reducers/popup-reducer'

import Button from 'client/shared/blocks/button'

type Props = {
  type: 'link' | 'button'
  messageId: string
  message?: string
  buttonProps?: {
    width?: string
    className?: string
  }
  withIcon?: boolean
} & DecoratorProps

class LoginButton extends Component<Props> {
  static defaultProps = {
    type: 'link',
    messageId: 'header.login_link',
  }

  handleClick = () => {
    const { dispatch } = this.props
    dispatch(showPopup())
  }

  render() {
    if (this.props.withIcon) {
      return this.renderIconLogin()
    } else if (this.props.type === 'button') {
      return this.renderButton()
    } else {
      return this.renderLink()
    }
  }

  renderLink() {
    return (
      <div className="login-button" onClick={this.handleClick}>
        {this.renderMessage()}
      </div>
    )
  }

  renderIconLogin() {
    return (
      <button className="login-icon-button" onClick={this.handleClick}>
        <svg
          className="svg auth-icon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4107 16.4212C21.1063 15.1006 21.5 13.5963 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 13.5963 2.89371 15.1006 3.58932 16.4212C5.94246 14.4347 8.84612 13.25 12 13.25C15.1539 13.25 18.0575 14.4347 20.4107 16.4212ZM19.5996 17.7015C17.4596 15.8366 14.8299 14.75 12 14.75C9.17012 14.75 6.54041 15.8366 4.40041 17.7015C6.13367 20.0081 8.89261 21.5 12 21.5C15.1074 21.5 17.8663 20.0081 19.5996 17.7015ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM12 11.1111C13.5341 11.1111 14.7778 9.86746 14.7778 8.33333C14.7778 6.79921 13.5341 5.55556 12 5.55556C10.4659 5.55556 9.22223 6.79921 9.22223 8.33333C9.22223 9.86746 10.4659 11.1111 12 11.1111ZM12 12.6111C14.3626 12.6111 16.2778 10.6959 16.2778 8.33333C16.2778 5.97078 14.3626 4.05556 12 4.05556C9.63745 4.05556 7.72223 5.97078 7.72223 8.33333C7.72223 10.6959 9.63745 12.6111 12 12.6111Z"
          />
        </svg>
      </button>
    )
  }

  renderButton() {
    return (
      <Button
        {...this.props.buttonProps}
        type="inline"
        onClick={this.handleClick}
      >
        {this.renderMessage()}
      </Button>
    )
  }

  renderMessage() {
    const { message, messageId } = this.props

    if (message) {
      return <span>{message}</span>
    } else {
      return <Trans id={messageId} />
    }
  }
}

export default uiBox(LoginButton)

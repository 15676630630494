import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import './copyright.styl'

type Props = {
  centered: boolean
  secondaryColor?: boolean
}

export default class Copyright extends Component<Props> {
  static defaultProps = {
    centered: false,
  }

  render(): JSX.Element {
    const { centered, secondaryColor } = this.props

    const centeredMod = centered ? `copyright_centered` : ''
    const colorMode = secondaryColor ? `copyright_color-secondary` : ''

    return (
      <div className={`copyright ${centeredMod} ${colorMode}`}>
        <Trans
          id="footer.copyright"
          values={{ year: new Date().getFullYear() }}
        />
      </div>
    )
  }
}

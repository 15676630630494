import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'

import { AuthorsList } from 'client/shared/blocks/authors-list'
import { Heading } from 'client/shared/blocks/heading'
import Spacer from 'client/shared/blocks/spacer'

import { ResourceName } from 'client/shared/types/resource'
import { MinimalAuthorInfo } from 'client/shared/types/author'
import { CreatorProps } from 'client/shared/types/creator'

import './section-nav-header.styl'

type Props = {
  kind: ResourceName | 'shelf' | 'quote' | 'author' | 'series'
  intlId: string
  title?: string
  titleLink?: string
  author?: MinimalAuthorInfo
  authors?: MinimalAuthorInfo[]
  creator?: CreatorProps
  username?: string
  userLink?: string
  button?: React.ReactNode
  isLoggedIn?: boolean
  query?: QueryParams
  showAuthPopup?: () => void
}

class SectionNavHeader extends Component<Props> {
  render(): JSX.Element {
    const { kind } = this.props

    let headingText: JSX.Element | null
    switch (kind) {
      default:
      case 'book':
        headingText = this.renderBookHeader()
        break
      case 'shelf':
        headingText = this.renderShelfHeader()
        break
      case 'audiobook':
        headingText = this.renderAudioBookHeader()
        break
      case 'author':
        headingText = this.renderAuthorHeader()
        break
      case 'quote':
        headingText = this.renderQuoteHeader()
        break
      case 'comicbook':
        headingText = this.renderComicbookHeader()
        break
      case 'series':
        headingText = this.renderSeriesHeader()
        break
    }

    return (
      <div>
        <Heading rank={1} looksLike={2}>
          {headingText}
        </Heading>
        <Spacer size={24} />
      </div>
    )
  }

  renderBookHeader(): JSX.Element {
    const { intlId, title, titleLink, authors } = this.props

    const titleComponent = titleLink ? <Linka path={titleLink} /> : <span />
    const components = [
      titleComponent,
      <AuthorsList authors={authors} disableLink max={5} />,
    ]

    return <Trans id={intlId} values={{ title }} components={components} />
  }

  renderShelfHeader(): JSX.Element | null {
    const { intlId, title, titleLink, creator, query } = this.props

    if (!title || !titleLink || !creator) return null

    const components = [
      <Linka path={titleLink} />,
      <Linka path={urlFor.user(creator.login, query)} loginWall pseudo />,
    ]

    return (
      <Trans
        id={intlId}
        values={{
          title,
          username: creator.name,
        }}
        components={components}
      />
    )
  }

  renderAudioBookHeader(): JSX.Element | null {
    const { intlId, title, titleLink, authors } = this.props

    if (!title || !authors) return null

    const titleComponent = titleLink ? <Linka path={titleLink} /> : <span />
    const components = [
      titleComponent,
      <AuthorsList authors={authors} disableLink />,
    ]

    return (
      <Trans
        id={intlId}
        values={{
          title,
        }}
        components={components}
      />
    )
  }

  renderComicbookHeader(): JSX.Element | null {
    const { intlId, title, titleLink, authors } = this.props

    if (!title || !authors) return null

    const titleComponent = titleLink ? <Linka path={titleLink} /> : <span />
    const components = [
      titleComponent,
      <AuthorsList authors={authors} disableLink />,
    ]

    return (
      <Trans
        id={intlId}
        values={{
          title,
        }}
        components={components}
      />
    )
  }

  renderAuthorHeader(): JSX.Element | null {
    const { intlId, author } = this.props

    if (!author) return null

    return <Trans id={intlId} values={{ author: author.name }} />
  }

  renderQuoteHeader(): JSX.Element | null {
    const { intlId, title, titleLink, authors, username, userLink } = this.props

    if (!title || !titleLink || !authors) return null

    let components = [
      <Linka path={titleLink} />,
      <AuthorsList authors={authors} disableLink />,
    ]
    if (username && userLink) {
      components = [<Linka path={userLink}>{username}</Linka>, ...components]
    }

    return (
      <Trans id={intlId} values={{ title, username }} components={components} />
    )
  }

  renderSeriesHeader(): JSX.Element | null {
    const { intlId, title, titleLink, authors, button } = this.props

    if (!title || !authors) return null

    const titleComponent = titleLink ? <Linka path={titleLink} /> : <span />
    const components = [
      titleComponent,
      <AuthorsList authors={authors} max={3} disableLink />,
    ]

    return (
      <>
        <Trans id={intlId} values={{ title }} components={components} />
        {button}
      </>
    )
  }
}

export default SectionNavHeader

import startsWith from 'lodash/startsWith'

type Country = {
  dial_code: string
  code: string
}
type Countries = Array<Country>

const countries: Countries = require('./phone-input__countries.json')

/**
 * Given a full phone number (joined with a country code and starting with a plus),
 * split it into the country code and the phone number itself
 *
 * NOTE: the provided phoneNumber argument should start with a plus (as our api returns it)
 */
export function splitPhoneNumber(phoneNumber: string) {
  const matchedCountry = countries.filter(country => {
    const countryCodeWithoutSpaces = country.dial_code.replace(/\s/g, '')
    return startsWith(phoneNumber, countryCodeWithoutSpaces)
  })[0]

  if (!matchedCountry) return

  const { dial_code: _dial_code, code } = matchedCountry
  const dial_code = _dial_code.replace(/\s/g, '')
  const phoneNumberProper = phoneNumber.substring(dial_code.length)

  return { phoneNumber: phoneNumberProper, code }
}

/**
 * Given a full phone number (joined with the country dial code and starting with a plus),
 * and the country 2-letter ISO code,
 * remove the dial code and return just the phone number itself
 *
 * NOTE: the provided phoneNumber argument should be in the format (our api returns it)
 * (i.e. start with a plus and have no spaces)
 */
export function separatePhoneNumberFromDialCode(
  phoneNumber: string,
  countryCode: string,
) {
  const matchedCountry = countries.filter(
    country => country.code.toLowerCase() === countryCode.toLowerCase(),
  )[0]

  if (!matchedCountry) return

  let { dial_code } = matchedCountry
  dial_code = dial_code.replace(/\s/g, '')
  const phoneNumberProper = phoneNumber.substring(dial_code.length)
  return phoneNumberProper
}

import React, { Component } from 'react'

import './sticker.styl'

type Props = {
  kind: string
  style?: Record<string, unknown>
}
export class Sticker extends Component<Props> {
  static defaultProps = {
    kind: 'top',
  }

  render(): JSX.Element {
    const { kind, style } = this.props
    const kindMod = kind ? `sticker_${kind}` : ''

    return (
      <div style={style} className={`sticker ${kindMod}`}>
        {this.props.children}
      </div>
    )
  }
}

import noop from 'lodash/noop'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  analyticsEvent,
  IMPRESSION_CREATED,
} from 'client/shared/reducers/analytics-reducer'
import { SOCIAL_AUTH_SUCCESS } from 'client/shared/reducers/auth-reducer'
import {
  IMPRESSION_ADDED,
  IMPRESSION_CHANGED,
  IMPRESSION_REMOVED,
  IMPRESSION_LOADING,
  IMPRESSION_CURRENT_USER_LOADED,
  impressionSchema,
  loadCurrentUserImpression,
} from 'client/bookmate/reducers/impressions-reducer'
import { getEmotionsByIds } from 'client/bookmate/reducers/emotions-reducer'

import { EMOTIONS_LOADED } from 'client/bookmate/reducers/emotions-reducer'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import { GenericDispatchedEvent } from 'shared/types/redux'
import { Provider } from '../blocks/social-network-connection/social-network-connection'

const IMPRESSION_FORM_LOADED = 'IMPRESSION_FORM_LOADED'
const IMPRESSION_FORM_LOAD_FAILURE = 'IMPRESSION_FORM_LOADED_FAILURE'
const IMPRESSION_FORM_ADD_EMOTION = 'IMPRESSION_FORM_ADD_EMOTION'
const IMPRESSION_FORM_REMOVE_EMOTION = 'IMPRESSION_FORM_REMOVE_EMOTION'
const IMPRESSION_FORM_LOCK_SUBMIT = 'IMPRESSION_FORM_LOCK_SUBMIT'
const IMPRESSION_FORM_UNLOCK_SUBMIT = 'IMPRESSION_FORM_UNLOCK_SUBMIT'
const IMPRESSION_FORM_CHANGE_TEXT = 'IMPRESSION_FORM_CHANGE_TEXT'
const IMPRESSION_FORM_REMOVE_SHARE_PROVIDER =
  'IMPRESSION_FORM_REMOVE_SHARE_PROVIDER'
export const IMPRESSION_FORM_CLEAN = 'IMPRESSION_FORM_CLEAN'
const IMPRESSION_FORM_ADD_SOCIAL = 'IMPRESSION_FORM_ADD_SOCIAL'

export function getImpressionForm(state) {
  return {
    ...state.impressionForm,
    emotions: getEmotionsByIds(state, state.impressionForm.emotions),
    selectedEmotions: getEmotionsByIds(
      state,
      state.impressionForm.selectedEmotions,
    ),
  }
}

export function loadImpressionForm({ resourceUuid, resourceType }) {
  return loadCurrentUserImpression({
    resourceUuid,
    resourceType,
    onSuccess: (dispatch, getState, response) => {
      dispatch({
        ...response,
        type: IMPRESSION_FORM_LOADED,
      })
    },
    onError: dispatch => {
      dispatch({ type: IMPRESSION_FORM_LOAD_FAILURE })
    },
  })
}

const initialState = {
  selectedEmotions: [],
  uuid: '',
  emotions: [],
  text: '',
  loading: false,
  submitLocked: true,
  shareProviders: [],
}

export default function impressionForm(state = initialState, action) {
  switch (action.type) {
    case IMPRESSION_FORM_ADD_EMOTION:
      return {
        ...state,
        selectedEmotions: [...state.selectedEmotions, action.emotion.name],
      }
    case IMPRESSION_FORM_REMOVE_EMOTION:
      return {
        ...state,
        selectedEmotions: state.selectedEmotions.filter(
          emotionName => emotionName !== action.name,
        ),
      }
    case IMPRESSION_FORM_LOCK_SUBMIT:
      return {
        ...state,
        submitLocked: true,
      }
    case IMPRESSION_FORM_UNLOCK_SUBMIT:
      return {
        ...state,
        submitLocked: false,
      }
    case IMPRESSION_LOADING:
      return {
        ...state,
        loading: true,
      }
    case IMPRESSION_FORM_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case IMPRESSION_CHANGED:
    case IMPRESSION_ADDED:
    case IMPRESSION_REMOVED:
      return initialState
    case EMOTIONS_LOADED:
      return {
        ...state,
        emotions: action.result,
      }
    case IMPRESSION_FORM_CHANGE_TEXT:
      return {
        ...state,
        text: action.text,
      }
    case IMPRESSION_FORM_REMOVE_SHARE_PROVIDER:
      return {
        ...state,
        shareProviders: state.shareProviders.filter(
          provider => provider !== action.provider,
        ),
      }
    case SOCIAL_AUTH_SUCCESS:
    case IMPRESSION_FORM_ADD_SOCIAL:
      return {
        ...state,
        shareProviders: [...state.shareProviders, action.provider],
      }
    case IMPRESSION_FORM_LOADED: {
      const {
        content: text,
        emotions: selectedEmotions,
        uuid,
      } = action.impression

      return {
        ...state,
        loading: false,
        selectedEmotions,
        text,
        uuid,
      }
    }
    case IMPRESSION_FORM_CLEAN:
      return initialState
    default:
      return state
  }
}

export function toggleSubmit(condition) {
  return {
    type: condition
      ? IMPRESSION_FORM_UNLOCK_SUBMIT
      : IMPRESSION_FORM_LOCK_SUBMIT,
  }
}

export function addEmotion(emotion) {
  return { type: IMPRESSION_FORM_ADD_EMOTION, emotion }
}

export function removeEmotion(name) {
  return { type: IMPRESSION_FORM_REMOVE_EMOTION, name }
}

export function changeText(
  text: string,
): GenericDispatchedEvent & { text: string } {
  return { type: IMPRESSION_FORM_CHANGE_TEXT, text }
}

export function removeShareProvider(
  provider: Provider,
): GenericDispatchedEvent & { provider: Provider } {
  return { type: IMPRESSION_FORM_REMOVE_SHARE_PROVIDER, provider }
}

export function addShareProvider(
  provider: Provider,
): GenericDispatchedEvent & { provider: Provider } {
  return { type: IMPRESSION_FORM_ADD_SOCIAL, provider }
}

export function addImpression(data, cb = noop): ApiAction {
  const { resourceUuid, resourceType, impression } = data
  const urlResourceType = resourceType === 'serial' ? 'book' : resourceType
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/${urlResourceType}s/${resourceUuid}/impressions`,
      options: {
        method: 'post' as FetchMethod,
        data: { impression },
      },
      modifyResponse: response => ({
        uuid: response.impression.uuid,
        resourceUuid,
        resourceType,
      }),
      onSuccess: (dispatch, getState, response) => {
        dispatch(
          analyticsEvent(IMPRESSION_CREATED, {
            impression_id: response.impression.uuid,
          }),
        )
        dispatch({
          data: { resourceUuid, resourceType, uuid: response.impression.uuid },
          type: IMPRESSION_CURRENT_USER_LOADED,
        })
        cb(response.impression.uuid)
      },
      types: [IMPRESSION_LOADING, IMPRESSION_FORM_LOADED],
    },
  }
}

export function editImpression(uuid: string, data, cb = noop): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/impressions/${uuid}`,
      options: {
        method: 'put' as FetchMethod,
        data: { impression: data.impression },
      },
      schema: impressionSchema,
      responseKey: 'impression',
      onSuccess: () => {
        cb(uuid)
      },
      types: [IMPRESSION_LOADING, IMPRESSION_CHANGED],
    },
  }
}

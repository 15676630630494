export const doesCountryAllowApplePay = (country: string): boolean =>
  APPLE_PAY_ALLOWED_COUNTRIES.includes(country)

const APPLE_PAY_ALLOWED_COUNTRIES = [
  'ae',
  'at',
  'au',
  'be',
  'bg',
  'br',
  'ca',
  'ch',
  'ci',
  'cr',
  'cy',
  'cz',
  'de',
  'dk',
  'do',
  'ee',
  'es',
  'fi',
  'fr',
  'gb',
  'gi',
  'gr',
  'gt',
  'hk',
  'hr',
  'hu',
  'id',
  'ie',
  'in',
  'it',
  'jp',
  'li',
  'lt',
  'lu',
  'lv',
  'mt',
  'mx',
  'my',
  'nl',
  'no',
  'nz',
  'pe',
  'ph',
  'pl',
  'pt',
  'ro',
  'se',
  'sg',
  'si',
  'sk',
  'sn',
  'th',
  'tt',
  'us',
  'uy',
]

import { GiftKind } from 'client/bookmate/reducers/gifts-reducer'

export function getVisibleItemsTypes(
  kind: GiftKind,
  areComicsAvailable?: boolean,
): string[] {
  switch (kind) {
    case 'master':
      return ['books', 'audiobooks', 'comicbooks']
    case 'premium':
      return areComicsAvailable ? ['books', 'comicbooks'] : ['books']
    case 'audio':
      return ['audiobooks']
    default:
      return []
  }
}

export function areBooksVisible(visibleItemsTypes: string[]): boolean {
  return visibleItemsTypes.includes('books')
}

export function areAudiobooksVisible(visibleItemsTypes: string[]): boolean {
  return visibleItemsTypes.includes('audiobooks')
}

export function areComicbooksVisible(visibleItemsTypes: string[]): boolean {
  return visibleItemsTypes.includes('comicbooks')
}

export function isGiftsAvailable(availableKinds: GiftKind[]): boolean {
  return availableKinds.length > 0
}

import {
  SOCIAL_AUTH_SUCCESS,
  AUTH_FORM_LOGIN_SUCCESS,
} from 'client/shared/reducers/auth-reducer'
import get from 'lodash/get'

export const POPUP_HIDE = 'POPUP_HIDE'
export const POPUP_SHOW = 'POPUP_SHOW'

const DEFAULT_POPUP = 'auth'

export type PopupState = {
  authHidden: boolean
}

type Action = hidePopupAction | showPopupAction

type PopupPayload = { kind: string }

type showPopupAction = {
  type: 'POPUP_SHOW'
  callback?: () => void | null
  payload: PopupPayload
}

type hidePopupAction = {
  type: 'POPUP_HIDE'
}

export function show(params?): Action {
  return {
    type: POPUP_SHOW,
    callback: get(params, 'callback') || null,
    payload: { kind: get(params, 'kind') || DEFAULT_POPUP },
  }
}

export function hide(): Action {
  return {
    type: POPUP_HIDE,
  }
}

const initialState = {
  authHidden: true,
}

export default function popup(
  state: PopupState = initialState,
  action: Action,
): PopupState {
  switch (action.type) {
    case SOCIAL_AUTH_SUCCESS:
    case AUTH_FORM_LOGIN_SUCCESS:
    case POPUP_HIDE:
      return initialState

    case POPUP_SHOW:
      return {
        ...state,
        [`${action.payload.kind}Hidden`]: false,
      }

    default:
      return state
  }
}

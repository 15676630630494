import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'react-router-redux'
import compose from 'lodash/fp/compose'

import errorBoundary from 'client/shared/decorators/error-boundary'
import uploadHelper, {
  MAX_FILES_AT_ONCE,
} from 'client/bookmate/helpers/upload-helper'
import urlFor from 'shared/tools/url-helper'

import {
  addFile,
  showDropZone,
  hideDropZone,
  getValidFiles,
} from 'client/bookmate/reducers/upload-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'

import { UploadDropZone } from 'client/bookmate/blocks/upload-drop-zone'
import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from 'client/shared/types/current-user'
import { Router } from 'client/shared/types/react-router'

const FULLSCREEN_KIND = 'fullscreen'

type Props = {
  kind?: string
  disabledAt?: string[]
  canSelectFile?: boolean
  dispatch: Dispatch
  currentUser: CurrentUserState
  hidden: boolean
  router: Router
}

class _UploadDropZoneBox extends Component<Props> {
  componentDidMount(): void {
    window.addEventListener('keydown', this.hideDropZoneHandler)
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.hideDropZoneHandler)
  }

  hideDropZoneHandler = event => {
    const { kind, dispatch } = this.props

    if (event.key !== 'Escape' || kind !== FULLSCREEN_KIND) return

    dispatch(hideDropZone())
  }

  render(): JSX.Element {
    const { kind, hidden, canSelectFile } = this.props

    return (
      <UploadDropZone
        kind={kind}
        hidden={hidden}
        canSelectFile={canSelectFile}
        fileSelectDisabled={this.addingFilesDisabled()}
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onFileSelect={this.onFileSelect}
      />
    )
  }

  addingFilesDisabled() {
    const {
      upload: { files },
      currentUser,
    } = this.props

    if (currentUser.team) return false

    return files.filter(file => !file.error).length >= MAX_FILES_AT_ONCE
  }

  disabledAtCurrentPage() {
    const {
      disabledAt = [],
      router: {
        location: { pathname },
      },
    } = this.props

    return disabledAt.includes(pathname)
  }

  onDrop = e => {
    if (this.disabledAtCurrentPage()) return

    const { kind, dispatch } = this.props

    e.preventDefault()
    const {
      dataTransfer: { files },
    } = e.nativeEvent || e
    this.validateAndAddFiles(files)

    if (kind === FULLSCREEN_KIND) {
      dispatch(
        push(urlFor.upload(undefined, this.props.router?.location?.query)),
      )
    }
  }

  onDragOver = () => {
    if (this.disabledAtCurrentPage()) return

    const { kind, dispatch } = this.props

    if (kind === FULLSCREEN_KIND) {
      dispatch(showDropZone())
    }
  }

  onDragLeave = () => {
    const { kind, dispatch } = this.props

    if (kind === FULLSCREEN_KIND) {
      dispatch(hideDropZone())
    }
  }

  onFileSelect = e => {
    const {
      target: { files },
    } = e.nativeEvent || e
    this.validateAndAddFiles(files)
  }

  validateAndAddFiles(files) {
    const { dispatch, currentUser, kind } = this.props

    try {
      ;[...files].forEach(file => {
        dispatch((_dispatch, getState) =>
          _dispatch(
            addFile(
              ...uploadHelper.validateFile(
                file,
                getValidFiles(getState()),
                currentUser.team,
              ),
            ),
          ),
        )
      })
    } catch (error) {
      if (kind === FULLSCREEN_KIND) {
        dispatch(hideDropZone())
        dispatch(showAlert('error', { message: 'errors.unknown' }))
      }
    }
  }
}

const connectWrapper = connect((state: State) => ({
  upload: state.upload,
  currentUser: state.currentUser,
}))

const wrappers = compose(connectWrapper, errorBoundary, withRouter)

export const UploadDropZoneBox = wrappers(_UploadDropZoneBox)

import { combineReducers } from '@reduxjs/toolkit'
import { routerReducer } from 'react-router-redux'

import appReducer from '../../shared/reducers/app-reducer'
import analyticsReducer from '../../shared/reducers/analytics-reducer'
import languagesReducer from '../../shared/reducers/languages-reducer'
import currentUserReducer from '../../shared/reducers/current-user-reducer'
import fakePageReducer from '../../shared/reducers/fake-page-reducer'
import authReducer from '../../shared/reducers/auth-reducer'
import salinasReducer from '../../shared/reducers/salinas-reducer'
import popupReducer from '../../shared/reducers/popup-reducer'
import alertReducer from '../../shared/reducers/alert-reducer'
import userAchievementsReducer from './user-achievements-reducer'
import achievementsReducer from './achievements-reducer'
import templatesReducer from '../../shared/reducers/templates-reducer'

import searchReducer from './search-reducer'
import shelfReducer from './shelf-reducer'
import postFormReducer from './post-form-reducer'
import shelfFormReducer from './shelf-form-reducer'

import bookReducer from './book-reducer'
import serialReducer from './serial-reducer'
import comicbookReducer from './comicbook-reducer'
import audioBookReducer from './audiobook-reducer'
import seriesReducer from './series-reducer'
import impressionFormReducer from './impression-form-reducer'

import booksReducer from './books-reducer'
import comicbooksReducer from './comicbooks-reducer'
import authorsReducer from './authors-reducer'
import audioBooksReducer from './audiobooks-reducer'
import seriesListReducer from './series-list-reducer'
import impressionsReducer from './impressions-reducer'
import quotesReducer from './quotes-reducer'
import usersReducer from './users-reducer'
import userReducer from './user-reducer'
import authorReducer from './author-reducer'
import shelvesReducer from './shelves-reducer'
import libraryCardsReducer from './library-cards-reducer'
import audioCardsReducer from './audio-cards-reducer'
import emotionsReducer from './emotions-reducer'
import libraryBannersReducer from './library-banners-reducer'
import postsReducer from './posts-reducer'
import topicReducer from './topic-reducer'
import commentsReducer from './comments-reducer'
import asyncPurchasesReducer from '../../shared/reducers/async-purchases-reducer'
import stripeReducer from '../../shared/reducers/stripe-reducer'
import subscriptionReducer from './subscription-reducer'
import paypalReducer from '../../shared/reducers/paypal-reducer'
import metaReducer from '../../shared/reducers/meta-reducer'
import uploadReducer from './upload-reducer'
import notificationsReducer from '../../shared/reducers/notifications-reducer'
import feedsReducer from './feeds-reducer'
import feedEntriesReducer from './feed-entries-reducer'
import friendsSuggestionsReducer from './friends-suggestions-reducer'
import problemFormReducer from './problem-form-reducer'
import specialsReducer from './specials-reducer'
import giftsReducer from './gifts-reducer'

import codeReducer from './code-reducer'
import personalCodeSharingReducer from './personal-code-sharing-reducer'

import unsubscribeReducer from './unsubscribe-reducer'

import sitemapReducer from './sitemap-reducer'
import showcaseReducer from './showcase-reducer'
import showcaseSectionsReducer from './showcase-sections-reducer'

import mtnReducer from './mtn-reducer'

export default combineReducers({
  app: appReducer,
  languages: languagesReducer,
  alert: alertReducer,
  currentUser: currentUserReducer,
  user: userReducer,
  author: authorReducer,
  fakePage: fakePageReducer,
  popup: popupReducer,
  auth: authReducer,
  routing: routerReducer,
  analytics: analyticsReducer,
  templates: templatesReducer,

  book: bookReducer,
  serial: serialReducer,
  comicbook: comicbookReducer,
  audiobook: audioBookReducer,
  series: seriesReducer,
  impressionForm: impressionFormReducer,
  search: searchReducer,
  shelf: shelfReducer,
  postForm: postFormReducer,
  shelfForm: shelfFormReducer,
  problemForm: problemFormReducer,
  topic: topicReducer,
  userAchievements: userAchievementsReducer,
  stripe: stripeReducer,
  subscription: subscriptionReducer,
  paypal: paypalReducer,
  asyncPurchases: asyncPurchasesReducer,
  upload: uploadReducer,
  notifications: notificationsReducer,
  feeds: feedsReducer,
  friendsSuggestions: friendsSuggestionsReducer,
  specials: specialsReducer,
  gifts: giftsReducer,
  code: codeReducer,
  personalCodeSharing: personalCodeSharingReducer,
  sitemap: sitemapReducer,
  meta: metaReducer,
  unsubscribeProcess: unsubscribeReducer,
  showcase: showcaseReducer,
  salinas: salinasReducer,
  mtn: mtnReducer,

  entities: combineReducers({
    achievements: achievementsReducer,
    authors: authorsReducer,
    books: booksReducer,
    comicbooks: comicbooksReducer,
    audiobooks: audioBooksReducer,
    impressions: impressionsReducer,
    quotes: quotesReducer,
    users: usersReducer,
    shelves: shelvesReducer,
    libraryCards: libraryCardsReducer,
    audioCards: audioCardsReducer,
    emotions: emotionsReducer,
    posts: postsReducer,
    banners: libraryBannersReducer,
    feedEntries: feedEntriesReducer,
    comments: commentsReducer,
    series: seriesListReducer,
    showcaseSections: showcaseSectionsReducer,
  }),
})

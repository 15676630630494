import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import noop from 'lodash/noop'
import classNames from 'classnames'

import uploadHelper, {
  VISIBLE_SUPPORTED_FORMATS,
} from 'client/bookmate/helpers/upload-helper'

import Button from 'client/shared/blocks/button'
import UploadFileFormat from 'client/bookmate/blocks/upload-file-format'

import './upload-drop-zone.styl'

const FULLSCREEN_KIND = 'fullscreen'

type Props = {
  kind?: string
  hidden?: boolean
  onDrop: (e) => void
  onDragOver: () => void
  onDragLeave: () => void
  onFileSelect: (e) => void
  canSelectFile?: boolean
  fileSelectDisabled?: boolean
}

export class UploadDropZone extends Component<Props> {
  state = {
    hover: false,
  }

  dragTimeout = null

  onDragOver = (e: Event): void => {
    if (this.dragTimeout) {
      clearTimeout(this.dragTimeout)
      this.dragTimeout = null
    }

    const { dataTransfer } = e.nativeEvent || e
    const { onDragOver = noop } = this.props

    if ([...dataTransfer.types].includes('Files')) {
      e.preventDefault()
      // Fix bug in IE 11
      try {
        dataTransfer.dropEffect = 'copy'
      } catch (err) {
        // ignore the error
      }
      if (!this.state.hover) {
        this.setState({ hover: true })
        onDragOver(e)
      }
    }
  }

  onDragLeave = e => {
    if (typeof e.persist === 'function') {
      e.persist()
    }

    this.dragTimeout = setTimeout(() => {
      const { onDragLeave = noop } = this.props

      e.preventDefault()
      this.setState({ hover: false })
      onDragLeave(e)
    })
  }

  onDrop = e => {
    this.onDragLeave(e)
    this.props.onDrop(e)
  }

  onClick = e => {
    const { file } = this.refs

    if (this.canSelectFile() && e.target !== file) {
      file.click()
    }
  }

  canSelectFile(): boolean {
    const { canSelectFile = false, fileSelectDisabled = false } = this.props

    return canSelectFile && !fileSelectDisabled
  }

  componentDidMount(): void {
    this.addListenersToDocument()
  }

  componentWillUnmount(): void {
    this.removeListenersFromDocument()
  }

  addListenersToDocument(): void {
    document.addEventListener('dragover', this.onDragOver)
    if (this.props.kind === FULLSCREEN_KIND) {
      document.addEventListener('dragleave', this.onDragLeave)
      document.addEventListener('drop', this.onDrop)
    }
  }

  removeListenersFromDocument(): void {
    document.removeEventListener('dragover', this.onDragOver)
    if (this.props.kind === FULLSCREEN_KIND) {
      document.removeEventListener('dragleave', this.onDragLeave)
      document.removeEventListener('drop', this.onDrop)
    }
  }

  getListeners(): Record<string, any> {
    if (this.props.kind === FULLSCREEN_KIND) {
      return {}
    } else {
      return {
        onClick: this.onClick,
        onDrop: this.onDrop,
        onDragOver: this.onDragOver,
        onDragLeave: this.onDragLeave,
      }
    }
  }

  render(): JSX.Element {
    const {
      kind,
      hidden,
      fileSelectDisabled = false,
      canSelectFile = false,
    } = this.props

    const classes = classNames({
      'upload-drop-zone': true,
      [`upload-drop-zone_${kind}`]: kind,
      'upload-drop-zone_hidden': hidden,
      'upload-drop-zone_hover': this.state.hover,
      'upload-drop-zone_disabled': canSelectFile && fileSelectDisabled,
    })

    return (
      <div className={classes} {...this.getListeners()}>
        <i className="upload-drop-zone__icon" />
        <div className="upload-drop-zone__supported-formats">
          {VISIBLE_SUPPORTED_FORMATS.map((format, i) => (
            <UploadFileFormat key={i}>{format}</UploadFileFormat>
          ))}
        </div>
        <div className="upload-drop-zone__label">
          <Trans id="upload.drop_zone_label" />
        </div>

        {canSelectFile && this.renderFileButton()}
      </div>
    )
  }

  renderFileButton(): JSX.Element {
    const { onFileSelect, fileSelectDisabled = false } = this.props

    return (
      <div>
        <div className="upload-drop-zone__or">
          <span className="upload-drop-zone__or">
            <Trans id="upload.or" />
          </span>
        </div>
        <div className="upload-drop-zone__select-file">
          <Button disabled={fileSelectDisabled}>
            <Trans id="upload.select_files" />
          </Button>
          <input
            disabled={fileSelectDisabled}
            type="file"
            ref="file"
            multiple
            onChange={onFileSelect}
            accept={uploadHelper.supportedFormatsAsString()}
            className="upload-drop-zone__file-input"
          />
        </div>
      </div>
    )
  }
}

import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import { QUOTE_REMOVED } from 'client/bookmate/reducers/quotes-reducer'
import {
  IMPRESSION_ADDED,
  IMPRESSION_REMOVED,
} from 'client/bookmate/reducers/impressions-reducer'
import { constants } from 'client/bookmate/constants'

import {
  serialChanged,
  serialRemoveFromWishlistSuccess,
} from 'client/bookmate/reducers/serial-reducer'

import { checkEntities } from 'client/bookmate/helpers/entities-helper'

function updateReadersCount(oldReadersCount, reason) {
  return (
    oldReadersCount +
    {
      added: 1,
      updated: 0,
      removed: -1,
    }[reason]
  )
}

const initialState = {}

export default function books(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'books')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }

  switch (action.type) {
    case QUOTE_REMOVED: {
      const book = state[action.bookUuid]

      return {
        ...state,
        [action.bookUuid]: {
          ...book,
          quotes_count: book.quotes_count - 1,
        },
      }
    }

    case IMPRESSION_REMOVED: {
      if (action.resourceType === 'book') {
        let currentBook = state[action.resourceUuid]
        currentBook = omit(currentBook, 'impression_uuid')

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentBook,
            impressions_count: currentBook.impressions_count - 1,
          },
        }
      } else return state
    }

    case IMPRESSION_ADDED: {
      if (action.resourceType === 'book') {
        const currentBook = state[action.resourceUuid]

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentBook,
            impression_uuid: action.impression.uuid,
            impressions_count: currentBook.impressions_count + 1,
          },
        }
      } else return state
    }

    case constants.BOOK_CHANGED: {
      const { book: changedBook, reason } = action
      let bookData = state[changedBook.uuid]

      if (!bookData) return state

      // delete it when authors will begin use api5
      if (reason === 'removed') {
        bookData = omit(bookData, 'lc')
      }

      return {
        ...state,
        [changedBook.uuid]: {
          ...bookData,
          ...changedBook,
          readers_count: updateReadersCount(bookData.readers_count, reason),
        },
      }
    }

    case constants.BOOK_REMOVED_FROM_WISHLIST_SUCCESS: {
      const changedBook = state[action.uuid]

      if (!changedBook) return state

      return {
        ...state,
        [changedBook.uuid]: {
          ...changedBook,
          in_wishlist: false,
        },
      }
    }

    case serialRemoveFromWishlistSuccess.type: {
      const changedSerial = state[action.payload.uuid]

      if (!changedSerial) return state

      return {
        ...state,
        [changedSerial.uuid]: {
          ...changedSerial,
          in_wishlist: false,
        },
      }
    }

    case serialChanged.type: {
      const { serial: changedSerial, reason } = action.payload
      let serialData = state[changedSerial.uuid]

      if (!serialData) return state

      // delete it when authors will begin use api5
      if (reason === 'removed') {
        serialData = omit(serialData, 'lc')
      }

      return {
        ...state,
        [changedSerial.uuid]: {
          ...serialData,
          ...changedSerial,
          readers_count: updateReadersCount(serialData.readers_count, reason),
        },
      }
    }

    default:
      return state
  }
}

import get from 'lodash/get'

import {
  ProductPlan,
  WebPlan,
  SpecialOfferPlan,
  UpgradeOption,
} from 'client/shared/types/subscription'
import { GiftPlan } from 'client/bookmate/reducers/gifts-reducer'
import { PromoPlan } from 'client/shared/types/code'

export type PlanType =
  | ProductPlan
  | WebPlan
  | GiftPlan
  | PromoPlan
  | SpecialOfferPlan

export function prepareSubscriptionAnalyticsPayload(
  plan: PlanType | null | undefined,
  errorMessage?: string,
) {
  if (!plan) return

  const subType = get(plan, 'productId', '') || get(plan, 'kind', '') // ProductPlan will have the productId field // other product types will have the kind field

  // these are additional fields added to be sent via Facebook pixel;
  // our analytics don't care about them
  const priceFields = {}
  if (plan.price && plan.currency) {
    priceFields.currency = plan.currency
    priceFields.price = plan.price / 100
  }

  const analyticsPayload: {
    [key: string]: unknown
  } = {
    duration: plan.duration,
    payment_method: plan.system,
    sub_type: subType,
    is_trial: plan.trial,
    billing_plan_id: plan.id,
    ...priceFields,
  }

  if (errorMessage) {
    analyticsPayload.reason = errorMessage
  }

  return analyticsPayload
}

export function prepareAnalyticsPayloadForUpgrade(
  upgradeOption: UpgradeOption,
  errorMessage?: string,
) {
  const {
    plan: {
      id: planId,
      productId,
      system,
      duration,
      price,
      currency,
      discount_percent,
      analytics,
    },
  } = upgradeOption

  const analyticsPayload: {
    [key: string]: unknown
  } = {
    plan_id: planId,
    sub_type: productId,
    is_trial: false, // NOTE: currently, we cannot upgrade to or from trial subscription
    duration,
    price,
    currency,
    discount: discount_percent,
    payment_method: system,

    // NOTE: check below fields after backend is ready with https://dreams.atlassian.net/browse/BMC-13192
    previous_sub_type: get(analytics, 'process_data.previous_sub_type', ''),
    previous_duration: get(analytics, 'process_data.previous_duration', ''),
    previous_payment_method: get(
      analytics,
      'process_data.previous_payment_method',
      '',
    ),
    previous_plan_id: get(analytics, 'process_data.previous_plan_id', ''),
  }

  if (errorMessage) {
    analyticsPayload.reason = errorMessage
  }

  return analyticsPayload
}

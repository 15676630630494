import { NavItem } from 'client/shared/blocks/expandable-bubbles-list/expandable-bubbles-list'
import { Heading } from 'client/shared/blocks/heading/heading'
import MetaTitle from 'client/shared/blocks/meta-title/meta-title'
import Meta from 'client/shared/blocks/meta/meta'
import Spacer from 'client/shared/blocks/spacer/spacer'
import React, { Component } from 'react'
import { SalinasHeader } from '../blocks/salinas/salinas-header'
import ShareFB from 'client/bookmate/blocks/salinas/share-fb.png'
import ShareTW from 'client/bookmate/blocks/salinas/share-tw.png'
import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import { Dispatch, State } from 'shared/types/redux'
import errorBoundary from 'client/shared/decorators/error-boundary'
import {
  authenticate,
  loadContent,
  loadNavigation,
} from 'client/shared/reducers/salinas-reducer'
import { Navigation, ShowcaseSection } from 'client/shared/types/showcase'
import urlHelper from 'shared/tools/url-helper'
import ShowcaseSections from '../blocks/showcase-sections/showcase-sections'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import Linka from 'client/shared/blocks/linka/linka'
import 'client/shared/blocks/expandable-bubbles-list/expandable-bubbles-list.styl'
import '../blocks/salinas/salinas.styl'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import {
  SALINAS_FIELDS_BASE,
  SALINAS_HEADING,
} from '../boxes/salinas-sign-up-box'
import Button from 'client/shared/blocks/button/button'
import { handleRecaptсhaV3 } from 'client/shared/helpers/recaptcha-helpers'
import { Recaptcha } from 'client/shared/blocks/recaptcha/recaptcha'
import { Loader } from 'client/shared/blocks/loader/loader'
import urlFor from 'shared/tools/url-helper'

export const SALINAS = 'salinas'

type Props = {
  sections: ShowcaseSection[]
  navigation: Navigation[]
  dispatch: Dispatch
  currentUser: CurrentUserState
  app: AppState
  loading: boolean
  fingerprint: string
} & DecoratorProps

const SalinasBanner = ({ isMobile }: { isMobile: boolean }): JSX.Element => (
  <div className="salinas-banner">
    <Heading>¡Bienvenido a tu Biblioteca Socio!</Heading>
    <p>Tu ventana al conocimiento ilimitado</p>
    {isMobile && (
      <img
        className="banner-logos_footer"
        alt="Bookmate x salinas logo"
        src="https://a.bmstatic.com/iu/34/235/biblioteca_socio_logo 1-a8bf01c941cbd1c6b58416a4845f4535.png"
        aria-label="Bookmate x salinas logo"
      />
    )}
  </div>
)

class SalinasLibrary extends Component<Props> {
  componentDidMount(): void {
    const { dispatch, currentUser } = this.props
    if (!currentUser.auth) return
    dispatch(loadNavigation())
    dispatch(loadContent())
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { dispatch } = this.props
    if (
      prevProps.currentUser.auth !== this.props.currentUser.auth &&
      this.props.currentUser.auth
    ) {
      dispatch(loadNavigation())
      dispatch(loadContent())
    }
  }

  prepareItem = (topic: Navigation): NavItem => {
    return {
      id: `${topic.params.uuid || topic.params.slug}-es`,
      title: topic.title,
      path: urlHelper.topicHierarchy({
        topic: `${topic.params.slug}`,
        resourceType: `books`,
      }).topic,
    }
  }

  render(): JSX.Element | null {
    const {
      navigation,
      sections,
      dispatch,
      currentUser,
      app,
      isMobileSize,
      isDesktopSize,
      isTabletSize,
      loading,
      fingerprint,
    } = this.props

    if ((!navigation.length || !sections.length) && currentUser.auth) {
      return (
        <div className="salinas-page">
          <Loader color="gray" kind="big" centered />
        </div>
      )
    }

    const isMobile = isMobileSize()
    return currentUser.auth ? (
      <div className="salinas-page">
        <MetaTitle
          disableSuffix
          title="Biblioteca socio x Bookmate - Grupo Salinas"
        />
        <Meta content={ShareTW} property="twitter:image" />
        <Meta content={ShareFB} property="og:image" />
        <SalinasHeader />
        {!isMobile && <SalinasBanner isMobile={false} />}
        <Spacer size={isMobile ? 16 : 100} />
        <div className="salinas-library_column">
          <ul className="expandable-bubbles-list">
            {navigation?.map(this.prepareItem).map(item => (
              <li className="expandable-bubbles-list__item" key={item.id}>
                <Linka
                  className="expandable-bubbles-list__item-link"
                  path={item.path}
                >
                  {item.title}
                </Linka>
              </li>
            ))}
          </ul>
          <Spacer size={isMobile ? 24 : 100} />
          {isMobile && (
            <>
              <SalinasBanner isMobile />
              <Spacer size={32} />
            </>
          )}
          <ShowcaseSections
            sections={sections}
            app={app}
            dispatch={dispatch}
            language="es"
            slug={SALINAS}
            areLanguagesMatched
            getShowcasePath={section =>
              urlFor.showcaseSection(
                urlFor.library(),
                section.slug,
                app.storedQuery,
              )
            }
            userId={currentUser.data.id as number}
          />
        </div>
        <Spacer size={140} />
        <img
          className="logos_footer"
          alt="Bookmate x salinas logo"
          src="https://a.bmstatic.com/iu/124/228/Group 1144-612b1f5c12f22b9627427c4cc4f37637.png"
          aria-label="Bookmate x salinas logo"
        />
        <div className="spaceholder" />
      </div>
    ) : (
      <LoginForm
        fingerprint={fingerprint}
        dispatch={dispatch}
        loading={loading}
        isTabletSize={isTabletSize()}
        isDesktopSize={isDesktopSize()}
      />
    )
  }
}

type LoginProps = {
  loading: boolean
  isTabletSize: boolean
  isDesktopSize: boolean
  dispatch: Dispatch
  fingerprint: string
}

type LoginState = {
  submitDisabled: boolean
  email: string
  password: string
}

class LoginForm extends Component<LoginProps, LoginState> {
  state = {
    submitDisabled: true,
    email: '',
    password: '',
  }

  componentDidUpdate(): void {
    if (this.state.submitDisabled && this.areAllFilledOut()) {
      this.setState({ submitDisabled: false })
    } else if (!this.state.submitDisabled && !this.areAllFilledOut()) {
      this.setState({ submitDisabled: true })
    }
  }

  areAllFilledOut(): boolean {
    const { password, email } = this.state
    return Boolean(password && email)
  }

  handleForm(
    event: Event,
    dispatch: Dispatch,
    data: { email: string; password: string },
    fingerprint,
  ): void {
    event.preventDefault()

    handleRecaptсhaV3('sign_in/password', token => {
      dispatch(
        authenticate(
          {
            user: { credential: data.email, password: data.password },
            'g-recaptcha-response': token,
          },
          fingerprint,
        ),
      )
    })
  }

  render() {
    const { isTabletSize, isDesktopSize, fingerprint, dispatch } = this.props

    return (
      <div className="salinas-body">
        <div className="spaceholder" />
        <div className="salinas-column">
          <img
            className="logos_intro"
            alt="Bookmate x salinas logo"
            src="https://a.bmstatic.com/iu/25/142/Group 1152-622001d48b4bdd870e2daad6f48fb4c1.png"
            aria-label="Bookmate x salinas logo"
          />
          <Spacer />
          <Heading>{SALINAS_HEADING}</Heading>
          <Spacer size={24} />
          <p className="salinas-description">
            Por favor, inicia sesión para acceder a esta página.
          </p>
          <Spacer size={40} />
          <form
            autoComplete="off"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onSubmit={(event: Event) =>
              this.handleForm(event, dispatch, this.state, fingerprint)
            }
            className="salinas-register_form"
          >
            {SALINAS_FIELDS_BASE.map(field => (
              <label className="salinas-register_field" key={field.name}>
                <span className="register-hint">{field.label}</span>
                <Spacer size={8} />
                <input
                  autoComplete="off"
                  placeholder={field.placeholder}
                  type={field.type || 'text'}
                  className="register-input"
                  name={field.name}
                  value={this.state[field.name]}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange={(event: Event) =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    this.setState({ [field.name]: event.target.value })
                  }
                />
                {this.state[field.name] ? (
                  <span className="valid">✓</span>
                ) : (
                  <span className="invalid">✖</span>
                )}
              </label>
            ))}
            <Spacer size={32} />
            <Button
              loading={this.props.loading}
              disabled={this.state.submitDisabled}
            >
              Iniciar sesión
            </Button>
          </form>
          {(isTabletSize || isDesktopSize) && <div className="spaceholder" />}
          <Spacer size={100} />
          <img
            className="logos_footer"
            alt="Bookmate x salinas logo"
            src="https://a.bmstatic.com/iu/124/228/Group 1144-612b1f5c12f22b9627427c4cc4f37637.png"
            aria-label="Bookmate x salinas logo"
          />
          <div className="spaceholder" />
        </div>
        <Recaptcha />
      </div>
    )
  }
}

const connectWrapper = connect((state: State) => {
  return {
    app: state.app,
    currentUser: state.currentUser,
    sections: state.salinas.sections,
    navigation: state.salinas.navigation,
    loading: state.salinas.loading,
    fingerprint: state.app.fingerprint,
  }
})

const wrappers = compose(errorBoundary, connectWrapper, uiBox)

export default wrappers(SalinasLibrary)

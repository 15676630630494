import React, { Component } from 'react'

import './meta-counters.styl'

import { getReducedNumber } from 'client/shared/helpers/text-helpers'

import SVGInline from 'react-svg-inline'
import UserIcon from 'client/shared/icons/user.svg'
import BookIcon from 'client/shared/icons/book.svg'
import ShelfIcon from 'client/shared/icons/shelf.svg'
import QuoteIcon from 'client/shared/icons/quote.svg'
import ImpressionIcon from 'client/shared/icons/impression.svg'
import LockIcon from 'client/shared/icons/lock.svg'

const icons = {
  books: BookIcon,
  shelves: ShelfIcon,
  users: UserIcon,
  quotes: QuoteIcon,
  impressions: ImpressionIcon,
  closed: LockIcon,
}

export type CounterItem = {
  kind: string
  description?: string
  count?: number
}

export type InfoItem = {
  kind: string
  description?: string
  info: string
}

type Props = {
  counters?: CounterItem[]
  info?: InfoItem[]
}

class MetaCounters extends Component<Props> {
  renderListItem(item: {
    icon: string
    key: string
    description?: string
    label: string | number
  }): JSX.Element {
    const { icon, key, label, description } = item

    return (
      <li className="meta-counters__item" title={description} key={key}>
        <span className="meta-counters__label">
          <SVGInline className="meta-counters__icon" svg={icon} />
        </span>
        <span className="meta-counters__count">{label}</span>
      </li>
    )
  }

  render(): JSX.Element {
    const { counters = [], info = [] } = this.props

    return (
      <ul className="meta-counters">
        {counters
          .filter(item => typeof item.count === 'number')
          .map((item, index) =>
            this.renderListItem({
              icon: icons[item.kind],
              key: `meta-counter-${index}`,
              description: item.description,
              label: getReducedNumber(item.count || 0),
            }),
          )}
        {info.map((item, index) =>
          this.renderListItem({
            icon: icons[item.kind],
            key: `meta-info-${index}`,
            description: item.description,
            label: item.info,
          }),
        )}
      </ul>
    )
  }
}

export default MetaCounters

import React from 'react'
import SVGInline from 'react-svg-inline'
import Checkmark from 'client/shared/icons/checkmark.svg'

export const SubscriptionDescriptionAdvantage = ({
  advantage,
}: {
  advantage: string
}): JSX.Element => {
  return (
    <li className="subscription-description-advantage">
      <SVGInline svg={Checkmark} />
      <span>{advantage}</span>
    </li>
  )
}

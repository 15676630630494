import React from 'react'
import SVGInline from 'react-svg-inline'

import TwitterIcon from 'client/shared/icons/twitter.svg'
import FacebookIcon from 'client/shared/icons/facebook.svg'
import GoogleIcon from 'client/shared/icons/google.svg'
import YettelIcon from 'client/bookmate/blocks/subscription-logo/assets/yettel_green.svg'
import AppleIcon from 'client/shared/icons/apple.svg'

import { SocialNetwork } from 'client/shared/reducers/auth-reducer'

import './social-userinfo-plate.styl'

type Props = {
  name?: string
  userpic?: string
  provider?: SocialNetwork
}

const providerToIcon = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  google: GoogleIcon,
  telenor_bulgaria: YettelIcon,
  apple: AppleIcon,
}

const SocialUserinfoPlate: React.FC<Props> = props => {
  if (!props || !props.provider) return null
  const { provider, userpic, name } = props
  const socialIcon = providerToIcon[provider]

  if (!socialIcon) {
    throw new Error(`There is no socialIcon for social network ${provider}`)
  }

  return (
    <div className="social-userinfo-plate">
      <div className="social-userinfo-plate__icon">
        <SVGInline svg={socialIcon} />
      </div>
      <div className="social-userinfo-plate__name">{name}</div>
      <div className="social-userinfo-plate__avatar">
        <img src={userpic} />
      </div>
    </div>
  )
}

export default SocialUserinfoPlate

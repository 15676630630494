import React, { PureComponent } from 'react'

import { trimText } from 'client/shared/helpers/text-helpers'
import sanitizeHtml from 'shared/tools/sanitize-html'

import Meta from 'client/shared/blocks/meta'

import { MAX_META_DESCRIPTION_LENGTH } from 'client/shared/constants/metadata'
import { NBSP_REGEX } from 'client/shared/constants/text-constants'

type Props = {
  og?: string | null | undefined
  description: string
  suffix: string
}

const addSuffix = (suffix: string, str: string): string => {
  return str + (suffix ? ` ${suffix}` : '')
}

const processString = (suffix: string, str: string): string => {
  const maxLen = MAX_META_DESCRIPTION_LENGTH - (suffix ? suffix.length : 0)

  return addSuffix(
    suffix,
    trimText(sanitizeHtml(str, { allowedTags: [] }), maxLen),
  ).replace(NBSP_REGEX, ' ')
}

export class MetaDescription extends PureComponent<Props> {
  static defaultProps = {
    suffix: '',
    description: '',
  }

  render(): JSX.Element {
    const { description: rawDesc, suffix, og } = this.props

    const description = rawDesc ? processString(suffix, rawDesc) : ''
    const ogDescription = og ? processString(suffix, og) : description

    return (
      <>
        <Meta name="description" content={description} />
        <Meta content={ogDescription} property="twitter:description" />
        <Meta content={ogDescription} property="og:description" />
      </>
    )
  }
}

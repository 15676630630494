import React, { Component } from 'react'
import { Trans, withI18n, withI18nProps } from '@lingui/react'
import noop from 'lodash/noop'
import find from 'lodash/find'
import omit from 'lodash/omit'
import compose from 'lodash/fp/compose'
import cn from 'classnames'

import refsForwarder from 'client/shared/decorators/refs-forwarder'

import SVGInline from 'react-svg-inline'

import './phone-input.styl'

import countries from './phone-input__countries.json'
import { separatePhoneNumberFromDialCode } from './phone-input-helpers'

import BackIcon from 'client/shared/icons/back.svg'

type Props = {
  country: string // the ISO alpha-2 (two-letter) code of the country
  codeName: string // name used for the country code input field
  name: string // name used for the phone input field
  onInput: (arg0: string) => void
  error?: string
  phoneNumber?: string
  readOnly?: boolean
  placeholder: string
  forwardedRef: () => any
} & withI18nProps

type State = {
  selectShown: boolean
  code: string
  highlightedCountryCode: string
  phoneNumber: string
}

type CountryProps = {
  code: string
  name: string
  dial_code: string
}

class PhoneInput extends Component<Props, State> {
  currentFilter = ''
  shouldResetFilter = true
  lastTimeout: null | undefined = null
  sortedCountries: CountryProps[] = []
  sortedCountriesByCode: CountryProps[] = []
  activeCountryCodeElement: HTMLElement | null | undefined = null
  phoneElement: HTMLInputElement | null | undefined = null
  countryCodesListElement: HTMLElement | null | undefined = null

  static defaultProps = {
    onInput: noop,
    forwardedRef: noop,
    placeholder: '',
  }

  state = {
    selectShown: false,
    code: this.getCountryCode(this.props.country),
    highlightedCountryCode: this.props.country,
    phoneNumber: '',
  }

  constructor(props) {
    super(props)
    props.forwardedRef(this)
    this.initializeCountryCodes(props)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { phoneNumber, country } = nextProps

    // do nothing if insufficient data is provided or user has started entering the phone
    if (!phoneNumber || !country || prevState.phoneNumber) return null

    const cleanPhoneNumber = separatePhoneNumberFromDialCode(
      phoneNumber,
      country,
    )

    if (cleanPhoneNumber) {
      return { phoneNumber: cleanPhoneNumber }
    } else {
      return null
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
    document.addEventListener('keydown', this.handleKeydownOptions)
    document.addEventListener('keypress', this.handleKeypressOptions)
  }

  componentDidUpdate() {
    if (this.activeCountryCodeElement instanceof HTMLElement) {
      this.scrollTo(this.activeCountryCodeElement)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
    document.removeEventListener('keydown', this.handleKeydownOptions)
    document.removeEventListener('keypress', this.handleKeypressOptions)
  }

  initializeCountryCodes(props) {
    const { i18n } = props
    const transformedCountries = countries.map(country => {
      const { code, dial_code } = country
      return {
        code,
        name: i18n._(`countries.${code}`),
        dial_code,
      }
    })

    this.sortedCountries = [
      ...transformedCountries.sort((a, b) => a.name.localeCompare(b.name)),
    ]
    this.sortedCountriesByCode = [
      ...transformedCountries.sort((a, b) => a.dial_code - b.dial_code),
    ]
  }

  getCountryCode(country: string) {
    const regex = new RegExp(`^${country}$`, 'i') // for case-insensitive comparison
    const matchedCountry = find(countries, _country =>
      regex.test(_country.code),
    )
    return matchedCountry ? matchedCountry.dial_code : '+7'
  }

  setPhoneNumber({ phoneNumber, country }: Props) {
    // do nothing if insufficient data is provided or user has started entering the phone
    if (!phoneNumber || !country || this.state.phoneNumber) return

    const cleanPhoneNumber = separatePhoneNumberFromDialCode(
      phoneNumber,
      country,
    )

    if (cleanPhoneNumber) {
      this.setState({ phoneNumber: cleanPhoneNumber })
    }
  }

  // useful as an interface to interact with the component
  getFullPhoneNumber(): string {
    let { phoneNumber } = this.state
    phoneNumber = phoneNumber.trim()
    if (!phoneNumber) {
      return ''
    } else {
      const countryCode = this.state.code.replace(/\s/g, '')
      return `${countryCode}${phoneNumber}`
    }
  }

  scrollTo(elem: HTMLElement) {
    if (elem && elem.parentElement) {
      elem.scrollTop = elem.parentElement.getBoundingClientRect().top
      elem.focus()
    }
  }

  isSameCountry(code1: string, code2: string) {
    return code1 && code2 ? code1.toLowerCase() === code2.toLowerCase() : false
  }

  getNewIndex({ down }: { down: boolean }) {
    const activeCountry = find(this.sortedCountries, country =>
      this.isSameCountry(country.code, this.state.highlightedCountryCode),
    )
    const activeCountryIndex = this.sortedCountries.indexOf(activeCountry)
    let newCountryIndex = 0
    if (down) {
      newCountryIndex =
        activeCountryIndex === this.sortedCountries.length - 1
          ? 0
          : activeCountryIndex + 1
    } else {
      newCountryIndex =
        activeCountryIndex === 0
          ? this.sortedCountries.length - 1
          : activeCountryIndex - 1
    }
    return newCountryIndex
  }

  moveUp() {
    const newCountryCode = this.sortedCountries[
      this.getNewIndex({ down: false })
    ].code

    this.setState({
      highlightedCountryCode: newCountryCode,
    })
  }

  moveDown() {
    const newCountryCode = this.sortedCountries[
      this.getNewIndex({ down: true })
    ].code

    this.setState({
      highlightedCountryCode: newCountryCode,
    })
  }

  changeActiveCountry(value: string) {
    let country = find(
      this.sortedCountries,
      _country =>
        _country.name.substring(0, value.length).toLowerCase() ===
        value.toLowerCase(),
    )
    if (!country) {
      country = find(
        this.sortedCountriesByCode,
        _country =>
          _country.dial_code.replace(' ', '').substring(1, value.length + 1) ===
          value,
      )
    }
    if (country) {
      this.setState({
        highlightedCountryCode: country.code,
      })
    }
  }

  resetFilterValue() {
    this.shouldResetFilter = true
    this.lastTimeout = null
    this.currentFilter = ''
  }

  changeCode(el: EventTarget) {
    if (!(el instanceof HTMLElement)) return

    const codeElem = el.querySelector('.phone-input-option__code')
    const code = codeElem && codeElem.textContent
    const highlightedCountryCode =
      codeElem && codeElem.dataset ? codeElem.dataset.code : ''

    this.setState({
      selectShown: false,
      code: code || '',
      highlightedCountryCode,
    })

    // eslint-disable-next-line no-unused-expressions
    this.phoneElement && this.phoneElement.focus()
  }

  handleCodeClick = () => {
    this.setState({
      selectShown: !this.state.selectShown,
    })
  }

  handleOptionClick = (e: React.SyntheticEvent<HTMLElement>): void => {
    this.changeCode(e.currentTarget)
  }

  handleClickOutside = (event?: MouseEvent): void => {
    if (!this.state.selectShown) return

    let shouldHideCountryCodeList

    if (event) {
      const { target: clickTarget } = event

      if (!(clickTarget instanceof Element)) return

      if (
        this.countryCodesListElement &&
        !this.countryCodesListElement.contains(clickTarget)
      ) {
        // user clicked outside the country codes list; so we should hide the list
        shouldHideCountryCodeList = true
      }
    } else {
      // user pressed escape; we should close the country codes list
      shouldHideCountryCodeList = true
    }

    if (shouldHideCountryCodeList) {
      this.setState({
        selectShown: false,
      })
    }
  }

  handleKeydownOptions = (e: KeyboardEvent): void => {
    if (this.state.selectShown) {
      const keyCodes = {
        UP: 38,
        DOWN: 40,
        ENTER: 13,
        ESC: 27,
        BS: 8,
      }

      switch (e.keyCode) {
        case keyCodes.UP:
          e.preventDefault()
          this.moveUp()
          break
        case keyCodes.DOWN:
          e.preventDefault()
          this.moveDown()
          break
        case keyCodes.ENTER:
          e.preventDefault()
          this.changeCode(e.target)
          break
        case keyCodes.ESC:
          e.preventDefault()
          this.handleClickOutside()
          break
        case keyCodes.BS:
          this.resetFilterValue()
          break
        default:
          break
      }
    }
  }

  handleKeypressOptions = (e: KeyboardEvent): void => {
    if (this.state.selectShown) {
      e.preventDefault()
      e.stopPropagation()

      const timeout = 300
      const character = String.fromCharCode(e.charCode)

      this.currentFilter = this.shouldResetFilter
        ? character
        : this.currentFilter + character
      this.shouldResetFilter = false
      if (this.lastTimeout) {
        clearTimeout(this.lastTimeout)
      }
      this.lastTimeout = setTimeout(() => {
        this.resetFilterValue()
      }, timeout)

      this.changeActiveCountry(this.currentFilter)
    }
  }

  handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { onInput } = this.props

    const phoneNumber = event.currentTarget.value

    onInput(phoneNumber)

    this.setState({
      phoneNumber,
    })
  }

  render() {
    const { codeName, placeholder, readOnly, ...otherProps } = this.props
    const { code, selectShown } = this.state

    return (
      <div className="phone-input">
        <div className="phone-input__wrapper">
          <div
            className={cn('phone-input__code', {
              'phone-input__code_readonly': readOnly,
              'phone-input__code_shown-dropdown': selectShown,
            })}
            onClick={!readOnly ? this.handleCodeClick : undefined}
          >
            {!readOnly ? (
              code
            ) : (
              <div className="phone-input__code__value-wrapper">{code}</div>
            )}
            <input type="hidden" name={codeName} value={code} />
          </div>
          {/* otherProps below contain i18nHash prop which comes from withI18n; it should be omitted from input props */}
          <input
            className="phone-input__input"
            inputMode="tel"
            placeholder={placeholder}
            {...omit(otherProps, [
              'country',
              'intl',
              'error',
              'phoneNumber',
              'onInput',
              'i18nHash',
              'forwardedRef',
            ])}
            ref={element => (this.phoneElement = element)}
            onChange={this.handleInputChange}
            value={this.state.phoneNumber}
          />
        </div>
        {this.state.selectShown ? this.renderCodeSelect() : null}
      </div>
    )
  }

  renderCodeSelect() {
    return (
      <div className="phone-input__select-wrapper">
        <SVGInline
          svg={BackIcon}
          className="phone-input__back"
          onClick={this.handleClickOutside}
        />
        <div className="phone-input__header">
          <Trans id="countries.select_country" />
        </div>
        <ul
          className="phone-input__select"
          ref={element => (this.countryCodesListElement = element)}
        >
          {this.sortedCountries.map(country => {
            const active = this.isSameCountry(
              country.code,
              this.state.highlightedCountryCode,
            )
            const optionClass = active
              ? 'phone-input-option phone-input-option_active'
              : 'phone-input-option'
            let refElement
            if (active) {
              refElement = elem => {
                this.activeCountryCodeElement = elem
              }
            } else {
              // eslint-disable-next-line no-empty-function
              refElement = noop
            }

            return (
              <li
                className={optionClass}
                key={country.code}
                onClick={this.handleOptionClick}
                ref={refElement}
                tabIndex={0}
              >
                <span className="phone-input-option__name" title={country.name}>
                  {country.name}
                </span>
                <span
                  className="phone-input-option__code"
                  data-code={`${country.code}`}
                >
                  {country.dial_code}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const wrappers = compose(refsForwarder, withI18n({ update: true }))

export default wrappers(PhoneInput)

import { CALL_API } from 'shared/middlewares/api-middleware'

const UPLOAD_ADD_FILE = 'UPLOAD_ADD_FILE'
const UPLOAD_DELETE_FILE = 'UPLOAD_DELETE_FILE'
const UPLOAD_SET_LINK = 'UPLOAD_SET_LINK'
const UPLOAD_SEND = 'UPLOAD_SEND'
const UPLOAD_SEND_SUCCESS = 'UPLOAD_SEND_SUCCESS'
const UPLOAD_SEND_ERROR = 'UPLOAD_SEND_ERROR'
const UPLOAD_RESET = 'UPLOAD_RESET'
const UPLOAD_SHOW_DROP_ZONE = 'UPLOAD_SHOW_DROP_ZONE'
const UPLOAD_HIDE_DROP_ZONE = 'UPLOAD_HIDE_DROP_ZONE'

const initialState = {
  files: [],
  link: '',
  loading: false,
  error: null,
  showDropZone: false,
}

let nextFileId = 0

function generateNextId() {
  return ++nextFileId
}

export function getValidFiles(state) {
  return state.upload.files.filter(file => !file.error)
}

export default function upload(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.file],
      }

    case UPLOAD_DELETE_FILE:
      return {
        ...state,
        files: state.files.filter(file => file.id !== action.id),
      }

    case UPLOAD_SET_LINK:
      return {
        ...state,
        link: action.link,
      }

    case UPLOAD_SEND:
      return {
        ...state,
        loading: true,
      }

    case UPLOAD_SEND_SUCCESS:
    case UPLOAD_RESET:
      return initialState

    case UPLOAD_SEND_ERROR:
      return {
        ...state,
        error: action.error,
      }

    case UPLOAD_SHOW_DROP_ZONE:
      return {
        ...state,
        showDropZone: true,
      }

    case UPLOAD_HIDE_DROP_ZONE:
      return {
        ...state,
        showDropZone: false,
      }

    default:
      return state
  }
}

export function addFile(error, file) {
  return {
    type: UPLOAD_ADD_FILE,
    file: {
      id: generateNextId(),
      error,
      data: file,
    },
  }
}

export function setLink(link) {
  return {
    type: UPLOAD_SET_LINK,
    link,
  }
}

export function deleteFile(id) {
  return {
    type: UPLOAD_DELETE_FILE,
    id,
  }
}

export function showDropZone() {
  return {
    type: UPLOAD_SHOW_DROP_ZONE,
  }
}

export function hideDropZone() {
  return {
    type: UPLOAD_HIDE_DROP_ZONE,
  }
}

export function send(data, cb) {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/upload_books',
      options: {
        method: 'post',
        isMultipart: true,
        dontParse: true,
        body: data,
      },
      onSuccess: cb,
      types: [UPLOAD_SEND, UPLOAD_SEND_SUCCESS, UPLOAD_SEND_ERROR],
    },
  }
}

export function reset() {
  return {
    type: UPLOAD_RESET,
  }
}

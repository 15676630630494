import { AudiobookProps } from 'client/shared/types/audiobook'
import { BookProps } from 'client/shared/types/book'
import { Locale } from 'client/shared/types/current-user'
import { ResourceName, ShowcaseResource } from 'client/shared/types/resource'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

const UNSUBSCRIBING = 'UNSUBSCRIBING'
const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS'
const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR'
const CANCELLATION_REASONS_LOAD = 'CURRENT_USER_CANCELLATION_REASONS_LOAD'
const CANCELLATION_REASONS_SUCCESS = 'CURRENT_USER_CANCELLATION_REASONS_SUCCESS'
const CANCELLATION_REASONS_ERROR = 'CURRENT_USER_CANCELLATION_REASONS_ERROR'
const CANCELLATION_REASONS_SEND = 'CANCELLATION_REASONS_SEND'
const CANCELLATION_REASONS_SEND_SUCCESS = 'CANCELLATION_REASONS_SEND_SUCCESS'
const CANCELLATION_REASONS_SEND_ERROR = 'CANCELLATION_REASONS_SEND_ERROR'
const LOAD_RETENTION_OFFER = 'LOAD_RETENTION_OFFER'
const LOAD_RETENTION_OFFER_SUCCESS = 'LOAD_RETENTION_OFFER_SUCCESS'
const LOAD_RETENTION_OFFER_ERROR = 'LOAD_RETENTION_OFFER_ERROR'
const LOAD_BESTSELLERS_RESOURCES = 'LOAD_BESTSELLERS_RESOURCES'
const LOAD_BESTSELLERS_RESOURCES_SUCCESS = 'LOAD_BESTSELLERS_RESOURCES_SUCCESS'
const LOAD_BESTSELLERS_RESOURCES_ERROR = 'LOAD_BESTSELLERS_RESOURCES_ERROR'

export type CancellationReason = {
  variant: string
  description: string
}

export type UnsubscribeState = {
  pending: boolean
  success: boolean
  error: boolean
  cancellationReasons: {
    variants: CancellationReason[]
    error: string
    loading: boolean
  }
  retentionOfferData: RetentionOfferData | null
}

type UnsubscribingAction = {
  type: typeof UNSUBSCRIBING
}

type UnsubscribeSuccessAction = {
  type: typeof UNSUBSCRIBE_SUCCESS
}

type UnsubscribeErrorAction = {
  type: typeof UNSUBSCRIBE_ERROR
}

type RetentionOfferLoad = {
  type: typeof LOAD_RETENTION_OFFER
}

type BestsellersResourcesLoad = {
  type: typeof LOAD_BESTSELLERS_RESOURCES
}
type BestsellersResourcesLoadSuccess = {
  type: typeof LOAD_BESTSELLERS_RESOURCES_SUCCESS
  books: BookProps[]
  append?: boolean
  audiobooks?: AudiobookProps[]
}

export type RetentionOfferResourceItem = {
  type: 'finished_book'
  attributes: {
    uuid: string
    type: 'Book'
    progress: number
    book_state: 'finished'
    cover: {
      url: string
      width: number
      height: number
    }
    private: boolean
  }
}

export type RetentionOfferData = {
  total_finished_books_purchased_not_uploads?: number
  active_subscription_per_month_price?: number
  active_subscription_per_month_price_discounted?: number
  active_subscription_per_month_currency?: string
  total_books_in_store_price?: number
  retention_flow_offer_used?: boolean
  last_10_finished_books?: { data: RetentionOfferResourceItem[] }
  last_10_reading_books?: { data: RetentionOfferResourceItem[] }
  bestsellers?: BookProps[] | AudiobookProps[]
  appended?: BookProps[]
  loading: boolean
}

type RetentionOfferLoadSuccess = {
  type: typeof LOAD_RETENTION_OFFER_SUCCESS
  data: {
    attributes: RetentionOfferData
  }
  last_10_finished_books: { data: RetentionOfferResourceItem[] }
  last_10_reading_books: { data: RetentionOfferResourceItem[] }
}
type RetentionOfferLoadError = {
  type: typeof LOAD_RETENTION_OFFER_ERROR
}
type CancellationReasonsLoad = {
  type: typeof CANCELLATION_REASONS_LOAD
}

type CancellationReasonsSuccessAction = {
  type: typeof CANCELLATION_REASONS_SUCCESS
  variants: CancellationReason[]
}

type CancellationReasonsErrorAction = {
  type: typeof CANCELLATION_REASONS_ERROR
  error: Record<string, string> | string
}

type Action =
  | UnsubscribingAction
  | UnsubscribeSuccessAction
  | UnsubscribeErrorAction
  | CancellationReasonsSuccessAction
  | CancellationReasonsErrorAction
  | RetentionOfferLoad
  | RetentionOfferLoadSuccess
  | RetentionOfferLoadError
  | CancellationReasonsLoad
  | BestsellersResourcesLoad
  | BestsellersResourcesLoadSuccess

export function requestToUnsubscribe(query: {
  [key: string]: string
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/unsubscribe',
      options: {
        method: 'post',
        dontParse: true,
        data: query,
      },
      preserveError: true,
      types: [UNSUBSCRIBING, UNSUBSCRIBE_SUCCESS, UNSUBSCRIBE_ERROR],
    },
  }
}

export function loadRetentionOffer(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/subscriptions/retention_flow',
      types: [
        LOAD_RETENTION_OFFER,
        LOAD_RETENTION_OFFER_SUCCESS,
        LOAD_RETENTION_OFFER_ERROR,
      ],
    },
  }
}

export function loadUserCancellationReasonVariants(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/unsubscribe_reasons/variants',
      types: [
        CANCELLATION_REASONS_LOAD,
        CANCELLATION_REASONS_SUCCESS,
        CANCELLATION_REASONS_ERROR,
      ],
    },
  }
}

export type UserCancellationPayload = {
  comment?: string
  other_reason?: string
  chosen_variants: string[]
}

export function loadBestsellersResources({
  sectionSlug,
  showcaseSlug,
  libraryLang,
  contentType,
  append,
}: {
  sectionSlug: string
  resourceType?: ResourceName | 'series'
  showcaseTag?: string
  showcaseSlug: string
  libraryLang: Locale
  contentType: ShowcaseResource
  append?: boolean
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/showcases/${libraryLang}/${showcaseSlug}/${contentType}/sections/${sectionSlug}/resources?page=1&per_page=10`,
      types: [
        LOAD_BESTSELLERS_RESOURCES,
        LOAD_BESTSELLERS_RESOURCES_SUCCESS,
        LOAD_BESTSELLERS_RESOURCES_ERROR,
      ],
      normalize: res => ({ ...res, append }),
    },
  }
}

export function sendUserCancellationInfo(
  payload: UserCancellationPayload,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/unsubscribe_reason',
      options: {
        method: 'post',
        data: payload,
      },
      types: [
        CANCELLATION_REASONS_SEND,
        CANCELLATION_REASONS_SEND_SUCCESS,
        CANCELLATION_REASONS_SEND_ERROR,
      ],
    },
  }
}

const initialState = {
  pending: false,
  success: false,
  error: false,
  retentionOfferData: null,
  cancellationReasons: {
    variants: [],
    error: '',
    loading: false,
  },
}

export default function unsubscribe(
  state: UnsubscribeState = initialState,
  action: Action,
): UnsubscribeState {
  switch (action.type) {
    case UNSUBSCRIBING:
      return {
        ...state,
        pending: true,
      }

    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        pending: false,
        success: true,
      }

    case UNSUBSCRIBE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      }

    case CANCELLATION_REASONS_LOAD:
      return {
        ...state,
        cancellationReasons: {
          ...state.cancellationReasons,
          loading: true,
        },
      }

    case CANCELLATION_REASONS_SUCCESS:
      return {
        ...state,
        cancellationReasons: {
          ...state.cancellationReasons,
          variants: action.variants,
          loading: false,
        },
      }

    case CANCELLATION_REASONS_ERROR:
      return {
        ...state,
        cancellationReasons: {
          ...state.cancellationReasons,
          error:
            typeof action?.error === 'string'
              ? action.error
              : action.error?.message,
        },
      }

    case LOAD_BESTSELLERS_RESOURCES:
    case LOAD_RETENTION_OFFER:
      return {
        ...state,
        retentionOfferData: {
          ...state.retentionOfferData,
          loading: true,
        },
      }

    case LOAD_RETENTION_OFFER_SUCCESS:
      return {
        ...state,
        retentionOfferData: {
          ...action.data.attributes,
          loading: false,
        },
      }

    case LOAD_BESTSELLERS_RESOURCES_SUCCESS:
      return {
        ...state,
        retentionOfferData: {
          ...state.retentionOfferData,
          bestsellers: !action.append
            ? action.books || action.audiobooks
            : state.retentionOfferData?.bestsellers,
          appended: action.append
            ? action.books
            : state.retentionOfferData?.appended || [],
          loading: false,
        },
      }

    default:
      return state
  }
}

import React, { Component } from 'react'
import Linka from 'client/shared/blocks/linka'
import { Trans } from '@lingui/react'
import { Dropdown } from 'client/shared/blocks/dropdown'
import { HeaderNavigationMore } from './header-nav-more'
import { QueryParams } from 'shared/tools/url-helper'

type Props = {
  auth: boolean
  className?: string
  giftsShown?: boolean
  query?: QueryParams
  isHideCode?: boolean
  isHideGifts?: boolean
}

type State = {
  moreOptionsShown: boolean
}

export class HeaderNavigationDropdown extends Component<Props, State> {
  state = {
    moreOptionsShown: false,
  }

  openMoreOptions = (): void => {
    this.setState({ moreOptionsShown: !this.state.moreOptionsShown })
  }

  closeMoreOptions = (): void => {
    this.setState({ moreOptionsShown: false })
  }

  render(): JSX.Element {
    const { className, auth, giftsShown, query } = this.props

    return (
      <Linka
        className="header__nav-tab header__nav-tab_center"
        pseudo
        asBlock
        activeClassName="header__nav-tab_active"
        onClick={this.openMoreOptions}
      >
        <Trans id="auth.more" />
        <span className="more-symbol"> ▼</span>
        <Dropdown
          position="top-center"
          offsetTop={32}
          onClose={this.closeMoreOptions}
          hidden={!this.state.moreOptionsShown}
        >
          <HeaderNavigationMore
            isHideCode={this.props.isHideCode}
            isHideGifts={this.props.isHideGifts}
            {...{ className, auth, giftsShown, query }}
          />
        </Dropdown>
      </Linka>
    )
  }
}

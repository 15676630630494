import React from 'react'
import SVGInline from 'react-svg-inline'
import { withI18n } from '@lingui/react'

import './social-links.styl'

import FacebookIcon from 'client/shared/icons/facebook2.svg'
import TwitterIcon from 'client/shared/icons/twitter2.svg'
import InstagramIcon from 'client/shared/icons/instagram.svg'

import Linka from 'client/shared/blocks/linka'
import {
  isFacebookAvailable,
  isInstagramAvailable,
  isTwitterAvailable,
} from 'client/shared/helpers/socials-helpers'

type Props = {
  country?: string
  locale: string
  i18n: any
}
class SocialLinks extends React.Component<Props> {
  render() {
    const { locale, i18n, country } = this.props
    const facebookPath = i18n.t`social.facebook`
    const twitterPath = i18n.t`social.twitter`
    const instagramPath = i18n.t`social.instagram`

    return (
      <div className="social-links__footer">
        {isFacebookAvailable(country) && (
          <Linka path={facebookPath}>
            <SVGInline svg={FacebookIcon} className="social-link__icon" />
          </Linka>
        )}
        {(isTwitterAvailable(country) && locale === 'id' && (
          <Linka path={twitterPath}>
            <SVGInline svg={TwitterIcon} className="social-link__icon" />
          </Linka>
        )) ||
          null}
        {(isInstagramAvailable(country) && locale === 'es' && (
          <Linka path={instagramPath}>
            <SVGInline svg={InstagramIcon} className="social-link__icon" />
          </Linka>
        )) ||
          null}
      </div>
    )
  }
}

export default withI18n({ update: true })(SocialLinks)

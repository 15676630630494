import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import errorBoundary, {
  ErrorBoundaryProps,
} from 'client/shared/decorators/error-boundary'

import Footer from 'client/shared/blocks/footer'
import SuccessLayout from 'client/bookmate/blocks/code-success-layout'

import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as CodeState } from 'client/bookmate/reducers/code-reducer'
import { callAndroidOnSuccessPayment } from 'client/shared/helpers/stripe-helper'
import { QueryParams } from 'shared/tools/url-helper'

type Props = {
  code: CodeState
  dispatch: Dispatch
  currentUser: CurrentUserState
  query?: QueryParams
} & ErrorBoundaryProps &
  withI18nProps

export const A1_TO_LIBRARY_LINK =
  'https://bookmate.onelink.me/8LRx?af_web_dp=https%3A%2F%2Fhr.bookmate.com%2Flibrary%3Futm_source%3Da1%26utm_medium%3Dpartner%26utm_campaign%3Da1_landing%26utm_content%3Dhr&af_xp=custom&pid=a1&af_channel=partner&af_click_lookback=7d&af_dp=bookmate%3A%2F%2Fshowcase&c=a1_landing'

class A1SuccessBox extends Component<Props> {
  timeout: any

  componentDidMount(): void {
    const { dispatch, code } = this.props

    if (code.redirectUrl) {
      this.timeout = setTimeout(dispatch, 3000, push(code.redirectUrl))
    }

    callAndroidOnSuccessPayment()
  }

  componentWillUnmount(): void {
    if (this.timeout) clearTimeout(this.timeout)
  }

  render(): JSX.Element {
    const {
      currentUser: {
        data: { locale, country },
      },
      i18n,
      code: { codeValue },
      query,
    } = this.props
    const language = i18n._(`shared.${locale}_lang`)

    return (
      <div className="a1-success__wrapper">
        <SuccessLayout
          query={query}
          codeValue={codeValue}
          language={language}
          country={country}
          libraryLink={A1_TO_LIBRARY_LINK}
        />
        <Footer withButtons={false} libraryLink={A1_TO_LIBRARY_LINK} />
      </div>
    )
  }
}

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  code: state.code,
  query: state.app.storedQuery,
}))

const wrappers = compose(
  connectWrapper,
  errorBoundary,
  withI18n({ update: true }),
)

export default wrappers(A1SuccessBox)

import isEmpty from 'lodash/isEmpty'

import { AudiobookProps as Audiobook } from 'client/shared/types/audiobook'

export default {
  canBeListened(audiobook: Audiobook) {
    return audiobook && audiobook.can_be_listened
  },

  isAdded(audiobook: Audiobook) {
    return (
      audiobook &&
      audiobook.audio_card &&
      audiobook.audio_card.state !== 'removed'
    )
  },

  isPublic(audiobook: Audiobook) {
    return audiobook && audiobook.audio_card && !audiobook.audio_card.hidden
  },

  isPrivate(audiobook: Audiobook) {
    return audiobook && audiobook.audio_card && audiobook.audio_card.hidden
  },

  isFinished(audiobook: Audiobook) {
    return (
      audiobook &&
      audiobook.audio_card &&
      audiobook.audio_card.state === 'finished'
    )
  },

  inProgress(audiobook: Audiobook) {
    const { audio_card: audioCard } = audiobook
    return (
      !this.isFinished(audiobook) &&
      !isEmpty(audioCard) &&
      Boolean(audioCard.progress)
    )
  },

  getProgressInPercent(audiobook: Audiobook) {
    const { audio_card: audioCard, duration } = audiobook
    if (audioCard && duration) {
      return Math.min(
        99,
        Math.max(1, Math.floor((audioCard.progress / duration) * 100)),
      )
    }
  },
}

import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import findIndexForTruncation from './helpers/text-truncate-helper'
import {
  trimTitle,
  trimAuthors,
  trimSimpleText,
} from 'client/shared/helpers/text-helpers'
import compose from 'lodash/fp/compose'
import omit from 'lodash/omit'

type Props = {
  containerClassName?: string
  lines: number
  text: string
  textType: 'title' | 'authors' | 'default'
  ellipsis: string | Node // should either be a string or a node whose immediate child is text element
  style: {
    [key: string]: string | number
  }
} & withI18nProps

class TextTruncate extends Component<Props> {
  static defaultProps = {
    lines: 1,
    text: '',
    textType: 'default',
    ellipsis: '…',
    style: {},
  }

  canvas: CanvasRenderingContext2D | null = null
  textContainer: Element | null | undefined = null

  componentDidMount() {
    this.setupCanvas(this.textContainer)
  }

  setupCanvas(textContainer: Element | null | undefined) {
    if (!Element) return
    const canvas = document.createElement('canvas')
    const docFragment = document.createDocumentFragment()
    const style = window.getComputedStyle(textContainer as Element)
    const font = [
      style['font-weight'],
      style['font-style'],
      style['font-size'],
      style['font-family'],
    ].join(' ')

    docFragment.appendChild(canvas)
    this.canvas = canvas.getContext('2d')

    if (!this.canvas) return ''

    this.canvas.font = font
  }

  measureWidth = text => {
    return this.canvas?.measureText(text)?.width
  }

  getEllipsisText() {
    // expect the ellipsis prop to be either string or a React element whose immediate child is text element
    // if truncated text is a list of authors, use internationalized ellipsis
    const { ellipsis } = this.props

    if (typeof ellipsis === 'string') {
      return ellipsis
    } else if (ellipsis) {
      // @FIXME: use correct type
      return (ellipsis as any).props.children
    }
  }

  getPluralEllipsisText() {
    const { textType, i18n } = this.props

    if (textType === 'authors') {
      return i18n.t`miscellaneous.and_others`
    } else {
      return null
    }
  }

  getTruncatedText(maxTextLength) {
    const { textType } = this.props
    return this.getTruncateFunction(textType)(maxTextLength)
  }

  getTruncateFunction(textType) {
    if (textType === 'title') {
      return this.getTruncatedTitle
    } else if (textType === 'authors') {
      return this.getTruncatedAuthors
    } else {
      return this.getTruncatedSimpleText
    }
  }

  getTruncatedTitle = maxTextLength => {
    const { text, ellipsis } = this.props
    const truncated = trimTitle(text, maxTextLength)
    return (
      <span>
        {truncated.text}
        {truncated.shouldAddEllipsis && ellipsis}
      </span>
    )
  }

  getTruncatedAuthors = maxTextLength => {
    const { text, i18n } = this.props
    const truncated = trimAuthors(text, maxTextLength)
    const { shouldAddEllipsis, shouldMentionOthers } = truncated

    if (shouldAddEllipsis) {
      return <span>{truncated.text}…</span>
    } else if (shouldMentionOthers) {
      return (
        <span>
          {truncated.text} {i18n.t`miscellaneous.and_others`}
        </span>
      )
    }
  }

  getTruncatedSimpleText = maxTextLength => {
    const { text, ellipsis } = this.props
    const truncated = trimSimpleText(text, { maxTextLength })
    return (
      <span>
        {truncated}
        {ellipsis}
      </span>
    )
  }

  getRenderText() {
    const { textContainer } = this
    if (!textContainer) return

    const { text, lines } = this.props
    const ellipsisText = this.getEllipsisText()
    const pluralEllipsisText = this.getPluralEllipsisText()

    const textContainerWidth = textContainer.getBoundingClientRect().width

    const parameters = {
      text,
      allowedNumberOfLines: lines,
      ellipsisText,
      pluralEllipsisText,
      maxWidth: textContainerWidth,
      textWidthMeasurer: this.measureWidth,
    }

    const { shouldTruncate, truncationIndex } = findIndexForTruncation(
      parameters,
    )

    return shouldTruncate ? (
      this.getTruncatedText(truncationIndex)
    ) : (
      <span>{text}</span>
    )
  }

  render() {
    const { text, containerClassName, style, ...restProps } = this.props

    let renderText: JSX.Element | string | undefined = text
    if (this.textContainer) {
      renderText = this.getRenderText()
    }

    return (
      <div
        ref={element => (this.textContainer = element)}
        className={containerClassName}
        title={text}
        style={{
          ...style,
          overflow: 'hidden',
          boxSizing: 'border-box',
          wordWrap: 'break-word',
        }}
        {...omit(
          restProps,
          'textType',
          'i18nHash',
          'i18n',
          'lines',
          'ellipsis',
        )}
      >
        {renderText}
      </div>
    )
  }
}

const wrapper = compose(withI18n({ update: true }))

export default wrapper(TextTruncate)

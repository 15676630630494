import React from 'react'
import cn from 'classnames'
import { withI18n, withI18nProps, Trans } from '@lingui/react'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import SVGInline from 'react-svg-inline'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import withMarketing, {
  MarketingProps,
} from 'client/shared/decorators/with-marketing'
import urlFor from 'shared/tools/url-helper'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'
import Button from 'client/shared/blocks/button'
import NextIcon from 'client/shared/icons/next.svg'

import './subscription-button.styl'

import { Location, Router } from 'client/shared/types/react-router'

type Context = 'default' | 'header'

type Props = {
  kind: 'huge' | 'small' | 'banner'
  context: Context
  location: Location
  className?: string
  onClick?: () => void
  custom?: string
  router: Router
} & DecoratorProps &
  MarketingProps &
  withI18nProps

class SubscriptionButton extends React.Component<Props> {
  static defaultProps = {
    context: 'default',
    kind: 'huge',
  }

  hasAnySubscriptions(): boolean {
    const {
      subscriptionState: { userAccessLevels },
    } = this.props
    return subscriptionHelper.hasAnySubscriptions(userAccessLevels)
  }

  isFeaturedProductAvailable(): boolean {
    return Boolean(this.props.subscriptionState.featuredProduct)
  }

  isOnSpecialOfferPage(): boolean {
    const {
      location: { pathname },
    } = this.props

    return /^\/special-offers/.test(pathname)
  }

  canShowButton(): boolean {
    const { context } = this.props
    // check for various conditions for showing/hiding the button in this method

    return (
      !this.isOnSpecialOfferPage() && // this is not a special offer page (where the button would be a distraction)
      !(context === 'header' && this.hasAnySubscriptions())
    )
  }

  getUnanuthenticatedMessage(): string {
    const { i18n } = this.props

    if (this.isFeaturedProductAvailable()) {
      return this.getFeaturedProductMessage()
    } else {
      return i18n.t`header.buy_subscription_link`
    }
  }

  getUnanuthenticatedLink(): string {
    const { router } = this.props
    if (this.isFeaturedProductAvailable()) {
      return urlFor.subscriptionFeaturedProduct(router?.location?.query)
    } else {
      return urlFor.subscription(router?.location?.query)
    }
  }

  getFeaturedProductMessage(): string {
    const {
      subscriptionState: { featuredProduct },
    } = this.props
    return get(featuredProduct, 'button.text', '')
  }

  prepareRegularLink(): string {
    return urlFor.subscription(this.props.router?.location?.query)
  }

  render(): JSX.Element | null {
    const { auth, i18n, context, onClick } = this.props

    if (!this.canShowButton()) return null

    if (!auth) {
      const text = this.getUnanuthenticatedMessage()
      const link = this.getUnanuthenticatedLink()
      return this.renderButton({ text, link, onClick })
    } else if (this.hasAnySubscriptions()) {
      if (context === 'header') {
        return null
      } else {
        return this.renderButton({
          link: urlFor.library(this.props.router?.location?.query),
          text: i18n.t`buttons.start_read`,
          onClick,
        })
      }
    } else if (this.isFeaturedProductAvailable()) {
      const text = this.getFeaturedProductMessage()
      const featuredProductLink = urlFor.subscriptionFeaturedProduct(
        this.props.router?.location?.query,
      )
      return this.renderButton({ link: featuredProductLink, text, onClick })
    } else {
      return this.renderButton({
        link: this.prepareRegularLink(),
        text: i18n.t`header.buy_subscription_link`,
        onClick,
      })
    }
  }

  renderButton({
    link,
    text,
    onClick,
  }: {
    link?: string
    text: React.ReactNode
    onClick?: () => any
  }): JSX.Element | null {
    const { kind, className, custom } = this.props

    const classes = cn(
      ['subscription-button', `subscription-button_${kind}`, className].filter(
        Boolean,
      ),
    )

    if (custom === 'family') {
      const buttonPath = urlFor.subscriptionKind(
        custom,
        'plans',
        this.props.router?.location?.query,
      )
      return (
        <Button as="span" className={classes} path={buttonPath}>
          <Trans id="family-subscription.landing_action" />
        </Button>
      )
    }

    if (kind === 'banner') {
      return (
        <Button className={classes} path={link}>
          <span>{text}</span>
          <span className="banner__right-controls">
            <Trans id="plan.try" />
            <SVGInline className="banner-svg" svg={NextIcon} />
          </span>
        </Button>
      )
    } else if (link) {
      return (
        <Button as="span" className={classes} path={link}>
          {text}
        </Button>
      )
    } else if (onClick) {
      return (
        <Button className={classes} onClick={onClick}>
          {text}
        </Button>
      )
    } else {
      return null
    }
  }
}

const wrappers = compose(
  uiBox,
  withMarketing,
  withI18n({ update: true }),
  withRouter,
)

export const SubscriptionButtonUnwrapped = SubscriptionButton

export default wrappers(SubscriptionButton)

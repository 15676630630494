import throttle from 'lodash/throttle'

export const throttledLikeHelper = (dispatchFunc, dispatchType) => {
  const throttledApiCall = throttle(
    ({ uuid, liked, likes_count, dispatch }) =>
      dispatch(dispatchFunc({ uuid, liked, likes_count })),
    1000,
  )

  return ({ uuid, liked, likes_count, dispatch }) => {
    dispatch({ type: dispatchType, liked, uuid, likes_count })
    throttledApiCall({ uuid, liked, likes_count, dispatch })
  }
}

export const isPromise = (value: any): boolean =>
  Boolean(value && typeof value?.then === 'function')

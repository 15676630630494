import { schema } from 'normalizr'
import omit from 'lodash/omit'

const kindToResourceType = {
  books: 'book',
  comics: 'comicbook',
  audio: 'audiobook',
  podcast: 'audiobook',
}

export const libraryCardSchema = new schema.Entity(
  'libraryCards',
  {},
  { idAttribute: 'uuid' },
)
export const libraryCardsSchema = new schema.Array(libraryCardSchema)

// Book objects returned from api5 contain both the 'authors' field (a comma-separated string)
// and the 'authors_objects' field (an array of objects containing author's name and id).
// To unify the shape of the authors field across entities, move the contents of
// the authors_objects field to the authors field (and delete the authors_objects field)
export const bookSchema = new schema.Entity(
  'books',
  {
    library_card: libraryCardSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy(book) {
      return omit(
        {
          ...book,
          resourceType: book.source_type === 'serial' ? 'serial' : 'book',
          topics:
            book.topics && typeof book.topics[0] === 'string'
              ? book.topics.map(topic => ({ title: topic, uuid: null }))
              : book.topics,
          authors: book.authors_objects ? book.authors_objects : book.authors,
        },
        'authors_objects',
      )
    },
  },
)
export const booksSchema = new schema.Array(bookSchema)

// CAREFUL: this schema needs to be defined before audioBookSchema
export const audioCardSchema = new schema.Entity(
  'audioCards',
  {},
  { idAttribute: 'uuid' },
)

export const audioBookSchema = new schema.Entity(
  'audiobooks',
  {
    audio_card: audioCardSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy(audiobook) {
      return {
        ...audiobook,
        resourceType: 'audiobook',
      }
    },
  },
)
export const audioBooksSchema = new schema.Array(audioBookSchema)

export const authorSchema = new schema.Entity(
  'authors',
  {},
  { idAttribute: 'uuid' },
)
export const authorsSchema = new schema.Array(authorSchema)

export const comicbookSchema = new schema.Entity(
  'comicbooks',
  {},
  {
    idAttribute: 'uuid',
    processStrategy(comicbook) {
      return {
        ...comicbook,
        resourceType: 'comicbook',
      }
    },
  },
)
export const comicbooksSchema = new schema.Array(comicbookSchema)

export const seriesSchema = new schema.Entity(
  'series',
  {
    authors: authorsSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy(series) {
      return {
        ...series,
        resourcesTypes: series.kinds.map(kind => kindToResourceType[kind]),
      }
    },
  },
)
export const seriesListSchema = new schema.Array(seriesSchema)

export const userSchema = new schema.Entity(
  'users',
  {},
  { idAttribute: 'login' },
)

export const usersSchema = new schema.Array(userSchema)

export const shelfSchema = new schema.Entity(
  'shelves',
  {},
  {
    idAttribute: 'uuid',
    processStrategy(shelf) {
      if (shelf.topics && typeof shelf.topics[0] === 'string') {
        shelf.topics = shelf.topics.map(topic => ({
          title: topic,
          uuid: null,
        }))
      }
      return shelf
    },
  },
)

shelfSchema.define({
  creator: userSchema,
})

export const shelvesSchema = new schema.Array(shelfSchema)

export const resourceSchemaByType = {
  book: bookSchema,
  audiobook: audioBookSchema,
  comicbook: comicbookSchema,
  series: seriesSchema,
  shelf: shelfSchema,
}

export const resourcesSchemaByType = {
  books: booksSchema,
  audiobooks: audioBooksSchema,
  comicbooks: comicbooksSchema,
  series: seriesListSchema,
  shelves: shelvesSchema,
}

import React, { Component, ComponentClass } from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'
import map from 'lodash/fp/map'

import './authors-list.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import Linka from 'client/shared/blocks/linka'

import { MinimalAuthorInfo } from 'client/shared/types/author'
import { connect } from 'react-redux'
import { State } from 'shared/types/redux'

type Props = {
  kind?: 'default' | 'compact'
  authors: MinimalAuthorInfo[]
  disableLink?: boolean
  className?: string
  underlined?: boolean
  max?: number
  query?: QueryParams
}

class _AuthorsList extends Component<Props> {
  static defaultProps = {
    kind: 'default',
    authors: [],
    disableLink: false,
  }

  normalizeAuthors() {
    const { authors, max } = this.props

    let normalizedAuthors: MinimalAuthorInfo[] | null = null

    if (typeof authors === 'string') {
      normalizedAuthors = authors
        .split(',')
        .map(author => ({ name: author.trim() }))
    } else {
      normalizedAuthors = authors
    }

    return {
      authors: max ? normalizedAuthors?.slice(0, max) : normalizedAuthors,
      isSliced: max ? Number(normalizedAuthors?.length) > max : false,
    }
  }

  render(): JSX.Element {
    const { kind, className, underlined } = this.props
    const { authors, isSliced } = this.normalizeAuthors()
    const authorsCl = cn('authors-list', `authors-list_${kind}`, className, {
      'authors-list_underlined': underlined,
    })

    return (
      <span className={authorsCl} title={map('name', authors).join(', ')}>
        {authors?.map((author, index) =>
          this.renderAuthor(author, authors, index),
        )}
        {isSliced && <Trans id="miscellaneous.and_others" />}
      </span>
    )
  }

  renderAuthor(
    author: MinimalAuthorInfo,
    authors: MinimalAuthorInfo[],
    index: number,
  ): JSX.Element {
    const { disableLink, query } = this.props
    const authorTrimmed = author.name.trim()

    const path = author.uuid && urlFor.author(author.uuid, query)

    return (
      <span key={index} className="authors-list__author">
        {disableLink || !path ? (
          <span>{authorTrimmed}</span>
        ) : (
          <Linka path={path}>{authorTrimmed}</Linka>
        )}
        {index + 1 !== authors.length && <span>,</span>}
      </span>
    )
  }
}

const connectWrapper = connect((state: State) => ({
  query: state.app.storedQuery,
}))

export const AuthorsList = connectWrapper(_AuthorsList as ComponentClass<Props>)

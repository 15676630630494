import { normalize } from 'normalizr'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { createRedirectForResource } from 'shared/tools/url-helper'

import { cacheAction } from 'shared/middlewares/cache-middleware'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  comicbookSchema,
  comicbooksSchema,
  usersSchema,
  shelvesSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import { normalizeSeriesParts } from 'client/bookmate/helpers/series-helpers'

const COMICBOOK_LOAD = 'COMICBOOK_LOAD'
const COMICBOOK_LOAD_SUCCESS = 'COMICBOOK_LOAD_SUCCESS'
const COMICBOOK_LOAD_ERROR = 'COMICBOOK_LOAD_ERROR'

const COMICBOOK_READERS_LOAD = 'COMICBOOK_READERS_LOAD'
const COMICBOOK_READERS_LOAD_SUCCESS = 'COMICBOOK_READERS_LOAD_SUCCESS'

const COMICBOOK_SHELVES_LOAD = 'COMICBOOK_SHELVES_LOAD'
const COMICBOOK_SHELVES_LOAD_SUCCESS = 'COMICBOOK_SHELVES_LOAD_SUCCESS'

const COMICBOOK_PUBLISHER_RESOURCES_LOAD = 'COMICBOOK_PUBLISHER_RESOURCES_LOAD'
const COMICBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS =
  'COMICBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS'

const COMICBOOK_RELATED_LOAD = 'COMICBOOK_RELATED_LOAD'
const COMICBOOK_RELATED_LOAD_SUCCESS = 'COMICBOOK_RELATED_LOAD_SUCCESS'

const COMICBOOK_IMPRESSIONS_LOAD = 'COMICBOOK_IMPRESSIONS_LOAD'
const COMICBOOK_IMPRESSIONS_LOAD_SUCCESS = 'COMICBOOK_IMPRESSIONS_LOAD_SUCCESS'

const COMICBOOK_EMOTION_RATING_LOAD = 'COMICBOOK_EMOTION_RATING_LOAD'
const COMICBOOK_EMOTION_RATING_LOAD_SUCCESS =
  'COMICBOOK_EMOTION_RATING_LOAD_SUCCESS'

const COMICBOOK_SERIES_EPISODES_LOAD = 'COMICBOOK_SERIES_EPISODES_LOAD'
const COMICBOOK_SERIES_EPISODES_LOAD_SUCCESS =
  'COMICBOOK_SERIES_EPISODES_LOAD_SUCCESS'

import {
  impressionsSchema,
  addIdToImpressions,
  prepareImpressionsFromAPIResponse,
  IMPRESSION_REMOVED,
  IMPRESSION_CURRENT_USER_LOADED,
  IMPRESSION_CURRENT_USER_CLEAN,
} from 'client/bookmate/reducers/impressions-reducer'

const getComicbookRedirectUrl = createRedirectForResource('comicbook')

export function load(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/comicbooks/${uuid}`,
      normalize: ({ comicbook: _comicbook }, { getState }) => {
        const correctComicbookId = _comicbook.uuid
        const currentPath = getState().app.url

        if (uuid !== correctComicbookId) {
          serverRedirectTo(
            getComicbookRedirectUrl(correctComicbookId, currentPath),
            301,
          )
        } else {
          const { result, entities } = normalize(_comicbook, comicbookSchema)

          return {
            result,
            entities,
          }
        }
      },
      types: [COMICBOOK_LOAD, COMICBOOK_LOAD_SUCCESS, COMICBOOK_LOAD_ERROR],
    },
  }
}

// eslint-disable-next-line func-names
export const loadReaders = cacheAction(
  function ({ uuid, page = 1, per_page = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/comicbooks/${uuid}/readers`,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [COMICBOOK_READERS_LOAD, COMICBOOK_READERS_LOAD_SUCCESS],
      },
    }
  },
  COMICBOOK_READERS_LOAD_SUCCESS,
  null,
)

// eslint-disable-next-line func-names
export const loadShelves = cacheAction(
  function ({ uuid, page = 1, per_page = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/comicbooks/${uuid}/bookshelves`,
        options: {
          data: {
            page,
            per_page,
          },
        },
        schema: shelvesSchema,
        responseKey: 'bookshelves',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [COMICBOOK_SHELVES_LOAD, COMICBOOK_SHELVES_LOAD_SUCCESS],
      },
    }
  },
  COMICBOOK_SHELVES_LOAD_SUCCESS,
  null,
)

// eslint-disable-next-line func-names
export const loadRelated = cacheAction(
  function ({
    bookshelfUuid,
    page = 1,
    per_page = 20,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/bookshelves/${bookshelfUuid}/comicbooks`,
        options: {
          data: {
            page,
            per_page,
            order_by,
            order_direction,
          },
        },
        schema: comicbooksSchema,
        responseKey: 'comicbooks',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? page + 1 : null,
        }),
        types: [COMICBOOK_RELATED_LOAD, COMICBOOK_RELATED_LOAD_SUCCESS],
      },
    }
  },
  COMICBOOK_RELATED_LOAD_SUCCESS,
  null,
)

// eslint-disable-next-line func-names
export const loadImpressions = cacheAction(
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/comicbooks/${uuid}/impressions`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionsFromAPIResponse(response.impressions),
            impressionsSchema,
          )

          return {
            result,
            entities: {
              ...entities,
              impressions: addIdToImpressions(
                entities.impressions,
                uuid,
                'comicbook',
              ),
            },
            updateField: index ? 'impressionsIndex' : 'impressions',
            index,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [COMICBOOK_IMPRESSIONS_LOAD, COMICBOOK_IMPRESSIONS_LOAD_SUCCESS],
      },
    }
  },
  COMICBOOK_IMPRESSIONS_LOAD_SUCCESS,
  null,
)

export function loadEmotionRating(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/comicbooks/${uuid}/emotion_ratings`,
      types: [
        COMICBOOK_EMOTION_RATING_LOAD,
        COMICBOOK_EMOTION_RATING_LOAD_SUCCESS,
      ],
    },
  }
}

export function loadPublisherResources(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/api/v5/comicbooks/${uuid}/publisher_resources`,
      types: [
        COMICBOOK_PUBLISHER_RESOURCES_LOAD,
        COMICBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS,
      ],
      ignoreError: true,
    },
  }
}

// eslint-disable-next-line func-names
export const loadOtherEpisodes = cacheAction(
  function ({ uuid, seriesUuid, p = 1, pp = 20, append = false }) {
    const data: { page: number; per_page: number; series_uuid?: string } = {
      page: p,
      per_page: pp,
    }

    if (seriesUuid) {
      data.series_uuid = seriesUuid
    }

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/comicbooks/${uuid}/more_in_series`,
        options: { data },
        normalize: ({ parts }) => {
          const { result, entities } = normalizeSeriesParts(parts)

          return {
            result,
            entities,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [
          COMICBOOK_SERIES_EPISODES_LOAD,
          COMICBOOK_SERIES_EPISODES_LOAD_SUCCESS,
        ],
      },
    }
  },
  COMICBOOK_SERIES_EPISODES_LOAD_SUCCESS,
  null,
)

const initialState = {
  loading: false,
  uuid: '',
  readers: [],
  shelves: [],
  related: [],
  otherEpisodes: [],
  impressions: [],
  impressionsIndex: [],
  pages: {
    readers: 1,
    shelves: 1,
    related: 1,
    impressions: 1,
    impressionsIndex: 1,
    otherEpisodes: 1,
  },
  emotionRating: [],
  publisherResources: [],
  currentUserImpressionUuid: null,
}

export default function comicbook(state = initialState, action) {
  switch (action.type) {
    case COMICBOOK_LOAD:
      return {
        ...state,
        uuid: '',
        loading: true,
      }

    case COMICBOOK_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uuid: action.result,
        error: null,
      }

    case COMICBOOK_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        uuid: '',
        error: action.error,
      }

    case COMICBOOK_READERS_LOAD_SUCCESS:
      return {
        ...state,
        readers: action.append
          ? [...state.readers, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          readers: action.nextPage,
        },
      }

    case COMICBOOK_SHELVES_LOAD_SUCCESS:
      return {
        ...state,
        shelves: action.append
          ? [...state.shelves, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          shelves: action.nextPage,
        },
      }

    case COMICBOOK_PUBLISHER_RESOURCES_LOAD_SUCCESS:
      return {
        ...state,
        publisherResources: action.comicbooks,
      }

    case COMICBOOK_RELATED_LOAD_SUCCESS:
      return {
        ...state,
        related: action.append
          ? [...state.related, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          related: action.nextPage,
        },
      }

    case COMICBOOK_IMPRESSIONS_LOAD_SUCCESS: {
      const { updateField, result, index, append } = action

      return {
        ...state,
        [updateField]:
          index || !append ? result : [...state[updateField], ...result],
        pages: {
          ...state.pages,
          [updateField]: action.nextPage,
        },
      }
    }

    case IMPRESSION_REMOVED:
      if (action.resourceType === 'comicbook') {
        return {
          ...state,
          impressionsIndex: state.impressionsIndex.filter(
            uuid => uuid !== action.uuid,
          ),
          impressions: state.impressions.filter(uuid => uuid !== action.uuid),
        }
      } else {
        return state
      }

    case COMICBOOK_EMOTION_RATING_LOAD_SUCCESS:
      return {
        ...state,
        emotionRating: action.emotion_ratings,
      }

    case IMPRESSION_CURRENT_USER_LOADED: {
      const { resourceUuid, resourceType, uuid } = action.data

      if (resourceType === 'comicbook' && resourceUuid === state.uuid) {
        return {
          ...state,
          currentUserImpressionUuid: uuid,
        }
      } else {
        return state
      }
    }

    case IMPRESSION_CURRENT_USER_CLEAN:
      return {
        ...state,
        currentUserImpressionUuid: null,
      }

    case COMICBOOK_SERIES_EPISODES_LOAD_SUCCESS: {
      return {
        ...state,
        otherEpisodes: action.append
          ? [...state.otherEpisodes, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          otherEpisodes: action.nextPage,
        },
      }
    }

    default:
      return state
  }
}

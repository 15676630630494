import fetchWrapper from 'shared/tools/fetch-wrapper'

import { push, replace } from 'react-router-redux'
import urlFor, { QueryParams } from 'shared/tools/url-helper'

import { findProductPlanById } from 'client/bookmate/selectors/subscription-selectors'

import { externalRedirect } from 'client/shared/reducers/app-reducer'

import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import {
  prepareSubscriptionAnalyticsPayload,
  prepareAnalyticsPayloadForUpgrade,
} from 'client/shared/helpers/analytics-helpers'
import {
  analyticsEvent,
  SUBSCRIPTION_SUCCESSFUL,
  SUBSCRIPTION_FAILED,
} from 'client/shared/reducers/analytics-reducer'
import {
  loadUserAccessLevels,
  loadProducts,
  loadFeaturedProduct,
  loadUserSubscriptions,
} from 'client/bookmate/reducers/subscription-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'

import { POST_STRIPE_DATA_SUCCESS } from 'client/shared/constants/code-constants'

import { ThunkAction, GetState, Dispatch } from 'shared/types/redux'
import { PlanType } from 'client/shared/helpers/analytics-helpers'
import { UpgradeOption } from 'client/shared/types/subscription'

const POST_STRIPE_DATA = 'POST_STRIPE_DATA'
const POST_STRIPE_DATA_ERROR = 'POST_STRIPE_DATA_ERROR'
const STRIPE_SCRIPT_LOAD_SUCCESS = 'STRIPE_SCRIPT_LOAD_SUCCESS'
const STRIPE_SCRIPT_LOAD_FAILURE = 'STRIPE_SCRIPT_LOAD_FAILURE'
const CLEAR_STRIPE_ERROR = 'CLEAR_STRIPE_ERROR'
const STRIPE_3D_SECURE_ERROR = 'STRIPE_3D_SECURE_ERROR'
const OFFER_REGULAR_PLAN = 'OFFER_REGULAR_PLAN'
const STRIPE_CREATE_PAYMENT_INTENT = 'STRIPE_CREATE_PAYMENT_INTENT'
const STRIPE_CREATE_PAYMENT_INTENT_SUCCESS =
  'STRIPE_CREATE_PAYMENT_INTENT_SUCCESS'
const STRIPE_CREATE_PAYMENT_INTENT_ERROR = 'STRIPE_CREATE_PAYMENT_INTENT_ERROR'
const PAYMENT_INTENT_CLEAR = 'PAYMENT_INTENT_CLEAR'

const PROMO_CREATE_PAYMENT_INTENT = 'PROMO_CREATE_PAYMENT_INTENT'
const PROMO_CREATE_PAYMENT_INTENT_SUCCESS =
  'PROMO_CREATE_PAYMENT_INTENT_SUCCESS'
const PROMO_CREATE_PAYMENT_INTENT_ERROR = 'PROMO_CREATE_PAYMENT_INTENT_ERROR'

const CHEAP_TRIAL_CREATE_PAYMENT_INTENT = 'CHEAP_TRIAL_CREATE_PAYMENT_INTENT'
const CHEAP_TRIAL_CREATE_PAYMENT_INTENT_SUCCESS =
  'CHEAP_TRIAL_CREATE_PAYMENT_INTENT_SUCCESS'
const CHEAP_TRIAL_CREATE_PAYMENT_INTENT_ERROR =
  'CHEAP_TRIAL_CREATE_PAYMENT_INTENT_ERROR'

// action payload types
type Stripe3DSecureErrorPayload = {
  threeDSecureError?: string | null
  kind: string | null | undefined
}

type Stripe3DSecureDataPostPayload = {
  plan: string
  sourceId: string
  path?: string
  email?: string
  token?: string
}

type UpgradeSubscriptionPayload = {
  recurrentSubscriptionId: string
  upgradeOption: UpgradeOption
  path: string
}

type DispatchPostStripeSuccessActionsPayload = {
  dispatch: Dispatch
}

export type Stripe3DSecurePostOptions = {
  specialOffer?: string | null | undefined
  onSuccess?: () => void
}

type SpecialOfferPostOptions = {
  specialOffer: string
  onSuccess?: () => void
}

// response of our backend upon successful purchase of subscription
type Stripe3dSecureSuccessResponse = {
  status: 'success' | 'success_with_reminder'
}

type Stripe3DSecurePostData = {
  plan: string
  source: string
  return_url: string
  'g-recaptcha-response'?: string
  email?: string
}

type Stripe3DSecureDataCompletePayload = {
  client_secret: string
  source: string
  payment_intent: string
  payment_intent_client_secret: string
  path: string
  type: string
  campaign?: string
}

type CreatePaymentIntentAction = {
  type: typeof STRIPE_CREATE_PAYMENT_INTENT
}

type CreatePaymentIntentSuccessAction = {
  type: typeof STRIPE_CREATE_PAYMENT_INTENT_SUCCESS
  intent_type: IntentType
  return_url: string
  client_secret: string
}

type CreatePaymentIntentErrorAction = {
  type: typeof STRIPE_CREATE_PAYMENT_INTENT_ERROR
  error: string
}

type CreateCheapTrialPaymentIntentAction = {
  type: typeof CHEAP_TRIAL_CREATE_PAYMENT_INTENT
}

type CreateCheapTrialPaymentIntentSuccessAction = {
  type: typeof CHEAP_TRIAL_CREATE_PAYMENT_INTENT_SUCCESS
  intent_type: IntentType
  return_url: string
  client_secret: string
}

type CreateCheapTrialPaymentIntentErrorAction = {
  type: typeof CHEAP_TRIAL_CREATE_PAYMENT_INTENT_ERROR
  error: string
}

type CreatePromoPaymentIntentAction = {
  type: typeof PROMO_CREATE_PAYMENT_INTENT
}

type CreatePromoPaymentIntentSuccessAction = {
  type: typeof PROMO_CREATE_PAYMENT_INTENT_SUCCESS
  intent_type: IntentType
  return_url: string
  client_secret: string
}

type CreatePromoPaymentIntentErrorAction = {
  type: typeof PROMO_CREATE_PAYMENT_INTENT_ERROR
  error: string
}

type PaymentIntentClearAction = { type: typeof PAYMENT_INTENT_CLEAR }

// our API response types
// (status '3d_secure_flow_initiated' means that Stripe purchase should be performed
// in two steps, and that the user has to be redirected to the provuded url to complete
// the transaction)
type StripeSubscriptionEndpointResponseType =
  | {
      status: '3d_secure_flow_initiated'
      redirect_url: string
    }
  | Stripe3dSecureSuccessResponse

// action types

type StripeScriptLoadSuccessAction = {
  type: 'STRIPE_SCRIPT_LOAD_SUCCESS'
}

type StripeScriptLoadFailureAction = {
  type: 'STRIPE_SCRIPT_LOAD_FAILURE'
}

type StripePostDataAction = {
  type: 'POST_STRIPE_DATA'
  data: {
    loading: true
  }
}

type ErrorData = {
  loading: false
  error: { message: string } | string
  offerRegularPlan?: boolean
}

type StripePostDataErrorAction = {
  type: 'POST_STRIPE_DATA_ERROR'
  data: ErrorData
}

type StripeDataPostSuccessAction = {
  type: 'POST_STRIPE_DATA_SUCCESS'
  data: {
    kind: string
    loading: boolean
    error: null
  }
}

type Stripe3DSecureErrorAction = {
  type: 'STRIPE_3D_SECURE_ERROR'
  data: Stripe3DSecureErrorPayload
}

type StripeErrorClearAction = { type: 'CLEAR_STRIPE_ERROR' }

type StripeOfferRegularPlan = { type: 'OFFER_REGULAR_PLAN' }

type Action =
  | StripeScriptLoadSuccessAction
  | StripeScriptLoadFailureAction
  | StripePostDataAction
  | StripePostDataErrorAction
  | StripeDataPostSuccessAction
  | Stripe3DSecureErrorAction
  | StripeErrorClearAction
  | StripeOfferRegularPlan
  | CreatePaymentIntentAction
  | CreatePaymentIntentSuccessAction
  | CreatePaymentIntentErrorAction
  | PaymentIntentClearAction
  | CreatePromoPaymentIntentAction
  | CreatePromoPaymentIntentSuccessAction
  | CreatePromoPaymentIntentErrorAction
  | CreateCheapTrialPaymentIntentAction
  | CreateCheapTrialPaymentIntentSuccessAction
  | CreateCheapTrialPaymentIntentErrorAction

export type PaymentIntent = {
  complete: boolean
  error?: Record<string, string> | string
  kind?: IntentType
  returnUrl?: string
  key?: string
}

export type State = {
  readonly loading: boolean
  readonly error:
    | (string | null | undefined)
    | ({ message: string } | null | undefined)
  readonly scriptLoaded: boolean
  readonly scriptLoadingFailed: boolean
  readonly threeDSecureError: string | null
  readonly offerRegularPlan: boolean
  readonly paymentIntent: PaymentIntent
}

export const PAYMENT_INTENT = 'payment_intent'

async function StripeResponseHandler(
  response: StripeSubscriptionEndpointResponseType,
  {
    dispatch,
    successPageParams,
    onSuccess,
    storedQuery,
  }: {
    plan: PlanType | null | undefined
    dispatch: Dispatch
    successPageParams: { path: string; type: string }
    onSuccess?: () => void
    storedQuery?: QueryParams
  },
) {
  if (response.status === '3d_secure_flow_initiated') {
    const redirectUrl = response.redirect_url

    return dispatch(externalRedirect(redirectUrl))
  }

  if (onSuccess) {
    onSuccess()
    return dispatchPostStripeSuccessActions({ dispatch })
  }

  dispatchPostStripeSuccessActions({ dispatch })
  const query =
    response.status === 'success_with_reminder' ? { reminder: true } : {}

  return dispatch(
    push(
      urlFor.subscriptionSuccess({
        ...query,
        ...successPageParams,
        ...storedQuery,
      }),
    ),
  )
}

async function StripeErrorHandler(
  err: string | { error: { message: string } },
  { plan, dispatch }: { plan: PlanType | null | undefined; dispatch: Dispatch },
  isCardUsedForTrialError?: boolean,
) {
  const errorMessage =
    typeof err === 'string' ? err : err.error?.message || 'Stripe error'
  const analyticsPayload = prepareSubscriptionAnalyticsPayload(
    plan,
    errorMessage,
  )
  dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))

  const errorObject = typeof err === 'string' ? err : err.error

  dispatch({
    type: POST_STRIPE_DATA_ERROR,
    data: {
      loading: false,
      error: isCardUsedForTrialError ? null : errorObject,
    },
  })
}

export function markStripeLoadingSuccess(): StripeScriptLoadSuccessAction {
  return {
    type: STRIPE_SCRIPT_LOAD_SUCCESS,
  }
}

export function markStripeLoadingFailure(): StripeScriptLoadFailureAction {
  return {
    type: STRIPE_SCRIPT_LOAD_FAILURE,
  }
}

export function setStripe3DSecureError(
  payload: Stripe3DSecureErrorPayload,
): Stripe3DSecureErrorAction {
  return {
    type: STRIPE_3D_SECURE_ERROR,
    data: payload,
  }
}

export function postStripe3DSecureData(
  payload: Stripe3DSecureDataPostPayload,
  options: Stripe3DSecurePostOptions = { specialOffer: null },
): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { specialOffer, onSuccess } = options
    const uri = `/p/api/v5/payments/stripe/secure_subscriptions`
    const { path } = payload

    const state = getState()

    const plan = specialOffer
      ? state.subscription.specialOffer[specialOffer].plan
      : findProductPlanById(state, payload.plan)

    const successPageParams: { path: string; type: string } = {
      path: path as string,
      type: specialOffer || (plan && plan.trial) ? 'trial' : 'subsc',
      ...(plan && plan.kind ? { kind: plan.kind } : {}),
    }

    const data: Stripe3DSecurePostData = {
      plan: payload.plan,
      'g-recaptcha-response': payload.token,
      source: payload.sourceId,
      return_url: urlFor.stripePaymentComplete({
        ...successPageParams,
        ...state.app.storedQuery,
      }),
    }

    dispatch({
      type: POST_STRIPE_DATA,
      data: {
        loading: true,
      },
    })

    try {
      const response = await fetchWrapper.fetch(uri, {
        state: getState(),
        method: 'post',
        data,
      })

      const { currency, duration, trial, id, system, kind, price } = plan

      const analyticsPayload = {
        billing_plan_id: id,
        currency,
        duration,
        is_trial: trial,
        payment_method: system,
        price: price / 100,
        sub_type: kind,
        billing_user_subscription_id: response.billing_user_subscription_id,
      }

      dispatch(analyticsEvent(SUBSCRIPTION_SUCCESSFUL, analyticsPayload))

      StripeResponseHandler(response, {
        plan,
        dispatch,
        successPageParams,
        onSuccess,
        storedQuery: state.app.storedQuery,
      })
    } catch (err) {
      const isCardUsedForTrialError =
        err &&
        typeof err?.error === 'object' &&
        err.error?.code === 'card_has_prior_trial' &&
        err.response?.status === 422

      if (isCardUsedForTrialError) {
        dispatch({
          type: OFFER_REGULAR_PLAN,
        })
      }
      StripeErrorHandler(err, { plan, dispatch }, isCardUsedForTrialError)
    }
  }
}

export function completeStripe3DSecureData(
  payload: Stripe3DSecureDataCompletePayload,
  planName: string,
): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/p/api/v5/payments/stripe/secure_subscriptions/complete`
    const successPageParams = { path: payload.path, type: payload.type }
    const state = getState()

    await fetchWrapper
      .fetch(uri, {
        state: getState(),
        method: 'post',
        data: payload,
      })
      .then(res => {
        const query =
          res.status === 'success_with_reminder' ? { reminder: true } : {}

        const plan = payload.campaign
          ? state.subscription.specialOffer[payload.campaign].plan
          : findProductPlanById(state, planName)

        const { currency, id, system, trial, price, kind, duration } = plan
        const analyticsPayload = {
          billing_plan_id: id,
          currency,
          duration,
          is_trial: trial,
          payment_method: system,
          price: price / 100,
          sub_type: kind,
          billing_user_subscription_id: res.billing_user_subscription_id,
        }
        dispatch(analyticsEvent(SUBSCRIPTION_SUCCESSFUL, analyticsPayload))
        serverRedirectTo(
          urlFor.subscriptionSuccess({
            ...query,
            ...state.app.storedQuery,
            ...successPageParams,
          }),
        )
      })
  }
}

export function updateSubscription(payload: UpgradeSubscriptionPayload) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { recurrentSubscriptionId, upgradeOption } = payload
    const endpoint = `/p/api/v5/payments/stripe/subscriptions/${recurrentSubscriptionId}`
    const planId = upgradeOption.plan.id
    const prorationDate = upgradeOption.proration_date
    const successPageParams = { path: payload.path, type: 'subsc' }
    const state = getState()
    try {
      const {
        redirect_url,
        billing_user_subscription_id,
      } = await fetchWrapper.fetch(endpoint, {
        state,
        method: 'put',
        data: {
          plan: planId,
          proration_date: prorationDate,
        },
      })

      if (redirect_url) {
        dispatch(externalRedirect(redirect_url))
      } else {
        const analyticsPayload = prepareAnalyticsPayloadForUpgrade(
          upgradeOption,
        )
        dispatch(
          analyticsEvent(SUBSCRIPTION_SUCCESSFUL, {
            ...analyticsPayload,
            billing_user_subscription_id,
          }),
        )
        dispatchPostStripeSuccessActions({ dispatch })
        dispatch(
          replace(
            urlFor.subscriptionSuccess({
              ...successPageParams,
              ...state.app.storedQuery,
            }),
          ),
        )
      }
    } catch (error) {
      const errorMessage =
        typeof error === 'string' ? error : error.error.message
      const analyticsPayload = prepareAnalyticsPayloadForUpgrade(
        upgradeOption,
        errorMessage,
      )
      dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))
      dispatch(showAlert('error', { message: errorMessage }))
    }
  }
}

function dispatchPostStripeSuccessActions({
  dispatch,
}: DispatchPostStripeSuccessActionsPayload): void {
  dispatch(loadUserSubscriptions())
  dispatch(loadUserAccessLevels())
  dispatch(loadProducts())
  dispatch(loadFeaturedProduct())

  dispatch({
    type: POST_STRIPE_DATA_SUCCESS,
    data: {
      loading: false,
      error: null,
    },
  })
}

export type IntentType = 'payment_intent' | 'setup_intent'

export function createPromoPaymentIntent({
  promoUuid,
}: {
  promoUuid: string
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/payments/stripe/async/deferred_subscriptions',
      types: [
        PROMO_CREATE_PAYMENT_INTENT,
        PROMO_CREATE_PAYMENT_INTENT_SUCCESS,
        PROMO_CREATE_PAYMENT_INTENT_ERROR,
      ],
      options: {
        method: 'post',
        data: { promo_uuid: promoUuid },
      },
    },
  }
}

export function createPaymentIntent({ planId }: { planId: string }): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/payments/stripe/async/subscriptions',
      types: [
        STRIPE_CREATE_PAYMENT_INTENT,
        STRIPE_CREATE_PAYMENT_INTENT_SUCCESS,
        STRIPE_CREATE_PAYMENT_INTENT_ERROR,
      ],
      options: {
        method: 'post',
        data: { plan: planId },
      },
    },
  }
}

export function createCheapTrialPaymentIntent({
  variant,
  plan_duration,
}: {
  variant?: string
  plan_duration?: string
}): ApiAction {
  const data: Record<string, string> = {
    variant: variant as string,
  }
  if (plan_duration) data.plan_duration = plan_duration as string

  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/payments/stripe/async/cheap_trials',
      // endpoint: '/p/api/v5/payments/stripe/cheap_trials',
      types: [
        CHEAP_TRIAL_CREATE_PAYMENT_INTENT,
        CHEAP_TRIAL_CREATE_PAYMENT_INTENT_SUCCESS,
        CHEAP_TRIAL_CREATE_PAYMENT_INTENT_ERROR,
      ],
      options: {
        method: 'post',
        data,
      },
    },
  }
}

export function clearPaymentIntent(): PaymentIntentClearAction {
  return { type: PAYMENT_INTENT_CLEAR }
}

export function postSpecialOfferData(
  payload: Stripe3DSecureDataPostPayload,
  options: SpecialOfferPostOptions,
): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { specialOffer, onSuccess } = options
    const uri = `/p/api/v5/payments/${specialOffer}/subscribe`
    const { path } = payload
    const state = getState()
    const plan = state.subscription.specialOffer[specialOffer].plan

    const successPageParams: {
      path: string
      email: string
      campaign: string
      type: string
    } = {
      path: path as string,
      type: 'subsc',
      campaign: specialOffer,
      email: payload.email as string,
    }

    const data: Stripe3DSecurePostData = {
      plan: payload.plan,
      email: payload.email as string,
      source: payload.sourceId,
      return_url: urlFor.stripePaymentComplete({
        ...successPageParams,
        ...state.app.storedQuery,
      }),
    }

    dispatch({
      type: POST_STRIPE_DATA,
      data: {
        loading: true,
      },
    })

    try {
      const response = await fetchWrapper.fetch(uri, {
        state,
        method: 'post',
        data,
      })
      StripeResponseHandler(response, {
        plan,
        dispatch,
        successPageParams,
        onSuccess,
        storedQuery: state.app.storedQuery,
      })
    } catch (err) {
      StripeErrorHandler(err, { plan, dispatch })
    }
  }
}

export function completeSpecialOfferData(
  payload: Stripe3DSecureDataCompletePayload,
  specialOffer: string,
): ThunkAction {
  return async (dispatch: Dispatch, getState: GetState) => {
    const uri = `/p/api/v5/payments/${specialOffer}/complete`
    const state = getState()
    await fetchWrapper
      .fetch(uri, {
        state,
        method: 'post',
        data: payload,
      })
      .then(() => {
        serverRedirectTo(
          urlFor.specialOfferSuccess({
            campaign: specialOffer,
            query: state.app.storedQuery,
          }),
        )
      })
  }
}

const initialState = {
  loading: false,
  error: null,
  scriptLoaded: false,
  scriptLoadingFailed: false,
  threeDSecureError: '',
  offerRegularPlan: false,
  paymentIntent: { complete: false },
}

export default function stripeReducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case POST_STRIPE_DATA:
      return {
        ...state,
        ...action.data,
      }

    case POST_STRIPE_DATA_SUCCESS:
      return {
        ...state,
        ...action.data,
      }

    case STRIPE_SCRIPT_LOAD_FAILURE:
      return {
        ...state,
        scriptLoadingFailed: true,
      }

    case POST_STRIPE_DATA_ERROR:
      return {
        ...state,
        ...action.data,
      }

    case STRIPE_SCRIPT_LOAD_SUCCESS:
      return {
        ...state,
        scriptLoaded: true,
      }

    case CLEAR_STRIPE_ERROR:
      return {
        ...state,
        error: null,
      }

    case STRIPE_3D_SECURE_ERROR:
      return {
        ...state,
        ...action.data,
      }

    case OFFER_REGULAR_PLAN:
      return {
        ...state,
        offerRegularPlan: true,
      }
    case PROMO_CREATE_PAYMENT_INTENT_SUCCESS:
    case STRIPE_CREATE_PAYMENT_INTENT_SUCCESS:
    case CHEAP_TRIAL_CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          complete: true,
          kind: action.intent_type,
          returnUrl: action.return_url,
          key: action.client_secret,
        },
      }
    case PROMO_CREATE_PAYMENT_INTENT_ERROR:
    case STRIPE_CREATE_PAYMENT_INTENT_ERROR:
    case CHEAP_TRIAL_CREATE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        paymentIntent: { complete: false, error: action.error },
      }
    case PAYMENT_INTENT_CLEAR:
      return {
        ...state,
        paymentIntent: { complete: false },
      }

    default:
      return state
  }
}

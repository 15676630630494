import dedent from 'dedent'

const formatErrMsg = (err, id?: number) =>
  `${id != null ? ` ${id + 1}: ` : ''}${[err.status || err.code, err.message]
    .filter(Boolean)
    .join(' ')}`

export const getErrMsg = data => {
  if (data.errors) {
    return (
      Array.isArray(data.errors) && data.errors.map(formatErrMsg).join('; ')
    )
  }

  if (data.error) {
    return formatErrMsg(data.error)
  }

  return 'no oauth_token'
}

export const getAbsentProviderMsg = (name?: string, providers?: string[]) => {
  if (!name || !providers) return 'provider isn’t supported'

  return dedent`
    No params for social provider ${name} found,
    list of available providers: ${providers.join(', ')}
  `
}

export const handleAbsentProvider = res =>
  res.status(404).send(getAbsentProviderMsg())

export const getErrMsgWithStep = (error, step) =>
  `${error instanceof Error ? error.message : error} | step: ${step}`

import React, { Component } from 'react'
import ProgressiveCover, {
  SetSizeBy,
} from 'client/shared/blocks/progressive-cover'
import { authorsAsString } from 'client/bookmate/helpers/author/authorsAsString'
import cn from 'classnames'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import {
  getCoverOptions,
  getSlidingCoverOptions,
} from 'client/shared/helpers/book-cover-helpers'
import FallbackCover from 'client/shared/blocks/fallback-cover'

import { BookProps } from 'client/shared/types/book'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { SerialProps } from 'client/shared/types/serial'
import { Size } from 'client/shared/types/cover'
import { Size as CoverSize } from 'client/shared/types/shelf'

import './book-cover.styl'
import { Trans } from '@lingui/react'

const HEIGHTS = {
  '56': 78,
  '88': 124,
  '120': 176,
  '176': 247,
  '208': 290,
}

type Props = {
  book: BookProps | ComicbookProps | SerialProps
  size: Size
  isInSlider?: boolean
  isLeadCover?: boolean
} & DecoratorProps

class _BookCover extends Component<Props> {
  static defaultProps = {
    size: 88,
    isInSlider: false,
  }

  getImageSrc() {
    const {
      book: { cover },
    } = this.props

    if (cover && cover.large) {
      return cover.large
    } else if (cover && cover.url) {
      return cover.url
    } else {
      return ''
    }
  }

  getAuthorsNames() {
    const {
      book: { authors },
    } = this.props

    if (typeof authors === 'string') {
      return authors
    } else {
      return authorsAsString(authors)
    }
  }

  getAlt(): string {
    const {
      book: { title },
    } = this.props

    return `${title}, ${this.getAuthorsNames()}`
  }

  render() {
    const {
      app,
      size,
      book,
      book: { cover, can_be_read },
      isLeadCover,
    } = this.props

    const sizeKind = typeof size === 'object' ? size[app.size] : size
    const wrapperWidth = this.getImageSizes(sizeKind).imgWidth
    const isGiftsPage = app.currentLocationHref.includes('gifts')

    const showAsUnavailable =
      book.hasOwnProperty('can_be_read') &&
      !can_be_read &&
      !isLeadCover &&
      !isGiftsPage

    return (
      <div
        className={cn(`book-cover book-cover_${sizeKind}`, {
          'book-cover_unavailable': showAsUnavailable,
        })}
      >
        {showAsUnavailable && (
          <span
            style={{ width: `${wrapperWidth}px` }}
            className="book__unavailable_wrapper"
          >
            <span className="book__unavailable_text">
              <Trans id="badge.private" />
            </span>
          </span>
        )}
        {(cover && cover.large) || (cover && cover.url)
          ? this.renderImg(sizeKind)
          : this.renderFallBack()}
      </div>
    )
  }

  getImageSizes(blockWidth = 88) {
    const {
      book: { cover },
      isInSlider,
    } = this.props

    const blockHeight = HEIGHTS[blockWidth]
    const { imgHeight, imgWidth, dynamicImageSizingSide } = isInSlider
      ? getSlidingCoverOptions(cover, blockWidth, blockHeight)
      : getCoverOptions(cover, blockWidth, blockHeight)

    return { imgHeight, imgWidth, dynamicImageSizingSide }
  }

  renderImg(blockWidth = 88) {
    const {
      book: { cover },
    } = this.props

    const { imgHeight, imgWidth, dynamicImageSizingSide } = this.getImageSizes(
      blockWidth,
    )

    return (
      <div className="book-cover__wrapper">
        <ProgressiveCover
          src={this.getImageSrc()}
          placeholder={cover.placeholder || ''}
          width={imgWidth as CoverSize}
          height={imgHeight as CoverSize}
          alt={this.getAlt()}
          setSizeBy={
            [120].includes(blockWidth)
              ? (dynamicImageSizingSide as SetSizeBy)
              : null
          }
        />
      </div>
    )
  }

  renderFallBack() {
    const {
      app,
      size,
      book: { title, authors },
    } = this.props

    return (
      <FallbackCover
        title={title}
        authors={authors}
        size={typeof size === 'object' ? size[app.size] : size}
      />
    )
  }
}

export const BookCover = uiBox(_BookCover)

import React, { Component, createElement } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import Linka from 'client/shared/blocks/linka'
import TextTruncate from 'client/shared/blocks/text-truncate'

import './shelf.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import FollowButtonBox from 'client/bookmate/boxes/follow-button-box'
import { UserAvatar } from 'client/shared/blocks/user-avatar'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import MetaCounters, {
  CounterItem,
  InfoItem,
} from 'client/bookmate/blocks/meta-counters'
import PlusBox from 'client/shared/boxes/plus-box'
import ProgressiveCover from 'client/shared/blocks/progressive-cover'

import { CreatorProps } from 'client/shared/types/creator'
import { ShelfProps, Size } from 'client/shared/types/shelf'

type Props = {
  shelf: ShelfProps
  kind: 'card' | 'grid' | 'list'
  coverSize?: Size
  titleLines: number
  showPlus: boolean
  path?: string
  id?: string
  as?: string
  onClick?: () => void
  query?: QueryParams
} & DecoratorProps &
  withI18nProps

const COVER_HEIGHTS = {
  '88': 88,
  '288': 160,
  '300': 160,
  '408': 218,
}

class Shelf extends Component<Props> {
  static defaultProps = {
    showPlus: true,
    titleLines: 2,
  }

  getCoverSize(): Size {
    const { kind, coverSize } = this.props

    if (coverSize) return coverSize

    switch (kind) {
      case 'grid':
        return {
          desktopLarge: 408,
          desktopMedium: 408,
          desktop: 300,
          tablet: 288,
          mobile: 288,
        }
      case 'card':
        return 'adaptive'
      default:
        return 88
    }
  }

  getCoverSizeKind(): Size {
    const { app } = this.props

    const coverSize = this.getCoverSize()

    return typeof coverSize === 'object' ? coverSize[app.size] : coverSize
  }

  getCounters(): CounterItem[] {
    const {
      shelf: { posts_count, followers_count },
    } = this.props

    return [
      { kind: 'books', count: posts_count },
      { kind: 'users', count: followers_count },
    ].filter(({ count }) => count > 0)
  }

  getMetaInfo(): InfoItem[] {
    const {
      shelf: { state },
      i18n,
    } = this.props

    return state === 'hidden'
      ? [
          {
            kind: 'closed',
            info: i18n._('shelf.closed'),
          },
        ]
      : []
  }

  render(): JSX.Element {
    const {
      shelf: { title, creator, uuid },
      kind,
      titleLines,
      showPlus,
      path,
      id,
      onClick,
      query,
      as = 'div',
    } = this.props

    const counters = this.getCounters()
    const metaInfo = this.getMetaInfo()
    const ShelfEl = createElement(as, {
      onClick,
      id,
      className: `shelf shelf_${kind} shelf_${this.getCoverSizeKind()}`,
      children: (
        <>
          <div className="shelf__wrapper">
            <div className="shelf__cover">{this.renderCover()}</div>
            <div className="shelf__description">
              {creator && !['card', 'grid'].includes(kind) && (
                <div className="shelf__creator">{this.getCreator(creator)}</div>
              )}
              <Linka
                title={title}
                path={path || urlFor.shelf(uuid, query)}
                className={`shelf__title ${
                  !/\s/.test(title.trim()) ? 'shelf__title_without-spaces' : ''
                }`}
              >
                <TextTruncate
                  lines={titleLines}
                  text={title}
                  textType="title"
                />
              </Linka>
              {kind === 'list' &&
                (Boolean(counters.length) || Boolean(metaInfo.length)) && (
                  <MetaCounters counters={counters} info={metaInfo} />
                )}
            </div>
            {kind === 'list' && showPlus && this.renderPlus()}
          </div>
          {kind === 'grid' && this.renderFooter()}
          {kind === 'card' && this.renderButtonFooter()}
        </>
      ),
    })

    return ShelfEl
  }

  renderCover(): JSX.Element {
    const {
      i18n,
      shelf: {
        cover: { large, small },
        title,
        creator,
      },
    } = this.props

    const sizeKind = this.getCoverSizeKind()

    const alt = `${i18n._('shelf.shelf_alt', { title: title.trim() })}, ${
      creator.name || creator.login
    }`

    return (
      <ProgressiveCover
        src={sizeKind === 88 ? small : large}
        width={sizeKind === 'adaptive' ? '100%' : sizeKind}
        height={
          sizeKind === 'adaptive' ? 'auto' : COVER_HEIGHTS[String(sizeKind)]
        }
        alt={alt}
        setSizeBy={sizeKind === 88 ? 'width' : null}
      />
    )
  }

  getCreator(creator: CreatorProps): string {
    return creator.name || creator.login
  }

  renderButtonFooter(): JSX.Element | null {
    const {
      shelf,
      shelf: { following },
    } = this.props

    return following ? null : (
      <div className="shelf__button-footer">
        {<FollowButtonBox shelf={shelf} />}
      </div>
    )
  }

  renderPlus(): JSX.Element {
    return <PlusBox shelf={this.props.shelf} />
  }

  renderFooter(): JSX.Element {
    const {
      query,
      shelf: { creator },
    } = this.props

    const counters = this.getCounters()
    const metaInfo = this.getMetaInfo()

    return (
      <div className="shelf__footer">
        <Linka
          path={urlFor.user(creator.login, query)}
          className="shelf__useravatar"
          loginWall
          pseudo
          asBlock
        >
          <UserAvatar user={creator} size={24} />
        </Linka>
        <Linka
          path={urlFor.user(creator.login, query)}
          className="shelf__username"
          loginWall
          pseudo
        >
          {creator.name || creator.login}
        </Linka>
        {(Boolean(counters.length) || Boolean(metaInfo.length)) && (
          <div className="shelf__meta-counters">
            <MetaCounters counters={counters} info={metaInfo} />
          </div>
        )}
      </div>
    )
  }
}

export const ShelfUnwrapped = Shelf

const wrappers = compose(withI18n({ update: true }), uiBox)

export default wrappers(Shelf)

import { cacheAction } from 'shared/middlewares/cache-middleware'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  seriesSchema,
  usersSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import {
  SERIES_FOLLOW_SUCCESS,
  SERIES_UNFOLLOW_SUCCESS,
} from 'client/bookmate/reducers/series-list-reducer'
import { normalizeSeriesParts } from 'client/bookmate/helpers/series-helpers'

import { SeriesFullPartProps } from 'client/shared/types/series'
import { UserShort } from 'client/shared/types/user'
import { BookSerialProps } from 'client/shared/types/resource'

const LOAD_SERIES = `LOAD_SERIES`
const LOAD_SERIES_ERROR = `LOAD_SERIES_ERROR`
const LOAD_SERIES_SUCCESS = `LOAD_SERIES_SUCCESS`

const LOAD_SERIES_PARTS = `LOAD_SERIES_PARTS`
const LOAD_SERIES_PARTS_ERROR = `LOAD_SERIES_PARTS_ERROR`
const LOAD_SERIES_PARTS_SUCCESS = `LOAD_SERIES_PARTS_SUCCESS`
const RESET_SERIES_PARTS = `RESET_SERIES_PARTS`

const LOAD_SERIES_FOLLOWERS = `LOAD_SERIES_FOLLOWERS`
const LOAD_SERIES_FOLLOWERS_SUCCESS = `LOAD_SERIES_FOLLOWERS_SUCCESS`
const SERIES_CHANGED = `SERIES_CHANGED`

const LOAD_SERIES_VARIANTS = `LOAD_SERIES_VARIANTS`
const LOAD_SERIES_VARIANTS_ERROR = `LOAD_SERIES_VARIANTS_ERROR`
const LOAD_SERIES_VARIANTS_SUCCESS = `LOAD_SERIES_VARIANTS_SUCCESS`

export type State = {
  uuid: string
  parts: SeriesFullPartProps[]
  followers: UserShort[]
  loading: boolean
  pages: {
    followers: number | null
    parts: number | null
  }
  books: BookSerialProps[]
}

type LoadAction = {
  type: typeof LOAD_SERIES
}

type LoadErrorAction = {
  type: typeof LOAD_SERIES_ERROR
}

type LoadSuccessAction = {
  type: typeof LOAD_SERIES_SUCCESS
  result: string
}

type ResetPartsAction = {
  type: typeof RESET_SERIES_PARTS
}

type LoadSeriesPartsSuccessAction = {
  type: typeof LOAD_SERIES_PARTS_SUCCESS
  result: string[]
  append: boolean
  nextPage: number | null
}

type LoadSeriesFollowersSuccessAction = {
  type: typeof LOAD_SERIES_FOLLOWERS_SUCCESS
  result: string[]
  append: boolean
  nextPage: number | null
}

type LoadSeriesVariantsSuccessAction = {
  type: typeof LOAD_SERIES_VARIANTS_SUCCESS
  books: BookSerialProps[]
}

type Action =
  | LoadAction
  | LoadSuccessAction
  | LoadErrorAction
  | ResetPartsAction
  | LoadSeriesPartsSuccessAction
  | LoadSeriesFollowersSuccessAction
  | LoadSeriesVariantsSuccessAction

export function load(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}`,
      schema: seriesSchema,
      responseKey: 'series',
      types: [LOAD_SERIES, LOAD_SERIES_SUCCESS, LOAD_SERIES_ERROR],
    },
  }
}

export function resetParts(): ResetPartsAction {
  return {
    type: RESET_SERIES_PARTS,
  }
}

export const loadParts = cacheAction(function ({
  uuid,
  p = 1,
  pp = 20,
  order = 'asc',
  append = false,
}) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/parts`,
      options: {
        data: {
          page: p,
          per_page: pp,
          order,
        },
      },
      normalize: ({ parts }) => {
        const { result, entities } = normalizeSeriesParts(parts)

        return {
          result,
          entities,
          append,
          nextPage: result.length ? p + 1 : null,
        }
      },
      types: [
        LOAD_SERIES_PARTS,
        LOAD_SERIES_PARTS_SUCCESS,
        LOAD_SERIES_PARTS_ERROR,
      ],
    },
  }
},
LOAD_SERIES_PARTS_SUCCESS)

export const loadFollowers = cacheAction(
  function ({ uuid, p = 1, pp = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/series/${uuid}/followers`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [LOAD_SERIES_FOLLOWERS, LOAD_SERIES_FOLLOWERS_SUCCESS],
      },
    }
  },
  LOAD_SERIES_FOLLOWERS_SUCCESS,
  [SERIES_CHANGED],
)

export function loadVariants(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/series/${uuid}/variants/book`,
      types: [
        LOAD_SERIES_VARIANTS,
        LOAD_SERIES_VARIANTS_SUCCESS,
        LOAD_SERIES_VARIANTS_ERROR,
      ],
    },
  }
}

const initialState: State = {
  uuid: '',
  loading: false,
  parts: [],
  followers: [],
  pages: {
    followers: 1,
    parts: 1,
  },
  variants: [],
}

export default function series(state: State = initialState, action: Action) {
  switch (action.type) {
    case LOAD_SERIES:
      return {
        ...initialState,
        loading: true,
      }

    case LOAD_SERIES_SUCCESS:
      return {
        ...state,
        uuid: action.result,
        loading: false,
      }

    case LOAD_SERIES_PARTS_SUCCESS:
      return {
        ...state,
        parts: action.append
          ? [...state.parts, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          parts: action.nextPage,
        },
      }

    case SERIES_FOLLOW_SUCCESS:
    case SERIES_UNFOLLOW_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOAD_SERIES_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followers: action.append
          ? [...state.followers, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          followers: action.nextPage,
        },
      }

    case LOAD_SERIES_VARIANTS_SUCCESS:
      return {
        ...state,
        books: action.books,
      }

    case RESET_SERIES_PARTS:
      return {
        ...state,
        parts: initialState.parts,
        pages: {
          ...state.pages,
          parts: initialState.pages.parts,
        },
      }

    default:
      return state
  }
}

import { schema, normalize } from 'normalizr'
import { createAction, createReducer } from '@reduxjs/toolkit'
import noop from 'lodash/noop'

import { cacheAction } from 'shared/middlewares/cache-middleware'

import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { encodeParams } from 'client/shared/helpers/url-helpers'

import { CALL_API, CALL_API_ERROR } from 'shared/middlewares/api-middleware'

import {
  createLibraryCard,
  updateLibraryCard,
} from 'client/bookmate/reducers/library-cards-reducer'

import { loadLibraryCardAction } from 'client/bookmate/reducers/loadLibraryCardAction'

import {
  quoteSchema,
  QUOTE_LIKED,
  QUOTE_REMOVED,
} from 'client/bookmate/reducers/quotes-reducer'

import {
  impressionsSchema,
  addIdToImpressions,
  prepareImpressionsFromAPIResponse,
  IMPRESSION_ADDED,
  IMPRESSION_REMOVED,
  IMPRESSION_CHANGED,
  IMPRESSION_LIKED,
  IMPRESSION_CURRENT_USER_LOADED,
  IMPRESSION_CURRENT_USER_CLEAN,
} from 'client/bookmate/reducers/impressions-reducer'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
} from 'client/bookmate/reducers/comments-reducer'

import { constants } from 'client/bookmate/constants'

import {
  shelfSchema,
  booksSchema,
  bookSchema,
  usersSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import { createRedirectForResource } from 'shared/tools/url-helper'
import { getBookById } from 'client/bookmate/selectors/book-selector'

const getSerialRedirectUrl = createRedirectForResource('serial')

const serialLoad = createAction('SERIAL_LOAD')
const serialLoadSuccess = createAction('SERIAL_LOAD_SUCCESS')
const serialLoadError = createAction('SERIAL_LOAD_ERROR')
export const serialChanged = createAction('SERIAL_CHANGED')

const serialPublisherResourcesLoad = createAction(
  'SERIAL_PUBLISHER_RESOURCES_LOAD',
)
const serialPublisherResourcesSuccess = createAction(
  'SERIAL_PUBLISHER_RESOURCES_LOAD_SUCCESS',
)

const serialReadersLoad = createAction('SERIAL_READERS_LOAD')
const serialReadersLoadSuccess = createAction('SERIAL_READERS_LOAD_SUCCESS')

const serialShelvesLoad = createAction('SERIAL_SHELVES_LOAD')
const serialShelvesLoadSuccess = createAction('SERIAL_SHELVES_LOAD_SUCCESS')

const serialRelatedLoad = createAction('SERIAL_RELATED_LOAD')
const serialRelatedLoadSuccess = createAction('SERIAL_RELATED_LOAD_SUCCESS')

const serialQuotesLoad = createAction('SERIAL_QUOTES_LOAD')
const serialQuotesLoadSuccess = createAction('SERIAL_QUOTES_LOAD_SUCCESS')

const serialImpressionsLoad = createAction('SERIAL_IMPRESSIONS_LOAD')
const serialImpressionsLoadSuccess = createAction(
  'SERIAL_IMPRESSIONS_LOAD_SUCCESS',
)

const serialOtherVersionsLoad = createAction('SERIAL_OTHER_VERSIONS_LOAD')
const serialOtherVersionsLoadSuccess = createAction(
  'SERIAL_OTHER_VERSIONS_LOAD_SUCCESS',
)

const serialRemoveFromWishlist = createAction('SERIAL_REMOVED_FROM_WISHLIST')
export const serialRemoveFromWishlistSuccess = createAction(
  'SERIAL_REMOVED_FROM_WISHLIST_SUCCESS',
)

const serialAddToWishlist = createAction('SERIAL_ADD_TO_WISHLIST')
const serialAddToWishlistSuccess = createAction(
  'SERIAL_ADD_TO_WISHLIST_SUCCESS',
)

const serialEmotionRatingLoad = createAction('SERIAL_EMOTION_RATING_LOAD')
const serialEmotionRatingLoadSuccess = createAction(
  'SERIAL_EMOTION_RATING_LOAD_SUCCESS',
)

const serialEpisodeslLoad = createAction('SERIAL_EPISODES_LOAD')
const serialEpisodeslLoadSuccess = createAction('SERIAL_EPISODES_LOAD_SUCCESS')

const serialStateLoad = createAction('SERIAL_STATE_LOAD')
const serialStateLoadSuccess = createAction('SERIAL_STATE_LOAD_SUCCESS')

const seenMarkPending = createAction('SERIAL_SEEN_MARK_PENDING')
const seenMarkCompleted = createAction('SERIAL_SEEN_MARK_COMPLETED')

export const clearSerialEpisodes = createAction('CLEAR_SERIAL_EPISODES')

const serialLoadSeries = createAction('SERIAL_LOAD_SERIES')
const serialLoadSeriesSuccess = createAction('SERIAL_LOAD_SERIES_SUCCESS')

export function loadSerial(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}`,
      normalize: async ({ book: serial }, { dispatch, getState }) => {
        const correctSerialId = serial.uuid
        const currentPath = getState().app.url

        if (uuid !== correctSerialId) {
          serverRedirectTo(
            getSerialRedirectUrl(correctSerialId, currentPath),
            301,
          )
        } else {
          const { result, entities } = normalize(serial, bookSchema)

          if (serial.library_card_uuid) {
            await dispatch(loadLibraryCardAction(serial.library_card_uuid))
          }

          return {
            result,
            entities,
            library_card_uuid: serial.library_card_uuid,
            in_library: serial.in_library,
          }
        }
      },
      types: [serialLoad, serialLoadSuccess, serialLoadError],
    },
  }
}
export function loadEpisodes({
  uuid,
  page = 1,
  per_page = 20,
  order,
  append = true,
  filter = 'all',
}) {
  const query = encodeParams({ page, per_page, order })
  const endpoint = filter === 'all' ? '' : '/unfinished'

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/episodes${endpoint}?${query}`,
      normalize: (response, { getState }) => {
        const { result, entities } = normalize(response.episodes, booksSchema)
        const serial = getBookById(getState(), uuid)
        serial.episodes = result
        return {
          result,
          append,
          filter,
          nextPage: result.length ? page + 1 : null,
          entities: {
            ...entities,
            books: {
              ...normalize(serial, bookSchema).entities.books,
              ...entities.books,
            },
          },
        }
      },
      responseKey: 'episodes',
      types: [serialEpisodeslLoad, serialEpisodeslLoadSuccess, CALL_API_ERROR],
    },
  }
}

export const loadQuotes = cacheAction(
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    const data = {
      page: p,
      per_page: pp,
      top: true,
    }

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/quotes`,
        options: { data },
        schema: new schema.Array(quoteSchema),
        responseKey: 'quotes',
        modifyResponse: response => ({
          updateField: index ? 'quotesIndex' : 'quotes',
          index,
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [serialQuotesLoad, serialQuotesLoadSuccess],
      },
    }
  },
  serialQuotesLoadSuccess,
  [QUOTE_LIKED, QUOTE_REMOVED, COMMENT_ADDED, COMMENT_REMOVED],
)

export function loadEmotionRating(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/emotion_ratings`,
      types: [serialEmotionRatingLoad, serialEmotionRatingLoadSuccess],
    },
  }
}

export function loadPublisherResources(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/api/v5/serials/${uuid}/publisher_resources`,
      types: [serialPublisherResourcesLoad, serialPublisherResourcesSuccess],
      ignoreError: true,
    },
  }
}

export const loadImpressions = cacheAction(
  function ({ uuid, p = 1, pp = 20, index = false, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/impressions`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionsFromAPIResponse(response.impressions),
            impressionsSchema,
          )

          return {
            result,
            entities: {
              ...entities,
              impressions: addIdToImpressions(
                entities.impressions,
                uuid,
                'book',
              ),
            },
            updateField: index ? 'impressionsIndex' : 'impressions',
            index,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [serialImpressionsLoad, serialImpressionsLoadSuccess],
      },
    }
  },
  serialImpressionsLoadSuccess,
  [
    IMPRESSION_ADDED,
    IMPRESSION_CHANGED,
    IMPRESSION_REMOVED,
    IMPRESSION_LIKED,
    COMMENT_ADDED,
    COMMENT_REMOVED,
  ],
)

export const loadReaders = cacheAction(
  function ({ uuid, p = 1, pp = 15, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/readers`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [serialReadersLoad, serialReadersLoadSuccess],
      },
    }
  },
  serialReadersLoadSuccess,
  [serialChanged],
)

export function loadSerialSeries(uuid: string): Record<string, any> {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/variants/series`,
      responseKey: 'series',
      types: [serialLoadSeries, serialLoadSeriesSuccess],
    },
  }
}

export const loadShelves = cacheAction(function ({
  uuid,
  p = 1,
  pp = 20,
  append = false,
}) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/bookshelves`,
      options: {
        data: {
          page: p,
          per_page: pp,
        },
      },
      schema: new schema.Array(shelfSchema),
      responseKey: 'bookshelves',
      modifyResponse: response => ({
        append,
        nextPage: response.result.length ? p + 1 : null,
      }),
      types: [serialShelvesLoad, serialShelvesLoadSuccess],
    },
  }
},
serialShelvesLoadSuccess)

export const loadRelated = cacheAction(
  function ({
    uuid,
    p = 1,
    pp = 20,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/books/${uuid}/related`,
        options: {
          data: {
            page: p,
            per_page: pp,
            order_by,
            order_direction,
          },
        },
        schema: booksSchema,
        responseKey: 'books',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [serialRelatedLoad, serialRelatedLoadSuccess],
      },
    }
  },
  serialRelatedLoadSuccess,
  [constants.BOOK_CHANGED],
)

export function readInPrivate(serial, libraryCardUuid) {
  const libraryCardState = {
    uuid: libraryCardUuid ? libraryCardUuid : serial.library_card_uuid,
    public: false,
  }

  if (serial.in_library) {
    return updateLibraryCard(libraryCardState)
  } else {
    return createLibraryCard(libraryCardState, serial)
  }
}

export function markAsRead(serial, libraryCardUuid) {
  const libraryCardState = {
    uuid: libraryCardUuid ? libraryCardUuid : serial.library_card_uuid,
    state: 'finished',
  }

  if (serial.in_library) {
    return updateLibraryCard(libraryCardState)
  } else {
    return createLibraryCard(libraryCardState, serial)
  }
}

export function removeFromWishlist(serial) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/wishlisted_books/${serial.uuid}`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      modifyResponse: () => ({
        uuid: serial.uuid,
      }),
      types: [serialRemoveFromWishlist, serialRemoveFromWishlistSuccess],
    },
  }
}

export function loadSerialState(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/state`,
      normalize: (state, { getState }) => {
        const serial = getBookById(getState(), uuid)
        serial.serialState = state
        const { entities } = normalize(serial, bookSchema)
        return {
          state,
          entities,
        }
      },
      types: [serialStateLoad, serialStateLoadSuccess],
    },
  }
}

export function markSerialAsSeen(uuid: string) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}/mark_as_seen`,
      options: {
        method: 'put',
      },
      types: [seenMarkPending, seenMarkCompleted, CALL_API_ERROR],
    },
  }
}

const initialState = {
  loading: true,
  uuid: '',
  in_wishlist: undefined,
  library_card_uuid: '',
  episodes: {
    all: [],
    allEpisodesPage: 1,
    unfinished: [],
    unfinishedEpisodesPage: 1,
  },
  impressionsIndex: [],
  impressions: [],
  quotesIndex: [],
  quotes: [],
  shelves: [],
  related: [],
  otherEpisodes: [],
  otherVersions: {
    serial: [],
  },
  readers: [],
  pages: {
    impressions: 1,
    quotes: 1,
    related: 1,
    readers: 1,
    shelves: 1,
    otherEpisodes: 1,
  },
  emotionRating: [],
  currentUserImpressionUuid: null,
  state: {
    completed: false,
    finished: false,
  },
  publisherResources: [],
  series: [],
}

export const SERIAL = 'serial'

const serial = createReducer(initialState, {
  [serialLoad]: state => {
    state.loading = true
  },
  [serialLoadSuccess]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      library_card_uuid: payload.library_card_uuid,
      uuid: payload.result,
      error: null,
    }
  },
  [serialLoadError]: (state, { payload }) => {
    return {
      ...state,
      loading: false,
      uuid: '',
      error: payload.error,
    }
  },
  [serialEpisodeslLoad]: noop,
  [serialEpisodeslLoadSuccess]: (
    state,
    { payload: { result, append, nextPage, filter } },
  ) => {
    return {
      ...state,
      episodes: {
        ...state.episodes,
        [filter]: append ? [...state.episodes[filter], ...result] : result,
        [`${filter}EpisodesPage`]: nextPage,
      },
    }
  },
  [serialReadersLoad]: noop,
  [serialReadersLoadSuccess]: (state, { payload }) => {
    return {
      ...state,
      readers: payload.append
        ? [...state.readers, ...payload.result]
        : payload.result,
      pages: {
        ...state.pages,
        readers: payload.nextPage,
      },
    }
  },
  [serialShelvesLoad]: noop,
  [serialShelvesLoadSuccess]: (state, { payload }) => {
    return {
      ...state,
      shelves: payload.append
        ? [...state.shelves, ...payload.result]
        : payload.result,
      pages: {
        ...state.pages,
        shelves: payload.nextPage,
      },
    }
  },
  [serialRelatedLoad]: noop,
  [serialRelatedLoadSuccess]: (state, { payload }) => ({
    ...state,
    related: payload.append
      ? [...state.related, ...payload.result]
      : payload.result,
    pages: {
      ...state.pages,
      related: payload.nextPage,
    },
  }),
  [serialImpressionsLoad]: noop,
  [serialImpressionsLoadSuccess]: updateFields,
  [serialQuotesLoad]: noop,
  [serialQuotesLoadSuccess]: updateFields,
  [QUOTE_REMOVED]: (state, action) => {
    return {
      ...state,
      quotesIndex: state.quotesIndex.filter(uuid => uuid !== action.uuid),
      quotes: state.quotes.filter(uuid => uuid !== action.uuid),
    }
  },
  [IMPRESSION_REMOVED]: (state, action) => {
    if (action.resourceType === 'serial') {
      state.impressionsIndex = state.impressionsIndex.filter(
        uuid => uuid !== action.uuid,
      )
      state.impressions = state.impressions.filter(uuid => uuid !== action.uuid)
    }
  },
  [serialChanged]: (state, { payload }) => {
    if (payload.serial.uuid === state.uuid) {
      state.library_card_uuid = payload.serial.library_card_uuid
    }
  },
  [serialEmotionRatingLoad]: noop,
  [serialEmotionRatingLoadSuccess]: (state, { payload }) => {
    state.emotionRating = payload.emotion_ratings
  },
  [serialPublisherResourcesLoad]: noop,
  [serialPublisherResourcesSuccess]: (state, { payload }) => {
    state.publisherResources = payload.books
  },
  [IMPRESSION_CURRENT_USER_LOADED]: (state, action) => {
    const { resourceUuid, resourceType, uuid } = action.data

    if (resourceType === 'serial' && resourceUuid === state.uuid) {
      state.currentUserImpressionUuid = uuid
    }
  },
  [IMPRESSION_CURRENT_USER_CLEAN]: state => {
    state.currentUserImpressionUuid = null
  },
  [serialOtherVersionsLoad]: noop,
  [serialOtherVersionsLoadSuccess]: noop,
  [serialRemoveFromWishlist]: noop,
  [serialAddToWishlist]: noop,
  [serialAddToWishlistSuccess]: noop,
  [serialStateLoad]: noop,
  [serialStateLoadSuccess]: (state, { payload }) => {
    state.serialState = payload.state
  },
  [seenMarkPending]: noop,
  [seenMarkCompleted]: noop,
  [clearSerialEpisodes]: state => {
    state.episodes = initialState.episodes
  },
  [serialLoadSeries]: noop,
  [serialLoadSeriesSuccess]: (state, { payload }) => {
    state.series = Object.values(payload)
  },
})

function updateFields(state, { payload }) {
  const { updateField, result, index, append } = payload

  return {
    ...state,
    [updateField]:
      index || !append ? result : [...state[updateField], ...result],
    pages: {
      ...state.pages,
      [updateField]: payload.nextPage,
    },
  }
}

export default serial

import { denormalize } from 'normalizr'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import compact from 'lodash/compact'
import { createSelector } from '@reduxjs/toolkit'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  shelfSchema,
  shelvesSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import {
  POST_ADDED,
  POST_REMOVED,
} from 'client/bookmate/reducers/posts-reducer'

import {
  analyticsEvent,
  SHELF_FOLLOW_ANALYTICS,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'

import { checkEntities } from 'client/bookmate/helpers/entities-helper'
import { cacheAction } from 'shared/middlewares/cache-middleware/cache-middleware'
import { DK_SHELF_UUID, DK_SUMMER_SHELF_UUID } from '../boxes/follow-button-box'
import { showAlert } from 'client/shared/reducers/alert-reducer'

export const SHELVES_ALL_LOAD = 'SHELVES_ALL_LOAD'
export const SHELVES_ALL_LOAD_SUCCESS = 'SHELVES_ALL_LOAD_SUCCESS'
export const SHELVES_ALL_LOAD_ERROR = 'SHELVES_ALL_LOAD_ERROR'
export const SHELF_FOLLOW = 'SHELF_FOLLOW'
export const SHELF_FOLLOW_SUCCESS = 'SHELF_FOLLOW_SUCCESS'
export const SHELF_UNFOLLOW = 'SHELF_UNFOLLOW'
export const SHELF_UNFOLLOW_SUCCESS = 'SHELF_UNFOLLOW_SUCCESS'
export const SHELF_ADDED = 'SHELF_ADDED'
export const SHELF_CHANGED = 'SHELF_CHANGED'
const SHELF_REMOVE = 'SHELF_REMOVE'
const SHELF_REMOVE_SUCCESS = 'SHELF_REMOVE_SUCCESS'
const SHELVES_TOPICS_LOAD = 'SHELVES_TOPICS_LOAD'
const SHELVES_TOPICS_LOAD_SUCCESS = 'SHELVES_TOPICS_LOAD_SUCCESS'
const SHELVES_CLEAR = 'SHELVES_CLEAR'
const SHELVES_GET_USER_TABS = 'SHELVES_GET_USER_TABS'
const SHELVES_GET_USER_TABS_SUCCESS = 'SHELVES_GET_USER_TABS_SUCCESS'
const MOBILE_NAV_POPUP_HIDE = 'MOBILE_NAV_POPUP_HIDE'
const MOBILE_NAV_POPUP_SHOW = 'MOBILE_NAV_POPUP_SHOW'

const entitiesSelector = state => state.entities
const idSelector = (_state, id) => id
const idsSelector = (_state, ids) => ids

export const getShelvesByIds = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => compact(denormalize(ids, shelvesSchema, entities)),
)

export const getShelfById = createSelector(
  idSelector,
  entitiesSelector,
  (id, entities) => denormalize(id, shelfSchema, entities) || {},
)

const initialState = {
  topics: [],
  objects: [],
  tabs: {},
  mobileNavShown: false,
}

export default function shelves(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'shelves')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }

  switch (action.type) {
    case POST_ADDED:
      return {
        ...state,
        [action.shelfUuid]: {
          ...state[action.shelfUuid],
          posts_count: state[action.shelfUuid].posts_count + 1,
        },
      }

    case POST_REMOVED: {
      let postsCount = state[action.shelfUuid].posts_count
      if (action.resourceType === 'book') {
        postsCount--
      }
      return {
        ...state,
        [action.shelfUuid]: {
          ...state[action.shelfUuid],
          posts_count: postsCount,
        },
      }
    }

    case SHELF_FOLLOW_SUCCESS: {
      const followedShelf = state[action.uuid]

      if (!followedShelf) return state

      return {
        ...state,
        [followedShelf.uuid]: {
          ...followedShelf,
          following: true,
          followers_count: followedShelf.followers_count + 1,
        },
      }
    }

    case SHELF_UNFOLLOW_SUCCESS: {
      const unfollowedShelf = state[action.uuid]

      if (!unfollowedShelf) return state

      return {
        ...state,
        [unfollowedShelf.uuid]: {
          ...unfollowedShelf,
          following: false,
          followers_count: unfollowedShelf.followers_count - 1,
        },
      }
    }

    case SHELVES_ALL_LOAD_SUCCESS: {
      return {
        ...state,
        objects: [...state.objects, ...action.bookshelves],
        meta: action.meta || {},
        attemptedEmptyFetch: action.bookshelves.length === 0,
      }
    }

    case SHELVES_TOPICS_LOAD_SUCCESS:
      return {
        ...state,
        topics: action.topics,
      }

    case SHELVES_CLEAR:
      return {
        ...state,
        objects: [],
      }

    case SHELVES_GET_USER_TABS_SUCCESS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.topic]: Boolean(action.bookshelves.length),
        },
      }

    case MOBILE_NAV_POPUP_HIDE:
      return {
        ...state,
        mobileNavShown: false,
      }

    case MOBILE_NAV_POPUP_SHOW:
      return {
        ...state,
        mobileNavShown: true,
      }

    default:
      return state
  }
}

export function follow(
  uuid: string,
  analytics,
  onFollowSuccess = noop,
  code?: string,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}/followers`,
      options: {
        method: 'post',
      },
      modifyResponse: () => ({ uuid }),
      onSuccess: dispatch => {
        dispatch(analyticsEvent(SHELF_FOLLOW_ANALYTICS, analytics))
        onFollowSuccess()
        if ([DK_SUMMER_SHELF_UUID, DK_SHELF_UUID].includes(code as string)) {
          dispatch(
            showAlert('success', {
              message:
                'Du har allerede et aktivt abonnement. Fortsat god læselyst!',
            }),
          )
        }
      },
      types: [SHELF_FOLLOW, SHELF_FOLLOW_SUCCESS],
    },
  }
}

export function unfollow(uuid: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}/followers`,
      options: {
        method: 'delete',
      },
      modifyResponse: () => ({ uuid }),
      onSuccess: dispatch =>
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'shelf',
            object_id: uuid,
          }),
        ),
      types: [SHELF_UNFOLLOW, SHELF_UNFOLLOW_SUCCESS],
    },
  }
}

export function remove(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      onSuccess: dispatch =>
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'shelf',
            object_id: uuid,
          }),
        ),
      types: [SHELF_REMOVE, SHELF_REMOVE_SUCCESS],
    },
  }
}

function getEndpoint(topic: string, topicUuid?: string): string {
  switch (topic) {
    case 'all':
      return `/p/api/v5/bookshelves`
    case 'following':
      return `/p/api/v5/profile/bookshelves/following`
    case 'friends':
      return `/p/api/v5/profile/bookshelves/friends_following`
    default:
      return topicUuid
        ? `/p/api/v5/topics/${topicUuid}/bookshelves`
        : `/p/api/v5/bookshelves/${topic}`
  }
}

export const loadShelves = cacheAction(
  // eslint-disable-next-line func-names
  function ({ page = 1, per_page = 20, topic = '', topicUuid = '' }) {
    const lastFetchTimestamp = Date.now()
    const endpoint = getEndpoint(topic, topicUuid)

    return {
      [CALL_API]: {
        endpoint,
        options: {
          data: {
            page,
            per_page,
          },
        },
        types: [
          SHELVES_ALL_LOAD,
          SHELVES_ALL_LOAD_SUCCESS,
          SHELVES_ALL_LOAD_ERROR,
        ],
      },
      lastFetchTimestamp,
    }
  },
  SHELVES_ALL_LOAD_SUCCESS,
  '',
)

export function loadShelvesTopics(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/bookshelves/topics',
      types: [SHELVES_TOPICS_LOAD, SHELVES_TOPICS_LOAD_SUCCESS],
    },
  }
}

export function clearShelves() {
  return {
    type: SHELVES_CLEAR,
  }
}

export function hideMobileNav() {
  return {
    type: MOBILE_NAV_POPUP_HIDE,
  }
}

export function showMobileNav() {
  return {
    type: MOBILE_NAV_POPUP_SHOW,
  }
}

export const getUserTabs = cacheAction(
  // eslint-disable-next-line func-names
  function ({ page = 1, per_page = 1, topic = '' }) {
    const lastFetchTimestamp = Date.now()
    const endpoint = getEndpoint(topic)

    return {
      [CALL_API]: {
        endpoint,
        options: {
          data: {
            page,
            per_page,
          },
        },
        modifyResponse: () => ({ topic }),
        types: [SHELVES_GET_USER_TABS, SHELVES_GET_USER_TABS_SUCCESS],
      },
      lastFetchTimestamp,
    }
  },
  SHELVES_GET_USER_TABS_SUCCESS,
  '',
)

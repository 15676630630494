import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { shouldShowPreSignupEmailsField } from 'client/shared/selectors/auth-selectors'

import { updatePreSignupFields } from 'client/shared/reducers/auth-reducer'

import SignupCheckboxes from 'client/shared/blocks/signup-checkboxes'

import { PreSignupFields } from 'client/shared/reducers/auth-reducer'
import { State } from 'shared/types/redux'
import { QueryParams } from 'shared/tools/url-helper'

type Props = {
  acceptTermsOfService: boolean
  isOfLegalAge: boolean
  agreeToReceiveEmails: boolean
  showEmailsCheckbox: boolean
  updatePreSignupFields: (arg: PreSignupFields) => void
  query?: QueryParams
}

class SignupCheckboxesBox extends PureComponent<Props> {
  updatePreSignupFields = (payload: {
    acceptTermsOfService: boolean
    isOfLegalAge: boolean
    agreeToReceiveEmails: boolean
  }) => {
    this.props.updatePreSignupFields(payload)
  }

  render() {
    return (
      <SignupCheckboxes
        acceptTermsOfService={this.props.acceptTermsOfService}
        isOfLegalAge={this.props.isOfLegalAge}
        agreeToReceiveEmails={this.props.agreeToReceiveEmails}
        showEmailsCheckbox={this.props.showEmailsCheckbox}
        updatePreSignupFields={this.updatePreSignupFields}
        query={this.props.query}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  acceptTermsOfService: state.auth.preSignupFields.acceptTermsOfService,
  isOfLegalAge: state.auth.preSignupFields.isOfLegalAge,
  agreeToReceiveEmails: state.auth.preSignupFields.agreeToReceiveEmails,
  showEmailsCheckbox: shouldShowPreSignupEmailsField(state),
  query: state.app.storedQuery,
})

const mapDispatchToProps = {
  updatePreSignupFields,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupCheckboxesBox)

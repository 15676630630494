import { denormalize } from 'normalizr'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import omit from 'lodash/omit'

import {
  comicbookSchema,
  comicbooksSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import {
  analyticsEvent,
  COMICBOOK_ADDED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'
import { loadCounters } from 'client/bookmate/reducers/user-reducer'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  IMPRESSION_ADDED,
  IMPRESSION_REMOVED,
} from 'client/bookmate/reducers/impressions-reducer'
import { constants } from 'client/bookmate/constants'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { LibraryCardProps } from 'client/shared/types/library-card'

const COMICBOOK_CARD_ADDING = constants.COMICBOOK_CARD_ADDING
export const COMICBOOK_CARD_ADDED = constants.COMICBOOK_CARD_ADDED
const COMICBOOK_CARD_CHANGING = constants.COMICBOOK_CARD_CHANGING
const COMICBOOK_CARD_CHANGED = constants.COMICBOOK_CARD_CHANGED
const COMICBOOK_CARD_REMOVING = constants.COMICBOOK_CARD_REMOVING
export const COMICBOOK_CARD_REMOVED = constants.COMICBOOK_CARD_REMOVED

export function getComicbooksByIds(state, ids) {
  return denormalize(ids, comicbooksSchema, state.entities)
}

export function getComicbookById(state, id) {
  return denormalize(id, comicbookSchema, state.entities) || {}
}

// add a comic to My books
export function createComicbookCard(
  comicbookCardState?: LibraryCardProps,
  comicbook?: ComicbookProps,
): ApiAction {
  const data = {
    comic_card: {
      ...comicbookCardState,
      comicbook_uuid: comicbook?.uuid,
    },
  }

  if (comicbook?.bookshelfId) {
    data.comicbook = { bookshelf_uuid: comicbook.bookshelfId }
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/comic_cards`,
      options: {
        method: 'post' as FetchMethod,
        data,
      },
      normalize: ({ comic_card }, { getState }) => {
        // the value of readers_count in a comicbook coming in this response is wrong
        // (because the backend does not immediately update it when creating a comic card),
        // so we have to update this value using the data already present in our state
        const {
          comicbook: { uuid: comicbookId },
        } = comic_card
        const _comicbook = getComicbookById(getState(), comicbookId)

        comic_card = pick(comic_card, ['hidden', 'progress', 'state', 'uuid'])

        return {
          comicbook: {
            uuid: _comicbook.uuid,
            readers_count: _comicbook.readers_count + 1,
            comic_card,
          },
        }
      },
      onSuccess: (dispatch, getState, { comicbook: { comic_card } }) => {
        const analyticsPayload = {
          auto: false,
          comic_id: comicbook?.uuid,
          state: comicbookCardState?.state
            ? comicbookCardState?.state
            : 'not_finished',
          is_public: !comic_card.hidden,
        }
        dispatch(analyticsEvent(COMICBOOK_ADDED, analyticsPayload))
      },
      types: [COMICBOOK_CARD_ADDING, COMICBOOK_CARD_ADDED],
    },
  }
}

export function updateComicbookCard(
  comicbookCardState: LibraryCardProps,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/comic_cards/${comicbookCardState.uuid}`,
      options: {
        method: 'put' as FetchMethod,
        data: {
          comic_card: comicbookCardState,
        },
      },
      normalize: ({ comic_card }) => {
        const {
          comicbook: { uuid: comicbookId, readers_count },
        } = comic_card

        comic_card = pick(comic_card, ['hidden', 'progress', 'state', 'uuid'])

        return {
          comicbook: {
            uuid: comicbookId,
            readers_count,
            comic_card,
          },
        }
      },
      types: [COMICBOOK_CARD_CHANGING, COMICBOOK_CARD_CHANGED],
    },
  }
}

export function removeComicbookCard(comicbook: ComicbookProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/comic_cards/${comicbook.comic_card.uuid}`,
      options: {
        method: 'delete' as FetchMethod,
      },
      normalize: ({ comic_card }, { getState }) => {
        // the value of readers_count in a comicbook coming in this response is wrong
        // (because the backend does not immediately update it when creating a comic card),
        // so we have to update this value using the data already present in our state
        const {
          comicbook: { uuid: comicbookId },
        } = comic_card
        const _comicbook = getComicbookById(getState(), comicbookId)
        return {
          comicbook: {
            uuid: _comicbook.uuid,
            comic_card: null,
            readers_count: _comicbook.readers_count - 1,
          },
        }
      },
      onSuccess: (dispatch, getState, { comicbook: _comicbook }) => {
        const {
          currentUser: {
            data: { login },
          },
        } = getState()

        dispatch(loadCounters(login))

        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'comic',
            object_id: _comicbook.uuid,
          }),
        )
      },
      types: [COMICBOOK_CARD_REMOVING, COMICBOOK_CARD_REMOVED],
    },
  }
}

function updateAfterComicbookCardChange(state, action) {
  const updatedComicbook = state[action.comicbook.uuid]

  return {
    ...state,
    [updatedComicbook.uuid]: {
      ...updatedComicbook,
      comic_card: action.comicbook.comic_card,
      readers_count: action.comicbook.readers_count,
    },
  }
}

export default function comicbooks(state = {}, action) {
  if (action.entities && action.entities.comicbooks) {
    return merge({}, state, action.entities.comicbooks)
  }

  switch (action.type) {
    case COMICBOOK_CARD_ADDED:
    case COMICBOOK_CARD_REMOVED:
    case COMICBOOK_CARD_CHANGED:
      return updateAfterComicbookCardChange(state, action)

    case IMPRESSION_ADDED: {
      if (action.resourceType === 'comicbook') {
        const currentComicbook = state[action.resourceUuid]

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentComicbook,
            impression_uuid: action.impression.uuid,
            impressions_count: currentComicbook.impressions_count + 1,
          },
        }
      } else {
        return state
      }
    }

    case IMPRESSION_REMOVED: {
      if (action.resourceType === 'comicbook') {
        let currentComicbook = state[action.resourceUuid]
        currentComicbook = omit(currentComicbook)

        return {
          ...state,
          [action.resourceUuid]: {
            ...currentComicbook,
            impressions_count: currentComicbook.impressions_count - 1,
          },
        }
      } else {
        return state
      }
    }

    default:
      return state
  }
}

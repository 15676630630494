import omit from 'lodash/omit'
import merge from 'lodash/merge'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import {
  AUDIOBOOK_CHANGED,
  load as loadAudiobook,
} from 'client/bookmate/reducers/audiobook-reducer'
import { getAudioBookById } from 'client/bookmate/reducers/audiobooks-reducer'
import { loadCounters } from 'client/bookmate/reducers/user-reducer'

import { audioCardSchema } from 'client/bookmate/reducers/schemas/schemas'

import {
  analyticsEvent,
  AUDIOBOOK_ADDED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { FetchMethod } from 'shared/tools/fetch-wrapper'
import { Dispatch, GetState } from 'shared/types/redux'

const AUDIO_CARD_CHANGE = 'AUDIO_CARD_CHANGE'
const AUDIO_CARD_CHANGED = 'AUDIO_CARD_CHANGED'
const AUDIO_CARD_REMOVED = 'AUDIO_CARD_REMOVED'

export type AudioCardState = {
  state?: 'finished' | 'pending' | 'reading'
  uuid?: string
  hidden?: boolean
}

type AnalyticsPayload = {
  auto: boolean
  audiobook_id: string
  is_public?: boolean
  state?: string
}

export function createAudioCard(
  audioCardState: AudioCardState,
  audiobook: AudiobookProps,
): ApiAction {
  const data: {
    audio_card?: AudioCardState & { audiobook_uuid: string }
    audiobook?: { bookshelf_id: string }
  } = {
    audio_card: {
      ...audioCardState,
      audiobook_uuid: audiobook.uuid,
    },
  }

  const { bookshelfId } = audiobook

  if (bookshelfId) {
    data.audiobook = { bookshelf_id: bookshelfId }
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/audio_cards`,
      options: {
        method: 'post' as FetchMethod,
        data,
      },
      schema: audioCardSchema,
      responseKey: 'audio_card',
      rawResponseKeys: ['audio_card'],
      modifyResponse: response => ({
        reason: 'added',
        audiobook: {
          uuid: audiobook.uuid,
          audio_card: response.result,
        },
      }),
      onSuccess: dispatch => {
        const analyticsPayload: AnalyticsPayload = {
          auto: false,
          audiobook_id: audiobook.uuid,
        }
        analyticsPayload.state = audioCardState.state
          ? audioCardState.state
          : 'not_finished'
        analyticsPayload.is_public = !audioCardState.hidden
        dispatch(analyticsEvent(AUDIOBOOK_ADDED, analyticsPayload))
      },
      types: [AUDIO_CARD_CHANGE, AUDIOBOOK_CHANGED],
    },
  }
}

export function updateAudioCard(
  audioCardState: AudioCardState = {},
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/audio_cards/${audioCardState.uuid}`,
      options: {
        method: 'put' as FetchMethod,
        data: {
          audio_card: audioCardState,
        },
      },
      schema: audioCardSchema,
      responseKey: 'audio_card',
      types: [AUDIO_CARD_CHANGE, AUDIO_CARD_CHANGED],
    },
  }
}

function removeAudioCard(audioCard, audiobook) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/audio_cards/${audioCard.uuid}`,
      options: {
        method: 'delete' as FetchMethod,
      },
      rawResponseKeys: ['audio_card'],
      onSuccess: async (dispatch, getState, response) => {
        const {
          currentUser: {
            data: { login },
          },
        } = getState()

        await dispatch(loadCounters(login))

        dispatch({
          type: AUDIO_CARD_REMOVED,
          data: audioCard,
        })
        dispatch({
          type: AUDIOBOOK_CHANGED,
          reason: 'removed',
          audiobook: {
            uuid: audiobook.uuid,
            audio_card: response.result,
          },
        })
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'audiobook',
            object_id: audiobook.uuid,
          }),
        )
      },
      types: [AUDIO_CARD_CHANGE],
    },
  }
}

export function removeAudioCardGivenAudiobook(audiobook: AudiobookProps) {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    try {
      if (!audiobook.audio_card) {
        await dispatch(loadAudiobook(audiobook.uuid))
        audiobook = getAudioBookById(getState(), audiobook.uuid)
      }
      const audioCardData = { uuid: audiobook?.audio_card?.uuid }

      return dispatch(removeAudioCard(audioCardData, audiobook))
    } catch (error) {
      throw error
    }
  }
}

export default function audioCards(state = {}, action) {
  if (action.entities && action.entities.audioCards) {
    return merge({}, state, action.entities.audioCards)
  }

  switch (action.type) {
    case AUDIO_CARD_REMOVED:
      return omit(state, action.uuid)

    default:
      return state
  }
}

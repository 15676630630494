import React from 'react'
import Spacer from 'client/shared/blocks/spacer'
import { connect } from 'react-redux'
import { CurrentUserData } from 'client/shared/types/current-user'
import classNames from 'classnames'
import './subscription-form.styl'
import { State } from 'shared/types/redux'

type Props = {
  withoutMarginTop?: boolean
  userData: CurrentUserData
  className?: string
}

const TaxesComment: React.FC<Props> = ({
  withoutMarginTop = false,
  userData: { country, locale },
  className,
}) => {
  if (country !== 'ar') return null

  return (
    <>
      {!withoutMarginTop && <Spacer size={16} />}
      <span className={classNames('taxes-comment', className)}>
        {locale === 'en'
          ? 'The price does not include taxes operating on the territory of Argentina.'
          : 'El precio no incluye impuestos vigentes en Argentina.'}
      </span>
    </>
  )
}

export default connect((state: State) => ({
  userData: state.currentUser.data,
}))(TaxesComment)

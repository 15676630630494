import React, { Component, Fragment } from 'react'
import { Trans, withI18n, withI18nProps } from '@lingui/react'
import { connect } from 'react-redux'

import AudioBook from 'client/bookmate/blocks/audiobook'
import { Author } from 'client/bookmate/blocks/author'
import { Book } from 'client/shared/blocks/book'
import { Comicbook } from 'client/shared/blocks/comicbook'
import Series from 'client/shared/blocks/series'
import List from 'client/bookmate/blocks/list'
import MoreButton from 'client/shared/blocks/more-button'
import Linka from 'client/shared/blocks/linka'
import SectionNavHeader from 'client/bookmate/blocks/section-nav-header'

import { Heading } from 'client/shared/blocks/heading'
import Spacer from 'client/shared/blocks/spacer'

import urlFor, {
  addParamsToPath,
  NullableQueryParams,
  QueryParams,
} from 'shared/tools/url-helper'
import deviceHelper from 'shared/tools/device-helper'

const AVAILABLE_STATE = 'available'
const CLOSED_STATE = 'closed'
const BANNED_STATE = 'banned'

const AUTHOR = 'author'
export const BOOK = 'book'
export const AUDIOBOOK = 'audiobook'
export const COMICBOOK = 'comicbook'
export const SERIES = 'series'

import './search-best-match.styl'

import {
  BestMatchProps,
  RelatedProps,
  SearchBestResultName,
} from 'client/shared/types/search'
import { ResourceProps } from 'client/shared/types/resource'
import { BookProps } from 'client/shared/types/book'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { AuthorProps } from 'client/shared/types/author'
import { SeriesProps } from 'client/shared/types/series'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { sendOnClickAnalytics } from 'shared/tools/analytics-helper'
import { Dispatch, State } from 'shared/types/redux'

type Props = {
  app: AppState
  data: BestMatchProps
  position: number
  dispatch: Dispatch
  userId: number
  query?: QueryParams
} & withI18nProps

type ItemKind = 'grid' | 'list'

class SearchBestMatch extends Component<Props> {
  get hasRelatedResult() {
    const { related } = this.props.data

    return related && related.items.length
  }

  get needMoreButton(): boolean | undefined {
    const { related } = this.props.data

    return related && related.meta.per_page < related.meta.total
  }

  render(): JSX.Element | null {
    const { data } = this.props

    if (data.state === BANNED_STATE && !this.hasRelatedResult) return null

    return (
      <div className="search-best-match">
        {data.type === AUTHOR
          ? this.renderAuthorsBestMatch(data)
          : this.renderBookBestMatch(data)}
      </div>
    )
  }

  renderAuthorsBestMatch(data: BestMatchProps) {
    if (data.state === AVAILABLE_STATE || !this.hasRelatedResult) {
      return this.renderMatchedItem(data)
    } else {
      return this.renderRelatedItems(data)
    }
  }

  renderBookBestMatch(data: BestMatchProps) {
    if (data.state !== AVAILABLE_STATE && this.hasRelatedResult) {
      return this.renderRelatedItems(data)
    } else if (data.state === AVAILABLE_STATE || data.state === CLOSED_STATE) {
      return this.renderMatchedItem(data)
    }
  }

  renderMatchedItem(matchedItem: BestMatchProps) {
    let renderedItem

    switch (matchedItem.type) {
      case BOOK:
        renderedItem = this.renderBook(matchedItem.item)
        break

      case AUDIOBOOK:
        renderedItem = this.renderAudiobook(matchedItem.item)
        break

      case COMICBOOK:
        renderedItem = this.renderComicbook(matchedItem.item)
        break

      case AUTHOR: {
        renderedItem = this.renderAuthor(matchedItem.item)
        break
      }

      case SERIES: {
        renderedItem = this.renderSeries(matchedItem.item)
        break
      }
    }

    return (
      <Fragment>
        <Heading rank={2} looksLike={1}>
          <Trans id="search.best_match" />
        </Heading>
        <Spacer size={24} />
        {renderedItem}
      </Fragment>
    )
  }

  renderRelatedItems(matchedItem: BestMatchProps) {
    const { i18n, query } = this.props

    if (matchedItem.type === AUTHOR) {
      const authorName = (
        <Linka
          path={`${urlFor.author(matchedItem.item.uuid, {
            ...query,
          })}`}
        >
          {matchedItem.item.name}
        </Linka>
      )

      return (
        <Fragment>
          {this.renderRelatedHeader(
            i18n._('search.looks_like_author_books'),
            authorName,
          )}
          <Spacer size={24} />
          {this.renderSlider(matchedItem.related)}
        </Fragment>
      )
    } else {
      const title = i18n._(`search.looks_like_${matchedItem.type}`)
      const itemTitle = this.renderItemTitle(
        matchedItem.item,
        matchedItem.type,
        { withLink: matchedItem.state === CLOSED_STATE },
      )
      const relatedLink = urlFor.resourceRelated(
        matchedItem.item.uuid,
        matchedItem.type,
      )
      const moreItemsUrl =
        this.needMoreButton && relatedLink
          ? addParamsToPath(`${relatedLink}`, query as NullableQueryParams)
          : ''
      const moreButtonText = <Trans id="search.all_related" />

      return (
        <Fragment>
          {this.renderRelatedHeader(title, itemTitle)}
          <Spacer size={24} />
          {this.renderSlider(matchedItem.related)}
          <Spacer size={40} />
          {moreItemsUrl && (
            <MoreButton
              path={moreItemsUrl}
              centered
              children={moreButtonText}
            />
          )}
        </Fragment>
      )
    }
  }

  renderItemTitle(
    item: ResourceProps | SeriesProps,
    type: SearchBestResultName,
    { withLink }: { withLink: boolean } = { withLink: false },
  ) {
    const { query } = this.props
    const itemTitleClass = withLink
      ? 'search-best-match__item-title search-best-match__item-title_clickable'
      : 'search-best-match__item-title'
    const resourceLink = urlFor.resource(item.uuid, type, query)
    const titleLink =
      withLink && resourceLink
        ? addParamsToPath(resourceLink, query as NullableQueryParams)
        : ''

    return (
      <div className={itemTitleClass}>
        <SectionNavHeader
          kind={type}
          intlId="book.resource_title"
          title={item.title}
          titleLink={titleLink}
          authors={item.authors}
          query={query}
        />
      </div>
    )
  }

  renderRelatedHeader(firstPart: string, secondPart: React.ReactNode) {
    return (
      <div className="search-best-match__subtitle">
        <Heading rank={2} centered>
          <span>{firstPart}</span>
          <br />
          {secondPart}
        </Heading>
      </div>
    )
  }

  renderSlider(relatedItems: RelatedProps | null | undefined) {
    const { app } = this.props

    if (!relatedItems) return null

    let renderedItems: JSX.Element[] | null = null
    let height = '176'
    switch (relatedItems.type) {
      case 'book':
        renderedItems = relatedItems.items.map(item =>
          this.renderBook(item, 'grid'),
        )
        break

      case 'audiobook':
        renderedItems = relatedItems.items.map(item =>
          this.renderAudiobook(item, 'grid'),
        )
        height = '120'
        break

      case 'comicbook':
        renderedItems = relatedItems.items.map(item =>
          this.renderComicbook(item, 'grid'),
        )
        break

      case 'series':
        renderedItems = relatedItems.items.map(item => this.renderSeries(item))
        break
    }

    const controlsNeeded = renderedItems.length > 4
    const isDesktop = deviceHelper.isDesktopAllSizes(app)

    return (
      <List
        type={controlsNeeded && isDesktop ? 'walker' : 'slider'}
        coverHeight={height}
      >
        {renderedItems}
      </List>
    )
  }

  renderBook(book: BookProps, kind: ItemKind = 'list') {
    const { dispatch, userId } = this.props
    return (
      <Book
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: BOOK,
            itemUuid: book.uuid,
            screenName: 'search_all',
            position: 1,
            userId,
          })
        }
        key={book.uuid}
        book={book}
        kind={kind}
        coverSize={120}
        isInSlider
      />
    )
  }

  renderAudiobook(audiobook: AudiobookProps, kind: ItemKind = 'list') {
    const { dispatch, userId } = this.props
    return (
      <AudioBook
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: AUDIOBOOK,
            itemUuid: audiobook.uuid,
            screenName: 'search_all',
            position: 1,
            userId,
          })
        }
        key={audiobook.uuid}
        audiobook={audiobook}
        kind={kind}
        coverSize={120}
      />
    )
  }

  renderComicbook(comicbook: ComicbookProps, kind: ItemKind = 'list') {
    const { dispatch, userId } = this.props
    return (
      <Comicbook
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: COMICBOOK,
            itemUuid: comicbook.uuid,
            screenName: 'search_all',
            position: 1,
            userId,
          })
        }
        key={comicbook.uuid}
        comicbook={comicbook}
        kind={kind}
        path={`${urlFor.comicbook(comicbook.uuid, this.props.query)}`}
        coverSize={120}
        isInSlider
      />
    )
  }

  renderAuthor(author: AuthorProps) {
    const { dispatch, userId } = this.props
    return (
      <Author
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: AUTHOR,
            itemUuid: author.uuid,
            screenName: 'search_all',
            position: 1,
            userId,
          })
        }
        author={author}
        kind="list"
      />
    )
  }

  renderSeries(series: SeriesProps) {
    const { dispatch, userId } = this.props
    return (
      <Series
        onClick={() =>
          sendOnClickAnalytics({
            dispatch,
            resourceType: SERIES,
            itemUuid: series.uuid,
            screenName: 'search_all',
            position: 1,
            userId,
          })
        }
        key={series.uuid}
        series={series}
      />
    )
  }
}

const mapStateToProps = (state: State) => ({
  app: state.app,
})

export default connect(mapStateToProps)(
  withI18n({ update: true })(SearchBestMatch),
)

import React, { PureComponent } from 'react'
import { Trans } from '@lingui/react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import Checkbox from 'client/shared/blocks/checkbox'
import Linka from 'client/shared/blocks/linka'
import Spacer from 'client/shared/blocks/spacer'

type Props = {
  acceptTermsOfService: boolean
  isOfLegalAge: boolean
  agreeToReceiveEmails: boolean
  showEmailsCheckbox: boolean
  updatePreSignupFields: ({ prop }: { [prop: string]: boolean }) => void
  query?: QueryParams
}

class SignupCheckboxes extends PureComponent<Props> {
  updateCheckbox = (prop: string) => (): void => {
    this.props.updatePreSignupFields({
      [prop]: !this.props[prop],
    })
  }

  render(): JSX.Element {
    return (
      <div className="signup-checkboxes">
        <Checkbox
          checked={this.props.acceptTermsOfService}
          id="signup-checkboxes-legal"
          label={this.renderLegalLabel()}
          onChange={this.updateCheckbox('acceptTermsOfService')}
        />
        <Spacer />
        <Checkbox
          checked={this.props.isOfLegalAge}
          id="signup-checkboxes-age"
          label={this.renderAgeLabel()}
          onChange={this.updateCheckbox('isOfLegalAge')}
        />
        {this.props.showEmailsCheckbox && (
          <>
            <Spacer />
            <Checkbox
              checked={this.props.agreeToReceiveEmails}
              id="signup-checkboxes-emails"
              label={this.renderEmailsLabel()}
              onChange={this.updateCheckbox('agreeToReceiveEmails')}
            />
          </>
        )}
      </div>
    )
  }

  renderLegalLabel(): JSX.Element {
    const LinkToLegal = (
      <Linka
        path={urlFor.legal('#terms_of_use', this.props.query)}
        target="_blank"
      />
    )
    return <Trans id="auth.checkbox_legal" components={[LinkToLegal]} />
  }

  renderAgeLabel(): JSX.Element {
    return <Trans id="auth.checkbox_age" />
  }

  renderEmailsLabel(): JSX.Element {
    return <Trans id="auth.checkbox_emails" />
  }
}

export default SignupCheckboxes

import { normalize } from 'normalizr'

import { CALL_API } from 'shared/middlewares/api-middleware'

import {
  achievementSchema,
  achievementsSchema,
} from 'client/bookmate/reducers/achievements-reducer'
import { booksSchema } from 'client/bookmate/reducers/schemas/schemas'

import {
  ACHIEVEMENTS_USER_LOADING,
  ACHIEVEMENTS_USER_LOAD_SUCCESS,
  ACHIEVEMENTS_BOOKS_LOADING,
  ACHIEVEMENTS_BOOKS_LOAD_SUCCESS,
  ACHIEVEMENTS_FRIENDS_LOADING,
  ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS,
  ACHIEVEMENTS_PLEDGE_IN_PROGRESS,
  ACHIEVEMENTS_PLEDGE_SUCCESS,
  ACHIEVEMENTS_PLEDGE_DELETING,
  ACHIEVEMENTS_PLEDGE_DELETE_SUCCESS,
  ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR,
  ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR,
  ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR,
  ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR,
} from 'client/shared/constants/achievements-constants'

import { CurrentUserState } from 'client/shared/types/current-user'

type LoadUserAchievementsPayload = {
  username: string
  year: string | number
  isMyPage: boolean
}

type LoadFinishedBooksPayload = {
  username: string
  year: string | number
  page: number
  append: boolean
  isMyPage: boolean
}

type OpenPledgeFormForCurrentYearAction = {
  type: 'ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR'
}

type ClosePledgeFormForCurrentYearAction = {
  type: 'ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR'
}

type OpenPledgeFormForNextYearAction = {
  type: 'ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR'
}

type ClosePledgeFormForNextYearAction = {
  type: 'ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR'
}

type AchievementsLoadSuccessAction = {
  type: 'ACHIEVEMENTS_USER_LOAD_SUCCESS'
  result: string
  entities: {
    [key: string]: any
  }
}

type FriendsAchievementsLoadSuccessAction = {
  type: 'ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS'
  result: string[]
  entities: {
    [key: string]: any
  }
  append: boolean
  nextPage: number | null | undefined
}

type AchievementsBooksLoadingAction = {
  type: 'ACHIEVEMENTS_BOOKS_LOADING'
}

type AchievementsBooksLoadSuccessAction = {
  type: 'ACHIEVEMENTS_BOOKS_LOAD_SUCCESS'
  entities: {
    [key: string]: any
  }
  data: string[]
  append: boolean
  nextPage: number | null | undefined
}

type AchievementsPledgeSuccessAction = {
  type: 'ACHIEVEMENTS_PLEDGE_SUCCESS'
  username: string
  year: number
}

type State = {
  ui: {
    canMakePledgeForCurrentYear: boolean
    canMakePledgeForNextYear: boolean
    makingPledgeForCurrentYear: boolean
    makingPledgeForNextYear: boolean
    waitingForFinishedBooks: boolean
    waitingForNextYearReadingChallenge: boolean
  }
  achievements: string[]
  friendsAchievements: string[]
  finishedBooks: string[]
  queryPages: {
    finishedBooks: number
    friendsAchievements: number
  }
}

type Action =
  | AchievementsLoadSuccessAction
  | FriendsAchievementsLoadSuccessAction
  | OpenPledgeFormForCurrentYearAction
  | ClosePledgeFormForCurrentYearAction
  | OpenPledgeFormForNextYearAction
  | ClosePledgeFormForNextYearAction
  | AchievementsBooksLoadingAction
  | AchievementsBooksLoadSuccessAction
  | AchievementsPledgeSuccessAction

export function currentYear() {
  return new Date().getFullYear()
}

export function isPageOfCurrentUser(
  username: string,
  currentUser: CurrentUserState,
) {
  return username.toLowerCase() === currentUser.data.login.toLowerCase()
}

export function openPledgeFormForCurrentYear() {
  return {
    type: ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR,
  }
}

export function closePledgeFormForCurrentYear() {
  return {
    type: ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR,
  }
}

export function openPledgeFormForNextYear() {
  return {
    type: ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR,
  }
}

export function closePledgeFormForNextYear() {
  return {
    type: ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR,
  }
}

export function loadUserAchievements({
  username,
  year,
  isMyPage = false,
}: LoadUserAchievementsPayload) {
  const endpoint = isMyPage
    ? `/p/api/v5/profile/reading_achievements/${year}`
    : `/p/api/v5/users/${username}/reading_achievements/${year}`

  return {
    [CALL_API]: {
      endpoint,
      responseKey: 'reading_achievement',
      schema: achievementSchema,
      types: [ACHIEVEMENTS_USER_LOADING, ACHIEVEMENTS_USER_LOAD_SUCCESS],
    },
  }
}

/**
 * Get achievements of people the current user is following
 */
export function loadFriendsAchievements({
  page = 1,
  append = false,
}: { page: number; append: boolean } = {}) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/following/reading_achievements`,
      options: {
        data: {
          page,
        },
      },
      responseKey: 'reading_achievements',
      schema: achievementsSchema,
      modifyResponse: normalizedResponse => ({
        append,
        nextPage: normalizedResponse.result.length ? page + 1 : null,
      }),
      types: [ACHIEVEMENTS_FRIENDS_LOADING, ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS],
    },
  }
}

export function loadFinishedBooks({
  username,
  year,
  isMyPage,
  page = 1,
  append = false,
}: LoadFinishedBooksPayload) {
  const endpoint = isMyPage
    ? `/p/api/v5/profile/reading_achievements/${year}/finished_books`
    : `/p/api/v5/users/${username}/reading_achievements/${year}/finished_books`

  const data = {
    page,
  }

  return {
    [CALL_API]: {
      endpoint,
      options: {
        data,
      },
      normalize: res => {
        const { result, entities } = normalize(res.books, booksSchema)

        return {
          entities,
          data: result,
          append,
          nextPage: result.length ? page + 1 : null,
        }
      },
      types: [ACHIEVEMENTS_BOOKS_LOADING, ACHIEVEMENTS_BOOKS_LOAD_SUCCESS],
    },
  }
}

export function makePledge(pledgedBooksCount: number, year: number) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/reading_achievements/${year}/reading_challenge`,
      options: {
        method: 'put',
        data: {
          reading_challenge: {
            promised_books_count: pledgedBooksCount,
          },
        },
      },
      normalize: (response, { getState }) => {
        const {
          currentUser: {
            data: { login },
          },
        } = getState()

        return {
          username: login,
          year,
          readingChallenge: response.reading_challenge,
        }
      },
      types: [ACHIEVEMENTS_PLEDGE_IN_PROGRESS, ACHIEVEMENTS_PLEDGE_SUCCESS],
    },
  }
}

export function deletePledgeForCurrentYear() {
  const _currentYear = new Date().getFullYear()
  return deletePledge(_currentYear)
}

export function deletePledgeForNextYear() {
  const nextYear = new Date().getFullYear() + 1
  return deletePledge(nextYear)
}

function deletePledge(year) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/reading_achievements/${year}/reading_challenge`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      normalize: (response, { getState }) => {
        // NOTE: response is empty
        const {
          currentUser: {
            data: { login },
          },
        } = getState()

        return {
          username: login,
          year,
        }
      },
      types: [ACHIEVEMENTS_PLEDGE_DELETING, ACHIEVEMENTS_PLEDGE_DELETE_SUCCESS],
    },
    data: { year },
  }
}

const initialState = {
  ui: {
    // NOTE: this looks flaky; might change in the future
    canMakePledgeForCurrentYear: !isDecember(),
    canMakePledgeForNextYear: isDecember(),
    makingPledgeForCurrentYear: false,
    makingPledgeForNextYear: false,
    waitingForFinishedBooks: false,
    waitingForNextYearReadingChallenge: false,
  },
  achievements: [],
  visitingCurrentUserAchievements: {},
  friendsAchievements: [],
  finishedBooks: [],
  queryPages: {
    finishedBooks: 1,
    friendsAchievements: 1,
  },
}

export default function achievements(
  state: State = initialState,
  action: Action,
) {
  const _currentYear = new Date().getFullYear()

  switch (action.type) {
    case ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS:
      return {
        ...state,
        friendsAchievements: action.append
          ? [...state.friendsAchievements, ...action.result]
          : action.result,
        queryPages: {
          ...state.queryPages,
          friendsAchievements: action.nextPage,
        },
      }
    case ACHIEVEMENTS_BOOKS_LOADING:
      return {
        ...state,
        ui: { ...state.ui, waitingForFinishedBooks: true },
      }
    case ACHIEVEMENTS_BOOKS_LOAD_SUCCESS:
      return {
        ...state,
        finishedBooks: action.append
          ? [...state.finishedBooks, ...action.data]
          : action.data,
        ui: { ...state.ui, waitingForFinishedBooks: false },
        queryPages: { ...state.queryPages, finishedBooks: action.nextPage },
      }

    case ACHIEVEMENTS_PLEDGE_SUCCESS: {
      const ui = {}

      if (action.year === _currentYear) {
        ui.makingPledgeForCurrentYear = false
      } else if (action.year === _currentYear + 1) {
        ui.makingPledgeForNextYear = false
      }
      return {
        ...state,
        // newAchievements,
        ui: { ...state.ui, ...ui },
      }
    }

    case ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR:
      return {
        ...state,
        ui: { ...state.ui, makingPledgeForCurrentYear: true },
      }
    case ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR:
      return {
        ...state,
        ui: { ...state.ui, makingPledgeForCurrentYear: false },
      }
    case ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR:
      return {
        ...state,
        ui: { ...state.ui, makingPledgeForNextYear: true },
      }
    case ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR:
      return {
        ...state,
        ui: { ...state.ui, makingPledgeForNextYear: false },
      }

    default:
      return state
  }
}

/**
 * NOTE: current logic is rather arbitrary and will most likely change
 * currently, the idea is to make pledges for the current year before December
 * and to make pledges for the next year in December
 */
function isDecember() {
  return new Date().getMonth() === 11 // is the current month December?
}

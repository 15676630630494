import Fingerprint2 from 'fingerprintjs2'
import { setFingerprint } from 'client/shared/reducers/app-reducer'
import { Dispatch } from 'shared/types/redux'

export async function getFingerprint(dispatch: Dispatch) {
  const fingerprint = await Fingerprint2.getPromise()
  const fingerprintHashed = Fingerprint2.x64hash128(
    fingerprint.map(pair => pair.value).join(),
    31,
  )

  dispatch(setFingerprint(fingerprintHashed))
}

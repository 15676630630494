declare let Stripe: any

import config from 'config'

import {
  markStripeLoadingSuccess,
  markStripeLoadingFailure,
} from 'client/shared/reducers/stripe-reducer'

import { Dispatch } from 'shared/types/redux'
import { StripeError, StripeElementError } from 'client/shared/types/stripe'
import isFunction from 'lodash/isFunction'

let stripe
let stripeInstance

const STRIPE_TO_BOOKMATE_ERROR_CODES = {
  elementErrors: {
    invalid_number: 'invalid_card_number_short',
    incomplete_number: 'incomplete_card_number_short',
    incomplete_cvc: 'incomplete_cvc_short',
    incomplete_expiry: 'incomplete_expiration_date_short',
    invalid_expiry_month_past: 'invalid_expiration_date_short',
    invalid_expiry_year_past: 'invalid_expiration_date_short',
    invalid_expiry_year: 'invalid_expiration_date_short',
  },
  apiErrors: {
    invalid_number: 'invalid_card_number',
    incorrect_number: 'invalid_card_number',
    invalid_expiry_year: 'invalid_expiration_date',
    invalid_expiry_month: 'invalid_expiration_date',
    invalid_cvc: 'card_verification_failed',
    expired_card: 'invalid_expiration_date',
    card_declined: 'card_verification_failed',
    insufficient_funds: 'no_money',
    card_verification_failed: 'card_verification_failed',
  },
}

export function loadStripe(dispatch: Dispatch, cb?: () => void): void {
  const script = document.createElement('script')
  script.defer = true

  script.onload = () => {
    initializeStripe()
    dispatch(markStripeLoadingSuccess())
    if (cb) cb()
  }
  script.onerror = () => {
    dispatch(markStripeLoadingFailure())
  }

  script.src = 'https://js.stripe.com/v3/'

  // eslint-disable-next-line no-unused-expressions
  document.body && document.body.appendChild(script)
}

function initializeStripe() {
  if (!stripe) {
    stripe = Stripe(config.stripeKey)
  }
}

export function getStripe(): typeof Stripe {
  return stripe
}

export function getStripeInstance(locale = 'en') {
  if (!stripeInstance) {
    stripeInstance = stripe.elements({ locale })
  }
  return stripeInstance
}

export function getErrorMessageIdForStripeElementError(
  stripeError: StripeElementError | null | undefined,
) {
  if (!stripeError) return undefined

  const { code } = stripeError
  return STRIPE_TO_BOOKMATE_ERROR_CODES.elementErrors[code]
}

export const INSUFFICIENT_FUNDS = 'insufficient_funds'

export function getErrorMessageIdForStripeApiError(
  stripeError: StripeError | null | undefined,
) {
  if (!stripeError) return undefined

  const { code, decline_code } = stripeError
  let errorCode = code
  if (decline_code === INSUFFICIENT_FUNDS) {
    errorCode = decline_code === INSUFFICIENT_FUNDS ? INSUFFICIENT_FUNDS : code
  }

  return STRIPE_TO_BOOKMATE_ERROR_CODES.apiErrors[errorCode]
}

// call Android API on Success if available
export function callAndroidOnSuccessPayment(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isFunction(window.Android?.onSuccessPayment)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Android.onSuccessPayment()
  }
}

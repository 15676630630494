import { normalize } from 'normalizr'

import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'

import { CALL_API } from 'shared/middlewares/api-middleware'

import { bookSchema } from 'client/bookmate/reducers/schemas/schemas'

import { constants } from 'client/bookmate/constants'

import { createRedirectForResource } from 'shared/tools/url-helper'

import { loadLibraryCardAction } from './loadLibraryCardAction'

const getBookRedirectUrl = createRedirectForResource('book')

export function loadBookAction(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/books/${uuid}`,
      normalize: async ({ book: _book }, { dispatch, getState }) => {
        const correctBookId = _book.uuid
        const currentPath = getState().app.url

        if (uuid !== correctBookId) {
          serverRedirectTo(getBookRedirectUrl(correctBookId, currentPath), 301)
        } else {
          const { result, entities } = normalize(_book, bookSchema)

          if (_book.library_card_uuid) {
            await dispatch(loadLibraryCardAction(_book.library_card_uuid))
          }

          return {
            result,
            entities,
            library_card_uuid: _book.library_card_uuid,
            in_library: _book.in_library,
          }
        }
      },
      types: [
        constants.BOOK_LOAD,
        constants.BOOK_LOAD_SUCCESS,
        constants.BOOK_LOAD_ERROR,
      ],
    },
  }
}

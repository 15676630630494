import React, { Children } from 'react'

export const ViewPagerContext = React.createContext()

export function getIndex(key, children) {
  let index = null

  Children.forEach(children, (child, _index) => {
    if (child.key === key || _index === key) {
      index = _index
    }
  })

  return index
}

export function modulo(val, max) {
  return ((val % max) + max) % max
}

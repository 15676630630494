import isPlainObject from 'lodash/isPlainObject'
import MemoryCache from './memory-cache'

function generateCacheKey(type, args) {
  args = args
    .map(arg => {
      return typeof arg === 'object' ? JSON.stringify(arg) : arg
    })
    .join(',')
  return `${type}:${args}`
}

export function cacheAction(fn, type, invalidateActions) {
  return (...args) => {
    const action = fn(...args)
    const options = args[args.length - 1]

    if (isPlainObject(options) && options.force) {
      return action
    }
    action.cacheKey = generateCacheKey(type, args)
    action.invalidateActions = invalidateActions
    return action
  }
}

export default function cacheMiddleware(cache = new MemoryCache(), deps = {}) {
  return ({ dispatch, getState }) => {
    return next => action => {
      const { cacheKey: key, invalidateActions } = action

      if (Array.isArray(invalidateActions)) {
        invalidateActions.forEach(actionType => {
          deps[actionType] = deps[actionType] || {}
          deps[actionType][key] = true
        })
      }

      if (key && typeof action === 'function') {
        if (cache.has(key)) {
          setTimeout(() => next(cache.get(key)))
        } else {
          return action(_action => {
            if (key.indexOf(`${_action.type}:`) === 0) cache.set(key, _action)
            return dispatch(_action)
          }, getState)
        }
      } else {
        if (action.type in deps) {
          // eslint-disable-next-line no-unused-vars
          for (const cacheKey in deps[action.type]) {
            cache.purgeKey(cacheKey)
          }
        }
        return next(action)
      }
    }
  }
}

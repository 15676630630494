import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { getCoverOptions } from 'client/shared/helpers/book-cover-helpers'
import ProgressiveCover from 'client/shared/blocks/progressive-cover'

import PlayIcon from 'client/shared/icons/play.svg'

import { SeriesProps } from 'client/shared/types/series'
import { SeriesCoverSize } from 'client/shared/types/cover'

import type { Exact } from 'types/local/exact-check'

import './series-cover.styl'

type Props = Exact<DecoratorProps> & {
  series: SeriesProps
  blockHeight: SeriesCoverSize
}

const WIDTHS = {
  '110': 72,
  '164': 108,
}

class SeriesCover extends Component<Props> {
  static defaultProps = {
    blockHeight: {
      desktopLarge: 164,
      desktopMedium: 164,
      desktop: 164,
      tablet: 110,
      mobile: 110,
    },
  }

  getAlt() {
    const { series } = this.props

    return series.title
  }

  getHeight() {
    const { app, blockHeight } = this.props

    return typeof blockHeight === 'object' ? blockHeight[app.size] : blockHeight
  }

  render() {
    return <div className="series-cover">{this.renderCover()}</div>
  }

  renderCover() {
    const { series } = this.props

    if (series.resourcesTypes[0] === 'audiobook') {
      return this.renderAudiobookCover()
    }

    return this.renderBookCover()
  }

  renderAudiobookCover() {
    const {
      series: { cover },
    } = this.props
    const height = this.getHeight()
    const coverStyle = { width: `${height}px`, height: `${height}px` }

    return (
      <div
        className="series-cover__wrapper series-cover__audiobook"
        style={coverStyle}
      >
        <div className="series-cover__substrate" />
        <SVGInline svg={PlayIcon} className="series-cover__play-icon" />
        <ProgressiveCover
          src={cover.large}
          placeholder={cover.placeholder || ''}
          width={height}
          height={height}
          alt={this.getAlt()}
        />
      </div>
    )
  }

  renderBookCover() {
    const {
      series: { cover },
    } = this.props
    const height = this.getHeight()
    const width = WIDTHS[height]
    const { imgHeight, imgWidth } = getCoverOptions(cover, width, height)
    const coverStyle = { width: `${imgWidth}px`, height: `${imgHeight}px` }

    return (
      <div
        className="series-cover__wrapper series-cover__book"
        style={coverStyle}
      >
        <div className="series-cover__substrate" />
        <ProgressiveCover
          src={cover.large}
          placeholder={cover.placeholder || ''}
          width={imgWidth}
          height={imgHeight}
          alt={this.getAlt()}
        />
      </div>
    )
  }
}

export default uiBox(SeriesCover)

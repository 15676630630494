import React, { Component } from 'react'
import './arrow-button.styl'

import SVGInline from 'react-svg-inline'
import ArrowIcon from 'client/shared/icons/back.svg'
import FullArrowIcon from 'client/shared/icons/arrow.svg'
import { withI18n, withI18nProps } from '@lingui/react'

type Props = {
  onClick?: () => void
  kind: 'prev' | 'next'
  type?: ['big' | 'medium' | 'small']
  appearance?: 'transparent' | 'round'
} & withI18nProps

class _ArrowButton extends Component<Props> {
  render(): JSX.Element {
    const { onClick, kind, type, appearance, i18n } = this.props

    const typeMod = type ? `arrow-button_${type}` : ''
    const appearanceMod = appearance ? `arrow-button_${appearance}` : ''
    const arrowType = appearance === 'round' ? FullArrowIcon : ArrowIcon

    return (
      <div
        role="navigation"
        aria-label={i18n.t`book.more`}
        className={`arrow-button arrow-button__${kind} ${typeMod} ${appearanceMod}`}
        onClick={onClick}
      >
        <SVGInline svg={arrowType} />
      </div>
    )
  }
}

export const ArrowButton = withI18n({ update: true })(_ArrowButton)

import merge from 'lodash/merge'
import { State } from 'shared/types/redux'
import { Entities } from 'client/shared/types/entities'

export const checkEntities = (
  state: State,
  action: {
    payload?: { entities: Entities }
    entities?: Entities
    type: string
  },
  type: string,
): State => {
  if (action.entities && action.entities[type]) {
    return merge({}, state, action.entities[type])
  }
  return action.payload?.entities
    ? merge({}, state, action.payload.entities[type])
    : {}
}

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import LanguagesButton from 'client/shared/blocks/languages-button'

import * as userActions from 'client/shared/reducers/current-user-reducer'
import * as languagesActions from 'client/shared/reducers/languages-reducer'

import type { Dispatch, State } from 'shared/types/redux'
import type { CurrentUserState } from 'client/shared/types/current-user'
import type { AccessLevels } from 'client/shared/types/subscription'
import type { State as LanguagesState } from 'client/shared/reducers/languages-reducer'
import { hide } from 'client/shared/reducers/fake-page-reducer'
import compose from 'lodash/fp/compose'
import { withRouter } from 'react-router'
import { Location } from '../types/react-router'

type Props = {
  currentUser: CurrentUserState
  userAccessLevels: AccessLevels
  languages: LanguagesState
  dispatch: Dispatch
  location: Location
}

class LanguagesButtonBox extends PureComponent<Props> {
  onUserLanguageChange = language => {
    const { dispatch, location } = this.props
    const _language = language === 'rus' ? 'ru' : language
    dispatch(userActions.setLibraryLanguage(_language))
    dispatch(userActions.setLocale(_language, location.pathname))
    dispatch(hide())
  }

  render() {
    const { dispatch, currentUser, userAccessLevels, languages } = this.props

    if (!languages.allUserLanguages.length)
      dispatch(languagesActions.setAllUserLanguages())

    return (
      <LanguagesButton
        currentUser={currentUser}
        userAccessLevels={userAccessLevels}
        languages={languages}
        onUserLanguageChange={this.onUserLanguageChange}
      />
    )
  }
}

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  userAccessLevels: state.subscription.userAccessLevels,
  languages: state.languages,
}))

const wrappers = compose(connectWrapper, withRouter)

export default wrappers(LanguagesButtonBox)

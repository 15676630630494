import { schema, normalize, denormalize } from 'normalizr'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import mapValues from 'lodash/mapValues'
import noop from 'lodash/noop'
import { createSelector } from '@reduxjs/toolkit'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import { emotionsSchema } from 'client/bookmate/reducers/emotions-reducer'
import {
  userSchema,
  bookSchema,
  audioBookSchema,
  comicbookSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import {
  analyticsEvent,
  LIKE_BUTTON_PRESSED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
} from 'client/bookmate/reducers/comments-reducer'

import { throttledLikeHelper } from 'shared/tools/api-helpers'
import { checkEntities } from 'client/bookmate/helpers/entities-helper'
import { Dispatch, GetState } from 'shared/types/redux'
import { ResourceProps } from 'client/shared/types/resource'

export const IMPRESSION_ADDED = 'IMPRESSION_ADDED'
const IMPRESSION_LIKE = 'IMPRESSION_LIKE'
export const IMPRESSION_LIKED = 'IMPRESSION_LIKED'
export const IMPRESSION_CHANGED = 'IMPRESSION_CHANGED'
const IMPRESSION_REMOVE = 'IMPRESSION_REMOVE'
export const IMPRESSION_REMOVED = 'IMPRESSION_REMOVED'
export const IMPRESSION_LOADING = 'IMPRESSION_LOADING'
const IMPRESSION_LOADED = 'IMPRESSION_LOADED'
const IMPRESSION_LOAD_FAILURE = 'IMPRESSION_LOAD_FAILURE'
export const IMPRESSION_CURRENT_USER_LOADED = 'IMPRESSION_CURRENT_USER_LOADED'
export const IMPRESSION_CURRENT_USER_CLEAN = 'IMPRESSION_CURRENT_USER_CLEAN'

const impressionResourceSchema = new schema.Union(
  {
    book: bookSchema,
    audiobook: audioBookSchema,
    comicbook: comicbookSchema,
  },
  (input, parent) => {
    return parent.embeddedResourceType
  },
)

export const impressionSchema = new schema.Entity(
  'impressions',
  {
    creator: userSchema,
    emotions: emotionsSchema,
    resource: impressionResourceSchema,
  },
  {
    idAttribute: 'uuid',
    processStrategy(impression) {
      return {
        ...impression,
        embeddedResourceType: impression.resource_type,
        resourceType: 'impression',
      }
    },
  },
)

export const impressionsSchema = new schema.Array(impressionSchema)

const entitiesSelector = state => state.entities
const idSelector = (_state, id) => id
const idsSelector = (_state, ids) => ids

export function addIdToImpressions(_impressions, resourceId, resourceType) {
  return mapValues(_impressions, impression => ({
    ...impression,
    [resourceType]: resourceId,
  }))
}

export const getImpressionsByIds = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => denormalize(ids, impressionsSchema, entities),
)

export const getImpressionById = createSelector(
  idSelector,
  entitiesSelector,
  (id, entities) => denormalize(id, impressionSchema, entities),
)

function prepareImpressionFromAPIResponse(impression) {
  return {
    ...impression,
    embeddedResourceType: impression.resource_type,
  }
}

export function prepareImpressionsFromAPIResponse(_impressions) {
  return _impressions.map(impression =>
    prepareImpressionFromAPIResponse(impression),
  )
}

export function remove(
  uuid: string,
  item: { resource: ResourceProps },
  cb = noop,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/impressions/${uuid}`,
      options: {
        method: 'delete',
      },
      modifyResponse: () => ({
        uuid,
        resourceUuid: item.resource.uuid,
        resourceType: item.resource.resourceType,
      }),
      onSuccess: dispatch => {
        cb() // will redirect to the book page if needed
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'impression',
            object_id: uuid,
          }),
        )
        dispatch({ type: IMPRESSION_CURRENT_USER_CLEAN })
      },
      types: [IMPRESSION_REMOVE, IMPRESSION_REMOVED],
    },
  }
}

// load current user's impression for a particular book by bookId
export function loadCurrentUserImpression({
  resourceUuid,
  resourceType,
  onSuccess = noop,
  onError = noop,
}: {
  resourceUuid: string
  resourceType: string
  onSuccess?: (...arg) => void
  onError?: () => void
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/${resourceType}s/${resourceUuid}/impression`,
      normalize: async response => {
        const { result, entities } = normalize(
          prepareImpressionFromAPIResponse(response.impression),
          impressionSchema,
        )

        return {
          result,
          entities,
          impression: entities.impressions[result],
        }
      },
      onSuccess: (dispatch, getState, response) => {
        dispatch({
          data: { resourceUuid, resourceType, uuid: response.impression.uuid },
          type: IMPRESSION_CURRENT_USER_LOADED,
        })
        onSuccess(dispatch, getState, response)
      },
      onError,
      preserveError: true,
      types: [IMPRESSION_LOADING, IMPRESSION_LOADED, IMPRESSION_LOAD_FAILURE],
    },
  }
}

export function loadImpression(uuid: string) {
  return async (
    dispatch: Dispatch,
    getState: GetState,
  ): Promise<void | ApiAction> => {
    const impression = getImpressionById(getState(), uuid)

    if (impression) {
      dispatch({ type: IMPRESSION_LOADED, impression })
      return
    }

    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/impressions/${uuid}`,
        normalize: async response => {
          const { result, entities } = normalize(
            prepareImpressionFromAPIResponse(response.impression),
            impressionSchema,
          )

          return {
            result,
            entities,
            impression: entities.impressions[result],
          }
        },
        types: [IMPRESSION_LOADING, IMPRESSION_LOADED, IMPRESSION_LOAD_FAILURE],
        ignoreError: true,
      },
    })
  }
}

function toggleLike({
  uuid,
  liked,
}: {
  uuid: string
  liked: boolean
  likes_count: number
  dispatch: Dispatch
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/impressions/${uuid}/likers`,
      options: {
        method: liked ? 'delete' : 'post',
        dontParse: true,
      },
      modifyResponse: () => ({ uuid, liked }),
      onSuccess: dispatch => {
        dispatch(
          analyticsEvent(LIKE_BUTTON_PRESSED, {
            object_type: 'impression',
            object_id: uuid,
          }),
        )
      },
      types: [IMPRESSION_LIKE, IMPRESSION_LIKED],
    },
  }
}

export const throttledToggleLike = throttledLikeHelper(
  toggleLike,
  IMPRESSION_LIKED,
)

const initialState = {}

export default function impressions(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'impressions')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }

  switch (action.type) {
    case IMPRESSION_ADDED:
      return {
        ...state,
        [action.impression.uuid]: action.impression,
      }

    case IMPRESSION_CHANGED: {
      const changedImpression = state[action.impression.uuid]

      return {
        ...state,
        [action.impression.uuid]: {
          ...changedImpression,
          ...action.impression,
        },
      }
    }

    case IMPRESSION_REMOVED:
      return omit(state, action.uuid)

    case IMPRESSION_LIKED: {
      const impression = state[action.uuid]

      if (typeof action.likes_count !== 'number') return state

      return {
        ...state,
        [action.uuid]: {
          ...impression,
          likes_count: action.liked
            ? action.likes_count - 1
            : action.likes_count + 1,
          liked: !action.liked,
        },
      }
    }

    case COMMENT_ADDED:
    case COMMENT_REMOVED: {
      const impression = state[action.resourceUuid]

      if (!impression) return state

      return {
        ...state,
        [action.resourceUuid]: {
          ...impression,
          comments_count: Math.max(
            impression.comments_count +
              (action.type === COMMENT_ADDED ? 1 : -1),
            0,
          ),
        },
      }
    }

    default:
      return state
  }
}

import { ComicbookProps as Comicbook } from 'client/shared/types/comicbook'

export default {
  canBeRead(comicbook: Comicbook) {
    return comicbook.can_be_read
  },

  isAdded(comicbook: Comicbook) {
    return Boolean(
      comicbook &&
        comicbook.comic_card &&
        comicbook.comic_card.state !== 'removed',
    )
  },

  isPublic(comicbook: Comicbook) {
    return comicbook && comicbook.comic_card && !comicbook.comic_card.hidden
  },

  isPrivate(comicbook: Comicbook) {
    return comicbook && comicbook.comic_card && comicbook.comic_card.hidden
  },

  isFinished(comicbook: Comicbook) {
    return (
      comicbook &&
      comicbook.comic_card &&
      comicbook.comic_card.state === 'finished'
    )
  },

  inProgress(comicbook: Comicbook) {
    return (
      comicbook &&
      comicbook.comic_card &&
      comicbook.comic_card.state !== 'finished' &&
      comicbook.comic_card.progress > 0
    )
  },

  getProgressInPercent(comicbook: Comicbook) {
    const { comic_card: comicCard, pages_count: pagesTotal } = comicbook
    if (comicCard && pagesTotal) {
      // comicCard has the `progress` field that stores the number of the page that user has got to
      return Math.min(
        99,
        Math.max(1, Math.floor((comicCard.progress / pagesTotal) * 100)),
      )
    }
  },
}

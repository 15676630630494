import React from 'react'
import {
  Router,
  RouterContext,
  browserHistory,
  applyRouterMiddleware,
  match,
} from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import useScroll from 'react-router-scroll/lib/useScroll'

import { ERRORS_POINT_NAME } from 'points'
import IntlWrapper from './intl-wrapper'

import { setUtmParams } from 'client/shared/reducers/app-reducer'
import { screenShown } from 'client/shared/reducers/analytics-reducer'

function getUtmParams(location) {
  return {
    utm_source: location.query.utm_source,
    utm_medium: location.query.utm_medium,
    utm_campaign: location.query.utm_campaign,
  }
}

function ClientCreatorComponent({ store, routes, history }) {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <IntlWrapper>
          <Router
            routes={routes}
            history={history}
            render={applyRouterMiddleware(
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useScroll((prevRouterProps, props) => {
                return !props.routes.some(route => route.ignoreScrollBehavior)
              }),
            )}
          />
        </IntlWrapper>
      </Provider>
    </HelmetProvider>
  )
}

export function clientContainerCreator({ store, routes }) {
  store = typeof store === 'function' ? store() : store
  const history = syncHistoryWithStore(browserHistory, store)
  const location = history.getCurrentLocation()
  const utmParams = getUtmParams(location)

  store.dispatch(setUtmParams(utmParams))
  store.dispatch(screenShown())

  history.listen(handleHistoryUpdate.bind(null, store, routes))

  return (
    <ClientCreatorComponent store={store} routes={routes} history={history} />
  )
}

export function serverContainerCreator({ store, renderProps, helmetContext }) {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <IntlWrapper>
          <RouterContext {...renderProps} />
        </IntlWrapper>
      </Provider>
    </HelmetProvider>
  )
}

function handleHistoryUpdate(store, routes, location) {
  // eslint-disable-next-line handle-callback-err
  match({ routes, location }, (err, redirect, renderProps) => {
    const state = store.getState()
    const {
      app: { pointName, firstRender },
    } = state
    const utmParams = getUtmParams(location)

    store.dispatch(setUtmParams(utmParams))
    store.dispatch(screenShown())

    if (!firstRender && (!renderProps || pointName === ERRORS_POINT_NAME)) {
      window.location.reload(true)
    }

    if (location.hash) {
      handleLocationHash(location.hash.replace('#', ''))
    }
  })
}

function handleLocationHash(hash) {
  const element = document.getElementById(hash)
  if (element) {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      element.scrollIntoView()
    }, 50)
  }
}

import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import urlFor from 'shared/tools/url-helper'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { Heading } from 'client/shared/blocks/heading'
import Linka from 'client/shared/blocks/linka'
import MetaCounters from 'client/bookmate/blocks/meta-counters'
import { AuthorsList } from 'client/shared/blocks/authors-list'
import SeriesCover from 'client/shared/blocks/series-cover'
import TextTruncate from 'client/shared/blocks/text-truncate'

import { SeriesProps } from 'client/shared/types/series'

import './series.styl'

type Props = {
  series: SeriesProps
  kind: 'list' | 'grid'
  id?: string
  onClick?: () => void
} & DecoratorProps

class Series extends Component<Props> {
  static defaultProps = {
    kind: 'list',
  }

  getCounters(): { kind: string; count?: number }[] {
    const {
      series: { followers_count, parts_count },
    } = this.props

    const counters = [
      {
        kind: 'books',
        count: parts_count,
      },
      {
        kind: 'users',
        count: followers_count,
      },
    ]

    return counters.filter(({ count }) => count > 0)
  }

  getGridCardSize() {
    return {
      desktopLarge: 408,
      desktopMedium: 408,
      desktop: 350,
      tablet: 288,
      mobile: 288,
    }
  }

  render() {
    const {
      app,
      series,
      series: { authors, title, has_new_parts: hasNewParts = false },
      isMobileSize,
      isTabletSize,
      kind,
      id,
      onClick,
    } = this.props

    return (
      <div
        onClick={onClick}
        id={id}
        className={cn(`series series_${kind}`, {
          [`series_${this.getGridCardSize()[app.size]}`]: kind === 'grid',
        })}
      >
        <div className="series__description">
          <AuthorsList
            authors={authors}
            className="series__authors"
            kind="compact"
          />
          <Linka
            title={title}
            className="series__title"
            path={`${urlFor.series(series.uuid, app.storedQuery)}`}
          >
            <Heading
              rank={isTabletSize() || isMobileSize() ? 3 : 2}
              kind="kazimir"
              disableHeadingTag
            >
              <TextTruncate lines={2} text={title} textType="title" />
            </Heading>
          </Linka>
          <div className="series__labels-wrapper">
            {hasNewParts && (
              <div className="series__new-episode-label">
                <Trans id="series.new_episodes" />
              </div>
            )}
            <MetaCounters counters={this.getCounters()} />
          </div>
        </div>
        <div className="series__cover">
          <SeriesCover series={series} />
        </div>
      </div>
    )
  }
}

export default uiBox(Series)

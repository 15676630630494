import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'

import urlFor from 'shared/tools/url-helper'
import prepareComponent from 'client/shared/decorators/prepare-component'
import AppWrapper, { prepare } from 'client/shared/app-wrapper'
import { UploadDropZoneBox } from 'client/bookmate/boxes/upload-drop-zone-box'

import { State } from 'shared/types/redux'

import './app.styl'
import { UploadState } from './blocks/upload/upload'

const prepareWrapper = prepareComponent(prepare, {
  userListener: true,
  name: 'App',
})
const connectWrapper = connect((state: State) => ({
  upload: state.upload,
}))
const wrappers = compose(prepareWrapper, connectWrapper)

export const App = wrappers(
  class App extends Component<{ upload: UploadState }> {
    render(): JSX.Element {
      const {
        upload: { showDropZone },
      } = this.props

      return (
        <>
          <AppWrapper {...this.props} />
          <UploadDropZoneBox
            kind="fullscreen"
            hidden={!showDropZone}
            disabledAt={[
              urlFor.upload(),
              urlFor.upload('link'),
              urlFor.bookmateRun(),
            ]}
          />
        </>
      )
    }
  },
)

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import * as codeActions from 'client/bookmate/reducers/code-reducer'
import Meta from 'client/shared/blocks/meta'
import MetaTitle from 'client/shared/blocks/meta-title'
import { MetaDescription } from 'client/shared/blocks/meta-description'
import Footer from 'client/shared/blocks/footer'
import Spacer from 'client/shared/blocks/spacer'
import { Dispatch, State } from 'shared/types/redux'
import { Location } from 'client/shared/types/react-router'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as CodeState } from 'client/bookmate/reducers/code-reducer'
import { A1Layout } from '../blocks/a1-layout/a1-layout'
import prepareComponent from 'client/shared/decorators/prepare-component'
import { A1_TO_LIBRARY_LINK } from '../boxes/a1-success-box'

type Props = {
  location: Location
  code: CodeState
  dispatch: Dispatch
  currentUser: CurrentUserState
  isDesktopAllSizes(): boolean
} & DecoratorProps

export const A1 = 'A1'

class A1Page extends Component<Props> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      auth,
      dispatch,
      code: { codeValue },
      location,
    } = this.props

    if (auth && !prevProps.auth && codeValue) {
      dispatch(codeActions.checkCode({ codeValue, query: location.search }))
    }
  }

  handleInput = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    const { dispatch } = this.props

    dispatch(
      codeActions.setCode({
        codeValue: e.target.value,
        autocomplete: false,
      }),
    )
  }

  handleForm = (e: React.SyntheticEvent<HTMLFormElement>): void => {
    const {
      currentUser: { auth },
      code: { codeValue },
      dispatch,
      location,
    } = this.props

    e.preventDefault()

    if (auth)
      dispatch(codeActions.checkCode({ codeValue, query: location.search }))
  }

  render(): JSX.Element {
    const {
      isDesktopAllSizes,
      isTabletSize,
      currentUser: { auth, data },
      code,
    } = this.props

    const title = 'Bookmate pretplata za A1 korisnike u Hrvatskoj'
    const description =
      'Uživajte u 30 dana besplatne Bookmate pretplate za A1 korisnike u Hrvatskoj. 16 jezika, preko 500.000 knjiga i audio-knjiga! Pridružite se sada!'

    return (
      <Fragment>
        <MetaTitle title={title} og={title} />
        <MetaDescription description={description} og={description} />
        <Meta
          content="https://a.bmstatic.com/iu/180/170/Sharing pic Twitter (1)-c69d9a130594c901de254503b4d38b5a.png"
          property="twitter:image"
        />
        <Meta
          content="https://a.bmstatic.com/iu/81/28/Sharing pic FB (1)-3172ebbc18cc93625181af6807f9e37e.png"
          property="og:image"
        />
        <A1Layout
          code={code}
          locale={data.locale}
          auth={auth}
          handleForm={this.handleForm}
          handleInput={this.handleInput}
          isDesktop={isDesktopAllSizes() || isTabletSize()}
        />
        <Spacer size={64} />
        <Footer libraryLink={A1_TO_LIBRARY_LINK} />
      </Fragment>
    )
  }
}

const prepareWrapper = prepareComponent(async function ({ store, location }) {
  const { dispatch } = store
  const { query } = location
  const { promo: codeValue } = query
  if (codeValue) {
    dispatch(
      codeActions.setCode({
        codeValue,
      }),
    )
  }
})

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  code: state.code,
}))

const wrappers = compose(prepareWrapper, connectWrapper, uiBox)

export default wrappers(A1Page)

import React, { Component } from 'react'

import './loader.styl'

const COLORS = {
  white: '#fff',
  gray: '#d8d4d0',
}

type Props = {
  size: number
  strokeWidth: number
  color: 'white' | 'gray'
  kind: 'default' | 'big'
  centered: boolean
}

export class Loader extends Component<Props> {
  static defaultProps = {
    color: 'white',
    kind: 'default',
    strokeWidth: 2,
    size: 24,
    centered: false,
  }

  render(): JSX.Element {
    const {
      size: _size,
      strokeWidth: _strokeWidth,
      color,
      kind,
      centered,
    } = this.props

    let size = _size
    let strokeWidth = _strokeWidth

    if (kind === 'big') {
      size = 48
      strokeWidth = 4
    }

    const centeredKind = centered ? 'loader_centered' : ''

    return (
      <div
        className={`loader loader_${kind} ${centeredKind}`}
        style={{ width: size, height: size }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          height={size}
          width={size}
          viewBox={`0 0 ${size} ${size}`}
        >
          <circle
            cx={size / 2}
            cy={size / 2}
            r={size / 2 - strokeWidth / 2}
            strokeWidth={strokeWidth}
            stroke={COLORS[color]}
          />
        </svg>
      </div>
    )
  }
}

import { Trans } from '@lingui/react'
import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'
import ChevronIcon from './chevron.svg'
import ArrowIcon from './arrow.svg'
import TickIcon from './tick.svg'
import { withI18n, withI18nProps } from '@lingui/react'
import { Dropdown } from 'client/shared/blocks/dropdown/dropdown'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { compose } from 'lodash/fp'
import { noop } from 'lodash'
import './sorting.styl'

const AVAILABLE_OPTIONS: OrderByOption[] = [
  'popularity',
  'publication_date',
  'alphabetically',
]

const DESC = 'desc'
const ASC = 'asc'

export type OrderByOption = 'popularity' | 'publication_date' | 'alphabetically'
export type OrderDirectionOption = 'desc' | 'asc'

type State = {
  isDropdownShown: boolean
  orderBy: OrderByOption
  orderDirection: OrderDirectionOption
}

type Props = {
  onSelect: ({
    order_by,
    order_direction,
  }: {
    order_by: OrderByOption
    order_direction: OrderDirectionOption
  }) => void
} & withI18nProps &
  DecoratorProps

class _SortingList extends Component<Props, State> {
  state = {
    isDropdownShown: false,
    orderBy: 'popularity' as OrderByOption,
    orderDirection: DESC as OrderDirectionOption,
  }

  selectOrder(option: OrderDirectionOption): void {
    if (option === this.state.orderDirection) return
    this.setState({
      orderDirection: option,
    })
    this.props.onSelect({
      order_direction: option,
      order_by: this.state.orderBy,
    })
  }

  selectOrderType(option: OrderByOption): void {
    if (option === this.state.orderBy) return
    const orderDirection =
      option === 'alphabetically' ? ASC : this.state.orderDirection
    this.setState({
      isDropdownShown: false,
      orderBy: option,
      orderDirection,
    })
    this.props.onSelect({
      order_by: option,
      order_direction: orderDirection,
    })
  }

  render(): JSX.Element {
    const { i18n, isMobileSize } = this.props

    return (
      <div className="sorting_wrapper">
        <span className="sorting-title">
          <Trans id={`library.sort`} />
        </span>
        <button
          aria-label={i18n.t`library.sort_by`}
          className={`sorting-selected ${
            this.state.isDropdownShown ? 'sorting-open' : 'sorting-closed'
          }`}
          onClick={() => this.setState({ isDropdownShown: true })}
        >
          <Trans id={`library.sort_by_${this.state.orderBy}`} />
          <SVGInline svg={ChevronIcon} className="sorting-chevron" />
          <Dropdown
            offsetTop={30}
            offsetRight={-25}
            allSameSized
            onClose={() =>
              this.setState({
                isDropdownShown: false,
              })
            }
            hidden={!this.state.isDropdownShown}
          >
            {AVAILABLE_OPTIONS.map((option: OrderByOption) => {
              const isSelected = option === this.state.orderBy
              return (
                <div
                  onTouchEnd={
                    isMobileSize() ? () => this.selectOrderType(option) : noop
                  }
                  onClick={() => this.selectOrderType(option)}
                  className={`option${isSelected ? ' selected' : ''}`}
                >
                  <span className="option-desc">
                    <SVGInline
                      svg={TickIcon}
                      className={`selected-tick ${isSelected ? 'visible' : ''}`}
                    />
                    <Trans id={`library.sort_by_${option}`} />
                    {!isSelected && <SVGInline
                        svg={ArrowIcon}
                        className={`sorting-order ${
                          this.state.orderDirection === DESC ? DESC : ASC
                        }`}
                      />
                    }
                  </span>
                  {isSelected && (
                    <span
                      onTouchEnd={
                        isMobileSize()
                          ? () =>
                              this.selectOrder(
                                this.state.orderDirection === DESC ? ASC : DESC,
                              )
                          : noop
                      }
                      onClick={() =>
                        this.selectOrder(
                          this.state.orderDirection === DESC ? ASC : DESC,
                        )
                      }
                    >
                      <SVGInline
                        svg={ArrowIcon}
                        className={`sorting-order ${
                          this.state.orderDirection === DESC ? DESC : ASC
                        }`}
                      />
                    </span>
                  )}
                </div>
              )
            })}
          </Dropdown>
        </button>
        <span
          className="chevron-icon"
          onTouchEnd={
            isMobileSize()
              ? () =>
                  this.selectOrder(
                    this.state.orderDirection === DESC ? ASC : DESC,
                  )
              : noop
          }
          onClick={() =>
            this.selectOrder(this.state.orderDirection === DESC ? ASC : DESC)
          }
        >
          <SVGInline
            svg={ArrowIcon}
            className={`sorting-order ${
              this.state.orderDirection === DESC ? DESC : ASC
            }`}
          />
        </span>
      </div>
    )
  }
}

const wrappers = compose(uiBox, withI18n({ update: true }))
export const SortingList = wrappers(_SortingList)

import { CALL_API } from 'shared/middlewares/api-middleware'
import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

import {
  analyticsEvent,
  FRIEND_FOLLOW,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'

import { checkEntities } from 'client/bookmate/helpers/entities-helper'

const USER_FOLLOWING = 'USER_FOLLOWING'
export const USER_FOLLOW = 'USER_FOLLOW'
export const USER_UNFOLLOW = 'USER_UNFOLLOW'

export function getUserById(state, id) {
  return state.entities.users[id] || {}
}

export function getUsersByIds(state, ids) {
  return ids.map(id => getUserById(state, id))
}

export function follow(login, analytics) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/users/${login}/followings`,
      options: {
        method: 'post',
        dontParse: true,
      },
      onSuccess: dispatch => {
        dispatch(analyticsEvent(FRIEND_FOLLOW, analytics))
      },
      types: [USER_FOLLOWING, USER_FOLLOW],
    },
    data: { login },
  }
}

export function unfollow(user) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/users/${user.login}/followings`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      onSuccess: dispatch => {
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'user',
            object_id: user.id,
          }),
        )
      },
      types: [USER_FOLLOWING, USER_UNFOLLOW],
    },
    data: {
      login: user.login,
    },
  }
}

const initialState = {}

export default function users(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'users')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }
  switch (action.type) {
    case USER_FOLLOW:
      return {
        ...state,
        [action.data.login]: merge({}, state[action.data.login], {
          following: true,
        }),
      }
    case USER_UNFOLLOW:
      return {
        ...state,
        [action.data.login]: merge({}, state[action.data.login], {
          following: false,
        }),
      }
    default:
      return state
  }
}

import React, { Component } from 'react'
import { Trans } from '@lingui/react'

export class RecurrentNotice extends Component<{
  hideLinebreak?: boolean
  price?: JSX.Element | string
  noticeId?: string
}> {
  render(): JSX.Element {
    const { price, noticeId, hideLinebreak } = this.props
    return (
      <div className="stripe-credit-card__recurrent-notice">
        <Trans
          id={noticeId || 'subscription.recurrent_notice'}
          values={{ price, break: hideLinebreak ? '' : <br /> }}
        />
      </div>
    )
  }
}

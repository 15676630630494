import React, { Component, createElement } from 'react'
import classNames from 'classnames'
import SVGInline from 'react-svg-inline'
import omit from 'lodash/omit'

import './button.styl'

import { Loader } from 'client/shared/blocks/loader'
import Linka from 'client/shared/blocks/linka'

type Props = {
  children: React.ReactNode
  icon?: string | Node
  onClick?: (e?: Event) => void
  onSubmit?: (e: Event) => void
  disabled: boolean
  kind?:
    | 'primary'
    | 'secondary'
    | 'inline'
    | 'transparent'
    | 'huge'
    | 'danger'
    | 'facebook'
    | 'twitter'
    | 'google'
    | 'telenor_bulgaria'
    | 'icon'
    | 'apple'
  type?: 'transparent' | 'inline' | 'submit'
  loading: boolean
  hidden: boolean
  width?: string
  pseudo: boolean
  path?: string
  active: boolean
  className: string
  target?: boolean
  id?: string
  'aria-label'?: string
  as?: string
}

class Button extends Component<Props> {
  static defaultProps = {
    disabled: false,
    loading: false,
    hidden: false,
    pseudo: false,
    active: false,
    className: '',
  }

  classes(): string {
    const {
      kind: _kind,
      type,
      disabled,
      loading,
      hidden,
      active,
      className,
    } = this.props

    // to avoid adblock hiding twitter button
    const kind = _kind === 'twitter' ? 'tw' : _kind
    const kindMod = kind ? `button_${kind}` : ''
    const typeMod = type ? `button_${type}` : ''

    return classNames({
      button: true,
      [kindMod]: kind,
      [typeMod]: type,
      button_loading: loading,
      button_hidden: hidden,
      button_disabled: disabled,
      button_active: active,
      [className]: className,
    })
  }

  renderButton(): JSX.Element {
    const {
      children,
      icon,
      disabled,
      loading,
      width,
      ...otherProps
    } = this.props

    return (
      <button
        className={this.classes()}
        {...omit(otherProps, [
          'pseudo',
          'active',
          'path',
          'type',
          'kind',
          'className',
        ])}
        type="submit"
        disabled={disabled || loading}
        style={{ minWidth: width }}
      >
        {icon && <SVGInline svg={icon as string} className="button__icon" />}
        {loading ? <Loader /> : children}
      </button>
    )
  }

  renderPseudo(): JSX.Element {
    const {
      children,
      icon,
      disabled,
      loading,
      width,
      ...otherProps
    } = this.props

    return (
      <div
        role="button"
        className={this.classes()}
        {...omit(otherProps, [
          'pseudo',
          'active',
          'path',
          'type',
          'kind',
          'className',
        ])}
        disabled={disabled || loading}
        style={{ minWidth: width }}
      >
        {icon && <SVGInline svg={icon as string} className="button__icon" />}
        {loading ? <Loader /> : children}
      </div>
    )
  }

  renderLink(): JSX.Element {
    const {
      children,
      icon,
      disabled,
      loading,
      width,
      path,
      target,
      as = 'button',
      ...otherProps
    } = this.props

    const ButtonEl: JSX.Element = createElement(as, {
      className: 'button_link',
      children: (
        <>
          {icon && <SVGInline svg={icon as string} className="button__icon" />}
          {loading ? <Loader /> : children}
        </>
      ),
    })

    return (
      <Linka
        path={path}
        className={this.classes()}
        {...omit(otherProps, ['pseudo', 'active', 'type', 'kind', 'className'])}
        disabled={disabled || loading}
        style={{ minWidth: width as string }}
        target={target ? '_blank' : '_self'}
      >
        {ButtonEl}
      </Linka>
    )
  }

  render(): JSX.Element {
    const { pseudo = false, path } = this.props

    if (path) {
      return this.renderLink()
    } else {
      return pseudo ? this.renderPseudo() : this.renderButton()
    }
  }
}

export default Button

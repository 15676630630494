import React, { Component } from 'react'
import BadgeList from 'client/shared/blocks/badge-list'
import MetaCounters from 'client/bookmate/blocks/meta-counters'
import TextTruncate from 'client/shared/blocks/text-truncate'
import { AuthorsList } from 'client/shared/blocks/authors-list'
import Linka from 'client/shared/blocks/linka'
import BookStatus from 'client/shared/blocks/book-status'

import { BookSerialProps } from 'client/shared/types/resource'
import { Size } from 'client/shared/types/cover'

const HEIGHTS = {
  '56': 78,
  '88': 124,
  '120': 176,
  '176': 247,
  '208': 290,
}

export type BookDescriptionProps = {
  book: BookSerialProps
  kind: 'grid' | 'list'
  titleLines: number
  shouldShowMetaCounters: boolean
  shouldShowBadges: boolean
  hasEpisodePosition: boolean
  finished?: boolean
  inPrivate?: boolean
  progress?: number
  bookPath?: string
  isInSlider?: boolean
  coverSize?: Size
}

export class BookDescription extends Component<BookDescriptionProps> {
  getCounters(): { kind: string; count?: number }[] {
    const {
      readers_count,
      quotes_count,
      markers_count,
      impressions_count,
      bookshelves_count,
    } = this.props.book
    const counters = [
      { kind: 'users', count: readers_count },
      {
        kind: 'quotes',
        count: typeof quotes_count === 'number' ? quotes_count : markers_count,
      },
      { kind: 'impressions', count: impressions_count },
      { kind: 'shelves', count: bookshelves_count },
    ].filter(({ count }) => count > 0)

    return counters
  }

  render(): JSX.Element {
    const {
      book,
      kind,
      titleLines,
      shouldShowMetaCounters,
      shouldShowBadges,
      hasEpisodePosition,
      finished,
      progress,
      inPrivate,
      bookPath,
      isInSlider,
      coverSize,
    } = this.props
    const { language, title, authors_objects, labels, cover, authors } = book

    const attributes: { width?: string } = {}
    if (isInSlider) {
      const coverHeight = HEIGHTS[coverSize]
      if (cover.ratio) {
        attributes.width = `${coverHeight * cover.ratio - 1}px`
      }
    }

    const counters = this.getCounters()
    return (
      <div className="book__description" style={{ ...attributes }}>
        <AuthorsList
          authors={authors || authors_objects}
          className="book__authors"
          kind="compact"
        />
        <Linka title={title} className="book__title" path={bookPath}>
          <TextTruncate lines={titleLines} text={title} textType="title" />
        </Linka>
        {['list'].includes(kind) && (
          <div className="book__reading-status">
            <BookStatus
              isFinished={finished}
              progress={progress}
              isPrivate={inPrivate}
              isSerial={book.source_type === 'serial'}
              episodePosition={
                hasEpisodePosition
                  ? book.library_card.current_episode_position
                  : null
              }
            />
          </div>
        )}
        {['list'].includes(kind) &&
          shouldShowMetaCounters &&
          Boolean(counters.length) && <MetaCounters counters={counters} />}
        {['list'].includes(kind) && shouldShowBadges && labels && (
          <BadgeList labels={labels} language={language} />
        )}
      </div>
    )
  }
}

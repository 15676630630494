import React, { Component } from 'react'
import { connect } from 'react-redux'
import { goBack, push } from 'react-router-redux'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import BackButton from 'client/shared/blocks/back-button'
import HeaderAvatarBox from 'client/shared/boxes/header-avatar-box'

import { CurrentUserState } from 'client/shared/types/current-user'
import { Dispatch, State } from 'shared/types/redux'

type Props = {
  dispatch: Dispatch
  currentUser: CurrentUserState
  kind?: 'default' | 'white' | 'black' | 'without-avatar'
  onClose?: () => void | null | undefined
  query?: QueryParams
}

class BackButtonBox extends Component<Props> {
  render(): JSX.Element {
    const {
      currentUser: { auth },
      kind,
    } = this.props

    return (
      <BackButton clickHandler={this.onClick} kind={kind}>
        {auth && !['without-avatar'].includes(kind as string) && (
          <HeaderAvatarBox />
        )}
      </BackButton>
    )
  }

  canGoBack() {
    return window.history.length > 2
  }

  onClick = (): void => {
    const { dispatch, onClose, query } = this.props

    if (onClose) {
      onClose()

      return
    }

    dispatch(this.canGoBack() ? goBack() : push(urlFor.library(query)))
  }
}

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  query: state.app.storedQuery,
}))

export default connectWrapper(BackButtonBox)

import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'shared/types/redux'

export type AlertState = {
  kind: AlertKind
  hidden: boolean
  message: string
  values: Record<string, unknown>
  components: JSX.Element[]
}

export type AlertKind = 'success' | 'error' | 'feedback'

const initialState = {
  kind: '',
  hidden: true,
  message: '',
  values: {},
  components: [],
}

const SHOW_TIMEOUT = 5000

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertShow: (state, { payload }) => ({ hidden: false, ...payload }),
    alertHide: () => initialState,
  },
})

export const { alertShow, alertHide } = alertSlice.actions

export default alertSlice.reducer

export function showAlert(
  kind: AlertKind,
  {
    message,
    timeout = SHOW_TIMEOUT,
    values = {},
    components = [],
    html,
  }: {
    timeout?: number
    message?: string
    values?: any
    html?: string
    components?: JSX.Element[]
  },
): (dispatch: Dispatch) => void {
  return dispatch => {
    dispatch(alertShow({ kind, message, values, components, html }))
    setTimeout(() => dispatch(alertHide()), timeout)
  }
}

export function hideAlert(): (dispatch: Dispatch) => void {
  return dispatch => {
    dispatch(alertHide())
  }
}

export const constants = {
  POSITIVE_SHELF_ID: 'CsHUNz99',
  NEGATIVE_SHELF_ID: 'zfCSqmH8',

  APPLE_PAY: 'apple_pay',
  GOOGLE_PAY: 'google_pay',
  LINK: 'link',
  CARDS: 'cards',
  PAYPAL: 'paypal',

  // auth reducer
  AUTH_FORM_LOCK_SUBMIT: 'AUTH_FORM_LOCK_SUBMIT',
  AUTH_FORM_UNLOCK_SUBMIT: 'AUTH_FORM_UNLOCK_SUBMIT',
  AUTH_FORM_CHANGE_STEP: 'AUTH_FORM_CHANGE_STEP',
  AUTH_FORM_CHANGE_FLOW: 'AUTH_FORM_CHANGE_FLOW',
  AUTH_FORM_ERROR: 'AUTH_FORM_ERROR',
  AUTH_FORM_LOADING: 'AUTH_FORM_LOADING',
  AUTH_FORM_CHANGE_PHONE: 'AUTH_FORM_CHANGE_PHONE',
  AUTH_FORM_LOGIN_SUCCESS: 'AUTH_FORM_LOGIN_SUCCESS',
  AUTH_FORM_RESET_CREDENTIAL: 'AUTH_FORM_RESET_CREDENTIAL',
  AUTH_FORM_RESET_PASSWORD: 'AUTH_FORM_RESET_PASSWORD',
  AUTH_FORM_SYNC_VALUES: 'AUTH_FORM_SYNC_VALUES',
  AUTH_FORM_CLEAR: 'AUTH_FORM_CLEAR',
  SOCIAL_AUTH_INIT: 'SOCIAL_AUTH_INIT',
  AUTH_PASSWORD_RESET: 'AUTH_PASSWORD_RESET',
  AUTH_CHECK_IF_REGISTERED: 'AUTH_CHECK_IF_REGISTERED',
  AUTH_CHECK_IF_REGISTERED_SUCCESS: 'AUTH_CHECK_IF_REGISTERED_SUCCESS',
  AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK: 'AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK',
  AUTH_UPDATE_PRE_SIGNUP_FIELDS: 'AUTH_UPDATE_PRE_SIGNUP_FIELDS',

  // book reducer
  BOOK_LOAD: 'BOOK_LOAD',
  BOOK_LOAD_SUCCESS: 'BOOK_LOAD_SUCCESS',
  BOOK_LOAD_ERROR: 'BOOK_LOAD_ERROR',
  BOOK_CHANGED: 'BOOK_CHANGED',
  BOOK_READERS_LOAD: 'BOOK_READERS_LOAD',
  BOOK_READERS_LOAD_SUCCESS: 'BOOK_READERS_LOAD_SUCCESS',
  BOOK_SHELVES_LOAD: 'BOOK_SHELVES_LOAD',
  BOOK_SHELVES_LOAD_SUCCESS: 'BOOK_SHELVES_LOAD_SUCCESS',
  BOOK_RELATED_LOAD: 'BOOK_RELATED_LOAD',
  BOOK_RELATED_LOAD_SUCCESS: 'BOOK_RELATED_LOAD_SUCCESS',
  BOOK_QUOTES_LOAD: 'BOOK_QUOTES_LOAD',
  BOOK_QUOTES_LOAD_SUCCESS: 'BOOK_QUOTES_LOAD_SUCCESS',
  BOOK_IMPRESSIONS_LOAD: 'BOOK_IMPRESSIONS_LOAD',
  BOOK_IMPRESSIONS_LOAD_SUCCESS: 'BOOK_IMPRESSIONS_LOAD_SUCCESS',
  BOOK_REMOVED_FROM_WISHLIST: 'BOOK_REMOVED_FROM_WISHLIST',
  BOOK_REMOVED_FROM_WISHLIST_SUCCESS: 'BOOK_REMOVED_FROM_WISHLIST_SUCCESS',
  BOOK_ADD_TO_WISHLIST: 'BOOK_ADD_TO_WISHLIST',
  BOOK_ADD_TO_WISHLIST_SUCCESS: 'BOOK_ADD_TO_WISHLIST_SUCCESS',
  BOOK_OTHER_VERSIONS_LOAD: 'BOOK_OTHER_VERSIONS_LOAD',
  BOOK_OTHER_VERSIONS_LOAD_SUCCESS: 'BOOK_OTHER_VERSIONS_LOAD_SUCCESS',
  BOOK_EMOTION_RATING_LOAD: 'BOOK_EMOTION_RATING_LOAD',
  BOOK_EMOTION_RATING_LOAD_SUCCESS: 'BOOK_EMOTION_RATING_LOAD_SUCCESS',
  BOOK_SERIES_EPISODES_LOAD: 'BOOK_SERIES_EPISODES_LOAD',
  BOOK_SERIES_EPISODES_LOAD_SUCCESS: 'BOOK_SERIES_EPISODES_LOAD_SUCCESS',

  // comicbook reducer
  COMICBOOK_CARD_ADDING: 'COMICBOOK_CARD_ADDING',
  COMICBOOK_CARD_ADDED: 'COMICBOOK_CARD_ADDED',
  COMICBOOK_CARD_CHANGING: 'COMICBOOK_CARD_CHANGING',
  COMICBOOK_CARD_CHANGED: 'COMICBOOK_CARD_CHANGED',
  COMICBOOK_CARD_REMOVING: 'COMICBOOK_CARD_REMOVING',
  COMICBOOK_CARD_REMOVED: 'COMICBOOK_CARD_REMOVED',
}

import React from 'react'
import SVGInline from 'react-svg-inline'
import { Trans } from '@lingui/react'

import './credit-card-placeholder.styl'

import CardMaskTop from './card-mask-top.svg'
import CardMaskBottom from './card-mask-bottom.svg'

type Props = { error: boolean }

const CreditCardPlaceholder: React.FC<Props> = ({ error }) => {
  const errorMessage = <Trans id="errors.stripe_script_loading_failed" />

  return (
    <div className="credit-card-placeholder">
      <div className="credit-card-placeholder__mask-layer credit-card-placeholder__mask-layer-top">
        <SVGInline
          svg={CardMaskTop}
          className="credit-card-placeholder__mask"
        />
      </div>
      <div className="credit-card-placeholder__mask-layer credit-card-placeholder__mask-layer-bottom">
        <SVGInline
          svg={CardMaskBottom}
          className="credit-card-placeholder__mask"
        />
      </div>
      {error && (
        <div className="credit-card-placeholder__error">{errorMessage}</div>
      )}
    </div>
  )
}

export default CreditCardPlaceholder

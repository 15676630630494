import React, { Component } from 'react'
import { Trans } from '@lingui/react'
import Spacer from 'client/shared/blocks/spacer'
import SVGInline from 'react-svg-inline'
import ErrorIcon from 'client/shared/icons/alert-info.svg'
import { StripeError } from 'client/shared/types/stripe'
import './payment-rejected-error.styl'

type Props = {
  apiError:
    | {
        message: string
      }
    | null
    | undefined
  stripeError: StripeError | null
  getErrorMessage: (error: StripeError) => JSX.Element
}

export class PaymentRejectedError extends Component<Props> {
  render(): JSX.Element {
    const { apiError, stripeError, getErrorMessage } = this.props
    let errorMessage

    if (stripeError) {
      errorMessage = getErrorMessage(stripeError)
    } else if (apiError) {
      errorMessage = apiError.message ? (
        <span>{apiError.message}</span>
      ) : (
        <Trans id="errors.unknown" />
      )
    }

    return (
      <>
        <div className="stripe-credit-card__payment-rejected-error">
          <SVGInline className="stripe-error_icon" svg={ErrorIcon} />
          {errorMessage}
        </div>
        <Spacer size={24} />
      </>
    )
  }
}

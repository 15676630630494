import { normalize } from 'normalizr'
import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import {
  postSchema,
  getPost,
  preparePostFromAPIResponse,
  POST_ADDED,
  POST_CHANGED,
} from 'client/bookmate/reducers/posts-reducer'
import {
  analyticsEvent,
  SHELF_POST_CREATED,
} from 'client/shared/reducers/analytics-reducer'

import { Dispatch, GenericDispatchedEvent, GetState } from 'shared/types/redux'

const POST_FORM_LOADING = 'POST_FORM_LOADING'
const POST_FORM_CLEAR = 'POST_FORM_CLEAR'
const POST_FORM_CHANGE_TEXT = 'POST_FORM_CHANGE_TEXT'
const POST_FORM_ERROR = 'POST_FORM_ERROR'
const POST_FORM_ADD_RESOURCE = 'POST_FORM_ADD_RESOURCE'
const POST_FORM_ADD_POST = 'POST_FORM_ADD_POST'

// resource name can be 'book' | 'audiobook' | 'comicbook' | 'quote' | 'impression';
// but due to problems with typing, it is typed here as string
type ResourceName = string

type ResourceData = {
  type: ResourceName
  id: string
}

type CreatePostDataPayload = {
  shelfUuid: string
  resourceType: ResourceName
  resourceId: string
  annotation: string
}

type EditPostDataPayload = {
  shelfUuid: string
  annotation: string
}

type ChangeTextAction = {
  type: typeof POST_FORM_CHANGE_TEXT
  annotation: string
}

type AddResourceToPostFormAction = {
  type: typeof POST_FORM_ADD_RESOURCE
  resource: ResourceData
}

type AddPostToPostFormAction = {
  type: typeof POST_FORM_ADD_POST
  data: {
    postId: string
    annotation: string
    resource: ResourceData
  }
}

type Action =
  | ChangeTextAction
  | AddResourceToPostFormAction
  | AddPostToPostFormAction

export type State = {
  resource: ResourceData | null | undefined
  postId: string | null | undefined
  annotation: string
  loading: boolean
}

export function changeText(annotation: string) {
  return { type: POST_FORM_CHANGE_TEXT, annotation }
}

export function addResourceToPostForm({
  resourceType,
  resourceId,
}: {
  resourceType: ResourceName
  resourceId: string
}): GenericDispatchedEvent & { resource: { type: ResourceName; id: string } } {
  return {
    type: POST_FORM_ADD_RESOURCE,
    resource: {
      type: resourceType,
      id: resourceId,
    },
  }
}

export function addPostToPostForm(postId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const post = getPost(postId, getState().entities)
    dispatch({
      type: POST_FORM_ADD_POST,
      data: {
        postId,
        annotation: post.annotation,
        resource: {
          type: post.resourceType,
          id: post.resource.uuid,
        },
      },
    })
  }
}

export function clearForm(): GenericDispatchedEvent {
  return {
    type: POST_FORM_CLEAR,
  }
}

export function createPost(
  data: CreatePostDataPayload,
  cb: () => void,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${data.shelfUuid}/posts`,
      options: {
        method: 'post',
        data: preparePostDataForSubmission(data),
      },
      normalize: response => {
        const post = preparePostFromAPIResponse(response.post)
        const { result, entities } = normalize(post, postSchema)

        return {
          result,
          entities,
          post,
          shelfUuid: data.shelfUuid,
        }
      },
      onSuccess: (dispatch, getState, response) => {
        dispatch(
          analyticsEvent(SHELF_POST_CREATED, {
            shelf_post_id: response.result,
          }),
        )
        dispatch(clearForm())
        cb(response.post)
      },
      onError: dispatch => {
        dispatch({ type: POST_FORM_ERROR })
      },
      types: [POST_FORM_LOADING, POST_ADDED],
    },
  }
}

function preparePostDataForSubmission(data: {
  annotation?: string
  resourceId: string
  resourceType: ResourceName
}) {
  const post: {
    resource_name: string
    resource_uuid: string
    annotation?: string
  } = {
    resource_name: resourceTypeToApiResourceName(data.resourceType),
    resource_uuid: data.resourceId,
  }
  if (data.annotation) {
    post.annotation = data.annotation
  }
  return { post }
}

function resourceTypeToApiResourceName(resourceType) {
  if (resourceType === 'quote') {
    return 'marker'
  } else if (resourceType === 'serial') {
    return 'book'
  } else {
    return resourceType
  }
}

export function editPost(
  uuid: string,
  data: EditPostDataPayload,
  cb: () => void,
): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${data.shelfUuid}/posts/${uuid}`,
      options: {
        method: 'put',
        data: {
          post: {
            annotation: data.annotation,
          },
        },
      },
      normalize: response => {
        const { entities, result } = normalize(response.post, postSchema)

        return {
          result,
          entities,
          post: response.post,
        }
      },
      onSuccess: (dispatch, getState, response) => {
        dispatch(clearForm())
        cb(response.post)
      },
      types: [POST_FORM_LOADING, POST_CHANGED],
    },
  }
}

const initialState = {
  resource: null,
  postId: null,
  annotation: '',
  loading: false,
}

export default function postForm(state: State = initialState, action: Action) {
  switch (action.type) {
    case POST_FORM_LOADING:
      return {
        ...state,
        loading: true,
      }
    case POST_FORM_ADD_RESOURCE:
      return {
        ...state,
        resource: action.resource,
      }
    case POST_FORM_ADD_POST:
      return {
        ...state,
        ...action.data,
      }
    case POST_FORM_CHANGE_TEXT:
      return {
        ...state,
        annotation: action.annotation,
      }
    case POST_FORM_CLEAR:
      return initialState
    case POST_FORM_ERROR:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import compose from 'lodash/fp/compose'

import {
  unfollow as unfollowShelf,
  remove,
} from 'client/bookmate/reducers/shelves-reducer'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import DeleteIcon from 'client/shared/icons/delete.svg'
import EditIcon from 'client/shared/icons/edit.svg'
import {
  MenuKind,
  ContextMenuButton,
} from 'client/shared/blocks/context-menu-button'

import { FullShelfProps } from 'client/shared/types/shelf'
import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from '../types/current-user'

type Props = {
  shelf: FullShelfProps
  kind: MenuKind
  dispatch: Dispatch
  onStateChange: () => void
  currentUser: CurrentUserState
  query?: QueryParams
} & withI18nProps

class _ShelfContextMenuBox extends Component<Props> {
  getMenuData() {
    const {
      i18n,
      shelf: { editable },
    } = this.props

    const items: { title: string; action: () => void; icon: string }[] = []

    if (editable) {
      items.push(
        {
          title: i18n.t`context-menu.edit`,
          action: this.onEditClick,
          icon: EditIcon,
        },
        {
          title: i18n.t`shared.remove`,
          action: this.onRemoveClick,
          icon: DeleteIcon,
        },
      )
    } else {
      items.push({
        title: i18n.t`shelf.unfollow`,
        action: this.onShelfUnfollowClick,
        icon: DeleteIcon,
      })
    }

    return { items }
  }

  onShelfUnfollowClick = (): void => {
    const { dispatch, shelf: { uuid } = {}, onStateChange } = this.props

    dispatch(unfollowShelf(uuid))
    if (onStateChange) {
      onStateChange()
    }
  }

  onEditClick = (): void => {
    const {
      dispatch,
      shelf: { uuid },
      query,
    } = this.props

    dispatch(push(urlFor.shelfEdit(uuid, query)))
  }

  onRemoveClick = (): void => {
    const {
      dispatch,
      i18n,
      currentUser: {
        data: { login },
      },
      query,
      shelf: { uuid } = {},
    } = this.props

    if (confirm(i18n.t`shelf.delete_shelf_confirm`)) {
      dispatch(remove(uuid))
      dispatch(push(urlFor.userShelves(login, query)))
    }
  }

  render(): JSX.Element {
    const { kind } = this.props

    return <ContextMenuButton menuData={this.getMenuData()} kind={kind} />
  }
}

const wrappers = compose(
  connect((state: State) => ({
    currentUser: state.currentUser,
    qyery: state.app.storedQuery,
  })),
  withI18n({ update: true }),
)

export const ShelfContextMenuBox = wrappers(_ShelfContextMenuBox)

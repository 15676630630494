import { normalize } from 'normalizr'
import merge from 'lodash/merge'
import keys from 'lodash/keys'

import { ResourceName, ShowcaseResource } from 'client/shared/types/resource'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'
import { resourcesSchemaByType } from 'client/bookmate/reducers/schemas/schemas'
import { showcaseSectionSchema } from 'client/bookmate/reducers/schemas/showcase-schemas'

import {
  getShowcaseTag,
  getSectionId,
} from 'client/bookmate/helpers/showcase-helpers'

import {
  analyticsEvent,
  RECOMMENDATIONS_SHOWN,
} from 'client/shared/reducers/analytics-reducer'
import { Locale } from 'client/shared/types/current-user'

const LOAD_SHOWCASE_SECTION = 'LOAD_SHOWCASE_SECTION'
const LOAD_SHOWCASE_SECTION_SUCCESS = 'LOAD_SHOWCASE_SECTION_SUCCESS'
const LOAD_SHOWCASE_SECTION_ERROR = 'LOAD_SHOWCASE_SECTION_ERROR'

const LOAD_SHOWCASE_SECTIONS_RESOURCES = 'LOAD_SHOWCASE_SECTIONS_RESOURCES'
const LOAD_SHOWCASE_SECTIONS_RESOURCES_SUCCESS =
  'LOAD_SHOWCASE_SECTIONS_RESOURCES_SUCCESS'
const LOAD_SHOWCASE_SECTIONS_RESOURCES_ERROR =
  'LOAD_SHOWCASE_SECTIONS_RESOURCES_ERROR'

const CLEAR_SHOWCASE_SECTION = 'CLEAR_SHOWCASE_SECTION'

function getExtendedSection({ section, perPage }) {
  return section
    ? {
        ...section,
        objects: section.objects.length
          ? section.objects.slice(0, perPage)
          : section.objects,
        loading: false,
      }
    : { loading: false }
}

export function loadShowcaseSection({
  sectionSlug,
  showcaseSlug,
  libraryLang,
  contentType,
  params = {},
}: {
  sectionSlug: string
  resourceType?: ResourceName | 'series'
  showcaseTag?: string
  showcaseSlug?: string
  libraryLang?: Locale
  contentType?: ShowcaseResource
  params?: any
}): ApiAction {
  const showcaseTag = getShowcaseTag({
    slug: showcaseSlug,
    language: libraryLang,
    contentType,
  })
  const sectionId = getSectionId(sectionSlug, showcaseTag)

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/showcases/${libraryLang}/${showcaseSlug}/${contentType}/sections/${sectionSlug}`,
      schema: showcaseSectionSchema,
      modifyResponse: response => {
        const section = response.entities.showcaseSections[sectionId]
        if (!section) return response
        const { per_page = 10 } = params

        response.entities.showcaseSections[sectionId] = getExtendedSection({
          section,
          perPage: per_page,
        })

        return response
      },
      types: [
        LOAD_SHOWCASE_SECTION,
        LOAD_SHOWCASE_SECTION_SUCCESS,
        LOAD_SHOWCASE_SECTION_ERROR,
      ],
    },
    sectionSlug,
    showcaseTag,
  }
}

export function loadShowcaseSectionsResources({
  sectionSlug,
  showcaseSlug,
  libraryLang,
  contentType,
  params = {},
}: {
  sectionSlug: string
  resourceType?: ResourceName | 'series'
  showcaseTag?: string
  showcaseSlug: string
  libraryLang: Locale
  contentType: ShowcaseResource
  params?: {
    [key: string]: any
  }
}): ApiAction {
  const showcaseTag = getShowcaseTag({
    slug: showcaseSlug,
    language: libraryLang,
    contentType,
  })
  const {
    page = 1,
    data: _data = {},
    per_page = 10,
    append = false,
    order_by = 'popularity',
    order_direction = 'desc',
  } = params
  const data = {
    ..._data,
    page,
    per_page,
    order_by,
    order_direction,
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/showcases/${libraryLang}/${showcaseSlug}/${contentType}/sections/${sectionSlug}/resources`,
      options: {
        data,
      },
      normalize: response => {
        const resourceKey = keys(response)[0]
        const resourcesType =
          resourceKey === 'bookshelves' ? 'shelves' : resourceKey

        const { entities, result } = normalize(
          response[resourceKey],
          resourcesSchemaByType[resourcesType],
        )
        return {
          entities,
          append,
          nextPage: result.length ? page + 1 : null,
          result: result.map(id => ({
            id,
            schema: resourcesType,
          })),
        }
      },
      onSuccess: (dispatch, getState, response) => {
        const resourcesType = keys(response)[0]
        if (sectionSlug === 'recommendations') {
          dispatch(
            analyticsEvent(RECOMMENDATIONS_SHOWN, {
              [resourcesType]: response.result.map(el => el.id),
            }),
          )
        }
      },
      types: [
        LOAD_SHOWCASE_SECTIONS_RESOURCES,
        LOAD_SHOWCASE_SECTIONS_RESOURCES_SUCCESS,
        LOAD_SHOWCASE_SECTIONS_RESOURCES_ERROR,
      ],
    },
    sectionSlug,
    showcaseTag,
  }
}

const initialState = {}

export default function showcaseSections(state = initialState, action) {
  if (action.entities && action.entities.showcaseSections) {
    return merge({}, state, action.entities.showcaseSections)
  }

  switch (action.type) {
    case LOAD_SHOWCASE_SECTION:
    case LOAD_SHOWCASE_SECTIONS_RESOURCES: {
      const { sectionSlug, showcaseTag } = action
      const sectionId = getSectionId(sectionSlug, showcaseTag)
      const sectionContents = state[sectionId]

      return {
        ...state,
        [sectionId]: {
          ...sectionContents,
          loading: true,
        },
      }
    }

    case LOAD_SHOWCASE_SECTION_ERROR:
    case LOAD_SHOWCASE_SECTIONS_RESOURCES_ERROR: {
      const { sectionSlug, showcaseTag } = action
      const sectionId = getSectionId(sectionSlug, showcaseTag)
      const sectionContents = state[sectionId]

      return {
        ...state,
        [sectionId]: {
          ...sectionContents,
          loading: false,
        },
      }
    }

    case LOAD_SHOWCASE_SECTIONS_RESOURCES_SUCCESS: {
      const { sectionSlug, showcaseTag, append, result, nextPage } = action
      const sectionId = getSectionId(sectionSlug, showcaseTag)
      const sectionContents = state[sectionId]
      return {
        ...state,
        [sectionId]: {
          ...sectionContents,
          objects: append ? [...sectionContents.objects, ...result] : result,
          page: nextPage,
          loading: false,
        },
      }
    }

    case CLEAR_SHOWCASE_SECTION:
      return initialState

    default:
      return state
  }
}

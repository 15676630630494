import React, { Component } from 'react'
import { connect } from 'react-redux'
import { I18nProvider } from '@lingui/react'

import { CurrentUserState } from 'client/shared/types/current-user'

import config from 'config'

interface Locale {
  messages: { [key: string]: string }
  languageData: { [key: string]: string }
}
interface Props {
  translations: {
    [key: string]: Locale
  }
  locale: string
}

interface RootState {
  languages: {
    translations: {
      [key: string]: Locale
    }
  }
  currentUser: CurrentUserState
}

class IntlWrapper extends Component<Props> {
  shouldComponentUpdate(nextProps) {
    return Boolean(nextProps.translations[nextProps.locale])
  }

  prepareCatalogs() {
    const { translations } = this.props

    return Object.keys(translations).reduce((result, locale) => {
      return {
        ...result,
        [locale]: {
          languageData:
            (translations[locale] && translations[locale].languageData) ||
            undefined,
          messages: translations[locale]
            ? translations[locale].messages || translations[locale]
            : {},
        },
      }
    }, {})
  }

  render() {
    const { children, locale: _locale } = this.props
    const locale = config.locales.includes(_locale)
      ? _locale
      : config.fallbackLocale

    return (
      <I18nProvider language={locale} catalogs={this.prepareCatalogs()}>
        {children}
      </I18nProvider>
    )
  }
}

const wrapper = connect((state: RootState) => ({
  translations: state.languages.translations,
  locale: state.currentUser.data.locale,
}))

export default wrapper(IntlWrapper)

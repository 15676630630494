import { QueryParams } from 'shared/tools/url-helper'

export function encodeParams(params: QueryParams): string {
  return Object.keys(params)
    .reduce((acc, key) => {
      return params[key] !== undefined
        ? `${acc}${key}=${encodeURIComponent(params[key])}&`
        : acc
    }, '')
    .slice(0, -1)
}

export function prepareUserSiteLink(link: string): string {
  const regex = /^(?:https?|ftp):\/\//
  const javascriptRegex = /^(javascript:)+/

  if (regex.test(link)) {
    return link
  } else if (javascriptRegex.test(link)) {
    return link.replace(javascriptRegex, '')
  } else {
    return `http://${link}`
  }
}

import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import './book-status.styl'

import FlagIcon from 'client/shared/icons/flag.svg'
import BookmarkIcon from 'client/shared/icons/bookmark.svg'
import LockIcon from 'client/shared/icons/lock.svg'

type Props = {
  progress?: number | null | undefined
  isFinished?: boolean
  isPrivate?: boolean
  episodePosition?: number | null | undefined
  isSerial?: boolean
  isContentHidden?: boolean
}

export default class BookStatus extends Component<Props> {
  render(): JSX.Element {
    const { isPrivate, isContentHidden } = this.props

    return (
      <div className="book-status">
        {this.renderFinishedOrProgress()}
        {!isContentHidden && isPrivate && (
          <div className="book-status_private">
            <SVGInline svg={LockIcon} />
          </div>
        )}
      </div>
    )
  }

  renderFinishedOrProgress(): JSX.Element | null {
    const { isFinished, progress, episodePosition, isSerial } = this.props

    if (isFinished) {
      return (
        <div className="book-status_finished">
          <SVGInline svg={FlagIcon} />
        </div>
      )
    } else if (progress && progress > 0) {
      return (
        <div className="book-status_progress">
          {episodePosition && (
            <span className="book-status_episode">{episodePosition}</span>
          )}
          <span>
            {!isSerial && <SVGInline svg={BookmarkIcon} />}
            {progress}%
          </span>
        </div>
      )
    } else return null
  }
}

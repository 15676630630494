import React from 'react'
import { Trans } from '@lingui/react'
import { withRouter, RouteComponentProps } from 'react-router'

import urlFor from 'shared/tools/url-helper'

import Linka from 'client/shared/blocks/linka'
import Checkbox from 'client/shared/blocks/checkbox'

import { State as AppState } from 'client/shared/reducers/app-reducer'

import './cookies-message.styl'

import * as cookieTypes from 'locales/en/shared.cookies-types.json'
import cookieHelper, { COOKIES_FULL_CONSENT } from 'shared/tools/cookie-helper'
import { CookieConsent } from 'shared/tools/cookie-helper'

type Props = {
  country: string
  disabledAt?: string[]
  app?: AppState
  router: RouteComponentProps
}

type State = {
  display: boolean
  displayCookieSettings: boolean
  cookiesConsent: CookieConsent
}
class _CookiesMessage extends React.Component<Props, State> {
  static defaultProps = {
    disabledAt: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      display: false,
      displayCookieSettings: false,
      cookiesConsent: {
        cookieBasic: true,
        cookiePerformance: false,
        cookieMarketing: false,
      },
    }
  }

  componentDidMount() {
    const shouldShowComponent = !cookieHelper.isConsentGiven()
    this.setState({ display: shouldShowComponent })

    if (cookieHelper.isSoftCookieCountry(this.props.country)) {
      cookieHelper.setFullConsent()
    }
  }

  disabledAtCurrentPage() {
    const {
      disabledAt,
      router: {
        location: { pathname },
      },
    } = this.props

    return disabledAt?.some(path => pathname.includes(path))
  }

  onAcceptAll = (): void => {
    cookieHelper.setConsentGiven()
    cookieHelper.enableAllCategories({ country: this.props.country })
    cookieHelper.setUserChoice(COOKIES_FULL_CONSENT)
    this.setState({ display: false })
  }

  onAcceptChosen = (): void => {
    const { cookiesConsent } = this.state
    const { country } = this.props
    cookieHelper.setConsentGiven()
    cookieHelper.setUserChoice(cookiesConsent)

    if (cookieHelper.isSoftCookieCountry(this.props.country)) {
      cookieHelper.setFullConsent()
    } else {
      cookieHelper.setCustomConsent(cookiesConsent, country)
    }

    this.setState({ display: false })
  }

  toggleSettings = (): void => {
    this.setState({ displayCookieSettings: !this.state.displayCookieSettings })
  }

  onCheckboxChange = (cookieType: string): void => {
    const cookiesConsent = { ...this.state.cookiesConsent }
    cookiesConsent[cookieType] = !cookiesConsent[cookieType]
    this.setState({ cookiesConsent })
  }

  renderCheckboxes = (): JSX.Element => {
    const cookieTypesArray = Object.keys(cookieTypes).filter(item =>
      item.startsWith('cookie'),
    )
    const checkboxesList = cookieTypesArray.map(type => {
      const label = <Trans id={`cookies-types.${type}`} />
      const isAlwaysChecked = type === 'cookieBasic'
      return (
        <Checkbox
          checked={this.state.cookiesConsent[type]}
          id={type}
          label={label}
          onChange={() => this.onCheckboxChange(type)}
          isDisabled={isAlwaysChecked}
        />
      )
    })
    return <div className="cookies-message__checkboxes">{checkboxesList}</div>
  }

  render(): JSX.Element | null {
    const { display, displayCookieSettings } = this.state
    const {
      router: {
        location: { pathname, query },
      },
    } = this.props

    if (
      !display ||
      this.disabledAtCurrentPage() ||
      cookieHelper.isSoftCookieLocation(pathname)
    ) {
      return null
    }

    const legalLink = (
      <Linka
        className="cookies-message__link"
        path={urlFor.legal('/#cookies', query)}
      />
    )

    const settingsListButton = (
      <span
        onClick={this.toggleSettings}
        className="cookies-message__link"
      ></span>
    )

    const acceptMessage = displayCookieSettings
      ? 'cookies-message.accept-all'
      : 'cookies-message.accept'

    return (
      <div className="cookies-message" ref="cookies_message">
        <img
          className="cookies-message__image"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          src={`${ASSETS_PATH}/cookies/cookies.svg`}
        />
        <div className="cookies-message__text">
          <Trans
            id="cookies-message.text"
            components={[<br />, legalLink, settingsListButton]}
          />
        </div>
        {displayCookieSettings && this.renderCheckboxes()}
        <div className="cookies-message__buttons">
          <div
            className="cookies-message__button cookies-message__button_primary"
            onClick={this.onAcceptAll}
          >
            <Trans id={acceptMessage} />
          </div>

          {!this.state.displayCookieSettings && (
            <div
              className="cookies-message__button cookies-message__button_secondary"
              onClick={this.toggleSettings}
            >
              <Trans id={'cookies-message.cookie-settings'} />
            </div>
          )}

          {displayCookieSettings && (
            <div
              className="cookies-message__button cookies-message__button_secondary"
              onClick={this.onAcceptChosen}
            >
              <Trans id="cookies-message.accept-chosen" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CookiesMessage = withRouter(_CookiesMessage)

import React, { Component } from 'react'
import noop from 'lodash/noop'
import classNames from 'classnames'

import './overlay.styl'

const ESC_CODE = 27

class Overlay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
    }
  }

  render() {
    const classes = classNames({
      overlay: true,
      overlay_closing: this.state.closing,
    })

    return (
      <div className={classes} ref="overlay">
        {this.props.children}
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false)
    document.addEventListener('keyup', this.handleEsc, false)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false)
    document.addEventListener('keyup', this.handleEsc, false)
  }

  close() {
    const { onClick = noop } = this.props
    const { closing } = this.state
    if (closing) return
    this.setState({ closing: true })
    onClick()
  }

  handleClick = e => {
    const { overlay } = this.refs

    if (e.target === overlay) {
      this.close()
    }
  }

  handleEsc = e => {
    if (e.keyCode === ESC_CODE) {
      e.preventDefault()
      this.close()
    }
  }
}

export default Overlay

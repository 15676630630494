import React, { PureComponent } from 'react'
import { Trans, withI18n, withI18nProps } from '@lingui/react'
import { withRouter } from 'react-router'
import compose from 'lodash/fp/compose'

import './login-form.styl'

import SignupCheckboxesBox from 'client/shared/boxes/signup-checkboxes-box'
import Linka from 'client/shared/blocks/linka'

import SocialUserinfoPlate from 'client/shared/blocks/social-userinfo-plate'
import Input from 'client/shared/blocks/input'
import CredentialInput from 'client/shared/blocks/credential-input'
import Button from 'client/shared/blocks/button'
import AuthProviders from 'client/shared/blocks/auth-providers'
import Spacer from 'client/shared/blocks/spacer'
import JoinOrLoginText from 'client/shared/blocks/join-or-login-text'

import {
  isFacebookAvailable,
  isTwitterAvailable,
} from 'client/shared/helpers/socials-helpers'
import urlFor from 'shared/tools/url-helper'

import { AuthStep, SocialAuthData } from 'client/shared/reducers/auth-reducer'
import { hide } from 'client/shared/reducers/popup-reducer'
import { Dispatch } from 'shared/types/redux'
import { Location, Params, Router } from 'client/shared/types/react-router'

type CredentialInputProps = {
  loading: boolean
  userpic: string
  onChange: () => void
  onBlur: (arg0: Event) => void
  onBrowserEntered: (email: string) => void
}

type Props = {
  currentStep: AuthStep | null | undefined
  socialAuth: SocialAuthData | null | undefined
  shouldShowPreSignupFields: boolean
  shouldAllowAuth: boolean
  changeStep: (step: AuthStep) => void
  error: string | null | undefined
  loading: boolean
  isUserRegistrationChecked: boolean
  isUserRegistered: boolean
  credentialInputProps: CredentialInputProps
  onSocialButtonClick: (arg0: string) => void
  withAuth: boolean
  country: string
  dispatch: Dispatch
  location: Location
  autoFocus?: boolean
  params: Params
  router: Router
} & withI18nProps

// NOTE: the `withAuth` prop means that the user is outside of the AuthBox context,
// e.g. in the authentication step when buying a subscription

class LoginForm extends PureComponent<Props> {
  static defaultProps = {
    withAuth: false,
  }

  render() {
    return <div className="login-form">{this.renderCurrentStep()}</div>
  }

  renderCurrentStep() {
    const { withAuth, currentStep } = this.props
    if (withAuth && currentStep === 'socialAuthenticated') {
      return this.renderSocialAuthenticatedStep()
    } else {
      return this.renderEmailStep()
    }
  }

  renderEmailStep() {
    const {
      i18n,
      dispatch,
      shouldShowPreSignupFields,
      shouldAllowAuth,
      error,
      loading,
      isUserRegistrationChecked,
      isUserRegistered,
      credentialInputProps,
      withAuth,
      autoFocus = true,
      router,
    } = this.props

    return (
      <>
        <CredentialInput
          inputProps={{
            name: 'email',
            placeholder: i18n.t`auth.email_placeholder`,
            autoFocus,
          }}
          {...credentialInputProps}
        />
        <Input
          type="password"
          name="password"
          placeholder={i18n.t`auth.password_placeholder`}
        />
        <Linka
          className="login-form__subaction"
          path={urlFor.resetPassword(router?.location?.query)}
          onClick={() => dispatch(hide())}
        >
          <Trans id="auth.forgot_password" />
        </Linka>
        {shouldShowPreSignupFields && <SignupCheckboxesBox />}
        {this.showError(error)}
        <Button
          type="submit"
          disabled={!shouldAllowAuth}
          loading={loading}
          kind={isUserRegistered ? undefined : 'primary'}
        >
          <JoinOrLoginText
            isChecked={isUserRegistrationChecked}
            isRegistered={isUserRegistered}
            role="button"
          />
        </Button>
        {withAuth && this.renderAllLoginOptions()}
      </>
    )
  }

  renderSocialAuthenticatedStep() {
    const {
      changeStep,
      shouldAllowAuth,
      socialAuth,
      error,
      loading,
    } = this.props
    // this step is active only during signup; so always show signup checkboxes,
    const toEmail = (
      <span className="auth__error-link" onClick={() => changeStep('email')} />
    )
    const errorElement = error && <Trans id={error} components={[toEmail]} />

    return (
      <>
        <SocialUserinfoPlate {...socialAuth} />
        <Spacer />
        <SignupCheckboxesBox />
        <Spacer size={24} />
        <Button kind="primary" disabled={!shouldAllowAuth} loading={loading}>
          <Trans id="buttons.join" />
        </Button>
        {error && this.showError(errorElement)}
      </>
    )
  }

  showError(error) {
    if (error) {
      return <p className="login-form__error">{error}</p>
    }
  }

  renderAllLoginOptions() {
    const { country, onSocialButtonClick } = this.props
    return (
      <span className="login-form__social">
        <AuthProviders
          onButtonClick={onSocialButtonClick}
          kind="links"
          providersConditions={{
            facebook: isFacebookAvailable(country),
            twitter: isTwitterAvailable(country),
          }}
        />
      </span>
    )
  }
}

const wrappers = compose(withI18n({ update: true }), withRouter)

export default wrappers(LoginForm)

import React from 'react'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'
import { Trans } from '@lingui/react'

export const HeaderNavigationMore = ({
  auth,
  className,
  giftsShown,
  query,
  isHideCode,
  isHideGifts,
}: {
  auth: boolean
  className?: string
  giftsShown?: boolean
  query?: QueryParams
  isHideCode?: boolean
  isHideGifts?: boolean
}): JSX.Element => {
  return (
    <>
      <Linka
        className="header__nav-tab header__nav-tab_center"
        path={urlFor.bookshelves(query)}
        activeClassName="header__nav-tab_active"
      >
        <Trans id="search.bookshelves_header" />
      </Linka>
      {auth && (
        <Linka className={className} path={urlFor.friendsFeed(query)}>
          <Trans id="header.feed_link" />
        </Linka>
      )}
      {giftsShown && !isHideGifts && (
        <Linka
          className="header__nav-tab header__nav-tab_center"
          path={urlFor.gifts(query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="gifts.index_button" />
        </Linka>
      )}
      {!isHideCode && (
        <Linka
          className="header__nav-tab header__nav-tab_center"
          path={urlFor.codePage('', query)}
          activeClassName="header__nav-tab_active"
        >
          <Trans id="code.redeem_code" />
        </Linka>
      )}
    </>
  )
}

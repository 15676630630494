import React, { PureComponent } from 'react'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import Badge, { StyledBadge } from 'client/shared/blocks/badge'

import './badge-list.styl'

import { Label } from 'client/shared/types/book'

type Props = {
  labels: Label[]
  language?: string
  library_lang: string
} & DecoratorProps

class BadgeList extends PureComponent<Props> {
  static defaultProps = {
    labels: [],
  }

  render() {
    return (
      <div className="badge-list">
        {this.renderLanguage()}
        {this.renderLabels()}
      </div>
    )
  }

  renderLanguage() {
    const { language, library_lang } = this.props

    if (language && language !== library_lang) {
      return <Badge type="language">{language}</Badge>
    }
  }

  renderLabels() {
    const { labels } = this.props

    return labels.map((label, i) => (
      <StyledBadge key={i} color={label.color}>
        {label.text}
      </StyledBadge>
    ))
  }
}

export default uiBox(BadgeList)

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import urlFor from 'shared/tools/url-helper'
import deviceHelper from 'shared/tools/device-helper'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'

import { logout } from 'client/shared/reducers/current-user-reducer'

import { Dropdown } from 'client/shared/blocks/dropdown'
import HeaderDropdownContents from 'client/shared/blocks/header-dropdown'
import { UserAvatar } from 'client/shared/blocks/user-avatar'
import Linka from 'client/shared/blocks/linka'

import { State as AppState } from 'client/shared/reducers/app-reducer'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'

const DROPDOWN_TOP_OFFSET = 36

type Props = {
  app: AppState
  currentUser: CurrentUserState
  subscription: SubscriptionState
} & { dispatch: Dispatch }

type State = {
  dropdownShown: boolean
}

class HeaderAvatarBox extends PureComponent<Props, State> {
  state = {
    dropdownShown: false,
  }

  showDropdown = () => {
    this.setState({
      dropdownShown: true,
    })
  }

  hideDropdown = (): void => {
    this.setState({
      dropdownShown: false,
    })
  }

  handleLogout = (e: Event): void => {
    e.preventDefault()
    this.props.dispatch(logout())
  }

  render(): JSX.Element {
    const { app } = this.props

    if (deviceHelper.isMobileSize(app)) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }
  }

  renderDesktop() {
    const {
      currentUser: { data: user },
      subscription: { userAccessLevels },
      app,
    } = this.props
    const hasSubscription = subscriptionHelper.hasAnySubscriptions(
      userAccessLevels,
    )

    return (
      <div
        onMouseEnter={this.showDropdown}
        onClick={this.showDropdown}
        onMouseLeave={this.hideDropdown}
      >
        <UserAvatar user={user} lazyLoading={false} />
        <Dropdown
          position="top-right"
          offsetTop={DROPDOWN_TOP_OFFSET}
          onClose={this.hideDropdown}
          hidden={!this.state.dropdownShown}
        >
          <HeaderDropdownContents
            user={user}
            hasSubscription={hasSubscription}
            handleLogout={this.handleLogout}
            domain={app.domain}
            query={app.storedQuery}
          />
        </Dropdown>
      </div>
    )
  }

  renderMobile() {
    const {
      currentUser: { data: user },
    } = this.props

    return (
      <Linka
        path={urlFor.profile(user.login)}
        activeClassName="header__nav-link_active"
      >
        <UserAvatar user={user} size={24} lazyLoading={false} />
      </Linka>
    )
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  app: state.app,
  currentUser: state.currentUser,
  subscription: state.subscription,
}))

export default connectWrapper(HeaderAvatarBox)

import { CoverProps, OldBookCoverProps } from 'client/shared/types/cover'

type Options = {
  imgHeight?: number
  imgWidth?: number
  dynamicImageSizingSide?: 'width' | 'height' | string
}

export function getCoverOptions(
  cover: CoverProps | OldBookCoverProps,
  imgWidth = 88,
  imgHeight = 124,
): Options {
  const ratio = (cover && cover?.ratio) || null
  const options = {
    imgHeight,
    imgWidth,
    dynamicImageSizingSide: 'width',
  }

  if (!ratio) {
    return options
  }

  if (ratio === 1) {
    options.imgHeight = imgWidth
  }

  if (ratio > imgWidth / imgHeight) {
    options.imgHeight = Math.ceil(imgWidth / ratio)
  }

  if (ratio < imgWidth / imgHeight) {
    options.imgWidth = Math.ceil(imgHeight * ratio)
    options.dynamicImageSizingSide = 'height'
  }

  return options
}

export function getSlidingCoverOptions(
  cover: CoverProps | OldBookCoverProps,
  imgWidth = 88,
  imgHeight = 124,
): Options {
  const options = {
    imgHeight,
    imgWidth,
    dynamicImageSizingSide: 'height',
  }

  if (!cover?.ratio) return options

  options.imgWidth = Math.ceil(imgHeight * cover.ratio)

  return options
}

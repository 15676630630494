import React, { Component } from 'react'
import { connect } from 'react-redux'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { withRouter } from 'react-router'

import './minimal-header.styl'

import Logo, { LogoKind } from 'client/shared/blocks/logo'
import BackButtonBox from 'client/shared/boxes/back-button-box'
import { State } from 'shared/types/redux'
import { compose } from 'lodash/fp'
import { loginMode } from '../../../../shared/constants/login.constants'

type Props = {
  onlyLogo?: boolean
  logoKind?: LogoKind
  onClose?: () => void | null | undefined
  children?: JSX.Element | null
  logoLink?: string
  query?: QueryParams
  isPromoPage?: boolean
  params?: Record<string, string>
}

const connectWrapper = connect((state: State) => ({
  query: state.app.storedQuery,
}))

class _MinimalHeader extends Component<Props> {
  render(): JSX.Element {
    const {
      logoKind = 'default',
      onClose,
      onlyLogo = false,
      children,
      isPromoPage,
      logoLink = urlFor.root(this.props.query),
    } = this.props

    return (
      <div className={`minimal-header ${isPromoPage ? 'seafoam-bg' : ''}`}>
        <div className="minimal-header__logo">
          <Logo href={logoLink} kind={logoKind} />
        </div>
        {!onlyLogo && (
          <div
            className={`minimal-header__back-button minimal-header__back-button_${logoKind}`}
          >
            {this.props.params?.loginmode !== loginMode.mondiaPay && (
              <BackButtonBox onClose={onClose} />
            )}
          </div>
        )}
        {children}
      </div>
    )
  }
}

const wrappers = compose(connectWrapper, withRouter)
export const MinimalHeader = wrappers(_MinimalHeader)

import React, { Component } from 'react'
import { Trans } from '@lingui/react'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { Heading } from 'client/shared/blocks/heading'
import Spacer from 'client/shared/blocks/spacer'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import Linka from 'client/shared/blocks/linka'
import { MinimalHeader } from 'client/shared/blocks/minimal-header'
import FooterStoreButtons from 'client/shared/blocks/footer-store-buttons'
import StoreButtonBox from 'client/shared/boxes/store-button-box'

import './code-success-layout.styl'

type Props = {
  language: string
  country: string
  query?: QueryParams
  libraryLink?: string
} & DecoratorProps

class SuccessLayout extends Component<Props> {
  render(): JSX.Element {
    const { language, isDesktopAllSizes, query, libraryLink } = this.props
    return (
      <div className="code-success-layout">
        <MinimalHeader onlyLogo />
        <LayoutColumn autoWidth centered size={8}>
          <Spacer size={64} />
          <div className="code-success-layout__image" />
          <Spacer size={16} />
          <Heading kind="graphik" centered>
            <Trans id="code.success_header" />
          </Heading>
          <Spacer size={24} />
          <p className="code-success-layout__description">
            <Trans
              id="code.success_subscription"
              values={{ language }}
              components={[
                <Linka
                  className="code-success-layout__link"
                  path={libraryLink || urlFor.library(query)}
                />,
              ]}
            />
          </p>
          <Spacer size={32} />
          {isDesktopAllSizes() && (
            <>
              <div className="code-success-layout__qr" />
              <Spacer size={48} />
            </>
          )}
          <div className="code-success-layout__buttons">
            {isDesktopAllSizes() ? (
              <FooterStoreButtons
                path={libraryLink}
                kind="inline"
                size="big"
                theme="light2"
                order={['android', 'ios']}
              />
            ) : (
              <StoreButtonBox size="big" theme="light2" path={libraryLink} />
            )}
          </div>
          <Spacer size={30} />
        </LayoutColumn>
        <Spacer size="bottom-page" />
      </div>
    )
  }
}

export default uiBox(SuccessLayout)

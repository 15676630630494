import React, { PureComponent } from 'react'
import { Trans } from '@lingui/react'

type Role = 'header' | 'button'

type Props = {
  isChecked: boolean
  isRegistered: boolean
  role: Role
}

class JoinOrLoginText extends PureComponent<Props> {
  render() {
    const { isChecked, isRegistered, role } = this.props
    if (role === 'header' && !isChecked) {
      return <Trans id="auth.join_or_login" />
    } else if (isRegistered) {
      const messageId = role === 'header' ? 'auth.login' : 'buttons.login'
      return <Trans id={messageId} />
    } else {
      const messageId = role === 'header' ? 'auth.join' : 'buttons.join'
      return <Trans id={messageId} />
    }
  }
}

export default JoinOrLoginText

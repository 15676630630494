import React, { Component } from 'react'
import './author.styl'

import Linka from 'client/shared/blocks/linka'
import urlFor, { QueryParams } from 'shared/tools/url-helper'

import { UserAvatar } from 'client/shared/blocks/user-avatar'
import MetaCounters from 'client/bookmate/blocks/meta-counters'

import { AuthorProps } from 'client/shared/types/author'
import PlusBox from 'client/shared/boxes/plus-box'

type Props = {
  author: AuthorProps
  kind: 'list' | 'card'
  query?: QueryParams
  id?: string
  onClick?: () => void
}

export class Author extends Component<Props> {
  renderPic(): JSX.Element {
    const { author, kind } = this.props
    let size: number

    switch (kind) {
      case 'list':
        size = 88
        break
      case 'card':
      default:
        size = 40
    }

    return <UserAvatar user={author} size={size} />
  }

  render(): JSX.Element {
    const { author, kind, query, id, onClick } = this.props
    const authorUrl = urlFor.author(author.uuid, query)

    return (
      <div className={`author author_${kind}`} id={id} onClick={onClick}>
        <div className="author__pic">{this.renderPic()}</div>
        <div className="author__info">
          <Linka className="author__name" title={author.name} path={authorUrl}>
            {author.name}
          </Linka>
          {['list'].includes(kind) && author.works_count > 0 && (
            <MetaCounters
              counters={[{ kind: 'books', count: author.works_count }]}
            />
          )}
        </div>
        <div className="user__follow">
          {['list'].includes(kind) && !author?.following && (
            <PlusBox key={id} author={author} />
          )}
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'

type MetaProps = {
  content: string
  property?: string
  name?: string
}

class Meta extends Component<MetaProps> {
  render(): JSX.Element {
    return <Helmet meta={[{ ...this.props }]} />
  }
}

export default Meta

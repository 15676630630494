export const ACHIEVEMENTS_USER_LOADING = 'ACHIEVEMENTS_USER_LOADING'
export const ACHIEVEMENTS_USER_LOAD_SUCCESS = 'ACHIEVEMENTS_USER_LOAD_SUCCESS'
export const ACHIEVEMENTS_BOOKS_LOADING = 'ACHIEVEMENTS_BOOKS_LOADING'
export const ACHIEVEMENTS_BOOKS_LOAD_SUCCESS = 'ACHIEVEMENTS_BOOKS_LOAD_SUCCESS'
export const ACHIEVEMENTS_FRIENDS_LOADING = 'ACHIEVEMENTS_FRIENDS_LOADING'
export const ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS =
  'ACHIEVEMENTS_FRIENDS_LOAD_SUCCESS'
export const ACHIEVEMENTS_PLEDGE_IN_PROGRESS = 'ACHIEVEMENTS_PLEDGE_IN_PROGRESS'
export const ACHIEVEMENTS_PLEDGE_SUCCESS = 'ACHIEVEMENTS_PLEDGE_SUCCESS'
export const ACHIEVEMENTS_PLEDGE_DELETING =
  'ACHIEVEMENTS_PLEDGE_FOR_CURRENT_YEAR_DELETING'
export const ACHIEVEMENTS_PLEDGE_DELETE_SUCCESS =
  'ACHIEVEMENTS_PLEDGE_FOR_CURRENT_YEAR_DELETE_SUCCESS'
export const ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR =
  'ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_CURRENT_YEAR'
export const ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR =
  'ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_CURRENT_YEAR'
export const ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR =
  'ACHIEVEMENTS_OPEN_PLEDGE_FORM_FOR_NEXT_YEAR'
export const ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR =
  'ACHIEVEMENTS_CLOSE_PLEDGE_FORM_FOR_NEXT_YEAR'

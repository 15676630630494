import { Trans } from '@lingui/react'
import React from 'react'

export const RecaptchaNotice: React.FC = () => {
  const urlRoot = 'https://policies.google.com'
  const linkProps = { target: '_blank', rel: 'noopener noreferrer' }
  return (
    <p className="auth__recaptcha" style={{ padding: '12px 0' }}>
      <Trans
        id="auth.reCAPTCHA_notice"
        components={[
          <br />,
          <a href={`${urlRoot}/privacy`} {...linkProps} />,
          <a href={`${urlRoot}/terms`} {...linkProps} />,
        ]}
      />
    </p>
  )
}

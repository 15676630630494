import { createAction, createReducer } from '@reduxjs/toolkit'
import set from 'lodash/set'

import { CALL_API } from 'shared/middlewares/api-middleware'
import { getMetaId } from '../helpers/meta-helpers'
import {
  availableTypes,
  pluralizeObjectTypeName,
} from 'client/bookmate/helpers/showcase-helpers'
import { decodeEntities } from 'client/shared/helpers/text-helpers'

import { ResourceMetaData, MetaData } from 'client/shared/types/resource'

const LOADING_STATUS = 'loading'
const SUCCESS_LOADING_STATUS = 'success'

const metaDataLoad = createAction('META_DATA_LOAD')
const metaDataLoadSuccess = createAction('META_DATA_LOAD_SUCCESS')

export type State = {
  loadingStates: {
    [key: string]: boolean
  }
  data: {
    [key: string]: MetaData
  }
}

export const loadMetaData = ({
  resourceName,
  resourceUuid,
  language,
  contentType,
}: ResourceMetaData) => {
  let data = { language }

  if (
    contentType &&
    availableTypes.includes(contentType) &&
    contentType !== 'mixed'
  ) {
    data = {
      ...data,
      content_type: pluralizeObjectTypeName(contentType),
    }
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/${resourceName}/${resourceUuid}/seo_metadata`,
      options: {
        data,
      },
      types: [metaDataLoad, metaDataLoadSuccess],
      ignoreError: true,
    },
    id: getMetaId({ resourceName, resourceUuid, language, contentType }),
  }
}

const initialState: State = {
  loadingStates: {},
  data: {},
}

const meta = createReducer(initialState, {
  [metaDataLoad]: (state, { payload }) =>
    set(state, `loadingStates[${payload.id}]`, LOADING_STATUS),

  [metaDataLoadSuccess]: (state, { payload }) => {
    set(state, `loadingStates[${payload.id}]`, SUCCESS_LOADING_STATUS)
    state.data = {
      ...state.data,
      [payload.id]: {
        description: decodeEntities(payload.description),
        title: decodeEntities(payload.title),
      },
    }
  },
})

export default meta

import React, { Component } from 'react'
import noop from 'lodash/noop'
import cn from 'classnames'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import { DropdownItemBox } from 'client/shared/boxes/dropdown-item-box'

import SVGInline from 'react-svg-inline'
import MarkIcon from 'client/shared/icons/mark.svg'

import './dropdown.styl'
import env from 'env'

const TAIL_OFFSET = 30

type Props = {
  items?: Record<string, unknown>[]
  position?: string
  hidden?: boolean
  offsetTop?: number
  offsetRight?: number
  kind?: string
  allSameSized?: boolean
  onClose?: () => void
  optionText?: string
  onSuccess?: () => void
} & DecoratorProps

class _Dropdown extends Component<Props> {
  static defaultProps = {
    position: 'top-center',
    onClose: noop,
  }
  dropdownRef: React.RefObject<HTMLDivElement>

  constructor(props) {
    super(props)
    this.dropdownRef = React.createRef()
  }

  render() {
    const { items, hidden, position, children, allSameSized } = this.props

    return (
      <div
        className={cn('dropdown', `dropdown_${position}`, {
          dropdown_hidden: hidden,
          same_size: allSameSized,
        })}
        ref={this.dropdownRef}
      >
        {children || this.renderItems(items)}
      </div>
    )
  }

  renderItems(items) {
    return items.map(item => {
      const { actionName, action, icon, name, title, selected, asChild } = item
      const { isMobileSize } = this.props

      return (
        <DropdownItemBox
          isMobileSize={isMobileSize()}
          key={actionName || title}
          item={item}
        >
          {asChild ? (
            item.body
          ) : (
            <div
              className="dropdown__item"
              onClick={action}
              onTouchEnd={isMobileSize() ? action : noop}
              data-action={actionName}
            >
              {icon && (
                <SVGInline
                  svg={icon}
                  className={cn(
                    'dropdown__item-icon',
                    `dropdown__item-icon-${name}`,
                  )}
                />
              )}
              <span className="dropdown__item-title">{title}</span>
              {selected && (
                <SVGInline svg={MarkIcon} className="dropdown__item-mark" />
              )}
            </div>
          )}
        </DropdownItemBox>
      )
    })
  }

  componentDidUpdate() {
    const { hidden, isMobileSize } = this.props
    if (hidden || isMobileSize()) return

    this.updatePosition()
  }

  updatePosition() {
    const { position, offsetTop, offsetRight } = this.props

    const dropdown = this.dropdownRef.current

    if (!dropdown) return

    const { offsetWidth, parentNode } = dropdown
    const handleWidth = parentNode?.offsetWidth

    switch (position) {
      case 'top-right':
        dropdown.style.left = `-${
          offsetWidth - handleWidth / 2 - TAIL_OFFSET
        }px`
        break
      case 'top-right-offset':
        dropdown.style.left = `-${offsetRight}px`
        break
      case 'top-left':
        dropdown.style.left = `-${TAIL_OFFSET}px`
        break
      case 'top-center':
        dropdown.style.left = `${-(offsetWidth / 2) + handleWidth / 2}px`
        break
      case 'right':
        dropdown.style.right = '0'
    }

    if (offsetTop) {
      // if a custom offset from the parent element is provided, add it to the dropdown styles
      dropdown.style.top = `${offsetTop}px`
    }
  }

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart, {
      passive: false,
    })
    document.addEventListener('click', this.handleClickOutside, true)
    document.addEventListener('touchend', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    const { onClose } = this.props
    document.removeEventListener('touchstart', this.handleTouchStart, {
      passive: false,
    })
    document.removeEventListener('click', this.handleClickOutside, true)
    document.removeEventListener('touchend', this.handleClickOutside, true)
    if (onClose) onClose()
  }

  handleTouchStart = e => {
    const { onClose, hidden, optionText, onSuccess } = this.props
    if (!hidden && !document.querySelector('.languages')) {
      e.preventDefault()
      if (onClose) onClose()
      if (optionText && env.isClient()) {
        window.navigator.clipboard.writeText(optionText)
        if (onSuccess) onSuccess()
      }
      if (document.querySelector('.floating-action')) {
        document.querySelector('.floating-action').style.visibility = 'visible'
      }
    }
  }
  handleClickOutside = e => {
    const { onClose, hidden } = this.props
    const target =
      e.type === 'touchend' && e.touches.length > 0 ? e.touches[0] : e.target
    const parent = this.dropdownRef.current?.parentNode

    if (!hidden && !parent?.contains(target)) {
      e.preventDefault()
      if (onClose) onClose()
    }
  }
}

export const Dropdown = uiBox(_Dropdown)

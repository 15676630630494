import { normalize, denormalize, Schema } from 'normalizr'
import {
  CALL_API,
  ApiAction,
  ApiErrorAction,
} from 'shared/middlewares/api-middleware'
import { push } from 'react-router-redux'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'

import urlFor from 'shared/tools/url-helper'
import { getResourceNormalizationSchemaByType } from 'client/shared/helpers/resource-helpers'
import { loadInfo } from 'client/shared/reducers/current-user-reducer'
import {
  analyticsEvent,
  SUBSCRIPTION_SUCCESSFUL,
  SUBSCRIPTION_FAILED,
} from 'client/shared/reducers/analytics-reducer'
import { UserShort } from 'client/shared/types/user'
import { ResourceName } from 'client/shared/types/resource'
import { State as ReduxState } from 'shared/types/redux'
import {
  PaymentIntent,
  PAYMENT_INTENT,
} from 'client/shared/reducers/stripe-reducer'
import { FetchMethod } from 'shared/tools/fetch-wrapper'

const CARD_THEMES = [
  'v_first',
  'v_second',
  'v_third',
  'v_fourth',
  'v_fifth',
  'v_sixth',
]

const GIFTS_CARD_CONTENT_UPDATED = 'GIFTS_CARD_CONTENT_UPDATED'
const GIFTS_CARD_THEME_UPDATED = 'GIFTS_CARD_THEME_UPDATED'

const GIFTS_ADD_BOOK_SUGGESTION = 'GIFTS_ADD_BOOK_SUGGESTION'
const GIFTS_CREATE_PAYMENT_INTENT = 'GIFTS_CREATE_PAYMENT_INTENT'
const GIFTS_CREATE_PAYMENT_INTENT_SUCCESS =
  'GIFTS_CREATE_PAYMENT_INTENT_SUCCESS'
const GIFTS_CREATE_PAYMENT_INTENT_ERROR = 'GIFTS_CREATE_PAYMENT_INTENT_ERROR'

const GIFTS_PAYMENT_INTENT_CLEAR = 'GIFTS_PAYMENT_INTENT_CLEAR'

const GIFTS_CREATE_CARD = 'GIFTS_CREATE_CARD'
const GIFTS_CREATE_CARD_SUCCESS = 'GIFTS_CREATE_CARD_SUCCESS'
const GIFTS_CREATE_CARD_ERROR = 'GIFTS_CREATE_CARD_ERROR'

const GIFTS_PLANS_LOAD = 'GIFTS_PLANS_LOAD'
const GIFTS_PLANS_LOAD_SUCCESS = 'GIFTS_PLANS_LOAD_SUCCESS'
const GIFTS_PLANS_LOAD_ERROR = 'GIFTS_PLANS_LOAD_ERROR'

const GIFTS_CHANGE_KIND = 'GIFTS_CHANGE_KIND'
const GIFTS_CHANGE_PLAN = 'GIFTS_CHANGE_PLAN'

const GIFT_CARD_ACTIVATE = 'GIFT_CARD_ACTIVATE'
const GIFT_CARD_ACTIVATE_SUCCESS = 'GIFT_CARD_ACTIVATE_SUCCESS'

const GIFT_CARD_LOAD = 'GIFT_CARD_LOAD'
const GIFT_CARD_LOAD_SUCCESS = 'GIFT_CARD_LOAD_SUCCESS'

const GIFT_CARD_SENDER_DATA_SET = 'GIFT_CARD_SENDER_DATA_SET'
const GIFT_PURCHASE_STATUS_LOAD = 'GIFT_PURCHASE_STATUS_LOAD'
const GIFT_PURCHASE_STATUS_LOAD_SUCCESS = 'GIFT_PURCHASE_STATUS_LOAD_SUCCESS'
const GIFT_PURCHASE_STATUS_LOAD_ERROR = 'GIFT_PURCHASE_STATUS_LOAD_ERROR'
const CLEAR_GIFT_ID = 'CLEAR_GIFT_ID'
const LOCATION_HREF = 'LOCATION_HREF'

export type GiftKind = 'master' | 'premium' | 'audio'

export type GiftPlan = {
  id: string
  price: number
  currency: string
  duration: string
  system: string
  preselected: boolean
  discount_percent: number | null | undefined
  regular_price: number | null | undefined
  trial: false
  kind: GiftKind
}

export type GiftCardInPayload = {
  background: string
  content: string
  resource_uuid: string
  resource_type: ResourceName | null | undefined
  deliver_at: string
  recepient_email?: string
  gifter_email: string
}

type CreatePaymentIntentAction = {
  type: typeof GIFTS_CREATE_PAYMENT_INTENT
}

type CreatePaymentIntentSuccessAction = {
  type: typeof GIFTS_CREATE_PAYMENT_INTENT_SUCCESS
  return_url: string
  client_secret: string
}

type CreatePaymentIntentErrorAction = {
  type: typeof GIFTS_CREATE_PAYMENT_INTENT_ERROR
  error: string
}

type ClearGiftId = {
  type: typeof CLEAR_GIFT_ID
}

type CreateGiftCard = {
  type: typeof GIFTS_CREATE_CARD
}

type CreateGiftSuccessCard = {
  type: typeof GIFTS_CREATE_CARD_SUCCESS
  return_url: string
  client_secret: string
}

type CreateGiftErrorCard = {
  type: typeof GIFTS_CREATE_CARD_ERROR
  error: string
}

type PaymentIntentClearAction = { type: typeof GIFTS_PAYMENT_INTENT_CLEAR }

export type GiftCardMinimal = {
  background: string
  content: string
}

export type GiftCardComplete = {
  uuid: string
  billing_promo: {
    duration: string
    uuid: string
    kind: string
  }
  resource: string
  resource_type: '' | ResourceName
  content: string
  user: UserShort | null | undefined
  background: string
}

type GiftsKinds = {
  [key: string]: {
    plans: GiftPlan[]
    planId: string
    suggestedItemId: string
    suggestedItemType: ResourceName | null | undefined
  }
}

export type CreateGiftCardPayload = {
  source: string
  plan_uuid: string
  gift_card: GiftCardInPayload
  kind?: string
}

type GiftsCardSenderDataSetAction = {
  type: 'GIFT_CARD_SENDER_DATA_SET'
  data: {
    senderEmail: string
    recipientEmail: string
    deliverAt: string
  }
}

type LocationChange = {
  type: typeof LOCATION_HREF
  data: string
}

type GiftsChangePlanAction = {
  type: 'GIFTS_CHANGE_PLAN'
  data: {
    planId: string
  }
}

type GiftsChangeKindAction = {
  type: 'GIFTS_CHANGE_KIND'
  data: {
    kind: string
  }
}

type GiftsPlansLoadAction = {
  type: 'GIFTS_PLANS_LOAD'
  data: {
    loading: boolean
  }
}

type GiftsPlansLoadSuccessAction = {
  type: 'GIFTS_PLANS_LOAD_SUCCESS'
  data: {
    kind: GiftKind
    kinds: GiftsKinds
    availableKinds: GiftKind[]
    loading: boolean
    error: string
  }
}

type GiftsPlansLoadErrorAction = {
  type: 'GIFTS_PLANS_LOAD_ERROR'
  data: {
    loading: boolean
    error: string | null | undefined
  }
}

type GiftsCardContentUpdateAction = {
  type: 'GIFTS_CARD_CONTENT_UPDATED'
  text: string
}

type GiftsCardThemeUpdateAction = {
  type: 'GIFTS_CARD_THEME_UPDATED'
  theme: string
}

type GiftsAddBookSuggestionAction = {
  type: 'GIFTS_ADD_BOOK_SUGGESTION'
  uuid: string
  resourceType: string
}

type GiftCardActivateAction = {
  type: 'GIFT_CARD_ACTIVATE'
}

export type PurchaseStatus = 'processing' | 'completed' | 'failed'

type GiftPurchaseStatusSuccessAction = {
  type: 'GIFT_PURCHASE_STATUS_LOAD_SUCCESS'
  status: PurchaseStatus
  gift_card_uuid: string | null
  error: { message: string | null }
}

type GiftPurchaseStatusErrorAction = {
  type: 'GIFT_PURCHASE_STATUS_LOAD_ERROR'
  status: PurchaseStatus
  error: { message: string }
}

type GiftCardLoadSuccessAction = {
  type: 'GIFT_CARD_LOAD_SUCCESS'
  data: {
    gift_card: GiftCardComplete
  }
}

type Action =
  | GiftsCardSenderDataSetAction
  | GiftsChangePlanAction
  | GiftsChangeKindAction
  | GiftsPlansLoadAction
  | GiftsPlansLoadSuccessAction
  | GiftsPlansLoadErrorAction
  | GiftsCardContentUpdateAction
  | GiftsCardThemeUpdateAction
  | GiftsAddBookSuggestionAction
  | GiftCardActivateAction
  | GiftCardLoadSuccessAction
  | ApiErrorAction
  | GiftPurchaseStatusSuccessAction
  | GiftPurchaseStatusErrorAction
  | LocationChange
  | CreatePaymentIntentAction
  | CreatePaymentIntentSuccessAction
  | CreatePaymentIntentErrorAction
  | PaymentIntentClearAction
  | CreateGiftCard
  | CreateGiftSuccessCard
  | CreateGiftErrorCard
  | ClearGiftId

export type State = {
  kind: GiftKind
  kinds: GiftsKinds
  themes: string[]
  senderEmail: string
  card: GiftCardMinimal
  gift_card: GiftCardComplete
  error: string
  loading: boolean
  deliverAt?: string
  recipientEmail?: string
  purchaseStatus: null | PurchaseStatus
  purchaseError: string
  giftUuid: string
  isComplete?: boolean
  availableKinds: GiftKind[]
  paymentIntent: PaymentIntent
}

function getFilteredPlans(plans: GiftPlan[], kind: string) {
  return plans.filter(plan => plan.kind === kind)
}

export function getGiftCard(state: ReduxState) {
  const { gift_card } = state.gifts
  const giftCard = { ...gift_card } // make sure not to mutate the state

  if (giftCard.resource && giftCard.resource_type) {
    const resourceSchema = getResourceNormalizationSchemaByType(
      giftCard.resource_type,
    )
    giftCard.resource = denormalize(
      giftCard.resource,
      resourceSchema,
      state.entities,
    )
  }

  return giftCard
}

export function setSenderData({
  email,
  emailGiftRecipient,
  deliverAt,
}: {
  email: string
  emailGiftRecipient: string
  deliverAt: string
}): {
  type: string
  data: { senderEmail: string; recipientEmail: string; deliverAt: string }
} {
  return {
    type: GIFT_CARD_SENDER_DATA_SET,
    data: {
      senderEmail: email,
      recipientEmail: emailGiftRecipient,
      deliverAt,
    },
  }
}

export function changePlan(planId: string): GiftsChangePlanAction {
  return {
    type: GIFTS_CHANGE_PLAN,
    data: {
      planId,
    },
  }
}

export function changeKind(kind: string): GiftsChangeKindAction {
  return {
    type: GIFTS_CHANGE_KIND,
    data: {
      kind,
    },
  }
}

export function loadGiftsPlans(): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/plans/gifts`,
      normalize: response => {
        const kinds = uniqBy(response.plans, 'kind').map(plan => plan.kind)

        const data = kinds.reduce((allData, kind) => {
          // to make the shape of GiftPlan more consistent with other Plans:
          // - add the `trial` field and set it to false
          // - add the `system` field and set it to `cards` by default
          const filteredPlans = getFilteredPlans(response.plans, kind).map(
            plan => ({
              ...plan,
              system: plan.system || 'cards',
              trial: false,
            }),
          )
          const currentPlan =
            find(filteredPlans, plan => plan.preselected) || filteredPlans[0]
          return {
            ...allData,
            [kind]: {
              plans: filteredPlans,
              planId: currentPlan.id,
              suggestedItemId: '',
              suggestedItemType: '',
            },
          }
        }, {})

        let preselectedKind

        if (kinds.includes('master')) {
          preselectedKind = 'master'
        } else if (kinds.includes('premium')) {
          preselectedKind = 'premium'
        } else {
          // this is an unchartered territory; we shouldn't be here
          preselectedKind = kinds[0]
        }

        return {
          data: {
            kinds: {
              ...data,
            },
            kind: preselectedKind,
            availableKinds: kinds,
            loading: false,
            error: '',
          },
        }
      },
      types: [
        GIFTS_PLANS_LOAD,
        GIFTS_PLANS_LOAD_SUCCESS,
        GIFTS_PLANS_LOAD_ERROR,
      ],
    },
  }
}

export function clearGiftsPaymentIntent(): PaymentIntentClearAction {
  return { type: GIFTS_PAYMENT_INTENT_CLEAR }
}

export function createGiftsPaymentIntent({
  planId,
  cb,
}: {
  planId: string
  cb: () => Promise<void>
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/payments/stripe/async/gifts',
      options: {
        method: 'post',
        data: { plan: planId },
      },
      onSuccess: () => cb(),
      types: [
        GIFTS_CREATE_PAYMENT_INTENT,
        GIFTS_CREATE_PAYMENT_INTENT_SUCCESS,
        GIFTS_CREATE_PAYMENT_INTENT_ERROR,
      ],
    },
  }
}

export function createGiftCard({
  giftCard,
  clientKey,
}: {
  giftCard: CreateGiftCardPayload
  clientKey: string
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/payments/stripe/async/gifts',
      options: {
        method: 'put' as FetchMethod,
        data: {
          client_secret: clientKey,
          plan: giftCard.plan_uuid,
          gift_card: giftCard.gift_card,
        },
      },
      types: [
        GIFTS_CREATE_CARD,
        GIFTS_CREATE_CARD_SUCCESS,
        GIFTS_CREATE_CARD_ERROR,
      ],
    },
  }
}

export function activateGiftCard(gift_card: GiftCardComplete): ApiAction {
  const { billing_promo } = gift_card

  const analyticsPayload: {
    payment_method: string
    duration: string
    sub_type: string
    is_trial: boolean
    reason?: string
  } = {
    payment_method: 'gift',
    duration: billing_promo.duration,
    sub_type: billing_promo.kind,
    is_trial: false,
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/promocodes`,
      options: {
        method: 'post' as FetchMethod,
        data: {
          code: billing_promo.uuid,
        },
      },
      onSuccess: (dispatch, getState) => {
        const state = getState()
        dispatch(analyticsEvent(SUBSCRIPTION_SUCCESSFUL, analyticsPayload))
        dispatch(loadInfo())
        dispatch(push(urlFor.giftsActivationSuccess(state.app.storedQuery)))
      },
      onError: (dispatch, getState, err) => {
        analyticsPayload.reason =
          typeof err === 'string' ? err : err.error.message
        dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))
      },
      types: [GIFT_CARD_ACTIVATE, GIFT_CARD_ACTIVATE_SUCCESS],
    },
  }
}

export function loadGiftPurchaseStatus(id: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/gift_purchases/${id}`,
      types: [
        GIFT_PURCHASE_STATUS_LOAD,
        GIFT_PURCHASE_STATUS_LOAD_SUCCESS,
        GIFT_PURCHASE_STATUS_LOAD_ERROR,
      ],
      options: {
        ignoreError: true,
      },
    },
  }
}

export function loadGift(giftId: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/gift_cards/${giftId}`,
      normalize: res => {
        const { gift_card } = res
        const additionalData = {}

        if (gift_card && gift_card.resource) {
          const resourceNormalizationSchema = getResourceNormalizationSchemaByType(
            gift_card.resource_type,
          )
          const { result, entities } = normalize(
            gift_card.resource,
            resourceNormalizationSchema as Schema<any>,
          )
          gift_card.resource = result
          additionalData.entities = entities
        }

        return {
          data: {
            gift_card,
            loading: false,
            error: '',
          },
          ...additionalData,
        }
      },
      types: [GIFT_CARD_LOAD, GIFT_CARD_LOAD_SUCCESS],
    },
  }
}

export function updateGiftCardContent(text: string): Action {
  return {
    type: GIFTS_CARD_CONTENT_UPDATED,
    text,
  }
}

export function updateGiftCardTheme(theme: string): Action {
  return {
    type: GIFTS_CARD_THEME_UPDATED,
    theme,
  }
}

export function addBookSuggestion(uuid: string, resourceType: string): Action {
  return {
    type: GIFTS_ADD_BOOK_SUGGESTION,
    uuid,
    resourceType,
  }
}

const initialState = {
  kind: 'premium',
  availableKinds: [],
  kinds: {},
  themes: CARD_THEMES,
  senderEmail: '',
  card: {
    content: '',
    background: CARD_THEMES[0],
  },
  gift_card: {
    uuid: '',
    billing_promo: {
      duration: '',
      uuid: '',
      kind: '',
    },
    resource: '',
    resource_type: '',
    content: '',
    user: null,
  },
  purchaseStatus: null,
  error: '',
  loading: false,
  isComplete: false,
  paymentIntent: { complete: false },
}

export default function gifts(state = initialState, action: Action) {
  switch (action.type) {
    case LOCATION_HREF:
      return action.data.slice(-5) === 'gifts'
        ? {
            ...state,
            card: initialState.card,
            gift_card: initialState.gift_card,
          }
        : state
    case GIFTS_CARD_CONTENT_UPDATED:
      return {
        ...state,
        card: {
          ...state.card,
          content: action.text,
        },
      }

    case GIFTS_CARD_THEME_UPDATED:
      return {
        ...state,
        card: {
          ...state.card,
          background: action.theme,
        },
      }

    case GIFTS_ADD_BOOK_SUGGESTION:
      return {
        ...state,
        kinds: {
          ...state.kinds,
          [state.kind]: {
            ...state.kinds[state.kind],
            suggestedItemId: action.uuid,
            suggestedItemType: action.resourceType,
          },
        },
      }

    case GIFTS_PLANS_LOAD_SUCCESS:
      return {
        ...state,
        kind: action.data.kind,
        kinds: action.data.kinds,
        availableKinds: action.data.availableKinds,
        loading: action.data.loading,
        error: action.data.error,
      }

    case GIFTS_PLANS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      }

    case GIFTS_CHANGE_PLAN:
      return {
        ...state,
        kinds: {
          ...state.kinds,
          [state.kind]: {
            ...state.kinds[state.kind],
            planId: action.data.planId,
          },
        },
      }

    case GIFTS_CHANGE_KIND:
      return {
        ...state,
        kind: action.data.kind,
      }

    case GIFT_CARD_LOAD_SUCCESS:
      return {
        ...state,
        gift_card: action.data.gift_card,
      }

    case GIFT_CARD_ACTIVATE:
      return {
        ...state,
        loading: true,
      }

    case GIFT_CARD_SENDER_DATA_SET:
      // eslint-disable-next-line no-case-declarations
      const { senderEmail, recipientEmail, deliverAt } = action.data

      return {
        ...state,
        senderEmail,
        recipientEmail,
        deliverAt,
      }

    case GIFTS_CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          complete: true,
          returnUrl: action.return_url,
          key: action.client_secret,
          kind: PAYMENT_INTENT,
        },
      }
    case GIFTS_CREATE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        paymentIntent: { complete: false, error: action.error },
      }

    case GIFTS_PAYMENT_INTENT_CLEAR:
      return {
        ...state,
        paymentIntent: { complete: false },
      }

    case GIFT_PURCHASE_STATUS_LOAD_SUCCESS:
      return {
        ...state,
        purchaseStatus: action.status,
        giftUuid: action.gift_card_uuid,
        purchaseError: action.error?.message,
      }

    case GIFT_PURCHASE_STATUS_LOAD_ERROR:
      return {
        ...state,
        purchaseStatus: action.status,
        purchaseError: action.error?.message,
      }

    default:
      return state
  }
}

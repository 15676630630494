import React, { Component } from 'react'
import HeaderBox from 'client/shared/boxes/header-box'
import { Heading } from 'client/shared/blocks/heading/heading'
import Spacer from 'client/shared/blocks/spacer/spacer'
import Button from 'client/shared/blocks/button/button'
import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import Footer from 'client/shared/blocks/footer/footer'
import Meta from 'client/shared/blocks/meta/meta'
import { MetaDescription } from 'client/shared/blocks/meta-description/meta-description'
import MetaTitle from 'client/shared/blocks/meta-title/meta-title'
import urlHelper, { QueryParams } from 'shared/tools/url-helper'
import { Location } from 'client/shared/types/react-router'

import BooksCovers from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/books.png'
import TitleDesktop from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/title-desktop.png'
import TitleMobile from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/title-mobile.png'
import AuthorImage from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/author.png'
import OriginalsLogo from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/logo.png'
import DevicesImage from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/devices.png'
import ShareFBimage from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/share.png'
import ShareTWimage from 'client/bookmate/pages/originals/assets/el-ano-de-mariposa/twitter.png'

import ShareVidadesTw from 'client/bookmate/pages/originals/assets/vidades/twitter.png'
import ShareVidadesFb from 'client/bookmate/pages/originals/assets/vidades/facebook.png'
import AuthorVilar from 'client/bookmate/pages/originals/assets/vidades/author-vilar.png'
import DevicesVilar from 'client/bookmate/pages/originals/assets/vidades/devices.png'
import VidadesTitle from 'client/bookmate/pages/originals/assets/vidades/title.png'
import VidadesBooks from 'client/bookmate/pages/originals/assets/vidades/books.png'
import VidadesLogo from 'client/bookmate/pages/originals/assets/vidades/logo.png'

import BooksElTeatroCovers from 'client/bookmate/pages/originals/assets/el-teatro/books.png'
import TitleElTeatroDesktop from 'client/bookmate/pages/originals/assets/el-teatro/title-desktop.png'
import TitleElTeatroMobile from 'client/bookmate/pages/originals/assets/el-teatro/title-mobile.png'
import AuthorElTeatroImage from 'client/bookmate/pages/originals/assets/el-teatro/author.png'
import AuthorElTeatroName from 'client/bookmate/pages/originals/assets/el-teatro/author-name.png'
import DevicesElTeatroImage from 'client/bookmate/pages/originals/assets/el-teatro/devices.png'
import ShareElTeatroFBimage from 'client/bookmate/pages/originals/assets/el-teatro/share.png'
import ShareElTeatroTWimage from 'client/bookmate/pages/originals/assets/el-teatro/twitter.png'

import './common.styl'
import './el-ano-de-mariposa.styl'

type Props = { location: Location; campaign: string } & DecoratorProps

export const EL_TEATRO = 'el-teatro'
export const MARIPOSA = 'mariposa'
export const VIDADES = 'lascavidades'

const LandingDescription = ({
  isMobile,
  campaign,
  query,
  isTablet,
}: {
  isMobile: boolean
  campaign: string
  query?: QueryParams
  isTablet?: boolean
}): JSX.Element => (
  <>
    {TEXTS[campaign].withExtraButtons ? (
      <div className="buttons-group top one-button">
        <Button
          aria-label={TEXTS[campaign].buttons.long}
          className="landing-button_book"
          path={urlHelper.book(TEXTS[campaign].bookUuid, query)}
        >
          <img
            src="https://a.bmstatic.com/iu/44/170/Read-381d30f9cbf469e47c618ce164a31f94.svg"
            alt={TEXTS[campaign].buttons.short[0]}
          />
          {TEXTS[campaign].buttons.short[0]}{' '}
          {!isMobile && !isTablet && TEXTS[campaign].buttons.short[1]}
        </Button>
        <Button
          kind="transparent"
          aria-label="Activa el código promocional aquí"
          className="landing-button"
          path={TEXTS[campaign].promo}
        >
          Activa el código promocional aquí
        </Button>
      </div>
    ) : null}
    {isMobile && campaign === EL_TEATRO && (
      <>
        <Spacer size={32} />
        <img
          src={TEXTS[campaign].devices}
          alt={TEXTS[campaign].alt}
          className="book_lead-in"
        />
        <Spacer size={140} />
      </>
    )}
    <p className="landing-description">{TEXTS[campaign].description}</p>
    <Spacer size={24} />
    {TEXTS[campaign].logo ? (
      <img
        className="landing-logo_originals"
        src={TEXTS[campaign].logo}
        alt="originals logo"
      />
    ) : null}
    {isMobile && [VIDADES, MARIPOSA].includes(campaign) && (
      <>
        <Spacer size={32} />
        <img
          src={TEXTS[campaign].devices}
          alt={TEXTS[campaign].alt}
          className="book_lead-in"
        />
      </>
    )}
    <Spacer size={isMobile || campaign === VIDADES ? 32 : 80} />
  </>
)

const META = {
  [MARIPOSA]: {
    title:
      'Lee y escucha “El año de la mariposa” de Camila Valenzuela León en Bookmate',
    description:
      'Lee o escucha antes que todos “El año de la mariposa”, la nueva historia de Camila Valenzuela León. El libro y audiolibro ya están disponibles en Bookmate. ¡Suscríbete ahora!',
    share: {
      tw: ShareTWimage,
      fb: ShareFBimage,
    },
  },
  [EL_TEATRO]: {
    title:
      'Exclusivas de Bookmate presenta la nueva edición de El teatro del engaño',
    description: `La investigación de Emmanuelle Steels revela las intrigas y manipulaciones que se desarrollaron en la sombra. En México, el caso Cassez-Vallarta es el emblema de una parodia de justicia que usa seres humanos y tritura vidas para dar al público un buen espectáculo.`,
    share: {
      tw: ShareElTeatroTWimage,
      fb: ShareElTeatroFBimage,
    },
  },
  [VIDADES]: {
    title: 'Lee y escucha “Las cavidades” de Elaine Vilar Madruga en Bookmate',
    description:
      'Lee o escucha antes que todos “Las cavidades”, la nueva historia de Elaine Vilar Madruga. El libro y audiolibro ya están disponibles en Bookmate. ¡Suscríbete ahora!',
    share: {
      tw: ShareVidadesTw,
      fb: ShareVidadesFb,
    },
  },
}

const TEXTS = {
  [MARIPOSA]: {
    title: 'Bookmate Originals presenta una novela de Camila Valenzuela',
    description: `La enfermedad es un lobo que acecha en un bosque muerto, una mariposa que se posa en el rostro cansado de Nina. Serán tiempos de revelaciones y de ausencias: un padre que fallece, un hermano que vuelve, los secretos familiares que salen a la luz durante el año de la mariposa.`,
    leadIn: {
      desktop: TitleDesktop,
      mobile: TitleMobile,
    },
    buttons: {
      long: 'Lee aquí',
      short: ['Lee ', 'aquí'],
    },
    logo: OriginalsLogo,
    alt: 'el ano de mariposa',
    books: BooksCovers,
    bookUuid: 'Tmd677mf',
    audiobookUuid: 'SHdMNceZ',
    promo: urlHelper.codePage('', {
      promo: 'mariposa',
    }),
    devices: DevicesImage,
    author: {
      name: 'Camila Valenzuela León',
      image: AuthorImage,
      description: `(Santiago, 1985) es una escritora y editora chilena. Gan&oacute;
      el Premio Barco de Vapor en el 2014 con su novela Nieve negra y su
      libro De bosque y cenizas obtuvo el Premio Municipal de Literatura
      de Santiago 2022 en la categor&iacute;a de literatura juvenil.`,
    },
  },
  [VIDADES]: {
    title: 'Bookmate Originals presenta una novela de Elaine Vilar Madruga',
    description: `Una novela sobre el cuerpo y sobre cabezas errantes que lo abandonan, sobre el deseo que habita en la cavidad donde cuerpo y cabeza se desconectan. Una historia de mujeres y su linaje.`,
    leadIn: {
      desktop: VidadesTitle,
      mobile: VidadesTitle,
    },
    buttons: {
      long: 'Lee aquí',
      short: ['Lee ', 'aquí'],
    },
    logo: VidadesLogo,
    alt: 'el ano de mariposa',
    books: VidadesBooks,
    aboutBooks: `Aricia está recuperándose de una cesárea. Tiene la sensación de que su cuerpo ha cambiado, pero también su manera de percibir el mundo. Al volverse madre, siente que su cabeza se aleja cada vez más de su cuerpo y se da cuenta de que es parte de un linaje de mujeres de cabezas flotantes y, a través de una serie de sensaciones nuevas, se adentra en experiencias eróticas que oscilan entre el sueño y la realidad, el pasado y el presente, mientras intenta entender su herencia y la historia de las mujeres de su familia.`,
    bookUuid: 'YHj1rZG9',
    audiobookUuid: 'MdkwiCuS',
    promo: urlHelper.codigoPage({
      code: 'lascavidades',
    }),
    devices: DevicesVilar,
    author: {
      name: 'Elaine Vilar Madruga',
      image: AuthorVilar,
      description: `(1989, La Habana, Cuba) es dramaturga, narradora y poeta. Es considerada una de las voces literarias actuales más importantes de Cuba y del Caribe. Sus textos han sido publicados en numerosas antologías en todo el mundo y ha obtenido multitud de premios a nivel nacional e internacional. Es autora de Salomé (La Pollera, 2018), 
      La tiranía de las moscas (Barrett, 2021), El cielo de la selva (Lava, 2023).`,
    },
  },
  [EL_TEATRO]: {
    title: 'Exclusivas de Bookmate presenta la nueva edición',
    leadIn: {
      desktop: TitleElTeatroDesktop,
      mobile: TitleElTeatroMobile,
    },
    buttons: {
      long: 'Leer',
      short: ['Leer', ''],
    },
    withExtraButtons: true,
    description: `Una investigación que revela los entresijos del caso Cassez-Vallarta. En México, este escándalo se convirtió en el emblema de una parodia de justicia que fabrica culpables para dar al público un buen espectáculo.`,
    alt: 'El teatro del engaño',
    books: BooksElTeatroCovers,
    bookUuid: 'TvwcIIt2',
    devices: DevicesElTeatroImage,
    promo: urlHelper.codigoPage({ code: 'elteatro' }),
    author: {
      name: 'Emmanuelle Steels',
      image: AuthorElTeatroImage,
      description: `Emmanuelle Steels es una periodista originaria de Bélgica. Trabaja como corresponsal para varios medios del ámbito francófono: el periódico francés Libération, la radio pública francesa Radio France, 
      la radio televisión de Bélgica RTBF, la radio televisión suiza RTS y la revista de viaje y naturaleza GEO. En 2015, sale publicada la primera edición de su libro El teatro del engaño: buscando a los Zodiaco, la banda de secuestradores que nunca existió (Grijalbo), fruto de una investigación de seis años sobre el caso de Florence Cassez e Israel Vallarta.`,
    },
  },
}

class LatamOriginal extends Component<Props> {
  getAuthor(): JSX.Element | null {
    const { campaign } = this.props
    switch (campaign) {
      case MARIPOSA:
        return (
          <>
            <div className="about-author">
              <img
                src={TEXTS[campaign].author.image}
                alt={TEXTS[campaign].author.name}
                className="author-name"
              />
              <p>
                <b>{TEXTS[campaign].author.name}</b>
                <br />
                {TEXTS[campaign].author.description}
              </p>
            </div>
            <img
              src={TEXTS[campaign].books}
              alt={TEXTS[campaign].alt}
              className="book-covers"
            />
          </>
        )
      case EL_TEATRO:
        return (
          <>
            <div className="landing-author">
              <img
                src={TEXTS[campaign].author.image}
                alt={TEXTS[campaign].author.name}
                className="author-name"
              />
              <img
                src={AuthorElTeatroName}
                alt={TEXTS[campaign].author.name}
                className="author-name-string"
              />
            </div>
            <Spacer size={48} />
            <p className="landing-author_desc">
              <img
                src={TEXTS[campaign].books}
                alt={TEXTS[campaign].alt}
                className="book-covers"
              />
              {TEXTS[campaign].author.description}
            </p>
            <Spacer size={48} />
          </>
        )
      case VIDADES:
        return (
          <div className="author-block">
            <div className="books-col">
              <div>{TEXTS[VIDADES].aboutBooks}</div>
              <img src={TEXTS[VIDADES].books} />
            </div>
            <Spacer size={64} />
            <img
              className="author-image"
              src={TEXTS[VIDADES].author.image}
              alt={TEXTS[VIDADES].author.name}
            />
            <Spacer size={20} />
            <span className="author-about">
              <b>{TEXTS[VIDADES].author.name}</b>
            </span>
            <p className="author-about">{TEXTS[VIDADES].author.description}</p>
          </div>
        )
      default:
        return null
    }
  }

  getDescription(isMobile: boolean, isTablet: boolean): JSX.Element | null {
    return isMobile ? (
      <>
        <LandingDescription
          campaign={this.props.campaign}
          isMobile={isMobile}
          query={this.props.location.query}
          isTablet={isTablet}
        />
      </>
    ) : null
  }

  render(): JSX.Element {
    const { isMobileSize, isTabletSize, location, campaign } = this.props
    const isMobile = isMobileSize()
    const isTablet = isTabletSize()

    return (
      <div className={`original__wrapper ${campaign}`}>
        <MetaTitle disableSuffix title={META[campaign].title} />
        <MetaDescription description={META[campaign].description} />
        <Meta content={META[campaign].share.fb} property="og:image" />
        <Meta content={META[campaign].share.tw} property="twitter:image" />
        <Meta content="summary_large_image" property="twitter:card" />
        <HeaderBox />
        <div className="landing-body">
          <Heading kind="graphik">{TEXTS[campaign].title}</Heading>
          <Spacer />
          <img
            className="landing-title_icon"
            src={TEXTS[campaign].leadIn[isMobile ? 'mobile' : 'desktop']}
            alt={TEXTS[campaign].alt}
          />
          <Spacer size={32} />
          {!isMobile && (
            <LandingDescription
              campaign={campaign}
              isMobile={isMobile}
              query={location.query}
              isTablet={isTablet}
            />
          )}
          {[EL_TEATRO, VIDADES] && this.getDescription(isMobile, isTablet)}
        </div>
        {[MARIPOSA].includes(campaign) &&
          this.getDescription(isMobile, isTablet)}
        <div className="landing-card">
          {this.getAuthor()}
          <div className="landing_action-buttons">
            <div
              className={`buttons-group${
                !TEXTS[campaign].audiobookUuid ? ' one-button' : ''
              }`}
            >
              <Button
                aria-label={TEXTS[campaign].buttons.long}
                className="landing-button_book"
                path={urlHelper.book(TEXTS[campaign].bookUuid, location.query)}
              >
                <img
                  src="https://a.bmstatic.com/iu/44/170/Read-381d30f9cbf469e47c618ce164a31f94.svg"
                  alt={TEXTS[campaign].buttons.short}
                />
                {TEXTS[campaign].buttons.short[0]}
                {!isMobile && !isTablet && TEXTS[campaign].buttons.short[1]}
              </Button>
              {TEXTS[campaign].audiobookUuid && (
                <Button
                  aria-label="Escucha aquí"
                  className="landing-button_audiobook"
                  path={urlHelper.audioBook(
                    TEXTS[campaign].audiobookUuid,
                    location.query,
                  )}
                >
                  <img
                    src="https://a.bmstatic.com/iu/221/85/Listen-37e862dc0c50eac165df11dc73934624.svg"
                    alt="Escucha"
                  />
                  Escucha {!isMobile && !isTablet && 'aquí'}
                </Button>
              )}
            </div>
            <Spacer size={32} />
            <div className="buttons-group">
              <Button
                kind="transparent"
                className="landing-button"
                path={urlHelper.library(location.query)}
                aria-label="Lee este y miles de libros más aquí"
              >
                Lee este y miles de libros más aquí
              </Button>
              <Button
                kind="transparent"
                aria-label="Activa el código promocional aquí"
                className="landing-button"
                path={TEXTS[campaign].promo}
              >
                Activa el código promocional aquí
              </Button>
            </div>
          </div>
          {campaign !== VIDADES && <Spacer size={64} />}
        </div>
        <Footer />
      </div>
    )
  }
}

export default uiBox(LatamOriginal)

import React, { Component } from 'react'
import cn from 'classnames'

import './layout-column.styl'

type Props = {
  children?: React.ReactNode
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12 | 13 | 14 // Number of columns (column is 64 px wide with 8px gutter)
  autoWidth: boolean // 1) Can contain elements that are wider than the width set by the `size` prop; 2) Centers its own contents
  centered: boolean // Gets centered with auto margins
  className: string
  style?: Record<string, unknown>
}

export class LayoutColumn extends Component<Props> {
  static defaultProps = {
    autoWidth: false,
    centered: false,
    className: '',
  }

  render(): JSX.Element {
    const { size, className, centered, autoWidth, style } = this.props

    const classNames = cn('layout-column', size && `layout-column_${size}`, {
      'layout-column_centered': centered,
      'layout-column_single': !size,
      'layout-column_auto-width': autoWidth,
      [className]: className,
    })

    return size ? (
      <div className={classNames}>{this.props.children}</div>
    ) : (
      <div style={style} className={classNames}>
        <div className="layout-column__inner">{this.props.children}</div>
      </div>
    )
  }
}

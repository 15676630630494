import { schema } from 'normalizr'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import { createSelector } from '@reduxjs/toolkit'

import { cacheAction } from 'shared/middlewares/cache-middleware'
import { CALL_API } from 'shared/middlewares/api-middleware'

import { checkEntities } from 'client/bookmate/helpers/entities-helper'

const emotionSchema = new schema.Entity('emotions', {}, { idAttribute: 'name' })
export const emotionsSchema = new schema.Array(emotionSchema)

const EMOTIONS_LOAD = 'EMOTIONS_LOAD'
export const EMOTIONS_LOADED = 'EMOTIONS_LOADED'

const entitiesSelector = state => state.entities
const idSelector = (_state, id: number | string) => id

const getEmotionById = createSelector(
  entitiesSelector,
  idSelector,
  (entities, id) => entities.emotions[id] || {},
)

export function getEmotionsByIds(state, ids) {
  return ids.map(id => getEmotionById(state, id))
}

export const getAllEmotions = createSelector(entitiesSelector, entities =>
  values(entities.emotions),
)

export const loadEmotions = cacheAction(
  () => {
    return {
      [CALL_API]: {
        endpoint: '/p/a/4/d/impressions/emotions',
        schema: new schema.Array(emotionSchema),
        responseKey: 'objects',
        types: [EMOTIONS_LOAD, EMOTIONS_LOADED],
      },
    }
  },
  EMOTIONS_LOADED,
  null,
)

const initialState = {}

export default function emotions(state = initialState, action) {
  const mergedEntities = checkEntities(state, action, 'emotions')

  if (!isEmpty(mergedEntities)) return mergedEntities
  return state
}

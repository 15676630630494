import { CALL_API } from 'shared/middlewares/api-middleware'

import { libraryCardsSchema } from 'client/bookmate/reducers/schemas/schemas'

import {
  LIBRARY_CARD_LOAD,
  LIBRARY_CARD_LOAD_SUCCESS,
} from '../../shared/constants/library-cards-constants'

export function loadLibraryCardAction(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/library_cards/${uuid}`,
      schema: libraryCardsSchema,
      responseKey: 'library_card',
      types: [LIBRARY_CARD_LOAD, LIBRARY_CARD_LOAD_SUCCESS],
    },
  }
}

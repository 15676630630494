import React from 'react'
import { connect } from 'react-redux'

import { prepareSubscriptionAnalyticsPayload } from 'client/shared/helpers/analytics-helpers'

import {
  analyticsEvent,
  SUBSCRIPTION_FAILED,
} from 'client/shared/reducers/analytics-reducer'

import StripeCreditCard from 'client/shared/blocks/stripe-credit-card'

import { State as StripeState } from 'client/shared/reducers/stripe-reducer'
import { StripeError } from 'client/shared/types/stripe'
import {
  WebPlan,
  ProductPlan,
  SpecialOfferPlan,
} from 'client/shared/types/subscription'
import { GiftPlan } from 'client/bookmate/reducers/gifts-reducer'
import { Dispatch, State } from 'shared/types/redux'
import { Recaptcha } from 'client/shared/blocks/recaptcha'

type Props = {
  dispatch: Dispatch
  callbackError?:
    | (string | null | undefined)
    | ({ message: string } | null | undefined) // error from our backend,
  awaitingApiResponse?: boolean | null | undefined // true when our api is trying to charge user's card using stripe token
  stripe: StripeState
  buttonChild?: React.ReactNode
  disabled: boolean
  currentPlan?: ProductPlan | WebPlan | SpecialOfferPlan | GiftPlan
  noticeAddition?: React.ReactNode
  showRecurrentNotice?: boolean
  isDarkTheme?: boolean
  recaptchaAction?: string
  buttonTextId?: string
  isGiftsPage?: boolean
}

class StripeCreditCardBox extends React.Component<Props> {
  handleError = (stripeError: StripeError) => {
    const { currentPlan } = this.props

    const analyticsPayload = prepareSubscriptionAnalyticsPayload(
      currentPlan,
      stripeError?.message,
    )
    this.props.dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))
  }

  render() {
    const {
      stripe: {
        loading,
        error: stripeError,
        scriptLoaded,
        scriptLoadingFailed,
        offerRegularPlan,
      },
      awaitingApiResponse,
      callbackError,
      buttonChild,
      disabled,
      noticeAddition,
      showRecurrentNotice,
      isDarkTheme,
      buttonTextId,
      isGiftsPage,
    } = this.props

    return (
      <>
        <Recaptcha />
        <StripeCreditCard
          onError={this.handleError}
          disabled={disabled}
          ready={scriptLoaded}
          scriptLoadingFailed={scriptLoadingFailed}
          buttonChild={buttonChild}
          buttonTextId={buttonTextId}
          apiError={callbackError || stripeError}
          awaitingApiResponse={awaitingApiResponse || loading}
          showRecurrentNotice={showRecurrentNotice}
          noticeAddition={noticeAddition}
          isDarkTheme={isDarkTheme}
          offerRegularPlan={offerRegularPlan}
          isGiftsPage={isGiftsPage}
        />
      </>
    )
  }
}

export default connect((state: State) => ({
  stripe: state.stripe,
}))(StripeCreditCardBox)

// @flow

import { normalize, denormalize } from 'normalizr'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

import {
  shelvesSchema,
  usersSchema,
  bookSchema,
  booksSchema,
  audioBookSchema,
  audioBooksSchema,
  comicbookSchema,
  comicbooksSchema,
  authorSchema,
  authorsSchema,
  seriesSchema,
  seriesListSchema,
} from 'client/bookmate/reducers/schemas/schemas'
import { getShelvesByIds } from 'client/bookmate/reducers/shelves-reducer'
import {
  getBookById,
  getBooksByIds,
} from 'client/bookmate/selectors/book-selector'
import {
  getAudioBookById,
  getAudioBooksByIds,
} from 'client/bookmate/reducers/audiobooks-reducer'
import {
  getComicbookById,
  getComicbooksByIds,
} from 'client/bookmate/reducers/comicbooks-reducer'
import {
  getAuthorById,
  getAuthorsByIds,
} from 'client/bookmate/reducers/authors-reducer'
import { getUsersByIds } from 'client/bookmate/reducers/users-reducer'
import {
  getSeriesByIds,
  getSeriesById,
} from 'client/bookmate/selectors/series-selectors'

import type { State as AppState } from 'shared/types/redux'
import type { BestMatchItemProps } from 'client/shared/types/search'

type RelatedProps = {
  type: string
  objects: BestMatchItemProps[]
  meta: {
    matches_user_lang: boolean
    page: number
    per_page: number
    total: number
    url: string
  }
}

type BestMatchProps = {
  item: BestMatchItemProps
  state: string
  type: string
  related: RelatedProps
}

const BEST_MATCH = 'bestMatch'

const SCHEMA = {
  book: bookSchema,
  audiobook: audioBookSchema,
  comicbook: comicbookSchema,
  author: authorSchema,
  series: seriesSchema,
}

const SCHEMAS = {
  books: booksSchema,
  audiobooks: audioBooksSchema,
  comicbooks: comicbooksSchema,
  series: seriesListSchema,
  users: usersSchema,
  bookshelves: shelvesSchema,
  authors: authorsSchema,
}

const GET_BY_IDS = {
  books: getBooksByIds,
  audiobooks: getAudioBooksByIds,
  comicbooks: getComicbooksByIds,
  series: getSeriesByIds,
  bookshelves: getShelvesByIds,
  authors: getAuthorsByIds,
  users: getUsersByIds,
}

const GET_BY_ID = {
  book: getBookById,
  audiobook: getAudioBookById,
  comicbook: getComicbookById,
  author: getAuthorById,
  series: getSeriesById,
}

// map from our names of search sections to terms used by analytics
export const SECTION_TO_CONTEXT = {
  books: 'books',
  audiobooks: 'audiobooks',
  comicbooks: 'comics',
  series: 'series',
  bookshelves: 'shelves',
  users: 'users',
  all: 'all',
  authors: 'authors',
}

function normalizeType(type) {
  const matchingTypes = {
    book: 'books',
    comicbook: 'comicbooks',
    audiobook: 'audiobooks',
  }

  return matchingTypes[type] || type
}

function normalizeResource(resource: any, key: string) {
  return normalize(resource, SCHEMA[key])
}

function normalizeResources(resources: any[], key: string) {
  return normalize(resources, SCHEMAS[key])
}

function normalizeBestMatchRelated(related: RelatedProps) {
  if (!related) return { result: null, entities: {} }

  const type = normalizeType(related.type)
  const { result, entities } = normalizeResources(related.objects, type)

  return {
    result: {
      meta: related.meta,
      items: result,
      type: related.type,
    },
    entities,
  }
}

function normalizeBestMatch(bestMatchData: BestMatchProps) {
  let entities = {}

  const {
    result: bestMatchResult,
    entities: bestMatchEntities,
  } = normalizeResource(bestMatchData.item, bestMatchData.type)
  entities = merge(entities, bestMatchEntities)

  const {
    result: related,
    entities: relatedEntities,
  } = normalizeBestMatchRelated(bestMatchData.related)
  entities = merge(entities, relatedEntities)

  return {
    result: {
      ...bestMatchData,
      item: bestMatchResult,
      related,
    },
    entities,
  }
}

export function denormalizeBestMatch(
  bestMatchData: BestMatchProps,
  state: AppState,
): Record<string, any> {
  const { entities } = state
  let related: Record<string, any> | null = null

  if (bestMatchData.related) {
    const type = bestMatchData.related.type
    const meta = bestMatchData.related.meta
    const { items } = GET_BY_ID[type](state, bestMatchData.related)

    related = {
      items: denormalize(items, SCHEMAS[normalizeType(type)], entities),
      meta,
      type,
    }
  }

  return {
    ...bestMatchData,
    item: GET_BY_ID[bestMatchData.type](state, bestMatchData.item),
    related,
  }
}

export function normalizeData(results: any) {
  const normalizeResults = {}
  let allEntities = {}

  for (const key in results) {
    if (key === BEST_MATCH) {
      const { result, entities } = normalizeBestMatch(results[key])
      allEntities = merge(allEntities, entities)

      normalizeResults[key] = result
    } else {
      const { result, entities } = normalizeResources(results[key].items, key)
      normalizeResults[key] = {
        items: result,
        meta: results[key].meta,
      }
      allEntities = merge(allEntities, entities)
    }
  }

  return {
    normalizeResults,
    entities: allEntities,
  }
}

export function getSearch(state: AppState) {
  const {
    search: searchFromState,
    search: { results },
  } = state
  const _search = cloneDeep(searchFromState)

  for (const key in results) {
    if (key === BEST_MATCH) {
      _search.results[key] = denormalizeBestMatch(results[key], state)
    } else {
      _search.results[key] = {
        meta: results[key].meta,
        items: GET_BY_IDS[key](state, results[key].items),
      }
    }
  }

  return _search
}

export function apiForSection(section: string) {
  switch (section) {
    case 'authors':
      return '/api/v5/authors/search'
    case 'books':
      return '/api/v5/books/search'
    case 'audiobooks':
      return '/api/v5/audiobooks/search'
    case 'comicbooks':
      return '/api/v5/comicbooks/search'
    case 'series':
      return '/api/v5/series/search'
    case 'bookshelves':
      return '/api/v5/bookshelves/search'
    case 'users':
      return '/api/v5/users/search'
    case 'all':
    default:
      return '/api/v5/search'
  }
}

function getBestMatchData(bestMatch) {
  if (!bestMatch) return {}

  return {
    bestMatch: {
      item: bestMatch.main_object,
      state: bestMatch.main_object_state,
      type: bestMatch.main_object_type,
      related: bestMatch.spare,
    },
  }
}

function getKeyData(data, key) {
  return {
    [key]: {
      meta: data[key].meta,
      items: data[key].objects.slice(0, 3),
    },
  }
}

function getAllResult(data) {
  let result = {}

  for (const key in data) {
    if (key === 'best_match') {
      result = getBestMatchData(data.best_match)
    } else {
      result = {
        ...result,
        ...getKeyData(data, key),
      }
    }
  }

  return result
}

export function extractResultsForSection(data: any, section: string) {
  switch (section) {
    case 'all': {
      return getAllResult(data)
    }

    case 'books':
    case 'audiobooks':
    case 'comicbooks':
    case 'series':
    case 'bookshelves':
    case 'users':
    case 'authors':
      return {
        [section]: {
          meta: data.meta,
          items: data.objects,
        },
      }
  }
}

export function extractNextPage(data: any, section: string, query: string) {
  if (!data.meta) return null

  const {
    meta: { total, page, per_page },
  } = data

  if (page * per_page <= total) {
    // we have not yet reached the last page
    return `/p/api/v5/${section}/search?query=${query}&page=${
      page + 1
    }&per_page=20`
  } else {
    return null
  }
}

export function extractNotFound(data: any) {
  const {
    objects,
    audiobooks,
    comicbooks,
    series,
    books,
    bookshelves,
    users,
    authors,
    best_match,
  } = data

  if (!objects) {
    return (
      ![
        audiobooks,
        comicbooks,
        books,
        series,
        bookshelves,
        users,
        authors,
      ].some(section => section && section.objects.length) && !best_match
    )
  }

  return !objects.length
}

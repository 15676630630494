import React from 'react'
import { Trans } from '@lingui/react'
import cn from 'classnames'

import './header-dropdown.styl'

import urlFor, { QueryParams } from 'shared/tools/url-helper'

import Linka from 'client/shared/blocks/linka'
import FooterLinks from 'client/shared/blocks/footer-links'
import { CurrentUserState } from 'client/shared/types/current-user'

export default function HeaderDropdown(props: {
  user: CurrentUserState['data']
  hasSubscription: boolean
  handleLogout: (e: Event) => void
  domain: string
  query?: QueryParams
}): JSX.Element {
  const { user, hasSubscription, handleLogout, domain, query } = props
  const subscriptionPath = hasSubscription
    ? urlFor.manageSubscriptions(query)
    : urlFor.subscription('', query)

  return (
    <div className="header-dropdown">
      <div className="header-dropdown__item">
        <Linka path={subscriptionPath} className="header-dropdown__item-link">
          <Trans
            id={
              hasSubscription
                ? 'header.manage_subscription_link'
                : 'header.buy_subscription_link'
            }
          />
        </Linka>
      </div>
      <div className="header-dropdown__item">
        <Linka
          path={urlFor.userAchievements(
            user.login,
            new Date().getFullYear(),
            query,
          )}
          className="header-dropdown__item-link"
          id="header-achievements-link"
        >
          <Trans id="miscellaneous.my_achievements" /> ✨
        </Linka>
      </div>
      <div className="header-dropdown__item">
        <Linka
          path={urlFor.settings(user.login, '', query)}
          className="header-dropdown__item-link"
        >
          <Trans id="header.settings_link" />
        </Linka>
      </div>
      <div className="header-dropdown__item">
        <Linka
          path={urlFor.notifications(query)}
          className={cn('header-dropdown__item-link', {
            'unread-alert': user.has_unread_notifications,
          })}
        >
          <Trans id="notifications.header" />
        </Linka>
      </div>
      <div className="header-dropdown__item">
        <Linka
          path={urlFor.upload(undefined, query)}
          className="header-dropdown__item-link"
        >
          <Trans id="header.upload_title" />
        </Linka>
      </div>
      <div className="header-dropdown__item">
        <span className="header-dropdown__item-link" onClick={handleLogout}>
          <Trans id="header.logout_link" />
        </span>
      </div>
      <hr className="header-dropdown__separator" />
      <FooterLinks kind="header-dropdown" domain={domain} query={query} />
    </div>
  )
}

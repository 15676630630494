import pick from 'lodash/pick'
import noop from 'lodash/noop'
import env from 'env'
import { normalize, Schema, NormalizedSchema } from 'normalizr'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import { logout } from 'client/shared/reducers/current-user-reducer'

export const UNKNOWN_ERROR_ID = 'errors.unknown'
const NETWORK_ERROR =
  'TypeError: NetworkError when attempting to fetch resource.'

import { Action, Dispatch, GetState, Store, State } from 'shared/types/redux'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { isPromise } from 'shared/tools/api-helpers'

export const CALL_API = 'CALL_API'
export const CALL_API_ERROR = 'CALL_API_ERROR'

export type ApiAction = {
  CALL_API: {
    endpoint: string | (() => string)
    options?: {
      method?: string
      data?: {
        [key: string]: string | boolean | number | Record<string, any>
      }
      headers: Headers | string[][] | Record<string, string> | undefined
      state?: State
      dontParse?: boolean
      isMultipart?: boolean
      body?: any
      ignoreError?: boolean
    }
    schema?: Schema
    responseKey?: string
    rawResponseKeys?: string[]
    normalize?: (
      response: any,
      arg1: Schema & { dispatch: Dispatch },
    ) => NormalizedSchema<string, Schema>
    modifyResponse?: (response: { [key: string]: any }) => void
    onSuccess?: (dispatch: Dispatch, getState: GetState, response: any) => void
    onError?: (dispatch: Dispatch, getState: GetState, error: any) => void
    preserveError?: boolean
    types: string[] | ActionCreatorWithoutPayload<any>[]
    ignoreError?: boolean
    name?: string
  }
}

export type ApiErrorAction = {
  type: 'CALL_API_ERROR'
  error: {
    message: string
  }
}

type FetchWrapper = {
  fetch: (
    arg0: string,
    arg1: {
      method?: string
      data?: { [key: string]: string }
      state?: State
    },
  ) => Promise<any>
}

const normalizeResponse = (
  response,
  { schema, responseKey, rawResponseKeys, modifyResponse },
) => {
  let normalizedResponse = response

  if (typeof responseKey === 'string') {
    normalizedResponse = normalizedResponse[responseKey]
  }

  if (schema) {
    if (typeof schema !== 'object') {
      throw new Error('Specify a valid schema.')
    }
    normalizedResponse = normalize(normalizedResponse, schema)

    if (rawResponseKeys) {
      if (
        !Array.isArray(rawResponseKeys) ||
        !rawResponseKeys.every(key => typeof key === 'string')
      ) {
        throw new Error('responseKeys should be a string array.')
      }

      normalizedResponse = {
        ...normalizedResponse,
        ...pick(response[responseKey], rawResponseKeys),
      }
    }
  }

  if (modifyResponse) {
    if (typeof modifyResponse !== 'function') {
      throw new Error('modifyResponse should be a function')
    }

    normalizedResponse = {
      ...normalizedResponse,
      ...modifyResponse(normalizedResponse),
    }
  }

  return normalizedResponse
}

const callApi = fetchWrapper => (
  endpoint,
  {
    options,
    schema,
    normalize: actionNormalize,
    responseKey,
    rawResponseKeys,
    modifyResponse,
    dispatch,
    getState,
  },
) => {
  return fetchWrapper.fetch(endpoint, options).then(response => {
    return (actionNormalize || normalizeResponse)(response, {
      schema,
      responseKey,
      rawResponseKeys,
      dispatch,
      getState,
      modifyResponse,
    })
  })
}

const apiMiddleware = (fetchWrapper: FetchWrapper) => (store: Store) => (
  next: Dispatch,
) => (action: ApiAction): unknown => {
  if (isPromise(action)) return
  const callAPI = action[CALL_API]

  if (typeof callAPI === 'undefined') return next(action)

  const {
    endpoint: _endpoint,
    options: _options = {},
    schema,
    responseKey,
    rawResponseKeys,
    preserveError = false,
    onSuccess = noop,
    onError = noop,
    normalize: actionNormalize,
    modifyResponse,
    types,
    ignoreError = false,
  } = callAPI
  let endpoint = _endpoint
  let options = _options

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState())
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.')
  }

  if (!Array.isArray(types) || types.length < 1 || types.length > 3) {
    throw new Error('Expected an array of 1-3 action types.')
  }

  if (typeof actionNormalize === 'function' && typeof schema === 'object') {
    throw new Error('Specify either normalize or schema but not both.')
  }

  const { dispatch, getState } = store
  const { app, recaptcha } = getState()

  options = {
    state: {
      app,
      recaptcha: recaptcha?.userToken,
    },
    ...options,
  }

  const actionWith: (arg0: Action) => Action = data => {
    const finalAction = { ...action, ...data }
    delete finalAction[CALL_API]

    if (typeof data.type !== 'string') {
      // for new redux-toolkit actions which are not strings
      delete finalAction.type
      return data.type(finalAction)
    }
    return finalAction
  }

  const [requestType, successType, _failureType = CALL_API_ERROR] = types
  let failureType = _failureType

  const responseAction = response => {
    return actionWith({
      ...response,
      type: successType,
    })
  }

  const errorAction = error => {
    if (preserveError) {
      return next(
        actionWith({
          type: failureType,
          error,
        }),
      )
    }

    const errorStatus = error?.response?.status

    if (errorStatus === 401 || error?.action === 'logout') return next(logout())

    if (env.isServer()) {
      throw error
    }

    console.error(error)

    let message
    // API 4
    if (typeof error.error === 'string') {
      message = error.error
      // API 5
    } else if (error.error && typeof error.error === 'object') {
      message = error.error.message
      // unknown error
    } else {
      failureType = CALL_API_ERROR
      message = UNKNOWN_ERROR_ID
    }

    if (failureType === CALL_API_ERROR && error.toString() !== NETWORK_ERROR) {
      next(showAlert('error', { message }))
      error = { error: { message } }
    }

    return next(
      actionWith({
        type: failureType,
        ...error,
      }),
    )
  }

  next(actionWith({ type: requestType }))

  return callApi(fetchWrapper)(endpoint, {
    options,
    schema,
    responseKey,
    rawResponseKeys,
    modifyResponse,
    normalize: actionNormalize,
    dispatch,
    getState,
    ignoreError,
  }).then(
    response => {
      if (successType) {
        next(responseAction(response))
      }
      onSuccess(dispatch, getState, response)
    },
    error => {
      if (ignoreError) return
      errorAction(error)
      onError(dispatch, getState, error)
    },
  )
}

export default apiMiddleware

import { normalize } from 'normalizr'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import find from 'lodash/find'
import first from 'lodash/first'

import { resourceSchemaByType } from 'client/bookmate/reducers/schemas/schemas'

import {
  SeriesFullPartProps,
  ShortSeriesProps,
  SeriesPartCommon,
} from 'client/shared/types/series'
import { ResourceName } from 'client/shared/types/resource'

type NormalizedParts = {
  result: (SeriesPartCommon & {
    resource: {
      uuid: string
    }
    resource_type: ResourceName
  })[]
  entities: any
}

export function getCurrentSeries(
  seriesList: ShortSeriesProps[],
  fromSeries?: string,
): ShortSeriesProps | undefined | null {
  if (seriesList && seriesList.length === 0) return null

  return find(seriesList, s => s.uuid === fromSeries) || first(seriesList)
}

export function normalizeSeriesPart(part: SeriesFullPartProps) {
  const { resource, resource_type, position, position_label } = part

  const { result, entities } = normalize(
    resource,
    resourceSchemaByType[resource_type],
  )

  return {
    resource: {
      uuid: result,
    },
    entities,
    resource_type,
    position,
    position_label,
  }
}

export function normalizeSeriesParts(
  parts: SeriesFullPartProps[],
): NormalizedParts {
  if (!parts.length)
    return {
      result: [],
      entities: [],
    }
  let allEntities = {}

  const resultParts = parts.map<SeriesFullPartProps>(part => {
    const key = `${part.resource_type}s`
    const normalizedSeriesPart = normalizeSeriesPart(part)

    allEntities = {
      ...allEntities,
      [key]: merge(allEntities[key], normalizedSeriesPart.entities[key]),
    }

    return omit(normalizedSeriesPart, 'entities')
  })

  return {
    result: resultParts,
    entities: allEntities,
  }
}

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import prepareComponent from 'client/shared/decorators/prepare-component'
import { serverRedirectTo } from 'client/shared/helpers/redirect-helpers'
import { withI18n, withI18nProps } from '@lingui/react'
import compose from 'lodash/fp/compose'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import errorBoundary, {
  ErrorBoundaryProps,
} from 'client/shared/decorators/error-boundary'
import urlFor from 'shared/tools/url-helper'

import { State as StripeState } from 'client/shared/reducers/stripe-reducer'
import { loadPlans } from 'client/bookmate/reducers/subscription-reducer'

import Footer from 'client/shared/blocks/footer'
import SubscriptionLayout from 'client/bookmate/blocks/code-subscription-layout'

import { Dispatch, State } from 'shared/types/redux'
import { Location, Params } from 'client/shared/types/react-router'
import { CurrentUserState, Locale } from 'client/shared/types/current-user'
import { State as CodeState } from 'client/bookmate/reducers/code-reducer'
import { Helmet } from 'react-helmet-async'
import { MinimalHeader } from 'client/shared/blocks/minimal-header/minimal-header'

type Props = {
  code: CodeState
  params: Params
  dispatch: Dispatch
  stripe: StripeState
  currentUser: CurrentUserState
  location: Location
} & ErrorBoundaryProps &
  withI18nProps &
  DecoratorProps

export const ARRIVA = 'ARRIVA'
export const MA60 = 'MA60'

class CodeSubscriptionBox extends Component<Props> {
  render() {
    const {
      dispatch,
      stripe: { loading, error, paymentIntent },
      isDesktopAllSizes,
      i18n,
      currentUser: {
        data: { locale, country },
      },
      code,
      isMobileSize,
    } = this.props

    const language = i18n._(`shared.${locale}_lang`)
    const isArrivaCampaign = code.codeValue.includes(ARRIVA)
    const isMA60Campaign = code.codeValue.includes(MA60)
    const isMobile = isMobileSize()
    let logoKind = 'black'
    if (isArrivaCampaign) logoKind = 'blue'
    else if (isMA60Campaign) logoKind = 'brownish'

    return (
      <Fragment>
        {isArrivaCampaign && (
          <Helmet>
            <style type="text/css">{`body { background-color: #272727; color: white; } .code-subscription-layout { background-color: #272727; } .copyright { color: white; } .footer .store-button { color: white; } .footer .store-button .store-button__icon svg { fill: white; }`}</style>
          </Helmet>
        )}
        {isMA60Campaign && (
          <Helmet>
            <style type="text/css">{`body { background-color: #F4F2EF; color: black; } .code-subscription-layout { background-color: #F4F2EF; }`}</style>
          </Helmet>
        )}
        <MinimalHeader logoKind={logoKind} />
        {isArrivaCampaign && (
          <img
            className="decor__books-train"
            src={
              isMobile
                ? 'https://a.bmstatic.com/iu/93/132/mobile-des-21fead7cffb83b4a6699109e4d361db0.svg'
                : 'https://a.bmstatic.com/iu/46/250/desktop-52b735580716f41149c0ac066789e224.webp'
            }
            alt="bookmate x arriva"
          />
        )}
        {isMA60Campaign && (
          <img
            className="bookmate-ma60-logo"
            src={
              isMobile
                ? 'https://a.bmstatic.com/iu/223/19/logo line (1)-637f6ed1a7578c90e3bde35c314359d3.svg'
                : 'https://a.bmstatic.com/iu/63/136/logo line-d1ac4f780c75f2a95a4a2cdc6135ab80.svg'
            }
            alt="logo bookmate ma60"
          />
        )}
        <SubscriptionLayout
          loading={loading}
          locale={locale}
          country={country}
          code={code}
          callbackError={error}
          isDesktop={isDesktopAllSizes()}
          isMobile={isMobile}
          language={language as Locale}
          dispatch={dispatch}
          paymentIntent={paymentIntent}
        />
        <Footer />
      </Fragment>
    )
  }
}

const prepareWrapper = prepareComponent(async ({ store }) => {
  const { dispatch, getState } = store
  const {
    code: { codeValue },
    app,
  } = getState()

  await dispatch(loadPlans())

  if (!codeValue) {
    serverRedirectTo(urlFor.codePage(undefined, app.storedQuery), 302)
  }
})

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  code: state.code,
  stripe: state.stripe,
}))

const wrappers = compose(
  prepareWrapper,
  uiBox,
  connectWrapper,
  errorBoundary,
  withI18n({ update: true }),
)

export default wrappers(CodeSubscriptionBox)

import { normalize } from 'normalizr'
import uniq from 'lodash/uniq'

import { cacheAction } from 'shared/middlewares/cache-middleware'
import { CALL_API, CALL_API_ERROR } from 'shared/middlewares/api-middleware'

import { shelfSchema } from 'client/bookmate/reducers/schemas/schemas'
import {
  SHELF_FOLLOW,
  SHELF_UNFOLLOW,
  SHELF_FOLLOW_SUCCESS,
  SHELF_UNFOLLOW_SUCCESS,
} from 'client/bookmate/reducers/shelves-reducer'

import {
  postsSchema,
  preparePostsFromAPIResponse,
  loadPost,
  POST_ADDED,
  POST_REMOVED,
  POST_LIKED,
  POST_CHANGED,
} from 'client/bookmate/reducers/posts-reducer'

import {
  COMMENT_ADDED,
  COMMENT_REMOVED,
} from 'client/bookmate/reducers/comments-reducer'

import {
  booksSchema,
  usersSchema,
} from 'client/bookmate/reducers/schemas/schemas'

const SHELF_LOAD = 'SHELF_LOAD'
const SHELF_LOAD_SUCCESS = 'SHELF_LOAD_SUCCESS'

const SHELF_POSTS_LOAD = 'SHELF_POSTS_LOAD'
const SHELF_POSTS_LOAD_SUCCESS = 'SHELF_POSTS_LOAD_SUCCESS'

const SHELF_BOOKS_LOAD = 'SHELF_BOOKS_LOAD'
const SHELF_BOOKS_LOAD_SUCCESS = 'SHELF_BOOKS_LOAD_SUCCESS'

const SHELF_FOLLOWERS_LOAD = 'SHELF_FOLLOWERS_LOAD'
const SHELF_FOLLOWERS_LOAD_SUCCESS = 'SHELF_FOLLOWERS_LOAD_SUCCESS'

export function load(uuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}`,
      options: {
        data: {
          complete_topics: true,
        },
      },
      normalize: response => {
        const { result, entities } = normalize(response.bookshelf, shelfSchema)
        const _shelf = entities.shelves[result]

        return {
          result,
          entities: {
            ...entities,
            shelves: {
              ...entities.shelves,
              [result]: {
                ..._shelf,
                type:
                  response.authors && response.authors.length > 0
                    ? 'series'
                    : 'shelf',
              },
            },
          },
        }
      },
      types: [SHELF_LOAD, SHELF_LOAD_SUCCESS],
    },
  }
}

export const loadPostsEnsuringPost = function (options) {
  options.ensurePost = true
  return loadPosts(options)
}

export const loadBooks = function (uuid, params = {}) {
  const { page = 1, per_page = 20, append = false } = params

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/bookshelves/${uuid}/books`,
      options: {
        data: {
          page,
          per_page,
        },
      },
      schema: booksSchema,
      responseKey: 'books',
      modifyResponse: response => ({
        append,
        nextPage: response.result.length ? page + 1 : null,
      }),
      types: [SHELF_BOOKS_LOAD, SHELF_BOOKS_LOAD_SUCCESS],
    },
  }
}

export const loadPosts = cacheAction(
  function (options) {
    const {
      uuid,
      postUuid,
      p = 1,
      pp = 20,
      append = false,
      ensurePost = false,
    } = options

    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/bookshelves/${uuid}/posts`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        normalize: async (response, { dispatch }) => {
          const { result: _result, entities } = normalize(
            preparePostsFromAPIResponse(response.posts),
            postsSchema,
          )

          let result = _result

          if (ensurePost && shouldLoadPost(entities.posts, postUuid)) {
            await dispatch(loadPost(uuid, postUuid))
            result = [postUuid, ...result]
          }

          return {
            result,
            entities,
            append,
            nextPage: result.length ? p + 1 : null,
          }
        },
        types: [SHELF_POSTS_LOAD, SHELF_POSTS_LOAD_SUCCESS],
      },
    }
  },
  SHELF_POSTS_LOAD_SUCCESS,
  [
    POST_LIKED,
    POST_ADDED,
    POST_CHANGED,
    POST_REMOVED,
    COMMENT_ADDED,
    COMMENT_REMOVED,
  ],
)

export const loadFollowers = cacheAction(
  function ({ uuid, p = 1, pp = 20, append = false }) {
    return {
      [CALL_API]: {
        endpoint: `/p/api/v5/bookshelves/${uuid}/followers`,
        options: {
          data: {
            page: p,
            per_page: pp,
          },
        },
        schema: usersSchema,
        responseKey: 'users',
        modifyResponse: response => ({
          append,
          nextPage: response.result.length ? p + 1 : null,
        }),
        types: [SHELF_FOLLOWERS_LOAD, SHELF_FOLLOWERS_LOAD_SUCCESS],
      },
    }
  },
  SHELF_FOLLOWERS_LOAD_SUCCESS,
  [SHELF_FOLLOW_SUCCESS, SHELF_UNFOLLOW_SUCCESS],
)

function shouldLoadPost(posts, postId) {
  return !posts || !(postId in posts)
}

const initialState = {
  loading: false,
  error: null,
  uuid: '',
  type: 'shelf',
  followers_count: 0,
  books_count: 0,
  posts_count: 0,
  followers: [],
  posts: [],
  books: [],
  pages: {
    followers: 1,
    posts: 1,
    books: 1,
  },
}

export default function shelf(state = initialState, action) {
  switch (action.type) {
    case SHELF_LOAD:
      return {
        ...state,
        uuid: '',
        loading: true,
        followers: [],
        posts: [],
        pages: {
          ...initialState.pages,
        },
      }

    case SHELF_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uuid: action.result,
        error: null,
      }

    case SHELF_FOLLOW:
    case SHELF_UNFOLLOW:
      return {
        ...state,
        loading: true,
      }

    case SHELF_FOLLOW_SUCCESS:
    case SHELF_UNFOLLOW_SUCCESS:
    case CALL_API_ERROR:
      return {
        ...state,
        loading: false,
      }

    case SHELF_POSTS_LOAD_SUCCESS:
      return {
        ...state,
        // since a post can occur repeatedly in the array of posts brought by the action,
        // (because it could be requested separately from a different api than other posts),
        // we need to ensure uniqueness of posts' ids
        posts: action.append
          ? uniq([...state.posts, ...action.result])
          : action.result,
        pages: {
          ...state.pages,
          posts: action.nextPage,
        },
      }

    case SHELF_BOOKS_LOAD_SUCCESS:
      return {
        ...state,
        books: action.append
          ? [...state.books, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          books: action.nextPage,
        },
      }

    case SHELF_FOLLOWERS_LOAD_SUCCESS:
      return {
        ...state,
        followers: action.append
          ? [...state.followers, ...action.result]
          : action.result,
        pages: {
          ...state.pages,
          followers: action.nextPage,
        },
      }

    case POST_REMOVED:
      if (action.shelfUuid !== state.uuid) {
        return state
      }

      return {
        ...state,
        posts: state.posts.filter(uuid => uuid !== action.uuid),
      }

    default:
      return state
  }
}

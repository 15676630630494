import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'

import Linka from 'client/shared/blocks/linka'

import './library-banner.styl'

import CloseIcon from 'client/shared/icons/close.svg'

import {
  LibraryBannerProps,
  LibraryBannerButton,
} from 'client/shared/types/library-banner'

type Props = {
  banner: LibraryBannerProps
  onClose: (arg0: string) => void
}

type State = {
  hidden: boolean
}

export default class LibraryBanner extends Component<Props, State> {
  state = {
    hidden: false,
  }

  onClose = (): void => {
    const {
      onClose,
      banner: { tag },
    } = this.props

    this.setState({ hidden: true })
    onClose(tag)
  }

  render(): JSX.Element | null {
    const {
      banner: { closable, background_color, background_image, buttons },
    } = this.props

    const bannerStyle = {
      backgroundColor: background_color,
      backgroundImage: `url(${background_image})`,
    }

    if (this.state.hidden) return null

    return (
      <div className="library-banner" style={bannerStyle}>
        {closable && (
          <div className="library-banner__close">
            <SVGInline svg={CloseIcon} onClick={this.onClose} />
          </div>
        )}
        {buttons.length > 1
          ? this.renderUnclickableContent()
          : this.renderClickableContent()}
      </div>
    )
  }

  renderUnclickableContent(): JSX.Element {
    const {
      banner: { buttons },
    } = this.props

    return (
      <div className="library-banner__content">
        {this.renderTitle()}
        <div>
          {buttons.map((button, index) => this.renderButton(button, index))}
        </div>
      </div>
    )
  }

  renderClickableContent(): JSX.Element {
    const {
      text,
      text_color,
      background_color,
      link,
    } = this.props.banner.buttons[0]

    const buttonStyle = {
      color: text_color,
      backgroundColor: background_color,
    }

    return (
      <Linka path={link} target="_blank" className="library-banner__content">
        {this.renderTitle()}
        <span className="library-banner__button" style={buttonStyle}>
          {text}
        </span>
      </Linka>
    )
  }

  renderButton(button: LibraryBannerButton, index: number): JSX.Element {
    const { text, text_color, background_color, link } = button

    const buttonStyle = {
      color: text_color,
      backgroundColor: background_color,
    }

    return (
      <Linka
        path={link}
        target="_blank"
        className="library-banner__button"
        key={index}
        style={buttonStyle}
      >
        {text}
      </Linka>
    )
  }

  renderTitle(): JSX.Element {
    const {
      banner: { title_text, title_color },
    } = this.props

    return (
      <pre className="library-banner__title" style={{ color: title_color }}>
        {title_text}
      </pre>
    )
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Transition, animated } from 'react-spring/renderprops.cjs'
import { Trans, withI18n, withI18nProps } from '@lingui/react'

import compose from 'lodash/fp/compose'
import pick from 'lodash/fp/pick'
import values from 'lodash/fp/values'
import every from 'lodash/fp/every'
import isEqual from 'lodash/isEqual'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import {
  getStripe,
  getErrorMessageIdForStripeElementError,
  getErrorMessageIdForStripeApiError,
} from 'client/shared/helpers/stripe-helper'

import Button from 'client/shared/blocks/button'
import { Dropdown } from 'client/shared/blocks/dropdown'
import Input from 'client/shared/blocks/input'
import Spacer from 'client/shared/blocks/spacer'
import CreditCardPlaceholder from './credit-card-placeholder/credit-card-placeholder'
import { RecurrentNotice } from './recurrent-notice'
import { PaymentRejectedError } from './payment-rejected-error'
import { AcceptedCards } from './accepted-cards/accepted-cards'
import StripeCardElement, { StripeCardElementType } from './stripe-card-element'
import CardLogo from './card-logo'
import SVGInline from 'react-svg-inline'
import LockIcon from 'client/shared/icons/lock.svg'
import StripeIcon from 'client/shared/icons/stripe.svg'
import TaxesComment from 'client/bookmate/blocks/subscription-form/taxes-comment'

import {
  StripeElementDataObject,
  StripeError,
  StripeElementError,
  StripeErrorResponse,
  StripeSourceResponse,
} from 'client/shared/types/stripe'
import { CurrentUserState } from 'client/shared/types/current-user'
import { State as ConnectedState } from 'shared/types/redux'
import {
  PaymentIntent,
  PAYMENT_INTENT,
} from 'client/shared/reducers/stripe-reducer'
import './stripe-credit-card.styl'
import { showAlert } from 'client/shared/reducers/alert-reducer'

type Props = {
  onError: (arg0: StripeError) => void
  disabled: boolean
  ready: boolean
  scriptLoadingFailed: boolean
  isDarkTheme?: boolean
  isGiftsPage?: boolean
  apiError:
    | {
        message: string
      }
    | null
    | undefined
  awaitingApiResponse: boolean
  buttonTextId: string
  paymentIntent: PaymentIntent
  giftsPaymentIntent: PaymentIntent
  planId?: string
  buttonChild: React.ReactNode | null | undefined
  showRecurrentNotice: boolean | null | undefined
  noticeAddition: React.ReactNode | null | undefined
  currentUser: CurrentUserState
  offerRegularPlan?: boolean
  isUpdateCard?: boolean
  onSubmit?: (res) => void
} & DecoratorProps &
  withI18nProps

type State = {
  awaitingStripeResponse: boolean
  markedField: 'cvc' | 'card-number' | 'expiration-date' | 'all' | null
  cardNumber: {
    error: StripeElementError | null | undefined
    brand: string | null | undefined
    complete: boolean
  }
  cardCVC: {
    error: StripeElementError | null | undefined
    complete: boolean
  }
  cardExpiryDate: {
    error: StripeElementError | null | undefined
    complete: boolean
  }
  cardholderName: string
  shouldShowCVCDropdown: boolean
  stripePaymentError: StripeError | null | undefined
}

const ERROR_TO_FIELD_MARKERS = {
  invalid_number: 'card-number',
  incorrect_number: 'card-number',
  invalid_expiry_year: 'expiration-date',
  invalid_expiry_month: 'expiration-date',
  invalid_cvc: 'cvc',
  incorrect_cvc: 'cvc',
  expired_card: 'expiration-date',
}

class StripeCreditCard extends Component<Props, State> {
  static defaultProps = {
    noticeAddition: null,
    buttonChild: null,
    showRecurrentNotice: true,
    buttonTextId: 'buttons.submit',
  }

  state = {
    awaitingStripeResponse: false,
    cardNumber: {
      error: undefined,
      brand: undefined,
      complete: false,
    },
    cardCVC: {
      error: undefined,
      complete: false,
    },
    cardExpiryDate: {
      error: undefined,
      complete: false,
    },
    cardholderName: '',
    shouldShowCVCDropdown: false,
    stripePaymentError: null,
    markedField: null,
  }

  stripeCardNumber = undefined

  areStripeFieldsReady = compose(
    every(fieldData => !fieldData.error && fieldData.complete),
    values,
    pick(['cardNumber', 'cardCVC', 'cardExpiryDate']),
  )

  checkIfCardNumberReady(): boolean {
    const {
      cardNumber: { error, complete },
    } = this.state
    return !error && complete
  }

  getPaymentSystem() {
    const {
      cardNumber: { brand },
    } = this.state

    return brand && brand !== 'unknown' ? brand : undefined
  }

  getErrorMessage(error?: StripeError | StripeElementError) {
    if (!error) return ''
    let errorIdPortion = ''
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.isStripePaymentError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      errorIdPortion = getErrorMessageIdForStripeApiError(error)
    } else {
      errorIdPortion = getErrorMessageIdForStripeElementError(error)
    }

    if (errorIdPortion) {
      const errorId = `errors.${errorIdPortion}`
      return <Trans id={errorId} />
    } else if (error.message) {
      return (
        <Trans
          id="errors.unknown_with_original_error"
          values={{ error: error.message }}
        />
      )
    } else {
      return <Trans id="errors.unknown" />
    }
  }

  toggleCVCDropdown = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ shouldShowCVCDropdown: !this.state.shouldShowCVCDropdown })
  }

  awaitingStripeResponse(isAwaiting) {
    this.setState({ awaitingStripeResponse: isAwaiting })
  }

  handleStripeElementChange = (
    elementType: StripeCardElementType,
    stripeDataObject: StripeElementDataObject,
  ) => {
    switch (elementType) {
      case 'cardNumber':
        this.handleCardNumberChange(stripeDataObject)
        break
      case 'cardCvc':
        this.handleCardCVCChange(stripeDataObject)
        break
      case 'cardExpiry':
        this.handleCardExpiryChange(stripeDataObject)
        break
      default:
        break
    }
  }

  handleCardNumberChange(stripeDataObject) {
    const newData = pick(['error', 'brand', 'complete'], stripeDataObject)
    if (!isEqual(this.state.cardNumber, newData)) {
      this.setState({ cardNumber: newData })
    }
  }

  handleCardCVCChange(stripeDataObject) {
    const newData = pick(['error', 'complete'], stripeDataObject)
    if (!isEqual(this.state.cardCVC, newData)) {
      this.setState({ cardCVC: newData })
    }
  }

  handleCardExpiryChange(stripeDataObject) {
    const newData = pick(['error', 'complete'], stripeDataObject)
    if (!isEqual(this.state.cardExpiryDate, newData)) {
      this.setState({ cardExpiryDate: newData })
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    return this.props.isUpdateCard ? this.handleUpdate() : this.handlePurchase()
  }
  handleUpdate = () => {
    const { stripeCardNumber } = this
    if (!stripeCardNumber) return

    const { onSubmit, onError, offerRegularPlan } = this.props
    const stripe = getStripe()

    this.clearStripeErrors()
    this.awaitingStripeResponse(true)

    stripe
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .createSource(stripeCardNumber.stripeElement, {
        owner: { name: this.state.cardholderName },
      })
      .then((result: StripeSourceResponse | StripeErrorResponse) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (result.error && !offerRegularPlan) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onError(result.error)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.setStripeError(result.error)
        } else if (onSubmit) onSubmit(result)
        this.awaitingStripeResponse(false)
      })
      .catch(error => {
        throw error
      })
  }

  handlePurchase = () => {
    const { stripeCardNumber } = this

    if (!stripeCardNumber) return

    const stripe = getStripe()

    this.clearStripeErrors()
    this.awaitingStripeResponse(true)

    const paymentMethod = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      card: stripeCardNumber.stripeElement,
      billing_details: {
        name: this.state.cardholderName,
      },
    }

    const { isGiftsPage, dispatch } = this.props

    const paymentIntent = isGiftsPage
      ? this.props.giftsPaymentIntent
      : this.props.paymentIntent

    if (paymentIntent.error) {
      dispatch(
        showAlert('error', {
          message:
            typeof paymentIntent.error === 'string'
              ? paymentIntent.error
              : paymentIntent.error?.message,
        }),
      )
      return this.awaitingStripeResponse(false)
    }

    if (paymentIntent.kind === PAYMENT_INTENT) {
      stripe
        .confirmCardPayment(paymentIntent.key, {
          payment_method: paymentMethod,
        })
        .then((result: StripeErrorResponse) => {
          if (result.error) {
            this.props.onError(result.error)
            this.setStripeError(result.error)
            this.awaitingStripeResponse(false)
          } else {
            this.awaitingStripeResponse(false)
            window.location.replace(paymentIntent.returnUrl as string)
          }
        })
    } else {
      stripe
        .confirmCardSetup(paymentIntent.key, {
          payment_method: paymentMethod,
        })
        .then((result: StripeErrorResponse) => {
          if (result.error) {
            this.props.onError(result.error)
            this.setStripeError(result.error)
            this.awaitingStripeResponse(false)
          } else {
            this.awaitingStripeResponse(false)
            window.location.replace(paymentIntent.returnUrl as string)
          }
        })
    }
  }

  clearStripeErrors(): void {
    if (this.state.stripePaymentError) {
      this.setState({ stripePaymentError: null })
    }
  }

  setStripeError(error): void {
    this.setState({
      stripePaymentError: {
        ...error,
        isStripePaymentError: true,
      },
    })
    const { stripePaymentError } = this.state
    this.setState({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      markedField: ERROR_TO_FIELD_MARKERS[stripePaymentError?.code] || 'all',
    })
  }

  canSubmit(): boolean {
    const { disabled } = this.props
    return !disabled && this.areFieldsReady(this.state)
  }

  areFieldsReady(state): boolean {
    return this.areStripeFieldsReady(state) && this.areCustomFieldsReady(state)
  }

  areCustomFieldsReady(state): boolean {
    return Boolean(state.cardholderName.trim().length) // cardholder field
  }

  /**
   * check whether form submission was unsuccessful due to either stripe returning
   * error instead of token, or due to some error of our backend
   */
  isPaymentRejected(): { message: string } | null | undefined {
    return this.state.stripePaymentError || this.props.apiError
  }

  isWaitingForResponseAfterSubmit(): boolean {
    return this.state.awaitingStripeResponse || this.props.awaitingApiResponse
  }

  onCardholderChange = ({ target }: Event): void => {
    if (target instanceof HTMLInputElement) {
      this.setState({ cardholderName: target.value })
    }
  }

  render(): JSX.Element {
    const { ready, scriptLoadingFailed } = this.props
    const baseClass = 'stripe-credit-card'
    const errorMod = this.isPaymentRejected() ? `${baseClass}_error` : ''
    const blockClass = `${baseClass} ${errorMod}`

    return (
      <div className={blockClass}>
        {ready ? (
          <div className="stripe-credit-card__inner">{this.renderForm()}</div>
        ) : (
          <CreditCardPlaceholder error={scriptLoadingFailed} />
        )}
      </div>
    )
  }

  renderForm() {
    const {
      offerRegularPlan,
      apiError,
      showRecurrentNotice,
      noticeAddition,
      currentUser: {
        data: { country },
      },
    } = this.props

    const shouldRenderRecurrentNotice = country !== 'mx'

    return (
      <div>
        <form
          className={`stripe-credit-card__form error_${this.state.markedField}`}
          onSubmit={this.handleSubmit}
        >
          {this.renderCardNumber()}
          {this.isPaymentRejected() && !offerRegularPlan && (
            <PaymentRejectedError
              apiError={apiError}
              stripeError={this.state.stripePaymentError}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              getErrorMessage={this.getErrorMessage}
            />
          )}
          <div className="stripe-credit-card__row">
            {this.renderCardHolder()}
            {this.renderExpiryDate()}
            {this.renderCVC()}
          </div>
          <div className="stripe-credit-card__submit-button-container">
            {this.renderSubmitButton()}
          </div>
          <TaxesComment />
        </form>
        <Spacer size={24} />
        {showRecurrentNotice && shouldRenderRecurrentNotice && (
          <RecurrentNotice />
        )}
        <Spacer size={32} />
        <div className="stripe-credit-card__privacy-notice">
          <div className="stripe-credit-card__privacy-notice__wrapper">
            <SVGInline
              className="stripe-credit-card__privacy-notice__icon"
              svg={LockIcon}
            />
            <span className="stripe-credit-card__privacy-notice__text">
              <Trans id="credit-card.privacy_notice" />
            </span>
          </div>
        </div>
        {noticeAddition && (
          <div className="stripe-credit-card__notice-addition">
            {' '}
            {noticeAddition}
          </div>
        )}
        <Spacer size={24} />
        <div className="subscribe-form__accepted-cards">
          <SVGInline
            className="stripe-credit-card__stripe__icon"
            svg={StripeIcon}
          />
          <AcceptedCards />
        </div>
      </div>
    )
  }

  renderCardNumber(): JSX.Element {
    const { currentUser, isDarkTheme } = this.props
    const elementType = 'cardNumber'
    const { error } = this.state.cardNumber
    const paymentSystem = this.getPaymentSystem()

    return (
      <div className="stripe-credit-card__row">
        <div className="stripe-credit-card__input-group">
          <label className="card-number">
            <div className="stripe-credit-card__field-label">
              <Trans id="credit-card.card_number" />
            </div>
            <StripeCardElement
              locale={currentUser.data.locale}
              isDarkTheme={isDarkTheme}
              type={elementType}
              error={error}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ref={element => (this.stripeCardNumber = element)}
              onChange={elementData =>
                this.handleStripeElementChange(elementType, elementData)
              }
            />
            <Transition
              native
              config={{ tension: 280, friction: 20 }}
              items={paymentSystem}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
            >
              {_paymentSystem =>
                Boolean(_paymentSystem) &&
                (props => (
                  <animated.span style={props}>
                    <CardLogo paymentSystem={_paymentSystem || ''} />
                  </animated.span>
                ))
              }
            </Transition>
          </label>
          <div className="stripe-credit-card__field-error">
            {error && this.getErrorMessage(error)}
          </div>
        </div>
        <Spacer size={24} />
      </div>
    )
  }

  renderCardHolder(): JSX.Element {
    const { i18n } = this.props
    const placeholder = i18n.t`credit-card.name_on_card`
    return (
      <div className="stripe-credit-card__input-group stripe-credit-card__input-group_short stripe-credit-card__cardholder-field">
        <label className="card-holder" htmlFor="cardholder-name">
          <div className="stripe-credit-card__field-label">
            <Trans id="credit-card.card_holder" />
          </div>
          <Input
            id="cardholder-name"
            name="cardholder-name"
            value={this.state.cardholderName}
            onChange={this.onCardholderChange}
            placeholder={placeholder}
            className="stripe-card-element"
          />
        </label>
      </div>
    )
  }

  renderCVC(): JSX.Element {
    const { isMobileSize, isDarkTheme, currentUser } = this.props
    const elementType = 'cardCvc'
    const { error } = this.state.cardCVC
    const cvcExplanationMessage = (
      <div className="stripe-credit-card__field-label-comment">
        <Trans id="credit-card.cvc_explanation" />
      </div>
    )
    const cvcExplanationTooltip = (
      <div
        className="stripe-credit-card__cvc-info"
        onClick={this.toggleCVCDropdown}
      >
        <span>?</span>
        <Dropdown
          position="top-center"
          offsetTop={20}
          hidden={!this.state.shouldShowCVCDropdown}
        >
          <div className="stripe-credit-card__field-label-dropdown">
            <Trans id="credit-card.cvc_explanation" />
          </div>
        </Dropdown>
      </div>
    )

    return (
      <div className="stripe-credit-card__input-group stripe-credit-card__input-group_short">
        <label className="cvc">
          <div className="stripe-credit-card__field-label stripe-credit-card__cvc-label">
            <Trans id="credit-card.cvc" />
          </div>
          {isMobileSize() ? cvcExplanationMessage : cvcExplanationTooltip}
          <StripeCardElement
            isDarkTheme={isDarkTheme}
            type={elementType}
            placeholder="CVC"
            error={error}
            locale={currentUser.data.locale}
            onChange={elementData =>
              this.handleStripeElementChange(elementType, elementData)
            }
          />
        </label>
        <div className="stripe-credit-card__field-error">
          {error && this.getErrorMessage(error)}
        </div>
      </div>
    )
  }

  renderExpiryDate(): JSX.Element {
    const { i18n, currentUser, isDarkTheme } = this.props
    const { error } = this.state.cardExpiryDate
    const elementType = 'cardExpiry'
    return (
      <div className="stripe-credit-card__input-group stripe-credit-card__input-group_short">
        <label htmlFor="expiration-date" className="expiration-date">
          <div className="stripe-credit-card__field-label">
            <Trans id="credit-card.expiration_date" />
          </div>
          <StripeCardElement
            isDarkTheme={isDarkTheme}
            type={elementType}
            locale={currentUser.data.locale}
            placeholder={i18n.t`shared.month_year_abbreviated`}
            error={error}
            onChange={elementData =>
              this.handleStripeElementChange(elementType, elementData)
            }
          />
        </label>
        <div className="stripe-credit-card__field-error">
          {error && this.getErrorMessage(error)}
        </div>
        <Spacer size={24} />
      </div>
    )
  }

  renderSubmitButton(): JSX.Element {
    const { buttonTextId, buttonChild } = this.props
    const disabled = !this.canSubmit()

    return (
      <Button
        kind="primary"
        loading={this.isWaitingForResponseAfterSubmit()}
        disabled={disabled}
        onClick={this.handleSubmit}
      >
        {buttonChild || <Trans id={buttonTextId} />}
      </Button>
    )
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  currentUser: state.currentUser,
  paymentIntent: state.stripe.paymentIntent,
  giftsPaymentIntent: state.gifts.paymentIntent,
}))

const wrappers = compose(connectWrapper, uiBox, withI18n({ update: true }))

export default wrappers(StripeCreditCard)

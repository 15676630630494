/*
  App with root url (/) must be the last in the array
  {
    name    : '',  // app name
    path    : '',  // app init.ts file path
    template: '',  // app index.html file path, default './shared/index.html'
    url     : ''   // app url
  }
*/

export const ERRORS_POINT_NAME = 'errors'

interface Point {
  name: string
  path: string
  url: string
}

const points: Point[] = [
  {
    name: ERRORS_POINT_NAME,
    path: './client/errors/init.ts',
    url: '/errors',
  },
  // the Bookmate point is the default point, and needs to be the last in this list
  // (for proper matching of the url by Express router)
  {
    name: 'bookmate',
    path: './client/bookmate/init.ts',
    url: '/',
  },
]

export default points

export const commonVendor = [
  'react',
  'react-dom',
  'react-router',
  'react-redux',
  'react-router-redux',
  'redux',
  'normalizr',
]

export const serverPoint = './server/server.ts'

export function isErrorsPoint(point: Point) {
  return point.name === ERRORS_POINT_NAME
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'

import { createLibraryCard } from 'client/bookmate/reducers/library-cards-reducer'
import { createAudioCard } from 'client/bookmate/reducers/audio-cards-reducer'
import { createComicbookCard } from 'client/bookmate/reducers/comicbooks-reducer'
import { follow as followUser } from 'client/bookmate/reducers/users-reducer'
import { follow as followShelf } from 'client/bookmate/reducers/shelves-reducer'
import { follow as followSeries } from 'client/bookmate/reducers/series-list-reducer'

import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import comicbookHelper from 'shared/tools/comicbook-helper'
import audioBookHelper from 'shared/tools/audiobook-helper'
import bookHelper from 'shared/tools/book-helper'

import { ShelfContextMenuBox } from 'client/shared/boxes/shelf-context-menu-box'
import { UserContextMenuBox } from 'client/shared/boxes/user-context-menu-box'
import SeriesContextButtonBox from 'client/shared/boxes/series-context-button-box'

import Plus from 'client/shared/blocks/plus'
import Linka from 'client/shared/blocks/linka'

import {
  CurrentUserState,
  CurrentUserData,
} from 'client/shared/types/current-user'
import { BookSerialProps } from 'client/shared/types/resource'
import { AudiobookProps } from 'client/shared/types/audiobook'
import { ComicbookProps } from 'client/shared/types/comicbook'
import { ShelfProps } from 'client/shared/types/shelf'
import { UserShort } from 'client/shared/types/user'
import { SeriesProps } from 'client/shared/types/series'
import { Dispatch, State as ConnectedState } from 'shared/types/redux'
import { subscribeToAuthor } from 'client/bookmate/reducers/author-reducer'
import { AuthorProps } from '../types/author'

type Props = {
  currentUser: CurrentUserState
  book?: BookSerialProps
  audiobook?: AudiobookProps
  comicbook?: ComicbookProps
  shelf?: ShelfProps
  user?: UserShort | CurrentUserData
  author?: AuthorProps
  series?: SeriesProps
  stateless: boolean
  query?: QueryParams
  feed?: boolean
} & { dispatch: Dispatch }

type State = {
  isAdded: boolean
}

class PlusBox extends Component<Props, State> {
  static defaultProps = {
    stateless: false,
  }

  state = {
    isAdded: false,
  }

  constructor(props) {
    super(props)
    if (this.isAdded()) {
      this.state = { isAdded: true }
    }
  }

  handleAddClick = () => {
    const { dispatch, currentUser } = this.props

    if (!currentUser.auth) {
      return dispatch(showAuthPopup({ callback: this.clickHandler }))
    }

    return this.clickHandler().then(() => {
      this.setState({ isAdded: true })
    })
  }

  clickHandler = () => {
    const {
      book,
      audiobook,
      comicbook,
      user,
      shelf,
      series,
      author,
    } = this.props

    if (audiobook) {
      return this.addAudiobook(audiobook)
    } else if (book) {
      return this.addBook(book)
    } else if (comicbook) {
      return this.addComicbook(comicbook)
    } else if (user) {
      return this.followUser(user)
    } else if (author) {
      return this.followAuthor(author)
    } else if (shelf) {
      return this.followShelf(shelf)
    } else if (series) {
      return this.followSeries(series)
    }
  }

  addAudiobook(audiobook) {
    const { dispatch } = this.props

    return dispatch(createAudioCard({}, audiobook))
  }

  addBook(book) {
    const { dispatch, currentUser, query } = this.props
    const { resourceType, title } = book

    return dispatch(createLibraryCard({}, book)).then(() =>
      dispatch(
        showAlert('success', {
          message: `alerts.${resourceType || 'book'}_added`,
          values: { title },
          components: [
            <Linka path={urlFor.userBooksAll(currentUser.data.login, query)} />,
          ],
        }),
      ),
    )
  }

  addComicbook(comicbook) {
    const { dispatch } = this.props

    return dispatch(createComicbookCard({}, comicbook))
  }

  followShelf(shelf) {
    const { dispatch } = this.props

    return dispatch(
      followShelf(shelf.uuid, {
        shelf_id: shelf.uuid,
      }),
    )
  }

  followUser(user) {
    const { dispatch } = this.props

    return dispatch(
      followUser(user.login, {
        followee_id: user.id,
      }),
    )
  }

  followAuthor(author: AuthorProps) {
    const { dispatch } = this.props
    return dispatch(subscribeToAuthor(author.uuid))
  }

  followSeries(series) {
    const { dispatch } = this.props

    return dispatch(followSeries(series.uuid))
  }

  isAdded() {
    const { audiobook, comicbook, book, shelf, user, series } = this.props

    let result

    if (audiobook) {
      result = audioBookHelper.isAdded(audiobook)
    } else if (comicbook) {
      result = comicbookHelper.isAdded(comicbook)
    } else if (book) {
      result = bookHelper.isAdded(book)
    } else if (shelf) {
      result = shelf.following
    } else if (user) {
      result = user.following
    } else if (series) {
      result = series.following
    }

    return Boolean(result)
  }

  onStateChange = () => {
    this.setState({ isAdded: false })
  }

  render() {
    const { stateless, feed } = this.props

    if (feed && this.state.isAdded) return null

    return (
      <Plus
        isAdded={stateless ? this.isAdded() : this.state.isAdded}
        onAdd={this.handleAddClick}
        stateless={stateless}
        contextMenu={this.renderContextMenuBox()}
      />
    )
  }

  renderContextMenuBox() {
    const { user, shelf, series } = this.props

    if (user) {
      return (
        <UserContextMenuBox
          user={user}
          kind="expand"
          onStateChange={this.onStateChange}
        />
      )
    } else if (shelf) {
      return (
        <ShelfContextMenuBox
          shelf={shelf}
          kind="expand"
          onStateChange={this.onStateChange}
        />
      )
    } else if (series) {
      return (
        <SeriesContextButtonBox
          series={series}
          kind="expand"
          onStateChange={this.onStateChange}
        />
      )
    }
  }
}

const wrappers = compose(
  connect((state: ConnectedState) => ({
    currentUser: state.currentUser,
    query: state.app.storedQuery,
  })),
)

export default wrappers(PlusBox)

import React, { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import Linka from 'client/shared/blocks/linka'

import './audiobook.styl'

import AudioBookCover from 'client/shared/blocks/audiobook-cover'
import BadgeList from 'client/shared/blocks/badge-list'
import PlusBox from 'client/shared/boxes/plus-box'
import MetaCounters from 'client/bookmate/blocks/meta-counters'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'
import urlFor from 'shared/tools/url-helper'
import TextTruncate from 'client/shared/blocks/text-truncate'
import { AuthorsList } from 'client/shared/blocks/authors-list'
import BookStatus from 'client/shared/blocks/book-status'

import { AudiobookProps } from 'client/shared/types/audiobook'
import { AudiobookCoverSize } from 'client/shared/types/cover'
import { AUDIOBOOK } from '../search-best-match/search-best-match'
import { MinimalAuthorInfo } from 'client/shared/types/author'

type Props = {
  audiobook: AudiobookProps
  kind: 'grid' | 'list'
  titleLines: number
  authorsLines: number
  hoverable: boolean
  showPlus: boolean
  description: boolean
  finished?: boolean
  progress?: number
  inPrivate?: boolean
  coverSize?: AudiobookCoverSize
  seriesPosition?: string
  seriesUuid?: string
  id?: string
  onClick?: () => void
} & DecoratorProps

class AudioBook extends Component<Props> {
  static defaultProps = {
    hoverable: false,
    authorsLines: 1,
    titleLines: 2,
    showPlus: true,
    description: true,
  }

  getCoverSize() {
    const { kind, coverSize } = this.props

    if (!coverSize) {
      return ['grid'].includes(kind) ? 120 : 88
    }

    return coverSize
  }

  getCoverSizeKind() {
    const { app } = this.props

    const coverSize = this.getCoverSize()

    return typeof coverSize === 'object' ? coverSize[app.size] : coverSize
  }

  getPath() {
    const {
      audiobook: { uuid },
      seriesUuid,
      app,
    } = this.props

    return urlFor.resource(uuid, AUDIOBOOK, {
      ...app.storedQuery,
      from_series: seriesUuid as string,
    })
  }

  shouldShowPlus(): boolean {
    const {
      kind,
      audiobook: { can_be_listened = true },
      showPlus,
    } = this.props

    return showPlus && can_be_listened && ['list', 'related'].includes(kind)
  }

  render(): JSX.Element | null {
    const { audiobook } = this.props

    if (isEmpty(audiobook)) return null

    const {
      description,
      kind,
      hoverable,
      seriesPosition,
      id,
      onClick,
    } = this.props

    const hoverableMod = hoverable ? 'audiobook_hoverable' : ''

    switch (kind) {
      case 'list':
        return (
          <li
            onClick={onClick}
            id={id}
            className={`audiobook audiobook_${kind} ${hoverableMod} audiobook_${this.getCoverSizeKind()}`}
          >
            <div className="audiobook__cover">
              {seriesPosition && (
                <span className="audiobook__position">{seriesPosition}</span>
              )}
              {this.renderCover()}
            </div>
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </li>
        )
      case 'grid':
        return (
          <div
            onClick={onClick}
            id={id}
            className={`audiobook audiobook_${kind} ${hoverableMod} audiobook_${this.getCoverSizeKind()}`}
          >
            <div className="audiobook__reading-status">
              {this.renderBookStatus()}
            </div>
            <div className="audiobook__cover">
              {seriesPosition && (
                <span className="audiobook__position">{seriesPosition}</span>
              )}
              {this.renderCover()}
            </div>
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </div>
        )
      default:
        return (
          <div
            id={id}
            className={`audiobook audiobook_${kind} ${hoverableMod} audiobook_${this.getCoverSizeKind()}`}
          >
            {['grid'].includes(kind) && (
              <div className="audiobook__reading-status">
                {this.renderBookStatus()}
              </div>
            )}
            <div className="audiobook__cover">
              {seriesPosition && (
                <span className="audiobook__position">{seriesPosition}</span>
              )}
              {this.renderCover()}
            </div>
            {description && this.renderDescription()}
            {this.shouldShowPlus() && this.renderPlus()}
          </div>
        )
    }
  }

  renderCover() {
    const { audiobook, description } = this.props

    const audiobookCover = (
      <AudioBookCover audiobook={audiobook} size={this.getCoverSize()} />
    )

    return description ? (
      audiobookCover
    ) : (
      <Linka title={audiobook.title} path={this.getPath()}>
        {audiobookCover}
      </Linka>
    )
  }

  renderDescription() {
    const {
      audiobook: {
        language,
        title,
        authors,
        labels,
        listeners_count,
        impressions_count,
      },
      kind,
      titleLines,
    } = this.props

    const counters = [
      { kind: 'users', count: listeners_count },
      { kind: 'impressions', count: impressions_count },
    ].filter(({ count }) => count > 0)

    return (
      <div className="audiobook__description">
        <AuthorsList
          authors={authors as MinimalAuthorInfo[]}
          className="audiobook__authors"
          kind="compact"
        />
        <Linka title={title} className="audiobook__title" path={this.getPath()}>
          <TextTruncate lines={titleLines} text={title} textType="title" />
        </Linka>
        {['list'].includes(kind) && (
          <div className="audiobook__reading-status">
            {this.renderBookStatus()}
          </div>
        )}
        {['list'].includes(kind) && Boolean(counters.length) && (
          <MetaCounters counters={counters} />
        )}
        {['list'].includes(kind) && labels && (
          <BadgeList labels={labels} language={language} />
        )}
      </div>
    )
  }

  renderPlus() {
    return <PlusBox audiobook={this.props.audiobook} />
  }

  renderBookStatus() {
    const { finished, progress, inPrivate } = this.props

    return (
      <BookStatus
        isFinished={finished}
        progress={progress}
        isPrivate={inPrivate}
      />
    )
  }
}

export default uiBox(AudioBook)

import find from 'lodash/find'

import env from 'env'
import urlFor, { addParamsToPath, QueryParams } from 'shared/tools/url-helper'

import {
  TYPE_BOOK,
  TYPE_AUDIOBOOK,
  TYPE_BOOKSHELF,
  TYPE_COMICBOOK,
  TYPE_SERIES,
  TYPE_LIBRARY,
  TYPE_MIXED,
} from 'client/shared/types/showcase'
import { ResourcesType } from 'client/shared/types/topic'
import { Locale } from 'client/shared/types/current-user'
import { ShowcaseResource } from 'client/shared/types/resource'

export const availableTypes = [
  TYPE_BOOK,
  TYPE_AUDIOBOOK,
  TYPE_COMICBOOK,
  TYPE_MIXED,
]
const CONTENT_TYPE = {
  books: TYPE_BOOK,
  audiobooks: TYPE_AUDIOBOOK,
  comicbooks: TYPE_COMICBOOK,
  library: TYPE_MIXED,
}

export const ORIGINALS = 'originals'

export function pluralizeObjectTypeName(name: string): string {
  if (name === TYPE_BOOKSHELF) {
    return 'shelves'
  }

  if (name === TYPE_MIXED || name === TYPE_SERIES) {
    return name
  }

  return `${name}s`
}

export function safePluralizeResourceType(name: string): string {
  if (name[name.length - 1] === 's') {
    return name
  }
  return `${name}s`
}

export function getRootShowcaseId({
  contentType,
  language,
}: {
  contentType: ResourcesType
  language: Locale
}): string {
  return `${contentType}-${language}`
}

export function getShowcaseTag({ language, slug, contentType }) {
  return `${language}-${slug}-${contentType}`
}

export function getSectionId(sectionSlug, showcaseTag) {
  return `${sectionSlug}-${showcaseTag}`
}

export function getResourceFromPathname(pathname: string): string {
  return pathname?.split('/')[1]
}

export function getTopicInfo(topic, resource) {
  const info = topic.match(/(\S*)-(\S{2})/)
  const contentType =
    resource === TYPE_LIBRARY
      ? TYPE_MIXED
      : resource.slice(0, resource.length - 1)

  if (!info || !availableTypes.includes(contentType)) return null

  return { slug: info[1], language: info[2], contentType }
}

export function areLanguagesMatched(state, lang) {
  return env.isServer() && state.currentUser.data.library_lang !== lang
}

export function getShowcaseResourcesType(
  contentType: ShowcaseResource,
): string {
  return contentType === TYPE_MIXED ? TYPE_LIBRARY : `${contentType}s`
}

export function getShowcaseLink(
  contentType: ShowcaseResource,
  slug?: string,
  query?: QueryParams,
): string {
  if (slug === ORIGINALS) {
    return addParamsToPath(`/${ORIGINALS}`, query || {})
  }
  return contentType === TYPE_MIXED
    ? urlFor.library(query)
    : addParamsToPath(`/${contentType}s`, query || {})
}

export function getCurrentShowcase(rootNavigation, contentType) {
  return (
    find(
      rootNavigation,
      item =>
        item.params.content_type === contentType &&
        item.params.slug !== ORIGINALS,
    ) || null
  )
}

export function getContentTypeByResource(resource) {
  return CONTENT_TYPE[resource] || TYPE_MIXED
}

export const getTitleFromHierarchy = hierarchy =>
  hierarchy &&
  hierarchy.reduce(
    (title, item) => (title ? `${title} | ${item.title}` : item.title),
    '',
  )

// client-side Rollbar reporter
import env from 'env'

class ErrorsHelper {
  initialized = false
  rollbar = null

  constructor() {
    if (env.isClient()) this.initializeRollbar()
  }

  initializeRollbar() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Rollbar = require('rollbar')

    this.rollbar = new Rollbar({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      accessToken: ROLLBAR_TOKEN_CLIENT,
      verbose: true,
      sendConfig: false,
      captureUncaught: env.isProduction() || env.isStaging(),
      captureUnhandledRejections: env.isProduction() || env.isStaging(),
      enabled: env.isProduction() || env.isStaging(),
      environment: env.isProduction()
        ? 'production'
        : env.isStaging()
        ? 'staging'
        : 'development',
      hostSafeList: ['bmstatic.com', 'bookmate.com'],
      autoInstrument: {
        network: false,
        log: false,
        dom: true,
        navigation: true,
        connectivity: false,
        contentSecurityPolicy: false,
        errorOnContentSecurityPolicy: false,
      },
      payload: {
        client: {
          javascript: {
            source_map_enabled: true,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            code_version: GIT_COMMIT,
            guess_uncaught_frames: true,
          },
        },
      },
    })

    this.initialized = true
  }

  setContext(userData) {
    if (this.initialized) {
      this.rollbar?.configure({
        payload: userData,
      })
    }
  }

  report(error: any) {
    if (typeof error !== 'string' && !Boolean(error instanceof Error)) return

    if (!env.isProduction()) {
      return console.error(error)
    }

    if (this.initialized) {
      this.rollbar?.error(error)
    }
  }
}

export default new ErrorsHelper()

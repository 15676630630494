import { schema, denormalize } from 'normalizr'
import merge from 'lodash/merge'
import compact from 'lodash/compact'

export const authorSchema = new schema.Entity(
  'authors',
  {},
  { idAttribute: 'uuid' },
)
const authorsSchema = new schema.Array(authorSchema)

export function getAuthorById(state, id) {
  return denormalize(id, authorSchema, state.entities)
}

export function getAuthorsByIds(state, ids) {
  return compact(denormalize(ids, authorsSchema, state.entities))
}

const initialState = {}

export default function authors(state = initialState, action) {
  if (action.entities && action.entities.authors) {
    return merge({}, state, action.entities.authors)
  }

  return state
}

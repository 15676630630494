import { denormalize } from 'normalizr'

import {
  usersSchema,
  seriesSchema,
  seriesListSchema,
  resourceSchemaByType,
} from 'client/bookmate/reducers/schemas/schemas'

import { State } from 'shared/types/redux'
import { ResourceName } from 'client/shared/types/resource'

export function getSeries(state: State) {
  const { uuid } = state.series

  return denormalize(uuid, seriesSchema, state.entities) || {}
}

export function getParts(state: State) {
  const { parts } = state.series

  return parts.map(part => ({
    ...part,
    resource:
      denormalize(
        part.resource.uuid,
        resourceSchemaByType[part.resource_type],
        state.entities,
      ) || {},
  }))
}

export function getFollowers(state: State) {
  return denormalize(state.series.followers, usersSchema, state.entities)
}

export function getPartsPage(state: State) {
  return state.series.pages.parts
}

export function getFollowersPage(state: State) {
  return state.series.pages.followers
}

export function isLoading(state: State) {
  return state.series.loading
}

export function getSeriesByIds(state: State, ids: string[]) {
  return denormalize(ids, seriesListSchema, state.entities)
}

export function getSeriesById(state: State, id: string[]) {
  return denormalize(id, seriesSchema, state.entities) || {}
}

export function getOtherEpisodes(state: State, resourceType: ResourceName) {
  const { otherEpisodes } = state[resourceType]

  return otherEpisodes.map(episode => ({
    ...episode,
    resource:
      denormalize(
        episode.resource.uuid,
        resourceSchemaByType[episode.resource_type],
        state.entities,
      ) || {},
  }))
}

import {
  ApiAction,
  CALL_API,
  CALL_API_ERROR,
} from 'shared/middlewares/api-middleware'
import merge from 'lodash/merge'
import get from 'lodash/get'
import pick from 'lodash/pick'
import cookie from 'react-cookie'

import fetchWrapper from 'shared/tools/fetch-wrapper'

import {
  loadInfo as loadUserInfo,
  requestPhoneRegistrationCode,
  USER_ID,
} from 'client/shared/reducers/current-user-reducer'
import {
  analyticsEvent,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_INITIATED,
  AUTHENTICATION_SUCCESSFUL,
  REGISTRATION_SUCCESSFUL,
  RESET_PASSWORD_REQUESTED,
  TOP_ADVERT_SIGN_UP,
  VERIFICATION_CODE_REQUESTED,
} from 'client/shared/reducers/analytics-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import { cleanShowcase } from 'client/bookmate/reducers/showcase-reducer'
import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'
import { clearTokens } from 'client/shared/reducers/app-reducer'

import { constants } from 'client/bookmate/constants'
import routingHelper from 'client/shared/helpers/routing-helpers'
import {
  Dispatch,
  GenericDispatchedEvent,
  GetState,
  ThunkAction,
} from 'shared/types/redux'
import { CurrentUserData } from '../types/current-user'
import env from 'env'
import { addParamsToPath, getDomain } from 'shared/tools/url-helper'
import { push } from 'react-router-redux'
import subscriptionHelper from '../helpers/subscription-helper'
import { MTN_AREA_CODE } from 'client/bookmate/blocks/mtn/phone-input'

const AUTH_FORM_LOCK_SUBMIT = constants.AUTH_FORM_LOCK_SUBMIT
const AUTH_FORM_UNLOCK_SUBMIT = constants.AUTH_FORM_UNLOCK_SUBMIT
const AUTH_FORM_CHANGE_STEP = constants.AUTH_FORM_CHANGE_STEP
const AUTH_FORM_CHANGE_FLOW = constants.AUTH_FORM_CHANGE_FLOW
const AUTH_FORM_CHANGE_PHONE = constants.AUTH_FORM_CHANGE_PHONE
const AUTH_FORM_ERROR = constants.AUTH_FORM_ERROR
const AUTH_FORM_LOADING = constants.AUTH_FORM_LOADING
export const AUTH_FORM_LOGIN_SUCCESS = constants.AUTH_FORM_LOGIN_SUCCESS
const AUTH_FORM_RESET_CREDENTIAL = constants.AUTH_FORM_RESET_CREDENTIAL
const AUTH_FORM_RESET_PASSWORD = constants.AUTH_FORM_RESET_PASSWORD
const AUTH_FORM_SYNC_VALUES = constants.AUTH_FORM_SYNC_VALUES
const AUTH_FORM_CLEAR = constants.AUTH_FORM_CLEAR
const MARK_YETTEL = 'MARK_YETTEL'
const MARK_YETTEL_END = 'MARK_YETTEL_END'
const SOCIAL_AUTH_INIT = constants.SOCIAL_AUTH_INIT
export const SOCIAL_AUTH_SUCCESS = 'SOCIAL_AUTH_SUCCESS'

const AUTH_PASSWORD_RESET = constants.AUTH_PASSWORD_RESET
const AUTH_CHECK_IF_REGISTERED = constants.AUTH_CHECK_IF_REGISTERED
const AUTH_CHECK_IF_REGISTERED_SUCCESS =
  constants.AUTH_CHECK_IF_REGISTERED_SUCCESS
const AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK =
  constants.AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK
const AUTH_UPDATE_PRE_SIGNUP_FIELDS = constants.AUTH_UPDATE_PRE_SIGNUP_FIELDS

const MONDIA_STATISTIC_LOADING = 'MONDIA_STATISTIC_LOADING'
const MONDIA_STATISTIC_SUCCESS = 'MONDIA_STATISTIC_LOADING_SUCCESS'
const MONDIA_STATISTIC_ERROR = 'MONDIA_STATISTIC_LOADING_ERROR'

const SIGN_IN_WITH_TOKEN_START = 'SIGN_IN_WITH_TOKEN_START'
const SIGN_IN_WITH_TOKEN_SUCCESS = 'SIGN_IN_WITH_TOKEN_SUCCESS'
const SIGN_IN_WITH_TOKEN_ERROR = 'SIGN_IN_WITH_TOKEN_ERROR'

export type AuthStep =
  | 'email'
  | 'social'
  | 'phone'
  | 'socialAuthenticated'
  | 'reset'
  | 'codeSent'
  | 'linkSent'
  | 'passwordReset'

export type authAdditionFlowVariants = 'mondiaPay'

export type authAdditionFlow = Record<authAdditionFlowVariants, string>

type AuthFlow = 'login' | 'signup'

type Oauth1TokenData = {
  oauth_token: string
  oauth_secret: string
}
type AuthFormChangePhoneAction = {
  type: typeof AUTH_FORM_CHANGE_PHONE
  phone: string
}
type Oauth2TokenData = { auth_token: string } | { access_token: string }

export type SocialNetwork =
  | 'facebook'
  | 'twitter'
  | 'google'
  | 'telenor_bulgaria'

// the type below describes data returned from our social_auth/send endpoint
// and passed via postMessage to appWrapper (see the handleMessage method in app-wrapper)
type SocialAuthPayload = {
  providerTokenData: Oauth1TokenData | Oauth2TokenData
  provider: SocialNetwork
  rawOauthResponse: {
    email?: string
  }
  oauth_name?: string
}

// the type below describes data that we receive from the Bookmate api,
// combine with our data and store in state
export type SocialAuthData = SocialAuthPayload & {
  name: string
  userpic: string
  email_provided: boolean
}

export type PreSignupFields = {
  acceptTermsOfService: boolean
  isOfLegalAge: boolean
  agreeToReceiveEmails: boolean
}

type AuthFormLockSubmitAction = {
  type: 'AUTH_FORM_LOCK_SUBMIT'
}

type AuthFormUnlockSubmitAction = {
  type: 'AUTH_FORM_UNLOCK_SUBMIT'
}

type MarkYettelAction = {
  type: typeof MARK_YETTEL
}

type MarkYettelEndAction = {
  type: typeof MARK_YETTEL_END
}

type AuthFormChangeStepAction = {
  type: 'AUTH_FORM_CHANGE_STEP'
  step: AuthStep
}

type AuthChangeFlowAction = {
  type: typeof AUTH_FORM_CHANGE_FLOW
  flow: AuthFlow
}

type AuthFormLoadingAction = {
  type: 'AUTH_FORM_LOADING'
}

type AuthFormLoginSuccessAction = {
  type: 'AUTH_FORM_LOGIN_SUCCESS'
  data: CurrentUserData
}

type AuthFormErrorAction = {
  type: 'AUTH_FORM_ERROR'
  field: string
  error: {
    message: string
    type?: string // relevant for social auth
  }
}

type AuthFormClearAction = {
  type: typeof AUTH_FORM_CLEAR
}

type AuthPasswordResetAction = {
  type: 'AUTH_PASSWORD_RESET'
  ui: {
    loading: boolean
    success?: boolean
    error?: string
  }
}

type SetNewPasswordPayload = {
  user: {
    new_password: string
  }
  token: string
}

type AuthCheckIfRegisteredAction = {
  type: 'AUTH_CHECK_IF_REGISTERED'
}

type AuthCheckIfRegisteredSuccessAction = {
  type: 'AUTH_CHECK_IF_REGISTERED_SUCCESS'
  registered: boolean
  userpic: string
}

type AuthFormResetCredentialAction = {
  type: 'AUTH_FORM_RESET_CREDENTIAL'
}

type AuthSyncValuesAction = {
  type: typeof AUTH_FORM_SYNC_VALUES
  payload: {
    [key: string]: string
  }
}

type AcceptDataFromSocialNetworkAction = {
  type: typeof AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK
  payload: SocialAuthData
  registered: boolean
}

type UpdatePreSignupFieldsAction = {
  type: typeof AUTH_UPDATE_PRE_SIGNUP_FIELDS
  payload: Partial<PreSignupFields>
}

type ApiErrorAction = {
  type: typeof CALL_API_ERROR
}

type signInWithTokenStartAction = {
  type: 'SIGN_IN_WITH_TOKEN_START'
}

type signInWithTokenSuccessAction = {
  type: 'SIGN_IN_WITH_TOKEN_START_SUCCESS'
}

type signInWithTokenErrorAction = {
  type: 'SIGN_IN_WITH_TOKEN_START_ERROR'
}

type Action =
  | AuthFormLockSubmitAction
  | AuthFormUnlockSubmitAction
  | AuthFormChangeStepAction
  | AuthChangeFlowAction
  | AuthFormLoadingAction
  | AuthFormLoginSuccessAction
  | AuthFormClearAction
  | AuthFormErrorAction
  | AuthPasswordResetAction
  | AuthCheckIfRegisteredAction
  | AuthCheckIfRegisteredSuccessAction
  | AuthFormResetCredentialAction
  | AuthSyncValuesAction
  | AcceptDataFromSocialNetworkAction
  | UpdatePreSignupFieldsAction
  | ApiErrorAction
  | AuthFormChangePhoneAction
  | signInWithTokenStartAction
  | signInWithTokenSuccessAction
  | signInWithTokenErrorAction
  | MarkYettelAction
  | MarkYettelEndAction

export type State = {
  ui: {
    checkingIfRegistered: boolean
    currentFormLockSubmit: boolean
  }
  loading: boolean
  currentStep: AuthStep | null | undefined
  flow: AuthFlow
  error: string | null | undefined
  userIsRegistered: boolean
  userRegistrationChecked: boolean
  userpic: string
  email: string
  phone: string
  smsCode: string
  password: string
  countryCode: string
  socialAuth: SocialAuthData | null | undefined
  preSignupFields: PreSignupFields
  signInWithToken?: { loading: boolean }
  yettel?: { authInit: boolean }
}

export function lockSubmit(): { type: string } {
  return {
    type: AUTH_FORM_LOCK_SUBMIT,
  }
}

export function unlockSubmit(): { type: string } {
  return {
    type: AUTH_FORM_UNLOCK_SUBMIT,
  }
}

export function markYettel(): { type: string } {
  return {
    type: MARK_YETTEL,
  }
}

export function markYettelEnd(): { type: string } {
  return {
    type: MARK_YETTEL_END,
  }
}

export function changeStep(
  step: AuthStep,
  sendAnalytics?: boolean,
): ThunkAction {
  return async dispatch => {
    dispatch({
      type: AUTH_FORM_CHANGE_STEP,
      step,
    })

    if (sendAnalytics) {
      dispatch(analyticsEvent(AUTHENTICATION_INITIATED, { source: step }))
    }
  }
}

function changeFlow(flow: AuthFlow) {
  return {
    type: AUTH_FORM_CHANGE_FLOW,
    flow,
  }
}

export function signInWithToken(signInToken: string, accessToken: string) {
  return async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    const correspondingTokenPayload = signInToken
      ? { token_type: 'sign_in_token', token_value: signInToken }
      : { token_type: 'access_token', token_value: accessToken }

    const payload = {
      user: correspondingTokenPayload,
    }

    const endpoint = '/p/api/v5/sign_in/token'
    await dispatch(clearTokens(getState().app.url))

    return await dispatch(authenticate(payload, endpoint, 'token'))
  }
}

export function markSignInWithTokenStart(): { type: string } {
  return {
    type: SIGN_IN_WITH_TOKEN_START,
  }
}

function markSignInWithTokenSuccess() {
  return {
    type: SIGN_IN_WITH_TOKEN_SUCCESS,
  }
}

function markSignInWithTokenError() {
  return {
    type: SIGN_IN_WITH_TOKEN_ERROR,
  }
}

export function handleAuthenticationStep(
  useRecaptcha: boolean,
  recaptchaToken?: string,
  additionalAuthFlow?: authAdditionFlow,
) {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const {
      auth: { currentStep },
    } = getState()

    if (
      ['email', 'codeSent', 'socialAuthenticated'].includes(
        currentStep as string,
      )
    ) {
      dispatch(
        handleFinalAuthenticationStep(
          currentStep === 'email' && useRecaptcha,
          recaptchaToken,
          additionalAuthFlow,
        ),
      )
    } else if (currentStep === 'phone') {
      dispatch(handleIntermediateAuthenticationStep())
    } else if (currentStep === 'reset') {
      dispatch(resetPassword())
    }
  }
}

function handleFinalAuthenticationStep(
  useRecaptcha: boolean,
  recaptchaToken?: string,
  additionalAuthFlow?: authAdditionFlow,
) {
  return async (dispatch: Dispatch, getState: GetState) => {
    // before proceeding further, we need to be sure whether the user is already registered;
    // so if we have not yet checked the user's credentials, we must first do so
    let state = getState()
    if (!state.auth.userRegistrationChecked) {
      const checkPayload = preparePayloadToCheckIfUserIsRegistered(state.auth)
      if (!checkPayload) return
      await checkIfUserIsRegistered(checkPayload)
    }

    state = getState() // because state could have updated in the if-block above

    const { auth: authState } = state
    const { flow, currentStep } = authState

    if (currentStep === 'email') {
      let payload: {
        user: {
          credential?: string
          password: string
          email?: string
          registration_token?: string
        }
      } = {
        user: {
          credential: authState.email,
          password: authState.password,
        },
      }

      if (flow === 'signup') {
        payload = {
          user: {
            email: authState.email,
            password: authState.password,
          },
        }
        if (additionalAuthFlow && 'mondiaPay' in additionalAuthFlow) {
          payload.user.registration_token = additionalAuthFlow.mondiaPay
        }
      }
      if (useRecaptcha) {
        payload['g-recaptcha-response'] = recaptchaToken
      }
      const endpoint = getAuthenticationEndpoint(
        flow,
        currentStep,
        additionalAuthFlow,
      )
      return await dispatch(authenticate(payload, endpoint, 'email'))
    } else if (currentStep === 'codeSent') {
      const payload = {
        user: {
          phone_number: authState.phone.startsWith(authState.countryCode)
            ? authState.phone
            : `${authState.countryCode}${authState.phone}`,
          confirmation_code: authState.smsCode,
        },
      }
      if (useRecaptcha) {
        payload['g-recaptcha-response'] = recaptchaToken
      }
      const endpoint = getAuthenticationEndpoint(flow, currentStep)
      return await dispatch(authenticate(payload, endpoint, 'phone'))
    } else if (
      currentStep === 'social' ||
      currentStep === 'socialAuthenticated'
    ) {
      const payload: {
        user: {
          [key: string]: unknown
        }
      } = {
        user: prepareSocialAuthCredentials(authState.socialAuth),
      }
      const email = get(authState, 'socialAuth.rawOauthResponse.email')
      if (email) {
        payload.user.oauth_email = email
      }

      const endpoint = getAuthenticationEndpoint(flow, currentStep)
      return await dispatch(
        authenticate(payload, endpoint, authState.socialAuth.provider),
      )
    }
  }
}

function handleIntermediateAuthenticationStep() {
  return async (dispatch: Dispatch, getState: GetState) => {
    const { auth: authState } = getState()

    if (authState.currentStep === 'phone') {
      await dispatch(
        checkIfUserIsRegistered({
          credential: `${authState.countryCode}${authState.phone}`,
          channel: 'phone',
        }),
      )
      const { phone, countryCode } = authState
      const fullPhoneNumber = countryCode + phone
      await dispatch(
        countryCode === MTN_AREA_CODE
          ? sendCode(countryCode, phone)
          : requestPhoneRegistrationCode({ phone_number: fullPhoneNumber }),
      )
    }
  }
}

export function syncAuthValues(payload: {
  [key: string]: string
}): AuthSyncValuesAction {
  return {
    type: AUTH_FORM_SYNC_VALUES,
    payload,
  }
}

function trackSignup() {
  return (dispatch: Dispatch) => {
    dispatch(analyticsEvent(REGISTRATION_SUCCESSFUL, {}))
  }
}

function trackTopAdvert(isSignup, dispatch: Dispatch) {
  const topAdvertPin = cookie.load('topAdvertPin')

  if (isSignup && topAdvertPin) {
    dispatch(
      analyticsEvent(TOP_ADVERT_SIGN_UP, {
        pin: topAdvertPin,
      }),
    )
  }

  cookie.remove('topAdvertPin')
}

function authenticate(
  payload: { user: Record<string, unknown> },
  endpoint: string,
  source: string,
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      auth: authState,
      app: { fingerprint },
    } = getState()
    let { flow } = authState
    if (source === 'token') flow = 'login'

    const context = flow === 'signup' ? 'registration' : 'login'

    if (flow === 'signup') {
      // add values of checkboxes that the user selected during signup
      payload = addSignupConfirmations(payload, authState)
    }

    dispatch({
      [CALL_API]: {
        endpoint,
        options: {
          method: 'post',
          data: payload,
          fingerprint,
        },
        modifyResponse: res => ({ data: res.user }),
        onSuccess: _dispatch => {
          trackTopAdvert(flow === 'signup', _dispatch)

          if (flow === 'signup') {
            _dispatch(trackSignup())
          }
          _dispatch(
            analyticsEvent(AUTHENTICATION_SUCCESSFUL, {
              source: normalizeAuthSourceForAnalytics(source),
              context,
              custom: true,
            }),
          )
          _dispatch(clearAuthForm())
          _dispatch(cleanShowcase())
          _dispatch(markSignInWithTokenSuccess())
          const { currentUser, app, mtn, subscription } = getState()
          if (mtn?.phone) {
            const hasSubscription = subscriptionHelper.hasActiveSubscriptions(
              subscription.userAccessLevels,
            )
            let path = '/mtn/entered/'
            if (hasSubscription) {
              path += 'success'
            } else {
              path += 'tariffs'
              if (app.storedQuery && Object.keys(app.storedQuery).length > 0)
                path = addParamsToPath(path, app.storedQuery)
            }
            _dispatch(push(path))
          }
          const userId = (currentUser.data.id as unknown) as string
          const options = {
            domain: env.isProduction() ? `.${getDomain(app.host)}` : undefined,
            path: '/',
          }
          cookie.save(USER_ID, userId, options)
        },
        onError: (_dispatch, _getState, response) => {
          const { error } = response
          const analyticsPayload: {
            [key: string]: string
          } = {
            source: normalizeAuthSourceForAnalytics(source),
            context,
          }

          if (error && error.message) {
            analyticsPayload.reason = error.message
          }
          _dispatch(analyticsEvent(AUTHENTICATION_FAILED, analyticsPayload))
          _dispatch(markSignInWithTokenError())
        },
        types: [AUTH_FORM_LOADING, AUTH_FORM_LOGIN_SUCCESS, AUTH_FORM_ERROR],
      },
    })
  }
}

function getAuthenticationEndpoint(
  flow: AuthFlow,
  step: string,
  additionFlow?: authAdditionFlow,
) {
  const namespace = flow === 'signup' ? 'sign_up' : 'sign_in'

  if (
    flow === 'signup' &&
    step === 'email' &&
    additionFlow &&
    'mondiaPay' in additionFlow
  ) {
    return `/p/api/v5/${namespace}/mondia_pays`
  }

  switch (step) {
    case 'email':
      return `/p/api/v5/${namespace}/password`
    case 'codeSent':
      return `/p/api/v5/${namespace}/code`
    case 'social':
    case 'socialAuthenticated':
      return `/p/api/v5/${namespace}/oauth`
    default:
      return ''
    // should not happen, but makes Flow happy
  }
}

export function sendCode(code: string, phone: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/mtn_ghana/send_code',
      options: {
        method: 'post',
        data: {
          phone_number: code + phone,
        },
      },
      onSuccess: dispatch => {
        dispatch(analyticsEvent(VERIFICATION_CODE_REQUESTED))
        dispatch(changeStep('codeSent'))
      },
      types: [AUTH_FORM_LOADING, AUTH_FORM_CHANGE_PHONE, AUTH_FORM_ERROR],
    },
    phone,
  }
}

export function acceptSocialAuthData(payload: SocialAuthPayload) {
  return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
    const state = getState()

    if (state.currentUser.auth) {
      if (payload.mobileState?.isConnecting) dispatch(addSocialNetwork(payload))

      // else just extraneous request
      return
    }

    const credentials = prepareSocialAuthCredentials(payload)

    const registeredCheckResponse = await fetchWrapper.fetch(
      '/p/api/v5/sign_in/oauth/registered',
      {
        data: credentials,
      },
    )

    if (registeredCheckResponse.registered) {
      dispatch(changeFlow('login'))
      dispatch(
        authenticate(
          { user: credentials },
          '/p/api/v5/sign_in/oauth',
          payload.provider,
        ),
      )
    } else {
      if (
        state.popup.authHidden &&
        routingHelper.getPathname(state) !== '/login'
      ) {
        dispatch(showAuthPopup())
      }

      const name =
        payload.provider === 'telenor_bulgaria' && !registeredCheckResponse.name
          ? 'telenor'
          : registeredCheckResponse.name || payload.provider

      dispatch({
        type: AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK,
        registered: false,
        payload: {
          ...pick(payload, [
            'providerTokenData',
            'provider',
            'rawOauthResponse',
          ]),
          name,
          userpic: registeredCheckResponse.userpic,
          email_provided: registeredCheckResponse.email_provided,
        },
      })
      dispatch(changeStep('socialAuthenticated'))
    }
  }
}

function addSocialNetwork(payload: SocialAuthPayload) {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/profile/authentication_providers',
      options: {
        method: 'post',
        data: { ...prepareSocialAuthCredentials(payload) },
        dontParse: true, // the response is an empty body
      },
      onSuccess: dispatch => {
        dispatch(loadUserInfo())
        dispatch(
          analyticsEvent(AUTHENTICATION_SUCCESSFUL, {
            source: normalizeAuthSourceForAnalytics(payload.provider),
            context: 'friend_finder',
            custom: true,
          }),
        )
      },
      onError: (dispatch, getState, response) => {
        const errorMessage = get(response, 'error.message')

        const analyticsPayload: {
          [key: string]: string
        } = {
          source: normalizeAuthSourceForAnalytics(payload.provider),
          context: 'friend_finder',
        }

        if (errorMessage) {
          analyticsPayload.reason = errorMessage
          dispatch(showAlert('error', { message: errorMessage }))
        }
        dispatch(analyticsEvent(AUTHENTICATION_FAILED, analyticsPayload))
      },
      types: [AUTH_FORM_LOADING, AUTH_FORM_LOGIN_SUCCESS, AUTH_FORM_ERROR],
    },
  }
}

export function resetPassword(email?: string) {
  return (dispatch: Dispatch, getState: GetState): void => {
    if (!email) {
      email = getState().auth.email
    }

    const payload = {
      user: { credential: email },
    }

    dispatch({
      [CALL_API]: {
        endpoint: '/p/api/v5/sign_in/reset_password',
        options: {
          method: 'post',
          data: payload,
        },
        onSuccess: _dispatch => {
          _dispatch(analyticsEvent(RESET_PASSWORD_REQUESTED))
          _dispatch(changeStep('linkSent'))
        },
        types: [AUTH_FORM_LOADING, AUTH_FORM_RESET_PASSWORD, AUTH_FORM_ERROR],
      },
    })
  }
}

export function setNewPassword(payload: SetNewPasswordPayload): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/a/4/u/change_password',
      options: {
        method: 'post',
        data: payload,
      },
      modifyResponse: () => ({
        ui: {
          loading: false,
          success: true,
        },
      }),
      onSuccess: dispatch => dispatch(changeStep('passwordReset')),
      types: [AUTH_FORM_LOADING, AUTH_PASSWORD_RESET],
    },
  }
}

export function initSocialAuth(provider: string) {
  return {
    type: SOCIAL_AUTH_INIT,
    meta: {
      analytics: {
        type: 'Authentication Initiated',
        payload: {
          source: provider,
        },
      },
    },
  }
}

export function clearAuthForm() {
  return {
    type: AUTH_FORM_CLEAR,
  }
}

function preparePayloadToCheckIfUserIsRegistered(
  authState,
): { credential: string; channel: ChannelType } | undefined {
  const { currentStep } = authState

  if (currentStep === 'email') {
    return { credential: authState.email, channel: 'email' }
  } else if (currentStep === 'codeSent') {
    return { credential: authState.phone, channel: 'phone' }
  }
}

type ChannelType = 'email' | 'phone'

export function checkIfUserIsRegistered(
  {
    credential,
    channel,
  }: {
    credential: string
    channel: ChannelType
  },
  recaptchaToken?: string,
): ApiAction {
  const endpoint =
    channel === 'email'
      ? '/p/api/v5/sign_in/credentials/registered'
      : '/p/api/v5/sign_in/phones/registered'

  let data: Record<string, any> = { credential }

  if (channel === 'email') {
    data = {
      credential,
      'g-recaptcha-response': recaptchaToken,
    }
  }

  return {
    [CALL_API]: {
      endpoint,
      options: {
        data,
      },
      types: [
        AUTH_CHECK_IF_REGISTERED,
        AUTH_CHECK_IF_REGISTERED_SUCCESS,
        AUTH_FORM_ERROR,
      ],
    },
  }
}

export function sendMondiaStatistic(url: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/partners/mondia_pay/onboarding_initial',
      options: {
        method: 'post',
        data: {
          url,
        },
      },
      types: [
        MONDIA_STATISTIC_LOADING,
        MONDIA_STATISTIC_SUCCESS,
        MONDIA_STATISTIC_ERROR,
      ],
      ignoreError: true,
    },
  }
}

export function resetCredential(): GenericDispatchedEvent {
  return { type: AUTH_FORM_RESET_CREDENTIAL }
}

export function updatePreSignupFields(
  payload: Partial<PreSignupFields>,
): GenericDispatchedEvent & { payload: Partial<PreSignupFields> } {
  return {
    type: AUTH_UPDATE_PRE_SIGNUP_FIELDS,
    payload,
  }
}

function prepareSocialAuthCredentials({
  provider,
  providerTokenData,
  oauth_name,
}: SocialAuthPayload) {
  return oauth_name
    ? {
        provider,
        provider_token_data: providerTokenData,
        oauth_name,
        account: 'global',
      }
    : {
        provider,
        provider_token_data: providerTokenData,
        account: 'global',
      }
}

function addSignupConfirmations(
  payload: Record<string, unknown>,
  authState: State,
) {
  return {
    ...payload,
    confirmations: {
      confirmation_terms_of_service:
        authState.preSignupFields.acceptTermsOfService,
      confirmation_adult: authState.preSignupFields.isOfLegalAge,
      confirmation_marketing_newsletters:
        authState.preSignupFields.agreeToReceiveEmails,
    },
  }
}

function normalizeAuthSourceForAnalytics(source: string) {
  return source.toLowerCase()
}

function stepSpecificResets(step: AuthStep) {
  // if the user is switching to one of the initial steps,
  // reset flow to 'signup' and forget user registration check
  if (['email', 'phone', 'social'].includes(step)) {
    return {
      flow: 'signup',
      userIsRegistered: false,
      userRegistrationChecked: false,
    }
  }
}

function getErrorFromAction(state: State, action: AuthFormErrorAction) {
  const {
    error: { message, type },
  } = action
  const { currentStep } = state
  if (currentStep === 'socialAuthenticated' && type === 'email_already_taken') {
    // special case; will display custom error
    return `auth.${type}`
  } else {
    return message
  }
}

const initialState = {
  ui: {
    checkingIfRegistered: false,
    currentFormLockSubmit: true,
  },
  loading: false,
  currentStep: null,
  flow: 'signup' as AuthFlow,
  error: null,
  userIsRegistered: false,
  userRegistrationChecked: false,
  userpic: '',
  email: '',
  password: '',
  countryCode: '',
  socialAuth: null,
  phone: '',
  smsCode: '',
  preSignupFields: {
    acceptTermsOfService: false,
    isOfLegalAge: false,
    agreeToReceiveEmails: false,
  },
  signInWithToken: { loading: false },
}

export default function auth(state: State = initialState, action: Action) {
  switch (action.type) {
    case AUTH_FORM_LOCK_SUBMIT:
      return {
        ...state,
        ui: {
          ...state.ui,
          currentFormLockSubmit: true,
        },
      }

    case AUTH_FORM_UNLOCK_SUBMIT:
      return {
        ...state,
        ui: {
          ...state.ui,
          currentFormLockSubmit: false,
        },
      }

    case AUTH_FORM_CHANGE_FLOW:
      return {
        ...state,
        flow: action.flow,
      }

    case AUTH_FORM_CHANGE_STEP:
      return {
        ...state,
        ui: {
          ...state.ui,
          currentFormLockSubmit: true,
        },
        error: null,
        loading: false,
        currentStep: action.step,
        ...stepSpecificResets(action.step),
      }

    case AUTH_FORM_SYNC_VALUES:
      return {
        ...state,
        ...action.payload,
      }

    case AUTH_FORM_CHANGE_PHONE:
      return {
        ...state,
        phone: action.phone,
      }

    case AUTH_FORM_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      }

    case AUTH_FORM_LOGIN_SUCCESS:
      return {
        ...state,
        currentStep: null,
        loading: false,
      }

    case AUTH_FORM_CLEAR:
      return initialState

    case AUTH_FORM_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          checkingIfRegistered: false,
        },
        loading: false,
        error: getErrorFromAction(state, action),
      }

    case CALL_API_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          checkingIfRegistered: false,
        },
        loading: false,
      }

    case MARK_YETTEL:
      return {
        ...state,
        yettel: { authInit: true },
      }

    case MARK_YETTEL_END:
      return {
        ...state,
        yettel: { authInit: false },
      }

    case AUTH_PASSWORD_RESET:
      return {
        ...state,
        ui: merge({}, state.ui, action.ui),
      }

    case AUTH_CHECK_IF_REGISTERED:
      return {
        ...state,
        ui: {
          ...state.ui,
          checkingIfRegistered: true,
        },
      }

    case AUTH_CHECK_IF_REGISTERED_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          checkingIfRegistered: false,
        },
        userIsRegistered: action.registered,
        userRegistrationChecked: true,
        flow: action.registered ? 'login' : 'signup',
        userpic: action.userpic,
      }

    case AUTH_FORM_RESET_CREDENTIAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          checkingIfRegistered: false,
        },
        userpic: '',
        userIsRegistered: false,
        userRegistrationChecked: false,
      }

    case AUTH_ACCEPT_DATA_FROM_SOCIAL_NETWORK:
      return {
        ...state,
        socialAuth: action.payload,
        userRegistrationChecked: true,
        registered: action.registered,
      }

    case AUTH_UPDATE_PRE_SIGNUP_FIELDS:
      return {
        ...state,
        preSignupFields: {
          ...state.preSignupFields,
          ...action.payload,
        },
      }

    case SIGN_IN_WITH_TOKEN_START:
      return {
        ...state,
        signInWithToken: { loading: true },
      }

    case SIGN_IN_WITH_TOKEN_SUCCESS:
    case SIGN_IN_WITH_TOKEN_ERROR:
      return {
        ...state,
        signInWithToken: { loading: false },
      }

    default:
      return state
  }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'lodash/fp/compose'

import urlHelper, { removeTrailingSlash } from 'shared/tools/url-helper'
import withAuth, { AuthProps } from 'client/shared/decorators/with-auth'
import * as authActions from 'client/shared/reducers/auth-reducer'
import Auth from 'client/shared/blocks/auth'

import { CurrentUserData } from 'client/shared/types/current-user'
import { Router } from 'client/shared/types/react-router'
import { State as ConnectedState } from 'shared/types/redux'
import { MTNState } from 'client/bookmate/reducers/mtn-reducer'
import { requestPhoneRegistrationCode } from '../reducers/current-user-reducer'

type Props = {
  data: CurrentUserData
  giftCode: string
  location: Location
  mtn: MTNState
  yettel?: { authInit: boolean }
} & AuthProps &
  Router

type State = {
  timerRunning: boolean
}

class AuthBox extends Component<Props, State> {
  state = {
    timerRunning: true,
  }
  componentWillUnmount() {
    this.props.dispatch(authActions.clearAuthForm())
  }

  render() {
    const {
      auth: { currentStep },
      location,
      giftCode,
      mtn,
      yettel,
    } = this.props

    if (!currentStep) return null

    return (
      <Auth
        yettel={yettel?.authInit}
        mtn={mtn}
        timerRunning={this.state.timerRunning}
        stopTimer={this.stopTimer}
        resendCode={this.resendCode}
        {...this.props}
        changeStep={this.changeStep}
        currentStep={currentStep}
        {...(location.pathname ===
        removeTrailingSlash(urlHelper.codePage(undefined, location.query))
          ? { specialParams: { giftCode } }
          : {})}
      />
    )
  }

  resendCode = () => {
    const { dispatch, auth, mtn } = this.props
    if (mtn?.authInit) dispatch(authActions.sendCode(auth.phone))
    else
      dispatch(
        requestPhoneRegistrationCode({
          phone_number: `${auth.countryCode}${auth.phone}`,
        }),
      )
    this.setState({ timerRunning: true })
  }
  stopTimer = (): void => this.setState({ timerRunning: false })

  changeStep = (step, sendAnalytics) => {
    this.props.dispatch(authActions.changeStep(step, sendAnalytics))
  }
}

const connectWrapper = connect((state: ConnectedState) => ({
  data: state.currentUser.data,
  giftCode: state.code.codeValue,
  mtn: state.mtn,
  yettel: state.auth.yettel,
}))

const wrappers = compose(
  connectWrapper,
  withAuth({ initialStep: 'social' }),
  withRouter,
)

export default wrappers(AuthBox)

import React, { PureComponent } from 'react'

import loadable from '@loadable/component'
import Spacer from 'client/shared/blocks/spacer'

const NavBox = loadable(() => import('client/shared/boxes/nav-box'), {
  fallback: null,
})

const GetTheAppPage = loadable(
  () => import('client/bookmate/pages/get-the-app-page'),
  {
    fallback: null,
  },
)

const SubtopicsAll = loadable(
  () => import('client/bookmate/blocks/subtopics-all'),
  {
    fallback: null,
  },
)

const GiftAgreement = loadable(
  () => import('client/bookmate/blocks/gift-agreement'),
  {
    fallback: null,
  },
)

import './fake-page.styl'

export type Props = {
  page: string
  onClose?(): void
  params?: { theme: string }
  hidden?: boolean
}

const pages = {
  nav: NavBox,
  getTheApp: GetTheAppPage,
  subtopics: SubtopicsAll,
  terms: GiftAgreement,
}

export default class FakePage extends PureComponent<Props> {
  node: HTMLElement | null | undefined

  componentDidMount(): void {
    this.toggleOverflow(true)

    const { node } = this

    if (node) {
      node.focus()
      node.addEventListener('focusout', this.handleFocus)
    }
  }

  componentWillUnmount(): void {
    this.toggleOverflow(false)

    if (this.node) {
      this.node.removeEventListener('focusout', this.handleFocus)
    }
  }

  handleFocus = (evt: FocusEvent): void => {
    evt.stopPropagation()

    const { relatedTarget } = evt

    if (
      this.node &&
      relatedTarget instanceof Node &&
      !this.node.contains(relatedTarget)
    ) {
      this.node.focus()
    }
  }

  handleEscape = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Escape' || event.which === 27) {
      this.props.onClose()
    }
  }

  toggleOverflow(shouldAdd: boolean | null | undefined): void {
    if (!document.body) return

    if (shouldAdd) {
      document.body.classList.add('no-overflow')
    } else {
      document.body.classList.remove('no-overflow')
    }
  }

  render(): JSX.Element {
    const { page, onClose, params } = this.props
    const PageContent = pages[page]

    return (
      <div
        className="fake-page"
        onKeyDown={this.handleEscape}
        ref={node => (this.node = node)}
        tabIndex={-1}
      >
        <PageContent onClose={onClose} params={params} />

        <Spacer size="bottom-page" />
      </div>
    )
  }
}

import React from 'react'
import noop from 'lodash/noop'

import './slider-controls.styl'

type Props = {
  numberOfSlides: number
  handleClick: () => void
  activeSlide: number
}

export const Pager: React.FC<Props> = ({
  activeSlide,
  handleClick = noop,
  numberOfSlides,
}) => {
  const indexes = Array(numberOfSlides)
    .fill()
    .map((x, index) => index)

  return (
    <ul className="slider-controls__pager">
      {indexes.map(index => {
        return (
          <li className="slider-controls__pager-item-container" key={index}>
            <span
              className={
                index === activeSlide
                  ? 'slider-controls__pager-item slider-controls__pager-item_active'
                  : 'slider-controls__pager-item'
              }
              onClick={() => handleClick(index)}
            />
          </li>
        )
      })}
    </ul>
  )
}

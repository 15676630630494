import React, { PureComponent } from 'react'

import Meta from 'client/shared/blocks/meta'
import HeadTitle from 'client/shared/blocks/head-title'

import { addBMSuffixWhen } from 'client/shared/helpers/seo-helpers'

type Props = {
  disableSuffix: boolean
  hasOgSuffix: boolean
  og?: string | null | undefined
  title: string
  barSuffix: boolean
}

class MetaTitle extends PureComponent<Props> {
  static defaultProps = {
    hasOgSuffix: false,
    disableSuffix: false,
    barSuffix: false,
  }

  render() {
    const {
      title: rawTitle,
      disableSuffix,
      og,
      hasOgSuffix,
      barSuffix,
    } = this.props

    const title = addBMSuffixWhen(!disableSuffix, rawTitle, barSuffix)
    const ogTitle = og
      ? addBMSuffixWhen(!disableSuffix || hasOgSuffix, og, barSuffix)
      : title

    return (
      <>
        <HeadTitle disableSuffix>{title}</HeadTitle>
        <Meta content={ogTitle} property="twitter:title" />
        <Meta content={ogTitle} property="og:title" />
      </>
    )
  }
}

export default MetaTitle

import React, { Component } from 'react'
import SVGInline from 'react-svg-inline'
import noop from 'lodash/noop'

import classNames from 'classnames'

import Overlay from '../overlay'

import BackIcon from 'client/shared/icons/close.svg'

import './popup.styl'
import { markEndAuth } from 'client/bookmate/reducers/mtn-reducer'
import { Dispatch } from 'shared/types/redux'

const ANIMATION_DELAY = 200

type Props = {
  width?: number
  height?: number
  children: React.ReactNode
  className?: string
  withoutCloseIcon?: boolean
  onClose?: () => void
  onClick?: () => void
  mtn?: boolean
  yettel?: boolean
  dispatch?: Dispatch
}

type State = {
  closing: boolean
}

class Popup extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
    }
  }

  render(): JSX.Element {
    const {
      width,
      height,
      children,
      className,
      withoutCloseIcon,
      mtn,
      yettel,
    } = this.props

    const classes = classNames(
      {
        popup: true,
        popup_closing: this.state.closing,
        mtn_background: mtn,
        yettel_background: yettel,
      },
      className,
    )

    return (
      <Overlay onClick={this.close}>
        <div className={classes} style={{ width, height }}>
          {!withoutCloseIcon && (
            <SVGInline
              svg={BackIcon}
              className="popup__close"
              onClick={this.close}
            />
          )}
          {children}
        </div>
      </Overlay>
    )
  }

  close = (): void => {
    // eslint-disable-next-line no-empty-function
    const { onClose = noop, mtn, dispatch } = this.props
    if (mtn && dispatch) dispatch(markEndAuth())
    this.setState({ closing: true })
    setTimeout(onClose, ANIMATION_DELAY)
  }
}

export default Popup

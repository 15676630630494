import {
  bookSchema,
  audioBookSchema,
  comicbookSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import {
  load as loadBook,
  loadImpressions as loadBookImpressions,
  loadRelated as loadRelatedBook,
  loadQuotes as loadQuotesBook,
  loadReaders as loadReadersBook,
  loadShelves as loadShelvesBook,
  readInPrivate as readInPrivateBook,
  markAsRead as markAsReadBook,
  removeFromWishlist as removeFromWishlistBook,
  loadOtherEpisodes as loadBookOtherEpisodes,
} from 'client/bookmate/reducers/book-reducer'
import { createLibraryCard as createLibbraryCardBook } from 'client/bookmate/reducers/library-cards-reducer'
import {
  load as loadAudiobook,
  loadImpressions as loadAudiobookImpressions,
  loadOtherEpisodes as loadAudiobookOtherEpisodes,
} from 'client/bookmate/reducers/audiobook-reducer'
import {
  load as loadComicbook,
  loadImpressions as loadComicbookImpressions,
  loadOtherEpisodes as loadComicbookOtherEpisodes,
} from 'client/bookmate/reducers/comicbook-reducer'

import {
  loadSerial,
  loadImpressions as loadSerialImpressions,
  loadRelated as loadRelatedSerial,
  loadQuotes as loadQuotesSerial,
  loadReaders as loadReadersSerial,
  loadShelves as loadShelvesSerial,
  readInPrivate as readInPrivateSerial,
  markAsRead as markAsReadSerial,
  removeFromWishlist as removeFromWishlistSerial,
} from 'client/bookmate/reducers/serial-reducer'

import {
  getBookById,
  getBooksByIds,
} from 'client/bookmate/selectors/book-selector'
import {
  getAudioBookById,
  getAudioBooksByIds,
} from 'client/bookmate/reducers/audiobooks-reducer'
import {
  getComicbookById,
  getComicbooksByIds,
  createComicbookCard,
} from 'client/bookmate/reducers/comicbooks-reducer'

import { createAudioCard } from 'client/bookmate/reducers/audio-cards-reducer'

import deeplinkHelper from 'client/shared/helpers/deeplink-helper'

import { PostProps } from 'client/shared/types/post'

import { ResourceName, ResourceProps } from 'client/shared/types/resource'
import { SerialProps } from 'client/shared/types/serial'
import { BookProps } from 'client/shared/types/book'
import { ApiAction } from 'shared/middlewares/api-middleware'

export const resourceTypes = {
  BOOK: 'book',
  COMICBOOK: 'comicbook',
  AUDIOBOOK: 'audiobook',
  SERIAL: 'serial',
  QUOTE: 'quote',
  IMPRESSION: 'impression',
}

// for singular resource
export function getResourceNormalizationSchemaByType(type: ResourceName) {
  switch (type) {
    case 'serial':
    case 'book':
      return bookSchema
    case 'audiobook':
      return audioBookSchema
    case 'comicbook':
      return comicbookSchema
    default:
      break
  }
}

export function getResourceByIdGetter(
  type: ResourceName,
): (state: any, id: string) => ResourceProps {
  switch (type) {
    case 'serial':
    case 'book':
      return getBookById
    case 'audiobook':
      return getAudioBookById
    case 'comicbook':
      return getComicbookById
  }
}

export function getResourcesByIdsGetter(type: ResourceName) {
  switch (type) {
    case 'serial':
    case 'book':
      return getBooksByIds
    case 'audiobook':
      return getAudioBooksByIds
    case 'comicbook':
      return getComicbooksByIds
    default:
      break
  }
}

export function getResourceImpressionsLoader(type: ResourceName) {
  switch (type) {
    case 'book':
      return loadBookImpressions
    case 'audiobook':
      return loadAudiobookImpressions
    case 'comicbook':
      return loadComicbookImpressions
    case 'serial':
      return loadSerialImpressions
    default:
      break
  }
}

export function getResourceOtherEpisodesLoader(type: ResourceName) {
  switch (type) {
    case 'book':
      return loadBookOtherEpisodes
    case 'audiobook':
      return loadAudiobookOtherEpisodes
    case 'comicbook':
      return loadComicbookOtherEpisodes
    default:
      break
  }
}

export function getResourceLoader(type: ResourceName) {
  switch (type) {
    case 'book':
      return loadBook
    case 'audiobook':
      return loadAudiobook
    case 'comicbook':
      return loadComicbook
    case 'serial':
      return loadSerial
    default:
      break
  }
}

export function getItemsFromPosts(posts: PostProps[]) {
  return posts.reduce((result, post) => {
    if (
      ['book', 'audiobook', 'comicbook', 'serial'].includes(post.resourceType)
    ) {
      result.push(post.resource)
    }
    return result
  }, [])
}

export function getResourceStoreLinkGetter(type: ResourceName) {
  switch (type) {
    case 'book':
      return deeplinkHelper.getBookStoreLink.bind(deeplinkHelper)
    case 'audiobook':
      return deeplinkHelper.getAudioBookStoreLink.bind(deeplinkHelper)
    case 'comicbook':
      return deeplinkHelper.getComicbookStoreLink.bind(deeplinkHelper)
    default:
      break
  }
}

export const getOtherEpisodesLoader = {
  book: loadBookOtherEpisodes,
  audiobook: loadAudiobookOtherEpisodes,
  comicbook: loadComicbookOtherEpisodes,
}

export const getResourceRelatedLoader = {
  book: (uuid: string): void => loadRelatedBook(uuid),
  serial: (uuid: string): void => loadRelatedSerial(uuid),
}

export const getResourceQuotesLoader = {
  book: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadQuotesBook(params),
  serial: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadQuotesSerial(params),
}

export const getResourceShelvesLoader = {
  book: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadShelvesBook(params),
  serial: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadShelvesSerial(params),
}

export const getResourceReadersLoader = {
  book: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadReadersBook(params),
  serial: (params: {
    uuid: string
    p: number
    pp: number
    append?: boolean
  }): void => loadReadersSerial(params),
}

type Resource = BookProps | SerialProps

export const readInPrivate = {
  book: (resource: Resource, libraryCardUuid?: string | null): ApiAction =>
    readInPrivateBook(resource, libraryCardUuid),
  serial: (resource: Resource, libraryCardUuid?: string | null): ApiAction =>
    readInPrivateSerial(resource, libraryCardUuid),
}
export const markAsRead = {
  book: (resource: Resource, libraryCardUuid: string): ApiAction =>
    markAsReadBook(resource, libraryCardUuid),
  serial: (resource: Resource, libraryCardUuid: string): ApiAction =>
    markAsReadSerial(resource, libraryCardUuid),
}

export const removeFromWishlist = {
  book: (resource: Resource): ApiAction => removeFromWishlistBook(resource),
  serial: (resource: Resource): ApiAction => removeFromWishlistSerial(resource),
}

export const createLibraryCard = {
  book: (resource: ResourceProps): ApiAction =>
    createLibbraryCardBook({}, resource),
  serial: (resource: ResourceProps): ApiAction =>
    createLibbraryCardBook({}, resource),
  comicbook: (resource: ResourceProps): ApiAction =>
    createComicbookCard({}, resource),
  audiobook: (resource: ResourceProps): ApiAction =>
    createAudioCard({}, resource),
}

import urlFor, { getQueryString } from 'shared/tools/url-helper'
import { getClientId } from 'shared/middlewares/analytics-middleware'

const APPSFLYER_DOMAIN = 'https://bookmate.onelink.me/8LRx'

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/bookmate-listen-read-books/id386177450'
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.bookmate'

type DeeplinkProps = {
  uuid: string
  channel: string
  campaign: string
  stage: string
  isDesktop: boolean
}

export default {
  getDesktopStoreLink(channel: string): string {
    switch (channel) {
      case 'ios':
        return APP_STORE_LINK
      case 'android':
        return GOOGLE_PLAY_LINK
      default:
        return urlFor.getTheApp()
    }
  },

  getAppLink(target: string, uuid: string): string {
    const af_sub3 = getClientId()
    switch (target) {
      case 'book':
        return `bookmate://book?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'audiobook':
        return `bookmate://audiobook?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'comicbook':
        return `bookmate://comics?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'shelf':
        return `bookmate://bookshelf?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'quote':
        return `bookmate://marker?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'impression':
        return `bookmate://document_impression?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'post':
        return `bookmate://bookshelf_document?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'user':
        return `bookmate://user?login=${uuid}&af_sub3=${af_sub3}`
      case 'feed':
        return `bookmate://feed?af_sub3=${af_sub3}`
      case 'community':
        return `bookmate://community?af_sub3=${af_sub3}`
      case 'createShelf':
        return `bookmate://create_bookshelf?af_sub3=${af_sub3}`
      case 'createImpression':
        return `bookmate://create_impression?uuid=${uuid}&af_sub3=${af_sub3}`
      case 'close':
        return `bookmate://close?af_sub3=${af_sub3}`
      case 'achievements':
        return `bookmate://achievements?login=${uuid}&af_sub3=${af_sub3}`
      case 'author':
        return `bookmate://author?uuid=${uuid}&af_sub3=${af_sub3}`
      default:
      case 'showcase':
        return `bookmate://showcase?af_sub3=${af_sub3}`
    }
  },

  getStoreLink({
    channel,
    campaign = 'install_app',
    stage,
    isDesktop,
    deeplinkPath = 'bookmate://showcase',
    pid = 'Download_app_web',
  }: {
    channel: string
    campaign: string
    stage: string
    isDesktop: boolean
    deeplinkPath: string
    pid: string
  }): string {
    const params = getQueryString({
      campaign,
      tags: 'bm_web',
      channel,
      stage,
      af_web_dp: isDesktop ? this.getDesktopStoreLink(channel) : '',
      pid,
      af_dp: deeplinkPath,
    })

    return `${APPSFLYER_DOMAIN}${params}`
  },

  getBookStoreLink({
    uuid,
    channel,
    campaign,
    stage,
    isDesktop,
  }: DeeplinkProps): string {
    const deeplinkPath = this.getAppLink('book', uuid)
    return this.getStoreLink({
      channel,
      campaign,
      stage,
      isDesktop,
      deeplinkPath,
    })
  },

  getAudioBookStoreLink({
    uuid,
    channel,
    campaign,
    stage,
    isDesktop,
  }: DeeplinkProps): string {
    const deeplinkPath = this.getAppLink('audiobook', uuid)
    return this.getStoreLink({
      channel,
      campaign,
      stage,
      isDesktop,
      deeplinkPath,
    })
  },

  getComicbookStoreLink({
    uuid,
    channel,
    campaign,
    stage,
    isDesktop,
  }: DeeplinkProps): string {
    const deeplinkPath = this.getAppLink('comicbook', uuid)
    return this.getStoreLink({
      channel,
      campaign,
      stage,
      isDesktop,
      deeplinkPath,
    })
  },

  getShelfStoreLink({
    uuid,
    channel,
    campaign,
    stage,
    isDesktop,
  }: DeeplinkProps): string {
    const deeplinkPath = this.getAppLink('shelf', uuid)
    return this.getStoreLink({
      channel,
      campaign,
      stage,
      isDesktop,
      deeplinkPath,
    })
  },
}

import { isLatAmCountry } from 'shared/tools/country-helpers'

export function localeToRegion(locale: string): string {
  switch (locale) {
    case 'en':
      return 'en_US'
    case 'sv':
      return 'sv_SE'
    default:
      return `${locale}_${locale.toUpperCase()}`
  }
}

export function readingLanguagesByCountry(country: string): string[] {
  if (isLatAmCountry(country)) {
    return ['es', 'en']
  }

  switch (country) {
    case 'by':
    case 'ru':
      return ['ru', 'en']
    case 'uk':
      return ['uk', 'en']
    case 'sr':
      return ['sr', 'en']
    case 'tr':
      return ['tr', 'en']
    case 'id':
      return ['id', 'en']
    case 'dk':
      return ['da', 'en']
    case 'nl':
      return ['nl', 'en']
    case 'bg':
      return ['bg', 'en']
    default:
      return ['en', 'es']
  }
}

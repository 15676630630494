import React, { Component } from 'react'
import MastercardIcon from 'client/shared/icons/mastercard.svg'
import VisaIcon from 'client/shared/icons/visa.svg'
import SVGInline from 'react-svg-inline'

export class AcceptedCards extends Component {
  render(): JSX.Element {
    return (
      <div className="stripe-credit-card__accepted-cards_group">
        <SVGInline
          className="stripe-credit-card__accepted-cards_icon visa-icon"
          svg={VisaIcon}
        />
        <SVGInline
          className="stripe-credit-card__accepted-cards_icon mastercard-icon"
          svg={MastercardIcon}
        />
      </div>
    )
  }
}

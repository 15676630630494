import React, { SyntheticEvent } from 'react'

import './phone-input.styl'

type Props = {
  value: string
  onChange: (value: string, isValid: boolean, areaCode: string) => void
}

export const MTN_AREA_CODE = '+233'

export const PhoneInput: React.FC<Props> = props => {
  const { value, onChange } = props

  const handlePhoneChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const v = e.currentTarget.value.replace(/\D/g, '')
    const isValid = v.length >= 7 && v.length <= 9
    onChange(v, isValid, MTN_AREA_CODE)
  }

  return (
    <div className="mtn-phone-input-wrapper">
      <div className="mtn-phone-input-code">{MTN_AREA_CODE}</div>
      <input
        className="mtn-phone-input"
        placeholder="Enter your phone number"
        type="phone"
        value={value}
        onChange={handlePhoneChange}
      />
    </div>
  )
}

import React, { Component } from 'react'

import Input from 'client/shared/blocks/input'
import { Loader } from 'client/shared/blocks/loader'

import { AUTOFILL_CHECK_TIMEOUT } from 'client/shared/constants/time-constants'

import './credential-input.styl'
import { isEmailValid } from '../../helpers/is-email-valid'

type Props = {
  loading: boolean
  userpic: string
  inputProps: { name: string; placeholder: string; autoFocus: boolean }
  onBlur: (arg0: Event) => void
  onChange: (arg?: unknown) => void
  onBrowserEntered: (email: string) => void
}

export default class CredentialInput extends Component<Props> {
  static defaultProps = {
    loading: false,
    userpic: '',
  }

  timer: ReturnType<typeof setTimeout> | null

  constructor(props: Props) {
    super(props)

    this.timer = null
  }

  componentDidMount(): void {
    this.checkIfAutocompleted()
  }

  componentWillUnmount(): void {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  // the field can be autocompleted by the browser that has saved user's credentials
  // for our web site; since the browser does not dispatch the "change" event when
  // it autocompletes the field, we are firing the change event manually
  // NOTE: this method does not work for Chrome (and possibly related browsers).
  // Chrome does not allow getting values from the pre-filled fields via the browser api
  checkIfAutocompleted(): void {
    const { input } = this.refs
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputElement = input.getElement()

    this.timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const email = input.getValue()
      if (email) {
        const changeEvent = document.createEvent('Event')
        changeEvent.initEvent('change', false, true)
        const blurEvent = document.createEvent('Event')
        blurEvent.initEvent('blur', false, true)
        inputElement.form.dispatchEvent(changeEvent)
        inputElement.dispatchEvent(blurEvent)
        // this.props.onBrowserEntered(email)
      }
    }, AUTOFILL_CHECK_TIMEOUT)
  }

  debounceTimer: ReturnType<typeof setTimeout> | null = null

  onDebounceChange = (arg?: unknown): void => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }

    const email = arg
      ? (arg as React.ChangeEvent<HTMLInputElement>)?.target.value
      : ''

    if (isEmailValid(email)) {
      this.debounceTimer = setTimeout(() => {
        this.props.onBrowserEntered(email)
      }, AUTOFILL_CHECK_TIMEOUT)
    }

    this.props.onChange(arg)
  }

  render(): JSX.Element {
    const { loading, userpic, inputProps, onBlur } = this.props

    return (
      <div className="credential-input">
        {loading && this.renderLoader()}
        {userpic && this.renderUserPic(userpic)}
        <Input
          {...inputProps}
          type="text"
          onBlur={onBlur}
          // onChange={onChange}
          onChange={this.onDebounceChange}
          ref="input"
          kind={loading || userpic ? 'with-userpic' : ''}
        />
      </div>
    )
  }

  renderLoader(): JSX.Element {
    return (
      <div className="credential-input__loader">
        <Loader color="gray" />
      </div>
    )
  }

  renderUserPic(userpic: string): JSX.Element {
    return <img className="credential-input__userpic" src={userpic} alt="" />
  }
}

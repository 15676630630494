import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { PurchaseStatus } from 'client/bookmate/reducers/gifts-reducer'

const ASYNC_PURCHASE_STATUS_LOAD = 'ASYNC_PURCHASE_STATUS_LOAD'
const ASYNC_PURCHASE_STATUS_LOAD_ERROR = 'ASYNC_PURCHASE_STATUS_LOAD_ERROR'
const ASYNC_PURCHASE_STATUS_LOAD_SUCCESS = 'ASYNC_PURCHASE_STATUS_LOAD_SUCCESS'

type AsyncPurchaseStatusLoadAction = {
  type: typeof ASYNC_PURCHASE_STATUS_LOAD
}

type AsyncPurchaseStatusSuccessAction = {
  type: typeof ASYNC_PURCHASE_STATUS_LOAD_SUCCESS
  status: PurchaseStatus
  error: { message: string | null }
  promo_uuid?: string
}

type AsyncPurchaseStatusErrorAction = {
  type: typeof ASYNC_PURCHASE_STATUS_LOAD_ERROR
  status: PurchaseStatus
  error: { message: string }
}

type Action =
  | AsyncPurchaseStatusLoadAction
  | AsyncPurchaseStatusSuccessAction
  | AsyncPurchaseStatusErrorAction

export type State = {
  loading: boolean
  purchaseStatus?: PurchaseStatus
  purchaseError?: string | null
  promo?: string
}

export function loadSubscriptionPurchaseStatus(id: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/subscription_purchases/${id}`,
      types: [
        ASYNC_PURCHASE_STATUS_LOAD,
        ASYNC_PURCHASE_STATUS_LOAD_SUCCESS,
        ASYNC_PURCHASE_STATUS_LOAD_ERROR,
      ],
      options: {
        ignoreError: true,
      },
    },
  }
}

export function loadCheapTrialPurchaseStatus(id: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/cheap_trial_purchases/${id}`,
      types: [
        ASYNC_PURCHASE_STATUS_LOAD,
        ASYNC_PURCHASE_STATUS_LOAD_SUCCESS,
        ASYNC_PURCHASE_STATUS_LOAD_ERROR,
      ],
      options: {
        ignoreError: true,
      },
    },
  }
}

export function loadPromoPurchaseStatus(id: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/promo_purchases/${id}`,
      types: [
        ASYNC_PURCHASE_STATUS_LOAD,
        ASYNC_PURCHASE_STATUS_LOAD_SUCCESS,
        ASYNC_PURCHASE_STATUS_LOAD_ERROR,
      ],
      options: {
        ignoreError: true,
      },
    },
  }
}

const initialState = {
  loading: false,
  completionError: null,
}

export default function asyncReducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case ASYNC_PURCHASE_STATUS_LOAD_SUCCESS:
      return {
        ...state,
        purchaseStatus: action.status,
        purchaseError: action.error?.message,
        promo: action.promo_uuid,
      }

    case ASYNC_PURCHASE_STATUS_LOAD_ERROR:
      return {
        ...state,
        purchaseStatus: action.status,
        purchaseError: action.error?.message,
      }

    default:
      return state
  }
}

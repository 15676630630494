global.IntlRelativeFormat = require('intl-relativeformat')

import ReactDOM from 'react-dom'
import { loadableReady } from '@loadable/component'

import config from 'config'
import { clientContainerCreator } from 'shared/tools/container-creator'
import { getUserLanguageTranslations } from 'client/shared/reducers/languages-reducer'

const localeResolver = require.context(
  'intl-relativeformat/dist/locale-data/',
  false,
  /\.js$/,
)

export default {
  init({ store, routes, locales = config.locales }) {
    locales.forEach(locale => localeResolver(`./${locale}.js`))
    const {
      currentUser: {
        data: { locale },
      },
    } = store.getState()
    const clientRender = ReactDOM.hydrate

    Promise.all([
      new Promise(loadableReady),
      store.dispatch(getUserLanguageTranslations(locale)),
    ]).then(() => {
      clientRender(
        clientContainerCreator({ store, routes }),
        document.getElementById('app-container'),
      )
    })
  },
}

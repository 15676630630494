import { TODO_ANY } from './shared/types/helper'

export default {
  requiredBuildParams: [
    'DEV_TOOL',
    'APP_ENV',
    'NODE_ENV',
    'NOTIFIER',
    'GIT_COMMIT',
    'DROP_CONSOLE',
    'SPEED_ANALYZER',
    'CIRCULAR_CHECK',
    'SERVER_ADDRESS',
    'MINIMIZE_CLIENT',
    'BUNDLE_ANALYZER',
    'DEV_TOOL_SERVER',
    'MINIMIZE_SERVER',
    'BUNDLE_ANALYZER_PORT',
    'ROLLBAR_TOKEN_SERVER',
  ],

  get value(): string {
    return this.isClient()
      ? window.CLIENT_PARAMS.env
      : (process.env.APP_ENV as string)
  },

  isClient(): boolean {
    return typeof window !== 'undefined'
  },

  isServer(): boolean {
    return !this.isClient()
  },

  isDevelopment(): boolean {
    return this.value === 'development'
  },

  isStaging(): boolean {
    return this.value === 'staging'
  },

  isProduction(): boolean {
    return this.value === 'production'
  },

  isTest(): boolean {
    return this.value === 'test'
  },

  getParam(param: string): TODO_ANY {
    const envParams = this.isServer() ? process.env : window.CLIENT_PARAMS
    const value = envParams[param] || ''
    return value.replace(/"/g, '')
  },

  getParamAsNumber(param: string): number {
    return Number(this.getParam(param))
  },

  getParamAsBoolean(param: string): boolean {
    return this.getParam(param) === 'true'
  },

  getDynamicParam(paramName: string): string {
    if (this.isDevelopment()) {
      const param = this.getParam(paramName)

      if (param === 'true' || param === 'false') return param

      return JSON.stringify(param)
    }

    return `$REPLACE_ME(${paramName})`
  },

  checkEnvParams(paramsArray: string[]): void {
    const absentParams = paramsArray.filter(
      paramName => !this.getParam(paramName),
    )

    if (absentParams.length > 0) {
      throw new Error(`Absent env params: ${absentParams.join('; ')}`)
    }
  },
}

import { ResourceMetaData } from 'client/shared/types/resource'

export const getMetaId = ({
  resourceName,
  resourceUuid,
  language,
  contentType,
}: ResourceMetaData) =>
  contentType
    ? `${resourceName}-${resourceUuid}-${language}-${contentType}`
    : `${resourceName}-${resourceUuid}-${language}`

import debounce from 'lodash/debounce'
import noop from 'lodash/noop'

import config from 'config'
import { CALL_API } from 'shared/middlewares/api-middleware'
import { cacheAction } from 'shared/middlewares/cache-middleware'

import { BOOK_CHANGED } from 'client/bookmate/reducers/book-reducer'
import { AUTH_FORM_LOGIN_SUCCESS } from 'client/shared/reducers/auth-reducer'
import { CURRENT_USER_LIBRARY_LANGUAGE_CHANGED } from 'client/shared/reducers/current-user-reducer'
import {
  analyticsEvent,
  SEARCH_RESULTS_LOADED,
} from 'client/shared/reducers/analytics-reducer'

import {
  apiForSection,
  extractResultsForSection,
  extractNotFound,
  extractNextPage,
  SECTION_TO_CONTEXT,
  normalizeData,
} from 'client/bookmate/helpers/search-helpers'

import type {
  SearchResultsProps,
  SearchTrendingProps,
} from 'client/shared/types/search'

const SEARCH_QUERY_CHANGE = 'SEARCH_QUERY_CHANGE'
const SEARCH_FILTER_HIDE = 'SEARCH_FILTER_HIDE'
const SEARCH_SECTION_CHANGE = 'SEARCH_SECTION_CHANGE'
const SEARCH_RESULTS_LOAD = 'SEARCH_RESULTS_LOAD'
export const SEARCH_RESULTS_LOAD_SUCCESS = 'SEARCH_RESULTS_LOAD_SUCCESS'
const SEARCH_RESULTS_LOAD_ERROR = 'SEARCH_RESULTS_LOAD_ERROR'

const SEARCH_TRENDING_LOAD = 'SEARCH_TRENDING_LOAD'
const SEARCH_TRENDING_LOAD_SUCCESS = 'SEARCH_TRENDING_LOAD_SUCCESS'
const SEARCH_TRENDING_LOAD_ERROR = 'SEARCH_TRENDING_LOAD_ERROR'

const ONE_SECOND = 1000

type State = {
  ui: {
    listTopics: boolean
    showClear: boolean
    loading: boolean
    notFound: boolean
    showFilters: boolean
  }
  section: string
  query: string
  nextPage: number | null
  trending: SearchTrendingProps[]
  results: SearchResultsProps | null
  sendAnalytics: boolean
  lastFetchTimestamp: number
  error: any
}

type SearchFilterHideAction = {
  type: typeof SEARCH_FILTER_HIDE
}

type ChangeQueryAction = {
  type: typeof SEARCH_QUERY_CHANGE
  query: string
}

type ChangeSectionAction = {
  type: typeof SEARCH_SECTION_CHANGE
  section: string
}

type SearchResultsLoadAction = {
  type: typeof SEARCH_RESULTS_LOAD
  lastFetchTimestamp: number
}

type SearchResultsLoadSuccessAction = {
  type: typeof SEARCH_RESULTS_LOAD_SUCCESS
  entities: { [key: string]: any }
  results: SearchResultsProps
  nextPage: number | null
  notFound: boolean
  append: boolean
  section: string
  lastFetchTimestamp: number
}

type SearchResultsLoadErrorAction = {
  type: typeof SEARCH_RESULTS_LOAD_ERROR
  error: any
}

type SearchTrendingLoadAction = {
  type: typeof SEARCH_TRENDING_LOAD
}

type SearchTrendingLoadSuccessAction = {
  type: typeof SEARCH_TRENDING_LOAD_SUCCESS
  payload: SearchTrendingProps[]
}

type Action =
  | SearchFilterHideAction
  | ChangeQueryAction
  | ChangeSectionAction
  | SearchResultsLoadAction
  | SearchResultsLoadSuccessAction
  | SearchResultsLoadErrorAction
  | SearchTrendingLoadAction
  | SearchTrendingLoadSuccessAction

export function changeQuery(query: string) {
  return {
    type: SEARCH_QUERY_CHANGE,
    query,
  }
}

export function changeSection(section: string) {
  return {
    type: SEARCH_SECTION_CHANGE,
    section,
  }
}

function modifySearchResults(res, { query, section }) {
  const data = section === 'all' ? res.search : res
  const notFound = extractNotFound(data)
  const results = extractResultsForSection(data, section)
  const { normalizeResults, entities } = normalizeData(results)

  return {
    entities,
    results: normalizeResults,
    nextPage: extractNextPage(data, section, query),
    notFound,
  }
}

function sendSearchLoadedAnalytics({ empty, query, context }) {
  return analyticsEvent(SEARCH_RESULTS_LOADED, {
    empty,
    query,
    search_type: context,
  })
}

export const loadResults = cacheAction(
  // eslint-disable-next-line func-names
  function (query, section, callback = noop) {
    const lastFetchTimestamp = Date.now()

    if (!query.trim().length) {
      return {
        type: SEARCH_RESULTS_LOAD_SUCCESS,
        lastFetchTimestamp,
      }
    }

    const context = SECTION_TO_CONTEXT[section]

    return {
      [CALL_API]: {
        endpoint: `/p${apiForSection(section)}`,
        options: {
          data: {
            query,
            page: 1,
            per_page: 20,
          },
        },
        modifyResponse: response => {
          return modifySearchResults(response, {
            query,
            section,
          })
        },
        onSuccess: (dispatch, getState, res) => {
          const state = getState()
          const { sendAnalytics } = state.search

          if (sendAnalytics) {
            const responseData = section === 'all' ? res.search : res

            dispatch(
              sendSearchLoadedAnalytics({
                empty: extractNotFound(responseData),
                query,
                context,
              }),
            )
          }

          if (
            query === state.search.query &&
            section === state.search.section
          ) {
            callback({ section, query })
          }
        },
        onError: dispatch => {
          dispatch(
            sendSearchLoadedAnalytics({
              empty: true,
              query,
              context,
            }),
          )
        },
        types: [
          SEARCH_RESULTS_LOAD,
          SEARCH_RESULTS_LOAD_SUCCESS,
          SEARCH_RESULTS_LOAD_ERROR,
        ],
      },
      lastFetchTimestamp,
    }
  },
  SEARCH_RESULTS_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

export const loadNextPage = cacheAction(
  // eslint-disable-next-line func-names
  function (nextPageUrl, section, query) {
    return {
      [CALL_API]: {
        endpoint: nextPageUrl,
        modifyResponse: data => {
          const results = extractResultsForSection(data, section)
          const { normalizeResults, entities } = normalizeData(results)

          return {
            entities,
            results: normalizeResults,
            nextPage: extractNextPage(data, section, query),
            notFound: extractNotFound(data),
            section,
            append: true,
          }
        },
        types: [
          SEARCH_RESULTS_LOAD,
          SEARCH_RESULTS_LOAD_SUCCESS,
          SEARCH_RESULTS_LOAD_ERROR,
        ],
      },
      lastFetchTimestamp: Date.now(),
    }
  },
  SEARCH_RESULTS_LOAD_SUCCESS,
  [BOOK_CHANGED],
)

export const loadTrending = cacheAction(
  () => async (dispatch, getState) => {
    const {
      currentUser: {
        data: { library_lang },
      },
    } = getState()

    await dispatch({
      [CALL_API]: {
        endpoint: `/p/api/v5/popular_searches/${
          library_lang || config.fallbackLocale
        }`,
        modifyResponse: response => ({
          payload: response.popular_searches.resources,
        }),
        types: [
          SEARCH_TRENDING_LOAD,
          SEARCH_TRENDING_LOAD_SUCCESS,
          SEARCH_TRENDING_LOAD_ERROR,
        ],
      },
    })
  },
  SEARCH_TRENDING_LOAD_SUCCESS,
  [CURRENT_USER_LIBRARY_LANGUAGE_CHANGED, AUTH_FORM_LOGIN_SUCCESS],
)

export const debouncedLoadResults = debounce(
  (dispatch, query, section, callback) => {
    dispatch(loadResults(query, section, callback))
  },
  250,
)

export function hideSearchFilters() {
  return {
    type: SEARCH_FILTER_HIDE,
  }
}

export const initialState: State = {
  ui: {
    listTopics: true,
    showClear: false,
    loading: false,
    notFound: false,
    showFilters: false,
  },
  section: 'all',
  query: '',
  nextPage: null,
  trending: [],
  results: null,
  sendAnalytics: true,
  lastFetchTimestamp: 0,
  error: null,
}

export default function search(state: State = initialState, action: Action) {
  switch (action.type) {
    case SEARCH_FILTER_HIDE:
      return {
        ...state,
        ui: {
          ...state.ui,
          showFilters: false,
        },
      }

    case SEARCH_QUERY_CHANGE:
      return {
        ...state,
        ui: {
          ...state.ui,
          notFound: false,
          listTopics: !action.query.length,
          showClear: Boolean(action.query.length),
        },
        query: action.query,
        results: null,
      }

    case SEARCH_SECTION_CHANGE:
      return {
        ...state,
        section: action.section,
        nextPage: null,
        results: null,
      }

    case SEARCH_TRENDING_LOAD:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      }

    case SEARCH_RESULTS_LOAD:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
        nextPage: null,
        sendAnalytics:
          action.lastFetchTimestamp - state.lastFetchTimestamp > ONE_SECOND,
        lastFetchTimestamp: action.lastFetchTimestamp,
      }

    case SEARCH_RESULTS_LOAD_SUCCESS:
      if (state.results && action.append) {
        action.results[action.section] = {
          ...action.results[action.section],
          items: [
            ...state.results[action.section].items,
            ...action.results[action.section].items,
          ],
        }
      }

      if (action.lastFetchTimestamp >= state.lastFetchTimestamp) {
        return {
          ...state,
          ui: {
            ...state.ui,
            notFound: action.notFound,
            loading: false,
            showFilters: true,
          },
          nextPage: action.nextPage,
          results: action.results,
          lastFetchTimestamp: action.lastFetchTimestamp,
        }
      }

      return state

    case SEARCH_RESULTS_LOAD_ERROR:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
        nextPage: null,
        results: null,
        error: action.error,
      }

    case SEARCH_TRENDING_LOAD_SUCCESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: false,
        },
        trending: action.payload,
      }

    default:
      return state
  }
}

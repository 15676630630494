import store from 'store'
import v1compatPlugin from 'store/plugins/v1-backcompat'

store.addPlugin(v1compatPlugin)

const sessionFallbackTime = 1000 * 60 * 60 * 24

export default {
  get _time(): number {
    return new Date().getTime()
  },

  set(key: string, value: unknown, expiration?: number | string): void {
    if (expiration) {
      if (expiration === 'session') {
        try {
          window.sessionStorage.setItem(key, JSON.stringify(value))
          store.set(key, { value, expiration: 'session', time: this._time })
        } catch (e) {
          store.set(key, {
            value,
            expiration: sessionFallbackTime,
            time: this._time,
          })
        }
      } else if (typeof expiration === 'number') {
        store.set(key, { value, expiration, time: this._time })
      }
    } else {
      store.set(key, value)
    }
  },

  get(key: string): string | null {
    const data = store.get(key)

    if (!data) {
      return null
    }

    if (data.expiration) {
      if (data.expiration === 'session') {
        try {
          let value = window.sessionStorage.getItem(key)
          try {
            value = JSON.parse(value as string)
          } catch (e) {
            // don't care; will return the value as is
          }
          return value
        } catch (e) {
          return this._time - data.time > data.expiration ? null : data.value
        }
      }

      return this._time - data.time > data.expiration ? null : data.value
    }

    return data
  },

  remove(key: string): void {
    const data = store.get(key)

    if (!data) return

    if (data.expiration === 'session') {
      try {
        window.sessionStorage.removeItem(key)
      } catch (e) {
        // don't care
      }
    }

    store.remove(key)
  },

  clear(): void {
    store.clearAll()
  },
}

// from SO: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
// transforms a hex RGB string to an object with separated r, g, and b colors
export function hexToRGB(hex: string): { r: number; g: number; b: number } {
  const match = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (match) {
    return {
      r: parseInt(match[1], 16),
      g: parseInt(match[2], 16),
      b: parseInt(match[3], 16),
    }
  } else {
    return { r: 250, g: 0, b: 0 }
  }
}

export function stringifyRGB(color: {
  r: number
  g: number
  b: number
  a?: number
}) {
  const { r, g, b, a } = color
  if (typeof a === 'number') {
    return `rgba(${r}, ${g}, ${b}, ${a})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}

// from SO: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
// amount is a number between -1 and 1
// (negative amount makes the color darker; positive amount makes the color lighter)
export function shadeRGBColor(
  { r, g, b }: { r: number; g: number; b: number },
  amount: number,
) {
  const t = amount < 0 ? 0 : 255
  const p = amount < 0 ? amount * -1 : amount
  return {
    r: Math.round((t - r) * p) + r,
    g: Math.round((t - g) * p) + g,
    b: Math.round((t - b) * p) + b,
  }
}

export function lowerLightnessHSL(l: number, ligntessCoefficient: number) {
  const resultingL = l - ligntessCoefficient

  if (!resultingL) return null

  return resultingL
}

export function stringifyHSL(color: { h: number; s: number; l: number }) {
  const { h, s, l } = color
  return `hsl(${h},${s * 100}%,${l * 100}%)`
}

// from SO: https://stackoverflow.com/questions/17433015/change-the-hue-of-a-rgb-color-in-javascript
// see also https://www.rapidtables.com/convert/color/rgb-to-hsl.html for reference
export function rgbToHSL({ r, g, b }: { r: number; g: number; b: number }) {
  r /= 255
  g /= 255
  b /= 255
  const cMax = Math.max(r, g, b)
  const cMin = Math.min(r, g, b)
  const delta = cMax - cMin
  const l = (cMax + cMin) / 2
  let h = 0
  let s = 0

  if (delta === 0) {
    h = 0
  } else if (cMax === r) {
    h = 60 * (((g - b) / delta) % 6)
  } else if (cMax === g) {
    h = 60 * ((b - r) / delta + 2)
  } else {
    h = 60 * ((r - g) / delta + 4)
  }

  if (delta === 0) {
    s = 0
  } else {
    s = delta / (1 - Math.abs(2 * l - 1))
  }

  return {
    h,
    s,
    l,
  }
}

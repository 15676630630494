/*

The algorithm is adopted from the ReactTextTruncate library
(https://github.com/ShinyChang/React-Text-Truncate)

Description of the text trimming algorithm:

1) Get initial data:
 - full text that is passed in the element
 - allowed number of lines
 - text that will be added as an ellipsis
 - width of the text component

2) Get the function that is going to measure the width of the
  truncated text (by making use of the canvas api)

3) Starting from the beginning of the text, walk its length
  towards the end. With every added character, check the width
  of the text fragment that has been examined so far comparing it
  to the maximum width of the container. When the width of the substring
  of the text becomes greater than (or equal to) the width of the component,
  remember the index of the last white space (a place where the line will break),
  subtract one from the number of lines allowed in the component, and continue
  from the character after the remembered white space.
  If the end of the text is reached before the allowed number of lines is used up,
  return a message notifying that the text should not be truncated. Else, add the
  ellipsis text to the end of the last line of the text, and walk back until the
  last line with the ellipsis fits in the container. Then return a message
  notifying that the text should be truncated and providing the index for truncation
  (which is position of the last white space before the ellipsis text)

*/

/**
 * @param {Object} parameters - An object containing all necessary parameters for the function:
 * @param {string} parameters.text - The text whose length needs to be checked for truncation
 * NOTE: the function is NOT expecting the text to contain HTML tags
 * NOTE: it is assumed that any word on the last line + ellipsis is narrower than the container width
 * @param {number} parameters.allowedNumberOfLines - Maximum number of lines the text can wrap in the text element
 * @param {string} parameters.ellipsisText - The text fragment added to the end of the truncated text to indicate truncation
 * @param {number} parameters.maxWidth - The width of the component (that a text line can not exceed)
 * @param {function} parameters.textWidthMeasurer - A function that measures the width of a text line
 * @return {Object} result
 * @return {bool} result.shouldTruncate - Instruction whether or not to truncate the text
 * @return {number} result.truncationIndex - Index at which the text should be truncated
 */
export default function findIndexForTruncation(parameters) {
  const {
    text,
    allowedNumberOfLines,
    ellipsisText = '',
    pluralEllipsisText,
    // used for e.g. a comma-separated list of authors
    maxWidth,
    textWidthMeasurer,
  } = parameters

  let lineStartPosition = 0
  let cursorPosition = 0
  let currentLineText = ''
  let currentLineWidth = 0
  let splitLinePosition = 0
  let lastPotentialLineBreakPosition = 0
  let lineHasPotentialLineBreaks = false

  const result = {
    shouldTruncate: false,
    truncationIndex: 0,
  }

  if (text.length === 0) {
    return result
  }

  // first, find the number of characters of the initial text that can fit in the container
  for (let currentLine = 0; currentLine < allowedNumberOfLines; currentLine++) {
    while (cursorPosition < text.length) {
      currentLineText = text.slice(lineStartPosition, cursorPosition + 1)
      currentLineWidth = textWidthMeasurer(currentLineText)

      if (currentLineWidth < maxWidth - 1) {
        if (
          text[cursorPosition + 1] === ' ' ||
          text[cursorPosition + 1] === '-'
        ) {
          // keep track of the last white space
          lineHasPotentialLineBreaks = true
          lastPotentialLineBreakPosition = cursorPosition
        }
        cursorPosition += 1
      } else {
        // at this point, we got to the position in the text where it splits to the next line
        // so if this is not the last allowed line, update the line start position
        splitLinePosition = lineHasPotentialLineBreaks
          ? lastPotentialLineBreakPosition
          : cursorPosition
        if (currentLine < allowedNumberOfLines - 1) {
          lineHasPotentialLineBreaks = false
          lineStartPosition = splitLinePosition + 1
        } else {
          result.shouldTruncate = true
        }
        break
      }
    }
  }
  // by this point, we have the portion of the original text that can fit in the container

  // adjust for ellipsis text in case the text has to be truncated and ellipsis text is supplied
  if (result.shouldTruncate && (ellipsisText || pluralEllipsisText)) {
    do {
      // let text = text.slice(lineStartPosition, cursorPosition);
      currentLineText = text.slice(lineStartPosition, cursorPosition)
      currentLineText += ellipsisText
      currentLineWidth = textWidthMeasurer(currentLineText)
      cursorPosition--
    } while (cursorPosition >= 0 && currentLineWidth >= maxWidth)
  }

  if (cursorPosition === text.length) {
    // cursor reached the end of the text, which means that the text should not be truncated
    return result
  } else {
    result.shouldTruncate = true
    result.truncationIndex = cursorPosition
  }
  return result
}

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'
import { withRouter } from 'react-router'

import { markAsRead as markNotificationsAsRead } from 'client/shared/reducers/notifications-reducer'

import deviceHelper from 'shared/tools/device-helper'
import subscriptionHelper from 'client/shared/helpers/subscription-helper'
import { isGiftsAvailable } from 'shared/tools/gifts-helper'

import Header from 'client/shared/blocks/header'
import { Sticker } from 'client/shared/blocks/sticker'

import { CurrentUserState } from 'client/shared/types/current-user'
import { State as SubscriptionState } from 'client/bookmate/reducers/subscription-reducer'
import { Route } from 'client/shared/types/react-router'
import { Dispatch, State } from 'shared/types/redux'
import { Navigation } from '../types/showcase'
import { Location } from 'client/shared/types/react-router'
import { loadRootShowcaseNavigation } from 'client/bookmate/reducers/showcase-reducer'
import urlHelper from 'shared/tools/url-helper'
import {
  DK_SHELF_UUID,
  DK_SUMMER_SHELF_UUID,
} from 'client/bookmate/boxes/follow-button-box'
import { LogoKind } from '../blocks/logo/logo'

type Props = {
  isBookmateApp: boolean
  size: string
  currentUser: CurrentUserState
  subscription: SubscriptionState
  route?: Route
  giftsShown: boolean
  showcaseType: Navigation[]
  location: Location
  isPointerEventsBlocked?: boolean
  dispatch: Dispatch
  fixed: boolean
  custom?: 'family' | string
  logoKind?: LogoKind
}
class HeaderBox extends PureComponent<Props> {
  markNotificationsAsRead = () => {
    const {
      currentUser: {
        data: { has_unread_notifications },
      },
    } = this.props

    if (has_unread_notifications) {
      this.props.dispatch(markNotificationsAsRead())
    }
  }

  render() {
    const { size, isBookmateApp, isPointerEventsBlocked } = this.props

    if (isBookmateApp) return null

    const isMobile = deviceHelper.isMobileSize({ size })

    return isMobile ? (
      <Sticker style={isPointerEventsBlocked ? { pointerEvents: 'none' } : {}}>
        {this.renderHeader()}
      </Sticker>
    ) : (
      this.renderHeader()
    )
  }

  renderHeader() {
    const {
      dispatch,
      size,
      currentUser,
      subscription: { userAccessLevels },
      route,
      giftsShown,
      showcaseType,
      location,
      fixed,
      custom,
      logoKind,
    } = this.props

    if (!showcaseType?.length) {
      dispatch(loadRootShowcaseNavigation(currentUser.data.library_lang))
    }

    return (
      <Header
        logoKind={logoKind}
        dispatch={dispatch}
        size={size}
        user={currentUser}
        hasSubscription={subscriptionHelper.hasActiveSubscriptions(
          userAccessLevels,
        )}
        hideSubscriptionBoxAt={[
          'family',
          'code',
          'subscription',
          'a1',
          'original',
          'codigo',
          'telcel',
          '3mesesx99',
          urlHelper.shelf(DK_SHELF_UUID),
          urlHelper.shelf(DK_SUMMER_SHELF_UUID),
        ]}
        onNotificationsHide={this.markNotificationsAsRead}
        route={route}
        giftsShown={giftsShown}
        showcaseType={showcaseType}
        location={location}
        fixed={fixed}
        custom={custom}
      />
    )
  }
}

const connectWrapper = connect((state: State) => ({
  size: state.app.size,
  isBookmateApp: state.app.isBookmateApp,
  currentUser: state.currentUser,
  subscription: state.subscription,
  giftsShown: state.gifts && isGiftsAvailable(state.gifts.availableKinds),
  showcaseType: state.showcase?.rootNavigation?.items,
}))

const wrapper = compose(connectWrapper, withRouter)

export default wrapper(HeaderBox)

import omit from 'lodash/omit'

// methods on this object are curried functions with already applied argument (app);
// so they do not need any more arguments
export type DeviceHelperWithApp = {
  getOsName: () => string
  isMobileOS: () => boolean
  isIOS: () => boolean
  isAndroid: () => boolean
  isWinPhone: () => boolean
  isWindows: () => boolean
  isDesktopSize: () => boolean
  isDesktopMediumSize: () => boolean
  isDesktopLargeSize: () => boolean
  isDesktopAllSizes: () => boolean
  isTabletSize: () => boolean
  isMobileSize: () => boolean
  isOneColumnSize: () => boolean
}

type AppParams = {
  os?: string
  size?: string
}

export default {
  withApp(app: AppParams) {
    return Object.keys(omit(this, ['withApp', 'getNativeOsName'])).reduce(
      (acc, key) => {
        acc[key] = this[key].bind(this, app)
        return acc
      },
      {},
    )
  },

  isMobileOS({ os }: { os: string }): boolean {
    return (
      this.isWinPhone({ os }) || this.isIOS({ os }) || this.isAndroid({ os })
    )
  },

  getNativeOsName(os: string): string {
    const oss = {
      ios: 'iOS',
      android: 'Android',
      wp: 'Windows Phone',
    }
    return oss[os]
  },

  getOsName({ os }: { os: string }): string {
    const oss = {
      iOS: 'ios',
      Android: 'android',
      'Windows Phone': 'wp',
    }
    return oss[os]
  },

  isIOS({ os }: { os: string }): boolean {
    return os === this.getNativeOsName('ios')
  },

  isAndroid({ os }: { os: string }): boolean {
    return os === this.getNativeOsName('android')
  },

  isWinPhone({ os }: { os: string }): boolean {
    return os === this.getNativeOsName('wp')
  },

  isWindows({ os }: { os: string }): boolean {
    return os === 'Windows'
  },

  isDesktopAllSizes({ size }: { size: string }): boolean {
    return (
      size === 'desktop' || size === 'desktopMedium' || size === 'desktopLarge'
    )
  },

  isDesktopSize({ size }: { size: string }): boolean {
    return size === 'desktop'
  },

  isDesktopMediumSize({ size }: { size: string }): boolean {
    return size === 'desktopMedium'
  },

  isDesktopLargeSize({ size }: { size: string }): boolean {
    return size === 'desktopLarge'
  },

  isTabletSize({ size }: { size?: string }): boolean {
    return size === 'tablet'
  },

  isMobileSize({ size }: { size?: string }): boolean {
    return size === 'mobile'
  },

  isOneColumnSize({ size }: { size: string }): boolean {
    return size === 'mobile' || size === 'tablet'
  },
}

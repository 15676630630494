import { MINIMUM_PASSWORD_LENGTH } from 'client/shared/constants/user-constants'

/**
 * Requirements for username (user's login)
 * - at least 3 characters long
 * - no more than 23 characters long
 * - on backend, must satisfy the following Ruby regex: /\A[a-z]+[a-z0-9]+\z/i
 *   therefore:
 *   - should start with a Latin letter
 *   - should consist only of Latin characters or digits
 */
export function isUsernameValid(
  username: string,
): { success: boolean } & { error?: string } {
  const validMessage = {
    success: true,
  }
  const failureMessage = {
    success: false,
  }

  const lengthCheck = checkUsernameLength(username)
  if (lengthCheck.error) {
    return Object.assign({}, failureMessage, lengthCheck)
  }

  const firstCharacterCheck = checkUsernameFirstCharacter(username)
  if (firstCharacterCheck.error) {
    return Object.assign({}, failureMessage, firstCharacterCheck)
  }

  const usernameCharactersCheck = checkUsernameCharacters(username)
  if (usernameCharactersCheck.error) {
    return Object.assign({}, failureMessage, usernameCharactersCheck)
  }

  return validMessage
}

function checkUsernameLength(username) {
  if (username.length < 3) {
    return { error: 'usernameLengthTooShort' }
  } else if (username.length > 23) {
    return { error: 'usernameLengthTooLong' }
  } else {
    return {}
  }
}

function checkUsernameFirstCharacter(username) {
  const firstCharacterRegex = /^[a-z]/i
  if (!firstCharacterRegex.test(username)) {
    return { error: 'usernameFirstCharacterNotLetter' }
  } else {
    return {}
  }
}

function checkUsernameCharacters(username) {
  const charactersRegex = /^(?:[a-z0-9]+)$/i
  if (!charactersRegex.test(username)) {
    return { error: 'usernameContainsInadmissibleCharacters' }
  } else {
    return {}
  }
}

export function checkPassword(password, confirmedPassword) {
  const validMessage = {
    success: true,
  }
  const failureMessage = {
    success: false,
  }

  const passwordsEquivalenceCheck = checkPasswordsEquivalence(
    password,
    confirmedPassword,
  )
  if (passwordsEquivalenceCheck.error) {
    return Object.assign({}, failureMessage, passwordsEquivalenceCheck)
  }

  const passwordLengthCheck = checkPasswordLength(password)
  if (passwordLengthCheck.error) {
    return Object.assign({}, failureMessage, passwordLengthCheck)
  }

  return validMessage
}

function checkPasswordsEquivalence(password, confirmedPassword) {
  if (password !== confirmedPassword) {
    return { error: 'newPasswordsDoNotMatch' }
  } else {
    return {}
  }
}

function checkPasswordLength(password) {
  // password can not be empty
  if (password.length === 0) {
    return { error: 'passwordCannotBeEmpty' }
  } else {
    return {}
  }
}

export function shouldEnablePasswordSubmit({
  oldPassword,
  newPassword,
  newPasswordConfirmed,
}: {
  oldPassword?: string
  newPassword: string
  newPasswordConfirmed: string
}): boolean {
  const isOldPasswordOk = oldPassword ? Boolean(oldPassword.length) : true
  const isNewPasswordOk = isPasswordValid(newPassword)
  const isPasswordConfirmed = newPassword === newPasswordConfirmed

  return isOldPasswordOk && isNewPasswordOk && isPasswordConfirmed
}

export function isPasswordValid(password) {
  return Boolean(password && password.length >= MINIMUM_PASSWORD_LENGTH)
}

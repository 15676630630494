import React, { Component } from 'react'

import { Trans } from '@lingui/react'

import './footer-links.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import urlFor, { QueryParams } from 'shared/tools/url-helper'
import Linka from 'client/shared/blocks/linka'

type Props = {
  kind: 'inline' | 'block' | 'header-dropdown'
  locale?: string
  auth?: boolean
  domain: string
  query?: QueryParams
  libraryLink?: string
  pathname?: string
} & DecoratorProps

class FooterLinks extends Component<Props> {
  render() {
    const { kind, auth, query, libraryLink, pathname } = this.props

    return (
      <ul className={`footer__links footer__links_${kind}`}>
        <li className="footer__links-item">
          <Linka path={urlFor.about(query)} className="footer__link">
            <Trans id="footer.about" />
          </Linka>
        </li>
        <li className="footer__links-item">
          <Linka
            path={libraryLink || urlFor.library(query)}
            className="footer__link"
          >
            <Trans id="header.library_link" />
          </Linka>
        </li>
        {!auth && !pathname?.includes('mtn') && (
          <li className="footer__links-item">
            <Linka
              path={urlFor.subscription('', query)}
              className="footer__link"
            >
              <Trans id="header.subscription_link" />
            </Linka>
          </li>
        )}
        <li className="footer__links-item">
          <Linka path={urlFor.contacts(query)} className="footer__link">
            <Trans id="footer.contact" />
          </Linka>
        </li>
        <li className="footer__links-item">
          <Linka path={urlFor.jobs(query)} className="footer__link">
            <Trans id="footer.jobs" />
          </Linka>
        </li>
        <li className="footer__links-item">
          <Linka path={urlFor.legal('', query)} className="footer__link">
            <Trans id="footer.legal" />
          </Linka>
        </li>
        <li className="footer__links-item">
          <Linka path={urlFor.support()} className="footer__link">
            <Trans id="side_menu.support" />
          </Linka>
        </li>
        <li className="footer__links-item">
          <Linka
            path={urlFor.sitemapPage(undefined, undefined, query)}
            className="footer__link"
          >
            <Trans id="sitemap.head_title" />
          </Linka>
        </li>
      </ul>
    )
  }
}

export default uiBox(FooterLinks)

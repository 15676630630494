import React, { Component } from 'react'
import cn from 'classnames'
import omit from 'lodash/omit'

import './heading.styl'

type Props = {
  rank: 1 | 2 | 3 | 4 | 6
  looksLike?: 1 | 2 | 3 | 4
  extraLarge?: boolean
  kind: 'default' | 'kazimir' | 'graphik'
  disableHeadingTag: boolean
  centered: boolean
  children?: React.ReactNode | string
  className?: string
  id?: string
}

export class Heading extends Component<Props> {
  static defaultProps = {
    rank: 1,
    kind: 'default',
    disableHeadingTag: false,
    centered: false,
  }

  getClassName(): string {
    const {
      rank,
      looksLike,
      kind,
      centered,
      extraLarge,
      className,
    } = this.props

    const sizeMod = looksLike || rank
    return cn('heading', `heading_${sizeMod}`, `heading_${kind}`, className, {
      heading_centered: centered,
      heading_extra_large: extraLarge,
    })
  }

  render(): JSX.Element {
    const { children, rank, disableHeadingTag, ...restProps } = this.props

    if (disableHeadingTag) {
      return <div className={this.getClassName()}>{children}</div>
    } else {
      const HeadingTag = `h${rank}`

      return (
        <HeadingTag
          {...omit(restProps, 'looksLike', 'centered', 'kind')}
          className={this.getClassName()}
        >
          {children}
        </HeadingTag>
      )
    }
  }
}

import React, { Component, createElement } from 'react'
import { Trans } from '@lingui/react'

import HorizontalScroller from 'client/shared/blocks/horizontal-scroller'
import Carousel from 'client/shared/blocks/carousel'
import Walker from 'client/shared/blocks/walker'
import snakeCase from 'lodash/snakeCase'
import Spacer from 'client/shared/blocks/spacer'
import ListHeader from 'client/bookmate/blocks/list-header'
import { Dispatch } from 'shared/types/redux'
import {
  OrderByOption,
  SortingList,
  OrderDirectionOption,
} from '../sorting/sorting'
import './list.styl'

export type ListType = 'default' | 'slider' | 'carousel' | 'walker'

export type HeaderRank = 1 | 2 | 3 | 4

type Props = {
  type: ListType
  isSeries?: boolean
  header?: string
  localizedHeader: boolean
  headerRank: HeaderRank
  looksLike?: HeaderRank
  allLink?: string | null | undefined
  allLinkInterceptor?: () => () => void | null | undefined
  children: React.ReactNode
  count?: number
  shouldUseHistoryReplace?: boolean
  coverHeight?: string
  showAllButton?: boolean
  kind?: string
  isOuterList?: boolean
  dispatch?: Dispatch
  isReducedSpacing?: boolean
  reverseHeader?: boolean
  withSorting?: ({
    order_by,
    order_direction,
  }: {
    order_by: OrderByOption
    order_direction: OrderDirectionOption
  }) => void
}

class List extends Component<Props> {
  static defaultProps = {
    type: 'default',
    localizedHeader: false,
    headerRank: 1,
  }

  render(): JSX.Element {
    const {
      header,
      headerRank,
      withSorting,
      reverseHeader,
      isOuterList,
    } = this.props
    const ListBody = createElement(isOuterList ? 'div' : 'ul', {
      children: this.renderBody(),
      className: 'list__body',
      id: `${snakeCase(header)}_list-body`,
    })

    return (
      <div className="list">
        <div className={`list-header-box${reverseHeader ? ' reverse' : ''}`}>
          {header && this.renderHeader()}
          {withSorting && <SortingList onSelect={withSorting} />}
        </div>
        <Spacer size={headerRank > 1 ? 8 : 16} />
        {ListBody}
      </div>
    )
  }

  renderHeader(): JSX.Element | null {
    const {
      header,
      allLink,
      allLinkInterceptor,
      localizedHeader,
      headerRank,
      count,
      shouldUseHistoryReplace,
      looksLike,
    } = this.props

    if (!header) return null

    return (
      <ListHeader
        rank={headerRank}
        looksLike={looksLike as HeaderRank}
        text={localizedHeader ? header : <Trans id={header} />}
        path={allLink}
        interceptor={allLinkInterceptor}
        count={count}
        shouldUseHistoryReplace={shouldUseHistoryReplace}
      />
    )
  }

  renderBody(): JSX.Element {
    const { type, children } = this.props

    switch (type) {
      case 'slider':
        return this.renderSlider()
      case 'carousel':
        return this.renderCarousel()
      case 'walker':
        return this.renderWalker()
      default:
        return children
    }
  }

  renderWalker(): JSX.Element {
    const {
      children,
      allLink,
      coverHeight,
      showAllButton,
      isReducedSpacing,
    } = this.props

    return (
      <Walker
        isReducedSpacing={isReducedSpacing}
        showAllLink={allLink}
        coverHeight={coverHeight}
        shouldShowLink={showAllButton}
      >
        {children}
      </Walker>
    )
  }

  renderSlider(): JSX.Element {
    const { children, isReducedSpacing = false, isSeries = false } = this.props

    return (
      <HorizontalScroller
        isSeries={isSeries}
        isReducedSpacing={isReducedSpacing}
      >
        {children}
      </HorizontalScroller>
    )
  }

  renderCarousel(): JSX.Element {
    const { children } = this.props
    return (
      <div className="list__carousel-container">
        <Carousel>
          {React.Children.map(children, (child, index) => (
            <div key={index} className="list__carousel-item-container">
              {child}
            </div>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default List

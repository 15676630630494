import React, { ReactNode } from 'react'

type Props = {
  isSelected: boolean
  children?: ReactNode
  hideChecker?: boolean
}

export function RadioTogglerOption(props: Props): JSX.Element {
  const baseClass = 'toggler__radio-option'
  const selectedModifier = props.isSelected ? `${baseClass}_selected` : ''
  const blockClass = `${baseClass} ${selectedModifier} ${
    props.hideChecker ? 'no-checker' : ''
  }`
  return <div className={blockClass}>{props.children}</div>
}

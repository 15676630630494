import storage from 'client/shared/helpers/storage'
import {
  invokeTagManagerConsentUpdateScript,
  pushCookieConsentUpdateEvent,
} from 'client/shared/helpers/vendor-trackers-helper'
import { isEUCountry } from 'shared/tools/country-helpers'

export type CookieConsent = {
  cookieBasic: boolean
  cookiePerformance: boolean
  cookieMarketing: boolean
}

export const COOKIES_FULL_CONSENT = {
  cookieBasic: true,
  cookiePerformance: true,
  cookieMarketing: true,
}

const COOKIES_BASIC_CONSENT = {
  cookieBasic: true,
  cookiePerformance: false,
  cookieMarketing: false,
}

export function setCookie(cookieName: string, value): void {
  const host = document.domain ?? 'localhost'
  document.cookie = `${cookieName}=${JSON.stringify(
    value,
  )};domain=${host};path=/;max-age=${cookieLifeTime}`
}

function getCookie(name: string) {
  let matches
  if (typeof document === 'object') {
    matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(
          /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
          '\\$1',
        )}=([^;]*)`,
      ),
    )
  }
  return matches ? decodeURIComponent(matches[1]) : null
}

export const cookieLifeTime = 1000 * 60 * 60 * 24

const SOFT_POLICY_PATHNAMES = []

export default {
  getCurrentCookieConsent(): CookieConsent {
    const storeVal = storage.get('bm_cookie_consent')

    if (!storeVal && typeof document !== 'undefined') {
      return storage.get(JSON.parse(getCookie('bm_cookie_consent')))
    }

    return storeVal
  },

  isSoftCookieLocation(pathname: string): boolean {
    return SOFT_POLICY_PATHNAMES.some(path => path === pathname)
  },

  isSoftCookieCountry(countryName: string): boolean {
    return !isEUCountry(countryName)
  },

  isSoftCookiePolicy(pathname: string, countryName: string): boolean {
    return (
      this.isSoftCookieLocation(pathname) ||
      this.isSoftCookieCountry(countryName)
    )
  },

  isConsentGiven(): boolean {
    const storeVal = storage.get('bm_cookie_consent_given')

    if (!storeVal && typeof document !== 'undefined') {
      return storage.get(JSON.parse(getCookie('bm_cookie_consent')))
    }

    return storeVal
  },

  isConsentSet(): boolean {
    const storeVal = Boolean(
      storage.get('bm_cookie_consent') &&
        Object.keys(storage.get('bm_cookie_consent')).length,
    )

    if (!storeVal && typeof document !== 'undefined') {
      const obj = JSON.parse(getCookie('bm_cookie_consent'))
      return Boolean(obj && Object.keys(obj).length)
    }

    return storeVal
  },

  isFullConsent(): boolean {
    if (this.isConsentSet()) {
      const values = Object.values(storage.get('bm_cookie_consent'))

      if (!values.length && typeof document !== 'undefined') {
        const newValues = Object.values(
          JSON.parse(getCookie('bm_cookie_consent')),
        )
        return !newValues.some(v => v === false)
      }

      return !values.some(v => v === false)
    } else {
      return false
    }
  },

  isStashedConsent(): boolean {
    const storeVal = Boolean(
      storage.get('bm_cookie_consent_stash') &&
        Object.keys(storage.get('bm_cookie_consent_stash')).length,
    )

    if (!storeVal && typeof document !== 'undefined') {
      const data = JSON.parse(getCookie('bm_cookie_consent_stash'))
      return Boolean(data && Object.keys(data).length)
    }

    return storeVal
  },

  enableAllCategories({
    force,
    country,
  }: {
    force?: boolean
    country?: string
  }): void {
    if (force) {
      if (this.isConsentSet()) {
        this.stashCurrentConsent()
      }
    } else {
      invokeTagManagerConsentUpdateScript(true, true)
      if (isEUCountry(country as string)) pushCookieConsentUpdateEvent()
    }
    this.setFullConsent()
  },

  setUserChoice(choice: CookieConsent): void {
    if (typeof document !== 'undefined') {
      setCookie('bm_cookie_consent_choice', { choice })
    }

    storage.set('bm_cookie_consent_choice', { choice })
  },

  setFullConsent(): void {
    if (typeof document !== 'undefined') {
      setCookie('bm_cookie_consent', { ...COOKIES_FULL_CONSENT })
    }

    storage.set('bm_cookie_consent', { ...COOKIES_FULL_CONSENT })
  },

  setBasicConsent({
    mode,
    country,
  }: {
    country?: string
    mode?: string
  }): void {
    if (typeof document !== 'undefined') {
      setCookie('bm_cookie_consent', { ...COOKIES_BASIC_CONSENT })
    }

    storage.set('bm_cookie_consent', { ...COOKIES_BASIC_CONSENT })

    if (mode) {
      invokeTagManagerConsentUpdateScript(false, false)
      if (isEUCountry(country as string)) pushCookieConsentUpdateEvent()
    }
  },

  setCustomConsent(consent: CookieConsent, country: string): void {
    if (typeof document !== 'undefined') {
      setCookie('bm_cookie_consent', { ...consent })
    }

    storage.set('bm_cookie_consent', { ...consent })

    invokeTagManagerConsentUpdateScript(
      consent.cookieMarketing,
      consent.cookiePerformance,
    )
    if (isEUCountry(country as string)) pushCookieConsentUpdateEvent()
  },

  setConsentGiven(): void {
    if (typeof document !== 'undefined') {
      setCookie('bm_cookie_consent_given', true)
    }

    storage.set('bm_cookie_consent_given', true)
  },

  stashCurrentConsent(): void {
    const stash = storage.get('bm_cookie_consent')

    if (!stash && typeof document !== 'undefined') {
      const newStash = getCookie('bm_cookie_consent')
      setCookie('bm_cookie_consent_stash', { ...newStash })
    }

    storage.set('bm_cookie_consent_stash', { ...stash })
  },

  applyConsentStash(country: string): void {
    const stash = storage.get('bm_cookie_consent_stash')
    storage.set('bm_cookie_consent', { ...stash })
    invokeTagManagerConsentUpdateScript(
      stash.cookieMarketing,
      stash.cookiePerformance,
    )

    if (typeof document !== 'undefined') {
      const newStash = getCookie('bm_cookie_consent_stash')
      setCookie('bm_cookie_consent', { ...newStash })
      invokeTagManagerConsentUpdateScript(
        newStash.cookieMarketing,
        newStash.cookiePerformance,
      )
    }

    if (isEUCountry(country as string)) pushCookieConsentUpdateEvent()
    this.clearConsentStash()
  },

  clearConsentStash(): void {
    if (typeof document !== 'undefined') {
      document.cookie = `bm_cookie_consent_stash;path=/;max-age=${0}`
    }

    storage.remove('bm_cookie_consent_stash')
  },
}

import { POPUP_SHOW, POPUP_HIDE } from 'client/shared/reducers/popup-reducer'
import { AUTH_FORM_LOGIN_SUCCESS } from 'client/shared/reducers/auth-reducer'

let callback = null

export default function authCallbackMiddleware() {
  return function authCallbackNextHandler(next) {
    return function authCallbackActionHandler(action) {
      if (action.type === POPUP_SHOW) {
        callback = action.callback
      }

      if (
        action.type === AUTH_FORM_LOGIN_SUCCESS &&
        typeof callback === 'function'
      ) {
        callback()
        callback = null
      }

      if (action.type === POPUP_HIDE) {
        callback = null
      }

      return next(action)
    }
  }
}

import React from 'react'
import noop from 'lodash/noop'

export { RadioTogglerOption } from './radio-toggler-option'

import './toggler.styl'

type Props = {
  name: string
  component: (props: any) => JSX.Element
  values: { element: JSX.Element | Element; value: string | number }[]
  checkedIndex?: number
  onChange: (number) => void
  hideChecker?: boolean
  className?: string
  labelClassName?: string
}
export class Toggler extends React.Component<Props> {
  static defaultProps = {
    checkedIndex: 0,
    onChange: noop,
  }

  state = {
    checkedIndex: 0,
  }

  static getDerivedStateFromProps(
    nextProps: Props,
    prevState: Props,
  ): { checkedIndex?: number } | null {
    const { checkedIndex } = nextProps

    if (checkedIndex !== prevState.checkedIndex) {
      return { checkedIndex }
    } else {
      return null
    }
  }

  handleChange(checkedIndex: number): void {
    const { values, onChange } = this.props
    onChange(values[checkedIndex].value)
    this.setState({ checkedIndex })
  }

  render(): JSX.Element {
    const { name, values, className, labelClassName, hideChecker } = this.props
    const { checkedIndex } = this.state

    return (
      <div className={className ? `${className} toggler` : 'toggler'}>
        {values.map(({ value, element }, i) => {
          const id = `${name}_${i}`

          return (
            <label
              htmlFor={id}
              key={1}
              className={`toggler__label${
                labelClassName ? ` ${labelClassName}` : ''
              }`}
            >
              <input
                type="radio"
                key={0}
                className="toggler__input"
                name={name}
                value={value}
                id={id}
                checked={i === checkedIndex}
                onChange={() => this.handleChange(i)}
              />
              {this.renderElementWithUpdatedProps({
                element,
                checkedIndex,
                currentIndex: i,
                hideChecker,
              })}
            </label>
          )
        })}
      </div>
    )
  }

  renderElementWithUpdatedProps({
    element,
    checkedIndex,
    currentIndex,
    hideChecker,
  }: {
    element: JSX.Element | Element
    checkedIndex: number
    currentIndex: number
    hideChecker?: boolean
  }): JSX.Element {
    const { component: Component } = this.props
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component
        isSelected={checkedIndex === currentIndex}
        hideChecker={hideChecker}
      >
        {element}
      </Component>
    )
  }
}

import camelCase from 'lodash/camelCase'

import pickAndMapKeys from 'shared/tools/utilities/pick-and-map-keys'

export default {
  getRemoteAddress(req) {
    return req
      ? req.headers['x-forwarded-for'] || req.connection.remoteAddress
      : ''
  },

  getAcceptLanguage(req) {
    return req && req.headers['accept-language']
  },

  getCookies(req) {
    return (req && req.headers.cookie) || ''
  },

  getUserAgent(req) {
    return (req && req.headers['user-agent']) || ''
  },

  getLocale(req) {
    const headerAcceptLanguage = this.getAcceptLanguage(req)
    const lang = headerAcceptLanguage
      ? headerAcceptLanguage?.split(';')[0]?.split(',')[1]
      : ''

    return lang
  },

  getUserTokens(req) {
    // possible tokens passed to vanguard in query parameters
    const userTokenNames = [
      'sign_in_token',
      'access_token',
      'auth_token',
      'revoke_access_token',
    ]
    return pickAndMapKeys(
      {
        path: 'query',
        keys: userTokenNames,
        modifier: camelCase,
      },
      req,
    )
  },

  getRequestID(req) {
    return (req && req.headers['x-request-id']) || ''
  },
}

import { createSelector } from '@reduxjs/toolkit'
import { denormalize } from 'normalizr'
import {
  bookSchema,
  booksSchema,
} from 'client/bookmate/reducers/schemas/schemas'

const idSelector = (_state, id) => id
const idsSelector = (_state, ids) => ids
const entitiesSelector = state => state.entities

export const getBooksByIds = createSelector(
  idsSelector,
  entitiesSelector,
  (ids, entities) => denormalize(ids, booksSchema, entities),
)

export const getBookById = createSelector(
  idSelector,
  entitiesSelector,
  (id, entities) => denormalize(id, bookSchema, entities) || {},
)

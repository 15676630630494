import get from 'lodash/get'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { push } from 'react-router-redux'

import urlFor from 'shared/tools/url-helper'
import storage from 'client/shared/helpers/storage'
import { prepareSubscriptionAnalyticsPayload } from 'client/shared/helpers/analytics-helpers'

import { findProductPlanById } from 'client/bookmate/selectors/subscription-selectors'

import {
  loadPlans,
  loadUserAccessLevels,
} from 'client/bookmate/reducers/subscription-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'
import {
  analyticsEvent,
  SUBSCRIPTION_SUCCESSFUL,
  SUBSCRIPTION_FAILED,
} from 'client/shared/reducers/analytics-reducer'

import {
  SELECTED_PAYPAL_PLAN,
  PATH_FOR_SUCCESS_PAGE,
} from 'client/shared/constants/storage-constants'

const CREATE_PAYPAL_AGREEMENT = 'CREATE_PAYPAL_AGREEMENT'
const CREATE_PAYPAL_AGREEMENT_SUCCESS = 'CREATE_PAYPAL_AGREEMENT_SUCCESS'
const CREATE_PAYPAL_AGREEMENT_ERROR = 'CREATE_PAYPAL_AGREEMENT_ERROR'

const COMPLETE_PAYPAL_PAYMENT = 'COMPLETE_PAYPAL_PAYMENT'
const COMPLETE_PAYPAL_PAYMENT_SUCCESS = 'COMPLETE_PAYPAL_PAYMENT_SUCCESS'
const COMPLETE_PAYPAL_PAYMENT_ERROR = 'COMPLETE_PAYPAL_PAYMENT_ERROR'

type PaypalApiError = {
  message?: string // field that can be shown to the user
  description?: string // field with debug info for developer
}

// response of our backend upon successful purchase of subscription
type PaypalSuccessResponse = {
  status: 'success' | 'success_with_reminder'
}

type CreatePaypalAgreementAction = {
  type: typeof CREATE_PAYPAL_AGREEMENT
}

type CreatePaypalAgreementSuccessAction = {
  type: typeof CREATE_PAYPAL_AGREEMENT_SUCCESS
}

type CreatePaypalAgreementErrorAction = {
  type: typeof CREATE_PAYPAL_AGREEMENT_ERROR
}

type CompletePaypalPaymentAction = {
  type: typeof COMPLETE_PAYPAL_PAYMENT
}

type CompletePaypalPaymentSuccessAction = {
  type: typeof COMPLETE_PAYPAL_PAYMENT_SUCCESS
}

type CompletePaypalPaymentErrorAction = {
  type: typeof COMPLETE_PAYPAL_PAYMENT_ERROR
  error: PaypalApiError | null | undefined // yes, an error response may not contain the error field :-(
}

type Action =
  | CreatePaypalAgreementAction
  | CreatePaypalAgreementSuccessAction
  | CreatePaypalAgreementErrorAction
  | CompletePaypalPaymentAction
  | CompletePaypalPaymentSuccessAction
  | CompletePaypalPaymentErrorAction

type State = {
  loading: boolean
  completionError: string | null | undefined
}

export function createPaypalAgreement(planId: string, path: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/pay_pal/agreements`,
      options: {
        method: 'post',
        data: {
          plan: planId,
        },
      },
      onSuccess: (dispatch, getState, res) => {
        const {
          agreement: { approval_url },
        } = res
        const selectedPlan = findProductPlanById(getState(), planId)
        storeSelectedPaypalPlan(selectedPlan)
        storePath(path)

        window.location.href = approval_url
      },
      onError: dispatch => {
        dispatch(showAlert('error', { message: 'errors.unknown' }))
      },
      types: [
        CREATE_PAYPAL_AGREEMENT,
        CREATE_PAYPAL_AGREEMENT_SUCCESS, // we don't really care about this action
        CREATE_PAYPAL_AGREEMENT_ERROR,
      ],
    },
  }
}

export function completePaypalSubscription(token: string): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/payments/pay_pal/subscriptions`,
      options: {
        method: 'post',
        data: {
          token,
        },
      },
      onSuccess: (dispatch, getState, response: PaypalSuccessResponse) => {
        const { status } = response
        dispatch(loadUserAccessLevels())
        dispatch(loadPlans())

        const query: { reminder?: boolean } = {}
        if (status === 'success_with_reminder') {
          query.reminder = true
        }
        const plan = retrieveStoredPaypalPlan()
        const analyticsPayload = prepareSubscriptionAnalyticsPayload(plan)
        const succesPageParams = {
          path: retrievePath(),
          type: plan.trial ? 'trial' : 'subsc',
        }

        dispatch(analyticsEvent(SUBSCRIPTION_SUCCESSFUL, analyticsPayload))
        dispatch(
          push(urlFor.subscriptionSuccess({ ...query, ...succesPageParams })),
        )
      },
      onError: (dispatch, getState, error) => {
        const errorMessage =
          get(error, 'error.message') ||
          get(error, 'error.description', 'unknown')
        const analyticsPayload = prepareSubscriptionAnalyticsPayload(
          retrieveStoredPaypalPlan(),
          errorMessage,
        )
        dispatch(analyticsEvent(SUBSCRIPTION_FAILED, analyticsPayload))
      },
      types: [
        COMPLETE_PAYPAL_PAYMENT,
        COMPLETE_PAYPAL_PAYMENT_SUCCESS,
        COMPLETE_PAYPAL_PAYMENT_ERROR,
      ],
    },
  }
}

function storeSelectedPaypalPlan(plan) {
  if (plan) {
    try {
      storage.set(SELECTED_PAYPAL_PLAN, plan, 'session')
    } catch (e) {
      // browser storage is full for some reason; can't be helped
    }
  }
}

function storePath(path) {
  if (path) {
    try {
      storage.set(PATH_FOR_SUCCESS_PAGE, path, 'session')
    } catch (e) {
      // browser storage is full for some reason; can't be helped
    }
  }
}

function retrievePath() {
  return storage.get(PATH_FOR_SUCCESS_PAGE)
}

function retrieveStoredPaypalPlan() {
  return storage.get(SELECTED_PAYPAL_PLAN)
}

function getCompletionError(error: PaypalApiError | null | undefined) {
  // return either the user-friendly error message that came from the API,
  // or, lacking that, our internationalization key for a generic error message
  if (error && error.message) {
    return error.message
  } else {
    return 'errors.unknown'
  }
}

const initialState = {
  loading: false,
  completionError: null,
}

export default function paypalReducer(
  state: State = initialState,
  action: Action,
): State {
  switch (action.type) {
    case CREATE_PAYPAL_AGREEMENT:
    case COMPLETE_PAYPAL_PAYMENT:
      return {
        ...state,
        loading: true,
      }

    case COMPLETE_PAYPAL_PAYMENT_ERROR:
      return {
        ...state,
        completionError: getCompletionError(action.error),
        loading: false,
      }

    case COMPLETE_PAYPAL_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CREATE_PAYPAL_AGREEMENT_ERROR:
      return initialState

    default:
      return state
  }
}

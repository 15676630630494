const PERSONAL_CODE_SHARING_DATA_LOAD_SUCCESS =
  'PERSONAL_CODE_SHARING_DATA_LOAD_SUCCESS'

const initialState = {}

export default function personalCodeSharing(state = initialState, action) {
  switch (action.type) {
    case PERSONAL_CODE_SHARING_DATA_LOAD_SUCCESS:
      return { ...state, ...action.data }
    default:
      return state
  }
}

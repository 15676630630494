import React, { PureComponent } from 'react'

import { Trans, withI18n, withI18nProps } from '@lingui/react'

import './footer-notice.styl'

type Props = {
  kind: 'legal' | 'seo'
} & withI18nProps

class FooterNotice extends PureComponent<Props> {
  static defaultProps = {
    kind: 'legal',
  }

  render(): JSX.Element | null {
    const { kind, i18n } = this.props

    switch (kind) {
      case 'legal':
        return (
          <div
            className="footer-notice"
            dangerouslySetInnerHTML={{ __html: i18n.t`footer.legal_notice` }}
          />
        )
      case 'seo':
        return (
          <div className="footer-notice">
            <Trans
              id="footer.seo_keyworded_text"
              components={[<>&nbsp;</>, <>&nbsp;</>, <>&nbsp;</>]}
            />
          </div>
        )
      default:
        return null
    }
  }
}

export default withI18n({ update: true })(FooterNotice)

import React, { Component } from 'react'
import get from 'lodash/get'

import './user-avatar.styl'

import uiBox, { DecoratorProps } from 'client/shared/decorators/ui-box'

import { CurrentUserData } from 'client/shared/types/current-user'
import { AuthorProps } from 'client/shared/types/author'
import { Avatar, UserShort } from 'client/shared/types/user'
import { Activist } from 'client/shared/types/notification'
import { Size } from 'client/shared/types/avatar'
import { CreatorProps as Creator } from 'client/shared/types/creator'
import ProgressiveCover from 'client/shared/blocks/progressive-cover'

type MinimalAvatarProps = {
  name: string
  avatar_url: string
  avatar: Avatar
}

type Props = {
  user:
    | UserShort
    | CurrentUserData
    | AuthorProps
    | Activist
    | Creator
    | MinimalAvatarProps
  size: Size
  lazyLoading: boolean
} & DecoratorProps

export const AVATAR_LG = 40
export const AVATAR_SM = 24
export const AVATAR_MD = 32

class _UserAvatar extends Component<Props> {
  static defaultProps = {
    size: AVATAR_LG,
    lazyLoading: true,
  }

  getSizeKind() {
    const { app, size } = this.props

    return typeof size === 'object' ? size[app.size] : size
  }

  getSrc() {
    const { user } = this.props

    // CurrentUserData
    if (user.avatar_url) {
      return user.avatar_url
    }

    // AuthorProps
    if (get(user, 'image.large') != null) {
      return get(user, 'image.large')
    }

    // UserShort
    if (user.avatar && user.avatar.large && user.avatar.small) {
      const { small, large } = user.avatar
      return [24, 32, 40].includes(this.getSizeKind()) ? small : large
    }

    return ''
  }

  getAlt() {
    const { user } = this.props

    if (user.name) {
      return user.name
    } else if (user.login) {
      return user.login
    } else {
      return ''
    }
  }

  render(): JSX.Element {
    const size = this.getSizeKind()
    return (
      <div className={`user-avatar user-avatar_${size}`}>
        {this.props.lazyLoading ? (
          <ProgressiveCover
            src={this.getSrc()}
            placeholder={''}
            width={size as Size}
            height={size as Size}
            alt={this.getAlt()}
          />
        ) : (
          <img src={this.getSrc()} alt={this.getAlt()} />
        )}
      </div>
    )
  }
}

export const UserAvatar = uiBox(_UserAvatar)

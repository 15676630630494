import React, { Component } from 'react'

import './library-posts.styl'

import uiBox from 'client/shared/decorators/ui-box'

import { UserAvatar } from 'client/shared/blocks/user-avatar'
import { BookCover } from 'client/shared/blocks/book-cover'
import Slider from 'client/shared/blocks/slider'
import Linka from 'client/shared/blocks/linka'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import TextTruncate from 'client/shared/blocks/text-truncate'
import PlaceholderBackground from 'client/shared/blocks/placeholder-background'
import { PostProps } from 'client/shared/types/post'

type Props = {
  posts: PostProps[]
  query?: QueryParams
}

class _LibraryPosts extends Component<Props> {
  annotationTruncateOptions() {
    return { lines: 3 }
  }

  titleTruncateOptions() {
    return { lines: 2 }
  }

  render(): JSX.Element {
    const { posts } = this.props

    return (
      <Slider
        components={posts.map((post, index) => this.renderPost(post, index))}
      />
    )
  }

  renderPost(post, index) {
    const {
      annotation,
      book,
      bookshelf: { uuid: bookshelfUuid },
      creator,
      uuid: postUuid,
    } = post
    const { name: creatorName, login: creatorLogin } = creator
    const { title, cover } = book

    return (
      <div key={index} className="library-posts__post">
        <Linka
          path={urlFor.shelfPost(bookshelfUuid, postUuid, '', {
            ...this.props.query,
          })}
          className="library-posts__fake-link"
          id={`featured-post-link_${postUuid}`}
          aria-label="post"
        />
        <div className="library-posts__content-container">
          <div className="library-posts__book-cover-container">
            <BookCover
              book={book}
              size={{
                desktopLarge: 176,
                desktopMedium: 176,
                desktop: 176,
                tablet: 176,
                mobile: 120,
              }}
            />
          </div>
          <div className="library-posts__text-container">
            <div className="library-posts__book-title">
              <TextTruncate
                {...this.titleTruncateOptions()}
                text={title}
                textType="title"
              />
            </div>
            <div className="library-posts__annotation">
              <TextTruncate
                {...this.annotationTruncateOptions()}
                text={annotation}
              />
            </div>
            <div
              className="library-posts__attribution-container"
              onClick={e => e.stopPropagation()}
            >
              <Linka path={urlFor.user(creatorLogin, this.props.query)}>
                <UserAvatar user={creator} size={24} />
              </Linka>
              <Linka
                aria-label={creatorName || creatorLogin}
                path={urlFor.user(creatorLogin, this.props.query)}
                className="library-posts__attribution-link"
              >
                <span className="library-posts__attribution">
                  {creatorName || creatorLogin}
                </span>
              </Linka>
            </div>
          </div>
        </div>
        <div className="library-posts__image-background">
          <PlaceholderBackground placeholder={cover.placeholder || ''} />
        </div>
        <div className="library-posts__darkened-background"></div>
      </div>
    )
  }
}

export const LibraryPosts = uiBox(_LibraryPosts)

import { denormalize } from 'normalizr'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'

import { CALL_API } from 'shared/middlewares/api-middleware'
import { constants } from 'client/bookmate/constants'
import { getBookById } from 'client/bookmate/selectors/book-selector'
import {
  analyticsEvent,
  BOOK_ADDED,
  OBJECT_REMOVED,
} from 'client/shared/reducers/analytics-reducer'

import { serialChanged } from 'client/bookmate/reducers/serial-reducer'

import { loadCounters } from 'client/bookmate/reducers/user-reducer'

import {
  libraryCardSchema,
  libraryCardsSchema,
} from 'client/bookmate/reducers/schemas/schemas'

import {
  LIBRARY_CARD_LOAD,
  LIBRARY_CARD_CREATED,
  LIBRARY_CARD_CHANGED,
  LIBRARY_CARD_REMOVED,
} from 'client/shared/constants/library-cards-constants'

import { loadBookAction } from './loadBookAction'

import { checkEntities } from 'client/bookmate/helpers/entities-helper'

export function getLibraryCardsByIds(state, ids) {
  return denormalize(ids, libraryCardsSchema, state.entities) || []
}

export function getLibraryCardById(state, id) {
  return denormalize(id, libraryCardSchema, state.entities) || {}
}

export function createLibraryCard(libraryCardState, book) {
  const bookUuid = book.book_uuid || book.uuid
  const { bookshelfId } = book // a book object coming from a book card on a shelf will have this
  const data = {
    lc: libraryCardState,
    book_uuid: bookUuid,
  }

  if (bookshelfId) {
    data.book = { bookshelf_id: bookshelfId }
  }

  const analytics = {
    state: libraryCardState.state ? libraryCardState.state : 'not_finished',
    is_public:
      typeof libraryCardState.public === 'boolean'
        ? libraryCardState.public
        : true,
    auto: false,
    book_id: bookUuid,
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/library_cards`,
      options: {
        method: 'post',
        data,
      },
      schema: libraryCardSchema,
      responseKey: 'library_card',
      onSuccess: (dispatch, getState, response) => {
        dispatch(analyticsEvent(BOOK_ADDED, analytics))
        if (book.resourceType === 'serial') {
          dispatch(
            serialChanged({
              reason: 'added',
              serial: {
                uuid: bookUuid,
                library_card: response.result,
                in_library: true,
                library_card_uuid: response.result,
              },
            }),
          )
        } else {
          dispatch({
            type: constants.BOOK_CHANGED,
            reason: 'added',
            book: {
              uuid: bookUuid,
              library_card: response.result,
              in_library: true,
              library_card_uuid: response.result,
            },
          })
        }
      },
      types: [LIBRARY_CARD_LOAD, LIBRARY_CARD_CREATED],
    },
  }
}

export function updateLibraryCard(libraryCardState = {}) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/library_cards/${libraryCardState.uuid}`,
      options: {
        method: 'put',
        data: {
          lc: libraryCardState,
        },
      },
      schema: libraryCardSchema,
      responseKey: 'library_card',
      onSuccess: async (dispatch, getState, response) => {
        const {
          data: { login },
        } = getState().currentUser
        await dispatch(loadCounters(login)) // update counters after library card was updated
        dispatch({
          type: LIBRARY_CARD_CHANGED,
          entities: response.entities,
          data: libraryCardState,
        })
      },
      types: [LIBRARY_CARD_LOAD],
    },
  }
}

function removeLibraryCard(libraryCardState = {}, bookUuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/profile/library_cards/${libraryCardState.uuid}`,
      options: {
        method: 'delete',
        dontParse: true,
      },
      types: [LIBRARY_CARD_LOAD],
      onSuccess: async (dispatch, getState) => {
        const {
          data: { login },
        } = getState().currentUser

        await dispatch(loadCounters(login)) // update counters after library card was removed
        dispatch({
          type: LIBRARY_CARD_REMOVED,
          data: libraryCardState,
        })
        dispatch({
          type: constants.BOOK_CHANGED,
          reason: 'removed',
          book: {
            uuid: bookUuid,
            library_card: null,
            library_card_uuid: null,
            in_library: false,
          },
        })
        dispatch(
          analyticsEvent(OBJECT_REMOVED, {
            object_type: 'book',
            object_id: bookUuid,
          }),
        )
      },
    },
  }
}

export const removeSerialEposidesLibraryCards = ({ episodes }) => (
  dispatch,
  getState,
) => {
  const state = getState()
  episodes.forEach(uuid => {
    const episode = getBookById(state, uuid)
    if (episode.library_card) {
      dispatch(removeLibraryCard(episode.library_card, uuid))
    }
  })
}

export function removeLibraryCardGivenBook(book) {
  return async (dispatch, getState) => {
    try {
      if (!book.library_card_uuid) {
        await dispatch(loadBookAction(book.uuid))
        book = getBookById(getState(), book.uuid)
      }
      return dispatch(
        removeLibraryCard({ uuid: book.library_card_uuid }, book.uuid),
      )
    } catch (error) {
      throw error
    }
  }
}

export default function libraryCards(state = {}, action) {
  const mergedEntities = checkEntities(state, action, 'libraryCards')

  if (!isEmpty(mergedEntities)) {
    return mergedEntities
  }

  switch (action.type) {
    case LIBRARY_CARD_REMOVED:
      return omit(state, action.data.uuid)

    default:
      return state
  }
}

import React, { Component } from 'react'
import { withI18n, withI18nProps } from '@lingui/react'
import { connect } from 'react-redux'

import compose from 'lodash/fp/compose'

import {
  unfollow,
  turnOnNotifications,
  turnOffNotifications,
} from 'client/bookmate/reducers/series-list-reducer'
import { showAlert } from 'client/shared/reducers/alert-reducer'

import { ContextMenuButton } from 'client/shared/blocks/context-menu-button'

import { SeriesProps } from 'client/shared/types/series'
import { Dispatch, State } from 'shared/types/redux'
import { MenuItem } from './audiobook-context-menu-box'

type Props = {
  series: SeriesProps
  dispatch: Dispatch
  auth: boolean
  kind: 'square' | 'expand'
  onStateChange: () => void
} & withI18nProps

class BookContextMenuBox extends Component<Props> {
  getMenuData() {
    const { i18n, series, kind } = this.props

    const items: MenuItem[] = []

    if (series.following) {
      items.push({
        title: i18n.t`series.unfollow_series`,
        action: this.onUnfollowClick,
        actionName: 'unfollow-series',
      })
    }

    if (kind === 'square') {
      if (series.notifications_enabled) {
        items.push({
          title: i18n.t`series.turn_off_notifications`,
          action: this.onTurnOffNotifications,
          actionName: 'turn-off-notifications',
        })
      } else {
        items.push({
          title: i18n.t`series.turn_on_notifications`,
          action: this.onTurnOnNotifications,
          actionName: 'turn-on-notifications',
        })
      }
    }

    return { items }
  }

  onUnfollowClick = () => {
    const { series, dispatch, onStateChange } = this.props

    dispatch(unfollow(series.uuid))

    if (onStateChange) {
      onStateChange()
    }
  }

  onTurnOnNotifications = () => {
    const { series, dispatch, i18n } = this.props

    dispatch(
      turnOnNotifications(
        series.uuid,
        this.showSuccessAlert(i18n.t`series.turn_on_notifications_message`),
      ),
    )
  }

  onTurnOffNotifications = () => {
    const { series, dispatch, i18n } = this.props

    dispatch(
      turnOffNotifications(
        series.uuid,
        this.showSuccessAlert(i18n.t`series.turn_off_notifications_message`),
      ),
    )
  }

  showSuccessAlert = message => {
    const { dispatch } = this.props

    return () => dispatch(showAlert('success', { message }))
  }

  render() {
    const { kind } = this.props

    return <ContextMenuButton menuData={this.getMenuData()} kind={kind} />
  }
}

const wrapper = compose(
  connect((state: State) => ({
    auth: state.currentUser.auth,
  })),
  withI18n({ update: true }),
)

export default wrapper(BookContextMenuBox)

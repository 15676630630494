import { $Diff } from 'utility-types'

import React, { Component, ComponentType, ElementConfig } from 'react'
import { connect } from 'react-redux'

import errorsHelper from 'client/shared/helpers/errors-helper'

import { State } from 'shared/types/redux'

type ComponentState = {
  hasError: boolean
}

export type ErrorBoundaryProps = {
  username: string | void
  url: string | void
}

export default function errorBoundary<
  InProps extends ErrorBoundaryProps,
  Com extends ComponentType<InProps>,
  OutProps extends $Diff<ElementConfig<Com>, ErrorBoundaryProps>
>(DecoratedComponent: Com): ComponentType<OutProps> {
  class ErrorBoundary extends Component<ElementConfig<Com>, ComponentState> {
    state = {
      hasError: false,
    }

    componentDidCatch(error: Error) {
      errorsHelper.setContext({
        username: this.props.username,
        url: this.props.url,
      })
      errorsHelper.report(error)
      this.setState({ hasError: true })
    }

    render() {
      if (this.state.hasError) {
        // TODO: show some message to the effect that there was an error rendering this component
        return null
      } else {
        return <DecoratedComponent {...this.props} />
      }
    }
  }

  const connectWrapper = connect((state: State) => ({
    username: state.currentUser.data.login,
    url: state.app.url,
  }))

  return connectWrapper(ErrorBoundary)
}

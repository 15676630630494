import { schema } from 'normalizr'
import noop from 'lodash/noop'
import omit from 'lodash/omit'
import animateScrollTo from 'animated-scroll-to'

import { ApiAction, CALL_API } from 'shared/middlewares/api-middleware'

import { userSchema } from 'client/bookmate/reducers/schemas/schemas'
import { getUserById } from 'client/bookmate/reducers/users-reducer'
import { LIKE_BUTTON_PRESSED } from 'client/shared/reducers/analytics-reducer'

import { throttledLikeHelper } from 'shared/tools/api-helpers'
import { Dispatch } from 'shared/types/redux'
import { FetchMethod } from 'shared/tools/fetch-wrapper'

const commentSchema = new schema.Entity(
  'comments',
  {
    creator: userSchema,
  },
  { idAttribute: 'uuid' },
)

const SCROLL_TO_OFFSET = 100

const commentsSchema = new schema.Array(commentSchema)

const COMMENTS_LOADING = 'COMMENTS_LOADING'
const COMMENTS_LOADED = 'COMMENTS_LOADED'
const COMMENT_LIKE = 'COMMENT_LIKE'
const COMMENT_LIKED = 'COMMENT_LIKED'
const COMMENT_REMOVE = 'COMMENT_REMOVE'
export const COMMENT_REMOVED = 'COMMENT_REMOVED'
const COMMENT_ADD = 'COMMENT_ADD'
export const COMMENT_ADDED = 'COMMENT_ADDED'

export function getCommentsByIds(state, ids) {
  return ids.map(id => getCommentById(state, id))
}

function getCommentById(state, id) {
  const comment = state.entities.comments[id]
  if (comment) {
    return {
      ...comment,
      creator: getUserById(state, comment.creator),
    }
  }
}

export function load(resourceType, resourceUuid) {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/${resourceType}/${resourceUuid}/comments`,
      schema: commentsSchema,
      responseKey: 'comments',
      types: [COMMENTS_LOADING, COMMENTS_LOADED],
    },
  }
}

export type LikeToggleProps = {
  uuid: string
  liked: boolean
  likes_count: number
  dispatch?: Dispatch
}

function toggleLike({ uuid, liked }: LikeToggleProps): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/comments/${uuid}/likers`,
      options: {
        method: liked ? 'delete' : 'post',
        dontParse: true,
      },
      modifyResponse: () => ({
        uuid,
        liked,
        meta: {
          analytics: {
            type: LIKE_BUTTON_PRESSED,
            payload: {
              object_type: 'comment',
              object_id: uuid,
            },
          },
        },
      }),
      types: [COMMENT_LIKE, COMMENT_LIKED],
    },
  }
}

export const throttledToggleLike = throttledLikeHelper(
  toggleLike,
  COMMENT_LIKED,
)

export function remove({
  uuid,
  resourceUuid,
}: {
  uuid: string
  resourceUuid: string
}): ApiAction {
  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/comments/${uuid}`,
      options: {
        method: 'delete' as FetchMethod,
        dontParse: true,
      },
      modifyResponse: () => ({ uuid, resourceUuid }),
      types: [COMMENT_REMOVE, COMMENT_REMOVED],
    },
  }
}

export function add(data, cb = noop): ApiAction {
  const { resourceType, resourceUuid, content, parent } = data

  let postData = {
    comment: { content },
  }

  if (parent) {
    postData = {
      ...postData,
      comment: {
        ...postData.comment,
        parent_uuid: parent.uuid,
      },
    }
  }

  return {
    [CALL_API]: {
      endpoint: `/p/api/v5/${resourceType}/${resourceUuid}/comments`,
      options: {
        method: 'post',
        data: postData,
      },
      schema: commentSchema,
      responseKey: 'comment',
      modifyResponse: response => ({
        resourceUuid,
        uuid: response.result,
      }),
      onSuccess: () => {
        cb()

        const _comments = document.querySelectorAll('.card_thread')
        if (_comments.length) {
          animateScrollTo(
            _comments[_comments.length - 1].offsetTop - SCROLL_TO_OFFSET,
            { speed: 1000 },
          )
        }
      },
      types: [COMMENT_ADD, COMMENT_ADDED],
    },
  }
}

const initialState = {
  ids: [],
}

export default function comments(state = initialState, action) {
  switch (action.type) {
    case COMMENTS_LOADING:
      return {
        ...state,
        ids: [],
      }

    case COMMENTS_LOADED:
      return {
        ...state,
        ...action.entities.comments,
        ids: action.result,
      }

    case COMMENT_REMOVED: {
      const newState = omit(state, action.uuid)

      return {
        ...newState,
        ids: state.ids.filter(id => id !== action.uuid),
      }
    }

    case COMMENT_LIKED: {
      const comment = state[action.uuid]

      if (action.liked !== comment.liked) {
        return state
      }

      return {
        ...state,
        [action.uuid]: {
          ...comment,
          likes_count: action.liked
            ? comment.likes_count - 1
            : comment.likes_count + 1,
          liked: !action.liked,
        },
      }
    }

    case COMMENT_ADDED: {
      let { comments: _comments } = action.entities
      _comments = Object.keys(_comments).reduce((acc, uuid) => {
        acc[uuid] = {
          ..._comments[uuid],
          recentlyAdded: true,
        }
        return acc
      }, {})

      return {
        ...state,
        ..._comments,
        ids: [...state.ids, action.uuid],
      }
    }

    default:
      return state
  }
}

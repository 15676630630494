import React, { useEffect } from 'react'

type Props = {
  onLoadApplePayButton: () => void
  onUnmountApplePay?: () => void
}

export const LinkPayButton: React.FC<Props> = ({
  onLoadApplePayButton,
  onUnmountApplePay,
}) => {
  useEffect(() => {
    onLoadApplePayButton()
    return () => {
      if (onUnmountApplePay) onUnmountApplePay()
    }
  }, [onLoadApplePayButton, onUnmountApplePay])

  return <div id="payment-request-button" />
}

import React, { Component } from 'react'

import { PaymentSystem as PaymentSystemType } from 'client/shared/types/subscription'

import './payment-system.styl'

type Props = {
  system: PaymentSystemType
}

export class PaymentSystem extends Component<Props> {
  render(): JSX.Element {
    const { system } = this.props

    return (
      <div className={`payment-system__icon payment-system__icon_${system}`} />
    )
  }
}

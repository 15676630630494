import { BookProps } from './book'
import { ComicbookProps } from './comicbook'
import { AudiobookProps } from './audiobook'
import { ShelfProps } from './shelf'
import { PostProps } from './post'
import { LibraryBannerProps } from './library-banner'
import { ResourceName, ShowcaseResource } from './resource'
import { SeriesProps } from './series'

import { Exact } from 'types/local/exact-check'
import { Locale } from './current-user'

export const TYPE_BOOK = 'book'
export const TYPE_SERIAL = 'serial'
export const TYPE_AUDIOBOOK = 'audiobook'
export const TYPE_COMICBOOK = 'comicbook'
export const TYPE_SERIES = 'series'
export const TYPE_BOOKSHELF = 'bookshelf'
export const TYPE_POST = 'post'
export const TYPE_LIBRARY = 'library'
export const TYPE_MIXED = 'mixed'
export const TYPE_BANNER = 'banner'

export type ContentType = ResourceName | typeof TYPE_MIXED

export type TabProps = {
  link: string
  title: string
  active: boolean
}

export type ShowcaseAttributes = {
  language: Locale
  slug: string
  contentType: ShowcaseResource
}

export type Navigation = {
  title: string
  type: 'showcase' | 'topic'
  params: {
    uuid: string
    slug: string
    content_type: ContentType
    language: string
  }
}

export type RootNavigation = {
  language: string
  items: Navigation[]
  loading: boolean
}

type CommonSection = {
  readonly id: number
  readonly all_url: string
  readonly language: string
  readonly slug: string
  readonly tag: string
  readonly title: string
  readonly page?: number | null | undefined
  readonly loading?: boolean
  readonly showcase_tag: string
}

export type SectionWithBooks = Exact<CommonSection> & {
  readonly objects: BookProps[]
  readonly objects_type: typeof TYPE_BOOK
  readonly uuid?: string
}

export type SectionWithAudiobooks = Exact<CommonSection> & {
  readonly objects: AudiobookProps[]
  readonly objects_type: typeof TYPE_AUDIOBOOK
  readonly uuid?: string
}

export type SectionWithComicbooks = Exact<CommonSection> & {
  readonly objects: ComicbookProps[]
  readonly objects_type: typeof TYPE_COMICBOOK
  readonly uuid?: string
}

export type SectionWithSeries = Exact<CommonSection> & {
  readonly objects: SeriesProps[]
  readonly objects_type: typeof TYPE_SERIES
  readonly uuid?: string
}

export type SectionWithShelves = Exact<CommonSection> & {
  readonly objects: ShelfProps[]
  readonly objects_type: typeof TYPE_BOOKSHELF
  readonly uuid?: string
}

export type SectionWithPost = Exact<CommonSection> & {
  readonly objects: PostProps[]
  readonly objects_type: typeof TYPE_POST
  readonly uuid?: string
}

export type SectionWithBanner = Exact<CommonSection> & {
  readonly objects: LibraryBannerProps[]
  readonly objects_type: typeof TYPE_BANNER
}

export type ShowcaseSection =
  | SectionWithBooks
  | SectionWithAudiobooks
  | SectionWithComicbooks
  | SectionWithSeries
  | SectionWithShelves
  | SectionWithPost
  | SectionWithBanner

export type ShowcaseHierarchy = {
  content_type: ResourceName
  language: string
  parent_slug: string | null | undefined
  slug: string
  title: string
}

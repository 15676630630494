import React, { Component } from 'react'

import SVGInline from 'react-svg-inline'

import ContextMenu, {
  ContextMenuDataProps,
} from 'client/shared/blocks/context-menu'

import MoreIcon from 'client/shared/icons/more.svg'
import ExpandIcon from 'client/shared/icons/expand.svg'

import Button from 'client/shared/blocks/button'

export type MenuKind = 'default' | 'square' | 'expand'

type Props = {
  kind: MenuKind
  menuData: ContextMenuDataProps
}

export class ContextMenuButton extends Component<Props> {
  static defaultProps = {
    kind: 'default',
  }

  render(): JSX.Element {
    const { menuData, kind } = this.props

    return (
      <Button
        pseudo
        kind="transparent"
        type="inline"
        className={kind === 'default' ? 'button_card-footer' : `button_${kind}`}
      >
        <ContextMenu {...menuData}>
          <SVGInline svg={kind === 'expand' ? ExpandIcon : MoreIcon} />
        </ContextMenu>
      </Button>
    )
  }
}

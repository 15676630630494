import React, { Component } from 'react'
import uiBox from 'client/shared/decorators/ui-box'
import { Dispatch } from 'shared/types/redux'
import noop from 'lodash/noop'

import {
  analyticsEvent,
  SHARE_BUTTON_PRESSED,
} from 'client/shared/reducers/analytics-reducer'

type Props = {
  item: Record<string, any>
  dispatch: Dispatch
  isMobileSize: boolean
}

class DropdownItemBoxComponent extends Component<Props> {
  onItemClick = () => {
    const {
      item: { analytics },
      dispatch,
    } = this.props

    if (analytics) {
      dispatch(analyticsEvent(SHARE_BUTTON_PRESSED, analytics))
    }
  }

  render() {
    const { isMobileSize } = this.props

    return (
      <div
        onClick={this.onItemClick}
        onTouchEnd={isMobileSize ? this.onItemClick : noop}
      >
        {this.props.children}
      </div>
    )
  }
}

export const DropdownItemBox = uiBox(DropdownItemBoxComponent)

import React, { Component } from 'react'
import './upload-file-format.styl'

class UploadFileFormat extends Component {
  render(): JSX.Element {
    return <span className="upload-file-format">{this.props.children}</span>
  }
}

export default UploadFileFormat

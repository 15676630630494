import debounce from 'lodash/debounce'
import uniq from 'lodash/uniq'

import { CALL_API } from 'shared/middlewares/api-middleware'

const FRIENDS_SUGGESTIONS_LOAD = 'FRIENDS_SUGGESTIONS_LOAD'
const FRIENDS_SUGGESTIONS_LOADED = 'FRIENDS_SUGGESTIONS_LOADED'
const FRIENDS_SUGGESTIONS_REMOVE = 'FRIENDS_SUGGESTIONS_REMOVE'
const FRIENDS_SUGGESTIONS_SLICE_HEAD = 'FRIENDS_SUGGESTIONS_SLICE_HEAD'

import { usersSchema } from 'client/bookmate/reducers/schemas/schemas'

export function getFriendsSuggestions(append = false) {
  return {
    [CALL_API]: {
      endpoint: '/p/api/v5/friend_suggestions',
      schema: usersSchema,
      responseKey: 'friend_suggestions',
      modifyResponse: () => ({ append }),
      types: [FRIENDS_SUGGESTIONS_LOAD, FRIENDS_SUGGESTIONS_LOADED],
    },
  }
}

const debouncedGetFriendsSuggestions = debounce(getFriendsSuggestions, 2000, {
  leading: true,
  trailing: false,
})

// removes 1 suggested friend based on his id
export function removeFromSuggestionsList(userId) {
  return {
    type: FRIENDS_SUGGESTIONS_REMOVE,
    userId,
  }
}

/* Removes several friends ids from the head of the suggestions list
 * (accepting as a parameter the number of ids to be removed).
 * Requests a new portion of suggestions if the number of suggestions
 * remaining in the list is less than the number requested for removal
 * (to anticipate the next removal)
 */
export function sliceSuggestionsListHead(headSize) {
  return async (dispatch, getState) => {
    dispatch({
      type: FRIENDS_SUGGESTIONS_SLICE_HEAD,
      headSize,
    })

    const remainingUserIds = getState().friendsSuggestions.userIds

    if (remainingUserIds.length <= headSize * 2) {
      return dispatch(debouncedGetFriendsSuggestions(true))
    }
  }
}

const initialState = {
  userIds: [],
}

export default function friendsSuggestions(state = initialState, action) {
  switch (action.type) {
    case FRIENDS_SUGGESTIONS_LOADED:
      return {
        ...state,
        userIds: action.append
          ? uniq([...state.userIds, ...action.result])
          : action.result,
      }

    case FRIENDS_SUGGESTIONS_REMOVE:
      return {
        ...state,
        userIds: state.userIds.filter(id => id !== action.userId),
      }

    case FRIENDS_SUGGESTIONS_SLICE_HEAD:
      return {
        ...state,
        userIds: state.userIds.slice(action.headSize),
      }

    default:
      return state
  }
}

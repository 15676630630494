import config from 'config'
import noop from 'lodash/noop'

type UnaryFn = (arg0?: any) => void

export const handleRecaptсhaV3 = (
  action: string,
  onSuccess: UnaryFn,
  onError: UnaryFn = noop,
): void => {
  window?.grecaptcha?.ready(function () {
    window.grecaptcha
      .execute(config.recaptchaV3SiteKey, {
        action,
      })
      .then(function (token) {
        onSuccess(token)
      })
      .catch(err => {
        onError(err)
        console.log('Recaptcha verification error', err)
      })
  })
}

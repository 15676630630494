import React, { Component } from 'react'
import { connect } from 'react-redux'

import deviceHelper from 'shared/tools/device-helper'

import StoreButton from 'client/shared/blocks/store-button'

import { State as App } from 'client/shared/reducers/app-reducer'
import { State } from 'shared/types/redux'

type Props = {
  app: App
  platform?: 'ios' | 'android'
  size?: 'big' | 'small'
  inverted?: boolean
  path?: string
  campaign?: string
  stage?: string
  width?: string
  theme?: string
}

class StoreButtonBox extends Component<Props> {
  getPlatform() {
    const { app } = this.props

    if (deviceHelper.isAndroid(app)) {
      return 'android'
    } else {
      return 'ios'
    }
  }

  render() {
    const {
      app,
      platform,
      campaign,
      inverted,
      size,
      stage,
      width,
      theme,
      path,
    } = this.props

    return (
      <StoreButton
        path={path}
        platform={platform || this.getPlatform()}
        inverted={inverted}
        size={size}
        campaign={campaign}
        stage={stage}
        width={width}
        theme={theme}
        app={app}
      />
    )
  }
}

export default connect((state: State) => ({
  app: state.app,
}))(StoreButtonBox)

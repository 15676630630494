import React, { Component, createElement } from 'react'
// for .cjs extension see https://bit.ly/2DVJnTW
import { Spring, SpringConfig } from 'react-spring/renderprops.cjs'

import Pager from './pager'
import Swipe from './swipe'
import { ViewPagerContext } from 'client/bookmate/helpers/view-pager-helper'

type Props = {
  tag: string
  autoSize: boolean | string
  springConfig: SpringConfig
  pager: Pager
}

class Frame extends Component<Props> {
  static defaultProps = {
    tag: 'div',
    autoSize: false,
    // springConfig: presets.noWobble
  }

  state = {
    width: 0,
    height: 0,
    instant: true,
  }

  constructor(props) {
    super(props)

    const { pager, autoSize } = this.props
    pager.setOptions({ autoSize })
    this.swipe = new Swipe(pager)
    this.hydrate = false
  }

  componentDidMount() {
    const { pager } = this.props

    pager.addFrame(this.element)

    // set frame size initially and then based on certain pager events
    this.setFrameSize()

    pager.on('viewChange', this.setFrameSize)
    pager.on('hydrated', this.setFrameSize)
  }

  componentDidUpdate(prevProps) {
    // update options that have changed
    if (this.props.autoSize !== prevProps.autoSize) {
      this.props.pager.setOptions({ autoSize: this.props.autoSize })
      this.props.pager.hydrate()
    }
  }

  setFrameSize = () => {
    const frameSize = this.props.pager.getFrameSize({ fullSize: true })

    if (frameSize.width && frameSize.height) {
      this.setState(frameSize, () => {
        // we need to unset the instant flag now that React Motion has dimensions to animate to
        if (this.state.instant) {
          this.setState({ instant: false })
        }
      })
    }
  }

  getFrameStyle() {
    const { width, height } = this.state
    return {
      maxWidth: width,
      height,
    }
  }

  render() {
    const { autoSize } = this.props
    const style = {
      position: 'relative',
      overflow: 'hidden',
    }

    if (autoSize) {
      return (
        <Spring to={this.getFrameStyle()}>
          {dimensions => {
            if (
              (autoSize === true || autoSize === 'width') &&
              dimensions.maxWidth
            ) {
              style.maxWidth = dimensions.maxWidth
            }
            if (
              (autoSize === true || autoSize === 'height') &&
              dimensions.height
            ) {
              style.height = dimensions.height
            }
            return this.renderFrame(style)
          }}
        </Spring>
      )
    } else {
      return this.renderFrame(style)
    }
  }

  renderFrame(style) {
    const { tag, ...restProps } = this.props
    delete restProps.autoSize
    delete restProps.pager

    const props = {
      ...this.swipe.getEvents(),
      ...restProps,
    }

    return createElement(tag, {
      ...props,
      style: {
        ...style,
        ...props.style,
      },
      ref: element => (this.element = element),
    })
  }
}

export default props => (
  <ViewPagerContext.Consumer>
    {({ pager }) => {
      return <Frame {...props} pager={pager} />
    }}
  </ViewPagerContext.Consumer>
)

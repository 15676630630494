import React, { Component } from 'react'
import { connect } from 'react-redux'
import { replace, push } from 'react-router-redux'
import { Link } from 'react-router'
import { show as showAuthPopup } from 'client/shared/reducers/popup-reducer'
import omit from 'lodash/omit'

import './linka.styl'

import { Dispatch, State } from 'shared/types/redux'
import env from 'env'

type Props = {
  path?: string
  disabled?: boolean
  children?: React.ReactNode // children may be absent e.g. when Linka is passed into the Trans component of js-lingui
  activeClassName?: string
  replace?: boolean
  onClick?: (e: Event) => void
  auth: boolean
  loginWall: boolean
  forceDefaultBehaviour?: boolean
  pseudo?: boolean
  asBlock?: boolean
  currentPath: string
  className?: string
  target?: string
  onlyActiveOnIndex?: boolean
  itemProp?: string
  id?: string
  title?: string
  includeSubpaths?: boolean
  rel?: string
  style?: Record<string, string>
} & { dispatch: Dispatch }

class Linka extends Component<Props> {
  static defaultProps = {
    loginWall: false,
  }

  handleClick = event => {
    const {
      auth,
      path,
      onClick,
      dispatch,
      loginWall,
      replace: useHistoryReplaceState,
      pseudo,
    } = this.props

    let onClickHandler = onClick

    /**
     * Если передан replace, то в истории не сохранится
     * следующий редирект. При этом не будет работать
     * переданный onClick!
     *
     */
    if (useHistoryReplaceState) {
      onClickHandler = () => dispatch(replace(path))
    }

    if (loginWall && !auth) {
      event.persist()
      event.preventDefault()

      dispatch(
        showAuthPopup({
          callback: () => {
            if (onClickHandler) {
              return onClickHandler(event)
            }

            dispatch(push(path))
          },
        }),
      )
    } else if (pseudo) {
      if (onClickHandler) {
        onClickHandler(event)
      }

      dispatch(push(path))
    } else if (onClickHandler) {
      return onClickHandler(event)
    }
  }

  handleClickOutside = event => {
    const { onClick } = this.props

    if (onClick) {
      return onClick(event)
    }
  }

  checkPathPattern(path: string): boolean {
    const checkPattern = (pathname: string): string => {
      const pattern = /\/\w+\//gi.exec(pathname)
      return pattern ? pattern[0] : ''
    }

    const pathPattern = checkPattern(path)
    const locationPattern = checkPattern(window?.location.pathname)
    return (
      pathPattern.length > 0 &&
      locationPattern.length > 0 &&
      pathPattern === locationPattern
    )
  }

  render() {
    const {
      path,
      children,
      activeClassName,
      forceDefaultBehaviour,
      pseudo,
      onlyActiveOnIndex,
      includeSubpaths,
      ...otherProps
    } = this.props
    const linkProps = omit(otherProps, [
      'dispatch',
      'replace',
      'loginWall',
      'auth',
      'currentPath',
      'i18nHash',
    ])

    if (pseudo) {
      return this.renderPseudoLink(linkProps)
    }

    if (path?.indexOf('http') === 0) {
      return (
        <a
          href={path}
          {...linkProps}
          onClick={this.handleClickOutside}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      )
    }

    if (forceDefaultBehaviour) {
      return (
        <a href={path} {...linkProps} onClick={this.handleClick}>
          {children}
        </a>
      )
    }

    if (includeSubpaths && typeof path === 'string' && env.isClient()) {
      const arePatternsEqual = this.checkPathPattern(path)

      linkProps.className =
        arePatternsEqual && activeClassName
          ? `${linkProps.className} ${activeClassName}`
          : linkProps.className
    }

    return (
      <Link
        to={path}
        {...linkProps}
        onClick={this.handleClick}
        activeClassName={activeClassName}
        onlyActiveOnIndex={onlyActiveOnIndex}
      >
        {children}
      </Link>
    )
  }

  renderPseudoLink(linkProps) {
    const {
      path,
      children,
      activeClassName,
      currentPath,
      includeSubpaths,
      asBlock,
    } = this.props
    if (!linkProps.onClick) {
      linkProps.onClick = this.handleClick
    }

    linkProps.className = linkProps.className
      ? `${linkProps.className} linka_pseudo`
      : `linka_pseudo`

    if (includeSubpaths && typeof path === 'string' && env.isClient()) {
      const arePatternsEqual = this.checkPathPattern(path)

      linkProps.className =
        (currentPath === path || arePatternsEqual) && activeClassName
          ? `${linkProps.className} ${activeClassName}`
          : linkProps.className
    }

    return asBlock ? (
      <div onClick={this.handleClick} {...linkProps}>
        {children}
      </div>
    ) : (
      <span onClick={this.handleClick} {...linkProps}>
        {children}
      </span>
    )
  }
}

const connectWrapper = connect((state: State) => ({
  auth: state.currentUser.auth,
  currentPath: state.app.url,
}))

export default connectWrapper(Linka)

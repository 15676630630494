import { schema, denormalize } from 'normalizr'
import { userSchema } from 'client/bookmate/reducers/schemas/schemas'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import compact from 'lodash/compact'
import merge from 'lodash/merge'

import {
  ACHIEVEMENTS_PLEDGE_SUCCESS,
  ACHIEVEMENTS_PLEDGE_DELETE_SUCCESS,
} from 'client/shared/constants/achievements-constants'

import { ReadingAchievements } from 'client/shared/types/achievements'

type State = {
  [key: string]: ReadingAchievements
}

export const achievementSchema = new schema.Entity(
  'achievements',
  {
    user: userSchema,
  },
  {
    idAttribute: generateAchievementId,
  },
)

export const achievementsSchema = new schema.Array(achievementSchema)

// accepts an achievement object; extracts from it fields necessary to build an id
function generateAchievementId({ year, user }) {
  if (typeof user === 'string') {
    // during denormalization, the user field of an achievement will contain just the login
    return buildAchievementId(user, year)
  } else {
    // during normalization, the user field will be a user object, with the login field
    return buildAchievementId(user.login, year)
  }
}

// builds achievement id; can be conveniently used for retrieving achievements from the state
export function buildAchievementId(login: string, year: string | number) {
  return `${login.toLowerCase()}_${year}`
}

export function getAchievementByUsernameAndYear(
  username: string,
  year: string | number,
  state: any,
) {
  return getAchievementById(state, buildAchievementId(username, year))
}

export function getAllAchievementsByUsername(username: string, state: any) {
  const ids = Object.keys(
    pickBy(state.entities.achievements, (value, key: string) => {
      return startsWith(key, `${username.toLowerCase()}_`)
    }),
  )

  return getAchievementsByIds(state, ids)
}

function getAchievementById(state: any, id: string) {
  return state.entities.achievements[id] || {}
}

export function getAchievementsByIds(state: any, ids: string[]) {
  return compact(denormalize(ids, achievementsSchema, state.entities))
}

const initialState = {}

export default function achievements(state: State = initialState, action: any) {
  if (action.entities && action.entities.achievements) {
    return merge({}, state, action.entities.achievements)
  }

  switch (action.type) {
    case ACHIEVEMENTS_PLEDGE_SUCCESS: {
      const updatedAchievementsId = buildAchievementId(
        action.username,
        action.year,
      )

      return {
        ...state,
        [updatedAchievementsId]: {
          ...state[updatedAchievementsId],
          reading_challenge: action.readingChallenge,
        },
      }
    }

    case ACHIEVEMENTS_PLEDGE_DELETE_SUCCESS: {
      const updatedAchievementsId = buildAchievementId(
        action.username,
        action.year,
      )

      return {
        ...state,
        [updatedAchievementsId]: {
          ...state[updatedAchievementsId],
          reading_challenge: null,
        },
      }
    }
  }
  return state
}

import get from 'lodash/get'

export default function pickAndMapKeys({ path, keys, modifier }, object) {
  // eslint-disable-next-line no-param-reassign
  object = path ? get(object, path) : object
  return keys.reduce((result, key) => {
    const value = get(object, key)

    if (value) {
      return {
        ...result,
        [modifier(key)]: value,
      }
    }

    return result
  }, {})
}
